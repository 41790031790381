import React, { FC } from 'react'
import { Section } from 'Components'
import { PieChart } from 'react-minimal-pie-chart'
import './CampaignProgress.scss'
import { Link, useHistory } from 'react-router-dom'
import bellicon from '../../../../assets/Images/prospects_lg_6.png'

type CampaignProgressType = {
  draft: number | undefined
  complete: number | undefined
  active: number | undefined
}

export const CampaignProgress: FC<{
  data: any
  textTotal?: String
  textShow: string
}> = ({ data, textTotal = 'SAVED OUT', textShow }) => {
  const campaignProgressSchema = [
    { value: data.active, color: '#FFAB03' },
    { value: data?.complete, color: '#3DC4F5' },
    { value: data?.draft, color: '#E3E0E0' },
  ]
  const getChart = () => {
    return (
      <div>
        {data.active === 0 && data.complete === 0 && data.draft === 0 ? (
          <img src={bellicon} width="100%" alt="No Data" />
        ) : (
          <PieChart
            data={campaignProgressSchema}
            lineWidth={30}
            labelPosition={0}
            className="save_audiences_container__pie_className"
          />
        )}
      </div>
    )
  }
  const history = useHistory()
  return (
    <Section
      title={textShow}
      UNSAFE_className_text="campaignProgress__title"
      UNSAFE_className_container="campaignProgress__container"
      spacer={false}
      isCenter
      absoluteHeader
    >
      <div className="campaignProgress__link">
        <Link
          to="/campaigns"
          className="campaignProgress__linkAnchore"
          // onClick={()=>history.push('/campaign-dashboard')}
        >
          See all
        </Link>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div className="campaignProgress__right" style={{ fontWeight: '600' }}>
          <div>
            <span style={{ background: '#E3E0E0' }} />
            Draft ({data?.draft})
          </div>
          <div>
            <span style={{ background: '#FFAB03' }} />
            Active ({data?.active})
          </div>
          <div>
            <span style={{ background: '#FCEA44' }} />
            Completed ({data?.complete})
          </div>
        </div>
        <div className="campaignProgress__innerContainer">{getChart()}</div>
      </div>
    </Section>
  )
}
