/* eslint-disable */
import { faTable } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef } from 'react'
import './LableInputSelect.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type TextInputType = {
  Options?: any
  onChange?: any
  title?: string
  requireTage?: any
  type?: string
  isError?: Boolean
  icon?: IconProp | JSX.Element
  placeHolder?: string | undefined
  value?: any
  // value?: string | number | readonly string[] | undefined
  wdt?: string | number | undefined
  border?: string | number | undefined
  fontSize?: string | number | undefined
  color?: string | undefined
  padding?: string | number | undefined
  EyePassIcon?: JSX.Element
  iconLeft?: any
  iconRight?: any
  style?: any
  placeholder?: string
  lableStyle?: any
  imgIconStyle?: any
  UNSAFE_ClassName?: string
  defaultValue?: string
}

export const LableInputSelect: React.FC<TextInputType> = ({
  Options,
  title,
  requireTage,
  onChange,
  placeholder,
  EyePassIcon,
  defaultValue,
  value,
  isError,
  iconLeft,
  iconRight,
  style,
  lableStyle,
  type = 'text',
  imgIconStyle,
  icon,
  wdt,
  border,
  fontSize,
  color,
  padding,
  UNSAFE_ClassName,
}) => {
  const [errir, seterrir] = useState('')
  return (
    <>
      <div className="container">
        <div
          style={style}
          className={`
          ${
            isError
              ? `did-floating-label-content did-error-input`
              : `did-floating-label-content `
          }
        `}
          // className="did-floating-label-content did-error-input "
        >
          {/* onclick="this.setAttribute('value', this.value);" value="" */}
          <select
            className="did-floating-selects"
            onChange={onChange}
            value={value}
          >
            {Options}
          </select>
          <label className="did-floating-labels">
            {title}{' '}
            <span style={{ color: '#BD0000', marginLeft: '-2%' }}>
              {requireTage}
            </span>
          </label>
        </div>
      </div>
    </>
  )
}
