import { combineReducers, compose, createStore } from 'redux'
import { userReducer } from './reducers/userReducer'
import { userMailReducer } from './reducers/userMailReducer'
import { SpinnerReducer } from './reducers/SpinnerReducer'
import { TableReducer } from './reducers/tableReducer'
import { chargebeePackageReducer } from './reducers/chargebeeReducer'
import { DropDown } from './reducers/dropDownReducer'
import { globalFilters, setGlobalFilters } from './reducers/SearchList'

import {
  RefreshListingReducer,
  typedSearchReducer,
  selectedPageReducer,
  selectedSortReducer,
  targetAudienceReducer,
  isSavedAudienceReducer,
} from './reducers/ListingReducer'
import {
  centerLensReducer,
  selectedListingsReducer,
  openTagModalReducer,
  selectedMarkerReducer,
  FilteredBuzzReducer,
} from './reducers/LensReducer'
import {
  isCampaignLaunchReducer,
  campaignIdReducer,
} from './reducers/campaignReducer'
import { notificationsReducer } from './reducers/notificationsReducer'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(
  combineReducers({
    userReducer,
    userMailReducer,
    SpinnerReducer,
    chargebeePackageReducer,
    centerLensReducer,
    selectedListingsReducer,
    openTagModalReducer,
    selectedMarkerReducer,
    FilteredBuzzReducer,
    RefreshListingReducer,
    typedSearchReducer,
    selectedPageReducer,
    selectedSortReducer,
    targetAudienceReducer,
    isSavedAudienceReducer,
    isCampaignLaunchReducer,
    campaignIdReducer,
    TableReducer,
    notificationsReducer,
    DropDown,
    setGlobalFilters,
    globalFilters,
  }),
  composeEnhancers(),
)
