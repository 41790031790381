import { Text } from '@eltoro-ui/components'
import { Section } from 'Components'
import { userRole } from 'Helpers'
import ConfirmModal from 'Pages/CampaignCreation/components/CampaignCreatives/_components/ConfirmationModal/ConfirmModal'
import React, { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'Redux/actions'
import {
  chargebeeSubscription,
  getChargebeePackages,
  userDetail,
} from 'Requests'
import { TRootState } from 'types'
import { SubscriptionCard } from '..'
import './SubscriptionSection.scss'

export const SubscriptionSection: FC = () => {
  const [subscriptions, setSubscriptions] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { userData } = useSelector((state: TRootState) => state.userReducer)

  const [confirmPay, setConfirmPay] = useState(false)
  const [bodySubcscibe, setbodySubcscibe] = useState<any>()

  useEffect(() => {
    getChargebeePackages().then((res) => {
      setSubscriptions(res.data?.subscriptions.reverse() || [])
    })
  }, [])

  const onSubscribe = (body: any) => {
    setLoading(true)
    chargebeeSubscription(body)
      .then((response) => {
        const token = localStorage.getItem('beewo_token') || undefined
        userDetail(token).then((res) => {
          const user = {
            user_role: userRole(res.data?.is_super),
            ...res.data,
          }
          dispatch(setUser(user))
          setConfirmPay(false)
          setLoading(false)
        })
        if (response.data?.detail !== 'subcription created') {
          toast.error('Invalid card or Insufficient funds')
        } else {
          toast.success('Succefully Subscribe')
        }
      })
      .catch(() => {
        toast.error('Subscription error')
        setLoading(false)
      })
  }

  return (
    <div className="Subscription__container">
      {confirmPay && (
        <ConfirmModal
          onClickOutside={() => setConfirmPay(false)}
          onSubmit={() => onSubscribe(bodySubcscibe)}
          deleteId="0"
          textToDisplay="Are you sure you want to upgrade the package"
        />
      )}
      <Text
        on="white"
        weight="bold"
        size="l"
        UNSAFE_className="Subscription__container__heading"
      >
        Subscription
      </Text>

      <Section spacer={false} dark>
        <SubscriptionCard
          backgroundTab="profile"
          data={subscriptions}
          loading={loading}
          userData={userData}
          onClick={(body: {}) => {
            setConfirmPay(true)
            setbodySubcscibe(body)
          }}
        />
      </Section>
    </div>
  )
}
