import React from 'react'
import './Spinner.scss'

export const Spinner = () => {
  return (
    <div
      style={{
        zIndex: 33,
        background: 'rgba(233, 233, 233, 0.5)',
        position: 'fixed',
        inset: '0px',
        height: '100vh',
        width: '100%',
      }}
    >
      <div className="before-spinner" />
    </div>
  )
}
