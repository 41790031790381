import React, { useCallback, useEffect, useState } from 'react'

import { FileRejection, useDropzone } from 'react-dropzone'
import { FileUploadType } from 'types'
import './Uploader.scss'
import Papa from 'papaparse'
import toast, { Toaster } from 'react-hot-toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
// assets/Images/close1.png
import excelPng from '../../assets/Images/excel.png'
import csvPng from '../../assets/Images/csv.png'
// const Papa = require('papaparse')
// const fs = require('fs')

type UploaderType = {
  onDropFile: (files: FileUploadType) => void
  type: 'banner' | 'directMail' | 'video' | 'csv'
}

const mimeTypes = {
  // audience: ['text/csv', 'text/plain', 'application/vnd.ms-excel', '.csv'],
  banner: [
    'image/jpeg',
    // 'image/gif',
    'image/png',
  ],
  directMail: [
    'image/jpeg',
    // 'image/gif',
    'image/png',
    // 'application/zip',
    // 'application/x-gzip',
    // 'application/octet-stream',
  ],
  video: [
    // 'video/x-flv',
    'video/mp4',
    'video/mov',
    // 'video/qt',
    'video/quicktime',
    // 'video/webm',
    // 'video/x-ms-wmv',
    // 'video/avi',
    // 'video/3gpp',
  ],
  csv: ['text/csv', 'application/vnd.ms-excel'],
  // audio: ['audio/x-m4a', 'audio/ogg', 'audio/mpeg', 'audio/mp3'],
}

const ImageDimensions = [
  '300x250',
  '320x100',
  '728x90',
  '320x50',
  '300x600',
  '160x600',
]
const maxSize = {
  // audience: 100000,
  banner: 1000000,
  directMail: 1000000,
  video: 100000000,
  csv: 500000000000,
}

const thumbsContainer = {
  display: 'flex',
  // flexDirection: 'row',
  // flexWrap: 'wrap',
  // marginTop: 8,
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  justifyContent: 'center',
  // boxSizing: 'border-box'
}

const thumbInner = {
  // display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

function setFileInfo(files: any[]) {
  // const files = this.files;
}
export const Uploader: React.FC<UploaderType> = ({
  children,
  onDropFile,
  type,
}) => {
  const [files, setFiles] = useState<Blob[]>([])

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // const file = acceptedFiles.find(f => f)

      fileRejections.forEach((file) => {
        const TypeError = file.errors.filter(
          (eType) => eType.code === 'file-invalid-type',
        )
        if (TypeError.length === 0) {
          file.errors.forEach((err) => {
            if (err.code === 'file-too-large') {
              toast.error(err.message)

              // setErrors(`Error: ${err.message}`);
            }
          })
        } else if (TypeError[0].code === 'file-invalid-type') {
          toast.error('File type is not supported')
        }
      })
      const myVideos: any[] = []

      const video = document.createElement('video')
      video.preload = 'metadata'

      const videoBlob = URL.createObjectURL(acceptedFiles[0])
      video.onloadedmetadata = function () {
        // window.URL.revokeObjectURL(video.src)
        const { duration } = video
        if (duration === Infinity) {
          toast.error('Unsupportive file')
        } else if (Math.floor(duration) <= 30) {
          myVideos.push(
            Object.assign(acceptedFiles[0], {
              preview: videoBlob,
            }),
          )

          setFiles(myVideos)
          onDropFile({
            acceptedFiles: myVideos,
            rejectedFiles: fileRejections,
          })
        } else {
          toast.error('Video must not be greater than 30 sec')
        }
      }

      video.src = videoBlob
      // const reader = new FileReader()
      // reader.onload = function () {
      //   const media = new Audio(reader?.result)
      //   media.onloadedmetadata = function () {
      //     if (Math.floor(media.duration) <= 30) {
      //       onDropFile({
      //         acceptedFiles,
      //         rejectedFiles: fileRejections,
      //       })
      //     } else {
      //       toast.error('Video must not be greater than 30 sec')
      //     }

      //     // this would give duration of the video/audio file
      //   }
      // }
      // reader.readAsDataURL(acceptedFiles[0])
      // var media = new Audio();

      if (type === 'banner') {
        const filteredImages: any[] = []
        // let counter = 0

        const Img = acceptedFiles.filter((file) => {
          const image = new Image()
          const newImg = URL.createObjectURL(file)
          image.addEventListener('load', () => {
            // only select images within width/height limits
            // if (image.width < 240 && image.height < 240) {
            //   filteredImages.push(image)
            // }
            if (ImageDimensions.includes(`${image.width}x${image.height}`)) {
              filteredImages.push(
                Object.assign(acceptedFiles[0], {
                  preview: newImg,
                }),
              )
              acceptedFiles = filteredImages

              setFiles(filteredImages)
              onDropFile({
                acceptedFiles: filteredImages,
                rejectedFiles: fileRejections,
                height: image?.height,
                width: image?.width,
              })
              // setTimeout(() => {
              //   URL.revokeObjectURL(newImg)
              // }, 1000)
            } else {
              toast.error('Images is not of given dimensions')
            }

            // if we have gone through all the files, handle the ones that
            // made it through the filter using `handleImages` function
            // if (counter === files.length) handleImages(filteredImages);
          })

          image.src = URL.createObjectURL(file)
          return ''
        })
      }

      if (type === 'csv') {
        let flag: boolean = false
        Papa.parse(acceptedFiles[0], {
          delimiter: '',
          chunkSize: 3,
          header: false,
          complete: (responses: any) => {
            Object.keys(responses.data[0]).forEach(function (key, index) {
              if (
                index >= 1 &&
                responses.data[index][0] === '' &&
                responses.data[index][1] === ('' || undefined)
              ) {
                // flag = true
                return (flag = true)
                // return true
              }
              return false
            })
            if (
              responses.data[0].includes('address') &&
              responses.data[0].includes('zip') &&
              responses.data[0].length === 2 &&
              flag === false
            ) {
              return (
                setFiles(acceptedFiles),
                onDropFile({
                  acceptedFiles,
                  rejectedFiles: fileRejections,
                })
              )
            }
            if (
              responses.data[0].includes('address') &&
              responses.data[0].includes('zip') &&
              responses.data[0].length === 2 &&
              flag === true
            ) {
              toast.error('Can’t attach empty file')
              return null
            }
            return toast.error('Incorrect format')
          },
        })
      }
    },
    [],
  )

  const testing = () => {}
  const thumbs = files.map((file: any) => (
    <div style={{ marginBottom: '9px', marginTop: '-11px' }}>
      <div>
        <div
          style={{
            display: 'flex',
            marginBottom: '-15px',
            marginRight: '86px',
            // justifyContent: 'flex-end',
          }}
        >
          {/* <input
          type="image"
          alt="new"
        /> */}
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{
              width: '22px',
              height: '22px',
              zIndex: 1001,
              background: '#c1c1c1',
              color: 'rgb(255 255 255)',
              cursor: 'pointer',
              borderRadius: '50%',
            }}
            // className="image-box__img-1"
            onClick={() => {
              setFiles([])
              onDropFile({
                acceptedFiles: [],
                rejectedFiles: [],
                height: 0,
                width: 0,
              })
            }}
          />
        </div>
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            {file.type.includes('video') && (
              <video
                // src={this.state.get_video}
                src={file.preview}
                style={{
                  // display: 'block',
                  width: '100px',
                  height: '100px',
                  // padding: '8px',
                }}
                // className="image-box__img-2"

                // className="get_preview_video_class"
              >
                <track kind="captions" />
              </video>
            )}
            {file.type.includes('image') && (
              <img
                src={file.preview}
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                alt="preview"
              />
            )}
            {file.name.split('.').pop().includes('xls') && (
              <img src={excelPng} style={img} alt="preview" />
            )}
            {file.name.split('.').pop().includes('csv') && (
              <img
                src={csvPng}
                style={{
                  display: 'block',
                  width: 'auto',
                  height: '100%',
                  padding: '8px',
                }}
                alt="preview"
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ width: '100px', marginBottom: '2rem' }}>
        <span style={{ wordBreak: 'break-all' }}>{file.name}</span>
      </div>
    </div>
  ))

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    // files.forEach((file) => URL.revokeObjectURL(file?.preview))
  }, [files])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: mimeTypes[type],
    maxSize: maxSize[type],
    // multiple: false,
  })

  return (
    <div>
      <Toaster position="top-center" />

      <div className="thumbsContainer">{thumbs}</div>

      <div
        className="upload_your_video"
        style={{
          // padding: '35px 54px',
          // background: ' #EEEFF0',
          marginBottom: '10px',
        }}
      >
        <div className="Uploader" {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
        </div>
      </div>
    </div>
  )
}

// const reader = new FileReader()
// reader.onload = function () {
//   const media = new Audio(reader?.result)
//   media.onloadedmetadata = function () {
//     if (Math.floor(media.duration) <= 30) {
//       onDropFile({
//         acceptedFiles,
//         rejectedFiles: fileRejections,
//       })
//     } else {
//       toast.error('Video must not be greater than 30 sec')
//     }

//     // this would give duration of the video/audio file
//   }
// }
// reader.readAsDataURL(acceptedFiles[0])

// acceptedFiles.map((file) => {
// const image = new Image()
// const filteredImages = []
// const i = new Image()

// i.onload = () => {
//   const reader = new FileReader()
//   reader.readAsDataURL(acceptedFiles[0])
//   reader.onload = () => {
//
//   }
// }

// const reader = new FileReader()
// reader.onload = function (entry) {
//   // The Image() constructor creates a new HTMLImageElement instance.
//   const image = new Image()
//   // image.src = entry.target.result;
//   image.addEventListener('load', function () {
//     // var s=aImg.getBoundingClientRect();
//     alert(`${(image.width, image.height)}`)
//   })
//   image.onload = function () {
//   }
// }
// acceptedFiles.map((file) => {
//   const image = new Image()
//   const filteredImages = []
//   if (image.addEventListener) {
//     image.addEventListener(
//       'load',
//       () => {

//         // only select images within width/height limits
//         if (image) {
//           filteredImages.push(image)
//         }

//         // increment counter for each image we go through
//         // counter += 1;

//         // if we have gone through all the files, handle the ones that
//         // made it through the filter using `handleImages` function
//         // if (counter === files.length) handleImages(filteredImages);
//       },
//       false,
//     )
//   }
//   return file
//   // image.src = URL.createObjectURL(file)
// })
// image.addEventListener('load', () => {
//
//

//   // only select images within width/height limits
//   if (ImageDimensions.includes(`${image.width}x${image.height}`)) {
//     filteredImages.push(image)
//   }
// })
// return filteredImages
// })
