import React, { useState } from 'react'
import {
  MaxHeightContainer,
  LargeIconButton,
  Text,
  GridBox,
  Modal,
} from '@eltoro-ui/components'
import './ProspectFinderSelection.scss'
import { useRouteMatch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBullhorn,
  // faLock,
  faWalking,
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { VotingModal } from 'Components/VotingModal'

import { Layout } from 'Components'
import { TRootState } from 'types'
import prospect_1 from '../../../assets/Images/prospects_lg_1.png'
import prospect_2 from '../../../assets/Images/prospects_lg_2.png'
import prospect_3 from '../../../assets/Images/prospects_lg_3.png'
// import prospect_4 from '../../../assets/Images/prospects_4.png'
import prospect_5 from '../../../assets/Images/prospects_lg_5.png'
import prospect_6 from '../../../assets/Images/prospects_lg_6.png'
import Talktoyoursphere from '../../../assets/Images/talktoyoursphere.png'
import Growyourspehere from '../../../assets/Images/growyourspehere.png'
import Beatthemarket from '../../../assets/Images/beatthemarket.png'
import Humbleberg from '../../../assets/Images/humbleberg.png'

const ProspectFinderHeader: React.FC<{
  mainTitle: string
  subTitle?: string
  icon?: JSX.Element
}> = ({ mainTitle, subTitle, icon }) => {
  return (
    <div className="ProspectFinderHeader">
      <GridBox alignItems="center" gridTemplateColumns="auto 1fr" gap="1rem">
        {icon && (
          <Text on="white" size="xxl">
            {icon}
          </Text>
        )}
        <Text
          on="white"
          size="xxxl"
          weight="bold"
          UNSAFE_className="ProspectFinderHeader__title"
        >
          {mainTitle}
        </Text>
      </GridBox>
      {subTitle && <Text on="white">{subTitle}</Text>}
    </div>
  )
}

export const ProspectFinderSelection = () => {
  const { url } = useRouteMatch()
  const { userData } = useSelector((state: TRootState) => state.userReducer)
  const [TalkModal, setTalkModal] = useState<boolean>(false)
  const [growSphereModal, setGrowSphereModal] = useState<boolean>(false)
  const [beattheMorketModal, setBeattheMorketModal] = useState(false)
  const [humblebergeModal, setHumblebergeModal] = useState(false)

  const upgradePackage = (str: string) => {
    if (userData?.sub_name !== 'beewo-subscription-premium') {
      return toast.success(str)
    }
    return null
  }

  return (
    <div className="ProspectFinderSelection" data-testid="prospectActivityPage">
      <MaxHeightContainer>
        <Layout container_className="ProspectFinderSelection__layout">
          <div className="ProspectFinderSelection__content">
            <div className="ProspectFinderSelection__contentHeader">
              <ProspectFinderHeader mainTitle="" />
              <p className="ProspectFinderSelection__sub_title">
                Identify prospects by observing footfall traffic seen in market
                or other variables
              </p>
            </div>
            <div className="ProspectFinderSelection__section ProspectFinderSelection__wrap">
              <LargeIconButton
                title="Sell Your Listing Faster"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={prospect_2}
                    alt="prospect_2"
                  />
                }
                subTitle="Locate intenders seen at comparable properties."
                // content={[
                //   'Engage with ',
                //   <strong>potential buyers</strong>,
                //   ' see all listings that are similar to yours and advertise your listings',
                // ]}
                to={`${url}/sell-your-listing-faster`}
              />
              <LargeIconButton
                UNSAFE_className="prospects_heading"
                title="Talk To Your Sphere"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={prospect_1}
                    alt="prospect_1"
                  />
                }
                subTitle="Stay in contact with your contacts."
                // content={[
                //   'Engage with ',
                //   <strong>potential buyers</strong>,
                //   ' seen at listings that are similiar to yours and advertise your listings',
                // ]}
                // to={`${url}/talk-to-your-sphere`}
                onClick={() => {
                  // toast.success('This feature is coming soon')
                  setTalkModal(true)
                }}
              />
              {TalkModal && (
                <Modal offClick={() => setTalkModal(false)}>
                  <VotingModal
                    handleModel={() => setTalkModal(false)}
                    icons={Talktoyoursphere}
                    title="Talk To Your Sphere"
                    subTitle="Engage with potential buyers seen at listings that are similiar to
                  yours and advertise your listings."
                  />
                </Modal>
              )}
              <LargeIconButton
                UNSAFE_className="prospects_heading"
                title="Grow Your Sphere"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={prospect_3}
                    alt="prospect_3"
                  />
                }
                onClick={() => {
                  // toast.success('This feature is coming soon')
                  setGrowSphereModal(true)
                }}
                subTitle="Expand your sphere to those likely to know you."
                // content={[
                //   'Advertise to ',
                //   <strong>potential buyers</strong>,
                //   ' seen at all available listings in a specific geographic area',
                // ]}
                // to={`${url}/grow-your-sphere`}
              />
              {growSphereModal && (
                <Modal offClick={() => setGrowSphereModal(false)}>
                  <VotingModal
                    handleModel={() => setGrowSphereModal(false)}
                    icons={Growyourspehere}
                    title="Grow Your Sphere"
                    subTitle="Advertise to potential buyers seen at all available listings in a specific geographic area."
                  />
                </Modal>
              )}
              <LargeIconButton
                UNSAFE_className="prospects_heading"
                title="Beat The Market"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={prospect_5}
                    alt="prospect_5"
                  />
                }
                subTitle="Get both sides of the transaction and send your listing to intenders in market before listing is live."
                // onClick={() =>
                //   upgradePackage(
                //     'This feature is only available for premium subscription',
                //   )
                // }
                onClick={() => {
                  // toast.success('This feature is coming soon')
                  setBeattheMorketModal(true)
                }}
                // content={[
                //   'Advertise to ',
                //   <strong>potential buyers</strong>,
                //   ' that have been seen in market at active and inactive listings',
                // ]}
                // to={
                //   userData?.sub_name === 'beewo-subscription-premium'
                //     ? `${url}/beat-the-market`
                //     : '#'
                // }
              />
              {beattheMorketModal && (
                <Modal offClick={() => setBeattheMorketModal(false)}>
                  <VotingModal
                    handleModel={() => setBeattheMorketModal(false)}
                    icons={Beatthemarket}
                    title="Beat The Market"
                    subTitle="Advertise to potential buyers that have been seen in market at active and inactive listings."
                  />
                </Modal>
              )}
              <LargeIconButton
                UNSAFE_className="prospects_heading"
                title="Humble Brag"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={prospect_6}
                    alt="prospect_4"
                  />
                }
                subTitle="Find residences surrounding your new listings or recently sold prosperities."
                // subTitle="Increase the effectiveness of traditional farming methods."
                // content={[
                //   'Engage with ',
                //   <strong>potential sellers</strong>,
                //   ' by advertising to the neighboors around your listings, active and inactive',
                // ]}
                onClick={() => {
                  // toast.success('This feature is coming soon')
                  setHumblebergeModal(true)
                }}
                // onClick={() =>
                //   upgradePackage(
                //     'This feature is only available for premium subscription',
                //   )
                // }
                // to={
                //   userData?.sub_name === 'beewo-subscription-premium'
                //     ? // ? `${url}/digital-farming`
                //       `${url}/humble-brag`
                //     : '#'
                // }
              />
              {humblebergeModal && (
                <Modal offClick={() => setHumblebergeModal(false)}>
                  <VotingModal
                    handleModel={() => setHumblebergeModal(false)}
                    icons={Humbleberg}
                    title="Humble Brag"
                    subTitle="Engage with potential sellers by advertising to the neighbors around your listings, active and inactive."
                  />
                </Modal>
              )}
            </div>
          </div>
        </Layout>
      </MaxHeightContainer>
    </div>
  )
}
