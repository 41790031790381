import React, { ReactNode } from 'react'
import './HeaderNav.scss'

export const HeaderNav = ({
  children,
  marginRight,
  marginLeft,
  paddingBlock,
}: {
  children: ReactNode
  marginRight?: string
  marginLeft?: string
  paddingBlock?: string
}) => {
  return (
    <div
      className="HeaderNav"
      style={{ marginRight, marginLeft, paddingBlock }}
    >
      {children}
    </div>
  )
}
