import React from 'react'
import { Button, Text } from '@eltoro-ui/components'
import './ContactsSeen.scss'
import { useHistory } from 'react-router-dom'
import Contactseen from '../../../../assets/Images/contactseen.png'

type ContactsSeenType = {
  onClick?: any
  textLogo: string
  text: string | undefined
  textValue: number
}
const ContactsSeen = ({
  textLogo,
  text,
  textValue,
  onClick,
}: ContactsSeenType) => {
  const history = useHistory()
  return (
    <div className="contact-seen">
      <div className="contact-seen__icon">
        <img src={Contactseen} alt="" />
      </div>
      <div className="contact-seen__columns_per_line">
        <div className="contact-seen__number_counter">
          <Text
            on="primary-500"
            UNSAFE_className="contact-seen__text-content__title"
          >
            {textValue < 1 ? '0' : 0}
          </Text>
        </div>
        <div className="contact-seen__text-content">
          <Text
            weight="bold"
            on="primary-500"
            UNSAFE_className="contact-seen__text-content__sub_title"
          >
            {text}
          </Text>
        </div>

        <Button
          kind="default"
          size="xl"
          weight="bolder"
          width="186px"
          UNSAFE_className="contact-seen__button"
          onClick={
            onClick
            //   () => {
            //   return textValue === 0 ? history.push('/my-sphere') : () => {}
            // }
          }
        >
          {textValue === 0 ? 'Upload Contacts' : 'Send Campaign'}
        </Button>
      </div>
    </div>
  )
}

export default ContactsSeen
