import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  PageHeader,
  CampaignDetails,
  Section,
  CompactSavedAudiences,
  Loading,
  Layout,
} from 'Components'
import { PieChart } from 'react-minimal-pie-chart'
import { Button, Spacer, FlexBox, GridBox, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faDownload,
  faHome,
  faUsdCircle,
} from '@fortawesome/pro-solid-svg-icons'
import './CampaignView.scss'
import { CampaignReport } from 'Components/CampaignReport'
import { getCampaignById } from 'Requests'
import { useAsync } from '@eltoro-ui/hooks'
import { CampaignType } from 'types'
import { getCampaignDetailsById } from 'Requests/Request_Methods/campaignMethods'
import target from '../../assets/Images/target1.svg'
import person from '../../assets/Images/person.svg'
import clicks from '../../assets/Images/clicks.svg'
import impressions from '../../assets/Images/impressions.svg'
import ctr from '../../assets/Images/ctr.svg'
import house from '../../assets/Images/house.svg'
import budget from '../../assets/Images/budget.svg'
import tempImg from '../../assets/Images/temp1.png'
import tempImg2 from '../../assets/Images/temp2.png'
import downloadicon from '../../assets/Images/Group86315.png'
import totalimpression from '../../assets/Images/totalimpression.png'
// import ctrSmall from '../../assets/Images/ctr-small.svg'
// import impressionsSmall from '../../assets/Images/impressions-small.svg'
// import clickSmall from '../../assets/Images/click-small.svg'

export const CampaignView = () => {
  const { campaignId } = useParams<{ campaignId: string }>()

  const [reportCardData, setReportCardData] = useState<any>([])
  const [largeReportCardData, setLargeReportCardData] = useState<any>([])

  const [campaign, setCampaign] = useState<any>({
    name: 'test campaign',
    clicks: 0,
    ctr: 0,
    total_impressions: 200000,
    served_impressions: 0,
    total_rate: 4000,
    served_rate: 0,
    total_days: 3,
    served_days: 1,
    start_day: '2021-12-29T05:00:00.000Z',
    stop_day: '2022-01-01T04:59:59.999Z',
    created: '2021-12-27T15:22:42.692Z',
    impressions_perday: 6,
    status: 'Open',
    orderlines: [
      {
        creative_type: 'Banner',
        creatives: [
          {
            _id: '4kNFJJehJpowyG5sY',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 1,
            name: 'Inline rectangle_ 300x250.png',
            files: [
              {
                _id: '4kNFJJehJpowyG5sY',
                url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                type: 1,
                name: 'Inline rectangle_ 300x250.png',
                size: 59947,
                height: 250,
                width: 300,
                key: '4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                updated: '2021-12-27T15:27:34.628Z',
                etag: 'cb347a8ac07a0937b0babc30fbd838b5',
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:27:34.646Z',
            updated: '2021-12-27T15:34:47.930Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'HuRN8YYf8KNgdrr8H1',
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                targetType: 1,
                creativeType: 1,
                name: 'Banner',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            mime: 'image/png',
            size: 166000,
            height: 250,
            width: 300,
          },
          {
            _id: '4kNFJJehJpowyG5sY',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 1,
            name: 'Inline rectangle_ 300x250.png',
            files: [
              {
                _id: '4kNFJJehJpowyG5sY',
                url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                type: 1,
                name: 'Inline rectangle_ 300x250.png',
                size: 59947,
                height: 250,
                width: 300,
                key: '4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                updated: '2021-12-27T15:27:34.628Z',
                etag: 'cb347a8ac07a0937b0babc30fbd838b5',
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:27:34.646Z',
            updated: '2021-12-27T15:34:47.930Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'HuRN8YYf8KNgdrr8H1',
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                targetType: 1,
                creativeType: 1,
                name: 'Banner',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            mime: 'image/png',
            size: 166000,
            height: 250,
            width: 300,
          },
          {
            _id: '4kNFJJehJpowyG5sY',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 1,
            name: 'Inline rectangle_ 300x250.png',
            files: [
              {
                _id: '4kNFJJehJpowyG5sY',
                url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                type: 1,
                name: 'Inline rectangle_ 300x250.png',
                size: 59947,
                height: 250,
                width: 300,
                key: '4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                updated: '2021-12-27T15:27:34.628Z',
                etag: 'cb347a8ac07a0937b0babc30fbd838b5',
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:27:34.646Z',
            updated: '2021-12-27T15:34:47.930Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'HuRN8YYf8KNgdrr8H1',
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                targetType: 1,
                creativeType: 1,
                name: 'Banner',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            mime: 'image/png',
            size: 166000,
            height: 250,
            width: 300,
          },
          {
            _id: '4kNFJJehJpowyG5sY',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 1,
            name: 'Inline rectangle_ 300x250.png',
            files: [
              {
                _id: '4kNFJJehJpowyG5sY',
                url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                type: 1,
                name: 'Inline rectangle_ 300x250.png',
                size: 59947,
                height: 250,
                width: 300,
                key: '4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                updated: '2021-12-27T15:27:34.628Z',
                etag: 'cb347a8ac07a0937b0babc30fbd838b5',
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:27:34.646Z',
            updated: '2021-12-27T15:34:47.930Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'HuRN8YYf8KNgdrr8H1',
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                targetType: 1,
                creativeType: 1,
                name: 'Banner',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            mime: 'image/png',
            size: 166000,
            height: 250,
            width: 300,
          },
          {
            _id: '4kNFJJehJpowyG5sY',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 1,
            name: 'Inline rectangle_ 300x250.png',
            files: [
              {
                _id: '4kNFJJehJpowyG5sY',
                url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                type: 1,
                name: 'Inline rectangle_ 300x250.png',
                size: 59947,
                height: 250,
                width: 300,
                key: '4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                updated: '2021-12-27T15:27:34.628Z',
                etag: 'cb347a8ac07a0937b0babc30fbd838b5',
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:27:34.646Z',
            updated: '2021-12-27T15:34:47.930Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'HuRN8YYf8KNgdrr8H1',
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                targetType: 1,
                creativeType: 1,
                name: 'Banner',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            mime: 'image/png',
            size: 166000,
            height: 250,
            width: 300,
          },
          {
            _id: '4kNFJJehJpowyG5sY',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 1,
            name: 'Inline rectangle_ 300x250.png',
            files: [
              {
                _id: '4kNFJJehJpowyG5sY',
                url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                type: 1,
                name: 'Inline rectangle_ 300x250.png',
                size: 59947,
                height: 250,
                width: 300,
                key: '4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                updated: '2021-12-27T15:27:34.628Z',
                etag: 'cb347a8ac07a0937b0babc30fbd838b5',
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:27:34.646Z',
            updated: '2021-12-27T15:34:47.930Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'HuRN8YYf8KNgdrr8H1',
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                targetType: 1,
                creativeType: 1,
                name: 'Banner',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            mime: 'image/png',
            size: 166000,
            height: 250,
            width: 300,
          },
          {
            _id: '4kNFJJehJpowyG5sY',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 1,
            name: 'Inline rectangle_ 300x250.png',
            files: [
              {
                _id: '4kNFJJehJpowyG5sY',
                url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                type: 1,
                name: 'Inline rectangle_ 300x250.png',
                size: 59947,
                height: 250,
                width: 300,
                key: '4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                updated: '2021-12-27T15:27:34.628Z',
                etag: 'cb347a8ac07a0937b0babc30fbd838b5',
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:27:34.646Z',
            updated: '2021-12-27T15:34:47.930Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'HuRN8YYf8KNgdrr8H1',
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                targetType: 1,
                creativeType: 1,
                name: 'Banner',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            mime: 'image/png',
            size: 166000,
            height: 250,
            width: 300,
          },
          {
            _id: '4kNFJJehJpowyG5sY',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 1,
            name: 'Inline rectangle_ 300x250.png',
            files: [
              {
                _id: '4kNFJJehJpowyG5sY',
                url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                type: 1,
                name: 'Inline rectangle_ 300x250.png',
                size: 59947,
                height: 250,
                width: 300,
                key: '4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                updated: '2021-12-27T15:27:34.628Z',
                etag: 'cb347a8ac07a0937b0babc30fbd838b5',
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:27:34.646Z',
            updated: '2021-12-27T15:34:47.930Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'HuRN8YYf8KNgdrr8H1',
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                targetType: 1,
                creativeType: 1,
                name: 'Banner',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/4/k/4kNFJJehJpowyG5sY/4kNFJJehJpowyG5sY-inline rectangle_ 300x250._source.png',
            mime: 'image/png',
            size: 166000,
            height: 250,
            width: 300,
          },
        ],
        targets: [
          {
            _id: 'DTjbreo4giQY7nR23',
            orgId: 'h9X4JMCHirH2RDoRi',
            lens: 'Expand Your Marketing',
            type: 12,
            conf: {
              targetType: 1,
            },
            name: 'sample2.csv',
            nameOrig: 'sample2.csv',
            files: [
              {
                _id: 'DTjbreo4giQY7nR23',
                url: 'https://s3.amazonaws.com/bucketuploads.eltoro.com/D/T/DTjbreo4giQY7nR23/DTjbreo4giQY7nR23-sample2._source.csv',
                type: 1,
                name: 'sample2.csv',
                size: 46,
                key: 'D/T/DTjbreo4giQY7nR23/DTjbreo4giQY7nR23-sample2._source.csv',
                bucket: 'bucketuploads.eltoro.com',
                mime: 'text/csv',
                updated: '2021-12-27T15:30:50.972Z',
              },
              {
                type: 2,
                name: 'sample2.csv (APPEND)',
                bucket: 'targetuploads.eltoro.com',
                key: 'dev/c74tocv9gm4g52ur1up0/quote/address/2021-12-27/c74toif9gm4g52ur1ut0-annotated.csv',
                url: 'https://s3.amazonaws.com/targetuploads.eltoro.com/dev/c74tocv9gm4g52ur1up0/quote/address/2021-12-27/c74toif9gm4g52ur1ut0-annotated.csv',
                updated: '2021-12-27T15:31:22.152Z',
                mime: 'text/csv',
              },
              {
                type: 3,
                name: 'sample2.csv (CLEANED)',
                bucket: 'bucketuploads.eltoro.com',
                key: 'D/T/DTjbreo4giQY7nR23/DTjbreo4giQY7nR23-2021-12-27-1530.csv',
                updated: '2021-12-27T15:30:55.680Z',
                mime: 'text/csv',
              },
              {
                type: 4,
                name: 'sample2.csv (ERRORS)',
                bucket: 'bucketuploads.eltoro.com',
                key: 'D/T/DTjbreo4giQY7nR23/DTjbreo4giQY7nR23-errors.csv',
                updated: '2021-12-27T15:30:55.680Z',
                mime: 'text/csv',
              },
            ],
            columns: [
              {
                srcKey: 'address_1',
                srcIndex: 0,
                srcField: 'address',
                payloadKey: 'a',
              },
              {
                srcKey: 'zip',
                srcIndex: 1,
                srcField: 'zip',
                payloadKey: 'z',
              },
            ],
            payload: [
              {
                bucketId: 'DTjbreo4giQY7nR23',
                _id: 'DTjbreo4giQY7nR23',
                src: ['address', 'zip'],
                h: true,
              },
              {
                bucketId: 'DTjbreo4giQY7nR23',
                _id: 'DTjbreo4giQY7nR23',
                src: ['"Acme', '123 N. Main St', 'suite"', '40202'],
              },
            ],
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:30:51.015Z',
            updated: '2021-12-27T15:36:41.091Z',
            status: 90,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'DbXMMLaJhfB72mYWj',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                targetType: 1,
                creativeType: 2,
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                name: 'video',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            lastUsed: '2022-01-01T04:59:59.999Z',
            archiveDate: '2022-01-31T04:59:59.999Z',
            progress: 1,
            quoteColumns: [
              {
                srcKey: 'address_1',
                srcIndex: 0,
                srcField: 'address',
                payloadKey: 'a',
              },
              {
                srcKey: 'zip',
                srcIndex: 1,
                srcField: 'zip',
                payloadKey: 'z',
              },
            ],
            review: {
              'total-lines': 1,
              duplicates: 0,
              apartments: 1,
              'special-chars': 1,
              'po-boxes': 0,
              'bad-zips': 0,
              'bad-addresses': 1,
              'remaining-lines': 1,
            },
            hasErrors: true,
            output: {
              quoteRequested: '2021-12-27T15:30:59.240Z',
              quoted: '2021-12-27T15:31:22.068Z',
              firstQuoted: '2021-12-27T15:31:22.068Z',
            },
            deploy: {
              replytoorig:
                'https://sqs.us-east-1.amazonaws.com/498598553520/portal-main-dev',
              quote_by: 'b2c search processing',
              quote_date: '2021-12-27T15:31:21Z',
              quote_append:
                'targetuploads.eltoro.com/dev/c74tocv9gm4g52ur1up0/quote/address/2021-12-27/c74toif9gm4g52ur1ut0-annotated.csv',
            },
            stats: {
              matched: 1,
              total: 1,
              rate: 1,
            },
            lastEtl: '2021-12-27T15:31:22.068Z',
          },
        ],
        clickThroughUrl: 'https://turtleco.com',
      },
      {
        creative_type: 'Video',
        creatives: [
          {
            _id: 'omeqJ2HpLcpGarEyR',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 11,
            name: '2021-12-01-192635.mp4',
            files: [
              {
                _id: 'omeqJ2HpLcpGarEyR',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqJ2HpLcpGarEyR-2021-12-01-192635._source.mp4',
                type: 1,
                name: '2021-12-01-192635.mp4',
                size: 1615192,
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqJ2HpLcpGarEyR-2021-12-01-192635._source.mp4',
                bucket: 'c.eltoro.com',
                mime: 'video/mp4',
                updated: '2021-12-27T15:31:19.708Z',
                etag: '5b2eb990fa222e8686ac6c04999c738a',
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.1024x576p30fps16-9.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 3936803,
                etag: 'a09d47d03a399fc20560b02c950b6a5e',
                type: 1,
                name: '2021-12-01-192635._source.1024x576p30fps16-9.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.1024x576p30fps16-9.mp4',
                updated: '2021-12-27T15:31:37.940Z',
                height: 480,
                width: 640,
                bitrate: 2707,
                duration: 11.634,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.1080p29fps.mp4',
                bucket: 'c.eltoro.com',
                mime: 'video/mp4',
                size: 9561277,
                etag: 'e241002f02392c64a30130877627de5b',
                type: 1,
                name: '2021-12-01-192635._source.1080p29fps.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.1080p29fps.mp4',
                updated: '2021-12-27T15:31:40.539Z',
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4',
                bucket: 'c.eltoro.com',
                mime: 'video/mp4',
                size: 528799,
                etag: 'ccc1fcea3c57efc964a97df1fd17bcc0',
                type: 1,
                name: '2021-12-01-192635._source.320px240p15fps.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4',
                updated: '2021-12-27T15:31:37.942Z',
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4_thumb1.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                size: 62419,
                etag: '4ea859fc80250cbe727d60ebf5d5bba4',
                type: 1,
                name: '2021-12-01-192635._source.320px240p15fps.mp4_thumb1.png',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4_thumb1.png',
                updated: '2021-12-27T15:33:05.839Z',
                height: 240,
                width: 320,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4_thumb2.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                size: 32218,
                etag: 'c70a1c9ba76934d99dcd6fa0e14613da',
                type: 1,
                name: '2021-12-01-192635._source.320px240p15fps.mp4_thumb2.png',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4_thumb2.png',
                updated: '2021-12-27T15:33:05.839Z',
                height: 240,
                width: 320,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4_thumb3.png',
                bucket: 'c.eltoro.com',
                mime: 'image/png',
                size: 62005,
                etag: '7e170619a4b36d6d314255f8acea8e2f',
                type: 1,
                name: '2021-12-01-192635._source.320px240p15fps.mp4_thumb3.png',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4_thumb3.png',
                updated: '2021-12-27T15:33:05.840Z',
                height: 240,
                width: 320,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.360p29fps16-9.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 1381672,
                etag: 'bf6124a1fb5690890e0095714f27c730',
                type: 1,
                name: '2021-12-01-192635._source.360p29fps16-9.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.360p29fps16-9.mp4',
                updated: '2021-12-27T15:31:37.942Z',
                height: 360,
                width: 480,
                bitrate: 949,
                duration: 11.645,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.360p29fps4-3.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 1178165,
                etag: 'b578d3b971b43f9ced78ff96c687fd82',
                type: 1,
                name: '2021-12-01-192635._source.360p29fps4-3.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.360p29fps4-3.mp4',
                updated: '2021-12-27T15:31:37.942Z',
                height: 360,
                width: 480,
                bitrate: 809,
                duration: 11.645,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.480p29fps16-9.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 2223612,
                etag: '8168e3c90e97b1fb1935f64011147775',
                type: 1,
                name: '2021-12-01-192635._source.480p29fps16-9.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.480p29fps16-9.mp4',
                updated: '2021-12-27T15:31:37.943Z',
                height: 480,
                width: 640,
                bitrate: 1528,
                duration: 11.645,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.480p29fps4-3.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 1713832,
                etag: '52a4b4d35fb82d5ac97905222ff163ed',
                type: 1,
                name: '2021-12-01-192635._source.480p29fps4-3.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.480p29fps4-3.mp4',
                updated: '2021-12-27T15:31:37.943Z',
                height: 480,
                width: 640,
                bitrate: 1177,
                duration: 11.645,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.720p29fps.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 4309928,
                etag: 'b54fc3f7e8e8ad78f0732a3b3d8c1f12',
                type: 1,
                name: '2021-12-01-192635._source.720p29fps.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.720p29fps.mp4',
                updated: '2021-12-27T15:31:37.946Z',
                height: 480,
                width: 640,
                bitrate: 2961,
                duration: 11.645,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.720p29fps.webm',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 1103981,
                etag: 'fb4c43f2bc27f21c9fcd17d4dfab02fe',
                type: 1,
                name: '2021-12-01-192635._source.720p29fps.webm',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.720p29fps.webm',
                updated: '2021-12-27T15:32:47.035Z',
                height: 480,
                width: 640,
                bitrate: 758,
                duration: 11.648,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.720p30fps.flv',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 5469495,
                etag: '8c7108684fbbc75fc5806a66640031d8',
                type: 1,
                name: '2021-12-01-192635._source.720p30fps.flv',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.720p30fps.flv',
                updated: '2021-12-27T15:31:40.541Z',
                height: 480,
                width: 640,
                bitrate: 3740,
                duration: 11.7,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.auto1080p30fps.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 2379915,
                etag: 'a3b7492958337f1cdfa5122094eae1ee',
                type: 1,
                name: '2021-12-01-192635._source.auto1080p30fps.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.auto1080p30fps.mp4',
                updated: '2021-12-27T15:31:37.946Z',
                height: 480,
                width: 640,
                bitrate: 1637,
                duration: 11.634,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.auto720p29fps.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 2098656,
                etag: 'edd6c463804d641259225b52a5761138',
                type: 1,
                name: '2021-12-01-192635._source.auto720p29fps.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.auto720p29fps.mp4',
                updated: '2021-12-27T15:31:37.946Z',
                height: 480,
                width: 640,
                bitrate: 1442,
                duration: 11.645,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.auto720p30fps.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 2098398,
                etag: '5fe1427a87997235343e08f7cb179744',
                type: 1,
                name: '2021-12-01-192635._source.auto720p30fps.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.auto720p30fps.mp4',
                updated: '2021-12-27T15:31:37.948Z',
                height: 480,
                width: 640,
                bitrate: 1443,
                duration: 11.634,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.cc-hd-1920x1080.mp4',
                bucket: 'c.eltoro.com',
                mime: 'video/mp4',
                size: 13763864,
                etag: '62edc6d4353da009d6be6ef7307aee3a',
                type: 1,
                name: '2021-12-01-192635._source.cc-hd-1920x1080.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.cc-hd-1920x1080.mp4',
                updated: '2021-12-27T15:32:47.036Z',
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.cc-sd-720x486.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 2003421,
                etag: '5d36e968c03a30426559a992d46f1119',
                type: 1,
                name: '2021-12-01-192635._source.cc-sd-720x486.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.cc-sd-720x486.mp4',
                updated: '2021-12-27T15:31:37.948Z',
                height: 486,
                width: 720,
                bitrate: 1378,
                duration: 11.634,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.cc-sd-720x486.mpg',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 3477504,
                etag: 'c0e5b7ccdcd1db3ebb46d2a2d18e7202',
                type: 1,
                name: '2021-12-01-192635._source.cc-sd-720x486.mpg',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.cc-sd-720x486.mpg',
                updated: '2021-12-27T15:31:37.951Z',
                height: 486,
                width: 720,
                bitrate: 2387,
                duration: 11.65499,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.high-afps.flv',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 1984371,
                etag: '041fbe5ae666d9cb91d9aebf18f5c2e0',
                type: 1,
                name: '2021-12-01-192635._source.high-afps.flv',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.high-afps.flv',
                updated: '2021-12-27T15:31:40.542Z',
                height: 480,
                width: 640,
                bitrate: 1357,
                duration: 11.7,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.iphone.mp4',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 8857610,
                etag: '119aab28fea3901c5062d597e4116016',
                type: 1,
                name: '2021-12-01-192635._source.iphone.mp4',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.iphone.mp4',
                updated: '2021-12-27T15:31:40.542Z',
                height: 480,
                width: 640,
                bitrate: 6091,
                duration: 11.634,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.low-afps.flv',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 1803899,
                etag: '3d1679677ad89ee7febc932e2e31964c',
                type: 1,
                name: '2021-12-01-192635._source.low-afps.flv',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.low-afps.flv',
                updated: '2021-12-27T15:31:40.545Z',
                height: 480,
                width: 640,
                bitrate: 1233,
                duration: 11.7,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source360p30fps.webm',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 997217,
                etag: '38579f13737dd1e059c9551a00c2d718',
                type: 1,
                name: '2021-12-01-192635._source360p30fps.webm',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source360p30fps.webm',
                updated: '2021-12-27T15:32:47.037Z',
                height: 360,
                width: 480,
                bitrate: 686,
                duration: 11.636,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source720p30fps.webm',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 1957589,
                etag: 'aeb6845cbd82422a8c5ab7222259a95c',
                type: 1,
                name: '2021-12-01-192635._source720p30fps.webm',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source720p30fps.webm',
                updated: '2021-12-27T15:32:47.038Z',
                height: 480,
                width: 640,
                bitrate: 1346,
                duration: 11.636,
              },
              {
                key: 'o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._sourcempegURLafps.ts',
                bucket: 'c.eltoro.com',
                mime: 'application/octet-stream',
                size: 682440,
                etag: '5cf23c921109b877a2056674c3d1bf52',
                type: 1,
                name: '2021-12-01-192635._sourcempegURLafps.ts',
                url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._sourcempegURLafps.ts',
                updated: '2021-12-27T15:31:37.952Z',
                height: 288,
                width: 384,
                bitrate: 467,
                duration: 11.679777,
              },
            ],
            category: 'IAB24',
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:31:19.717Z',
            updated: '2021-12-27T15:36:41.096Z',
            status: 10,
            sentForMiscBilling: false,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'DbXMMLaJhfB72mYWj',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                targetType: 1,
                creativeType: 2,
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                name: 'video',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            thumb:
              'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqj2hplcpgareyr-2021-12-01-192635._source.320px240p15fps.mp4_thumb1.png',
            url: 'https://s3.amazonaws.com/c.eltoro.com/o/m/omeqJ2HpLcpGarEyR/omeqJ2HpLcpGarEyR-2021-12-01-192635._source.mp4',
            mime: 'video/mp4',
            size: 13763864,
            progress: 0.7,
            fileCount: 22,
            height: 486,
            width: 720,
            bitrate: 6091,
            duration: 11.634,
          },
        ],
        targets: [
          {
            _id: 'DTjbreo4giQY7nR23',
            orgId: 'h9X4JMCHirH2RDoRi',
            type: 12,
            conf: {
              targetType: 1,
            },
            name: 'sample2.csv',
            nameOrig: 'sample2.csv',
            files: [
              {
                _id: 'DTjbreo4giQY7nR23',
                url: 'https://s3.amazonaws.com/bucketuploads.eltoro.com/D/T/DTjbreo4giQY7nR23/DTjbreo4giQY7nR23-sample2._source.csv',
                type: 1,
                name: 'sample2.csv',
                size: 46,
                key: 'D/T/DTjbreo4giQY7nR23/DTjbreo4giQY7nR23-sample2._source.csv',
                bucket: 'bucketuploads.eltoro.com',
                mime: 'text/csv',
                updated: '2021-12-27T15:30:50.972Z',
              },
              {
                type: 2,
                name: 'sample2.csv (APPEND)',
                bucket: 'targetuploads.eltoro.com',
                key: 'dev/c74tocv9gm4g52ur1up0/quote/address/2021-12-27/c74toif9gm4g52ur1ut0-annotated.csv',
                url: 'https://s3.amazonaws.com/targetuploads.eltoro.com/dev/c74tocv9gm4g52ur1up0/quote/address/2021-12-27/c74toif9gm4g52ur1ut0-annotated.csv',
                updated: '2021-12-27T15:31:22.152Z',
                mime: 'text/csv',
              },
              {
                type: 3,
                name: 'sample2.csv (CLEANED)',
                bucket: 'bucketuploads.eltoro.com',
                key: 'D/T/DTjbreo4giQY7nR23/DTjbreo4giQY7nR23-2021-12-27-1530.csv',
                updated: '2021-12-27T15:30:55.680Z',
                mime: 'text/csv',
              },
              {
                type: 4,
                name: 'sample2.csv (ERRORS)',
                bucket: 'bucketuploads.eltoro.com',
                key: 'D/T/DTjbreo4giQY7nR23/DTjbreo4giQY7nR23-errors.csv',
                updated: '2021-12-27T15:30:55.680Z',
                mime: 'text/csv',
              },
            ],
            columns: [
              {
                srcKey: 'address_1',
                srcIndex: 0,
                srcField: 'address',
                payloadKey: 'a',
              },
              {
                srcKey: 'zip',
                srcIndex: 1,
                srcField: 'zip',
                payloadKey: 'z',
              },
            ],
            payload: [
              {
                bucketId: 'DTjbreo4giQY7nR23',
                _id: 'DTjbreo4giQY7nR23',
                src: ['address', 'zip'],
                h: true,
              },
              {
                bucketId: 'DTjbreo4giQY7nR23',
                _id: 'DTjbreo4giQY7nR23',
                src: ['"Acme', '123 N. Main St', 'suite"', '40202'],
              },
            ],
            userId: 'RBPxKcoybG93XpqoZ',
            created: '2021-12-27T15:30:51.015Z',
            updated: '2021-12-27T15:36:41.091Z',
            status: 90,
            org: {
              _id: 'h9X4JMCHirH2RDoRi',
              name: 'BeeWoo',
            },
            orgIdParents: ['eltoro'],
            orderLines: [
              {
                _id: 'DbXMMLaJhfB72mYWj',
                campaignId: 'HuRN8YYf8KNgdrr8H',
                targetType: 1,
                creativeType: 2,
                start: '2021-12-29T05:00:00.000Z',
                stop: '2022-01-01T04:59:59.999Z',
                name: 'video',
                status: 5,
                campaignName: 'test campaign',
              },
            ],
            lastUsed: '2022-01-01T04:59:59.999Z',
            archiveDate: '2022-01-31T04:59:59.999Z',
            progress: 1,
            quoteColumns: [
              {
                srcKey: 'address_1',
                srcIndex: 0,
                srcField: 'address',
                payloadKey: 'a',
              },
              {
                srcKey: 'zip',
                srcIndex: 1,
                srcField: 'zip',
                payloadKey: 'z',
              },
            ],
            review: {
              'total-lines': 1,
              duplicates: 0,
              apartments: 1,
              'special-chars': 1,
              'po-boxes': 0,
              'bad-zips': 0,
              'bad-addresses': 1,
              'remaining-lines': 1,
            },
            hasErrors: true,
            output: {
              quoteRequested: '2021-12-27T15:30:59.240Z',
              quoted: '2021-12-27T15:31:22.068Z',
              firstQuoted: '2021-12-27T15:31:22.068Z',
            },
            deploy: {
              replytoorig:
                'https://sqs.us-east-1.amazonaws.com/498598553520/portal-main-dev',
              quote_by: 'b2c search processing',
              quote_date: '2021-12-27T15:31:21Z',
              quote_append:
                'targetuploads.eltoro.com/dev/c74tocv9gm4g52ur1up0/quote/address/2021-12-27/c74toif9gm4g52ur1ut0-annotated.csv',
            },
            stats: {
              matched: 1,
              total: 1,
              rate: 1,
            },
            lastEtl: '2021-12-27T15:31:22.068Z',
          },
        ],
        clickThroughUrl: 'https://turtleco.com',
      },
    ],
  })

  const { status, value } = useAsync(() => getCampaignDetailsById(campaignId))

  useEffect(() => {
    if (value?.data) setCampaign(value.data)
  }, [value])

  useEffect(() => {
    if (Object.entries(campaign).length) {
      setReportCardData([
        {
          icon: clicks,
          title: campaign.clicks,
          subTitle: 'Clicks',
        },
        {
          icon: impressions,
          title: campaign.served_impressions,
          subTitle: 'Impressions',
        },
        {
          icon: ctr,
          title: `${campaign.ctr}%`,
          subTitle: 'CTR',
        },
      ])

      setLargeReportCardData([
        {
          icon: faHome,
          title: campaign.total_impressions,
          subTitle: 'Total Impressions',
          detail1: `Served: ${campaign.served_impressions} Impressions`,
          detail2: `Remaining: ${
            campaign.total_impressions - campaign.served_impressions
          } Impressions`,
          servedColor: '#0668c9',
          remainingColor: '#e4f1ff',
          // pieChartData: [
          //   { value: campaign.served_impressions, color: '#0668c9' },
          //   {
          //     value: campaign.total_impressions - campaign.served_impressions,
          //     color: '#e4f1ff',
          //   },
          // ],
          pieChartData: [
            { value: 100, color: '#0668c9' },
            {
              value: 250 - 100,
              color: '#e4f1ff',
            },
          ],
        },
        {
          icon: faUsdCircle,
          title: `$${campaign.total_rate}`,
          subTitle: 'Total Budget',
          detail1: `Spent: $${campaign.served_rate}`,
          detail2: `Remaining: $${campaign.total_rate - campaign.served_rate}`,
          servedColor: '#00ae26',
          remainingColor: '#e2fce8',
          pieChartData: [
            { value: campaign.served_rate, color: '#00ae26' },
            {
              value: campaign.total_rate - campaign.served_rate,
              color: '#e2fce8',
            },
          ],
        },
      ])
    }
  }, [campaign])

  // const data = [
  //   {
  //     id: 0,
  //     name: 'test',
  //     source: 'test',
  //     type: 'test',
  //     campaign_id: 123,
  //     prospects_counts: 5,
  //   },
  //   {
  //     id: 0,
  //     name: 'test',
  //     source: 'test',
  //     type: 'test',
  //     campaign_id: 123,
  //     prospects_counts: 5,
  //   },
  // ]

  return (
    <>
      {!value ? (
        <div style={{ height: 'calc(100vh - 132px)', display: 'flex' }}>
          <Loading />
        </div>
      ) : (
        <div className="CampaignView">
          <PageHeader
            UNSAFE_CLASSNAME="CampaignView__header"
            title={campaign?.name}
            // title=""
            subTitle=""
            actions={[
              // <Button
              // kind="primary"
              //   UNSAFE_style={{
              //     marginBottom: '0.5rem',
              //     background: '#D1D1D1',
              //     border: 'none',
              //     width: '231px',
              //     height: '45px',
              //   }}
              //   size="l"
              //   iconLeft={
              //     <>
              //       <img
              //         src={downloadicon}
              //         alt="uploadicon"
              //         style={{ marginRight: '-10px' }}
              //       />
              //     </>
              //   }
              //   weight="bold"
              // >
              //   Download PDF
              // </Button>,
              <Button
                kind="primary"
                UNSAFE_style={{
                  // background: '#D1D1D1',
                  border: 'none',
                  width: '231px',
                  height: '45px',
                }}
                // to="/create-campaign/setup"
                UNSAFE_className="CampaignView__downloadPdf"
                disabled
                iconLeft={
                  <>
                    <img
                      style={{ padding: '0 10px' }}
                      src={downloadicon}
                      alt="downloadicon"
                    />
                  </>
                }
              >
                Download PDF
              </Button>,
            ]}
          />
          {/* {!!campaign && status === 'success' && ( */}
          {!!campaign && status === 'success' && (
            // <Layout>
            <>
              {/* New Component */}
              {/* <Section
                UNSAFE_className_container="CampaignView__top_section"
                spacer={false}
                // noBackground
                dark
              > */}
              <FlexBox
                UNSAFE_style={{
                  background: 'white',
                  padding: '31px 30px',
                  borderRadius: 20,
                  margin: '15px 0',
                }}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                {/* <GridBox gridTemplateColumns="5" gap="0.5rem" flexGrow={1}> */}
                <div className="campaign-created">
                  <CampaignDetails campaign={campaign} />
                </div>
                {/* </GridBox> */}

                {/* <GridBox
                  gap="0.5rem"
                  // justifySelf="flex-start"
                  // alignItems="flex-start"
                  gridTemplateColumns="5"
                  flexGrow={1}
                > */}
                {/* <span
                  style={{
                    border: '1px solid #DEDEDE',
                    transform: 'rotate(90deg)',
                  }}
                /> */}
                <div className="campaign-save_audience">
                  <Text
                    UNSAFE_className="campaign-save-audience-heading"
                    on="grey-050"
                    UNSAFE_style={{
                      display: 'inline-block',
                      marginBottom: '0.8rem',
                    }}
                  >
                    Saved Audience
                  </Text>
                  {/* <hr className="divider" /> */}
                  <div
                    style={{
                      height: 308,
                    }}
                  >
                    {/* {campaign?.orderlines[0]?.targets && ( */}
                    {campaign?.orderlines[0]?.targets.length && (
                      <CompactSavedAudiences
                        audiences={campaign?.orderlines[0]?.targets}
                        targetIcon={target}
                        userIcon={person}
                      />
                    )}
                  </div>
                </div>
                {/* {campaign?.orderlines[0]?.targets.length && (
                    <CompactSavedAudiences
                      audiences={campaign?.orderlines[0]?.targets}
                    />
                  )} */}
              </FlexBox>

              <FlexBox
                UNSAFE_style={{
                  background: 'white',
                  padding: '31px 30px',
                  borderRadius: 20,
                  margin: '30px 0',
                }}
                flexDirection="column"
              >
                <Text
                  UNSAFE_className="CampaignView__reportHeading"
                  on="grey-050"
                >
                  Report
                </Text>
                <hr className="divider" />
                <div className="CampaignView__report">
                  {/* Small size report cards */}
                  {reportCardData.map((data: any) => (
                    <div className="report_card">
                      <div className="img_div">
                        <img src={data.icon} alt="clicks" />
                      </div>
                      <div className="title_div">
                        <Text on="grey-050" UNSAFE_className="title">
                          {data.title}
                        </Text>
                        <Text on="grey-050" UNSAFE_className="sub_title">
                          {data.subTitle}
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="CampaignView__report">
                  {/* Large size report cards */}
                  {largeReportCardData.map((data: any, index: number) => (
                    <div className="report_larg_card">
                      <div className="nested_div">
                        <div className="detail_div">
                          <Text on="grey-050" UNSAFE_className="title">
                            {data.title}
                          </Text>
                          <Text on="grey-050" UNSAFE_className="sub_title">
                            {data.subTitle}
                          </Text>
                        </div>
                        <div className="detail_div">
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '10px',
                            }}
                          >
                            <div
                              className="card_dot s_impressions_color"
                              style={{
                                backgroundColor: data.servedColor,
                              }}
                            />
                            <Text on="grey-050" UNSAFE_className="detail1">
                              {data.detail1}
                            </Text>
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              className="card_dot r_impressions_color"
                              style={{
                                backgroundColor: data.remainingColor,
                              }}
                            />

                            <Text on="grey-050" UNSAFE_className="detail2">
                              {data.detail2}
                            </Text>
                          </span>
                        </div>
                      </div>
                      <div style={{ flexGrow: 1.2, textAlign: 'center' }}>
                        <PieChart
                          data={data.pieChartData}
                          lineWidth={40}
                          labelPosition={0}
                          className="report_pie_chart"
                          // segmentsShift={(index) => 0.5}
                          paddingAngle={4}
                          label={() => (
                            <>
                              <img
                                src={totalimpression}
                                alt="totalimpression"
                              />
                            </>
                            // <FontAwesomeIcon
                            //   style={{ fontSize: '10px', width: '12px' }}
                            //   className="icon"
                            //   icon={data.icon}
                            //   size="1x"
                            // />
                          )}
                        />
                      </div>
                    </div>

                    // <div className="report_larg_card">
                    //   <div className="img_div">
                    //     <img src={data.icon} alt="clicks" />
                    //   </div>
                    //   <div className="title_div">
                    //     <Text on="grey-050" UNSAFE_className="title">
                    //       {data.title}
                    //     </Text>
                    //     <Text on="grey-050" UNSAFE_className="sub_title">
                    //       {data.subTitle}
                    //     </Text>
                    //   </div>
                    // </div>
                  ))}
                </div>
                {/* <div className="CampaignView__right-column">
                  <CampaignReport campaign={campaign} />
                </div> */}
              </FlexBox>
              {/* </Section> */}

              <FlexBox
                UNSAFE_style={{
                  background: 'white',
                  padding: '31px 30px',
                  borderRadius: 20,
                  margin: '15px 0',
                }}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text
                  size="xxl"
                  on="grey-050"
                  UNSAFE_style={{
                    display: 'inline-block',
                    marginBottom: '0.8rem',
                    fontWeight: 300,
                    fontSize: '26px',
                    lineHeight: '35px',
                    color: '#2B1F0A',
                  }}
                >
                  Creatives
                </Text>
                <hr className="divider" style={{ marginBottom: '2.5rem' }} />

                <div
                  style={{
                    width: '40%',
                    paddingRight: '1rem',
                    // borderRight: '1px solid #DEDEDE',
                    display: 'flex',
                    // flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    className="CampaignView__creatives_container"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Text
                        UNSAFE_className="card_title"
                        on="grey-050"
                        size="xl"
                      >
                        Summer Sale
                      </Text>
                      <Text UNSAFE_className="sub_title" on="grey-050">
                        Highest performing campaign creative
                      </Text>
                    </div>
                    <div
                      className=""
                      style={{ display: 'flex', marginTop: '2.5rem' }}
                    >
                      <div>
                        <img
                          style={{
                            marginRight: 21,
                            borderRadius: '6px',
                            height: '156px',
                            width: '178px',
                          }}
                          src={tempImg}
                          alt="..."
                        />
                      </div>
                      <div className="right_container">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              margin: '5px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              className="small_imgs"
                              src={clicks}
                              alt="..."
                            />
                            <Text UNSAFE_className="des" on="grey-050">
                              Click:{' '}
                              <span className="allCampaignCreatives">200</span>
                            </Text>
                          </span>
                          <span
                            style={{
                              margin: '5px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              className="small_imgs"
                              src={impressions}
                              alt="..."
                            />
                            <Text UNSAFE_className="des" on="grey-050">
                              Impression:{' '}
                              <span className="allCampaignCreatives">500</span>
                            </Text>
                          </span>
                          <span
                            style={{
                              margin: '5px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img className="small_imgs" src={ctr} alt="..." />
                            <Text UNSAFE_className="des" on="grey-050">
                              CTR:{' '}
                              <span className="allCampaignCreatives">2.5%</span>
                            </Text>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <CampaignDetails campaign={campaign} /> */}
                </div>

                <div
                  style={{
                    width: '60%',
                    paddingLeft: '1rem',
                  }}
                >
                  <div className="allCampaignCreatives_box">
                    <Text
                      UNSAFE_style={{ marginLeft: '1rem' }}
                      UNSAFE_className="allCampaignCreatives"
                      on="grey-050"
                    >
                      All Campaign Creatives
                    </Text>
                    {/* <Text
                      UNSAFE_className="allCampaignCreatives__viewall"
                      on="grey-050"
                    >
                      View All
                    </Text> */}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      maxHeight: 320,
                      overflow: 'auto',
                      padding: '0 5px',
                    }}
                  >
                    {campaign?.orderlines?.map((campaignDetails: any) => {
                      return campaignDetails?.creatives?.map(
                        (creative: any) => {
                          return (
                            <div className="CampaignView__creatives_container CampaignView__all_campaign_creatives">
                              <div>
                                {campaignDetails.creative_type === 'Banner' ? (
                                  <img
                                    style={{ marginRight: 21 }}
                                    src={creative?.url}
                                    alt="..."
                                    className="creative_img"
                                  />
                                ) : (
                                  <video
                                    className="creative_img"
                                    style={{ marginRight: 21 }}
                                  >
                                    <source
                                      src={creative?.url}
                                      type="video/mp4"
                                    />
                                    <track
                                      src="captions_en.vtt"
                                      kind="captions"
                                      label="english_captions"
                                    />
                                  </video>
                                )}
                              </div>
                              <div className="right_container">
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Text
                                    UNSAFE_className="card_title"
                                    on="grey-050"
                                    size="xl"
                                  >
                                    {creative.name.split('__')[1]}
                                    {/* Summer Sale */}
                                  </Text>
                                  <Text
                                    UNSAFE_className="sub_title_heading"
                                    on="grey-050"
                                  >
                                    {campaignDetails.creative_type ===
                                      'Banner' &&
                                    creative?.width &&
                                    creative?.height
                                      ? `${campaignDetails.creative_type} - ${creative.width}x${creative.height}`
                                      : campaignDetails.creative_type}
                                  </Text>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Text UNSAFE_className="des" on="grey-050">
                                    Click:{' '}
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'black',
                                      }}
                                    >
                                      200
                                    </span>
                                  </Text>
                                  <Text UNSAFE_className="des" on="grey-050">
                                    Impression:{' '}
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'black',
                                      }}
                                    >
                                      500
                                    </span>
                                  </Text>
                                  <Text UNSAFE_className="des" on="grey-050">
                                    CTR:{' '}
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'black',
                                      }}
                                    >
                                      2.5%
                                    </span>
                                  </Text>
                                </div>
                              </div>
                            </div>
                          )
                        },
                      )
                    })}
                  </div>
                </div>
              </FlexBox>

              {/* <div className="CampaignView__body">
                <div className="CampaignView__left-column">
                  <CampaignDetails campaign={campaign} />
                  <Spacer height=".15rem" />
                  {campaign?.orderlines[0]?.targets.length > 0 ? (
                    <Section
                      title="Saved Audience"
                      UNSAFE_className_text="section-campaign-details"
                      noBackground
                    >
                      {campaign?.orderlines[0]?.targets && (
                        <CompactSavedAudiences
                          audiences={campaign?.orderlines[0]?.targets}
                        />
                      )}
                    </Section>
                  ) : null}
                </div>

                <div className="CampaignView__right-column">
                  <CampaignReport campaign={campaign} />
                </div>
              </div> */}
              {/* </Layout> */}
            </>
          )}
        </div>
      )}
    </>
  )
}
