import { atom, atomFamily } from 'recoil'
import {
  DimensionsType,
  ImageType,
  PositionType,
  UnsplashPhotoType,
} from '../Typings'

export const creativeTypeAtom = atom<'banner' | 'directMail'>({
  key: 'creativeType',
  default: 'banner',
})

export const directMailSizeAtom = atom<string | undefined>({
  key: 'directmailSize',
  default: undefined,
})

export const backgroundPhotoAtom = atom<ImageType | null>({
  key: 'backgroundPhoto',
  default: null,
})

export const backgroundPhotoPositionAtom = atomFamily<
  PositionType | undefined,
  { image: string; artboardName: string }
>({
  key: 'backgroundPhotoPosition',
  default: undefined,
})

export const backgroundPhotoDimensionsAtom = atomFamily<
  DimensionsType | undefined,
  { image: string; artboardName: string }
>({
  key: 'backgroundPhotoDimensions',
  default: undefined,
})

export const loadingBackgroundPhotoAtom = atom({
  key: 'loadingBackgroundPhoto',
  default: false,
})

export const unsplashPhotosAtom = atom<UnsplashPhotoType[]>({
  key: 'unsplashPhotos',
  default: [],
})

export const recentColorsAtom = atom<string[]>({
  key: 'recentColors',
  default: [],
})
