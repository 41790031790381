import { Button, Modal } from '@eltoro-ui/components'
import {
  faCreditCardFront,
  faUser,
  faChevronLeft,
} from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect, useState } from 'react'

import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loading, Spinner } from 'Components'
import { VotingModal } from 'Components/VotingModal'
import { useHistory, useParams } from 'react-router-dom'
import { getContactDetailById } from 'Requests'
import CaretLeft from '../../../../assets/Images/CaretLeft.png'
import Usericon from '../../../../assets/Images/usericon.png'
import Usernumber from '../../../../assets/Images/usernumber.png'
import state from '../../../../assets/Images/state.png'
import Usermail from '../../../../assets/Images/usermail.png'
import UserZipcode from '../../../../assets/Images/userzipcode.png'
import Useraddress from '../../../../assets/Images/useraddress.png'
import ContactInfoIcon from '../../../../assets/Images/contactinformationedit.png'

import { ContactInformation } from '../ContactInformation/ContactInformation'
import './ContactDetail.scss'

export const ContactDetail = () => {
  const params = useParams()
  const { contactId } = useParams<{ contactId: string }>()
  const [contactInfo, setContactInfo] = useState<any>()
  const [editContactModal, setEditContactModal] = useState(false)
  const history = useHistory()
  useEffect(() => {
    getContactDetailById(contactId).then((res: any) => {
      setContactInfo(res.data)
    })
  }, [])

  return (
    <div className="screen-inline">
      <div className="contactDetail__head">
        <div className="contactDetail__headIcon">
          {/* <FontAwesomeIcon
            icon={faChevronLeft}
            color="gray"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.history.back()
            }}
          /> */}
          <button
            style={{ border: 'none', background: 'none', cursor: 'pointer' }}
            type="button"
            onClick={() => {
              window.history.back()
            }}
          >
            <img src={CaretLeft} alt="CaretLeft" />
          </button>
          <h3 className="edit_information_title">Contact Information</h3>
        </div>
        <Button
          UNSAFE_className="contactDetail__button"
          UNSAFE_style={{
            marginBottom: '0.5rem',
            background: '#D1D1D1',
            border: 'none',
            fontSize: '16px',
            padding: '23px 20px',
            fontWeight: '700',
            width: '165px',
            height: '44px',
          }}
          size="l"
          weight="bold"
          onClick={() => {
            setEditContactModal(true)
            // history.push(`/my-sphere/${contactId}/contact-details/contact-edit`)
          }}
        >
          Edit Contact
        </Button>
      </div>
      {editContactModal && (
        <Modal offClick={() => setEditContactModal(false)}>
          <VotingModal
            handleModel={() => setEditContactModal(false)}
            icons={ContactInfoIcon}
            title="Contact Information Edit"
            subTitle="Ability to edit the contact details to change any data required. You are unable to edit the contact at this time."
          />
        </Modal>
      )}

      {contactInfo ? (
        <div className="contactDetail__content">
          <ContactInformation
            icon={
              <>
                <img src={Usericon} alt="Usericon" style={{ width: '7%' }} />
              </>
            }
            title="Name"
            content={`${contactInfo?.first_name} ${contactInfo?.last_name}`}
            UNSAFE_CLASSNAME="contactDetail__contentBox"
          />
          <ContactInformation
            icon={
              <>
                <img src={Useraddress} alt="Usericon" style={{ width: '7%' }} />
              </>
            }
            title="Address"
            content={contactInfo?.address}
            UNSAFE_CLASSNAME="contactDetail__contentBox"
          />
          {/* <ContactInformation
            icon={Usercity}
            title="City"
            content={contactInfo?.city}
            UNSAFE_CLASSNAME="contactDetail__contentBox"
          /> */}

          <ContactInformation
            icon={
              <>
                <img src={UserZipcode} alt="Usericon" style={{ width: '8%' }} />
              </>
            }
            title="Zip Code"
            content={contactInfo?.zip}
            UNSAFE_CLASSNAME="contactDetail__contentBox"
          />
          <ContactInformation
            icon={
              <>
                <img
                  src={Usernumber}
                  alt="Usernumber"
                  style={{ width: '7%' }}
                />
              </>
            }
            title="Phone Number"
            content={contactInfo?.phone}
            UNSAFE_CLASSNAME="contactDetail__contentBox"
          />

          <ContactInformation
            icon={
              <>
                <img style={{ width: '8%' }} src={Usermail} alt="Usericon" />
              </>
            }
            title="Email"
            content={contactInfo?.email}
            UNSAFE_CLASSNAME="contactDetail__contentBox"
          />
          <ContactInformation
            icon={
              <>
                <img src={state} alt="State" style={{ width: '10%' }} />
              </>
            }
            title="State"
            content={contactInfo?.state}
            UNSAFE_CLASSNAME="contactDetail__contentBox"
          />
        </div>
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </div>
  )
}
