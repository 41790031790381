import React, { useState } from 'react'
import './LensSidebarHeader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCenterLens, setSidebarLens } from 'Redux/actions'
import {
  faBoxOpen,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/pro-regular-svg-icons'
import { createPortal } from 'react-dom'
import { TRootState } from 'types'

export const LensSidebarHeader = ({
  heading,
  subHeading,
  toggle,
  isToggle,
}: {
  heading: string
  subHeading?: string | JSX.Element
  toggle?: Boolean
  isToggle?: any
}) => {
  const dispatch = useDispatch()
  const { openSideBar } = useSelector(
    (state: TRootState) => state.centerLensReducer,
  )
  const [isToggleOpen, setIsToggleOpen] = useState(true)
  const [showHoverText, isShowHoverText] = useState(false)
  const toggleSidebar = () => {
    dispatch(setSidebarLens(!openSideBar))
  }
  return (
    <div className="LensSidebarHeader">
      <Link
        className="LensSidebarHeader__back-button"
        onClick={() => dispatch(setCenterLens(false))}
        to="/prospects"
      >
        <FontAwesomeIcon icon={faChevronLeft} /> Go Back
      </Link>

      {/* <FontAwesomeIcon
        icon={faBars}
        onClick={toggleSidebar}
        className="LensSidebarHeader__collapse"
      /> */}
      {/* <div className="LensSidebarHeader__divider" /> */}
      <div className="LensSidebarHeader__heading">{heading}</div>
      {subHeading && (
        <div style={{ display: 'flex', position: 'relative' }}>
          <div className="LensSidebarHeader__sub-heading">{subHeading}</div>
          {toggle && (
            <div>
              {/* {showHoverText && (
                <sup style={{ fontSize: '9px', textAlign: 'left' }}>
                  Show Inactive listings
                </sup>
              )} */}

              <FontAwesomeIcon
                style={{
                  marginRight: '1.5rem',
                  fontSize: '32px',
                  cursor: 'pointer',
                }}
                size="1x"
                color="#FFB708"
                onMouseEnter={() => isShowHoverText(true)}
                onMouseLeave={() => isShowHoverText(false)}
                icon={isToggleOpen ? faToggleOn : faToggleOff}
                onClick={async () => {
                  await setIsToggleOpen(!isToggleOpen)
                  isToggle(!isToggleOpen)
                }}
              />
            </div>
          )}
          {showHoverText && (
            <div className="LensSidebarHeader__InactiveTooltip">
              Show Inactive Listings
            </div>
          )}
        </div>
      )}
    </div>
  )
}
