import React, { useRef, useState, useEffect } from 'react'
import { Modal, Text } from '@eltoro-ui/components'
import { useDebouncedCallback } from '@eltoro-ui/hooks'
import { TLocation } from 'types'
import './MapSearch.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { getLocationSearchResults, toTitleCase } from 'Helpers'
import { getLocationOnSearch } from 'Requests/Request_Methods/prospectMethods'
import axios from 'axios'

export const MapSearch = ({
  onSelect,
}: {
  onSelect: (location: TLocation) => void
}) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className="MapSearch">
      <button
        type="button"
        className="MapSearch__button"
        onClick={() => setIsActive(!isActive)}
      >
        <FontAwesomeIcon icon={faSearch} />
        <span className="content">Search</span>
      </button>

      {isActive && (
        <MapSearchModal
          onSelect={onSelect}
          onClose={() => setIsActive(false)}
        />
      )}
    </div>
  )
}

export const MapSearchModal = ({
  onSelect,
  onClose,
}: {
  onSelect: (location: TLocation) => void
  onClose: () => void
}) => {
  const mounted = useRef(false)
  const [searchedText, setsearchedText] = useState('')

  // const [suggestions, setSuggestions] = useState<[TLocation] | []>([])
  const [suggestions, setSuggestions] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => inputRef.current?.focus(), [])

  const getLocationsDebounced = useDebouncedCallback(
    async (query: string, ourRequest: any) => {
      setLoading(true)
      const results = await getLocationOnSearch(query, ourRequest)
      // setSuggestions(results.result)
      setSuggestions(results)
      setLoading(false)
    },
    800,
  )

  useEffect(() => {
    const ourRequest = axios.CancelToken.source() // <-- 1st step
    // if (searchedText.length > 2) {
    if (searchedText.length) {
      getLocationsDebounced(searchedText, ourRequest)
    } else {
      setSuggestions([])
      setLoading(false)
    }
    return () => {
      ourRequest.cancel()
    }
  }, [searchedText])
  // const boldedText = (text: string) => {
  //   const texted = text.split(searchedText)
  //   return <>{text}</>
  // }
  // const RenderHTML = (props: { HTML }) => (
  //   <span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>
  // )

  const makeBold = (item: string) => {
    // if(keyword)
    // const res = keyword.toUpperCase()
    const re = new RegExp(searchedText, 'ig')
    // return item.replace(re, `<b>${searchedText.toUpperCase()}</b>`)
    return item.replace(re, `<b>${searchedText}</b>`)
  }

  return (
    <Modal positionFromTop="6rem" offClick={onClose}>
      <div className="MapSearch__model-content">
        <div className="MapSearch__search">
          <FontAwesomeIcon icon={faSearch} className="MapSearch__icon" />
          <input
            placeholder="Search for a location"
            ref={inputRef}
            className="MapSearch__input"
            type="text"
            value={searchedText}
            // style={{ textTransform: 'capitalize' }}
            onChange={(e) => {
              // const lower_case = e.target.value.toLocaleLowerCase()
              setsearchedText(toTitleCase(e.target.value))
            }}
            maxLength={20}
          />
        </div>
        {/* <Text on="white" kind="subdued" size="xs">
          Search by city, zip, etc.
        </Text> */}
        {loading && searchedText.length > 2 ? (
          <div
            style={{
              textAlign: 'center',
              margin: '2rem',
            }}
          >
            <div className="before-spinner" style={{ position: 'unset' }} />
          </div>
        ) : (
          !!suggestions &&
          suggestions.length > 0 && (
            <ul className="MapSearch__suggestions">
              {suggestions.map((suggestion: TLocation, index: number) => (
                <li key={suggestion.key + index}>
                  <button
                    className="MapSearch__suggestion-button"
                    type="button"
                    onClick={() => {
                      onSelect(suggestion)
                      onClose()
                    }}
                  >
                    <span
                      style={{ textTransform: 'capitalize' }}
                      id={`index${index}`}
                      dangerouslySetInnerHTML={{
                        __html: makeBold(suggestion.display_value),
                      }}
                    />
                  </button>
                </li>
              ))}
            </ul>
          )
        )}
      </div>
    </Modal>
  )
}
