import React, { useEffect, useState } from 'react'
import { LoginHeader, LoginFooter, Tooltip } from 'Components'
import { LableInputField } from 'Components/LableInputField'
/* eslint-disable */
import {
  Header,
  HeaderLogo,
  Button,
  Form,
  FormItem,
  TextInput,
  Text,
  Modal,
} from '@eltoro-ui/components'
import { useDispatch, useSelector } from 'react-redux'
import { useValidatedState, useForm } from '@eltoro-ui/hooks'
import { emailVerify, restEmailVerify } from 'Requests'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga'
import { faEnvelope, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { setUser } from 'Redux/actions'
import { Input } from '../PasswordSetup/components/Input'
import { ErrorMsg } from '../PasswordSetup/components/ErrorMsg'
import logo from '../../Images/Icon_color@2x.png'
import emailIcon from '../../Images/emailicon.png'
import Warning from '../../Images/warning.png'

import leftGroup from '../../assets/Images/leftgroup.svg'
import rightGroup from '../../assets/Images/rightgroup.svg'

import './CreateAcc.scss'
import logobefore from '../../assets/Images/honeycomb.svg'
import logoafter from '../../assets/Images/honeycomb_left.svg'
import cross from '../../assets/Images/cross.png'
import { text } from '@fortawesome/fontawesome-svg-core'
import toast from 'react-hot-toast'

export const CreateAcc = () => {
  const [email, setEmail, isValidEmail] = useValidatedState('', 'email')
  const [password, setPassword] = useState<string>('')
  const required = ['email']
  const { formData, isValidForm, touched } = useForm({
    email,
  })
  const dispatch = useDispatch()
  // const { user } = useSelector(state => state.userReducer)
  const { validForm, missingFields } = isValidForm([...required, isValidEmail])
  const location = useLocation()
  const [modalOpen, setModalOpen] = useState(false)
  const [isDisabale, setIsDisabled] = useState(true)
  const [existEmail, setExistEmail] = useState(false)
  const [error, setError] = useState(false)
  const [internetError, setInternetError] = useState(false)
  const [baseUrl, setBaseUrl] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [sendingEmailLoading, setSendingEmailLoading] = useState(false)

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      setBaseUrl(process.env.REACT_APP_URL)
    } else {
      setBaseUrl('http://localhost:3000')
    }
  }, [])
  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="User already exists"
          topAbsolute="-85px"
          rightAbsolute="-48px"
          padding="7px 5px"
        />
      </div>
    )
  }
  const ResetErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="User not found"
          topAbsolute="-85px"
          rightAbsolute="-31px"
          padding="7px 5px"
        />
      </div>
    )
  }

  function sendEmail() {
    setSendingEmailLoading(true)
    if (location.pathname === '/signup') {
      const body = {
        email,
        redirect_link: baseUrl,
      }

      emailVerify(body)
        .then((response: any) => {
          if (response.data.msg === 'Verification email sent!') {
            setExistEmail(true)
            setModalOpen(true)
            setSendingEmailLoading(false)
            setLoading(false)
          } else {
            setError(true)
            setSendingEmailLoading(false)
            setLoading(false)
          }
        })
        .catch((err: any) => {
          toast.error(err)
          setError(true)
          setSendingEmailLoading(false)
          setLoading(false)
        })
    } else {
      const body = {
        email,
        redirect_url: baseUrl,
      }
      restEmailVerify(body)
        .then((res: any) => {
          if (res.detail) {
            setError(true)
            setSendingEmailLoading(false)
            setLoading(false)
          } else {
            setExistEmail(true)
            setModalOpen(true)
            setSendingEmailLoading(false)
            setLoading(false)
          }
        })
        .catch((err) => {
          toast.error(err)
          setError(true)
          setSendingEmailLoading(false)
          setLoading(false)
        })
    }
  }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()
    const Email = email
    const body = {
      email,
      redirect_link: 'http://localhost:3000',
    }
    sendEmail()
  }
  const history = useHistory()

  const setPass = () => {
    history.push('/verify-email')
  }
  return (
    <div className="loginContainer">
      <LoginHeader />
      {/* login */}

      {/* Modal */}
      {modalOpen && (
        <Modal
          offClick={() => {
            setModalOpen(false)
          }}
        >
          <div
            style={{
              position: 'absolute',
              marginLeft: '27rem',
              marginTop: '1rem',
              zIndex: '999',
            }}
          >
            <img
              src={cross}
              onClick={() => {
                setModalOpen(false)
              }}
              alt="cross"
              role="presentation"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div
            className="deleteConfirmationModal"
            style={{
              width: '470px',
              height: '260px',
              grid: '0rem',
              gridGap: '0rem',
            }}
          >
            <img
              className="rightImage"
              src={rightGroup}
              alt="confirmleftcomb"
            />
            <Text
              UNSAFE_className="deleteConfirmationModal__heading"
              on="white"
              weight="bold"
              size="xl"
              textAlign="center"
            >
              Email Sent!
            </Text>
            <br />
            <div
              // style={{
              // maxWidth: '280px',
              // margin: '0 30px',
              // display: 'flex',
              // }}
              className="email_has_been_sent"
            >
              <Text on="white" weight="normal" size="m" textAlign="center">
                {location.pathname === '/email-verify' ? (
                  <p
                    style={{
                      color: '#6D6D6D',
                      fontFamily: 'Open Sans',
                      fontWeight: '400',
                    }}
                  >
                    An email has been sent to you providing instructions to
                    reset your password.
                  </p>
                ) : (
                  <p
                    style={{
                      color: '#6D6D6D',
                      fontFamily: 'Open Sans',
                      fontWeight: '400',
                    }}
                  >
                    You should expect an email from us within the next 5
                    minutes.
                  </p>
                )}
              </Text>
            </div>
            <div
              className={`loginResendMail ${
                sendingEmailLoading ? 'resetPassword__button_disabled' : ''
              }`}
              onClick={() => {
                // setPass()
                sendEmail()
              }}
              aria-hidden="true"
            >
              <p style={{ fontWeight: 'bold' }}>
                Didn&apos;t receive the email?
              </p>
            </div>
            <img className="leftImage" src={leftGroup} alt="lconfirmleftcomb" />
          </div>

          {/* <div className="OnboardingModal__welcome">
<div
className="OnboardingModal__welcomeHeader"
style={{ marginBottom: '0' }}
>
<div style={{ display: 'flex', justifyContent: 'right' }}>
<img
src={cross}
onClick={() => {
setModalOpen(false)
}}
alt="cross"
role="presentation"
style={{ cursor: 'pointer' }}
/>
</div>
<div style={{ textAlign: 'center' }}>
<Text on="white" weight="bold" size="xl" textAlign="center">
Email Sent!
</Text>
</div>
<br />
<div
// style={{
// maxWidth: '280px',
// margin: '0 30px',
// display: 'flex',
// }}
className="email_has_been_sent"
>
<Text on="white" weight="normal" size="m" textAlign="center">
{location.pathname === '/email-verify' ? (
<p
style={{
color: '#6D6D6D',
fontFamily: 'Open Sans',
fontWeight: '400',
}}
>
An email has been sent to you providing instructions to
reset your password.
</p>
) : (
<p
style={{
color: '#6D6D6D',
fontFamily: 'Open Sans',
fontWeight: '400',
}}
>
You should expect an email from us within the next 5
minutes.
</p>
)}
</Text>
</div>
</div>

<div
className={`loginResendMail ${
sendingEmailLoading ? 'resetPassword__button_disabled' : ''
}`}
onClick={() => {
// setPass()
sendEmail()
}}
aria-hidden="true"
>
<p style={{ fontWeight: 'bold' }}>
Didn&apos;t receive the email?
</p>
</div>
</div> */}
        </Modal>
      )}
      {/* End Modal */}
      <div className="loginPrefix">
        <div className="mainLogin">
          <img
            src={logobefore}
            alt="beewo-logo-back"
            className="before-login-bg"
            id="logoBefore"
          />
          <div className="forget_all_content">
            <div
              className="mainLogin__logo"
              style={{ marginTop: '27px', marginBottom: '2rem' }}
            >
              <img
                src={logo}
                alt="beewo-logo"
                style={{ height: '111px', objectFit: 'contain' }}
              />
            </div>
            {location.pathname === '/logout' && (
              <div className="Logout__content">
                <b>You’ve Successfully Logged Out</b>
              </div>
            )}
            {location.pathname === '/signup' ? (
              <div style={{ display: 'contents' }}>
                <Text weight="bold" size="xl" on="white">
                  Create Account
                </Text>
                <div style={{ textAlign: 'center', padding: '0.8rem 0 0 0' }}>
                  <p className="Create_account_discription">
                    Enter your email address and we&apos;ll send you a
                    confirmation link
                  </p>
                </div>
              </div>
            ) : (
              <div style={{ display: 'contents' }}>
                <Text weight="bold" size="xl" on="white">
                  Forgot Password?
                </Text>
                <div style={{ textAlign: 'center' }}>
                  <p
                    style={{
                      color: '#6D6D6D',
                      fontFamily: 'Open Sans',
                      fontWeight: '400',
                      margin: '8px 0',
                    }}
                  >
                    Enter your email to recieve a password reset.
                  </p>
                </div>
              </div>
            )}
            <br />
            <div className="Login__form">
              <form onSubmit={onSubmit}>
                <LableInputField
                  title="Email Address"
                  placeholder="Enter your email address"
                  type="email"
                  onChange={(e) => {
                    setLoading(false)
                    setError(false)
                    setEmail(e.target.value)
                  }}
                  isError={!isValidEmail}
                  iconLeft={
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={emailIcon}
                      alt="icon"
                    />
                  }
                  iconRight={
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={error ? Warning : ' '}
                      // alt="icon"
                    />
                  }
                />
                {/* <Input
type="text"
placeHolder="Email Address"
isError={error}
// Height="50px"
data_testid="email_address"
// wdt="350px"
className="Login__formInput"
onChange={(e) => {
setLoading(false)
setError(false)
setEmail(e.target.value)
}}
validIcon={
<FontAwesomeIcon
icon={faExclamationCircle}
style={{
fontSize: '23px',
margin: '13px 5px 0 12px',
}}
/>
}
/> */}

                {location.pathname === '/signup' && error ? (
                  <ErrorToolbar />
                ) : (
                  ''
                )}

                {location.pathname === '/email-verify' && error ? (
                  <ResetErrorToolbar />
                ) : (
                  ''
                )}

                {isValidEmail ? (
                  ''
                ) : (
                  <ErrorMsg
                    title="Invalid email"
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )}
                <br />
                <Button
                  type="submit"
                  kind="primary"
                  size="l"
                  UNSAFE_className="sendVerification"
                  // UNSAFE_style={{ borderRadius: '10px', padding: '26px 0' }}
                  disabled={!isValidEmail || !email || loading}
                  fonts="16px"
                  weight="bold"
                  width="100%"
                  data_testid="submit_btn_verification"
                  UNSAFE_style={{ justifyContent: 'center' }}
                  children={
                    location.pathname === '/signup'
                      ? 'Send Verification Email'
                      : 'Send Password Recovery'
                  }
                  loading={loading && sendingEmailLoading}
                />
                {location.pathname === '/signup' ? (
                  <div>
                    {' '}
                    <div className="crtAccOr">
                      <Text weight="bold" size="m" on="white">
                        Or
                      </Text>
                    </div>
                    <Button
                      UNSAFE_style={{
                        border: '1px solid #dadada',
                        borderRadius: '10px',
                        padding: '26px 0',
                      }}
                      type="button"
                      kind="default"
                      size="l"
                      weight="bold"
                      width="100%"
                      data_testid="submit_btn"
                      // UNSAFE_style={{ height: '64px' }}
                      onClick={() => history.push('/login')}
                    >
                      Sign In
                    </Button>
                  </div>
                ) : (
                  ''
                )}
                <div className="loginBottom">
                  <p>Having issues with your account? </p>
                  <p>
                    Contact{' '}
                    <Link
                      to="/login"
                      className="loginBottomHelp"
                      style={{ paddingRight: '4px', color: '#FFAB03' }}
                    >
                      help@beewo.com
                    </Link>
                    for support
                  </p>
                </div>

                {/* Go Back */}
                {location.pathname !== '/signup' ? (
                  <div style={{ position: 'relative' }}>
                    <div
                      className="onboardingBack"
                      onClick={() => {
                        history.goBack()
                      }}
                      style={{ marginTop: '3rem' }}
                      aria-hidden="true"
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        color="#FFAB03"
                        style={{ marginRight: '10px', marginTop: '18px' }}
                      />
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#FFAB03',
                        }}
                      >
                        Go Back
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </form>
            </div>
          </div>
        </div>
        <img
          src={logoafter}
          alt="beewo-logo-back"
          id="logoAfter"
          className="after-login-bg"
        />
      </div>
      {/* End login */}
      <LoginFooter loginStyle />
    </div>
  )
}
