import { useState } from 'react'
import { MapLocationType } from 'types'

export const useLocations = () => {
  const [locations, setLocations] = useState<any>([])

  const addLocation = (
    suggestedLocation: any,
    setcallPolygonFilters?: (bool: boolean) => void,
  ) => {
    setLocations((prevLocations: any[]) => {
      const newLocations = prevLocations.some(
        (location) => location.value === suggestedLocation.value,
      )
        ? prevLocations
        : [...prevLocations, suggestedLocation]

      return newLocations
    })
    if (setcallPolygonFilters) {
      setcallPolygonFilters(true)
    }
  }

  const removeLocation = (
    id: string,
    setremoveLocationBool?: (bool: boolean) => void,
  ) => {
    setLocations((prevLocations: any[]) =>
      prevLocations.filter((location) => location.value !== id),
    )
    if (setremoveLocationBool) {
      setremoveLocationBool(true)
    }
  }

  const clearLocation = () => setLocations([])

  const hasLocations = locations?.length > 0

  return {
    locations,
    addLocation,
    removeLocation,
    clearLocation,
    hasLocations,
  }
}
