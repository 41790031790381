import React from 'react'
import { Spacer, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWalking } from '@fortawesome/pro-solid-svg-icons'
import { AudienceType, TCompactSavedAudiences } from 'types'
import { Tags } from 'Pages/SavedAudiences/components'
import './CompactSavedAudiences.scss'
import { faMap } from '@fortawesome/free-solid-svg-icons'
import { logger } from 'Tools'
import uploadCSV from '../../assets/Images/Group286.png'
import target from '../../assets/Images/target.png'
import user from '../../assets/Images/user.png'

const handleAudienceHover = (audience: any) => {
  logger.log(audience)
}
function audienceIcon(audience: number, targetIcon?: any) {
  if (targetIcon) {
    return targetIcon
  }

  if (audience === 12) {
    return uploadCSV
  }
  if (audience === 21) {
    return target
  }
  return user
}

export const CompactSavedAudiences = ({
  audiences,
  targetIcon,
  userIcon,
}: {
  audiences: TCompactSavedAudiences
  targetIcon?: any
  userIcon?: any
}) => {
  // const audienceName = (audience) => {
  //   if (audience === 12) {
  //     return 'Upload CSV'
  //   }
  //   if (audience === 21) {
  //     return 'Saved Audience'
  //   }
  //   return 'User'
  // }
  return (
    <div className="CompactSavedAudiences">
      {audiences.map((audience) => {
        return audience.type === 12 || audience.type === 21 ? (
          <div
            key={audience._id}
            onMouseEnter={() => handleAudienceHover(audience)}
          >
            <div className="CompactSavedAudiences__audience-container">
              <div className="CompactSavedAudiences__image">
                <img
                  width="45px"
                  src={audienceIcon(audience.type, targetIcon)}
                  alt=""
                />
              </div>
              <div className="CompactSavedAudiences__body">
                <Text
                  on="white"
                  UNSAFE_className="CompactSavedAudiences__audience_headings"
                >
                  {audience?.beewo?.type}

                  {audience?.beewo?.name}
                </Text>
                <Text size="m" on="white" UNSAFE_style={{ color: '#515A5C' }}>
                  {audience?.beewo?.filename?.substring(
                    audience?.beewo?.filename.indexOf('__') + 2,
                  )}
                </Text>
                {/* <Tags tags={audience.tags} /> */}
              </div>
              <div className="CompactSavedAudiences__description">
                {/* {audience.id} - This is a audience ID to talk about */}
              </div>
              <div className="CompactSavedAudiences__border" />
              <div className="CompactSavedAudiences__prospects">
                <div style={{ paddingRight: '0.4rem' }}>
                  {userIcon ? (
                    <img
                      style={{
                        width: '21px',
                        // height: '28px',
                        paddingTop: '2px',
                      }}
                      src={userIcon}
                      alt=""
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faWalking}
                      style={{
                        width: '20px',
                        height: '28px',
                        color: '#6D6D6D',
                      }}
                    />
                  )}
                </div>
                <div className="CompactSavedAudiences__prospects-text">
                  <div className="CompactSavedAudiences__text-display">
                    <span className="CompactSavedAudiences__span">
                      {audience?.beewo?.prospects_counts}
                    </span>

                    <div className="CompactSavedAudiences__prospect-name">
                      prospects
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Spacer />
          </div>
        ) : (
          <></>
        )
      })}
    </div>
  )
}
