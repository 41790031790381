import React, { useState, useEffect } from 'react'
import {
  PageHeader,
  // TableSelectionWrapper,
  Loading,
  SearchBar,
  // Pagination,
  EmptyStatus,
  ReactTable,
  ReactTableSearchBar,
} from 'Components'
import { Button, Table, TextInput, Text } from '@eltoro-ui/components'
// import { useAsync } from '@eltoro-ui/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { useDispatch, useSelector } from 'react-redux'
// import { setSpinner } from 'Redux/actions'
// import {
//   faHouse,
//   faHeart,
//   faPlus,
//   faSearch,
//   faHome,
// } from '@fortawesome/pro-solid-svg-icons'
import {
  APIFeatureType,
  APIFeatureResponseType,
  ResponseType,
  TRootState,
} from 'types'
import { useSelector } from 'react-redux'

import { getListings, getMyListings } from 'Requests'
import './MyListings.scss'
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'

import { Link, useHistory } from 'react-router-dom'
import { getCamelCase } from 'Helpers'

import tickmark from '../../assets/Images/tickmark.png'
import Oval from '../../assets/Images/Oval.png'
import redOval from '../../assets/Images/redOval.png'
import soldstatus from '../../assets/Images/soldstatus.png'
import rightarrow from '../../assets/Images/rightarrow.png'
import pendingicon from '../../assets/Images/inreview.png'

export const MyListings = () => {
  // const [selections, setSelections] = useState<APIFeatureType[]>([])
  const [listings, setListings] = useState<APIFeatureType[]>([])
  const [search, setSearch] = useState<string>('')
  const [listingData, setListingData] = useState<any>()
  // const [pages, setPages] = useState<any>()
  // const [unselect, setUnselectAll] = useState(false)
  // const [isLoading, setIsLoading] = useState({ apiCallLoaded: true })
  // const [currentPage, setCurrentPage] = useState<any>(1)
  // const [sortedList, setSortedList] = useState({
  //   sort: null,
  //   path: null,
  // })
  // const {
  //   setGlobalFilter,
  //   state: { globalFilter },
  // }: any = useTable({ columns: null, data: null }, useFilters, useGlobalFilter)

  // const dispatch = useDispatch()
  const history = useHistory()
  const { setGlobalFilters } = useSelector(
    (state: TRootState) => state.setGlobalFilters,
  )
  const { globalFilters } = useSelector(
    (state: TRootState) => state.globalFilters,
  )
  const statusButtonStyle = (stausbadge: any) => {
    if (stausbadge === 'active') {
      return 'MyListings__status-badge_active'
    }
    if (stausbadge === 'closed') {
      return 'MyListings__status-badge_closed'
    }
    if (stausbadge === 'pending') {
      return 'MyListings__status-badge_pending'
    }
    if (stausbadge === 'sold') {
      return 'MyListings__status-badge_sold'
    }
    return 'MyListings__status-badge'
  }

  // useEffect(() => {
  //   if (!sortedList.path) {
  //     setIsLoading({ ...isLoading, apiCallLoaded: true })
  //   } else {
  //     dispatch(setSpinner(true))
  //   }
  //   getMyListings(
  //     currentPage,
  //     20,
  //     search,
  //     sortedList.path && sortedList.path,
  //     sortedList.sort && sortedList.sort,
  //   ).then((res: any) => {
  //     setListings(res.data.listings)
  //     setListingData(res.data)
  //     setPages({
  //       current_page: res?.data?.current_page,
  //       total_listing: res?.data?.total_listing,
  //       total_pages: res?.data?.total_pages,
  //     })
  //     if (!sortedList.path) {
  //       setIsLoading({ ...isLoading, apiCallLoaded: false })
  //     } else {
  //       dispatch(setSpinner(false))
  //     }
  //   })
  //   setUnselectAll(false)
  // }, [search, currentPage, sortedList])

  // const onSort = (path: any, sort: any) => {
  //   setSortedList({ sort, path })
  //   setCurrentPage(1)
  // }

  const getStatusComponent = (item: string) => {
    let img: null | string = null
    if (item === 'active') img = tickmark
    else if (item === 'closed') img = redOval
    else if (item === 'sold') img = soldstatus
    else if (item === 'pending') img = pendingicon

    return (
      <div className={`${statusButtonStyle(item)} MyListings__status-badge`}>
        {img && (
          <img style={{ marginRight: '1rem' }} src={img} alt="status_icon" />
        )}
        {item}
      </div>
    )
  }

  // ========== New Table Utilities
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false)

  const _fetchData = async () => {
    setFetchDataIsLoading(true)
    try {
      const res = await getMyListings(1, 1000, null, null)
      if (res.data?.listings?.length) {
        setListings(res.data?.listings)
      }
      setFetchDataIsLoading(false)
    } catch (err: any) {
      toast.error(err)
      setFetchDataIsLoading(false)
    }
  }

  useEffect(() => {
    if (!listings?.length) {
      _fetchData()
    }
  }, [listings])

  const onViewDetail = (item: any) => {
    return (
      <div className="">
        <FontAwesomeIcon
          onClick={() =>
            history.push({
              pathname: `/my-listings/${item.listingid}`,
            })
          }
          icon={faChevronRight}
          size="1x"
          color="gray"
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        />
      </div>
    )
  }

  const columns = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Address',
          accessor: 'street',
        },
        {
          Header: 'City',
          accessor: (item: any) => getCamelCase(item.city),
        },
        {
          Header: 'Zip',
          accessor: 'zip',
        },
        {
          Header: 'Current Price',
          accessor: (item: any) => `$${item.listing_price.toLocaleString()}`,
        },
        {
          Header: 'Size',
          accessor: (item: any) => (item.size ? `${item.size} SQFT` : '-'),
        },
        {
          Header: 'Bed',
          accessor: 'beds',
        },
        {
          Header: 'Bath',
          accessor: 'baths',
        },
        {
          Header: 'Year',
          accessor: 'year_built',
        },

        {
          Header: 'Status',
          accessor: (item: any) =>
            getStatusComponent(item.status.toLowerCase()),
        },
        {
          Header: ' ',
          accessor: onViewDetail,
        },
      ],
    },
  ]
  // ========== New Table Utilities

  const getList = () => {
    let component = <></>
    if (fetchDataIsLoading) {
      component = <Loading />
    } else if (!listings?.length) {
      component = (
        <EmptyStatus subHeading="You can try by creating new listing to get better audience." />
      )
    } else {
      component = (
        <ReactTable
          title="listing"
          data={listings}
          columns={columns}
          selectFeature={false}
        />
      )

      // component = (
      //   <TableSelectionWrapper
      //     selectionType="listing"
      //     selections={[]}
      //     actions={[
      //       <button type="button" className="Campaigns__select-action">
      //         <Text on="tertiary-300" weight="bold">
      //           Delete
      //         </Text>
      //       </button>,
      //     ]}
      //   >
      //     <Table
      //       tableHeaderClass="tableRow_mylisting"
      //       tableRowClass="tableRow_mylisting"
      //       noCheckbox
      //       rows={listings || []}
      //       columns={[
      //         {
      //           path: 'street',
      //           label: 'Address',
      //           width: '18%',
      //           alignment: 'left',
      //           onSort,
      //           // RowCell: (listing) => <b>{listing.street}</b>,
      //         },
      //         {
      //           path: 'city',
      //           label: 'City',
      //           alignment: 'left',
      //           onSort,
      //         },
      //         {
      //           path: 'zip',
      //           label: 'Zip',
      //           alignment: 'left',
      //           onSort,
      //         },
      //         // { path: 'sub_divison', label: 'Subdivision Name', width: '20%' },
      //         {
      //           path: 'listing_price',
      //           label: 'Current Price',
      //           alignment: 'left',
      //           onSort,
      //           // RowCell: (listing) => (
      //           //   <b>{`$${listing.listing_price.toLocaleString()}`}</b>
      //           // ),
      //         },
      //         {
      //           path: 'size',
      //           label: 'Size',
      //           alignment: 'left',
      //           onSort,
      //           RowCell: (listing) =>
      //             listing.size ? (
      //               <span className="tableRow_mylisting">
      //                 {listing.size} SQFT
      //               </span>
      //             ) : (
      //               ''
      //             ),
      //         },
      //         {
      //           path: 'beds',
      //           label: 'Bed',
      //           alignment: 'left',
      //           onSort,
      //         },
      //         {
      //           path: 'baths',
      //           label: 'Bath',
      //           alignment: 'left',
      //           onSort,
      //         },
      //         {
      //           path: 'year_built',
      //           label: 'Year',
      //           alignment: 'left',
      //           onSort,
      //         },
      //         {
      //           path: 'status',
      //           label: 'Status',
      //           alignment: 'left',
      //           onSort,
      //           RowCell: (listing) => (
      //             <div
      //               className={`${statusButtonStyle(
      //                 listing.status,
      //               )} MyListings__status-badge`}
      //             >
      //               {listing.status === 'Active' ||
      //               listing.status === 'ACTIVE' ? (
      //                 <img
      //                   style={{ marginRight: '1rem' }}
      //                   src={tickmark}
      //                   alt="tickmark"
      //                 />
      //               ) : (
      //                 ''
      //               )}
      //               {listing.status === 'Closed' && (
      //                 <img
      //                   style={{ marginRight: '1rem' }}
      //                   src={redOval}
      //                   alt="tickmark"
      //                 />
      //               )}
      //               {listing.status === 'Sold' && (
      //                 <img
      //                   style={{ marginRight: '1rem' }}
      //                   src={soldstatus}
      //                   alt="soldstatus"
      //                 />
      //               )}
      //               {listing.status === 'Pending' && (
      //                 <img
      //                   style={{ marginRight: '1rem' }}
      //                   src={pendingicon}
      //                   alt="tickmark"
      //                 />
      //               )}

      //               {listing.status}
      //             </div>
      //           ),
      //         },
      //         {
      //           path: '',
      //           alignment: 'left',

      //           RowCell: (listing) => (
      //             <Link to={`/my-listings/${listing.listingid}`}>
      //               {/* <FontAwesomeIcon icon={faChevronRight} color="green" /> */}
      //               <img src={rightarrow} alt="rightarrow" />
      //             </Link>
      //             // <Button to={`/listings/${listing.listingid}`}>View</Button>
      //           ),
      //         },
      //       ]}
      //       // onSelect={setSelections}
      //       unSelectAll={unselect}
      //     />
      //     {!!listings?.length && (
      //       <Pagination
      //         title="listings"
      //         loading={isLoading.apiCallLoaded}
      //         currentPage={pages?.current_page}
      //         totalItems={pages?.total_listing}
      //         totalPages={pages?.total_pages}
      //         onClickPrev={() => setCurrentPage(currentPage - 1)}
      //         onClickNext={() => setCurrentPage(currentPage + 1)}
      //       />
      //     )}
      //   </TableSelectionWrapper>
      // )
    }
    return component
  }

  const [value, setValue] = React.useState('')

  return (
    <div className="MyListings">
      <PageHeader
        actionsStyle="MyListings__pageHeader"
        UNSAFE_CLASSNAME="MyListings__header"
        style={{ height: '84px', background: 'var(--color-white)' }}
        // icon={<FontAwesomeIcon icon={faHouse} />}
        title=""
        subTitle={
          <span style={{ marginLeft: '5px' }} className="MyListings_counts">
            You have{' '}
            <b style={{ color: '#2B1F0A', marginLeft: '5px' }}>
              {listings?.length} Listings
            </b>
          </span>
        }
        actions={[
          // <TextInput
          //   value={search}
          //   onChange={(e) => setSearch(e.target.value)}
          //   icon={<FontAwesomeIcon icon={faSearch} />}
          // />,
          <ReactTableSearchBar
            globalFilter={globalFilters}
            setGlobalFilter={setGlobalFilters}
          />,

          // <SearchBar
          //   value={search}
          //   onChange={(e) => setSearch(e.target.value)}
          // />
          <Button
            kind="primary"
            size="l"
            weight="bold"
            // UNSAFE_style={{ width: '202px', height: '48px' }}
            UNSAFE_className="MyListings__AdvertButton"
            // UNSAFE_style={{ marginBottom: '0.5rem' }}
            onClick={() => {
              history.push('/prospects')
            }}
          >
            Advertise Your Listing
          </Button>,
          // <Button kind="primary" iconLeft={<FontAwesomeIcon icon={faPlus} />}>
          //   Add Listing
          // </Button>,
        ]}
      />
      {/* {&& <Loading />} */}
      {/* {!listings && (
        <div className="MyListings__empty">
          <FontAwesomeIcon icon={faHome} size="10x" />
          <Text on="grey-050">No listings to display.</Text>
        </div>
      )} */}

      {getList()}
    </div>
  )
}
