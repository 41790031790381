/* eslint-disable */
import React, { useState, useEffect, FC } from 'react'

import {
  Form,
  FormItem,
  Button,
  Select,
  RadioButtonGroup,
  RadioButton,
  Text,
  SelectOptionType,
} from '@eltoro-ui/components'
// import { useSelector } from 'react-redux'
import { updateTheUser, userDetail } from 'Requests'
import toast from 'react-hot-toast'
import { userRole } from 'Helpers'
import { useDispatch } from 'react-redux'
import { setUser } from 'Redux/actions'

import { ProfileRealEstateInfoType } from 'types'
import { useForm } from '@eltoro-ui/hooks'
import { Section } from 'Components'
import './RealEstateInfoSection.scss'
import { PersonalInput } from 'Components/PersonalInfoForm/component/PersonalInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { LableInputSelect } from 'Components/LableInputSelect'

type PersonalInfoSectionType = {
  user: ProfileRealEstateInfoType
}

export const RealEstateInfoSection: FC<PersonalInfoSectionType> = ({
  user,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const [estateInfo, setEstateInfo] = useState({
    territory: '',
    experience: '',
    work_status: '',
    work_representation: '',
  })

  const { isValidForm, touched } = useForm(estateInfo)
  const required = [
    'territory',
    'experience',
    'work_status',
    'work_representation',
  ]
  const { validForm, missingFields } = isValidForm([...required])

  // const { user } = useSelector((state) => state.userReducer)

  useEffect(() => {
    if (user) {
      const { territory, experience, work_status, work_representation } =
        user || {}

      setEstateInfo({
        ...estateInfo,
        territory,
        experience,
        work_status,
        work_representation,
      })
    }
  }, [user])

  const onSubmit = () => {
    setLoading(true)
    updateTheUser(estateInfo)
      .then(() => {
        const token = localStorage.getItem('beewo_token') || undefined

        userDetail(token).then((res) => {
          const _user = {
            user_role: userRole(res.data?.is_super),
            ...res.data,
          }
          dispatch(setUser(_user))
          toast.success('Save Successfully!')
          setLoading(false)
        })
      })
      .catch((err) => {
        toast.error(err.data)
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string | number | undefined) => {
    setEstateInfo({ ...estateInfo, [key]: value })
  }

  const isDisabled = () => {
    const { territory, experience, work_status, work_representation } =
      estateInfo
    if (territory && experience && work_status && work_representation) {
      return false
    }
    return true
  }

  return (
    <></>
    // <div className="RealEstateInfo">
    //   <div className="RealEstateInfo__header">
    //     <Text
    //       on="white"
    //       weight="bold"
    //       size="l"
    //       UNSAFE_className="RealEstateInfo__heading"
    //     >
    //       Real Estate Info
    //     </Text>

    //     {isDisabled() && (
    //       <FontAwesomeIcon
    //         area-hidd
    //         className="RealEstateInfo__error_icon"
    //         icon={faExclamationCircle}
    //       />
    //     )}
    //   </div>

    //   <Section spacer={false} dark>
    //     <div>
    //       <Form
    //         onSubmit={onSubmit}
    //         required={required}
    //         valid={validForm}
    //         touched={touched}
    //         missingFields={missingFields}
    //       >
    //         <div className="RealEstateInfo__field_container input_label">
    //           <div className="RealEstateInfo__field">
    //             <FormItem
    //               FontSize="14px"
    //               htmlFor="workingArea"
    //               label="Where is your primary real estate territory?"
    //               redLabel={!!estateInfo.territory}
    //               required
    //               Width="95%"
    //               Height="1rem"
    //             >
    //               <PersonalInput
    //                 type="text"
    //                 fontSize="16px"
    //                 placeHolder="e.g. city, state, zipcode"
    //                 value={estateInfo.territory}
    //                 wdt="100%"
    //                 border="1px solid #c6cbcd"
    //                 onChange={(e) => onChange('territory', e.target.value)}
    //                 isError={!estateInfo.territory}
    //               />
    //             </FormItem>
    //           </div>

    //           <div className="RealEstateInfo__field input_label">

    //             <FormItem
    //               FontSize="14px"
    //               htmlFor="experience"
    //               label="How long have you been in real estate?"
    //               Height="1rem"
    //               redLabel={!!estateInfo.experience}
    //               required
    //             >
    //               <Select
    //                 Height="38px"
    //                 placeholder="Select Time Frame"
    //                 maxHeight={200}
    //                 selectedOption={{ label: estateInfo.experience }}
    //                 onChange={(value: SelectOptionType | undefined) =>
    //                   onChange('experience', value?.label)
    //                 }
    //                 options={[
    //                   { label: '0-1 years' },
    //                   { label: '2-5 years' },
    //                   { label: '6-10 years' },
    //                   { label: '10+ years' },
    //                 ]}
    //               />
    //             </FormItem>
    //           </div>
    //           <div className="RealEstateInfo__last_clild" />
    //         </div>

    //         <div className="RealEstateInfo__field_container">
    //           <div className="RealEstateInfo__field radio_label">
    //             <FormItem
    //               htmlFor="agency"
    //               label="Do you work for a Real Estate agency, or are you self represented?"
    //               redLabel={!!estateInfo.work_representation}
    //               required
    //             >
    //               <RadioButtonGroup
    //                 name="work_representation"
    //                 valueSelected={estateInfo.work_representation}
    //                 onChange={(value) => {
    //                   onChange('work_representation', value)
    //                 }}
    //               >
    //                 <RadioButton
    //                   key={1}
    //                   value="agency"
    //                   label="Real Estate Agency"
    //                 />
    //                 <RadioButton
    //                   key={2}
    //                   value="self"
    //                   label="Self Represented"
    //                 />
    //               </RadioButtonGroup>
    //             </FormItem>
    //           </div>

    //           <div className="RealEstateInfo__field radio_label">
    //             <FormItem
    //               htmlFor="work_status"
    //               label="Do you work in the real estate industry full or part time?"
    //               redLabel={!!estateInfo.work_status}
    //               required
    //             >
    //               <RadioButtonGroup
    //                 name="work_status"
    //                 valueSelected={estateInfo.work_status}
    //                 onChange={(value) => {
    //                   onChange('work_status', value)
    //                 }}
    //               >
    //                 <RadioButton key={1} value="full-time" label="Full Time" />
    //                 <RadioButton key={2} value="part-time" label="Part Time" />
    //               </RadioButtonGroup>
    //             </FormItem>
    //           </div>
    //           <div className="RealEstateInfo__last_clild" />
    //         </div>

    //         <hr className="RealEstateInfo__divider" />

    //         <div className="RealEstateInfo__btn_container">
    //           <Button
    //             type="submit"
    //             kind="primary"
    //             UNSAFE_className="RealEstateInfo__save_button"
    //             disabled={isDisabled() || loading}
    //           >
    //             Save
    //           </Button>
    //         </div>
    //       </Form>
    //     </div>
    //   </Section>
    // </div>
  )
}
