import { Text } from '@eltoro-ui/components'
import { EmptyStatus, Loading } from 'Components'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setUnReadNotification } from 'Redux/actions'
import toast from 'react-hot-toast'
import {
  getUnReadNotificationsCounts,
  getUserNotifications,
  markAsReadSingleNotification,
  markAsReadSpecificTypeNotifications,
} from 'Requests'
import { TUnReadTotalCounts } from 'types'
import accountIcon from '../../assets/Images/accounts.png'
import membershipIcon from '../../assets/Images/membershipIcon.svg'
import campaignIcon from '../../assets/Images/notificationCampaign.png'
import systemIcon from '../../assets/Images/notificationSystem.png'
import accountIcon_gray from '../../assets/Images/accounts_gray.png'
import membershipIcon_gray from '../../assets/Images/membershipIcon_gray.png'
import campaignIcon_gray from '../../assets/Images/notificationCampaign_gray.png'
import systemIcon_gray from '../../assets/Images/notificationSystem_gray.png'
import bellicon from '../../assets/Images/bellicon.png'
import folder from '../../assets/Images/folder.png'
import campaign_color from '../../assets/Images/campaign.png'

import './NotificationsTooltip.scss'

export const NotificationsTooltip = () => {
  const [activeTab, setActiveTab] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoading1, setIsLoading1] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<any>([])
  const [counts, setCounts] = useState<TUnReadTotalCounts[]>([])
  const [nextPage, setNextPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(Infinity)
  const [pageChange, setPageChange] = useState<number>()

  useEffect(() => {
    let isCancelled = false

    setIsLoading(true)

    const _getUnReadNotificationsCounts = () => {
      getUnReadNotificationsCounts()
        .then((res) => {
          if (!isCancelled) {
            setCounts(res.data)

            _getNotifications(isCancelled)
          }
        })
        .catch((err) => {
          if (!isCancelled) {
            toast.error(err)
            setIsLoading(false)
          }
        })
    }

    if (counts.length > 0) {
      markAsReadSpecificTypeNotifications(activeTab, 1)
        .then(() => {
          if (!isCancelled) {
            _getUnReadNotificationsCounts()
          }
        })
        .catch((err) => {
          if (!isCancelled) {
            toast.error(err)
            setIsLoading(false)
          }
        })
    } else {
      _getUnReadNotificationsCounts()
    }

    return () => {
      isCancelled = true
    }
  }, [])

  useEffect(() => {
    let isCancelled = false
    setIsLoading1(true)

    const _getUnReadNotificationsCounts = () => {
      getUnReadNotificationsCounts()
        .then((res) => {
          if (!isCancelled) {
            setCounts(res.data)

            _getNotifications(isCancelled)
          }
        })
        .catch((err) => {
          if (!isCancelled) {
            toast.error(err)
            setIsLoading(false)
          }
        })
    }

    if (counts.length > 0) {
      markAsReadSpecificTypeNotifications(activeTab, 1)
        .then(() => {
          if (!isCancelled) {
            _getUnReadNotificationsCounts()
          }
        })
        .catch((err) => {
          if (!isCancelled) {
            toast.error(err)
            setIsLoading(false)
          }
        })
    } else {
      _getUnReadNotificationsCounts()
    }

    return () => {
      isCancelled = true
    }
  }, [pageChange])

  const dispatch = useDispatch()

  useEffect(() => {
    if (counts.length) {
      dispatch(setUnReadNotification(true))
    } else {
      dispatch(setUnReadNotification(false))
    }
  }, [counts])

  const _getNotifications = (isCancelled: boolean, isToolTip = false) => {
    getUserNotifications(isToolTip ? 1 : nextPage, 20, activeTab).then(
      (res) => {
        if (!isCancelled) {
          if (res.data) {
            if (!isToolTip) {
              setNextPage(res?.data?.current_page + 1)
              setLastPage(res?.data?.total_pages)
            }
            if (
              notifications.length > 0 &&
              res?.data?.current_page > 1 &&
              !isToolTip
            ) {
              setNotifications([...notifications, ...res.data?.notifications])
            } else {
              setNotifications(res.data?.notifications)
            }
            setTimeout(() => {
              setIsLoading(false)
              setIsLoading1(false)
            }, 1000)
          } else {
            setIsLoading(false)
            setIsLoading1(false)
          }
        }
      },
    )
  }

  const readSingleNotification = (id: string) => {
    setIsLoading(true)
    markAsReadSingleNotification([{ id, read: 1 }])
      .then(() => {
        // _getUnReadNotificationsCounts()
        _getNotifications(false, true)
        // setIsLoading(false)
      })
      .catch((err) => {
        toast.error(err)
        setIsLoading(false)
      })
  }

  // const tabOnChange = (src: string) => {
  //   setIsLoading(true)
  //   // setActiveTab(src)
  //   markAsReadSpecificTypeNotifications(src, 1)
  //     .then(() => {
  //       _getUnReadNotificationsCounts(src)
  //     })
  //     .catch((err) => {
  //       setIsLoading(false)
  //     })
  // }

  const getIcon = (src: string, disable = false) => {
    switch (src) {
      case '':
        return disable ? folder : folder
      case 'accounts':
        return disable ? accountIcon_gray : accountIcon
      case 'membership':
        return disable ? membershipIcon_gray : membershipIcon
      case 'system':
        return disable ? campaignIcon_gray : systemIcon
      default:
        return disable ? campaign_color : campaignIcon
    }
  }

  const msToTime = (ms: number) => {
    const seconds: any = (ms / 1000).toFixed(1)
    const minutes: any = (ms / (1000 * 60)).toFixed(1)
    const hours: any = (ms / (1000 * 60 * 60)).toFixed(1)
    const days: any = (ms / (1000 * 60 * 60 * 24)).toFixed(1)

    let result: string = ''

    if (seconds < 60) {
      result = `${Math.round(seconds)} seconds ago`
    } else if (minutes < 60) {
      result = `${Math.round(minutes)} minutes ago`
    } else if (hours < 24) {
      result = `${Math.round(hours)} hours ago`
    } else {
      result = `${Math.round(days)} days ago`
    }

    return result
  }

  const onScroll = (e: any) => {
    if (
      e.target.scrollHeight - Math.abs(e.target.scrollTop) ===
      e.target.clientHeight
    ) {
      if (!isLoading1 && nextPage <= lastPage) {
        setPageChange(nextPage + 1)
      }
    }
  }
  const getMs = (createdDate: string) => {
    const date: any = new Date(createdDate)
    const currentDate: any = new Date()
    const utcCurrent = currentDate.getTimezoneOffset()
    currentDate.setMinutes(currentDate.getMinutes() + utcCurrent)
    const ms = currentDate - date
    return msToTime(ms)
  }
  const getBody = () => {
    let component = <></>
    if (isLoading) {
      component = <Loading />
    } else if (
      notifications?.length === 0 ||
      ['membership', 'accounts', 'system'].includes(activeTab)
    ) {
      component = (
        <EmptyStatus
          icon={<img src={getIcon(activeTab, true)} alt="..." />}
          heading={
            activeTab === 'campaign' || !activeTab
              ? 'No notifications are found'
              : 'Coming Soon'
          }
        />
      )
    } else {
      component = (
        <div
          className="body_container"
          onScroll={onScroll}
          style={{ overflowY: 'scroll', height: '400px' }}
        >
          {notifications.map((notification: any, i: number) => {
            return (
              <ul
                key={i}
                className={`list_view ${!notification.read ? 'unread' : ''}`}
                onClick={() => {
                  readSingleNotification(notification.id)
                }}
                role="presentation"
              >
                <li
                  // onClick={() => readSingleNotification(notification.id)}
                  // role="presentation"
                  className="notification_title"
                >
                  <img src={getIcon(notification.source)} alt="" width="15px" />
                  <Text on="grey-050">{notification.title}</Text>
                </li>
                <li>
                  <Text on="grey-050" kind="subdued">
                    {getMs(notification.created_at)}
                  </Text>
                </li>
              </ul>
            )
          })}
          {isLoading1 === true && nextPage <= lastPage ? (
            <ul>
              <Loading style={{ minHeight: '0px' }} />
            </ul>
          ) : (
            ''
          )}
        </div>
      )
    }
    return component
  }

  const getCount = (src: string) => {
    const _count = counts.find(
      (count) => count?.source === src && count?.total,
    )?.total
    return _count || 0
  }

  return (
    <div className="notification_container_tooltip">
      <div className="nested_container">
        <div className="header_container">
          <ul className="header_titles">
            <li
              className={activeTab === '' ? 'border_bottom' : ''}
              role="presentation"
              onClick={() => {
                setActiveTab('')
                setNextPage(1)
              }} // tabOnChange('')
            >
              <Text on="white" weight="bold">
                All
              </Text>
              <span className={`count ${activeTab === '' && 'black_bg_color'}`}>
                {counts.reduce((accumulator, object) => {
                  return accumulator + object.total
                }, 0)}
              </span>
            </li>

            <li
              className={`notificationTitleIcon ${
                activeTab === 'membership' ? 'border_bottom' : ''
              }`}
              role="presentation"
              style={{ display: 'flex' }}
              onClick={() => {
                setActiveTab('membership')
                setNextPage(1)
              }} // tabOnChange('membership')}
            >
              {/* <FontAwesomeIcon icon={membership} /> */}
              <img
                src={membershipIcon}
                alt=""
                className="notificationTitleIcon__icon"
              />
              <Text on="white" weight="bold">
                membership
              </Text>
              <span
                className={`count ${
                  activeTab === 'membership' && 'black_bg_color'
                }`}
              >
                {getCount('membership')}
              </span>
            </li>

            <li
              className={`notificationTitleIcon ${
                activeTab === 'campaign' ? 'border_bottom' : ''
              }`}
              role="presentation"
              onClick={() => {
                setActiveTab('campaign')
                setNextPage(1)
              }} // tabOnChange('campaign')}
            >
              <img
                src={campaignIcon}
                alt=""
                className="notificationTitleIcon__icon"
              />

              <Text on="white" weight="bold">
                campaign
              </Text>
              <span
                className={`count ${
                  activeTab === 'campaign' && 'black_bg_color'
                }`}
              >
                {getCount('campaign')}
              </span>
            </li>

            <li
              className={`notificationTitleIcon ${
                activeTab === 'accounts' ? 'border_bottom' : ''
              }`}
              role="presentation"
              onClick={() => {
                setActiveTab('accounts')
                setNextPage(1)
              }} // tabOnChange('accounts')}
            >
              <img
                src={accountIcon}
                alt=""
                className="notificationTitleIcon__icon"
              />

              <Text on="white" weight="bold">
                accounts
              </Text>
              <span
                className={`count ${
                  activeTab === 'accounts' && 'black_bg_color'
                }`}
              >
                {getCount('accounts')}
              </span>
            </li>

            <li
              className={`notificationTitleIcon ${
                activeTab === 'system' ? 'border_bottom' : ''
              }`}
              role="presentation"
              onClick={() => {
                setActiveTab('system')
                setNextPage(1)
              }} // tabOnChange('system')}
            >
              <img
                src={systemIcon}
                alt=""
                className="notificationTitleIcon__icon"
              />

              <Text on="white" weight="bold">
                system
              </Text>
              <span
                className={`count ${
                  activeTab === 'system' && 'black_bg_color'
                }`}
              >
                {getCount('system')}
              </span>
            </li>
          </ul>
        </div>
        {getBody()}
      </div>
    </div>
  )
}
