// import { APIFeatureResponseType } from 'types'
import {
  ResponseType,
  APIFeatureResponseType,
  TAutoComplete,
  TPostAreaSearch,
  TPostFYP,
} from 'types'
// import axios from 'axios'
import requests from '../http'

export const getCanvassingData = async (
  location: string,
  radius: number,
): Promise<ResponseType<any>> => {
  const res = await requests.get(`prospects/canvas`)
  return res.data && res.data
}
export const getMoverData = async (
  location: string,
  radius: number,
): Promise<ResponseType<any>> => {
  const res = await requests.get(`prospects/canvas`)
  return res.data && res.data
}

// export const getLocationOnSearch = async (
//   value: string,
//   ourRequest?: any,
// ): Promise<TAutoComplete> => {
//   const res = await requests.get(
//     `/listings/auto-complete?q=${value}`,
//     {},
//     ourRequest || '',
//   )
//   return res.data && res.data
// }

export const getLocationOnSearch = async (
  value: string,
  ourRequest?: any,
): Promise<TAutoComplete> => {
  const res: any[any] = await fetch(
    `https://nominatim.openstreetmap.org/search?q=${value},USA&limit=100&format=json&addressdetails=1`,
  )
  const jsonResponse = res.json()
  const result = await jsonResponse

  let searchFormat: any = []
  if (result.length) {
    searchFormat = result.map((i: any) => {
      if (i?.address?.city) {
        return {
          display_value: i.display_name,
          key: 'city',
          value: i?.address?.city?.toLowerCase(),
          bounding_box: i?.boundingbox,
        }
      }
      if (i?.address?.postcode) {
        return {
          display_value: i.display_name,
          key: 'zip',
          value: i?.address?.postcode,
          bounding_box: i?.boundingbox,
        }
      }
      return null

      // return {
      //   display_value: i.display_name,
      //   key: 'city',
      //   // value: i.display_name,
      //   value: getValue(),
      //   // value: i.display_name.split(',')[0].toLowerCase(),
      //   // value: i.display_name.split(',')[0].split(' ')[0].toLowerCase(),
      // }
    })
  }

  searchFormat = searchFormat.filter((i: any) => i !== null)
  return searchFormat
}

export const postAreaSearch = async (
  body: TPostAreaSearch,
): Promise<ResponseType<APIFeatureResponseType>> => {
  const req = '/listings/area-search/listining'
  return requests.post(req, body)
}

export const postFYP = async (
  body: TPostFYP,
): Promise<ResponseType<APIFeatureResponseType>> => {
  const req = '/listings/fyp/listining'
  return requests.post(req, body, false, body.ourRequest)
}
// export const getLocationSearchResults = async (query: string) => {
//   const url = `https://nominatim.openstreetmap.org/search?countrycodes=us&format=json&q=Orlando,florida`
//   return fetch(encodeURI(url))
//     .then((res) => res.json())
//     .then((json) => openStreepMapResolver(json))
// }
