import React, { useState, useEffect, useRef } from 'react'
import './ExpandYourMarketing.scss'
import {
  MapMarker,
  SaveAudienceWizard,
  AudienceResultsCard,
  AudienceResultsCount,
  LocationLabels,
} from 'Pages/ProspectActivity/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons'
import {
  Button,
  MaxHeightContainer,
  Spacer,
  Table,
  Text,
} from '@eltoro-ui/components'
import { APIFeatureType, TRealtorType, TRootState } from 'types'
import { faWalking } from '@fortawesome/free-solid-svg-icons'
import {
  BuzzScoreFilters,
  filterFeaturesByBuzz,
  MapSearch,
  Map,
  MapPopup,
  MapToolTip,
  MapSearchModal,
  Realtor,
  BuzzScore,
} from 'Components'
import { useLocations } from 'Hooks'
import { getMapListings, getRealtorActivity } from 'Requests'
import { browserLog } from 'Tools'
import {
  getIconBuzzColor,
  getLocationSearchResults,
  getLocationSearchResultsOSMId,
} from 'Helpers'
import toast from 'react-hot-toast'

import { postAreaSearch } from 'Requests/Request_Methods/prospectMethods'
import { setSpinner, setSidebarLens } from 'Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { buzzFilters } from '../../configs'
import {
  LensSidebar,
  LensSidebarHeader,
  LensSidebarFooter,
  LensSectionHeading,
  LensFilterContainer,
} from '../components'
import buzzScoreImg from '../../../../assets/Images/buzz_score.png'

export const ExpandYourMarketing = () => {
  const {
    locations,
    addLocation,
    removeLocation,
    hasLocations,
    clearLocation,
  } = useLocations()
  const dispatch = useDispatch()

  const [features, setFeatures] = useState<APIFeatureType[]>([])
  const [removeLocationBool, setremoveLocationBool] = useState(false)
  const [activeBuzzFilters, setActiveBuzzFilters] = useState(buzzFilters)
  const [isActiveAnother, setisActiveAnother] = useState(false)
  const [isSubmitAreas, setisSubmitAreas] = useState(false)

  const [toggle, setToggle] = useState(true)

  const [buzzExcluded, setbuzzExcluded] = useState<string[]>([])

  const [listingArea, setlistingArea] = useState<any[]>([])
  const [buttonClicked, setbuttonClicked] = useState(false)

  const [buttonDisabled, setbuttonDisabled] = useState(false)
  const [selectedListings, setSelectedListings] = useState<any>([])
  const [callPolygonFilters, setcallPolygonFilters] = useState<any>(false)
  const [dataObj, setdataObj] = useState<any>()
  const [activeToggleFeature, setActiveToggleFeature] =
    useState<string>('buzz_filter')

  const [realtorFilters, setRealtorFilters] = useState({
    page: 1,
    sort: 'active_listings',
    order: 'desc',
  })

  const [realtorData, setRealtorData] = useState([])

  useEffect(() => {
    if (locations.length) {
      const bodySchema = {
        key: 'zip',
        value: '',
        size: 5,
        ...realtorFilters,
      }
      const body: any = []

      locations.map(
        (location: { key: string; value: string; display_value: string }) => {
          return body.push({ ...bodySchema, value: location.value })
        },
      )

      getRealtorActivity({ search: body })
        .then((res: any) => {
          if (res?.detail) {
            toast.success(res?.detail?.msg)
          }
          setRealtorData(res?.data?.result)
        })
        .catch((err) => toast.error(err))
    }
  }, [locations, realtorFilters])

  const onPageChange = (page: number) => {
    setRealtorFilters({ ...realtorFilters, page })
  }
  const onSort = () => {
    const order = realtorFilters.order === 'desc' ? 'asc' : 'desc'
    setRealtorFilters({ ...realtorFilters, order, page: 1 })
  }
  // ========================

  const [firstClicked, setfirstClicked] = useState(false)
  const [loader, setloader] = useState(false)
  // useEffect(() => {}, [buzzExcluded, locations])
  const [refreshState, setrefreshState] = useState(false)
  const [buzzAvg, setbuzzAvg] = useState<any>([])
  useEffect(() => {
    // if (buzzExcluded) {
    apiCall()
    // }
  }, [buzzExcluded.length])

  useEffect(() => {
    if (removeLocationBool) {
      setcallPolygonFilters(true)
      apiCall()
    }
  }, [locations])
  const previousValues = useRef({ locations })

  useEffect(() => {
    if (firstClicked) {
      apiCall()
    }
  }, [firstClicked])
  const [first, setfirst] = useState(false)

  const resetStates = () => {
    clearLocation()
    setbuzzAvg([])
    setlistingArea([])
    setSelectedListings([])
  }
  const apiCall = () => {
    if (first || firstClicked) {
      setfirst(true)
      setbuttonDisabled(true)
      setloader(true)
      setdataObj({
        exclude: buzzExcluded,
        search: locations,
      })

      postAreaSearch({
        exclude: buzzExcluded,
        search: locations,
      })
        .then((res: any) => {
          const lisitingsData = []
          const buzzData = []
          const listingCounts = { count: 0 }
          if (res?.data?.result?.length > 0) {
            for (let i = 0; i < res?.data?.result?.length; i += 1) {
              listingCounts.count += res?.data?.result[i].listings.length
              lisitingsData.push(...res?.data?.result[i].listings)
              buzzData.push(res?.data?.result[i].avg)
              if (res.data.result.length - 1 === i) {
                setbuzzAvg(buzzData)
                setlistingArea(lisitingsData)
                setrefreshState(true)
                setSelectedListings(listingCounts.count)
                setcallPolygonFilters(true)
              }
            }
          } else {
            setcallPolygonFilters(false)
            dispatch(setSpinner(false))

            setbuzzAvg([])
            setrefreshState(true)
            setlistingArea([])
          }

          setfirstClicked(false)
          // setList(res.data.listings)
          // setListingData(res.data)
          // setIsLoading(true)
          // dispatch(setRefreshList(res.data.listings))
        })
        .catch((err: any) => {
          setbuttonDisabled(false)
          toast.error(err)
        })
        .finally(() => {
          setbuttonClicked(false)
          setisSubmitAreas(false)
          setloader(false)
          setremoveLocationBool(false)
          if (openSideBar) dispatch(setSidebarLens(false))
          if (!locations.length) dispatch(setSidebarLens(true))
        })
    }
  }

  const filteredFeatures =
    activeBuzzFilters.length < buzzFilters.length
      ? filterFeaturesByBuzz(features, activeBuzzFilters)
      : features
  const totalProspects = listingArea.reduce(
    (count, currFeature) => count + currFeature.unique_visitor_count,
    0,
  )

  const AllBuzz: any = []

  useEffect(() => {
    activeBuzzFilters.forEach((item) => AllBuzz.push(item.label))
    const Buzz = ['High buzz', 'Warm buzz', 'Medium buzz', 'Low buzz']
    const data = Buzz.filter(function (v: any) {
      return !AllBuzz.includes(v)
    })
    setbuzzExcluded(data)
  }, [activeBuzzFilters])
  const [polygonStates, setpolygonStates] = useState<any>([])
  // useEffect(() => {
  //   if (locations && locations.length > 0) {
  //     // if (refreshState) {
  //     const polygonData: any = []
  //     for (let i = 0; i < locations.length; i += 1) {
  //       getLocationSearchResults(locations[i].value)
  //         .then((resp: any) => {
  //           getLocationSearchResultsOSMId(resp.results[0].osm_id)
  //             .then((res) => {
  //               // data = [...data, res]
  //               polygonData.push(res[0].geojson)
  //             })
  //             .catch((err) => {
  //             })
  //             .finally(() => {
  //               if (i === locations.length - 1) {
  //                 setpolygonStates(polygonData)
  //               }
  //             })
  //         })
  //         .catch((err: any) => {
  //         })
  //     }
  //   }
  // }, [locations])
  const [updatedList, setupdatedList] = useState(false)

  const { openSideBar } = useSelector(
    (state: TRootState) => state.centerLensReducer,
  )

  const toggleSidebar = () => {
    dispatch(setSidebarLens(!openSideBar))
  }

  return (
    <div className="ExpandYourMarketing">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Grow Your Sphere"
              subHeading="View all houses for sale and the prospects seen in them."
            />
          }
        >
          <div className="LensSidebarHeader__heading LensSidebarHeader__heading__sub">
            Search a Location
          </div>
          <div className="LensSidebarHeader__sub-heading LensSidebarHeader__sub-heading__sub">
            e.g. City, Zip Code
          </div>

          {isActiveAnother && (
            <MapSearchModal
              onSelect={(suggestion) =>
                addLocation(suggestion, setcallPolygonFilters)
              }
              onClose={() => setisActiveAnother(false)}
            />
          )}
          <div className="ExpandYourMarketing__sidebar-content">
            {locations.length < 1 && (
              <MapSearch
                onSelect={(loc) => {
                  setbuttonDisabled(false)
                  addLocation(loc, setcallPolygonFilters)
                  setrefreshState(true)
                }}
              />
            )}

            {hasLocations && (
              <>
                <LocationLabels
                  locations={locations}
                  onRemove={(id) => {
                    removeLocation(id, setremoveLocationBool)

                    // if (locations.length > 0) {
                    setisSubmitAreas(true)
                    setupdatedList(true)
                    // apiCall()
                    // }
                  }}
                />
              </>
            )}
            <Spacer height="1rem" />
            {locations.length > 0 && locations.length < 5 && (
              <>
                <Button
                  UNSAFE_style={{
                    color: '#007B94',
                    border: '2px solid #007B94',
                    fontWeight: 'bold',
                    fontFamily: 'Helvetica',
                    fontSize: 'medium',
                  }}
                  UNSAFE_className="MyListingsLens__FooterBtn"
                  kind="default"
                  size="l"
                  width="100%"
                  onClick={() => {
                    setisActiveAnother(true)
                    setbuttonDisabled(false)
                  }}
                >
                  Add Another Listing
                </Button>
                <Spacer height="1rem" />
              </>
            )}
            <Button
              UNSAFE_style={{
                backgroundColor: '#007b94',
                color: '#F8F8F8',
                border: '2px solid #007B94',
                fontWeight: 'bold',
                fontFamily: 'Helvetica',
                fontSize: 'medium',
              }}
              UNSAFE_className="MyListingsLens__FooterBtn"
              kind="default"
              size="l"
              width="100%"
              onClick={() => {
                setisSubmitAreas(true)
                setbuttonClicked(true)
                setfirstClicked(true)
                // setisActiveAnother(true)
                // apiCall()
              }}
              disabled={locations.length === 0 || buttonDisabled}
            >
              {locations?.length === 0
                ? 'Submit'
                : `Find Listings in ${locations?.length} ${
                    locations?.length > 1 ? 'Areas' : 'Area'
                  }`}
            </Button>
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      {locations.length === 0 ||
        (buttonDisabled && (
          <div className={`${!openSideBar ? '' : 'MyListingsLens__hide'}`}>
            <div
              className="ExpandYourMarketing__rightTop"
              style={{ display: 'flex' }}
            >
              <Button
                UNSAFE_className="ExpandYourMarketing__rightTopButton"
                UNSAFE_style={{
                  background:
                    activeToggleFeature === 'buzz_filter' ? '#FFB708' : '',
                  color: activeToggleFeature === 'buzz_filter' ? 'white' : '',
                }}
                onClick={() => {
                  setActiveToggleFeature('buzz_filter')
                }}
              >
                Buzz Filter
              </Button>
              <Button
                UNSAFE_className="ExpandYourMarketing__rightTopButton"
                UNSAFE_style={{
                  background:
                    activeToggleFeature === 'realtor_filter' ? '#FFB708' : '',
                  color:
                    activeToggleFeature === 'realtor_filter' ? 'white' : '',
                }}
                onClick={() => {
                  setActiveToggleFeature('realtor_filter')
                }}
              >
                Realtor
              </Button>
            </div>
            <div className="MyListingsLens__filter">
              {activeToggleFeature === 'realtor_filter' ? (
                <div className="realtor_container">
                  {realtorData.map((realtor: TRealtorType, index) => (
                    <Realtor
                      key={index}
                      subTitle="Top brokerages"
                      title={realtor.value}
                      onPageChange={onPageChange}
                      currentPage={realtor.current_page}
                      totalPages={realtor.total_pages}
                      isDataFound={!!realtor?.data?.length}
                    >
                      {!realtor?.data.length ? (
                        <div style={{ textAlign: 'center' }}>
                          <Text on="grey-050" kind="subdued">
                            No Data Found of {realtor.value}
                          </Text>
                        </div>
                      ) : (
                        <Table
                          rows={realtor?.data}
                          columns={[
                            {
                              path: 'rank',
                              label: 'RANK',
                              alignment: 'center',
                              RowCell: (listing) => (
                                <b className="table_cell_content">
                                  {index + 1}
                                </b>
                              ),
                            },
                            {
                              path: 'mls_id',
                              label: 'BROKERAGE',
                              alignment: 'left',
                              RowCell: (listing) => <b>{listing.mls_id}</b>,
                            },
                            {
                              path: 'active_listings',
                              label: 'ACTIVE LISTINGS',
                              alignment: 'center',
                              onSort,
                            },
                          ]}
                        />
                      )}
                    </Realtor>
                  ))}
                </div>
              ) : (
                <LensFilterContainer>
                  <BuzzScoreFilters
                    filters={buzzFilters}
                    activeFilters={activeBuzzFilters}
                    onChange={setActiveBuzzFilters}
                  />
                  <div className="buzzScoreImg__container">
                    {/* <BuzzScore /> */}
                    {/* <img
                      src={buzzScoreImg}
                      className="buzzScoreImg__containerInside"
                      alt="..."
                    /> */}
                  </div>
                </LensFilterContainer>
              )}
            </div>
          </div>
        ))}

      {locations.length === 0 ||
        // && totalProspects
        (!loader && buttonDisabled && (
          <div className="MyListingsLens__audienceCenter">
            <div className="MyListingsLens__border_radius">
              <LensSidebarFooter>
                <AudienceResultsCard>
                  <div className="MyListingsLens__score">
                    <div className="MyListingsLens__scoreLeft">
                      <p>{totalProspects.toLocaleString()}</p>
                    </div>
                    <div className="MyListingsLens__scoreRight">
                      {/* <b>Results Prospects</b> */}
                      <b>Prospects</b>
                      <br />
                      have visited <b>{locations.length}</b>
                      {/* <b> listings</b> */}
                      <b>{locations.length > 1 ? ' locations' : ' location'}</b>
                    </div>
                  </div>
                  <Spacer />
                  <SaveAudienceWizard
                    type="Expand Your Marketing"
                    dataObj={dataObj}
                    onSaveAndClose={(audienceDetails) => {
                      // browserLog.info(audienceDetails)
                      resetStates()
                    }}
                    totalProspects={totalProspects}
                    listing={selectedListings}
                  />
                </AudienceResultsCard>
              </LensSidebarFooter>
            </div>
          </div>
        ))}

      {/* buzz score toggle */}
      <div
        style={{
          position: 'absolute',
          right: '50px',
          zIndex: 999,
          bottom: '44px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p style={{ fontSize: '10px', marginRight: '5px' }}>Show Filters</p>
        <FontAwesomeIcon
          style={{ marginRight: '1.5rem', fontSize: '32px' }}
          size="1x"
          color={!openSideBar ? '#FFB708' : '#FF0000'}
          icon={!openSideBar ? faToggleOn : faToggleOff}
          onClick={toggleSidebar}
        />
      </div>
      {/* buzz score toggle */}

      <div className="ExpandYourMarketing__map">
        <Map
          features={listingArea}
          buzzAvg={buzzAvg}
          firstClicked={first}
          locationsArea={locations}
          callPolygonFilters={callPolygonFilters}
          setcallPolygonFilters={setcallPolygonFilters}
          locations={[]}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          popup={(f) => <MapPopup feature={f} />}
          refreshState={refreshState}
          setrefreshState={setrefreshState}
          updatedList={updatedList}
          setupdatedList={setupdatedList}
          tooltip={(f) => <MapToolTip feature={f} />}
          circle={listingArea[Math.ceil(listingArea.length / 2)]}
          type="Expand Your Marketing"
          // polygonStates={polygonStates}
        />
      </div>
    </div>
  )
}
