// export * from './MyContacts'
import React from 'react'
import { Link, useParams, Route, Switch } from 'react-router-dom'
import { ContactDetail } from './components/ContactDetail/ContactDetail'
import { ContactEdit } from './components/ContactEdit/ContactEdit'
import { MyContactsPage } from './MyContacts'

export const MyContacts = () => {
  const params = useParams()

  const { contactId } = useParams<{ contactId: string }>()

  return (
    <Switch>
      <Route exact path="/my-sphere">
        <MyContactsPage />
      </Route>
      <Route exact path="/my-sphere/:contactId/contact-details">
        {/* <div>contact details page</div> */}
        <ContactDetail />
        <br />
        {/* <Link to={`/my-sphere/592/contact-details`}>contact edit</Link> */}
        {/* <Link to="/my-sphere/:contactId/contact-edit">contact edit</Link> */}
      </Route>
      <Route exact path="/my-sphere/:contactId/contact-details/contact-edit">
        {/* <div>contact edit page {contactId}</div> */}
        <ContactEdit />
      </Route>
    </Switch>
  )
}
