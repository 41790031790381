import React, { useState } from 'react'
import './OnboardingModal.scss'
import { Modal, Button, Text, SelectOptionType } from '@eltoro-ui/components'
import {
  PersonalInfoForm,
  // RealEstateInfoForm
} from 'Components'
import toast from 'react-hot-toast'
import { UserInfoType } from 'types'
import { useHistory } from 'react-router'
import { userDetail } from 'Requests/Request_Methods/authUserMethods'
import { userRole } from 'Helpers'
import { useDispatch } from 'react-redux'
import { setUser } from 'Redux/actions'
import Congratulation from '../../assets/Images/congratulation.svg'
import Leftgroup from '../../assets/Images/leftgroup.svg'
import Rightgroup from '../../assets/Images/rightgroup.svg'

export const OnboardingModal = ({
  setModalOpen,
}: {
  setModalOpen: (value: boolean) => void
}) => {
  const dispatch = useDispatch()

  const tkn = localStorage.getItem('beewo_token')

  const meApicall = () => {
    if (tkn) {
      userDetail(tkn)
        .then((res) => {
          if (res?.data?.email) {
            const user = {
              user_role: userRole(res.data.is_super),
              ...res.data,
            }
            dispatch(setUser(user))
            setModalOpen(false)
            setModalStep(1)
            history.push('/profile')
          }
        })
        .catch((err) => {
          // setIsLoading(false)
          toast.error(err)
        })
    }
  }

  const emptyUser = {
    personalInfo: {
      firstName: '',
      lastName: '',
      mls: { label: '' },
      mlsID: '',
      email: '',
      agency: 'agency',
      territory: '',
    },
    // realEstateInfo: {
    //   territory: '',
    //   experience: '',
    //   status: '',
    // },
  }
  const [currentUser, setCurrentUser] = useState<UserInfoType>(emptyUser)
  const [modalStep, setModalStep] = useState<number>(1)
  const history = useHistory()

  const populatePersonalInfo = (
    firstName: string,
    lastName: string,
    email: string,
    mls: SelectOptionType,
    mlsID: string,
    agency: string,
    territory: string,
  ) => {
    setCurrentUser({
      ...currentUser,
      personalInfo: {
        firstName,
        lastName,
        email,
        mls,
        mlsID,
        agency,
        territory,
      },
    })
  }

  // const populateRealEstateInfo = (
  //   territory: string,
  //   experience: string,
  //   status: string,
  // ) => {
  //   setCurrentUser({
  //     ...currentUser,
  //     realEstateInfo: {
  //       territory,
  //       experience,
  //       status,
  //     },
  //   })
  // }

  return (
    <Modal>
      {modalStep === 1 && (
        <PersonalInfoForm
          user={currentUser}
          onSubmit={(value) => {
            populatePersonalInfo(
              value.firstName,
              value.lastName,
              value.email,
              value.mls,
              value.mlsID,
              value.agency,
              value.territory,
            )
            setModalStep(3)
          }}
        />
      )}
      {/* {modalStep === 2 && (
        <RealEstateInfoForm
          user={currentUser}
          onSubmit={(value) => {
            populateRealEstateInfo(
              value.territory,
              value.experience,
              value.workStatus,
            )
            setModalStep(3)
          }}
          onBack={(value) => {
            setModalStep(value)
          }}
        />
      )} */}
      {modalStep === 3 && (
        <div className="OnboardingModal-welcome">
          <img className="Leftgroup" src={Leftgroup} alt="Leftgroup" />
          <div className="OnboardingModal-welcomeHeader">
            <div className="beewo-header-congrats">
              <img src={Congratulation} alt="congrats" />
              <p>Congratulations!</p>
            </div>
            <div className="beewo-header-welcome">
              <p>Welcome to Beewo</p>
            </div>
            <div className="description">
              <p>
                You&apos;ve successfully created your profile. Click below{' '}
                <br />
                to start accessing the platform.
              </p>
            </div>
          </div>
          <div className="btn-container">
            <Button
              UNSAFE_style={{
                borderRadius: '10px',
                padding: '26px 20px',
                fontFamily: 'Open Sans',
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '27px',
                color: '#2B1F0A',
              }}
              kind="primary"
              size="s"
              width="30%"
              onClick={() => {
                setModalOpen(false)
                window.location.reload()
                history.push('/dashboard')
                // meApicall()
              }}
            >
              Get Started
            </Button>
          </div>
          <img className="Rightgroup" src={Rightgroup} alt="Rightgroup" />
        </div>
      )}
    </Modal>
  )
}
