import { Button, Text } from '@eltoro-ui/components'
import { THomeTypeFilter } from 'types'
import React, { useEffect, useState } from 'react'
import './FindYourProspectFilters.scss'

export const HomeTypeFilter = ({
  modalOpen,
  setIsModalOpen,
  setHome,
  currentFilters,
  setIsClicked,
  isClicked,
}: THomeTypeFilter) => {
  // const [isDisabled, setIsDisabled] = useState(true)
  // const [setHomeTypeLocally, setHomeTypeLocally] = useState<{
  //   homeType: string | null
  // }>({
  //   homeType: null,
  // })

  const [homeTypeLocal, setHomeTypeLocal] = useState<{
    homeType: string | null
  }>({ homeType: null })

  // useEffect(() => {
  //   if (currentFilters.homeType) {
  //     setIsDisabled(false)
  //   } else {
  //     setIsDisabled(true)
  //   }
  // }, [currentFilters])

  const onClickClearOrCancel = (type: string) => {
    setIsModalOpen({ ...modalOpen, homeType: false })
    if (type === 'Clear') {
      setHome({ ...currentFilters, homeType: null })
      setHomeTypeLocal({ ...homeTypeLocal, homeType: null })

      if (currentFilters?.homeType) {
        setIsClicked({ ...isClicked, isLoaded: false })
      }
    }
    // show prospects box on cancel
    // else {
    //   setIsClicked({ ...isClicked, isLoaded: true })
    // }
  }

  const clickOnSave = () => {
    setHome({ ...currentFilters, ...homeTypeLocal })
    // setHomeTypeLocal({ ...homeTypeLocal, ...homeTypeLocal })
    setIsModalOpen({ ...modalOpen, homeType: false })
    setIsClicked({ ...isClicked, isLoaded: false })
  }

  useEffect(() => {
    if (currentFilters.homeType) {
      setHomeTypeLocal({ homeType: currentFilters.homeType })
    }
  }, [])

  return (
    <div className="FindYourProspectFilters">
      <div style={{ display: 'flex' }}>
        <span
          style={{
            color: '#AAB2B5',
            width: '120px',
            fontSize: '14px',
            fontFamily: 'Open Sans',
            lineHeight: '19px',
            fontWeight: '400',
          }}
        >
          Home Type
        </span>

        <div
          style={{
            width: '90%',
            marginLeft: '5px',
            height: '12px',
            borderBottom: '1px solid #9A9A9A',
          }}
        />
      </div>
      <br />
      <div className="FindYourProspectFilters__homeType">
        <Button
          UNSAFE_style={{
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
          }}
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            homeTypeLocal.homeType === 'Any'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHomeTypeLocal({ ...homeTypeLocal, homeType: 'Any' })
          }}
        >
          Any
        </Button>
        <Button
          UNSAFE_style={{
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
          }}
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            homeTypeLocal.homeType === 'Single Family Residence'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHomeTypeLocal({
              ...homeTypeLocal,
              homeType: 'Single Family Residence',
            })
          }}
        >
          Single Family Home
        </Button>
        <Button
          UNSAFE_style={{
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
          }}
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            homeTypeLocal.homeType === 'Condominium'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHomeTypeLocal({ ...homeTypeLocal, homeType: 'Condominium' })
          }}
        >
          Condominium
        </Button>
        <Button
          UNSAFE_style={{
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
          }}
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            homeTypeLocal.homeType === 'Apartment'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHomeTypeLocal({ ...homeTypeLocal, homeType: 'Apartment' })
          }}
        >
          Apartment
        </Button>
        <Button
          UNSAFE_style={{
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            borderRadius: '0 10px 10px 0',
          }}
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            homeTypeLocal.homeType === '1/2 Duplex'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHomeTypeLocal({ ...homeTypeLocal, homeType: '1/2 Duplex' })
          }}
        >
          Duplex
        </Button>

        {/* <Button
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            currentFilters.homeType === 'Townhouse'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHome({ ...currentFilters, homeType: 'Townhouse' })
          }}
        >
          Townhouse
        </Button>

        <Button
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            currentFilters.homeType === 'Mobile Home'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHome({ ...currentFilters, homeType: 'Mobile Home' })
          }}
        >
          Mobile Home
        </Button>
        <Button
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            currentFilters.homeType === 'Villa'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHome({ ...currentFilters, homeType: 'Villa' })
          }}
        >
          Villa
        </Button> */}
        {/* <Button
          UNSAFE_className={`FindYourProspectFilters__modalOption ${
            currentFilters.homeType === 'Manufactured Home'
              ? 'FindYourProspectFiltersActive'
              : ''
          }`}
          onClick={() => {
            setHome({ ...currentFilters, homeType: 'Manufactured Home' })
          }}
        >
          Manufactured Home
        </Button> */}
      </div>
      <br />
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          kind="default"
          size="l"
          UNSAFE_style={{
            backgroundColor: 'white',
            color: '#ffab03',
            fontSize: '17px',
            fontWeight: '400',
          }}
          UNSAFE_className="SellYourListingFirst__modalSave"
          weight="bold"
          onClick={() =>
            onClickClearOrCancel(
              // homeTypeLocal.homeType &&
              //   !(currentFilters.homeType === homeTypeLocal?.homeType)
              //   ? 'Clear'
              //   : 'Cancel',
              'Cancel',
            )
          }
        >
          {/* {homeTypeLocal?.homeType ? 'Clear' : 'Cancel'} */}
          {/* {!(currentFilters.homeType === homeTypeLocal?.homeType)
            ? 'Clear'
            : 'Cancel'} */}
          Cancel
        </Button>
        <Button
          kind="default"
          UNSAFE_className="SellYourListingFirst__modalSave"
          size="l"
          weight="bold"
          disabled={!homeTypeLocal?.homeType}
          onClick={clickOnSave}
        >
          Save Changes
        </Button>
      </div>
    </div>
  )
}
