/* eslint-disable */
import React, { useMemo, useEffect, useState, FC } from 'react'
import {
  Form,
  FormItem,
  Button,
  FlexBox,
  Select,
  Modal,
  Text,
} from '@eltoro-ui/components'
import { PersonalInput } from 'Components/PersonalInfoForm/component/PersonalInput'
import { useForm } from '@eltoro-ui/hooks'
import toast from 'react-hot-toast'
import { ProfilePersonalInfoType } from 'types'
// import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateTheUser, userDetail } from 'Requests'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { Section, Tooltip } from 'Components'
import { setUser } from 'Redux/actions'
import { userRole } from 'Helpers'
import { LableInputField } from 'Components/LableInputField'
import { LableInputSelect } from 'Components/LableInputSelect'
import Leftvoting from '../../../../assets/Images/leftvoting.svg'
import Rightvoting from '../../../../assets/Images/rightvoting.svg'
import cross from '../../../../assets/Images/cross.png'

import { useDispatch } from 'react-redux'
import Warning from '../../../../Images/warning.png'

import { ManageAvatar } from '..'
import './PersonalInfoSection.scss'

type PersonalInfoSectionType = {
  user: ProfilePersonalInfoType
  mslRegion: any
  callReset: () => void
}

export const PersonalInfoSection: FC<PersonalInfoSectionType> = ({
  user,
  mslRegion,
  callReset,
}) => {
  // const history = useHistory()
  const dispatch = useDispatch()
  const [loadingImg, setloadingImg] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fullScreenImage, setfullScreenImage] = useState(false)

  const [personalInfo, setPersonalInfo] = useState({
    first_name: '',
    last_name: '',
    mls_region: '',
    mls_id: '',
    email: '',
    territory: '',
    profile_pic: '',
  })
  const [options, setOptions] = useState(user.mls_region)
  useMemo(() => {
    if (user) {
      const {
        first_name,
        last_name,
        mls_region,
        mls_id,
        email,
        territory,
        profile_pic,
      } = user || {}

      setPersonalInfo({
        ...personalInfo,
        first_name,
        last_name,
        mls_region,
        mls_id,
        email,
        territory,
        profile_pic,
      })
    }
  }, [user])

  // useEffect(() => {
  //   if (options === '') {
  //     getMlS()
  //       .then((res) => {
  //         res.data.listings.map((i: { name: any }) => setOptions(i.name))
  //       })
  //       .catch((err) => toast.error(err.data))
  //   }
  // }, [])

  const { isValidForm, touched } = useForm(personalInfo)
  const required = ['first_name', 'last_name', 'mls_region']
  const { validForm, missingFields } = isValidForm([...required])

  const onSubmit = () => {
    setLoading(true)
    const { first_name, last_name, mls_region, email, territory } = personalInfo
    updateTheUser({ first_name, last_name, mls_region, email, territory })
      .then(() => {
        const token = localStorage.getItem('beewo_token') || undefined

        userDetail(token).then((res) => {
          setLoading(false)
          const _user = {
            user_role: userRole(res.data?.is_super),
            ...res.data,
          }
          dispatch(setUser(_user))
          toast.success('Saved Successfully!')
          setLoading(false)
        })
      })
      .catch((err) => {
        toast.error(err.data)
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string | undefined) => {
    setPersonalInfo({ ...personalInfo, [key]: value })
  }

  const isDisabled = () => {
    const { first_name, last_name, mls_region, email, territory } = personalInfo
    if (first_name && last_name && mls_region && email && territory) {
      return false
    }
    return true
  }
  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="Please fill the input"
          topAbsolute="-80px"
          rightAbsolute="-43px"
          padding="5px 5px"
        />
      </div>
    )
  }

  return (
    <div className="PersonalInfoSectionContainer">
      <div className="PersonalInfoSectionContainer__header">
        <Text
          on="white"
          weight="bold"
          size="l"
          UNSAFE_className="PersonalInfoSectionContainer__heading"
        >
          Personal Info
        </Text>

        {/* {isDisabled() && (
          <FontAwesomeIcon
            area-hidd
            className="PersonalInfoSectionContainer__error_icon"
            icon={faExclamationCircle}
          />
        )} */}
      </div>
      <Section spacer={false} dark>
        <FlexBox
          // alignItems="center"
          alignItems="flex-end"
          justifyContent="space-between"
          flexWrap="wrap-reverse"
        >
          <div className="PersonalInfoSection__avatar">
            <ManageAvatar
              userName={'ll'}
              url={personalInfo?.profile_pic}
              loading={loadingImg}
              setLoading={setloadingImg}
              onClick={() => {
                personalInfo?.profile_pic ? setfullScreenImage(true) : ''
              }}
            />
          </div>

          <div className="PersonalInfoSection">
            <Form
              onSubmit={onSubmit}
              required={required}
              valid={validForm}
              touched={touched}
              missingFields={missingFields}
              UNSAFE_className="PersonalInfoSection__form_container"
            >
              <div className="PersonalInfoSection__fields_container">
                <div
                  className="fields_container_nested"
                  // style={{ width: '95%', paddingRight: '5px' }}
                >
                  {/* <FormItem
                    htmlFor="first name"
                    FontSize="14px"
                    label="First Name"
                    redLabel={!!personalInfo.first_name}
                    required
                    Height="1rem"
                  > */}
                  {/* <PersonalInput
                      fontSize="16px"
                      type="text"
                      placeHolder="First Name"
                      value={personalInfo.first_name}
                      wdt="100%"
                      border="1px solid #c6cbcd"
                      onChange={(e) => onChange('first_name', e.target.value)}
                      isError={!personalInfo.first_name}
                    /> */}
                  <LableInputField
                    title="First Name"
                    placeholder="Enter Campaign Name"
                    requireTage="*"
                    isError={
                      !personalInfo.first_name &&
                      personalInfo.first_name.length < 1
                    }
                    iconRight={
                      <img
                        className="start_icon"
                        style={{
                          marginTop: '7px',
                          width: '24px',
                        }}
                        src={
                          !personalInfo.first_name &&
                          personalInfo.first_name.length < 1
                            ? Warning
                            : ''
                        }
                        // alt="icon"
                      />
                    }
                    style={{ paddingLeft: '20px' }}
                    value={personalInfo.first_name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const newString = e.target.value.replace(/\s+/g, ' ')
                      onChange('first_name', e.target.value)
                    }}
                  />{' '}
                  {!personalInfo.first_name &&
                  personalInfo.first_name.length < 1 ? (
                    <ErrorToolbar />
                  ) : (
                    ''
                  )}
                  {/* </FormItem> */}
                </div>
                <div
                  //  style={{ width: '95%', paddingLeft: '5px' }}
                  className="fields_container_nested"
                >
                  {/* <FormItem
                    FontSize="14px"
                    htmlFor="last name"
                    label="Last Name"
                    redLabel={!!personalInfo.last_name}
                    required
                    Width="95%"
                    Height="1rem"
                  > */}
                  {/* <PersonalInput
                      type="text"
                      fontSize="16px"
                      placeHolder="Last Name"
                      value={personalInfo.last_name}
                      wdt="100%"
                      border="1px solid #c6cbcd"
                      onChange={(e) => onChange('last_name', e.target.value)}
                      isError={!personalInfo.last_name}
                    /> */}
                  <LableInputField
                    title="Last Name"
                    requireTage="*"
                    placeholder="Enter Last Name"
                    isError={
                      !personalInfo.last_name &&
                      personalInfo.last_name.length < 1
                    }
                    iconRight={
                      <img
                        className="start_icon"
                        style={{
                          marginTop: '7px',
                          width: '24px',
                        }}
                        src={
                          !personalInfo.last_name &&
                          personalInfo.last_name.length < 1
                            ? Warning
                            : ''
                        }
                        // alt="icon"
                      />
                    }
                    value={personalInfo.last_name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const newString = e.target.value.replace(/\s+/g, ' ')
                      onChange('last_name', newString)
                    }}
                    style={{ paddingLeft: '20px' }}
                  />
                  {!personalInfo.last_name &&
                  personalInfo.last_name.length < 1 ? (
                    <ErrorToolbar />
                  ) : (
                    ''
                  )}

                  {/* </FormItem> */}
                </div>
              </div>

              <div className="PersonalInfoSection__fields_container">
                <div
                  className="fields_container_nested"

                  //  style={{ width: '95%', paddingRight: '5px' }}
                >
                  {/* <FormItem
                    htmlFor="MLS"
                    label="MLS Region"
                    redLabel={!!personalInfo.mls_region}
                    Height="1rem"
                    required
                  > */}
                  {/* <Select
                      Height="38px"
                      placeholder="Select Your MLS Region*"
                      options={options}
                      selectedOption={{ label: personalInfo.mls_region }}
                      onChange={(value) => onChange('mls_region', value?.label)}
                      maxHeight={300}
                    /> */}
                  <LableInputSelect
                    requireTage="*"
                    value={options}
                    // isError={
                    //   !personalInfo.mls_region &&
                    //   personalInfo.mls_region.length < 1
                    // }
                    title="Select Your MLS Region"
                    // isError={isSubmit && mls.length < 1}
                    // style={{ marginTop: '2rem' }}
                    onChange={(value: any) =>
                      onChange('mls_region', value?.label)
                    }
                    iconRight={
                      <img
                        className="start_icon"
                        style={{
                          marginTop: '7px',
                          width: '24px',
                        }}
                        src={
                          !personalInfo.mls_region &&
                          personalInfo.mls_region.length < 1
                            ? Warning
                            : ''
                        }
                        // alt="icon"
                      />
                    }
                    Options={
                      <>
                        return (
                        <>
                          {/* <option value="MLS Region">MLS Region</option> */}
                          <option selected value={options}>
                            {options}
                          </option>
                        </>
                        )
                      </>
                    }
                  />
                  {!personalInfo.mls_region &&
                  personalInfo.mls_region.length < 1 ? (
                    <ErrorToolbar />
                  ) : (
                    ''
                  )}
                  {/* </FormItem> */}
                </div>
                <div
                  //  style={{ width: '95%', paddingLeft: '5px' }}
                  className="fields_container_nested"
                >
                  {/* <FormItem
                    htmlFor="MLS ID"
                    label="What is your MLS ID?"
                    redLabel={!!personalInfo.mls_id}
                    Height="1rem"
                    required
                  >
                    <PersonalInput
                      value={personalInfo.mls_id}
                      fontSize="16px"
                      placeHolder="000-000-000"
                      wdt="100%"
                      disabled
                      border="1px solid #c6cbcd"
                    /> */}
                  {/* </FormItem> */}
                  <LableInputField
                    title="What is your MLS ID?"
                    requireTage="*"
                    placeholder="000-000-000"
                    disabled={true}
                    value={personalInfo.mls_id}
                    isError={
                      !personalInfo.mls_id && personalInfo.mls_id.length < 1
                    }
                    style={{ paddingLeft: '20px' }}
                    iconRight={
                      <img
                        className="start_icon"
                        style={{
                          marginTop: '7px',
                          width: '24px',
                        }}
                        src={
                          !personalInfo.mls_id && personalInfo.mls_id.length < 1
                            ? Warning
                            : ''
                        }
                        // alt="icon"
                      />
                    }
                  />

                  {!personalInfo.mls_id && personalInfo.mls_id.length < 1 ? (
                    <ErrorToolbar />
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div
                className="PersonalInfoSection__fields_container"
                // style={{ display: 'flex' }}
              >
                <div className="fields_container_nested">
                  <LableInputField
                    title="Work Email"
                    placeholder="Work Email"
                    disabled
                    requireTage="*"
                    isError={
                      !personalInfo.email && personalInfo.email.length < 1
                    }
                    iconRight={
                      <img
                        className="start_icon"
                        style={{
                          marginTop: '7px',
                          width: '24px',
                        }}
                        src={
                          !personalInfo.email && personalInfo.email.length < 1
                            ? Warning
                            : ''
                        }
                        // alt="icon"
                      />
                    }
                    value={personalInfo.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      // const newString = e.target.value.replace(/\s+/g, ' ')
                      onChange('email', e.target.value)
                    }}
                    style={{ paddingLeft: '20px' }}
                  />
                  {!personalInfo.email && personalInfo.email.length < 1 ? (
                    <ErrorToolbar />
                  ) : (
                    ''
                  )}
                </div>
                <div className="fields_container_nested">
                  <LableInputField
                    title="Primary real estate territory?"
                    UNSAFE_ClassName="realEstateInput"
                    requireTage="*"
                    placeholder="e.g. city, state, zipcode"
                    iconRight={
                      <img
                        className="start_icon"
                        style={{
                          marginTop: '7px',
                          width: '24px',
                        }}
                        src={
                          !personalInfo.territory &&
                          personalInfo.territory.length < 1
                            ? Warning
                            : ''
                        }
                        // alt="icon"
                      />
                    }
                    isError={
                      !personalInfo?.territory &&
                      personalInfo?.territory?.length < 1
                    }
                    value={personalInfo.territory}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      // const newString = e.target.value.replace(/\s+/g, ' ')
                      onChange('territory', e.target.value)
                    }}
                    style={{ paddingLeft: '20px' }}
                  />
                  {!personalInfo.territory &&
                  personalInfo.territory.length < 1 ? (
                    <ErrorToolbar />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Form>
            <footer>
              {/* <hr className="PersonalInfoSectionContainer__divider" /> */}
              <div>
                <div>
                  <button
                    onClick={callReset}
                    className="PersonalInfoSectionContainer__reset_password"
                    type="button"
                  >
                    Reset Password
                  </button>
                </div>
                <div style={{ marginTop: '1rem', width: '90%' }}>
                  <Button
                    type="submit"
                    kind="primary"
                    UNSAFE_className="PersonalInfoSectionContainer__save_button"
                    UNSAFE_style={{ justifyContent: 'center' }}
                    onClick={onSubmit}
                    disabled={isDisabled() || loading || loadingImg}
                    loading={loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </footer>
          </div>
        </FlexBox>
      </Section>
      {fullScreenImage ? (
        <Modal
          offClick={() => {
            setfullScreenImage(false)
          }}
        >
          <div className="imageModal">
            <img
              src={cross}
              onClick={() => {
                setfullScreenImage(false)
              }}
              alt="cross"
              className="PersonalInfoForm__cross"
              role="presentation"
            />
            <img className="leftvoting_icon1" src={Leftvoting} alt="icon" />
            <img
              className="profile-pic"
              src={personalInfo?.profile_pic}
              alt=""
            />

            <img className="rightvoting_icon1" src={Rightvoting} alt="icon" />
          </div>
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}
