import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import './LargeIconButton.scss'

type LargeIconButtonType = {
  UNSAFE_className?: string
  title: string
  subTitle?: string | JSX.Element
  disabled?: boolean
  icon?: JSX.Element
  content?: any
  rightText?: string | JSX.Element
  onClick?: () => void
  to?: LinkProps['to']
}
// & (
//   | { onClick?: () => void; to?: undefined }
//   | { onClick?: undefined; to?: LinkProps['to'] }
// )
export const LargeIconButton: React.FC<LargeIconButtonType> = ({
  title,
  subTitle,
  disabled = false,
  onClick,
  icon,
  rightText,
  to,
  content,
  UNSAFE_className,
}) => {
  const buttonContent = (
    <>
      {icon && <span className="LargeIconButton__icon">{icon}</span>}
      <div className="LargeIconButton__content">
        <p
          className={`LargeIconButton__title ${UNSAFE_className}`}
          // style={{ margin: content ? 0 : '2rem' }}
          style={{ margin: content ? 0 : 0 }}
        >
          {title}
        </p>
        {subTitle && (
          <div
            className="LargeIconButton__title-text"
            style={{ marginTop: content ? '0px' : '' }}
          >
            <p
              style={{
                margin: content && 0,
                fontSize: '13px',
                lineHeight: '16px',
                color: '#7E7E7E',
              }}
              className="largicon_paragraph"
            >
              {subTitle}
            </p>
          </div>
        )}
        {!content && <br />}
        {content && (
          <div className="LargeIconButton__title-text">
            <p style={{ margin: 0 }}>{content}</p>
          </div>
        )}
        {/* <div className="LargeIconButton__bottom-text-Parent">
          <span className="LargeIconButton__bottom-text">View More</span>
        </div> */}
      </div>
      {rightText && (
        <span className="LargeIconButton__right-text">{rightText}</span>
      )}{' '}
    </>
  )
  const buttonProps = {
    onClick,
    disabled,
  }
  return to && !disabled ? (
    <Link
      to={to}
      onClick={onClick}
      className="LargeIconButton LargeIconButton__link"
    >
      {buttonContent}
    </Link>
  ) : (
    <button
      {...buttonProps}
      type="button"
      className="LargeIconButton LargeIconButton LargeIconButton__link"
    >
      {buttonContent}
    </button>
  )
}
