import React, { FC, useState, useEffect, useCallback, useMemo } from 'react'
import { Avatar, Button, Modal } from '@eltoro-ui/components'
import './ManageAvatar.scss'
import { uploadProfilePicture, userDetail } from 'Requests'
import { setUser } from 'Redux/actions'
import { useDispatch } from 'react-redux'
import Cropper from 'react-easy-crop'
import { SpinnerLoader } from 'Components/SpinnerLoader'
import { getCroppedImg, getFormData, userRole } from 'Helpers'
import toast from 'react-hot-toast'
import defaultAvatar from '../../../../assets/Images/user.png'

type ManageAvatarType = {
  userName: string
  url: string
  loading: boolean
  setLoading: (param: boolean) => void
  onClick: () => void
}

export const ManageAvatar: FC<ManageAvatarType> = ({
  userName,
  url,
  loading,
  setLoading,
  onClick,
}) => {
  const [avatar, setAvatar] = useState('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<any>(1)
  const [image, setImage] = useState<any>('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [height, setHeight] = useState<any>(null)
  const [width, setWidth] = useState<any>(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
  const [imageUrl, setImageUrl] = useState('')
  const dispatch = useDispatch()

  useMemo(() => {
    setAvatar(url)

    return () => setAvatar('')
  }, [url])

  const onCropComplete = useCallback((croppedArea, _croppedAreaPixels) => {
    setCroppedAreaPixels(_croppedAreaPixels)
  }, [])

  const onChange = (file: any) => {
    setLoading(true)

    uploadProfilePicture(getFormData(file))
      .then(() => {
        const token = localStorage.getItem('beewo_token') || undefined
        userDetail(token)
          .then((res) => {
            const _user = {
              user_role: userRole(res.data?.is_super),
              ...res.data,
            }
            dispatch(setUser(_user))

            toast.success('Picture upload Successfully!')
            setLoading(false)
          })
          .catch((err) => {
            toast.error(err.data)
            setLoading(false)
          })
      })
      .catch((err) => {
        toast.error(err.data)
        setLoading(false)
      })
  }

  const showCroppedImage = useCallback(
    async (_img: any) => {
      try {
        const croppedImage: any = await getCroppedImg(
          window.URL.createObjectURL(_img),
          croppedAreaPixels,
        )
        const imgFile = new File([croppedImage], 'imgName', {
          type: 'image/png',
          lastModified: new Date().getTime(),
        })
        onChange(imgFile)
      } catch (e: any) {
        toast.error(e)
      }
      // eslint-disable-next-line
    },
    [croppedAreaPixels],
  )

  const handleFile = (e: any) => {
    if (e.target.files.length > 0) {
      const file = e.target.files
      const _url = URL.createObjectURL(file[0])
      const img = new Image()
      img.src = _url

      img.onload = function () {
        setWidth(width)
        setHeight(height)
      }

      const maxAllowedSize = 5 * 1024 * 1024

      if (file[0].size > maxAllowedSize) {
        toast.error('Image file is too large!')
      } else {
        setImage(file[0])
        setImageUrl(window.URL.createObjectURL(file[0]))
        setModalIsOpen(true)
      }
      e.target.value = null
    }
  }

  return (
    <div>
      <div className="ManageAvatar">
        <div className="ManageAvatar__avatar">
          <Avatar
            name={userName}
            rounded
            size="l"
            src={avatar || defaultAvatar}
            UNSAFE_className="avatar"
            onClick={onClick}
            UNSAFE_style={avatar ? { cursor: 'pointer' } : { cursor: 'auto' }}
          />
        </div>
        <label
          style={{ color: loading === true ? '#8fd8ed' : '#FFAB03' }}
          htmlFor="file-upload"
          className="custom-file-upload"
        >
          <input
            onChange={handleFile}
            accept="image/*"
            type="file"
            id="file-upload"
            className="file_input"
            disabled={loading}
          />
          Upload Photo
        </label>
      </div>
      {modalIsOpen && (
        <Modal className="image_cropper">
          <div className="image_cropper__container">
            <div className="image_cropper__crop_wrapper">
              <Cropper
                image={imageUrl}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                style={{
                  containerStyle: {
                    // width: 400,
                    height: 400,
                    position: 'relative',
                    margin: '0px auto',
                  },
                }}
              />
              <div className="image_cropper__buttons_wrapper">
                <Button
                  onClick={() => setModalIsOpen(false)}
                  UNSAFE_className="Notes__edit-btn"
                  size="l"
                  kind="primary"
                  disabled={loading}
                >
                  Cancel
                </Button>
                <input
                  type="range"
                  defaultValue={zoom}
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e) => setZoom(e.target.value)}
                />
                <Button
                  UNSAFE_className="Notes__edit-btn"
                  size="l"
                  kind="primary"
                  disabled={loading}
                  onClick={() => showCroppedImage(image)}
                >
                  {loading ? (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1rem',
                        }}
                      >
                        Save
                        <SpinnerLoader
                          style={{ position: 'unset', color: 'black' }}
                        />
                      </div>
                    </div>
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
