import React from 'react'
import './LoadingBar.scss'

export const LoadingBar: React.FC<{
  percentage?: number
  colorKind?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'grey'
    | 'success'
    | 'warning'
    | 'danger'
}> = ({ percentage, colorKind = 'grey' }) => {
  const hasPercentage = () => {
    if (typeof percentage === 'number') {
      return {
        width: percentage,
        animation: 'basic-transition',
      }
    }
    return {
      width: undefined,
      animation: 'is-flowing',
    }
  }

  return (
    <div className="LoadingBar" style={{ backgroundColor: 'white' }}>
      <div
        className={`LoadingBar__bar LoadingBar__bar--${
          hasPercentage().animation
        }`}
        style={{
          width: `${hasPercentage().width}%`,
          background: 'linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%)',
        }}
      />
    </div>
  )
}
