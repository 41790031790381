import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import './CampaignStatusBadge.scss'

type CampaignStatusBadgeType = {
  status?: string
  color?: string
  badgeIconClass?: any
  badgeicon?: any
}

export const CampaignStatusBadge: FC<CampaignStatusBadgeType> = ({
  status,
  color,
  badgeicon,
  badgeIconClass,
}) => {
  return (
    <div className={badgeIconClass}>
      <div />

      <img src={badgeicon} alt="badgeicon" />

      <div className="CampaignStatusBadge__text-container">{status}</div>
    </div>
  )
}
