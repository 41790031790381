import React, { useEffect } from 'react'

const OAuthGoogle = () => {
  // @ts-ignore
  const newDAta = function () {}
  useEffect(() => {
    // first

    // newDAta()

    const proxyHost = 'https://api.cloudsponge.com/auth'
    let isOAuthPage: any

    // let key: any
    let redirecting = false

    function appendSearch() {
      let search = window.location.search
      if (search && search[0] === '?') {
        search += '&'
      } else {
        search += '?'
      }
      search += 'state=_csAuth%3D1%26import_id%3Dproxy-test'
      return search
    }

    // serializes an object into a query-string
    const serializeParams = function (params: {
      [x: string]: string | number | boolean
    }) {
      // let k
      const results: string[] = []
      Object.keys(params).forEach((k) => {
        results.push(`${k}=${encodeURIComponent(params[k])}`)
      })
      // for (k in params) {
      // }
      return results.join('&')
    }

    // converts the URL string into an object
    const parseParams = function (url: string) {
      const obj = {}
      url.replace(/([^?=&]+)(=([^&]*))?/g, function ($0, $1, $2, $3) {
        // @ts-ignore
        return (obj[$1] = decodeURIComponent($3))
      })
      return obj
    }

    // parse the query string

    const queryParams: any = parseParams(window.location.search)

    // selects just the OAuth-related params out of the query
    const oauthParams: any = {}
    Object.keys(queryParams).forEach((key) => {
      if (
        key === 'code' ||
        key === 'state' ||
        key === 'error' ||
        key === 'error_code' ||
        key === 'forward'
      ) {
        // @ts-ignore
        oauthParams[key] = queryParams[key]
      }
    })
    // for (key in queryParams) {

    // }

    // checks to see if this page actually the necessary received OAuth params
    // and then flings the state and code up to CloudSponge to do the heavy lifting work

    if (
      // @ts-ignore
      oauthParams.state &&
      // @ts-ignore
      (oauthParams.code || oauthParams.error || oauthParams.error_code)
    ) {
      redirecting = true
      // @ts-ignore
      window.location = `${proxyHost}?${serializeParams(oauthParams)}`
    }

    // add the redirect endpoint to any .cs-proxy links on the page
    window.addEventListener(
      'load',
      function () {
        let i
        const links = document.getElementsByClassName('cs-force')
        for (i = 0; i < links.length; i += 1) {
          // @ts-ignore
          links[i].href = proxyHost + appendSearch()
        }
      },
      false,
    )

    // return {
    setTimeout(() => {}, 1000)
    // window.close()
    // redirecting,
    // force:
    // },
    // }
    // return () => {
    //   second
    // }
  }, [])

  return <div />
}

export default OAuthGoogle
