import React from 'react'
import './CreativeTypeBadge.scss'
import { Button, Text } from '@eltoro-ui/components'
// import user from '../../../assets/Images/user.png'
import user from '../../../../../../assets/Images/user.png'

export const CreativeTypeBadge = ({
  onClick,
  icon,
  text,
  selectedValue,
  bannerIcon,
  typeBadge,
  disabledDirectMail,
  fileTextClassName = '',
  UNSAFE_className = '',
}: {
  onClick: () => void
  icon: JSX.Element
  text: string
  selectedValue?: Boolean
  bannerIcon?: string
  typeBadge?: string
  disabledDirectMail?: boolean
  fileTextClassName?: string
  UNSAFE_className?: string
}) => {
  return (
    <div
      className={`CampaignSetup__box ${UNSAFE_className}`}
      // style={{ width: 'auto' }}
    >
      <div style={{ margin: selectedValue ? '0rem auto' : '1rem auto' }}>
        <img
          src={bannerIcon}
          alt=""
          style={{ height: '50px', marginBottom: '8px', marginTop: '12px' }}
        />
        {/* <FontAwesomeIcon icon={faUserAlt} /> */}
      </div>
      {selectedValue ? (
        <Text
          on="white"
          size="l"
          weight="bold"
          UNSAFE_style={{ textAlign: 'center' }}
          UNSAFE_className={fileTextClassName}
        >
          {' '}
          {text}
        </Text>
      ) : (
        <Button
          type="button"
          kind="primary"
          // width="auto"
          size="l"
          UNSAFE_style={{
            marginTop: '8px',
            // marginBottom: '20px',
            marginInline: 'auto',
            paddingInline: '30px',
            width: '187px',
            height: '54px',
            margin: '20px auto',
          }}
          UNSAFE_TEXTSTYLE="CampaignSetup__boxTextButton"
          // onClick={() => {
          //   // inputRef.current?.click()
          // }}
          disabled={disabledDirectMail}
          onClick={onClick}
        >
          {text}
        </Button>
      )}
      {typeBadge === 'video' && selectedValue && (
        <>
          {' '}
          {/* <Text
            on="white"
            size="l"
            weight="bold"
            UNSAFE_style={{ textAlign: 'center' }}
          >
            {' '}
            {text}
          </Text> */}
          <Button
            type="button"
            kind="primary"
            // width="auto"

            UNSAFE_className="CreativeTypeBadge__button"
            // UNSAFE_className="CampaignSetup__boxButton"
            UNSAFE_TEXTSTYLE="CreativeTypeBadge__boxTextButton"
            // onClick={() => {
            //   // inputRef.current?.click()
            // }}
            onClick={onClick}
          >
            Edit
          </Button>
        </>
      )}

      <input
        type="file"
        style={{ display: 'none' }}
        // ref={inputRef}
        // onInput={(e) => {
        //   handleCSV(e)
        // }}
      />
    </div>
    // <button type="button" className="CreativeTypeBadge" onClick={onClick}>
    //   <div className="CreativeTypeBadge__icon">{icon}</div>
    //   <Text on="grey-100">{text}</Text>
    // </button>
  )
}
