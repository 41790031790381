import {
  Button,
  MaxHeightContainer,
  Modal,
  Table,
  Text,
} from '@eltoro-ui/components'
import { useAsync } from '@eltoro-ui/hooks'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faWalking } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Layout,
  Loading,
  PageHeader,
  Pagination,
  ProgressStepper,
  SummaryCart,
} from 'Components'
import { LableInputField } from 'Components/LableInputField'
import { VotingModal } from 'Components/VotingModal'
import { getFileName } from 'Helpers'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import {
  setCampaignId,
  setIsLaunchCampaign,
  setLaunchProspect,
  setSpinner,
} from 'Redux/actions'
import {
  createCampaign,
  getCampaignAudience,
  // getSavedCampaignAudienceById,
  getCampaignById,
  saveCampaignTarget,
} from 'Requests/Request_Methods/campaignMethods'
import { browserLog } from 'Tools'
import { TRootState, TTargets } from 'types'
import { CampaignCreationFooter, CampaignCreationWrapper } from '..'
import uploadCSV from '../../../../assets/Images/Group286.png'
import mySphereIcon from '../../../../assets/Images/Group87212.png'
import target from '../../../../assets/Images/target.png'
import user from '../../../../assets/Images/user.png'
import cross from '../../../../assets/Images/cross.png'
import { CreativeCampaignModal } from '../CampaignCreatives/_components'
import './CampaignSetup.scss'

export const CampaignSetup = () => {
  const [campaignName, setCampaignName] = useState<string>('')
  const [audiences, setAudiences] = useState<TTargets>()
  const [selectedAudiences, setSelectedAudiences] = useState<any[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  // const [campaignId, setCampaignId] = useState<any>()
  const campaignIdParams = useParams<{ campaignId: string }>()
  // const [preSelectedList, setPreSelectedList] = useState<TTargets[]>()
  const [preSelectedList, setPreSelectedList] = useState<any>()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const history = useHistory<any>()
  // const [status, setStatus] = useState('')
  // const { status, value } = useAsync(() => getAudiences())
  const { status, value } = useAsync(() =>
    getCampaignAudience(
      campaignName ? campaignId.campaignId?.id : 1,
      1,
      10,
      isShowContact,
    ),
  )
  let sum = 0

  const campaignId = useSelector((state: TRootState) => state.campaignIdReducer)
  const [campaign, setCampaign] = useState<any>({
    // prospects: sum,
    // audiences: selectedAudiences.length,
  })
  const totalProspects = selectedAudiences.map(
    (item) => (sum += item.prospects_counts),
    // setCampaign(...stat)
  )

  const isLaunchCampaign = useSelector(
    (state: TRootState) => state.isCampaignLaunchReducer,
  )
  const [audiencePaginate, setAudiencePaginate] = useState<any>({
    current_page: 1,
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [firstLoad, setfirstLoad] = useState(false)
  const campaignAudienceAPI = () => {
    if (campaignId.campaignId) {
      // setStatus('pending')
      setIsloading(true)
      getCampaignAudience(
        campaignId.campaignId.id,
        audiencePaginate?.current_page ? audiencePaginate?.current_page : 1,
        10,
        isShowContact,
      ).then((res) => {
        // setPreSelectedList(res.data.audience)
        setAudiences(res?.data?.targets)

        setAudiencePaginate({
          current_page: res?.data?.current_page,
          total: res?.data?.total,
          total_page: res?.data?.total_pages,
        })
        // setStatus('success')
        // callIsSelected()
        setIsloading(false)
      })
    }
  }

  useEffect(() => {
    callIsSelected()
  }, [audiences?.length])

  const callIsSelected = () => {
    if (audiences?.length && history.location.state?.isSelected) {
      setSelectedAudiences([audiences[0]])
      setPreSelectedList([audiences[0]])
      setCampaign({
        prospects: reduceProspects([audiences[0]]),
        audiences: 1,
      })
    }
  }

  useEffect(() => {
    if (currentPage !== audiencePaginate.current_page) {
      campaignAudienceAPI()
      setCurrentPage(audiencePaginate.current_page)
    }
  }, [audiencePaginate.current_page])

  useEffect(() => {
    // if (value?.data?.targets) setAudiences(value.data.targets)
    campaignAudienceAPI()
  }, [isLaunchCampaign])
  const uploadCSVFile = () => {
    setUploadModalOpen(true)
  }
  const dispatch = useDispatch()
  const handleNextClick = () => {
    const body: any = []
    selectedAudiences?.forEach((item) => {
      const data = {
        type: item.type,
        id: item.id,
        campaign_id: item.campaign_id,
        prospects_counts: item.prospects_counts,
      }
      body.push(data)
    })
    dispatch(setSpinner(true))

    saveCampaignTarget({ targets: body }, campaignId.campaignId?.id).then(
      (res) => {
        if (res.data) {
          const item = {
            audiences: res.data.audience,
            prospects: res.data.prospects_count,
          }
          history.push({
            pathname: `/create-campaign/${campaignId.campaignId?.id}/creatives`,
            state: item,
          })
        }

        dispatch(setSpinner(false))
      },
    )
  }

  const reduceProspects = (p: { name: string; prospects_counts: number }[]) => {
    return p.reduce((acc, cur) => acc + cur.prospects_counts, 0)
  }
  const [isCampaignLoad, setCampaignLoad] = useState(false)

  useEffect(() => {
    if (campaignIdParams.campaignId) {
      getCampaignAudiences(campaignIdParams.campaignId)
    } else {
      setIsloading(false)
      setModalOpen(true)
    }
  }, [audiencePaginate?.current_page])

  const getCampaignAudiences = (id: any) => {
    setIsloading(true)
    // setPreSelectedList([...selectedAudiences])

    getCampaignById(id).then((res: any) => {
      // res.data?.name
      // res.data?.audiences
      // if (res.data.audience.length > 0) {
      // setAudiences(res?.data?.targets)
      // ask
      // dispatch(setIsLaunchCampaign(true))
      setCampaignName(res?.data?.name || '')
      dispatch(setCampaignId({ id: id || campaignIdParams.campaignId }))
      // setPreSelectedList(res.data.audience)
      // setSelectedAudiences(res.data.audience)
      setIsloading(false)
      // res.data.audience.map((item) => selectedAudiences.push(item))
      // setSelectedAudiences([...selectedAudiences])
      if (!firstLoad) {
        setPreSelectedList([...selectedAudiences, ...res.data?.audience])
        setfirstLoad(true)
      } else {
        setPreSelectedList([...selectedAudiences, ...res.data?.audience])
      }

      setCampaign({
        prospects: reduceProspects(res.data.audience),
        audiences: res.data.audience.length,
      })
      // } else {
      //   setIsloading(false)
      //   setModalOpen(true)
      // }
    })
  }

  const createNewCampaign = () => {
    setCampaignLoad(true)
    createCampaign({ name: campaignName })
      .then((res: any) => {
        if (res.data) {
          setModalOpen(false)
          dispatch(setCampaignId(res.data))
          dispatch(setIsLaunchCampaign(true))
          // setCampaignId(res.data)
          // history.push(`/create-campaign/${res.data._id}/creatives`)
        }
      })
      .catch((err) => {
        setCampaignLoad(false)
      })
  }
  const inputRef = useRef<HTMLInputElement>(null)
  const handleCSV = async () => {
    audiences?.push({
      campaign_id: 56,
      id: 104,
      name: 'contacts',
      prospects_counts: 16,
      source: 'My Sphere',
      type: 'contacts',
    })
    // need to set something
  }
  const [isLoading, setIsloading] = useState(true)
  const [isShowContact, setIsShowContact] = useState(false)
  const showContact = () => {
    getCampaignAudience(campaignId.campaignId?.id, 1, 10, true).then((res) => {
      setAudiences(res?.data?.targets)
      setIsShowContact(true)
      setAudiencePaginate({
        current_page: res?.data?.current_page,
        total: res?.data?.total,
        total_page: res?.data?.total_pages,
      })
    })
  }
  useEffect(() => {
    if (!uploadModalOpen && campaignName) {
      getCampaignAudiences(
        campaignId?.campaignId?.id || campaignIdParams?.campaignId,
      )

      // campaignAudienceAPI()
    }
  }, [uploadModalOpen])

  const audienceSelection = () => {
    const [mySphereModal, setMySphereModal] = useState(false)
    if (status === 'pending') {
      return <Loading />
    }
    return (
      <div
        className="CampaignSetup__empty"
        style={{ boxSizing: 'content-box' }}
      >
        {uploadModalOpen && (
          <CreativeCampaignModal
            openCreativeGen={() => {}}
            campaignId={campaignId.campaignId || 1}
            onSubmit={() => {}}
            onClickOutside={() => {
              setUploadModalOpen(false)
            }}
            type="csv"
          />
        )}

        <div className="CampaignSetup__box  CampaignSetup__center_prospect_container">
          <div style={{ margin: '1rem auto' }}>
            <img src={target} alt="" />
            {/* <FontAwesomeIcon icon={faUserAlt} /> */}
          </div>
          <div className="CampaignSetup__boxText">
            <Text
              on="grey-500"
              size="s"
              textAlign="center"
              UNSAFE_className="CampaignSetup__boxtext_size"
            >
              Use the Prospect Finder to apply lenses and find audiences most
              relevant to your market.
            </Text>
          </div>
          <Button
            type="button"
            kind="primary"
            width="auto"
            size="l"
            UNSAFE_className="CampaignSetup__boxButtonSize"
            UNSAFE_TEXTSTYLE="CampaignSetup__boxTextButton"
            onClick={() => {
              dispatch(setIsLaunchCampaign(true))
              dispatch(setLaunchProspect(true))
              history.push('/prospects')
            }}
          >
            Launch Prospect Finder
          </Button>
        </div>
        <div className="CampaignSetup__box">
          <div
            className="CampaignSetup__box__icon_container"

            // style={{ margin: '1rem auto' }}
          >
            <img src={uploadCSV} alt="" className="__icon" />
            {/* <FontAwesomeIcon icon={faUserAlt} /> */}
          </div>
          <div className="CampaignSetup__boxText">
            <Text
              on="grey-500"
              size="s"
              textAlign="center"
              UNSAFE_className="CampaignSetup__boxtext_size"
            >
              Upload a list of your prospects and send them advertisements.
            </Text>
          </div>
          {/* <input
            type="file"
            style={{ display: 'none' }}
            id="upload-csv-file"
            accept=".csv"
          /> */}
          <Button
            type="file"
            kind="primary"
            width="auto"
            size="l"
            UNSAFE_className="CampaignSetup__boxButtonSize"
            UNSAFE_TEXTSTYLE="CampaignSetup__boxTextButton"
            onClick={uploadCSVFile}
          >
            Upload CSV
          </Button>
          {/* <<UploadBannerModal
          onClickOutside={onClickOutside}
          onSubmit={onSubmit}
        /> */}
        </div>
        <div className="CampaignSetup__box">
          <div
            className="CampaignSetup__box__icon_container"
            //  style={{ margin: '1rem auto' }}
          >
            <img src={user} alt="" style={{ height: '59px' }} />
            {/* <FontAwesomeIcon icon={faUserAlt} /> */}
          </div>
          <div className="CampaignSetup__boxText">
            <Text
              on="grey-500"
              size="s"
              textAlign="center"
              UNSAFE_className="CampaignSetup__boxtext_size"
            >
              Send advertisements to everyone in your contacts.
            </Text>
          </div>

          <Button
            type="button"
            UNSAFE_style={{
              // margin: '1rem auto',
              fontWeight: 'bold',
              fontSize: 'medium',
              padding: '3px 5rem',
              paddingInline: '12px',
              background: '#D1D1D1',
              border: 'none',
              color: '#6D6D6D',
            }}
            UNSAFE_className="CampaignSetup__boxButtonSizes"
            UNSAFE_TEXTSTYLE="CampaignSetup__boxTextButton"
            disabled={isShowContact}
            onClick={() => {
              setMySphereModal(true)
              // inputRef.current?.click()
              // showContact()
            }}
          >
            Use My Sphere
          </Button>
          {mySphereModal && (
            <Modal offClick={() => setMySphereModal(false)}>
              <VotingModal
                handleModel={() => setMySphereModal(false)}
                icons={mySphereIcon}
                title="Use My Sphere"
                subTitle="Send advertisements to everyone in your contacts."
              />
            </Modal>
          )}
          <input
            type="file"
            style={{ display: 'none' }}
            ref={inputRef}
            onInput={() => {
              handleCSV()
            }}
          />
        </div>
      </div>
    )
  }
  function audienceIcon(audience: { type: string }) {
    if (audience.type === 'prospects') {
      return uploadCSV
    }
    if (audience.type === 'audience') {
      return target
    }
    return user
  }
  function getPaginatedList() {
    if (audiencePaginate?.current_page === audiencePaginate?.total_page) {
      const subt = audiencePaginate?.current_page * 10 - audiencePaginate?.total
      return audiencePaginate?.current_page * 10 - subt
    }

    return audiencePaginate?.current_page * 10
  }

  const renderAudiences = () => {
    if (isLoading) {
      return <Loading />
    }
    if (audiences?.length) {
      return (
        <>
          <Table
            rows={audiences}
            // UNSAFE_className="Main_table_camp"

            tableRowClass="CampaignSetup__tableRow"
            checkBorder="1px solid black"
            checkBoxActive="CampaignSetup__tableCheckbox"
            preSelected={preSelectedList}
            tableHeaderNoCheckbox
            onSelect={(audienceList: any[]) => {
              setSelectedAudiences([...audienceList])
              setPreSelectedList([...audienceList])
              setCampaign({
                prospects: reduceProspects(audienceList),
                audiences: audienceList.length,
              })
            }}
            columns={[
              {
                path: 'Source',
                RowCell: (audience) => (
                  <img src={audienceIcon(audience)} alt="" />
                ),
                width: '50px',
              },
              {
                path: 'Name',
                RowCell: (audience: { name: string; source: string }) => (
                  <Text on="white" size="l" weight="bold" tag="div">
                    {getFileName(audience?.name)}
                    <br />
                    <p
                      style={{
                        fontWeight: 300,
                        margin: '0',
                        textTransform: 'capitalize',
                      }}
                    >
                      {audience?.source}
                    </p>
                  </Text>
                ),
              },
              {
                path: 'prospects',
                label: 'Prospects',
                RowCell: (audience: { prospects_counts: number }) => (
                  <Text on="white" size="l" weight="bold">
                    <FontAwesomeIcon
                      icon={faWalking}
                      color="var(--color-secondary-300)"
                    />{' '}
                    {audience?.prospects_counts}
                  </Text>
                ),
              },
            ]}
          />
          <Pagination
            title="audiences"
            currentPage={audiencePaginate?.current_page}
            loading={isLoading}
            totalItems={audiencePaginate?.total}
            totalPages={audiencePaginate?.total_page}
            rangeOfItems={10}
            onClickPrev={() =>
              setAudiencePaginate({
                ...audiencePaginate,
                current_page: audiencePaginate?.current_page - 1,
              })
            }
            onClickNext={() =>
              setAudiencePaginate({
                ...audiencePaginate,
                current_page: audiencePaginate?.current_page + 1,
              })
            }
          />
        </>
      )
    }
    return (
      <div className="CampaignSetup__NoAudience">
        <Text
          on="grey-050"
          weight="normal"
          tag="div"
          size="xl"
          textAlign="center"
          UNSAFE_className="CampaignSetup__NoAudience_titles"
        >
          You don&apos;t have a saved audience.
          <p
            style={{
              fontSize: '16px',
              fontWeight: 400,
              color: '#6D6D6D',
              marginTop: '-6px',
            }}
          >
            Use the prospect activity tool or upload your own audience to get
            started
          </p>
        </Text>
      </div>
    )
  }

  return (
    <div className="CampaignSetup">
      {modalOpen && !isLaunchCampaign.isLaunchCampaign ? (
        <Modal offClick={() => {}} className="SaveAudienceModal">
          <div className="OnboardingModal__welcome ListingModalTagBg SaveAudiencePosition">
            <div className="OnboardingModal__welcomeHeader CampaignSetup__modalSpace">
              <img
                src={cross}
                onClick={() => {
                  history.goBack()
                }}
                alt="cross"
                className="PersonalInfoForm__cross"
                role="presentation"
              />
              <div className="CampaignSetup__heading_container">
                <Text
                  on="white"
                  // weight="normal"
                >
                  Name your Campaign
                </Text>
              </div>
              <br />
              <div
                style={{
                  // width: '280px',
                  margin: '0 30px',
                  textAlign: 'center',
                }}
              >
                <div style={{ textAlign: 'left' }}>
                  <Text
                    on="grey-500"
                    size="m"
                    UNSAFE_style={{ marginBottom: '0.5rem' }}
                  >
                    {' '}
                  </Text>

                  {/* <InputBox
                    placeHolder="Enter Campaign Name"
                    border="1px solid "
                    wdt="100%"
                    value={campaignName}
                    paddingLeft="10px"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const newString = e.target.value.replace(/\s+/g, ' ')
                      setCampaignName(newString)
                    }}
                  /> */}
                  <LableInputField
                    title="Campaign Name"
                    placeholder="Enter Campaign Name"
                    value={campaignName}
                    style={{ paddingLeft: '20px' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const newString = e.target.value.replace(/\s+/g, ' ')
                      setCampaignName(newString)
                    }}
                  />
                  <Button
                    data_testid="button-sub"
                    kind="primary"
                    size="xl"
                    // width="70%"
                    weight="bolder"
                    UNSAFE_style={{
                      margin: '2rem auto 0 auto',
                      marginTop: '25px',
                      width: '309px',
                      height: '54px',
                    }}
                    onClick={createNewCampaign}
                    disabled={isCampaignLoad || campaignName?.length < 6}
                    loading={isCampaignLoad}
                  >
                    Create New Campaign
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}
      <MaxHeightContainer
        UNSAFE_CLASSNAME="bg-content-color"
        fullHeight
        header={
          <PageHeader
            // LeftContent_ClassName="CampaignSetup__titleSpace"
            // style={{ height: '84px', background: 'var(--color-white)' }}
            UNSAFE_CLASSNAME_GRID="CampaignSetup__titleSpace"
            title={
              campaignName && !modalOpen
                ? campaignName
                : isLaunchCampaign?.campaignId?.name || ''
            }
            UNSAFE_CLASSNAME_TITLE="CampaignCheckout__title "
            actions={[
              <ProgressStepper
                tagNames={['Audience', 'Creative', 'Checkout', 'Submit']}
                totalSteps={4}
                activeStep={1}
                color="#1BA1C5"
              />,
            ]}
            subTitle=" "
            actionsStyle="CampaignSetup__progress mt-17"
            UNSAFE_CLASSNAME="mb-8px CampaignSetup__header"
          />
        }
        footer={
          <CampaignCreationFooter>
            {/* <Button
              size="l"
              onClick={() => browserLog.info('back')}
              UNSAFE_className="CampaignSetup__backbutton"
            >
              Back
            </Button> */}
            {/* <Button
              size="l"
              UNSAFE_className="campaignBack"
              // onClick={() => browserLog.info('back')}
              // disabled
              onClick={() => browserLog.info('back')}
            >
              Back
            </Button> */}
            <div
              className="CampaignSetup__bottom_details"
              // style={{
              //   width: '100%',
              //   display: 'flex',
              //   justifyContent: 'center',
              //   marginLeft: '4rem',
              //   // flexDirection: 'row',
              // }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <FontAwesomeIcon
                    style={{ color: '#FFAB03' }}
                    icon={faWalking}
                  />
                  <b
                    style={{
                      paddingLeft: '0.5rem',
                      fontSize: '20px',
                      fontWeight: '400',
                    }}
                  >
                    {sum}
                  </b>{' '}
                  Prospect Selected
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Text
                    on="primary-lighter"
                    size="xs"
                    UNSAFE_className="CampaignSetup__targeted_audience"
                  >
                    from {selectedAudiences.length} Targeted Audience
                  </Text>
                </div>
              </div>

              {/* <p style={{ margin: '0' }}>{selectedAudiences.length}</p> */}
            </div>
            <div className="CampaignSetup__next-button">
              <Button
                size="l"
                UNSAFE_style={{ width: '212.76px', height: '54.75px' }}
                UNSAFE_className="CampaignSetup__campaign_use_total_prospects"
                // kind="primary"
                onClick={handleNextClick}
                disabled={
                  !campaignId.campaignId || !selectedAudiences.length || sum < 1
                }
              >
                <span>
                  Use <b>{sum} Prospects</b>
                </span>
              </Button>
            </div>
          </CampaignCreationFooter>
        }
      >
        <Layout>
          <CampaignCreationWrapper>
            <div>
              <div className="CampaignSetup__audiences">
                <div>
                  <Text
                    on="grey-500"
                    size="xxl"
                    weight="bold"
                    UNSAFE_className="heading"
                  >
                    Create a new Target Audience or pick one from the list below
                  </Text>
                </div>
                <div style={{ marginTop: '7px', marginBottom: '0.5rem' }}>
                  <Text
                    on="white"
                    size="s"
                    UNSAFE_className="CampaignSetup__subheading"
                  >
                    Your campaign needs an audience, choose one or more audience
                    libraries
                  </Text>
                </div>

                <div className="CampaignSetup__audience-list">
                  {audienceSelection()}
                  {renderAudiences()}
                </div>
              </div>
            </div>
            {campaign && <SummaryCart campaign={campaign} />}
          </CampaignCreationWrapper>
        </Layout>
      </MaxHeightContainer>
    </div>
  )
}
