/* eslint-disable */

import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useLocation, Redirect, Link, useHistory } from 'react-router-dom'
import { Splash } from 'Pages'
import { Button, Form } from '@eltoro-ui/components'
import { useForm, useValidatedState } from '@eltoro-ui/hooks'
import {
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faLockAlt,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from 'AppContext'
import {
  ErrorMsg,
  InputBox,
  LoginFooter,
  LoginHeader,
  OnboardingModal,
  Tooltip,
} from 'Components'
import { userRole } from 'Helpers'
import { useDispatch } from 'react-redux'
import { setUser } from 'Redux/actions'
import { userDetail, userLogin } from 'Requests'
import ReactGA from 'react-ga'
import { LableInputField } from 'Components/LableInputField'
import logobefore from '../../assets/Images/honeycomb.svg'
import logoafter from '../../assets/Images/honeycomb_left.svg'
import logo from '../../Images/Icon_color@2x.png'
import User_icon from '../../Images/user.png'
import Warning from '../../Images/warning.png'
import UserWaring from '../../Images/userv.png'
import PassWarning from '../../Images/lockw.png'
import Password_icon from '../../Images/lock.png'
import './Login.scss'
import '../../Components/LableInputField/LableInputField.scss'
import toast from 'react-hot-toast'

export const Login = () => {
  const [loggedIntoMock, setLoggedIntoMock] = useState<boolean>(false)
  const [email, setEmail, isValidEmail] = useValidatedState('', 'email')
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
  const [password, setPassword] = useState<string>('')
  const [eyeShow, setEyeShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const { login } = useContext(AppContext)

  const [showPass, setshowPass] = useState(false)

  const { formData, isValidForm, touched } = useForm({
    email,
    password,
  })
  const [resp, setResp] = useState(Boolean)
  const [wrongPassword, setWrongPassword] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)
  const required = ['email', 'password']
  const { validForm, missingFields } = isValidForm([...required, isValidEmail])
  const location = useLocation()
  const [valid, setValid] = useState(false)
  // const history =useHistory()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [])

  const dispatch = useDispatch()
  const onSubmit = () => {
    setLoading(true)
    setResp(false)
    // e.preventDefault()
    const body = { username: email, password }

    userLogin(body)
      .then((res: any) => {
        if (res.data.token) {
          localStorage.setItem('beewo_token', res.data.token)
          localStorage.setItem('userMail', email)
          userDetail(res?.data?.token)
            .then(async (respon) => {
              const value = respon
              localStorage.setItem('beewo_token', res.data.token)

              if (respon?.data?.email) {
                const userData = {
                  user_role: userRole(respon?.data?.is_super),

                  ...respon.data,
                }
                dispatch(setUser(userData))
                // await getNotificationSettings().then((item) =>
                //   dispatch(
                //     // setUserNotification(item?.data?.notifications[0]?.status),
                //     setUserNotification(1),
                //   ),
                // )
                // localStorage.setItem('beewo_user', res.data.token)
                //

                setResp(true)
                setLoggedIntoMock(true)
                setValid(true)
                setIsSubmit(true)
                return <Redirect to="/prospects" />
              }
              setOpenOnboardingModal(true)

              return false
            })
            .catch((e) => {
              toast.error(e)
            })
        } else {
          setResp(false)
          setLoggedIntoMock(false)
          setValid(false)
          setIsSubmit(true)
          setLoading(false)
          return { loggedIn: false }
        }
        return false
        // if (res.data.detail === 'Inactive user') {
        //   setResp(false)
        //   setLoggedIntoMock(false)
        //   setValid(false)
        //   setIsSubmit(true)
        //   setOpenOnboardingModal(true)
        //   return { loggedIn: false }
        // }
      })
      .catch((err) => {
        if (err.detail === 'Inactive user') {
          setOpenOnboardingModal(true)
        }
        setResp(false)
        setLoggedIntoMock(false)
        setValid(false)
        setIsSubmit(true)
        setLoading(false)
        return { loggedIn: false }
      })
  }
  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          padding="9px 5px"
          text="Wrong username or password"
          topAbsolute="-91px"
          rightAbsolute="-81px"
        />
      </div>
    )
  }
  const { keycloak, initialized } = useKeycloak()
  const history = useHistory()
  const focusInput = useRef(null)
  // useEffect(() => focusInput, [])

  // const loginKC = useCallback(() => {
  //   keycloak?.login({ redirectUri: 'http://localhost:3000/dashboard' })
  // }, [keycloak])
  const key = keycloak
  if (location.pathname === '/login') {
    if (keycloak.authenticated || loggedIntoMock) {
      return <Redirect to="/prospects" />
    }
  }
  // if (process.env.NODE_ENV === 'production' && !initialized) {
  //   return <Splash />
  // }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(false)
    setPassword(e.target.value)
    setIsSubmit(false)

    if (e.target.value) {
      setWrongPassword(false)
    } else {
      setWrongPassword(true)
    }
  }
  const ForgotPassword = () => {
    history.push('/email-verify')
  }

  if (location.pathname === '/login/sa') {
    history.push('/login/super-admin')
  }
  const [superAdminRoute, setsuperAdminRoute] = useState(
    location.pathname === '/login/super-admin' && true,
  )

  return (
    <div className="" style={{ background: 'white' }}>
      <LoginHeader />
      {openOnboardingModal && (
        <OnboardingModal setModalOpen={setOpenOnboardingModal} />
      )}
      <div className="Login">
        <img
          id="logoBefore"
          src={logobefore}
          alt="beewo-logo-back"
          className="before-login-bg"
        />

        <div className="Login_all_content">
          <div className="Login__logo">
            <img
              src={logo}
              alt="beewo-logo"
              style={{
                height: '111px',
                objectFit: 'contain',
              }}
            />
          </div>
          <h3 className="login_heading">Login</h3>

          <div className="Login__form">
            <Form
              onSubmit={onSubmit}
              required={required}
              valid={validForm}
              missingFields={missingFields}
              touched={touched}
            >
              {/* <form onSubmit={(e) => onSubmit(e)}> */}
              <LableInputField
                title="Username"
                placeholder="Enter your username"
                isError={isSubmit && !resp}
                type="Email"
                iconLeft={
                  <img
                    className="start_icon"
                    style={{ marginTop: '7px', width: '24px' }}
                    src={isSubmit && !resp ? UserWaring : User_icon}
                    alt="icon"
                  />
                }
                iconRight={
                  <img
                    className="start_icon"
                    style={{ marginTop: '7px', width: '24px' }}
                    src={isSubmit && !resp ? Warning : ' '}
                    // alt="icon"
                  />
                }
                onChange={(e) => {
                  setEmail(e.target.value)
                  setIsSubmit(false)
                  setLoading(false)
                }}
                // lableStyle={{ top: '22px', left: '15px' }}
              />

              {/* <InputBox
                type="text"
                placeHolder="Email"
                // Height="50px"
                // wdt="350px"
                data_testid="email-input"
                Input__ClassName="Login__InputBox"
                icon={
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      fontSize: '23px',
                      // color: 'gray',
                      margin: '13px 5px 0px 12px',
                    }}
                  />
                }
                isError={isSubmit && !resp}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setIsSubmit(false)
                  setLoading(false)
                }}
                validIcon={
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{
                      fontSize: '23px',
                      margin: '13px 5px 0px 12px',
                    }}
                  />
                }
              /> */}
              {isValidEmail ? (
                ''
              ) : (
                <ErrorMsg
                  data_testid="error-email-input"
                  title="Invalid email"
                  icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                />
              )}
              {isSubmit && !resp ? <ErrorToolbar /> : ''}
              <br />
              <div style={{ position: 'relative' }}>
                <LableInputField
                  title="Password"
                  placeholder="Enter your password"
                  id="pass"
                  type={showPass === false ? 'password' : 'text'}
                  iconLeft={
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={isSubmit && !resp ? PassWarning : Password_icon}
                      alt="icon"
                    />
                  }
                  // iconRight={
                  //   <img
                  //     className="start_icon"
                  //     style={{ marginTop: '7px', width: '24px' }}
                  //     src={isSubmit && !resp ? Warning : ' '}
                  //     // alt="icon"
                  //   />
                  // }
                  isError={isSubmit && !resp}
                  onChange={(e) => {
                    handleChange(e)
                    // setPassword(e.target.value)
                  }}
                  // imgIconStyle={{ marginTop: '7px', width: '24px' }}
                  // lableStyle={{ top: '22px', left: '15px' }}

                  EyePassIcon={
                    showPass ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          fontSize: '21px',
                          color: '#b3b0b0',
                          margin: '19px 0 0 12px',
                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          // document.getElementById('pass')?.focus()
                          event.stopPropagation()
                          event.preventDefault()
                          if (focusInput.current) {
                            // @ts-ignore
                            focusInput.current.focus()
                          }
                          setshowPass(false)
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        style={{
                          fontSize: '21px',
                          color: '#b3b0b0',
                          margin: '19px 0 0 12px',
                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          document.getElementById('pass')?.focus()
                          if (focusInput.current) {
                            //  @ts-ignore
                            focusInput.current.focus()
                          }
                          setshowPass(true)
                        }}
                      />
                    )
                  }
                />
                {/* <InputBox
                  reference={focusInput}
                  type={showPass ? 'text' : 'password'}
                  placeHolder="Password"
                  isError={isSubmit && !resp}
                  // Height="50px"
                  // wdt="350px"
                  Input__ClassName="Login__InputBox"
                  icon={
                    <FontAwesomeIcon
                      icon={faLockAlt}
                      style={{
                        fontSize: '23px',
                        // color: 'gray',
                        margin: '13px 0 0 12px',
                      }}
                    />
                  }
                  EyePassIcon={
                    showPass ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          fontSize: '21px',
                          color: 'gray',
                          margin: '14px 0 0 12px',
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          if (focusInput.current) {
                            // @ts-ignore
                            focusInput.current.focus()
                          }
                          setshowPass(false)
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        style={{
                          fontSize: '21px',
                          color: 'gray',
                          margin: '14px 0 0 12px',
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          if (focusInput.current) {
                            //  @ts-ignore
                            focusInput.current.focus()
                          }
                          setshowPass(true)
                        }}
                      />
                    )
                  }
                  onChange={(e) => {
                    handleChange(e)
                    // setPassword(e.target.value)
                  }}
                /> */}
              </div>

              <div className="Login__options">
                {/* not functional until we get api...just toggles on and off right now */}
                {/* <Checkbox
                checked={isChecked}
                size="m"
                onChange={() => {
                  setIsChecked((prevIsChecked) => !prevIsChecked)
                }}
                label="Remember Me"
              /> */}

                <button
                  style={{ margin: '8px 0' }}
                  onClick={ForgotPassword}
                  className="forgotPassword"
                  type="button"
                >
                  Forgot Password?
                </button>
              </div>
              <Button
                UNSAFE_style={{
                  borderRadius: '10px',
                  padding: '26px 0',
                  width: '100%',
                  justifyContent: 'center',
                }}
                type="submit"
                kind="primary"
                size="l"
                weight="bold"
                fonts="15px"
                name="sign_in"
                disabled={wrongPassword || !isValidEmail || !email || loading}
                loading={loading}
                children="Sign In"
              />
              {!superAdminRoute && (
                <div className="loginBottom">
                  Don&apos;t have an account?
                  <button
                    onClick={() => {
                      history.push('/signup')
                    }}
                    className="Login__signupAcc"
                    type="button"
                  >
                    Signup
                  </button>
                </div>
              )}
              <div
                className="loginBottom"
                style={{ fontSize: '14px', color: '#6D6D6D' }}
              >
                <p>Having issues with your account? </p>
                <p>
                  Contact{' '}
                  <Link
                    to="/login"
                    className="loginBottomHelp"
                    style={{ paddingRight: '4px', color: '#FFAB03' }}
                  >
                    help@beewo.com
                  </Link>
                  for support
                </p>
              </div>
              {/* </form> */}
            </Form>
          </div>
          <img
            src={logoafter}
            id="logoAfter"
            alt="beewo-logo-back"
            className="after-login-bg"
          />
        </div>
      </div>

      <LoginFooter loginStyle />
    </div>
  )

  function newFunction(): any[] {
    return useState<boolean>(false)
  }
}
