import React from 'react'
import './Loading.scss'

export const Loading = (props?: any) => {
  return (
    <div style={props.style} className="Loading">
      <span className="Loading__dot" />
      <span className="Loading__dot" />
      <span className="Loading__dot" />
    </div>
  )
}
