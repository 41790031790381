import React, { useState } from 'react'
import { Button, Text } from '@eltoro-ui/components'
import { saveContacts } from 'Requests'
import { toast } from 'react-hot-toast'
import { getBrowserInfo } from '@eltoro-ui/hooks/useForm/helper'

export const StepTwo = ({ onClose }: { onClose: (e?: any) => void }) => {
  const [contacts, setContacts] = useState('')
  const [loading, setLoading] = useState(false)

  const onSave = () => {
    setLoading(true)
    saveContacts({
      body: contacts.split('\n'),
      device: `Web-${getBrowserInfo()}`,
    })
      .then((res) => {
        if (!res?.detail) {
          toast.success('Upload Successfully')
          onClose(true)
          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error('Invalid csv')
        setLoading(false)
      })
  }

  return (
    <>
      <Text tag="div" on="secondary-200" size="xxl">
        STEP 2
      </Text>
      <Text on="white" size="xl" tag="div">
        <b>Enter Your Contact</b>
      </Text>

      <div className="text_area_container">
        <textarea
          value={contacts}
          onChange={(e) => setContacts(e.target.value)}
          className="text_area"
          // placeholder={`First Name, Last Name, Address, Zip Code, Phone Number, Email, City \n \nJohn, Smith, 926 River Cove Ave Orlando FL , 32825 , +1 234 698 5874, john@gmail.com, Orlando
          placeholder={`first name, last name, address, zip, phone number, email, city \n \nJohn, Smith, 926 River Cove Ave Orlando FL, 32825, +1 234 698 5874, john@gmail.com, Orlando
        `}
        />
      </div>
      <div className="UploadBannerModal__footer">
        <Button kind="primary" onClick={onSave} disabled={!contacts || loading}>
          Upload
        </Button>
      </div>
    </>
  )
}
