import React, { FC, useState } from 'react'
import { Form, FormItem, Button, Spacer } from '@eltoro-ui/components'
import {
  useForm,
  useValidatedMask,
  useMask,
  useValidation,
  FormObjectType,
} from '@eltoro-ui/hooks'
import { StateSelect } from 'Components'
import { isValidExpiryDate } from 'Helpers'
import { CardFormInput } from './CardFormInput'
import './AddCardForm.scss'

type AddCardFormType = {
  onSave: (value: FormObjectType) => void
}

export const AddCardForm: FC<AddCardFormType> = ({ onSave }) => {
  const [nick_name, setNickName] = useState('')
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [street_address, setStreetAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState<string>('')

  const [card_number, setCardNumber] = useMask('', 'credit-card')
  const [expiry, setExpiry] = useMask('', 'expiration-date')
  const [zip, setZipCode, isValidZip] = useValidatedMask({
    initialState: '',
    validationMask: 'zip-code',
  })

  const isValidCard = useValidation('credit-card')
  const isValidExpiry =
    useValidation(/^(0[1-9]|1[0-2])\/?[0-9]{2}$/) && isValidExpiryDate(expiry)

  const { formData, isValidForm, touched } = useForm({
    card_number,
    expiry,
    nick_name,
    first_name,
    last_name,
    street_address,
    city,
    state,
    zip,
  })

  const handleSubmit = () => {
    onSave(formData)
  }

  const required = [
    'card_number',
    'expiry',
    'first_name',
    'last_name',
    'street_address',
    'city',
    'state',
    'zip',
  ]
  const { validForm, missingFields } = isValidForm([
    ...required,
    isValidZip,
    isValidExpiry,
    isValidCard(card_number.split(' ').join('')),
  ])

  return (
    <div className="AddCardForm">
      <Form
        onSubmit={handleSubmit}
        required={required}
        valid={validForm}
        touched={touched}
        missingFields={missingFields}
        UNSAFE_className="AddCardForm__form"
      >
        <CardFormInput
          htmlFor="nick_name"
          label="Card nickname"
          valid={!!nick_name}
          value={nick_name}
          setter={setNickName}
        />
        <CardFormInput
          htmlFor="card_number"
          label="Card number"
          errorMessage="Please enter valid card number"
          valid={isValidCard(card_number.split(' ').join(''))}
          value={card_number}
          setter={setCardNumber}
        />
        <CardFormInput
          htmlFor="expiry"
          label="Valid Thru"
          errorMessage="Please enter a valid expiration date"
          valid={isValidExpiry}
          value={expiry}
          setter={setExpiry}
        />
        <Spacer />
        <CardFormInput
          htmlFor="first_name"
          label="First name"
          errorMessage="Please enter a first name"
          valid={!!first_name}
          value={first_name}
          setter={setFirstName}
        />
        <CardFormInput
          htmlFor="last_name"
          label="Last name"
          errorMessage="Please enter a last name"
          valid={!!last_name}
          value={last_name}
          setter={setLastName}
        />
        <CardFormInput
          htmlFor="street_address"
          label="Street address"
          errorMessage="Please enter a street address"
          valid={!!street_address}
          value={street_address}
          setter={setStreetAddress}
        />
        <CardFormInput
          htmlFor="city"
          label="City"
          errorMessage="Please enter a city"
          valid={!!city}
          value={city}
          setter={setCity}
        />
        <FormItem
          htmlFor="state"
          label="State"
          errorMessage="Please enter a state"
          valid={!!state}
        >
          <StateSelect
            defaultValue={state}
            onChange={(value) => setState(value)}
          />
        </FormItem>
        <CardFormInput
          htmlFor="zip"
          label="Zip code"
          errorMessage="Please enter a valid zip code"
          valid={isValidZip}
          value={zip}
          setter={setZipCode}
        />
        <Button
          type="submit"
          kind="primary"
          disabled={missingFields.length > 0}
          UNSAFE_className="AddCardForm__save-btn"
        >
          Save card
        </Button>
      </Form>
    </div>
  )
}
