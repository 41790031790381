import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, FlexBox, Spacer, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { PageHeader, LabelValuePair, Loading, Layout } from 'Components'
import { getCamelCase, getLocalDate, getLocalTime } from 'Helpers'
import {
  getListingById,
  putNotesByListingId,
} from 'Requests/Request_Methods/listingMethods'
import { useAsync } from '@eltoro-ui/hooks'
import { APIFeatureType } from 'types'
import toast from 'react-hot-toast'
import Oval from '../../assets/Images/Oval.png'
import redOval from '../../assets/Images/redOval.png'
import pendingicon from '../../assets/Images/inreview.png'
import tickmark from '../../assets/Images/tickmark.png'
import soldstatus from '../../assets/Images/soldstatus.png'
import { ListingDetails, ListingPhotosMap } from './components'
import './ListingView.scss'

const statusButtonStyle = (stausbadge: any) => {
  if (stausbadge === 'Active' || stausbadge === 'ACTIVE') {
    return 'MyListings__status-badge_active'
  }
  if (stausbadge === 'Closed') {
    return 'MyListings__status-badge_closed'
  }
  if (stausbadge === 'Pending') {
    return 'MyListings__status-badge_pending'
  }
  if (stausbadge === 'Sold') {
    return 'MyListings__status-badge_sold'
  }
  return 'MyListings__status-badge'
}
const statusButtonIcon = (stausbadge: any) => {
  if (stausbadge === 'Active' || stausbadge === 'ACTIVE') {
    return tickmark
  }

  if (stausbadge === 'Closed') {
    return redOval
  }
  if (stausbadge === 'Sold') {
    return soldstatus
  }
  return pendingicon
}

export const ListingView = () => {
  const { listingId } = useParams<{ listingId: string }>()
  const [listing, setListing] = useState<APIFeatureType>()
  const [loading, setLoading] = useState<boolean>(false)

  const { status, value } = useAsync(() => getListingById(listingId))

  useEffect(() => {
    if (value) setListing(value.data)
  }, [value])
  const [updatedNotes, setupdatedNotes] = useState(false)

  useEffect(() => {
    if (updatedNotes) {
      getListingById(listingId)
        .then((res) => {
          setListing(res.data)
          setupdatedNotes(false)
        })
        .catch((err) => {
          setupdatedNotes(false)
        })
    }
  }, [updatedNotes])
  if (status === 'error')
    return (
      <div className="ListingView__error">
        <Text on="white" kind="warning">
          Error retrieving this listing
        </Text>
      </div>
    )
  if (!listing || status === 'pending') return <Loading />

  const { updated, street, city, zip, sub_divison, state } = listing

  const SubmitNotes = (notes: string) => {
    setLoading(true)
    putNotesByListingId(listingId, { note: notes })
      .then((resp) => {
        toast.success('Notes has been added')

        setupdatedNotes(true)
      })
      .catch((error) => {
        toast.error('Notes are not adding')
      })
    setLoading(false)
  }

  return (
    <div className="ListingView">
      {/* <div className="ListingView__go-back"></div> */}
      <PageHeader
        LeftContent_ClassName="LeftContent_main_div"
        UNSAFE_CLASSNAME="ListingView__page-header"
        title={
          <div className="ListingView__title_wrapper">
            <FlexBox UNSAFE_className="street_listing">{street}</FlexBox>{' '}
          </div>
        }
        statusbox={
          <>
            <div className="" style={{ marginLeft: '2rem', marginTop: '3px' }}>
              <div
                className={`${statusButtonStyle(
                  listing.status,
                )} MyListings__status-badge`}
              >
                <img
                  className="ListingView__Oval"
                  src={statusButtonIcon(listing.status)}
                  alt="Oval"
                />
                {listing.status}
              </div>
            </div>
          </>
        }
        // subTitle
        UNSAFE_className_SUBTITLE="ListingView__sub-title"
        subTitle={`Last Updated ${getLocalDate(updated)} - ${getLocalTime(
          updated,
        )}`}
        actions={[
          <FlexBox flexWrap="wrap" UNSAFE_className="city_and_zip_container">
            <LabelValuePair
              UNSAFE_className="ListingView__actions-colors"
              UNSAFE_className_Value="ListingView__actions-colors_value"
              label="State"
              value={state ? getCamelCase(state) : '-'}
            />
            <Spacer width="2rem" />
            <LabelValuePair
              UNSAFE_className="ListingView__actions-colors"
              UNSAFE_className_Value="ListingView__actions-colors_value"
              label="City"
              value={getCamelCase(city)}
            />
            <Spacer width="2rem" />
            <LabelValuePair
              UNSAFE_className="ListingView__actions-colors"
              UNSAFE_className_Value="ListingView__actions-colors_value"
              label="Zipcode"
              value={zip}
            />
            <Spacer width="1rem" />
          </FlexBox>,
        ]}
        icon={
          <Button
            size="l"
            type="button"
            UNSAFE_className="icon-headers listing-header-icon"
            UNSAFE_TEXTSTYLE="icon-header-text"
            to="/my-listings"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        }
      />
      <Layout>
        <div className="ListingView__body">
          <div className="ListingView__left-column">
            <ListingDetails
              listing={listing}
              onNotesSubmit={(notes) => SubmitNotes(notes)}
              loading={loading}
            />
          </div>
          <div className="ListingView__right-column">
            <ListingPhotosMap listing={listing} />
          </div>
        </div>
      </Layout>
    </div>
  )
}
