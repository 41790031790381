import React from 'react'
import { Text, Modal, Button } from '@eltoro-ui/components'
import './Modal.scss'
import { APIFeatureType } from 'types'

import Leftvoting from '../../assets/Images/leftvoting.svg'
import Rightvoting from '../../assets/Images/rightvoting.svg'

export const CustomModal: React.FC<{
  heading?: string
  buttonText?: string
  image?: string
  onClose: any
}> = ({ heading, buttonText, image, onClose }) => {
  return (
    // <div className="FeedbackModal">
    //   <Modal className="FeedbackModal__mainModal">
    //     <img className="leftvoting_icon1" src={Leftvoting} alt="icon" />
    //     <div className="FeedbackModal__image">
    //       <img src={image} alt="modalImage" />
    //     </div>
    //     <Text UNSAFE_className="FeedbackModal__title" on="grey-500">
    //       {heading}
    //     </Text>
    //     <Button
    //       type="submit"
    //       kind="primary"
    //       UNSAFE_className="FeedbackModal__doneBtn"
    //       onClick={onClose}
    //     >
    //       {buttonText}
    //     </Button>
    //     <img className="rightvoting_icon1" src={Rightvoting} alt="icon" />
    //   </Modal>
    // </div>
    <Modal>
      <div className="main_voting1">
        <img className="leftvoting_icon1" src={Leftvoting} alt="icon" />

        <img className="main_voting__icon" src={image} alt="" />
        <p
          className="voting_sub_heading1"
          style={{ padding: '0px 10%', color: '#6D6D6D' }}
        >
          {heading}
        </p>

        <Button
          UNSAFE_className="Voting_submit"
          kind="primary"
          size="s"
          weight="bolder"
          fonts="15px"
          name="sign_in"
          onClick={onClose}
          // disabled={}
        >
          {buttonText}
        </Button>
        <img className="rightvoting_icon1" src={Rightvoting} alt="icon" />
      </div>
    </Modal>
  )
}
