import React, { ReactNode } from 'react'
import { Text } from '@eltoro-ui/components'
import './TableSelectionWrapper.scss'

type TableSelectionWrapperType<T> = {
  children: ReactNode
  selectionType: string
  selections: T[]
  actions: ReactNode
  UNSAFE_CLASSNAME_WRAPPER?: string
}

export const TableSelectionWrapper = <T extends {}>({
  children,
  selectionType,
  selections,
  actions,
  UNSAFE_CLASSNAME_WRAPPER,
}: TableSelectionWrapperType<T>) => {
  return (
    <div className="TableSelectionWrapper">
      {selections.length > 0 ? (
        <div className="TableSelectionWrapper__selection-header">
          <Text
            on="white"
            UNSAFE_className="TableSelectionWrapper__selection_text"
          >
            {`${selections.length} ${selectionType}${
              selections.length > 1 ? 's' : ''
            } selected`}
          </Text>
          <div className="TableSelectionWraper__actions">
            {Array.isArray(actions)
              ? actions.map((action, i) => (
                  <React.Fragment key={i}>{action}</React.Fragment>
                ))
              : actions}
          </div>
        </div>
      ) : (
        <div className="TableSelectionWrapper__selection-placeholder" />
      )}
      <div
        className={`TableSelectionWrapper__table-wrapper ${
          selections?.length > 0
            ? 'TableSelectionWrapper__table-wrapper--selection'
            : ''
        } ${UNSAFE_CLASSNAME_WRAPPER}`}
      >
        {children}
      </div>
    </div>
  )
}
