import React, { FC } from 'react'
import { Section } from 'Components'
import { Text } from '@eltoro-ui/components'
import './FinishedCampaigns.scss'
import { PieChart } from 'react-minimal-pie-chart'
import nodata from '../../../../assets/Images/nodata.png'

type FinishedCampaignsDataType = {
  finished_campaign: number
  total: number
}

export const FinishedCampaigns: FC<{ data: FinishedCampaignsDataType }> = ({
  data,
}) => {
  const finishedCampaignsDataSchema = [
    { value: data?.total - data?.finished_campaign, color: '#CFD8DC' },
    { value: data?.finished_campaign, color: '#FFCA0E' },
  ]
  const isFinishedCampaignsDataSchema = finishedCampaignsDataSchema.reduce(
    (item) => item,
  )
  const getChart = () => {
    return (
      <PieChart
        rounded
        data={finishedCampaignsDataSchema}
        lineWidth={25}
        labelPosition={0}
        className="finished_campaigns_container__pie_className"
        label={({ dataIndex, dataEntry }) =>
          dataIndex === 1 ? (
            <>
              <text
                dominantBaseline="central"
                x="50"
                y="50"
                dx="0"
                dy="-15"
                textAnchor="middle"
                style={{ fontSize: '33px', fill: '#FFCA0E', fontWeight: '700' }}
              >
                {dataEntry.value}
              </text>
              <text
                dominantBaseline="central"
                x="50"
                y="50"
                dx="0"
                dy="13"
                textAnchor="middle"
                style={{ fontSize: '8px', fontWeight: 700 }}
              >
                FINISHED
              </text>
              <text
                dominantBaseline="central"
                x="50"
                y="50"
                dx="0"
                dy="22"
                textAnchor="middle"
                style={{ fontSize: '8px', fontWeight: 700 }}
              >
                OUT OF {data?.total}
              </text>
            </>
          ) : null
        }
      />
    )
  }

  return (
    <Section
      title="# Of Finished Campaigns"
      UNSAFE_className_container="finished_campaign_section"
      UNSAFE_className_text="FinishedCampaigns__title"
    >
      {isFinishedCampaignsDataSchema?.value === 0 ? (
        <div className="noData">
          <img style={{ width: '100px' }} src={nodata} alt="nodata" />
          <Text on="tertiary-300" UNSAFE_className="noDataText">
            No Data Found
          </Text>
        </div>
      ) : (
        <div className="finished_campaigns_container">{getChart()}</div>
      )}
    </Section>
  )
}
