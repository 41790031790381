/* eslint-disable */
import React, { useState, useRef } from 'react'
import { Button, Text } from '@eltoro-ui/components'
import confirmleftcomb from '../../assets/Images/logoutleftcomb.png'
import confirmrightcomb from '../../assets/Images/logoutrightcomb.png'
import './ConfrimModal.scss'

type TextInputType = {
  title?: string
  yesOnclick?: () => void
  noOnclick?: () => void
  loading?:boolean
  disable?:boolean
}

export const ConfrimModal: React.FC<TextInputType> = ({
  title,
  yesOnclick,
  noOnclick,
  loading,
  disable,
}) => {
  return (
    <>
      <div className="confirm_main">
        <img
          className="confirmrightcomb"
          src={confirmrightcomb}
          alt="confirmleftcomb"
        />
        <Text UNSAFE_className="confirm_main__heading" on="tertiary-300">
          {title}
        </Text>
        <div className="confirm_main__button">
          <Button
            // UNSAFE_className="confirm_button_modal"
            UNSAFE_className="confirm_main__yes_button_modal"
            kind="primary"
            loading={loading}
            disabled={disable}
            onClick={yesOnclick}
            // disabled={loading}
          >
            Yes
          </Button>
          <Button
            kind="text"
            UNSAFE_className="confirm_main__no_button_modal"
            onClick={noOnclick}
            // disabled={loading}
          >
            Cancel
          </Button>
        </div>
        <img
          className="confirmleftcomb"
          src={confirmleftcomb}
          alt="lconfirmleftcomb"
        />
      </div>
    </>
  )
}
