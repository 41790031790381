/* eslint-disable react/self-closing-comp */
import { Select } from '@eltoro-ui/components'
import React, { useEffect, useRef, useState } from 'react'
import './CustomMapCanvasPopup.scss'

// @ts-ignore
const MarkerWrap = React.createRef(null)

export const CustomMapCanvasPopup: React.FC<{
  feature?: any
  prospect?: boolean
  searchClicked: boolean
  listingData?: []
  setListingData?: (val: object) => void
  setListings?: (val: object) => void
}> = ({
  feature,
  prospect = true,
  setListings,
  setListingData,
  listingData,
  searchClicked,
}) => {
  const Hist = [0.4, 0.2, 0, 0, 0, 0, 0]
  // const max = feature?.buzz_history && Math.max(...feature.buzz_history)
  const count = useRef<number>(0)
  const [day, setDay] = useState('')
  const histogram = localStorage.getItem('Histogram')
  // @ts-ignore
  const [selectedVal, setSelectedVal] = useState(false)
  const [currentFruit, setCurrentFruit] = useState('oranges')

  return (
    <div className="MapCustomPopup">
      {/* <button
        type="button"
        onClick={() => {
          abcd('hello')
        }}
      >
        Hellow
      </button> */}
      <div
        // style={{ background: getIconBuzzColor(feature) }}
        className="MapCustomPopup__header"
        style={{
          width: 'max-content', // whiteSpace: 'break-spaces',
          padding: prospect ? '1rem' : '0.8rem 0.8rem 0rem 0.8rem',
        }}
      >
        <b>
          {' '}
          {feature?.unique_observations_total > 0
            ? feature.unique_observations_total
            : feature.unique_visitor_count}{' '}
          Prospects
        </b>
        {/* <br /> */}
        {/* {feature.street} */}

        {/* <Text
          // UNSAFE_style={{ color: '#515A5C', marginLeft: '13px' }}
          size="m"
          on="white"
          weight="bold"
        >
          {' '}
          {!prospect && feature?.street}
        </Text> */}
        <br />
        {/* <span style={{ color: getTextHeaderColor() }}> */}
        {/* {prospect && <Text on="white">{feature?.street}</Text>} */}

        {/* </span> */}

        {/* <Select
          Height="39px"
          // placeholder="Select Your MLS Region* "
          placeholder={
            // user.personalInfo.mls
            // ? 'MLS-Region'
            'Select Radius'
          }
          options={[
            { label: '2,000 meters' },
            { label: '1,500 meters' },
            { label: '1,000 meters' },
          ]}
          // isError={isSubmit && mls.label.length < 1}
          // placeholder={user.personalInfo.mls?user.personalInfo.mls.value:'__Select__'}
          selectedOption={feature?.radius}
          onChange={(value) => {
            // if (listingData && setListingData && setListings) {
            //   const tempList = [...listingData.listings]
            //   const newA = tempList.filter(
            //     (v: { listingid: number }) =>
            //       feature.listingid === v.listingid && {
            //         ...v,
            //         radius: value,
            //       },
            //   )
            //   // setListingData({ ...listingData, listings: newA })
            //   // if (searchClicked) {
            //   //   setListings([...newA])
            //   // }
            // }
            // getIconMap()
          }}
          maxHeight={300}
          radius="5px"
          size="14px"
        /> 
        */}

        {/* <select
          onChange={(event) => {
            changeFruit(event.target.value)
            count.current += count.current
          }}
          value={feature?.radius.label}
          ref={count}
        >
          <option value="2,000 meters">2,000 m Oranges</option>
          <option value="1,500 meters">1,500 m a Fruit Tomatoes</option>
          <option value="1,000 meters">1,000 ms Bananas</option>
        </select> */}
      </div>
    </div>
  )
}
