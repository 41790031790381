import React, { useEffect, useState, useRef } from 'react'
import { LoginHeader, LoginFooter, Tooltip, OnboardingModal } from 'Components'
import { Header, HeaderLogo, Button, Text, Modal } from '@eltoro-ui/components'
import { Redirect, useHistory, useLocation } from 'react-router'
import { useValidatedState, useForm, useValidation } from '@eltoro-ui/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faLockAlt,
  faEye,
  faEyeSlash,
} from '@fortawesome/pro-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, setUserMail } from 'Redux/actions'
import { SpinnerLoader } from 'Components/SpinnerLoader'

// import { TokenValidate } from 'Requests/Services/TokenVerify'
import {
  tokenValidate,
  createNewUser,
  forgotPassword,
  userLogin,
} from 'Requests'
import './PasswordSetup.scss'
import { userRole } from 'Helpers'
import toast from 'react-hot-toast'
import ReactGA from 'react-ga'
import { LableInputField } from 'Components/LableInputField'

import { userDetail } from 'Requests/Request_Methods/authUserMethods'
import logobefore from '../../assets/Images/honeycomb.svg'
import logoafter from '../../assets/Images/honeycomb_left.svg'
import leftGroup from '../../assets/Images/leftgroup.svg'
import rightGroup from '../../assets/Images/rightgroup.svg'
// import { KeycloakCreateUser } from 'Requests/Services/KeycloakCreateUser'
import logo from '../../Images/Icon_color@2x.png'
import PassWarning from '../../Images/lockw.png'
import Password_icon from '../../Images/lock.png'
import { Input } from './components/Input'
import { ErrorMsg } from './components/ErrorMsg'
import Warning from '../../Images/warning.png'

export const PasswordSetup = () => {
  const Location = useLocation()
  const [modalOpen, setModalOpen] = useState(false)
  const [resetModal, setResetModal] = useState(false)
  const [password, setPassword] = useState('')
  const [pass, setPass] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [wrongPassword, setWrongPassword] = useState(Boolean)
  const [retypePassword, setRetypePassword] = useState(true)
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPass, setshowPass] = useState(false)
  const [showPass1, setshowPass1] = useState(false)
  const [email, setEmail] = useState('')
  const history = useHistory()
  const focusInput = useRef(null)
  const query = new URLSearchParams(Location.search).get('token')?.toString()

  useEffect(() => {
    ReactGA.pageview(Location.pathname + Location.search)
  }, [])

  useEffect(() => {
    if (query && Location.pathname === '/verify-email') {
      tokenValidate(JSON.stringify(query))
        .then((response: any) => {
          if (response) {
            setIsValid(true)
            setEmail(response.data?.email)
          }
        })
        .catch((err: any) => {
          setIsValid(false)
          toast.error(
            `Token has been expired. Please try again with a new email.`,
          )
          // alert('invalid token')
        })
    } else {
      setIsValid(true)
    }
  }, [])

  const dispatch = useDispatch()
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()

    if (retypePassword || wrongPassword) {
      // setWrongPassword(true)
      // setRetypePassword(true)
    } else {
      if (Location.pathname === '/verify-email') {
        setIsValid(false)
        setLoading(true)
        const body = { email, password, token: query }

        createNewUser(body)
          .then((res) => {
            if (res?.data?.sub) {
              dispatch(setUserMail(res.data.email))
              localStorage.setItem('userMail', res.data.email)
              setModalOpen(true)
              setLoading(false)
            } else {
              setIsValid(true)
              if (typeof res?.detail === 'string') {
                toast.error(res?.detail)
              }
            }
          })
          .catch((err) => {
            toast.error(err)
          })
      } else {
        setIsValid(false)
        const param = { token: query, new_password: password }
        forgotPassword(param).then((res) => {
          if (res.detail) {
            setIsValid(true)
            toast.error('Inactive user')
            // alert('Inactive user')
          } else {
            setModalOpen(true)
          }
          // setResetModal(true)
        })
      }
      setLoading(false)
    }
  }
  const meApicall = (tkn: string) => {
    // if (tkn) {
    userDetail(tkn)
      .then((res) => {
        if (res?.data?.email) {
          const user = {
            user_role: userRole(res.data.is_super),
            ...res.data,
          }
          dispatch(setUser(user))
          setModalOpen(false)
          // setModalStep(1)
          history.push('/dashboard')
        }
      })
      .catch((err: any) => {
        // setIsLoading(false)
      })
    // }
  }
  const Login = () => {
    const obj = { username: email, password }
    userLogin(obj).then((res: any) => {
      if (res?.data?.token) {
        localStorage.setItem('beewo_token', res?.data?.token)
        meApicall(res.data.token)
        // setOpenOnboardingModal(true)
      } else {
        toast.error('Session Expired for Reset Password')
      }
    })
    setModalOpen(false)
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setLoading(false)
    if (
      e.target.value.match(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
      ) &&
      e.target.value.length <= 16
    ) {
      setWrongPassword(false)
      setRetypePassword(false)
    } else {
      setWrongPassword(true)
    }
  }
  const repeatPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass(e.target.value)
    setLoading(false)
    if (password === e.target.value) {
      setRetypePassword(false)
    } else {
      setRetypePassword(true)
    }
  }

  return (
    <div className="passwordSetup">
      <LoginHeader />
      {openOnboardingModal && (
        <OnboardingModal setModalOpen={setOpenOnboardingModal} />
      )}
      <div className="loginPrefix">
        <div className="mainLogin">
          <img
            src={logobefore}
            alt="beewo-logo-back"
            className="before-login-bg"
            id="logoBefore"
          />
          <div className="password_setup_content">
            <div className="mainLogin__logo">
              <img src={logo} alt="beewo-logo" style={{ height: '111px' }} />
            </div>

            <Text weight="bold" size="xl" on="white">
              Create New Password
            </Text>
            <div
              style={{
                textAlign: 'center',

                width: '100%',
                maxWidth: '85vw',
              }}
            >
              <p
                style={{
                  color: '#6D6D6D',
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                  margin: '8px 0',
                }}
              >
                Enter a new password, must be -criteria- and not be any previous
                passwords you’ve used
              </p>
            </div>
            <br />
            <div className="Login__form">
              <form
                onSubmit={(e) => {
                  onSubmit(e)
                }}
              >
                <div style={{ position: 'relative' }}>
                  <LableInputField
                    title="New Password"
                    placeholder="New Password"
                    id="enterPassword"
                    isError={wrongPassword && password.length > 0}
                    type={showPass === false ? 'password' : 'text'}
                    autoComplete="new-password"
                    iconLeft={
                      <img
                        className="start_icon"
                        style={{ marginTop: '7px', width: '24px' }}
                        src={
                          wrongPassword && password.length > 0
                            ? PassWarning
                            : Password_icon
                        }
                        alt="icon"
                      />
                    }
                    onChange={(e: any) => {
                      handleChange(e)
                    }}
                    EyePassIcon={
                      showPass ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{
                            fontSize: '21px',
                            color: '#b3b0b0',
                            margin: '19px 0 0 12px',
                            cursor: 'pointer',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            if (focusInput.current) {
                              // @ts-ignore
                              focusInput.current.focus()
                            }
                            setshowPass(false)
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{
                            fontSize: '21px',
                            color: '#b3b0b0',
                            margin: '19px 0 0 12px',
                            cursor: 'pointer',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            document.getElementById('enterPassword')?.focus()
                            if (focusInput.current) {
                              //  @ts-ignore
                              focusInput.current.focus()
                            }
                            setshowPass(true)
                          }}
                        />
                      )
                    }
                  />
                  {/* <Input
                  type="password"
                  placeHolder="Password"
                  wdt="100%"
                  icon={
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{
                        fontSize: '28px',
                        color: 'gray',
                        margin: '10px 0 0 10px',
                      }}
                    />
                  }
                  onChange={(e) => {
                    handleChange(e)
                  }}
                /> */}
                  {wrongPassword && password.length > 0 ? (
                    // <ErrorMsg
                    //   title="Password must contain min 8 characters including numbers, special characters, upper and lowercase letters."
                    //   icon={
                    //     <FontAwesomeIcon
                    //       icon={faExclamationCircle}
                    //       style={{ color: 'red' }}
                    //     />
                    //   }
                    // />
                    <ErrorMsg
                      title="Password must contain min 8 characters including numbers, special characters, upper and lowercase letters."
                      iconStyle={{ alignItems: 'flex-start' }}
                      icon={
                        <img
                          className="start_icon"
                          style={{ marginTop: '-3px', width: '24px' }}
                          src={Warning}
                          alt="icon"
                        />
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
                <br />
                <LableInputField
                  title="Re-Enter New Password"
                  id="reEnterPassword"
                  placeholder="Re-Enter New Password"
                  isError={retypePassword && pass.length > 0}
                  type={showPass1 === false ? 'password' : 'text'}
                  autoComplete="new-password"
                  iconLeft={
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={
                        retypePassword && pass.length > 0
                          ? PassWarning
                          : Password_icon
                      }
                      alt="icon"
                    />
                  }
                  onChange={(e: any) => {
                    repeatPassword(e)
                  }}
                  EyePassIcon={
                    showPass1 ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          fontSize: '21px',
                          color: '#b3b0b0',
                          margin: '19px 0 0 12px',
                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          if (focusInput.current) {
                            // @ts-ignore
                            focusInput.current.focus()
                          }
                          setshowPass1(false)
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        style={{
                          fontSize: '21px',
                          color: '#b3b0b0',
                          margin: '19px 0 0 12px',
                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          document.getElementById('reEnterPassword')?.focus()
                          if (focusInput.current) {
                            //  @ts-ignore
                            focusInput.current.focus()
                          }
                          setshowPass1(true)
                        }}
                      />
                    )
                  }
                />
                {/* <Input
                type="password"
                placeHolder="Re-enter Password"
                wdt="100%"
                icon={
                  <FontAwesomeIcon
                    icon={faLockAlt}
                    style={{
                      fontSize: '28px',
                      color: 'gray',
                      margin: '10px 0 0 10px',
                    }}
                  />
                }
                onChange={(e) => {
                  repeatPassword(e)
                }}
              /> */}
                {retypePassword && pass.length > 0 ? (
                  // <ErrorMsg
                  //   title="You enter invaild password"
                  //   icon={
                  //     <FontAwesomeIcon
                  //       icon={faExclamationCircle}
                  //       style={{ color: 'red' }}
                  //     />
                  //   }
                  // />
                  <ErrorMsg
                    title="You enter invaild password"
                    iconStyle={{ alignItems: 'flex-start' }}
                    icon={
                      <img
                        className="start_icon"
                        style={{ marginTop: '-3px', width: '24px' }}
                        src={Warning}
                        alt="icon"
                      />
                    }
                  />
                ) : (
                  ''
                )}
                <br />
                <Button
                  UNSAFE_style={{
                    borderRadius: '10px',
                    padding: '26px 0',
                    justifyContent: 'center',
                  }}
                  type="submit"
                  kind="primary"
                  size="xl"
                  width="100%"
                  fonts="16px"
                  disabled={retypePassword || wrongPassword || !isValid}
                  weight="bold"
                >
                  {loading ? (
                    <div style={{ display: 'flex' }}>
                      Create Password
                      <SpinnerLoader
                        style={{
                          position: 'unset',
                          color: 'black',
                          width: '25px',
                          height: '25px',
                          disabled: 'none !important',
                          marginTop: '0px',
                          opacity: 1,
                        }}
                      />
                    </div>
                  ) : (
                    'Create Password'
                  )}
                </Button>
              </form>
            </div>
          </div>
        </div>
        <img
          src={logoafter}
          alt="beewo-logo-back"
          className="after-login-bg"
          id="logoAfter"
        />
      </div>
      {/* End login */}
      {modalOpen && (
        <Modal
        // offClick={() => {
        //   setModalOpen(false)
        // }}
        >
          <div
            style={{
              width: '30rem',
              padding: '3rem 2rem',
              position: 'relative',
            }}
            className="OnboardingModal__welcome"
          >
            <img
              src={leftGroup}
              alt="leftcardicon"
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '23%',
                zIndex: '-1',
              }}
            />
            <div
              style={{ padding: '0px 2rem' }}
              className="OnboardingModal__welcomeHeader"
            >
              <div style={{ textAlign: 'center' }}>
                <Text
                  UNSAFE_style={{ fontWeight: '800' }}
                  on="white"
                  weight="bold"
                  size="xxl"
                  textAlign="center"
                >
                  Password Set Successfully
                </Text>
              </div>
              <br />
              <div
                style={{
                  // width: '280px',
                  margin: '0 30px',
                  textAlign: 'center',
                }}
              >
                <p className="newpassword_model">
                  You can now use your new password to sign in to your account.
                </p>
              </div>
            </div>

            <Button
              UNSAFE_style={{
                borderRadius: '10px',
                padding: '26px',
                width: '100%',
              }}
              kind="primary"
              size="xl"
              weight="bold"
              UNSAFE_className="passwordReset__login"
              onClick={() => {
                if (Location.pathname === '/reset-password') {
                  if (localStorage.getItem('beewo_token')) {
                    history.push('/profile')
                  } else {
                    history.push('/login')
                  }
                } else {
                  Login()
                }

                // setModalOpen(false)
                // setOpenOnboardingModal(true)
              }}
            >
              {localStorage.getItem('beewo_token') ? 'Done' : 'Sign In'}
            </Button>

            {/* <Button
              kind="primary"
              // size="l"
              UNSAFE_style={{ padding: '0.5rem 1rem 1.60rem' }}
              weight="bold"
              UNSAFE_className="passwordReset__app"
              onClick={() => {
                // setModalOpen(false)
              }}
            >
              {localStorage.getItem('beewo_token')
                ? 'Done'
                : 'Kindly Open Your App'}
            </Button> */}
            {/* <p style={{ margin: '0.5rem' }} className="passwordReset__app">
              Or
            </p>
            <Button
              kind="primary"
              // size="l"
              UNSAFE_style={{ padding: '0.5rem 1rem 1.60rem' }}
              weight="bold"
              UNSAFE_className="passwordReset__app"
              onClick={() => {
                // setModalOpen(false)
                history.push('/')
              }}
            >
              {localStorage.getItem('beewo_token') ? 'Done' : 'Sign In'}
            </Button> */}
            <img
              style={{
                position: 'absolute',
                bottom: '0px',
                right: '0px',
                width: '24%',
                zIndex: '-1',
              }}
              src={rightGroup}
              alt="righcardicon"
            />
          </div>
        </Modal>
      )}
      <LoginFooter loginStyle />
    </div>
  )
}
