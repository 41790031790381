import * as misc from './Request_Methods/miscMethods'
import * as prospects from './Request_Methods/prospectMethods'
import * as listings from './Request_Methods/listingMethods'
import * as users from './Request_Methods/userMethods'
import * as campaigns from './Request_Methods/campaignMethods'
import * as audiences from './Request_Methods/audienceMethods'
import * as creatives from './Request_Methods/creativeMethods'
import * as authUser from './Request_Methods/authUserMethods'
import * as chargebee from './Request_Methods/chargebeeMethods'
import * as contacts from './Request_Methods/contactsMethods'
import * as superAdminMethods from './Request_Methods/superAdminMethods'
import * as feedbackMethods from './Request_Methods/feedbackMethods'
import * as notifications from './Request_Methods/notifications'
import * as voting from './Request_Methods/votingMethod'

const methods = {
  ...listings,
  ...users,
  ...misc,
  ...prospects,
  ...campaigns,
  ...audiences,
  ...creatives,
  ...authUser,
  ...chargebee,
  ...contacts,
  ...superAdminMethods,
  ...feedbackMethods,
  ...notifications,
  ...voting,
}

export const {
  getAuth,
  getUser,
  updateUser,
  updateTheUser,
  createUser,
  getListings,
  getListingById,
  getMapListings,
  getCanvassingData,
  getCompListings,
  getMoverData,
  attachAudience,
  attachCreative,
  createCampaign,
  detachAudience,
  detachCreative,
  getCampaignById,
  getCampaignDetailsById,
  getCampaigns,
  updateCampaign,
  createAudience,
  getAudienceById,
  getAudiences,
  updateAudience,
  createCreative,
  getCreativeById,
  getCreatives,
  updateCreative,
  getNotifications,
  getFilters,
  emailVerify,
  tokenValidate,
  keycloakAdminLogin,
  createKeycloakUser,
  createNewUser,
  restEmailVerify,
  userLogin,
  forgotPassword,
  resetPassword,
  userDetail,
  userUpdate,
  createChargebeeUser,
  getChargebeePackages,
  chargebeeSubscription,
  getMlS,
  getListingHistogram,
  saveAudience,
  uploadCSV,
  saveCampaignTarget,
  chargebeeBundles,
  postCampaignBudget,
  addChargebeeBundle,
  postFYP,
  postAreaSearch,
  deleteCampaign,
  deleteAudience,
  getMyListings,
  getContacts,
  deleteContacts,
  uploadContactsCSV,
  saveContacts,
  uploadContactsSync,
  uploadProfilePicture,
  getCompaignGraphData,
  getCompaignPerformance,
  getRealStateUsersListings,
  getUserToken,
  getChargebeeTransactions,
  getPaymentCards,
  addCard,
  editCard,
  removeCard,
  createFeedback,
  getMyZipCode,
  getRealtorActivity,
  getLocationOnSearch,
  postAdvertiseToAll,
  getTestingNotications,
  getNotificationSettings,
  logout,
  pushNotificationSetting,
  userdeviceToken,
  getUnReadNotificationsCounts,
  getUserNotifications,
  markAsReadAllNotifications,
  markAsReadSingleNotification,
  markAsReadSpecificTypeNotifications,
  subscribeTopic,
  getContactDetailById,
  votingMethod,
  getVoting,
} = methods

export { mockApi } from './Mock/mockApi'
