import { Button, GridBox } from '@eltoro-ui/components'
import { Loading, LoginFooter, PageHeader } from 'Components'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TRootState } from 'types'
import { useSelector } from 'react-redux'
import { getCompaignGraphData } from 'Requests'
import toast from 'react-hot-toast'

import './CampaignDashboard.scss'
import {
  CampaignPerformance,
  CompaignType,
  FinishedCampaigns,
  ProgressCampaign,
  SavedAudiences,
} from './components'

export const CampaignDashboard = () => {
  const history = useHistory()
  const [graphData, setGraphData] = useState<any>({})

  useEffect(() => {
    getCompaignGraphData()
      .then(({ data }) => {
        setGraphData(data)
      })
      .catch((err) => toast.error(err))
  }, [])

  const currentCampaignName = useSelector(
    (state: TRootState) => state.isCampaignLaunchReducer,
  )

  return (
    <div className="CampaignDashboard">
      <PageHeader
        // title={campaignName}
        // title={currentCampaignName?.currentCampaignName}
        title=""
        UNSAFE_CLASSNAME="CampaignDashboard__dashboard_bar"
        subTitle=""
        actions={[
          <Button
            UNSAFE_style={{ marginBottom: '0.5rem' }}
            onClick={() => history.push('/create-campaign/setup')}
            UNSAFE_className="CampaignDashboard__newCampaign_btn"
          >
            New Campaign
          </Button>,
        ]}
      />

      {!Object.entries(graphData).length ? (
        <Loading />
      ) : (
        <div className="CampaignDashboard__main_div">
          <div className="CampaignDashboard__left_main_col">
            <div className="CampaignDashboard__CampaignPerformance">
              <CampaignPerformance />
            </div>
            <div className="CampaignDashboard__left_col_child_div">
              <div className="CampaignDashboard__graphdata_campaign">
                <CompaignType data={graphData?.campaign_type} />
              </div>
              <div className="CampaignDashboard__graphdata_finshed">
                <FinishedCampaigns data={graphData?.finished_campaign} />
              </div>
            </div>
          </div>
          <div className="CampaignDashboard__right_main_col">
            <div className="CampaignDashboard__Saved_audiences">
              <SavedAudiences
                data={graphData?.saved_audience}
                textShow="# Of Saved Audiences"
              />
            </div>
            <div className="CampaignDashboard__ProgressCampaign">
              <ProgressCampaign data={graphData?.in_progress_campaign} />
            </div>
          </div>
        </div>
        // <div className="CampaignDashboard__body">
        //   <div className="CampaignDashboard__left-column">
        //     <GridBox UNSAFE_className="box_container">
        //       <CompaignType data={graphData?.campaign_type} />
        //       <SavedAudiences
        //         data={graphData?.saved_audience}
        //         textShow="# Of Saved Audiences"
        //       />
        //       <ProgressCampaign data={graphData?.in_progress_campaign} />
        //       <FinishedCampaigns data={graphData?.finished_campaign} />
        //     </GridBox>
        //     {/* <br />
        //   <br />
        //   <br /> */}
        //   </div>
        //   <div className="CampaignDashboard__right-column">
        //     <CampaignPerformance />
        //   </div>
        // </div>
      )}
      {/* <div className="CampaignDashboard__footer_container">
        <LoginFooter />
      </div> */}
    </div>
  )
}
