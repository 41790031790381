export const DropDown: any | undefined = (
  state = { dropDownValue: false, dropDownValuee: false },
  action: any,
) => {
  switch (action.type) {
    case 'SET_DROP_DOWN':
      return { ...state, dropDownValue: action.payload }
    case 'SET_DROP_DOWN_1':
      return { ...state, dropDownValuee: action.payload }

    default:
      return { ...state }
  }
}
