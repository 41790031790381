import React, { useState } from 'react'
import { Button, Text } from '@eltoro-ui/components'
import './UploadVideoModal.scss'
import { Uploader } from 'Components/Uploader'
import { FileUploadType } from 'types'
import { browserLog } from 'Tools'
import toast from 'react-hot-toast'
import { UploadAreaLayout, UploadTypes } from '../UploadAreaLayout'
import cross from '../../../../../../assets/Images/cross.png'

export const UploadVideoModal = ({
  onClickOutside,
  onSubmit,
  typeModal,
}: {
  onClickOutside: () => void
  onSubmit: (files: any, type?: string) => void
  typeModal?: any
}) => {
  const [frontSubmittedFile, setfrontSubmittedFile] = useState<any>()
  return (
    <div className="UploadVideoModal">
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <img
          src={cross}
          onClick={onClickOutside}
          alt="cross"
          className="PersonalInfoForm__cross"
          role="presentation"
        />
      </div>
      <p className="upload_your_video"> Upload Your Video</p>

      <p className="upload_video_disc">
        We recommend video <b>no longer than 30 seconds</b> and supported
        <br /> only mp4 and mov format
      </p>

      <div>
        <Uploader
          type="video"
          onDropFile={(files) => setfrontSubmittedFile(files)}
        >
          <UploadAreaLayout uploadType={UploadTypes.video} />
        </Uploader>
      </div>
      <div className="UploadVideoModal__footer">
        {/* <Button
          kind="text"
          UNSAFE_className="UploadVideoModal__cancel-button"
          onClick={onClickOutside}
        >
          Cancel
        </Button> */}
        <button
          type="button"
          className="UploadBannerModal__cancel-button"
          onClick={onClickOutside}
        >
          Cancel
        </button>
        <Button
          UNSAFE_className="upload_video_confirm"
          kind="primary"
          onClick={() => {
            if (frontSubmittedFile?.acceptedFiles?.length > 0) {
              onSubmit(frontSubmittedFile, typeModal)
              onClickOutside()
            } else {
              toast.error('Please add a video creative')
            }
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
