import React, { useEffect, useState } from 'react'
import './Notification.scss'
import { Text } from '@eltoro-ui/components'
import {
  Loading,
  PageHeader,
  Pagination,
  TableSelectionWrapper,
  EmptyStatus,
} from 'Components'
import { removeTimeFromDate } from 'Helpers'
import {
  getUserNotifications,
  getUnReadNotificationsCounts,
  markAsReadAllNotifications,
  markAsReadSingleNotification,
  markAsReadSpecificTypeNotifications,
} from 'Requests'
import { useDispatch } from 'react-redux'

import {
  faUser,
  faAddressCard,
  faSlidersH,
  faBullhorn,
  faArrowAltFromLeft,
} from '@fortawesome/pro-regular-svg-icons'
import { setUnReadNotification } from 'Redux/actions'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TUnReadTotalCounts } from 'types'
import membershipIcon from '../../assets/Images/membershipIcon.svg'
import accountIcon from '../../assets/Images/accounts.png'
import systemIcon from '../../assets/Images/notificationSystem.png'
import campaignIcon from '../../assets/Images/notificationCampaign.png'
import accountIcon_gray from '../../assets/Images/accounts_gray.png'
import membershipIcon_gray from '../../assets/Images/membershipIcon_gray.png'
import campaignIcon_gray from '../../assets/Images/notificationCampaign_gray.png'
import systemIcon_gray from '../../assets/Images/notificationSystem_gray.png'
import bellicon from '../../assets/Images/bellicon.png'
import folder from '../../assets/Images/folder.png'
import campaign_color from '../../assets/Images/campaign.png'

export const Notifications = () => {
  const [activeTab, setActiveTab] = useState<string>('')
  // const [activeTab, setActiveTab] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [notifications, setNotifications] = useState<any>([])
  const [counts, setCounts] = useState<TUnReadTotalCounts[]>([])
  // const [isCancelled, setIsCancelled] = useState<boolean>(false)

  const [pages, setPages] = useState<{
    total_notifications: number
    total_pages: number
    current_page: number
  }>({
    total_notifications: 0,
    total_pages: 0,
    current_page: 1,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (counts.length) {
      dispatch(setUnReadNotification(true))
    } else {
      dispatch(setUnReadNotification(false))
    }
  }, [counts])

  useEffect(() => {
    // setIsCancelled(false)

    setNotifications([])
    setActiveTab(activeTab)

    // setIsLoading(true)
    _getUnReadNotificationsCounts()

    // return () => {
    //   setIsCancelled(true)
    // }
  }, [pages.current_page, activeTab])

  const _getUnReadNotificationsCounts = (src?: string) => {
    getUnReadNotificationsCounts()
      .then((res) => {
        // if (!isCancelled) {
        setCounts(res.data)
        // _getNotifications(src)
        // }
      })
      .catch((err) => {
        toast.error(err)
        // if (!isCancelled) {
        setIsLoading(false)
        // }
      })
  }

  useEffect(() => {
    let isCancelledNotifications = false

    const _getNotifications = (source?: string) => {
      // setNotifications([])
      getUserNotifications(pages.current_page, 20, source)
        .then((res) => {
          if (!isCancelledNotifications) {
            setNotifications(res.data?.notifications)

            setPages({
              current_page: res.data?.current_page || 1,
              total_notifications: res.data?.total_notifications || 0,
              total_pages: res.data?.total_pages || 0,
            })
            setIsLoading(false)
            // if()
            //   setTimeout(() => {
            //     setIsLoading(false)
            //   }, 2000)
            // } else {
            //   setIsLoading(false)
            // }
          }
        })
        .catch((err) => {
          if (!isCancelledNotifications) {
            toast.error(err)
            setIsLoading(false)
          }
        })
    }

    _getNotifications(activeTab)

    return () => {
      isCancelledNotifications = true
    }
  }, [counts, activeTab])

  const readSingleNotification = (id: string) => {
    setIsLoading(true)
    markAsReadSingleNotification([{ id, read: 1 }])
      .then(() => {
        _getUnReadNotificationsCounts()
      })
      .catch((err) => {
        toast.error(err)
        setIsLoading(false)
      })
  }

  const markAsAllRead = () => {
    setIsLoading(true)
    markAsReadAllNotifications(1)
      .then(() => {
        _getUnReadNotificationsCounts()
      })
      .catch((err) => {
        toast.error(err)
        setIsLoading(false)
      })
  }

  const tabOnChange = (src: string) => {
    setIsLoading(true)
    setActiveTab(src)
    // if (counts.length > 0) {
    markAsReadSpecificTypeNotifications(src, 1)
      .then(() => {
        // if (!isCancelled) {
        _getUnReadNotificationsCounts(src)
        // }
      })
      .catch((err) => {
        // if (!isCancelled) {
        toast.error(err)
        setIsLoading(false)
        // }
      })
    // } else {
    //   _getUnReadNotificationsCounts()
    // }
  }

  const getIcon = (src: string, disable = false) => {
    switch (src) {
      case '':
        return disable ? folder : folder
      case 'accounts':
        return disable ? accountIcon_gray : accountIcon
      case 'membership':
        return disable ? membershipIcon_gray : membershipIcon
      case 'system':
        return disable ? campaignIcon_gray : systemIcon
      default:
        return disable ? campaign_color : campaignIcon
    }
  }

  // const getLastElement = (length: number, index: number) => {
  //   if (length - 1 === index) {
  //     if (length >= 5) {
  //       return (
  //         <ul>
  //           <li className="notification_title" />
  //         </ul>
  //       )
  //     }
  //   }
  //   return null
  // }
  const getBody = () => {
    let component = <></>
    if (isLoading) {
      component = <Loading />
    } else if (
      notifications?.length === 0 ||
      ['membership', 'accounts', 'system'].includes(activeTab)
    ) {
      component = (
        <EmptyStatus
          icon={<img src={getIcon(activeTab, true)} alt="..." />}
          heading={
            activeTab === 'campaign' || !activeTab
              ? 'No notifications are found'
              : 'Coming Soon'
          }
        />
      )
    } else {
      component = (
        <div className="body_container" style={{ paddingBottom: '6rem' }}>
          {notifications.map((notification: any, i: number) => {
            return (
              <>
                <ul
                  className={!notification.read ? 'unread' : ''}
                  onClick={() => readSingleNotification(notification.id)}
                  role="presentation"
                >
                  <li className="notification_title">
                    {/* <FontAwesomeIcon
                      icon={getIcon(notification.source)}
                      style={{ fontSize: '17px' }}
                    /> */}
                    <img
                      src={getIcon(notification.source)}
                      alt=""
                      width="17px"
                    />

                    <Text on="grey-050">{notification.title}</Text>
                  </li>
                  <li>
                    <Text on="grey-050" kind="subdued">
                      {removeTimeFromDate(notification.created_at)}
                    </Text>
                  </li>
                </ul>
                {/* {getLastElement(notifications.length, i)} */}
              </>
            )
          })}
          {!!notifications?.length && (
            <div
              className="pagiate_container"
              style={{
                position: notifications.length > 4 ? 'relative' : 'absolute',
              }}
            >
              <Pagination
                title="notifications"
                loading={isLoading}
                currentPage={pages?.current_page}
                totalItems={pages?.total_notifications}
                totalPages={pages?.total_pages}
                onClickPrev={() => {
                  setIsLoading(true)
                  setPages({
                    ...pages,
                    current_page: pages.current_page - 1,
                  })
                }}
                onClickNext={() => {
                  setIsLoading(true)
                  setPages({
                    ...pages,
                    current_page: pages.current_page + 1,
                  })
                }}
              />
            </div>
          )}
        </div>
      )
    }
    return component
  }

  const getCount = (src: string) => {
    const _count = counts.find(
      (count) => count?.source === src && count?.total,
    )?.total
    return _count || 0
  }

  // const scrollToRight = () => {
  //   window.scrollBy(100, 0)
  // }

  return (
    <div className="notification_container">
      {/* <PageHeader title="" subTitle="" /> */}
      <TableSelectionWrapper
        selectionType="campaign"
        selections={[]}
        actions={[]}
      >
        <div className="nested_container">
          <div className="header_container">
            <ul className="header_titles">
              <li
                className={activeTab === '' ? 'border_bottom' : ''}
                role="presentation"
                onClick={() => tabOnChange('')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Text on="white" weight="bold">
                  All
                </Text>
                <span
                  className={`count ${activeTab === '' && 'black_bg_color'}`}
                >
                  {counts.reduce((accumulator, object) => {
                    return accumulator + object.total
                  }, 0)}
                </span>
              </li>

              <li
                className={activeTab === 'membership' ? 'border_bottom' : ''}
                role="presentation"
                onClick={() => tabOnChange('membership')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Text on="white" weight="bold">
                  membership
                </Text>
                <img
                  src={membershipIcon}
                  alt=""
                  className="notificationTitleIcon__icon"
                />

                <span
                  className={`count ${
                    activeTab === 'membership' && 'black_bg_color'
                  }`}
                >
                  {getCount('membership')}
                </span>
              </li>

              <li
                className={activeTab === 'campaign' ? 'border_bottom' : ''}
                role="presentation"
                onClick={() => tabOnChange('campaign')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Text on="white" weight="bold">
                  campaign
                </Text>
                <img
                  src={campaignIcon}
                  alt=""
                  className="notificationTitleIcon__icon"
                />

                <span
                  className={`count ${
                    activeTab === 'campaign' && 'black_bg_color'
                  }`}
                >
                  {getCount('campaign')}
                </span>
              </li>

              <li
                className={activeTab === 'accounts' ? 'border_bottom' : ''}
                role="presentation"
                onClick={() => tabOnChange('accounts')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Text on="white" weight="bold">
                  accounts
                </Text>
                <img
                  src={accountIcon}
                  alt=""
                  className="notificationTitleIcon__icon"
                />

                <span
                  className={`count ${
                    activeTab === 'accounts' && 'black_bg_color'
                  }`}
                >
                  {getCount('accounts')}
                </span>
              </li>

              <li
                className={activeTab === 'system' ? 'border_bottom' : ''}
                role="presentation"
                onClick={() => tabOnChange('system')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Text on="white" weight="bold">
                  system
                </Text>
                <img
                  src={systemIcon}
                  alt=""
                  className="notificationTitleIcon__icon"
                />

                <span
                  className={`count ${
                    activeTab === 'system' && 'black_bg_color'
                  }`}
                >
                  {getCount('system')}
                </span>
              </li>
            </ul>

            {/* <FontAwesomeIcon
              icon={faArrowAltFromLeft}
              size="2x"
              className="header_icons"
              onClick={scrollToRight}
            /> */}
            <ul className="notification__containerHeader">
              <li
                className="mark_all_as_read"
                onClick={markAsAllRead}
                role="presentation"
              >
                <Text on="grey-050">Mark all as read</Text>
              </li>
            </ul>
          </div>

          {getBody()}
        </div>
      </TableSelectionWrapper>
    </div>
  )
}
