// import { Button, Table, Text } from '@eltoro-ui/components'
// import { faWalking } from '@fortawesome/pro-regular-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Pagination } from 'Components'
// import { UploadCSVModal } from 'Pages/MyContacts/components'
// import ConfirmType from 'Pages/MyContacts/components/ConfirmModal.tsx/ConfirmType'
// import React, { useEffect, useState } from 'react'
// import { getContacts } from 'Requests'

import React, { useEffect, useState } from 'react'

import {
  Loading,
  PageHeader,
  TableSelectionWrapper,
  SearchBar,
  ConfirmationModal,
  Pagination,
  EmptyStatus,
} from 'Components'
import { Button, Table, Text, TextInput } from '@eltoro-ui/components'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus, faSearch, faWalking } from '@fortawesome/pro-solid-svg-icons'
// import { AudienceType } from 'types'
// import { getLocalDateTime, getTimePassed } from 'Helpers'
// import './SavedAudiences.scss'
// import { useDispatch, useSelector } from 'react-redux'
// import { setSpinner } from 'Redux/actions'
// import { useAsync } from '@eltoro-ui/hooks'
// import {
//   getAudiences,
//   deleteAudience,
// } from 'Requests/Request_Methods/audienceMethods'
// import { Tags } from './components'

import './TalkToSphereList.scss'
import shoePrints from '../../../../../assets/Images/Icon awesome-shoe-prints.png'

export const TalkToSphereList = () => {
  const [closeType, setCloseType] = useState(false)

  const SphereList = [
    {
      id: 1,
      name: 'Joe Smith',
    },
    {
      id: 2,
      name: 'Joe Smith',
    },
    {
      id: 3,
      name: 'Joe Smith',
    },
    {
      id: 4,
      name: 'Joe Smith',
    },
    {
      id: 5,
      name: 'Joe Smith',
    },
  ]
  const [selections, setSelections] = useState<any>([])
  const [unselect, setUnselectAll] = useState(false)
  const [PreSelections, setPreSelections] = useState<any[]>([])

  useEffect(() => {
    if (unselect) {
      setSelections([])
      setPreSelections([])
    }
  }, [unselect])

  const getList = () => {
    let component = <></>
    if (!'isLoading') {
      component = <Loading />
    } else if (!'isLength') {
      component = <EmptyStatus subHeading="No data" />
    } else {
      component = (
        <TableSelectionWrapper
          selectionType="sphere"
          selections={selections}
          UNSAFE_CLASSNAME_WRAPPER="talkToSphere__table"
          actions={[
            <button
              type="button"
              className="SavedAudiences__select-action"
              // onClick={() => setDeleteModalIsOpen(true)}
            >
              <Text on="tertiary-300" weight="bold">
                Delete
              </Text>
            </button>,
          ]}
        >
          <Table
            rows={SphereList}
            checkBorder="1px solid gray"
            onSelect={(id) => {
              if (id.length > 0) {
                setUnselectAll(false)
              }
              setSelections([...id])
              setPreSelections([...id])
            }}
            preSelected={PreSelections}
            unSelectAll={unselect}
            setUnselectAll={setUnselectAll}
            columns={[
              {
                path: 'prospects_counts',
                label: 'YOUR SPHERE OF INFLUENCE ACTIVITY',
                alignment: 'left',
                width: '100%',
                // onSort,
                RowCell: (sphere) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{ marginRight: 10 }}
                      src={shoePrints}
                      alt="shoePrints"
                    />
                    <Text on="white" size="l" weight="bold">
                      {sphere?.name}
                    </Text>
                  </div>
                ),
              },
            ]}
          />

          {/* {!!audiences?.length && (
            <Pagination
              title="audience"
              loading={isLoading.apiCallLoaded}
              currentPage={pages?.current_page}
              totalItems={pages?.total_count}
              totalPages={pages?.total_pages}
              onClickPrev={() => setCurrentPage(currentPage - 1)}
              onClickNext={() => setCurrentPage(currentPage + 1)}
            />
          )} */}

          {/* {deleteModalIsOpen && (
            <ConfirmationModal
              title="Are you sure you want to delete contact"
              onSubmit={removeAudience}
              onCancel={() => setDeleteModalIsOpen(false)}
              loading={deleteIsLoading}
            />
          )} */}
        </TableSelectionWrapper>
      )
    }
    return component
  }
  return getList()
}
