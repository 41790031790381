import Konva from 'konva'
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { RecoilText } from '../..'
import {
  blockFontSizeAtom,
  blockTextValueAtom,
  mainDimensionsBlockAtom,
} from '../../../../State'
import { BlockConfigType } from '../../../../Typings'

export const PoliticalHeader: React.FC<{
  id: string
  width: number
  height: number
  isOnMobile?: boolean
}> = ({ id, width, height, isOnMobile = false }) => {
  const [textDimensions, setTextDimensions] = useRecoilState(
    mainDimensionsBlockAtom(id),
  )
  const topRef = useRef<Konva.Text>(null)
  const middleRef = useRef<Konva.Text>(null)
  const bottomRef = useRef<Konva.Text>(null)
  const [middleHeight, setMiddleHeight] = useState(0)
  const topConfig: BlockConfigType = {
    id,
    kind: 'politicalHeader',
    key: 'topText',
  }
  const midConfig: BlockConfigType = {
    id,
    kind: 'politicalHeader',
    key: 'middleText',
  }
  const bottomConfig: BlockConfigType = {
    id,
    kind: 'politicalHeader',
    key: 'bottomText',
  }

  const middleTextSize = useRecoilValue(blockFontSizeAtom(midConfig))
  const middleTextContent = useRecoilValue(blockTextValueAtom(midConfig))
  const topTextSize = useRecoilValue(blockFontSizeAtom(topConfig))
  const topTextContent = useRecoilValue(blockTextValueAtom(topConfig))
  const bottomTextSize = useRecoilValue(blockFontSizeAtom(bottomConfig))
  const bottomTextContent = useRecoilValue(blockTextValueAtom(bottomConfig))

  useEffect(() => {
    if (!middleRef.current) return
    setMiddleHeight(middleRef.current.textHeight)
  }, [middleRef.current, middleTextSize, middleTextContent])

  useEffect(() => {
    if (!(middleRef.current && topRef.current && bottomRef.current)) return
    const topHeight = topRef.current.textHeight
    const midHeight = middleRef.current.textHeight
    const bottomHeight = bottomRef.current.textHeight
    const topWidth = topRef.current.getTextWidth()
    const midWidth = middleRef.current.getTextWidth()
    const bottomWidth = bottomRef.current.getTextWidth()

    const totalHeight = topHeight + midHeight + bottomHeight

    setTextDimensions({
      width: Math.max(topWidth, midWidth, bottomWidth),
      height: totalHeight,
    })
  }, [
    middleTextSize,
    middleTextContent,
    topTextSize,
    topTextContent,
    bottomTextSize,
    bottomTextContent,
  ])

  const middleY = topRef.current?.textHeight
  const bottomY = (middleY || 0) + middleHeight

  return (
    <>
      <RecoilText
        textRef={topRef}
        config={topConfig}
        width={textDimensions.width}
        height={height}
        isOnMobile={isOnMobile}
      />
      <RecoilText
        textRef={middleRef}
        config={midConfig}
        width={width}
        height={height}
        x={-(width / 2 - textDimensions.width / 2)}
        y={middleY}
        isOnMobile={isOnMobile}
      />
      <RecoilText
        textRef={bottomRef}
        config={bottomConfig}
        width={textDimensions.width}
        height={height}
        y={bottomY}
        isOnMobile={isOnMobile}
      />
    </>
  )
}
