import React from 'react'
import { Button, Spacer, Text } from '@eltoro-ui/components'
import { useSetRecoilState } from 'recoil'
import { MoreInfoContainer } from '../../..'
import { headShotImageSelector } from '../../../../../State'

const tempImages = [
  'https://m.media-amazon.com/images/M/MV5BMTQwMjAwNzI0M15BMl5BanBnXkFtZTcwOTY1MTMyOQ@@._V1_.jpg',
  'https://images.ctfassets.net/cbydjs9ahtk4/mJJMQtrpth8YQQteNpCaN/7c46b3a4e933cc23e98fb7c0dad26ee0/Silverman_Headshot_Photo_Credit_Robyn_Von_Swank.jpg?h=840',
  'https://lrmonline.com/wp-content/uploads/2019/07/akira-1106323-1280x0.jpeg',
]

export const PhotoEdit: React.FC<{ ids: string[] }> = ({ ids }) => {
  const setImage = useSetRecoilState(headShotImageSelector(ids))

  const handleClick = () => {
    setImage(tempImages[Math.floor(Math.random() * tempImages.length)])
  }

  return (
    <div className="PhotoEdit">
      <MoreInfoContainer label="Photo Info">
        <Text on="grey-100" weight="bold" kind="subdued">
          Image Upload
        </Text>
        <Spacer height="0.25rem" />
        <Button onClick={handleClick}>Future Uploader Here</Button>
      </MoreInfoContainer>
    </div>
  )
}
