import { PageNav, PageRoutes } from '@eltoro-ui/components'
import React, { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, useHistory } from 'react-router-dom'
import { MyListings } from 'SuperAdminPages/MyListings'
import { Notification } from 'SuperAdminPages/Notification'
import { TabWrapper } from './components'
import './SuperAdminDashboard.scss'

export const SuperAdminDashboard: FC = () => {
  const history = useHistory()
  const callResetUrl = () => {
    history.push('/prospects')
  }

  const profilePages = [
    {
      path: '/dashboard',
      title: 'Real-Estate Users',
      exactPath: true,
      component: () => (
        <TabWrapper>
          <MyListings callResetUrl={callResetUrl} />
        </TabWrapper>
      ),
    },
    {
      path: '/dashboard/notification',
      title: 'Notification',
      exactPath: true,
      component: () => (
        <TabWrapper>
          <Notification />
        </TabWrapper>
      ),
    },
  ]
  return (
    <div className="SuperAdminPage">
      <BrowserRouter>
        <Toaster position="top-center" />

        <nav className="SuperAdminPage__nav">
          <h3 className="SuperAdminPage__header">Super Admin</h3>
          <PageNav pages={profilePages} />
        </nav>

        <main className="SuperAdminPage__main">
          <PageRoutes pages={profilePages} />
        </main>
      </BrowserRouter>
    </div>
  )
}
