import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faPlus,
  faSortDown,
  faUserCircle,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'
import { TRootState } from 'types'
import { useSelector } from 'react-redux'
import { faSignOut } from '@fortawesome/pro-light-svg-icons'
import Collapsible from 'react-collapsible'
import { HeaderItem, Avatar } from '@eltoro-ui/components'
import './SideNavigationBar.scss'

export const SideNavigationBar: FC<{
  value: boolean
  onClick: () => void
  onClickDropdown: () => void
  onLogOut: () => void
  isAdmin: boolean
}> = ({ value, onClick, onClickDropdown, onLogOut, isAdmin }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isUnReadNotification } = useSelector(
    (state: TRootState) => state.notificationsReducer,
  )
  const { userData } = useSelector((state: TRootState) => state.userReducer)

  return (
    <div className={`side_nav_bar_container ${value ? '' : 'hide'}`}>
      <div className="shadow_container" />
      <div className="side_nav_bar_nested_container">
        <div className="icon_container">
          <FontAwesomeIcon
            className="cross_icon"
            icon={faPlusCircle}
            onClick={onClick}
          />
        </div>
        <div>
          <ul className="unorder_list">
            {isAdmin && (
              <>
                <li className="list hide_on_mobile">
                  <HeaderItem to="/prospects">Prospects</HeaderItem>
                </li>
                <li>
                  <Collapsible
                    onOpen={() => setIsOpen(!isOpen)}
                    onClose={() => setIsOpen(!isOpen)}
                    trigger={
                      <span
                        className="Collapsible_title"
                        style={{
                          alignItems: isOpen ? 'flex-end' : 'center',
                          cursor: 'pointer',
                        }}
                      >
                        My Campaigns
                        <FontAwesomeIcon
                          className="HeaderDropdown__icon"
                          icon={isOpen ? faSortUp : faSortDown}
                        />
                      </span>
                    }
                  >
                    <ul className="unorder_list">
                      <li className="list">
                        <HeaderItem to="/campaign-dashboard">
                          Campaign Dashboard
                        </HeaderItem>
                      </li>
                      <li className="list">
                        <HeaderItem to="/campaigns">Campaigns</HeaderItem>
                      </li>
                      <li className="list">
                        <HeaderItem to="/saved-audiences">
                          Saved Audience
                        </HeaderItem>
                      </li>
                      <li className="list">
                        <HeaderItem
                          to="/create-campaign/setup"
                          onClick={onClickDropdown}
                        >
                          <FontAwesomeIcon icon={faPlus} /> New Campaign
                        </HeaderItem>
                      </li>
                    </ul>
                  </Collapsible>
                </li>
                <li className="list">
                  <HeaderItem to="/my-sphere">My Sphere</HeaderItem>
                </li>
                <li className="list">
                  <HeaderItem to="/my-listings">My Listings</HeaderItem>
                </li>
                <li className="list notification_list_item">
                  <HeaderItem to="/notifications">My Notifications</HeaderItem>
                  {isUnReadNotification && <div className="dot" />}
                </li>
                <li className="list">
                  <HeaderItem
                    UNSAFE_CLASSNAME_NAVLINK_ITEM="side_nav_bar_nested_container__item_className"
                    to="/profile"
                    icon={
                      userData?.profile_pic ? (
                        <Avatar
                          name={`${userData?.first_name} ${userData?.last_name}`}
                          rounded
                          size="s"
                          src={userData?.profile_pic}
                          UNSAFE_className="avatar"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faUserCircle} />
                      )
                    }
                  >
                    {userData?.first_name || 'Profile'}
                  </HeaderItem>
                </li>
              </>
            )}

            <li className="list">
              <HeaderItem
                UNSAFE_STYLE={{ paddingTop: '0', paddingBottom: '0' }}
                onClick={onLogOut}
                icon={<FontAwesomeIcon icon={faSignOut} />}
              >
                Logout
              </HeaderItem>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
