/* eslint-disable */
import { GridBox, Text } from '@eltoro-ui/components'
import { faExclamationCircle, faLink } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMsg, InputBox } from 'Components'
import { getBase64 } from 'Components/CampaignReport'
import { LableInputField } from 'Components/LableInputField'
import { validateURL } from 'Helpers'
import React, { useState } from 'react'
import { CampaignType } from 'types'
import closeIcon from '../../../../../../../../assets/Images/close1.png'
import inputconnt from '../../../../../../../../assets/Images/inputconnt.png'
import Warning from '../../../../../../../../Images/warning.png'

import ImageIcon from '../../../../../../../../assets/Images/image (1).png'
// import ConfirmModal from '../../../ConfirmationModal/ConfirmModal'
import { ConfirmationModal } from 'Components'

import './ImageWithLink.scss'

export const ImageWithLink = ({
  creatives,
  setCampaign,
  campaign,
  isSubmit,
  type,
  setnextClicked,
  setUrls,
  urls,
}: {
  creatives: any
  setCampaign: (value: CampaignType) => void
  campaign: CampaignType
  isSubmit: boolean
  type: string
  setnextClicked: (p: boolean) => void
  setUrls?: any
  urls?: any
}) => {
  const [deleteModal, setdeleteModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [deleteModalId, setdeleteModalId] = useState<any>()
  const checkName = (val: string) => {
    if (val === 'banner') {
      return `Digital Banner Clickthrough URL`
    }
    // if (val === 'directMail') {
    //   return 'Direct Mail '
    // }
    return 'Video Clickthrough URL'
  }

  const creativeClickUrl = (str: any) => {
    return str?.replace('https://', '')
  }
  const onChangeUrl = (e: any, creative: any, index: number) => {
    if (e.target.value.length <= 4 || !validateURL(e.target.value)) {
      setIsError(true)
      setnextClicked(true)
      if (creative.content_type === 'banner') {
        setUrls({ ...urls, banner_url: false })
      }
      if (creative.content_type === 'video') {
        setUrls({ ...urls, video_url: false })
      }
    } else {
      setIsError(false)
      setnextClicked(false)
      if (creative.content_type === 'banner') {
        setUrls({ ...urls, banner_url: true })
      }
      if (creative.content_type === 'video') {
        setUrls({ ...urls, video_url: true })
      }
    }

    if (creative.content_type === 'banner') {
      for (let a = 0; a < campaign.creatives.length; a += 1) {
        if (campaign.creatives[a].content_type === 'banner') {
          campaign.creatives[a].click_url = e.target.value
          if (e.target.value) {
            campaign.creatives[a].errorVal = false
          }
        }
      }
    } else {
      campaign.creatives[index].click_url = e.target.value
      if (e.target.value) {
        campaign.creatives[index].errorVal = false
      }
    }
    setCampaign({
      ...campaign,
      creatives: [...campaign.creatives],
    })
  }

  return (
    <>
      {deleteModal && (
        <ConfirmationModal
          // typeModal={""}
          heading="Are you sure you want to delete this creative?"
          onCancel={() => setdeleteModal(false)}
          // deleteId={deleteModalId}
          // onClickOutside={onClickOutside}
          onSubmit={(e) => {
            const directMailFront = campaign.creatives.findIndex(
              (ele: { mail_type: string }) => ele.mail_type === 'front',
            )

            if (campaign?.creatives[e]?.mail_type === 'front') {
              const directMailBack: any = campaign.creatives.findIndex(
                (ele: { mail_type: string }) => ele.mail_type === 'back',
              )

              const loopArr = [directMailBack, directMailFront]
              const slicedArr = campaign.creatives

              for (let newI = 0; newI < loopArr.length; newI += 1) {
                slicedArr.splice(loopArr[newI], 1)
              }
              setCampaign({
                ...campaign,
                creatives: [...slicedArr],
              })
            } else {
              const slicedArr = campaign.creatives
              slicedArr.splice(deleteModalId, 1)
              setCampaign({
                ...campaign,
                creatives: [...slicedArr],
              })
            }
            setdeleteModal(false)
          }}
        />
      )}
      {creatives?.map(
        (
          creative: {
            mail_type: string
            content_type: string
            errorVal: any
            click_url: string | number | readonly string[] | undefined
            thumb: string | undefined
            content_name: any
          },
          index: number,
        ) =>
          creative?.mail_type !== 'back' ? (
            <>
              <GridBox gap="15px" gridTemplateColumns="2.6fr">
                {creative?.mail_type !== 'back' &&
                  index ===
                    creatives.findIndex(
                      (creat: any) =>
                        creat.content_type === type &&
                        creat?.mail_type !== 'back',
                    ) && (
                    <>
                      <>
                        {/* <div className="AttachedCreatives__hl" key={index} /> */}

                        <div style={{}}>
                          <Text
                            UNSAFE_className="digital_banner_title"
                            tag="div"
                            on="white"
                            size="l"
                            UNSAFE_style={{ color: '#2B1F0A' }}
                          >
                            {checkName(creative.content_type)}
                            <span style={{ color: 'red' }}>*</span>
                            {/* {creative.content_type === 'banner' &&
                        setdigitalCount(digitalCount + 1)} */}
                            {/* {creative.content_type === 'banner' && digitalCount} */}
                          </Text>
                          {creative?.content_type !== 'directMail' && (
                            <>
                              <InputBox
                                wdt="100%"
                                Input__ClassName="link_input_field"
                                value={creativeClickUrl(creative?.click_url)}
                                prefix="https://"
                                placeHolder="www.example.com"
                                paddingLeft="100px"
                                paddingTop="5px"
                                onChange={(e) => {
                                  onChangeUrl(e, creative, index)
                                  // (!url_validation(e.target.value))
                                  // (e.target.value.length < 4)

                                  // if (e.target.value.length < 4) {
                                  //   setIsError(true)
                                  //   setnextClicked(true)
                                  // } else {
                                  //   setIsError(false)
                                  //   setnextClicked(false)
                                  // }
                                  // if (creative.content_type === 'banner') {
                                  //   for (
                                  //     let a = 0;
                                  //     a < campaign.creatives.length;
                                  //     a += 1
                                  //   ) {
                                  //     if (
                                  //       campaign.creatives[a].content_type ===
                                  //       'banner'
                                  //     ) {
                                  //       campaign.creatives[a].click_url =
                                  //         e.target.value
                                  //       if (e.target.value) {
                                  //         campaign.creatives[a].errorVal = false
                                  //       }
                                  //     }
                                  //   }
                                  // } else {
                                  //   campaign.creatives[index].click_url =
                                  //     e.target.value
                                  //   if (e.target.value) {
                                  //     campaign.creatives[index].errorVal = false
                                  //   }
                                  // }
                                  // setCampaign({
                                  //   ...campaign,
                                  //   creatives: [...campaign.creatives],
                                  // })
                                }}
                                // size="l"
                                UNSAFE_CLASS_icon="padding-Icon "
                                UNSAFE_CLASS_text="sizeInput link_input_field_err"
                                UNSAFE_CLASS_Boolean={false}
                                icon={
                                  <FontAwesomeIcon
                                    icon={faLink}
                                    className="AttachedCreatives__icon"
                                  />
                                }
                                isError={
                                  (isSubmit && creative?.errorVal) || isError
                                }
                                // onChange={(e) => {
                                //   setEmail(e.target.value)
                                //   setIsSubmit(false)
                                //   setLoading(false)
                                // }}
                                validIcon={
                                  <abbr title="https://www.example.com">
                                    <FontAwesomeIcon
                                      icon={faExclamationCircle}
                                      style={{
                                        fontSize: '23px',
                                        margin: '13px 5px 0px 12px',
                                      }}
                                    />
                                  </abbr>
                                }
                                UNSAFE_CLASS_RIGHT_ICON
                                // error={}
                              />
                              {/* <LableInputField
                                value={creativeClickUrl(creative?.click_url)}
                                style={{ width: '40%', margin: '1rem 0' }}
                                title="Digital Banner (1) Clickthrough URL"
                                placeholder={checkName(creative.content_type)}
                                isError={
                                  (isSubmit && creative?.errorVal) || isError
                                }
                                type="Email"
                                iconLeft={
                                  <img
                                    className="start_icon"
                                    style={{ marginTop: '7px', width: '24px' }}
                                    src={inputconnt}
                                    alt="icon"
                                  />
                                }
                                iconRight={
                                  <img
                                    className="start_icon"
                                    style={{
                                      marginTop: '7px',
                                      width: '24px',

                                      right: '744px',
                                      position: 'absolute',
                                    }}
                                    src={
                                      (isSubmit && creative?.errorVal) ||
                                      isError
                                        ? Warning
                                        : ' '
                                    }
                                    // alt="icon"
                                  />
                                }
                                onChange={(e) => {
                                  onChangeUrl(e, creative, index)
                                }}
                                // lableStyle={{ top: '22px', left: '15px' }}
                              /> */}
                              {isSubmit && creative?.errorVal && (
                                <ErrorMsg
                                  UNSAFE_CLASS_icon="creative-error"
                                  title="Please enter url"
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faExclamationCircle}
                                    />
                                  }
                                />
                              )}
                            </>
                          )}

                          {/* <ClickthroughInput value="" /> */}
                          {/* { ? 'please fill' : ''} */}
                        </div>
                      </>

                      <div className="ImageWithLink">
                        {/* <img
                          // src={des.thumb || ImageIcon}
                          // src="https://beewo-dev-api.appiskey.com/api/v1/users/get_media?path=/app/creative_type_files/119/631/1643270926.685963__Leaderboard_728x90.png"
                          src="https://media.istockphoto.com/photos/headshot-of-44-year-old-mixed-race-man-in-casual-polo-shirt-picture-id1264106963?b=1&k=20&m=1264106963&s=170667a&w=0&h=dLQliHpFkaweGQhiRfkNGkwsAPoKCEy9UWWk-m2iCCk="
                          alt="banner"
                        /> */}

                        {creatives.map(
                          (des: any, innerIndex: any) =>
                            des.content_type === type &&
                            des?.mail_type !== 'back' && (
                              <div className="center-image-box">
                                <div className="image-box">
                                  <input
                                    type="image"
                                    src={closeIcon}
                                    alt="new"
                                    className="image-box__img-1"
                                    onClick={() => {
                                      setdeleteModalId(innerIndex)
                                      setdeleteModal(true)
                                    }}
                                  />

                                  {des.content_type !== 'video' ? (
                                    <img
                                      src={des.thumb || ImageIcon}
                                      // src="/app/creative_type_files/119/631/1643270926.685963__Leaderboard_728x90.png"

                                      // src="https://beewo-dev-api.appiskey.com/api/v1/users/get_media?path=/app/creative_type_files/119/631/1643270926.685963__Leaderboard_728x90.png"

                                      // src="https://media.istockphoto.com/photos/headshot-of-44-year-old-mixed-race-man-in-casual-polo-shirt-picture-id1264106963?b=1&k=20&m=1264106963&s=170667a&w=0&h=dLQliHpFkaweGQhiRfkNGkwsAPoKCEy9UWWk-m2iCCk="
                                      alt="banner"
                                      className="image-box__img-2"
                                    />
                                  ) : (
                                    <video
                                      // src={this.state.get_video}
                                      src={des.thumb || ImageIcon}
                                      className="image-box__img-2"

                                      // className="get_preview_video_class"
                                    >
                                      <track kind="captions" />
                                    </video>
                                  )}
                                </div>
                                {/* <Text
tag="div"
on="white"
size="s"
UNSAFE_style={{ color: '#34A2C6' }}
UNSAFE_className="center-image-box__text"
>
View
</Text> */}
                                <button
                                  type="button"
                                  className="center-image-box__text"
                                  // download={creative.thumb}

                                  onClick={(e) => {
                                    // download={

                                    // }
                                    // href={}
                                    // target="_blank"
                                    // rel="noreferrer"
                                    if (des.thumb.includes('blob')) {
                                      const anchor = document.createElement('a')

                                      anchor.href = des.thumb
                                      anchor.download = des?.content_name
                                        ? des?.content_name
                                        : 'download'
                                      anchor.target = '_blank'
                                      anchor.click()
                                    } else {
                                      getBase64(des.thumb).then((res) => {
                                        // setloader(false);
                                        // setimgState("data:image/jpeg;base64," + res);
                                        // showMarkerArea();
                                        const anchor =
                                          document.createElement('a')

                                        anchor.href = `data:image/${des.thumb
                                          .split('.')
                                          .pop()};base64,${res}`

                                        anchor.download = des?.content_name
                                          ? des?.content_name
                                          : 'download'
                                        anchor.target = '_blank'

                                        anchor.click()
                                      })
                                    }

                                    if (des.mail_type === 'front') {
                                      const element = e.target

                                      // element.href = URL.createObjectURL('file')
                                      // element.download = 'image.jpg'
                                      // element.click()
                                      const a = document.createElement('a')
                                      a.href = creatives[innerIndex + 1].thumb
                                      a.download = creatives[innerIndex + 1]
                                        ?.content_name
                                        ? creatives[innerIndex + 1].content_name
                                        : 'download'
                                      a.target = '_blank'

                                      a.click()
                                      // window.open(creatives[1].thumb)
                                    }
                                  }}
                                >
                                  View
                                </button>
                                {/* <a
    href={creative.thumb}
    target="_blank"
    rel="noreferrer"
    download
  /> */}
                              </div>
                            ),
                        )}
                      </div>
                    </>
                  )}
              </GridBox>
            </>
          ) : (
            <></>
          ),
      )}
    </>
  )
}
