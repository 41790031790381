import React from 'react'
import { RecoilText } from '../..'
import { BlockConfigType } from '../../../../Typings'

export const BasicParagraph: React.FC<{
  id: string
  width: number
  height: number
  isOnMobile?: boolean
}> = ({ id, width, height, isOnMobile = false }) => {
  const textConfig: BlockConfigType = {
    id,
    kind: 'basicParagraph',
    key: 'paragraphText',
  }

  return (
    <RecoilText
      config={textConfig}
      width={width}
      height={height}
      isOnMobile={isOnMobile}
    />
  )
}
