import React, { useEffect, useState } from 'react'
import { APIFeatureType } from 'types'
import { GridBox, Text } from '@eltoro-ui/components'
import './MapPopup.scss'
import {
  getAbbreviatedNumber,
  getIconBuzzColor,
  hexToRgb,
  luminance,
} from 'Helpers'
import { Bar } from 'react-chartjs-2'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { getListingHistogram } from 'Requests'
import { useSelector, connect } from 'react-redux'
import {
  savedAudiences,
  finishedCampaigns,
  verticalData,
  multiData,
} from '../../Pages/CampaignDashboard/fakeData'
import {
  multiOverlappingOptions,
  verticalStackingOptions,
} from '../../Pages/CampaignDashboard/barOptions'

// @ts-ignore
const MarkerWrap = React.createRef(null)

export const MapPopup: React.FC<{ feature: APIFeatureType }> = ({
  feature,
}) => {
  const getTextHeaderColor = () => {
    // trim 'var(' & ')' from returned string
    const trimmedColor = getIconBuzzColor(feature).slice(4, -1)
    // get css var property and remove '#' from beginning
    const hexColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(trimmedColor)
      .replace(/[#]/g, '')
    // convert hex to rgb and get luminance
    // useEffect(() => {
    // })

    const lumins = luminance(hexToRgb(hexColor))
    if (lumins > 0.6) {
      return 'black'
    }
    return 'white'
  }
  // @ts-ignore
  const [selectedVal, setSelectedVal] = useState(false)
  // @ts-ignore

  function markerHis() {
    const mls = localStorage.getItem('Seleceted_Marker')
    if (mls === feature.mls_id) {
      setSelectedVal(true)
    }
  }
  setInterval(markerHis, 500)
  return (
    <div className="MapPopup">
      <div
        // style={{ background: getIconBuzzColor(feature) }}
        className="MapPopup__header"
      >
        <b>Weekly Buzz</b>
        <br />
        {/* <span style={{ color: getTextHeaderColor() }}> */}
        <Text on="white">{feature.street}</Text>
        {/* </span> */}
      </div>
      <GridBox gridTemplateColumns="max-content" gap=".5rem" padding="1rem">
        <Text on="white">
          <span>
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
          </span>
          beds : {feature.beds} {`  ${feature.mls_id}`}
        </Text>
        <Text on="white">
          <span>
            {' '}
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
          </span>{' '}
          baths : {feature.baths}
        </Text>
        <Text on="white">
          <span>
            {' '}
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
          </span>
          Listing Price: $
          {feature.listing_price &&
            getAbbreviatedNumber(feature.listing_price.toString())}
        </Text>

        {/* <div id={feature.mls_id}>It loads</div>
        {selectedVal ? '<div>' : 'Not Showing'} */}
        <div className="MapPopup__buzzStatus">MEDIUM BUZZ</div>

        {/* <Text on="white">
          Listed on <b>{new Date(feature.created).toLocaleDateString()}</b>
        </Text> */}
      </GridBox>
    </div>
  )
}
