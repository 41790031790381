import React, { useEffect, useState } from 'react'
import './ListingsList.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSortAmountDownAlt,
  faSortAmountUp,
  faArrowRight,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCenterLens,
  setIsAudienceSaved,
  setRefreshList,
  setSelectedPage,
  setSelectedSort,
  setTypeSearch,
} from 'Redux/actions'
import {
  Button,
  Checkbox,
  Select,
  TextInput,
  TextSelect,
} from '@eltoro-ui/components'
import { getListings } from 'Requests'
import { APIFeatureType, TRootState } from 'types'
import { ListingLabel } from '..'

export const ListingsList = ({
  listings,
  selectedListings,
  onSelectedListingsChange,
  listingData,
  type,
}: {
  listings: APIFeatureType[]
  selectedListings: APIFeatureType[]
  listingData?: any
  onSelectedListingsChange: (newSelectedListings: APIFeatureType[]) => void
  type?: string
}) => {
  const [searchTextValue, setSearchTextValue] = useState('')
  const [allChecked, setAllChecked] = useState(false)
  const [page, setPage] = useState(1)
  const [buzzSortOrder, setBuzzSortOrder] = useState<'asc' | 'desc'>('desc')
  const [addressOrder, setAddressOrder] = useState<'asc' | 'desc'>('desc')
  const sortListingsByBuzz = (listingsToSort: APIFeatureType[]) => {
    // if (addressOrder === 'asc')
    //   return listingsToSort.sort((a, b) => a.zip - b.zip)
    // if (addressOrder === 'desc')
    //   return listingsToSort.sort((a, b) => b.zip - a.zip)
    // sortByZipcode(listingsToSort)
    if (buzzSortOrder === 'asc')
      return listingsToSort.sort((a, b) => a.activity_score - b.activity_score)
    if (buzzSortOrder === 'desc')
      return listingsToSort.sort((a, b) => b.activity_score - a.activity_score)

    return listingsToSort
  }
  const dispatch = useDispatch()
  const { isCenter } = useSelector(
    (state: TRootState) => state.centerLensReducer,
  )
  const options = [
    { label: 'Buzz Score', value: 'activity_score' },
    { label: 'Zip Code', value: 'zip' },
  ]
  const [selectedOption, setSelectedOption] = useState({ label: '' })
  // const { filteredBuzz } = useSelector(
  //   (state) => state.FilteredBuzzReducer,
  // )
  const handleListingLabelOnClick = (listing: APIFeatureType) => {
    const matchingListing = selectedListings.find(
      (l) => l.listingid === listing.listingid,
    )
    if (matchingListing)
      onSelectedListingsChange(
        selectedListings.filter((l) => l.listingid !== listing.listingid),
      )
    else onSelectedListingsChange([...selectedListings, listing])
  }
  const isAudienceSaved = useSelector(
    (state: TRootState) => state.isSavedAudienceReducer,
  )
  const handleAllCheckedChange = (checked: boolean) => {
    if (checked) {
      onSelectedListingsChange(listings)
      setAllChecked(true)
    } else {
      onSelectedListingsChange([])
      setAllChecked(false)
    }
  }

  useEffect(() => {
    if (
      selectedListings.length === listings.length &&
      selectedListings.length &&
      listings.length
    ) {
      setAllChecked(true)
    } else {
      setAllChecked(false)
    }
  }, [listings, selectedListings])

  useEffect(() => {
    if (isAudienceSaved.isAudienceSaved) {
      onSelectedListingsChange([])
      setAllChecked(false)
      dispatch(setIsAudienceSaved(false))
    }
  }, [isAudienceSaved])
  const { selectedPage } = useSelector(
    (state: TRootState) => state.selectedPageReducer,
  )
  const { typedSearch } = useSelector(
    (state: TRootState) => state.typedSearchReducer,
  )
  const { refreshList } = useSelector(
    (state: TRootState) => state.RefreshListingReducer,
  )
  const buzzStatus = (val: { label: string }) => {
    setSelectedOption(val)
    if (selectedOption.label === 'Buzz Score') {
      if (buzzSortOrder === 'asc') setBuzzSortOrder('desc')
      else setBuzzSortOrder('asc')
    }
    if (selectedOption.label === 'Zip Code') {
      if (addressOrder === 'asc') setAddressOrder('desc')
      else setAddressOrder('asc')
    }
  }

  const { selectedSort } = useSelector(
    (state: TRootState) => state.selectedSortReducer,
  )
  const viewDetail = () => {
    if (isCenter) {
      dispatch(setCenterLens(false))
    } else {
      dispatch(setCenterLens(true))
    }
  }

  function getPaginatedList() {
    if (selectedPage === listingData?.total_pages) {
      const subt = selectedPage * 20 - listingData?.total_listing
      return selectedPage * 20 - subt
    }

    return selectedPage * 20
  }

  // useEffect(() => {
  //   dispatch(setSelectedSort(options[1]))
  // }, [])
  return (
    <div className={`ListingsList ${type}`}>
      <div className="ListingsList__controls">
        <Checkbox
          isCircular
          checked={allChecked}
          onChange={handleAllCheckedChange}
          fontOverWrite
          label="Select all"
        />

        <TextSelect
          Height="30px"
          options={options}
          placeholder="__Select__"
          selectedOption={selectedSort}
          onChange={(value) => {
            handleAllCheckedChange(false)
            dispatch(setSelectedSort(value))
          }}
          maxHeight={300}
          sideText="Sort By :"
          size="12px"
          Margin="0px auto 1px auto"
          Width="180px"
          UNSAFE_Sort_CSS_className="fontSize_sort_by"
          UNSAFE_Selected_className="font-md"
          weight="bold"
        />
      </div>
      {type !== 'Digital Farming' && (
        <div className="ListingsList__search">
          <TextInput
            Background="transparent"
            icon={<FontAwesomeIcon icon={faSearch} />}
            value={typedSearch}
            onChange={(e) => {
              dispatch(setTypeSearch(e.target.value))
            }}
            size="l"
            margin="1rem 0"
          />
        </div>
      )}
      <hr />
      <div
        className={
          isCenter
            ? `ListingsList__listingsCenter ListingsList__listingScroll  ${
                type === 'Digital Farming' && 'Digital_Farming__listingsCenter'
              }`
            : `ListingsList__listings ListingsList__listingScroll ${
                type === 'Digital Farming' && 'Digital_Farming__listings'
              }`
        }
      >
        {sortListingsByBuzz(refreshList).map((listing) => (
          <ListingLabel
            key={listing.listingid}
            isSelected={
              !!selectedListings.find(
                (selectedL) => selectedL.listingid === listing.listingid,
              )
            }
            listing={listing}
            onClick={handleListingLabelOnClick}
          />
        ))}
      </div>
      <hr />
      <div className="ListingsList__listingsDetail">
        <div>
          {' '}
          <Button
            kind="default"
            onClick={viewDetail}
            UNSAFE_className="ListingsList__listingsDetailBtn"
          >
            {isCenter ? 'Less Detail' : 'More Detail'}
          </Button>
        </div>
        <div>
          <button
            type="button"
            style={{ margin: '0 5px', background: '#fff', border: 'none' }}
            disabled={selectedPage === 1}
            onClick={() => dispatch(setSelectedPage(selectedPage - 1))}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          {selectedPage * 20 - 19} - {getPaginatedList()}
          <button
            type="button"
            style={{ margin: '0 5px', background: '#fff', border: 'none' }}
            disabled={selectedPage === listingData?.total_pages}
            onClick={() => {
              dispatch(setSelectedPage(selectedPage + 1))
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
          <p style={{ textAlign: 'center', fontSize: '11px', margin: '0' }}>
            {listingData?.total_listing} listings
          </p>
        </div>
      </div>
    </div>
  )
}
