export const userMailReducer: undefined | any = (
  state = { userEmail: null },
  action: any,
) => {
  switch (action.type) {
    case 'SET_USER_MAIL':
      return { userEmail: action.payload }

    default:
      return { ...state }
  }
}
