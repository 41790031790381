export const RefreshListingReducer: undefined | any = (
  state = { refreshList: [] },
  action: any,
) => {
  switch (action.type) {
    case 'SET_REFRESH_LIST':
      return { refreshList: action.payload }
    default:
      return { ...state }
  }
}

export const typedSearchReducer: undefined | any = (
  state = { typedSearch: '' },
  action: any,
) => {
  switch (action.type) {
    case 'SET_TYPED_SEARCH':
      return { typedSearch: action.payload }
    default:
      return { ...state }
  }
}

export const selectedPageReducer: undefined | any = (
  state = { selectedPage: 1 },
  action: any,
) => {
  switch (action.type) {
    case 'SET_SELECTED_PAGE':
      return { selectedPage: action.payload }
    default:
      return { ...state }
  }
}

export const selectedSortReducer: undefined | any = (
  state = { selectedSort: { label: 'Zip Code', value: 'zip' } },
  action: any,
) => {
  switch (action.type) {
    case 'SET_SELECTED_SORT':
      return { selectedSort: action.payload }
    default:
      return { ...state }
  }
}

export const targetAudienceReducer: undefined | any = (
  state = { targetAudience: '' },
  action: any,
) => {
  switch (action.type) {
    case 'SET_AUDIENCE_NAME':
      return { targetAudience: action.payload }
    default:
      return { ...state }
  }
}

export const isSavedAudienceReducer: undefined | any = (
  state = { isAudienceSaved: false },
  action: any,
) => {
  switch (action.type) {
    case 'IS_AUDIENCE_SAVED':
      return { isAudienceSaved: action.payload }
    default:
      return { ...state }
  }
}
