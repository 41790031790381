import React, { FC, useState } from 'react'
import { Button, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loading } from 'Components'
import { TMetaData } from 'types'
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import basic_subscription from '../../../../assets/Images/basic_subscription.png'
import premium_subscription from '../../../../assets/Images/premium_subscription.png'
import './SubscriptionCard.scss'

export const SubscriptionCard: FC<any> = ({
  data,
  onClick,
  loading,
  userData,
  button_visibility_hidden = false,
  backgroundTab = '',
}) => {
  const [dropDownActive, setDropDownActive] = useState<Boolean>(false)

  const _onClick = (subscription: {
    bullet_points: any
    id: string
    metadata: TMetaData
  }) => {
    const body = {
      subscription_id: subscription.id,
      metadata: subscription.metadata,
      bullet_points: subscription.bullet_points,
      user_id: userData?.id,
    }
    onClick(body)
  }
  const subscription_name = (subscription: { name: string }) => {
    return subscription?.name === 'beewo-subscription-basic'
      ? 'Basic Subscription'
      : 'Premium Subscription'
  }

  const upgradeBottomIsHide = (subscriptionName: string) =>
    subscriptionName?.includes(userData?.sub_name)

  const conditionalText = (name: string) => {
    if (backgroundTab === 'profile') {
      if (upgradeBottomIsHide(name)) {
        return 'Buy'
      }
      if (userData?.sub_name === 'beewo-subscription-basic') {
        return 'Buy'
      }

      if (button_visibility_hidden) {
        return 'Buy'
      }
      return 'Buy'
    }
    return 'Buy'
  }

  const getSubscriptionButton = (subscription: any) => {
    if (userData?.sub_name === null) {
      return (
        <Button
          type="button"
          kind="primary"
          size="m"
          weight="bold"
          UNSAFE_className={`upgrade_button ${
            subscription?.name === 'beewo-subscription-basic'
              ? 'basic_btn'
              : 'premium_btn'
          } ${button_visibility_hidden && 'visibility_hidden'}`}
          UNSAFE_style={{
            color:
              subscription?.name === 'beewo-subscription-basic'
                ? '#FFAB03'
                : '#1f1f1f',
          }}
          onClick={() => _onClick(subscription)}
          disabled={!0}
        >
          {conditionalText(subscription?.name)}
        </Button>
      )
    }
    if (
      userData?.sub_name === 'beewo-subscription-premium' &&
      subscription?.name === 'beewo-subscription-basic'
    ) {
      return null
    }

    return (
      <Button
        type="button"
        kind="primary"
        size="m"
        weight="bold"
        UNSAFE_className={`upgrade_button ${
          subscription?.name === 'beewo-subscription-basic'
            ? 'basic_btn'
            : 'premium_btn'
        } ${button_visibility_hidden && 'visibility_hidden'}`}
        UNSAFE_style={{
          color:
            subscription?.name === 'beewo-subscription-basic'
              ? '#FFAB03'
              : '#1f1f1f',
        }}
        // disabled={
        //   (upgradeBottomIsHide(subscription?.name) ||
        //     button_visibility_hidden) &&
        //   true
        // }
        // disabled={Boolean(true)}
        disabled={!0}
        onClick={() => _onClick(subscription)}
      >
        {conditionalText(subscription?.name)}
      </Button>
    )
  }
  return (
    <div
      className="subscription_card_container"
      style={{ height: data.length === 1 ? 'auto' : '' }}
    >
      {!data.length && userData ? (
        <Loading />
      ) : (
        data.map((subscription: any, index: number) => (
          <div>
            <div
              key={`${subscription.id}${index}`}
              data-testid="subscriptionCard"
              style={{
                marginRight: index === 1 ? '-4px' : '2rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              className="subscription_card"
            >
              <div>
                <div style={{ display: 'flex' }}>
                  {subscription_name(subscription) === 'Basic Subscription' ? (
                    <img
                      src={basic_subscription}
                      alt="basic_subscription"
                      className="sub_logo"
                    />
                  ) : (
                    <img
                      src={premium_subscription}
                      alt="premium_subscription"
                      className="sub_logo"
                    />
                  )}
                  <Text
                    on="white"
                    weight="bold"
                    size="l"
                    textAlign="center"
                    UNSAFE_style={{
                      margin:
                        subscription_name(subscription) ===
                        'Premium Subscription'
                          ? '11px 0px 0px -1px'
                          : '13px 13px 0px 9px',

                      color:
                        subscription_name(subscription) ===
                        'Premium Subscription'
                          ? '#000'
                          : '#fff',
                    }}
                    UNSAFE_className="title"
                  >
                    {subscription?.name === 'beewo-subscription-basic'
                      ? 'Basic Subscription'
                      : 'Premium Subscription'}
                    {/* {subscription?.name === 'beewo-subscription-premium' ? 'hii' : ''} */}
                  </Text>
                </div>
                <div
                  className="price_div"
                  style={{
                    marginTop:
                      subscription?.name === 'beewo-subscription-basic'
                        ? '30px'
                        : '33px',
                  }}
                >
                  <Text
                    UNSAFE_className="price_value"
                    on="white"
                    weight="bold"
                    size="xxxl"
                    textAlign="center"
                  >
                    <span>$</span>
                    {parseInt(subscription?.metadata?.price, 10)}
                  </Text>
                  <div className="price_content">
                    <span className="decimal_value">
                      .
                      {(subscription?.metadata?.price).toString().split('.')[1]}
                    </span>
                    <Text
                      on="grey-500"
                      weight="normal"
                      size="xl"
                      textAlign="center"
                      UNSAFE_className="sub_month"
                    >
                      Per Month
                    </Text>
                  </div>
                </div>

                <ul
                  className={`${
                    subscription?.name === 'beewo-subscription-basic'
                      ? 'basic'
                      : 'premium'
                  } `}
                  style={{
                    paddingBottom: button_visibility_hidden && '0px',
                  }}
                >
                  {/* {subscription?.name === 'beewo-subscription-premium' ? (
                <li>
                  <Text
                    on="grey-500"
                    weight="normal"
                    size="m"
                    textAlign="center"
                    UNSAFE_className={`${
                      subscription?.name === 'beewo-subscription-basic'
                        ? 'basic'
                        : 'premium'
                    } `}
                  >
                    Everything in the Basic Package
                  </Text>
                  <FontAwesomeIcon
                    className="sidebar_icon"
                    icon={dropDownActive ? faSortUp : faSortDown}
                    style={{ marginLeft: '2%' }}
                    cursor="pointer"
                    onClick={() => {
                      setDropDownActive(!dropDownActive)
                    }}
                  />
                </li>
              ) : (
                ''
              )} */}
                  {subscription?.name === 'beewo-subscription-premium' &&
                  dropDownActive ? (
                    <div className="dropDownPremium">hellooooo</div>
                  ) : (
                    ''
                  )}
                  {subscription?.bullet_points?.map(
                    (
                      features: {
                        name: String
                        id: number | null
                        description: Array<string>
                      },
                      indexBullet: number,
                    ) => (
                      <React.Fragment key={`${features.id}${indexBullet}`}>
                        <li>
                          <Text
                            on="grey-500"
                            weight="normal"
                            size="m"
                            textAlign="center"
                            UNSAFE_className={`${
                              subscription?.name === 'beewo-subscription-basic'
                                ? 'basic'
                                : 'premium'
                            } `}
                          >
                            {features?.name}
                          </Text>
                        </li>
                        {features?.description?.length > 0 && (
                          <ul>
                            {features?.description?.map(
                              (des: String, i: number) => (
                                <li key={`${des}${i}`}>{des}</li>
                              ),
                            )}
                          </ul>
                        )}
                      </React.Fragment>
                    ),
                  )}
                </ul>
              </div>
              <div>{getSubscriptionButton(subscription)}</div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}
