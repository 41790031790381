/* eslint-disable */
import { Button, FormItem } from '@eltoro-ui/components'
import { useValidation } from '@eltoro-ui/hooks'
import { ErrorMsg, InputBox, Loading } from 'Components'
import { PersonalInput } from 'Components/PersonalInfoForm/component/PersonalInput'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import 'react-phone-input-2/lib/material.css'
import { get_email_validation } from 'Helpers'
import { useHistory, useParams } from 'react-router-dom'
import { getContactDetailById } from 'Requests'
import { editContact } from 'Requests/Request_Methods/contactsMethods'
import './ContactEdit.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faTable } from '@fortawesome/pro-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { LableInputField } from 'Components/LableInputField'
import Warning from '../../../../Images/warning.png'

export const ContactEdit = () => {
  const params = useParams()
  const { contactId } = useParams<{ contactId: string }>()
  const [contactInfo, setContactInfo] = useState<any>()
  const [buttonClicked, setButtonClicked] = useState(false)
  const [number, setNumber] = useState<any>()

  const history = useHistory()
  useEffect(() => {
    getContactDetailById(contactId).then((res: any) => {
      setContactInfo(res.data)
    })
  }, [])

  const onChange = (key: string, value: string | undefined) => {
    setContactInfo({ ...contactInfo, [key]: value })
  }

  const [emailIsValid, setEmailIsValid] = useState(true)

  const uploadData = () => {
    setButtonClicked(true)
    editContact(contactInfo, contactInfo.id).then((res) => {
      if (res.data) {
        toast.success('Contacts updated')
        setTimeout(() => {
          history.push(`/my-sphere/${contactId}/contact-details`)
        }, 1000)
      }

      setButtonClicked(false)
    })
  }

  const isValidPhoneNumber = contactInfo?.phone?.length >= 7

  return (
    <div className="screen-inline" style={{ position: 'relative' }}>
      <div className="contactEdit__head">
        {/* <FontAwesomeIcon
          icon={faChevronLeft}
          color="gray"
          style={{ cursor: 'pointer', marginRight: '1rem' }}
          onClick={() => {
            window.history.back()
          }}
        /> */}
        <h3 className="edit_inofo_title">Edit Information </h3>
      </div>
      {contactInfo ? (
        <div className="contactEdit__body">
          <div className="contactEdit__bodyRow">
            <div className="contactEdit__bodyColumn">
              {/* <FormItem
                htmlFor="first name"
                FontSize="14px"
                label="First Name"
                redLabel={!!contactInfo?.first_name}
                required
                Height="1rem"
                UNSAFE_ClassName="contactEdit__bodyColumnTitle"
              >
                <PersonalInput
                  fontSize="16px"
                  type="text"
                  placeHolder="First Name"
                  value={contactInfo?.first_name}
                  wdt="90%"
                  border="1px solid #c6cbcd"
                  onChange={(e) => onChange('first_name', e.target.value)}
                  isError={!contactInfo?.first_name}
                  color="gray"
                  UNSAFE_ClassName="contactEdit__bodyColumnInput"
                />

                {contactInfo?.first_name ? (
                  ''
                ) : (
                  <ErrorMsg
                    data_testid="error-email-input"
                    title="Invalid First Name"
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )}
              </FormItem> */}
              <LableInputField
                UNSAFE_ContainerStyle={{ width: '90%' }}
                style={{
                  paddingLeft: '22px',
                  marginTop: '2rem',
                  width: '100%',
                }}
                lableStyle={{ left: '14px' }}
                value={contactInfo?.first_name}
                title="First Name"
                placeholder="Enter First Name"
                isError={!contactInfo?.first_name}
                type="text"
                iconRight={
                  <img
                    className="start_icon"
                    style={{
                      marginTop: '7px',
                      width: '24px',
                    }}
                    src={!contactInfo?.first_name ? Warning : ''}
                    // alt="icon"
                  />
                }
                onChange={(e) => onChange('first_name', e.target.value)}
                // lableStyle={{ top: '22px', left: '15px' }}
              />
            </div>

            <div className="contactEdit__bodyColumn">
              {/* <FormItem
                htmlFor="last name"
                FontSize="14px"
                label="Last Name"
                redLabel={!!contactInfo?.last_name}
                required
                Height="1rem"
                UNSAFE_ClassName="contactEdit__bodyColumnTitle"
              >
                <PersonalInput
                  fontSize="16px"
                  type="text"
                  placeHolder="Last Name"
                  value={contactInfo?.last_name}
                  wdt="90%"
                  border="1px solid #c6cbcd"
                  onChange={(e) => onChange('last_name', e.target.value)}
                  isError={!contactInfo?.last_name}
                  color="gray"
                  UNSAFE_ClassName="contactEdit__bodyColumnInput"
                />
                {contactInfo?.last_name ? (
                  ''
                ) : (
                  <ErrorMsg
                    data_testid="error-email-input"
                    title="Invalid Last Name"
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )}
              </FormItem> */}
              <LableInputField
                UNSAFE_ContainerStyle={{ width: '90%' }}
                style={{
                  paddingLeft: '22px',
                  marginTop: '2rem',
                  width: '100%',
                }}
                lableStyle={{ left: '14px' }}
                value={contactInfo?.last_name}
                title="Last Name"
                placeholder="Enter Last Name"
                isError={!contactInfo?.last_name}
                type="text"
                iconRight={
                  <img
                    className="start_icon"
                    style={{
                      marginTop: '7px',
                      width: '24px',
                    }}
                    src={!contactInfo?.last_name ? Warning : ''}
                    // alt="icon"
                  />
                }
                onChange={(e) => onChange('last_name', e.target.value)}
                // lableStyle={{ top: '22px', left: '15px' }}
              />
            </div>
          </div>

          <div className="contactEdit__bodyRow">
            <div className="contactEdit__bodyColumn">
              {/* <FormItem
                htmlFor="Address"
                FontSize="14px"
                label="Address"
                redLabel={!!contactInfo?.address}
                required
                Height="1rem"
                UNSAFE_ClassName="contactEdit__bodyColumnTitle"
              >
                <PersonalInput
                  fontSize="16px"
                  type="text"
                  placeHolder="Address"
                  value={contactInfo?.address}
                  wdt="90%"
                  border="1px solid #c6cbcd"
                  onChange={(e) => onChange('address', e.target.value)}
                  isError={!contactInfo?.address}
                  UNSAFE_ClassName="contactEdit__bodyColumnInput"
                  color="gray"
                />
                {contactInfo?.address ? (
                  ''
                ) : (
                  <ErrorMsg
                    data_testid="error-email-input"
                    title="Invalid Address"
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )}
              </FormItem> */}
              <LableInputField
                UNSAFE_ContainerStyle={{ width: '90%' }}
                style={{
                  paddingLeft: '22px',
                  marginTop: '2rem',
                  width: '100%',
                }}
                lableStyle={{ left: '14px' }}
                value={contactInfo?.address}
                title="Address"
                placeholder="Enter Address"
                isError={!contactInfo?.address}
                type="text"
                iconRight={
                  <img
                    className="start_icon"
                    style={{
                      marginTop: '7px',
                      width: '24px',
                    }}
                    src={!contactInfo?.address ? Warning : ''}
                    // alt="icon"
                  />
                }
                onChange={(e) => onChange('address', e.target.value)}
                // lableStyle={{ top: '22px', left: '15px' }}
              />
            </div>

            <div className="contactEdit__bodyColumn">
              {/* <FormItem
                htmlFor="City"
                FontSize="14px"
                label="City"
                // redLabel={!!contactInfo?.city}
                required
                Height="1rem"
                UNSAFE_ClassName="contactEdit__bodyColumnTitle"
              >
                <PersonalInput
                  fontSize="16px"
                  type="text"
                  placeHolder="City"
                  value={contactInfo?.city}
                  wdt="90%"
                  color="gray"
                  border="1px solid #c6cbcd"
                  onChange={(e) => onChange('city', e.target.value)}
                  // isError={!contactInfo?.city}
                  UNSAFE_ClassName="contactEdit__bodyColumnInput"
                />
              </FormItem> */}
              <LableInputField
                UNSAFE_ContainerStyle={{ width: '90%' }}
                style={{
                  paddingLeft: '22px',
                  marginTop: '2rem',
                  width: '100%',
                }}
                lableStyle={{ left: '14px' }}
                value={contactInfo?.city}
                title="City"
                placeholder="Enter Address"
                isError={!contactInfo?.city}
                type="text"
                iconRight={
                  <img
                    className="start_icon"
                    style={{
                      marginTop: '7px',
                      width: '24px',
                    }}
                    src={!contactInfo?.city ? Warning : ''}
                    // alt="icon"
                  />
                }
                onChange={(e) => onChange('city', e.target.value)}
                // lableStyle={{ top: '22px', left: '15px' }}
              />
            </div>
          </div>

          <div className="contactEdit__bodyRow">
            <div className="contactEdit__bodyColumn">
              {/* <FormItem
                htmlFor="Zip Code"
                FontSize="14px"
                label="Zip Code"
                redLabel={!!contactInfo?.zip}
                required
                Height="1rem"
                UNSAFE_ClassName="contactEdit__bodyColumnTitle"
              >
                <PersonalInput
                  fontSize="16px"
                  type="text"
                  placeHolder="Zip Code"
                  value={contactInfo?.zip}
                  wdt="90%"
                  color="gray"
                  border="1px solid #c6cbcd"
                  onChange={(e) => onChange('zip', e.target.value)}
                  isError={!contactInfo?.zip}
                  UNSAFE_ClassName="contactEdit__bodyColumnInput"
                />
                {contactInfo?.zip ? (
                  ''
                ) : (
                  <ErrorMsg
                    data_testid="error-email-input"
                    title="Invalid Zip Code"
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )}
              </FormItem> */}
              <LableInputField
                UNSAFE_ContainerStyle={{ width: '90%' }}
                style={{
                  paddingLeft: '22px',
                  marginTop: '2rem',
                  width: '100%',
                }}
                lableStyle={{ left: '14px' }}
                value={contactInfo?.zip}
                title="Zip Code"
                placeholder="Enter Zip Code"
                isError={!contactInfo?.zip}
                type="text"
                iconRight={
                  <img
                    className="start_icon"
                    style={{
                      marginTop: '7px',
                      width: '24px',
                    }}
                    src={!contactInfo?.zip ? Warning : ''}
                    // alt="icon"
                  />
                }
                onChange={(e) => onChange('zip', e.target.value)}
                // lableStyle={{ top: '22px', left: '15px' }}
              />
            </div>
            <div className="contactEdit__bodyColumn">
              {/* <FormItem
                htmlFor="Phone Number"
                FontSize="14px"
                label="Phone Number"
                redLabel={!!contactInfo?.phone}
                required
                Height="1rem"
                UNSAFE_ClassName="contactEdit__bodyColumnTitle"
              > */}
              {/* <PersonalInput
                  fontSize="16px"
                  type="number"
                  placeHolder="Phone Number"
                  value={contactInfo?.phone}
                  wdt="90%"
                  border="1px solid #c6cbcd"
                  onChange={(e) => onChange('phone', e.target.value)}
                  isError={!contactInfo?.phone}
                  UNSAFE_ClassName="contactEdit__bodyColumnInput"
                  color="gray"
                /> */}
              <ReactPhoneInput
                specialLabel="Phone Number"
                containerStyle={{ marginTop: '15px' }}
                isValid={(value) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: '
                  }
                  if (value.match(/1234/)) {
                    return false
                  }
                  return true
                }}
                searchClass="search-class"
                countryCodeEditable={false}
                country="us"
                placeholder=""
                inputStyle={{
                  width: '100%',
                  padding: '28px 40px 28px 62px',
                  height: '38px',
                  fontSize: '14px',
                  color: '#6D6868',
                  fontWeight: '400',
                }}
                disableSearchIcon
                value={contactInfo?.phone}
                onChange={(value) => onChange('phone', value)}
                buttonStyle={{
                  // border: '1px solid #DADADA',
                  background: 'white',
                  border: 'none',
                  borderRadius: '10px',
                  // borderRight: 'none',
                }}
              />
              {/* {contactInfo?.phone.length >= 7 ? (
                ''
              ) : (
                <ErrorMsg
                  data_testid="error-email-input"
                  title="Invalid Phone Number"
                  icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                />
              )} */}
              {/* </FormItem> */}
            </div>
          </div>

          <div className="contactEdit__bodyRow">
            <div className="contactEdit__bodyColumn">
              {/* <FormItem
                htmlFor="Email"
                FontSize="14px"
                label="Email"
                redLabel={!!contactInfo?.email}
                required
                Height="1rem"
                UNSAFE_ClassName="contactEdit__bodyColumnTitle"
              >
                <PersonalInput
                  fontSize="16px"
                  type="text"
                  placeHolder="Email"
                  value={contactInfo?.email}
                  wdt="90%"
                  border="1px solid #c6cbcd"
                  onChange={(e) => {
                    const validate = get_email_validation(e.target.value)
                    if (!validate) {
                      setEmailIsValid(validate)
                    } else {
                      setEmailIsValid(validate)
                    }
                    onChange('email', e.target.value)
                  }}
                  isError={!contactInfo?.email || !emailIsValid}
                  UNSAFE_ClassName="contactEdit__bodyColumnInput"
                  color="gray"
                />
                {contactInfo?.email && emailIsValid ? (
                  ''
                ) : (
                  <ErrorMsg
                    data_testid="error-email-input"
                    title="Invalid Email Address"
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )}
              </FormItem> */}
              <LableInputField
                UNSAFE_ContainerStyle={{ width: '90%' }}
                style={{
                  paddingLeft: '22px',
                  marginTop: '2rem',
                  width: '100%',
                }}
                lableStyle={{ left: '14px' }}
                value={contactInfo?.email}
                title="Email"
                placeholder="Enter Email"
                isError={!contactInfo?.email || !emailIsValid}
                type="text"
                iconRight={
                  <img
                    className="start_icon"
                    style={{
                      marginTop: '7px',
                      width: '24px',
                    }}
                    src={!contactInfo?.email && emailIsValid ? Warning : ''}
                    // alt="icon"
                  />
                }
                onChange={(e) => {
                  const validate = get_email_validation(e.target.value)
                  if (!validate) {
                    setEmailIsValid(validate)
                  } else {
                    setEmailIsValid(validate)
                  }
                  onChange('email', e.target.value)
                }}
                // lableStyle={{ top: '22px', left: '15px' }}
              />
            </div>
            <div className="contactEdit__bodyColumn" />
          </div>

          <div className="contactEdit__button">
            <Button
              kind="primary"
              size="l"
              weight="bold"
              width="180px"
              onClick={() => {
                uploadData()
              }}
              disabled={
                !contactInfo?.first_name ||
                !contactInfo?.last_name ||
                !contactInfo?.email ||
                !contactInfo?.phone ||
                !isValidPhoneNumber ||
                !contactInfo?.address ||
                !contactInfo?.zip ||
                // !useValidation(/^(0[1-9]|1[0-2])\/?[0-9]{2}$/) ||
                buttonClicked ||
                !emailIsValid
              }
            >
              Update
            </Button>
            <Button
              kind="default"
              size="l"
              weight="bold"
              UNSAFE_className="contactEdit__editButton"
              width="180px"
              onClick={() => {
                window.history.back()
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}
