import React, { useState } from 'react'
import { GridBox, Table, Text, TextInput } from '@eltoro-ui/components'
import './AttachedCreatives.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faImages,
  faVideo,
  faEnvelope,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons'
import { CampaignType, CreativeType } from 'types'
import { faExclamationCircle, faLink } from '@fortawesome/pro-regular-svg-icons'
import { ErrorMsg, InputBox, Tooltip } from 'Components'
import { ClickthroughInput, CreativeThumb } from './_components'
import closeIcon from '../../../../../../assets/Images/close1.png'
import ImageIcon from '../../../../../../assets/Images/image (1).png'
// import { faLink } from '@fortawesome/free-solid-svg-icons'
import ConfirmModal from '../ConfirmationModal/ConfirmModal'
import { ImageWithLink } from './_components/ImageWithLink'

type creativeInfoType = {
  name: string
  icon: IconDefinition
}

const resolveCreativeType: { [key: number]: creativeInfoType } = {
  1: {
    name: 'Digital Banner',
    icon: faImages,
  },
  11: {
    name: 'Video',
    icon: faVideo,
  },
  2: {
    name: 'Direct Mail',
    icon: faEnvelope,
  },
}

export const AttachedCreatives = ({
  creatives,
  campaign,
  setCampaign,
  isSubmit,
  setnextClicked,
  setUrls,
  urls,
}: {
  creatives: CreativeType[]
  campaign: CampaignType
  setCampaign: (value: CampaignType) => void
  isSubmit: boolean
  setnextClicked: (e: boolean) => void
  setUrls?: (e: any) => void
  urls?: any
}) => {
  // function download(urls) {
  //   const url = urls.pop();

  //   const a = document.createElement("a");
  //   a.setAttribute('href', url);
  //   a.setAttribute('download', '');
  //   a.setAttribute('target', '_blank');
  //   a.click();

  //   if (urls.length == 0) {
  //     clearInterval(interval);
  //   }
  // }
  // const interval = setInterval(download, 300, "urls");

  const [clickthroughUrl, setClickthroughUrl] = useState(String)
  const [deleteModal, setdeleteModal] = useState(false)
  const [deleteModalId, setdeleteModalId] = useState<string | number>('')
  const [digitalCount, setdigitalCount] = useState(1)
  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          padding="9px 5px"
          text="Wrong username or password"
          topAbsolute="-89px"
          rightAbsolute="-95px"
        />
      </div>
    )
  }
  return (
    <div className="AttachedCreatives">
      <Text
        tag="div"
        on="white"
        size="xxl"
        UNSAFE_className="your_creative_heading"
      >
        Your Creatives
      </Text>
      <Text
        UNSAFE_className="your_creative_subheading"
        tag="div"
        on="white"
        size="s"
        UNSAFE_style={{ color: '#6d6d6d' }}
      >
        These are your creatives. Please add a clickthrough URL if applicable.
      </Text>

      {/* listing */}
      {deleteModal && (
        <ConfirmModal
          // typeModal={""}
          textToDisplay="Are you sure you want to delete this creative?"
          onClickOutside={() => setdeleteModal(false)}
          deleteId={deleteModalId}
          // onClickOutside={onClickOutside}
          onSubmit={(e) => {
            const directMailFront = campaign.creatives.findIndex(
              (ele: { mail_type: string }) => ele.mail_type === 'front',
            )
            if (campaign.creatives[e].mail_type === 'front') {
              const directMailBack: string = campaign.creatives.findIndex(
                (ele: { mail_type: string }) => ele.mail_type === 'back',
              )
              const loopArr = [directMailBack, directMailFront]
              const slicedArr = campaign.creatives
              for (let newI = 0; newI < loopArr.length; newI += 1) {
                slicedArr.splice(loopArr[newI], 1)
              }
              //   loopArr.forEach((new) =>
              //     // return
              //     // slicedArr.splice(new, 1)
              //                   // slicedArr.splice(new, 1)

              // )

              // slicedArr.splice(directMailFront, 1)
              // slicedArr.splice(directMailBack, 1)

              setCampaign({
                ...campaign,
                creatives: [...slicedArr],
              })
            } else {
              const slicedArr = campaign.creatives
              slicedArr.splice(e, 1)
              setCampaign({
                ...campaign,
                creatives: [...slicedArr],
              })
            }
          }}
        />
      )}

      <ImageWithLink
        creatives={creatives}
        setCampaign={setCampaign}
        campaign={campaign}
        isSubmit={isSubmit}
        type="banner"
        setnextClicked={setnextClicked}
        setUrls={setUrls}
        urls={urls}
      />

      <ImageWithLink
        creatives={creatives}
        setCampaign={setCampaign}
        campaign={campaign}
        isSubmit={isSubmit}
        type="video"
        setnextClicked={setnextClicked}
        setUrls={setUrls}
        urls={urls}
      />

      {/* <ImageWithLink
        creatives={creatives}
        setCampaign={setCampaign}
        campaign={campaign}
        isSubmit={isSubmit}
        type="directMail"
      /> */}

      {/* <Table
        rows={creatives}
        columns={[
          {
            path: 'src',
            label: 'Image',
            RowCell: (creative: CreativeType) => {
              return <CreativeThumb src={creative.thumb} alt={creative.name} />
            },
          },
          { path: 'name', label: 'Creative Name' },
          {
            path: 'type',
            label: 'Type',
            RowCell: (creative: CreativeType) => {
              const type = resolveCreativeType[creative.type]
              return (
                <div>
                  <FontAwesomeIcon
                    icon={type.icon}
                    className="AttachedCreatives__icon"
                  />
                  {type.name}
                </div>
              )
            },
          },
          {
            path: 'clickthrough',
            label: 'Clickthrough URL',
            RowCell: (creative) =>
              creative.type !== 2 && <ClickthroughInput value="" />,
          },
        ]}
      /> */}
    </div>
  )
}
