import React, { useEffect, useState } from 'react'
import './style.scss'

const CloudSpongeWidget = (props) => {
  useEffect(() => {
    addJavascript(
      process.env.REACT_APP_CLOUDSPONGE_KEY,
      '__cloudsponge_widget_script',
      () => {
        // calling init attaches the cloudsponge.launch action to any cloudsponge-launch links on the page
        //  we need to wait until the script has loaded so we aren't "bandwidthist"
        if (window.cloudsponge) {
          window.cloudsponge.init({
            sources: [
              'gmail',
              'yahoo',
              'outlookcom',
              'icloud',
              'aol',
              'office365',
              'outlook',
              'addressbook',
              // 'csv',
              'qq_mail',
            ],
            afterClosing: () => {
              props.onCloseTypea()
            },
            afterInit: () => {
              props.setButtonActive(false)
            },
            // receive the contacts into your javascript and do what you like with them.
            afterSubmitContacts: (contacts) => {
              props.setAddressBookContacts(contacts)
            },
            inlineOauth: false,
            browserContactCacheMin: 0,
          })
        }
      },
    )
  }, [props.loadFirst])
  const addJavascript = (src, id, callback) => {
    if (id && document.getElementById(id)) {
      // the script is already loaded so just invoke the callback and return
      if (callback) callback()
      return
    }

    // create and add the scrpt tag
    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.async = 1
    if (id) {
      scriptTag.id = id
    }

    // set the script to invoke a callback after it loads
    if (callback) {
      if (scriptTag.readyState) {
        // IE7+
        scriptTag.onreadystatechange = () => {
          if (
            scriptTag.readyState === 'loaded' ||
            scriptTag.readyState === 'complete'
          ) {
            // clear the callback so it only ever executes once
            scriptTag.onreadystatechange = null
            callback()
          }
        }
      } else {
        scriptTag.onload = () => {
          // Other browsers support the onload attribute \o/
          callback()
        }
      }
    }

    // assign the src attribute
    scriptTag.src = src
    // add the script to the page
    document.body.appendChild(scriptTag)
  }
  return props.children
}

export default CloudSpongeWidget
