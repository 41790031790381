import {
  Button,
  MaxHeightContainer,
  Select,
  SelectOptionType,
  Spacer,
  Text,
} from '@eltoro-ui/components'
import {
  BuzzScoreFilters,
  Map,
  MapToolTip,
  BuzzScore,
  ProspectDensity,
} from 'Components'
import { getIconBuzzColor } from 'Helpers'
import {
  AudienceResultsCard,
  ListingsList,
  MapMarker,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components'
import { buzzFilters } from 'Pages/ProspectActivity/configs'
import { LinearLoader } from 'Components/LinearLoader'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons'

import { useDispatch, useSelector } from 'react-redux'
import {
  setFilteredBuzz,
  setRefreshList,
  setSelectedListing,
  setSelectedPage,
  setSelectedSort,
  setTypeSearch,
  setSidebarLens,
} from 'Redux/actions'
import toast from 'react-hot-toast'
import { getListings } from 'Requests/Request_Methods/listingMethods'
/* import { mockLocation } from 'Requests/Fixtures/listings' */
import { browserLog } from 'Tools'
import { APIFeatureType, TRootState } from 'types'
import mapMarkerImg from '../../../../Images/Map-Marker.png'
import {
  LensFilterContainer,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from '../components'
import { useSelectedListingMarker } from '../components/LensSelectedMarker/LensSelectedMarker'
import './DigitalFarming.scss'
import buzzScoreImg from '../../../../assets/Images/buzz_score.png'

export const DigitalFarming = () => {
  const [activeBuzzFilters, setActiveBuzzFilters] = useState(buzzFilters)
  const { selectedSort } = useSelector(
    (state: TRootState) => state.selectedSortReducer,
  )
  const [inactiveListing, setInactivelisting] = useState<Boolean>(true)
  const [radiusSize, setradiusSize] = useState<SelectOptionType | undefined>({
    label: '1,000 meters',
  })
  const [activeRadiusTab, setActiveRadiusTab] = useState('default')
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const { selectedList } = useSelector(
    (state: TRootState) => state.selectedListingsReducer,
  )
  const AllBuzz: string[] = []
  const [searchClicked, setsearchClicked] = useState(false)

  const { selectedMarker } = useSelector(
    (state: TRootState) => state.selectedMarkerReducer,
  )
  const { filteredBuzz } = useSelector(
    (state: TRootState) => state.FilteredBuzzReducer,
  )
  const { selectedPage } = useSelector(
    (state: TRootState) => state.selectedPageReducer,
  )
  const { typedSearch } = useSelector(
    (state: TRootState) => state.typedSearchReducer,
  )
  const [listingData, setListingData] = useState<any>({
    listings: [],
    total_listings: 20,
    total_pages: 1,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [toggle, setToggle] = useState(false)

  const { isCenter, openSideBar } = useSelector(
    (state: TRootState) => state.centerLensReducer,
  )

  useEffect(() => {
    if (openSideBar) {
      setToggle(!openSideBar)
    } else {
      setToggle(!openSideBar)
    }
  }, [openSideBar])

  useEffect(() => {
    if (toggle) {
      dispatch(setSidebarLens(!toggle))
    } else {
      dispatch(setSidebarLens(!toggle))
    }
  }, [toggle])

  useEffect(() => {
    // if ('value' in selectedSort) {
    apiCall()
    // }
  }, [
    filteredBuzz,
    selectedPage,
    selectedSort,
    inactiveListing,
    typedSearch.length === 0,
  ])

  const apiCall = () => {
    setLoading(true)
    getListings({
      page: selectedPage,
      size: 20,
      exclude: filteredBuzz,
      search: typedSearch,
      sort: selectedSort.value,
      show_inactive: inactiveListing,
    })
      .then((res) => {
        // setList(res.data.listings)
        setsearchClicked(false)

        // setListingData(res.data)
        const newA = res.data?.listings.map((v: object) => ({
          ...v,
          radius: radiusSize,
          selected: false,
          unique_observations_total: 0,
        }))
        setListingData({ ...res.data, listings: newA })
        setIsLoading(true)
        dispatch(setRefreshList(newA))
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err)
      })
  }

  // useEffect(() => {
  //   if (typedSearch.length >= 1) {
  //     dispatch(setSelectedPage(1))
  //     getListings({
  //       page: selectedPage,
  //       size: 20,
  //       exclude: filteredBuzz,
  //       search: typedSearch,
  //       sort: selectedSort.value,
  //     })
  //       .then((res) => {
  //         // setList(res.data.listings)
  //         setListingData(res.data)
  //         setIsLoading(true)
  //         dispatch(setRefreshList(res.data.listings))
  //       })
  //       .catch((err) => {
  //       })
  //   }
  // }, [typedSearch])
  const getListingMethod = () => {
    setLoading(true)
    getListings({ size: 20 })
      .then((res) => {
        // setList(res.data.listings)
        setListingData(res.data)
        dispatch(setRefreshList(res.data?.listings))
        setLoading(false)
      })
      .catch((err) => {
        toast.error(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    activeBuzzFilters.forEach((item: { label: string }) =>
      AllBuzz.push(item.label),
    )
    const Buzz = ['High buzz', 'Warm buzz', 'Medium buzz', 'Low buzz']
    const data = Buzz.filter(function (v: string) {
      return !AllBuzz.includes(v)
    })
    dispatch(setFilteredBuzz(data))
    const revData = data.reverse()
  }, [activeBuzzFilters])

  const { refreshList } = useSelector(
    (state: TRootState) => state.RefreshListingReducer,
  )
  useEffect(() => {
    setListings(refreshList)
  }, [refreshList])
  const [listings, setListings] = useState(refreshList)
  const initialListing = refreshList
  const [radiusListing, setradiusListing] = useState<number[]>([])

  const [selectedListings, setSelectedListings] = useState<APIFeatureType[]>([])
  const [result, setResult] = useState(false)
  const [activeToggleFeature, setActiveToggleFeature] =
    useState<string>('buzz_filter')
  useEffect(() => {
    openAudience()
    dispatch(setSelectedListing(selectedListings))

    if (selectedListings.length < 1 || searchClicked) {
      setActiveToggleFeature('buzz_filter')
    }
  }, [selectedListings])

  type TListing = {
    unique_observations_total: number
    unique_visitor_count: number
    selected: boolean
  }

  const [totalProspects, settotalProspects] = useState(0)
  const totalN = (listing: TListing, total: number) => {
    if (listing?.selected) {
      if (listing?.unique_observations_total > 0) {
        return listing?.unique_observations_total + total
      }

      return listing?.unique_visitor_count + total
    }
    return total + 0
  }
  useEffect(() => {
    const totalProspectsCount = listingData.listings.reduce(
      (total: number, listing: TListing) => totalN(listing, total),
      0,
    )
    settotalProspects(totalProspectsCount)
  }, [listingData, listings])

  const openAudience = () => {
    if (selectedListings?.length >= 1) {
      setResult(true)
    } else {
      setResult(false)
    }
  }
  const getIconMap = () => {
    setLoading(true)
    setTimeout(() => {
      setsearchClicked(true)
      setListings([...listingData.listings])
      dispatch(setSidebarLens(false))
      setToggle(true)
      setLoading(false)
    }, 1000)
  }
  useEffect(() => {
    if (searchClicked) {
      setsearchClicked(false)

      dispatch(setRefreshList(listingData.listings))
    }
  }, [selectedListings])

  const checkSwitchToggle = (value: Boolean) => {
    setInactivelisting(value)
  }

  return (
    <div className="DigitalFarming">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Humble Brag"
              // subHeading="Find residences surrounding your new listings or recently sold prosperities."
              subHeading="Identify targets that reside around a specific area."
              toggle
              isToggle={(val: Boolean) => checkSwitchToggle(val)}
            />
          }
          footer={
            <>
              <div
              //  style={{ padding: '0 3rem', margin: '0 0 1rem 0' }}
              >
                <Button
                  UNSAFE_style={{
                    color: '#007B94',
                    border: '2px solid #007B94',
                  }}
                  UNSAFE_className="MyListingsLens__FooterBtn DigitalFarming__listings-button"
                  kind="default"
                  size="l"
                  width="100%"
                  disabled={!(selectedListings.length > 0)}
                  onClick={() => {
                    getIconMap()
                  }}
                >
                  Search Additional Targets
                </Button>
              </div>
              <div
              //  style={{ padding: '0 3rem', margin: '0.8rem 0' }}
              >
                <Button
                  UNSAFE_style={{
                    color: '#007B94',
                    border: '2px solid #007B94',
                  }}
                  UNSAFE_className="MyListingsLens__FooterBtn "
                  kind="default"
                  size="l"
                  width="100%"
                  onClick={() => {
                    // getIconMap()
                    dispatch(setFilteredBuzz([]))
                    setActiveBuzzFilters(buzzFilters)
                    getListingMethod()
                    dispatch(setTypeSearch(''))
                    dispatch(setSelectedPage(1))
                    dispatch(
                      setSelectedSort({ label: 'Zip Code', value: 'zip' }),
                    )
                    setSelectedListings([])
                  }}
                >
                  Refresh
                </Button>
              </div>
            </>
          }
        >
          {listingData.listings.length > 0 ? (
            <>
              <div
                className={
                  isCenter
                    ? 'MyListingsLens__sidebar-content '
                    : 'MyListingsLens__sidebar-content MyListingsLens__Height'
                }
              >
                <ListingsList
                  type="Digital Farming"
                  listings={listings}
                  selectedListings={selectedListings}
                  onSelectedListingsChange={(newSelectedListings) => {
                    if (listingData.listings.length > 0) {
                      const tempList = [...listingData.listings]

                      const newA = tempList.map((v: APIFeatureType) =>
                        newSelectedListings.some(
                          (sublist) => sublist.listingid === v.listingid,
                        )
                          ? {
                              ...v,
                              selected: true,
                              unique_observations_total: 0,
                            }
                          : {
                              ...v,
                              selected: false,
                              unique_observations_total: 0,
                            },
                      )
                      setListingData({ ...listingData, listings: [...newA] })

                      setSelectedListings(newSelectedListings)
                    }
                  }}
                  listingData={listingData}
                />
              </div>
            </>
          ) : (
            ''
          )}
          {listings?.length < 1 && isLoading ? (
            <div className="MyListingsLens__sidebar-content MyListingsLens__NoList">
              <p style={{ color: '#BD0000' }}>
                You don&apos;t have any listings. Your listings are pulled from
                the MLS every morning. Please check again or contact support if
                the issue persists.
              </p>
            </div>
          ) : (
            ''
          )}
        </MaxHeightContainer>
      </LensSidebar>

      <div className="MyListingsLens__map Digital_Farming__map">
        {result ? (
          <div
            className={
              isCenter
                ? 'MyListingsLens__audience'
                : 'MyListingsLens__audienceCenter'
            }
          >
            <div className="MyListingsLens__border_radius">
              <LensSidebarFooter>
                <AudienceResultsCard>
                  <div className="MyListingsLens__score">
                    <div className="MyListingsLens__scoreLeft">
                      <p
                        style={{
                          fontSize: '22px',
                          fontWeight: '600',
                          color: '#FFAB03',
                        }}
                      >
                        {totalProspects?.toLocaleString()}
                      </p>
                    </div>
                    <div className="MyListingsLens__scoreRight">
                      {/* <b>Results Prospects</b> */}
                      <b style={{ fontSize: '22px', fontWeight: 'normal' }}>
                        Prospects observed
                      </b>
                      <br />
                      {/* found around{' '} */}
                      {/* <b>
                        {selectedListings.length}
                        {selectedListings.length > 1
                          ? ' locations'
                          : ' location'}
                      </b> */}
                      {/* have visited <b>{selectedListings?.length}</b> of my{' '}
                      <b> listings</b> */}
                    </div>
                  </div>
                  <Spacer />
                  <SaveAudienceWizard
                    // type="my listings"
                    type="Digital Farming"
                    onSaveAndClose={(audienceDetails) =>
                      browserLog.info(audienceDetails)
                    }
                    totalProspects={totalProspects}
                    listing={selectedListings}
                    radiusListing={radiusListing}
                  />
                </AudienceResultsCard>
              </LensSidebarFooter>
            </div>
          </div>
        ) : (
          ''
        )}

        {toggle && (
          <>
            <div
              className="ExpandYourMarketing__rightTop"
              style={{ display: 'flex', width: 'auto' }}
            >
              <Button
                UNSAFE_className="ExpandYourMarketing__rightTopButton"
                UNSAFE_style={{
                  background:
                    activeToggleFeature === 'buzz_filter' ? '#FFB708' : '',
                  color: activeToggleFeature === 'buzz_filter' ? 'white' : '',
                }}
                onClick={() => {
                  setActiveToggleFeature('buzz_filter')
                }}
              >
                Buzz Filter
              </Button>
              {selectedListings.length > 0 && searchClicked && (
                <Button
                  UNSAFE_className="ExpandYourMarketing__rightTopButton"
                  UNSAFE_style={{
                    background:
                      activeToggleFeature === 'radius_filter' ? '#FFB708' : '',
                    color:
                      activeToggleFeature === 'radius_filter' ? 'white' : '',
                  }}
                  onClick={() => {
                    setActiveToggleFeature('radius_filter')
                  }}
                >
                  Radius Filter
                </Button>
              )}
              {/* <Button
                UNSAFE_className="ExpandYourMarketing__rightTopButton"
                UNSAFE_style={{
                  background:
                    activeToggleFeature === 'density_filter' ? '#FFB708' : '',
                  color:
                    activeToggleFeature === 'density_filter' ? 'white' : '',
                }}
                onClick={() => {
                  setActiveToggleFeature('density_filter')
                }}
              >
                Density
              </Button> */}
            </div>

            <div className="MyListingsLens__filter" style={{ display: 'flex' }}>
              {activeToggleFeature === 'radius_filter' &&
                selectedListings.length > 0 &&
                searchClicked && (
                  <>
                    <div className="setMeter_container pd-3">
                      <div className="radiusFilter">
                        <button
                          className={`radiusFilter__item ${
                            activeRadiusTab === 'default' &&
                            'radiusFilter__active'
                          }`}
                          type="button"
                          onClick={() => setActiveRadiusTab('default')}
                        >
                          Default Radius
                        </button>
                        <button
                          className={`radiusFilter__item ${
                            activeRadiusTab === 'individual ' &&
                            'radiusFilter__active'
                          }`}
                          type="button"
                          onClick={() => setActiveRadiusTab('individual ')}
                        >
                          Individual Radius
                        </button>
                      </div>
                      {activeRadiusTab === 'default' ? (
                        <div>
                          <p style={{ marginTop: '0px', marginBottom: '10px' }}>
                            Adjust Default Radius
                          </p>

                          <Select
                            Height="39px"
                            placeholder="Select radius"
                            childOnOptions
                            options={[
                              { label: '2,000 meters' },
                              { label: '1,500 meters' },
                              { label: '1,000 meters' },
                            ]}
                            noCross
                            // isError={isSubmit && mls.label.length < 1}
                            // placeholder={user.personalInfo.mls?user.personalInfo.mls.value:'__Select__'}
                            selectedOption={radiusSize}
                            onChange={(value) => {
                              const tempList = [...listingData.listings]
                              const selectedListingsTemp = [...selectedListings]
                              selectedListingsTemp.map(
                                (item: APIFeatureType) => (item.radius = value),
                              )
                              setradiusSize(value)
                              const newA = tempList.map((v: object) => ({
                                ...v,
                                radius: value,
                              }))
                              setListingData({ ...listingData, listings: newA })

                              if (searchClicked) {
                                setListings([...newA])
                              }
                            }}
                            maxHeight={300}
                            radius="5px"
                            size="14px"
                          />
                        </div>
                      ) : (
                        <div>
                          {selectedListings.map((item: APIFeatureType) => {
                            return (
                              <div className="individualRadius">
                                <p className="individualRadius__name">
                                  {item.street}
                                </p>
                                {/* <select
                                style={{ height: '25px' }}
                                onChange={(e) => {
                                  const value = { label: e.target.value }
                                  // setradiusSize(value)
                                  const selectedListingsTemp = [
                                    ...selectedListings,
                                  ]
                                  selectedListingsTemp.map(
                                    (selectedItem: APIFeatureType) =>
                                      item.listingid ===
                                        selectedItem.listingid &&
                                      (selectedItem.radius = value),
                                  )
                                  const newA = tempList.find(
                                    (v: { listingid: number }) =>
                                      item.listingid === v.listingid,
                                  )
                                  // newA = {
                                  //   radius: value,
                                  // },
                                  newA.radius = value
                                  setListingData({
                                    ...listingData,
                                    listings: [...listingData.listings],
                                  })

                                  if (searchClicked) {
                                    // setsearchClicked(false)
                                    setListings([...tempList])
                                  }
                                }}
                                value={item?.radius?.label}
                                // value="1,000 meters"
                              >
                                <option value="1,000 meters">1000</option>
                                <option value="1,500 meters">1500</option>
                                <option value="2,000 meters"> 2000</option>
                              </select> */}

                                <Select
                                  Height="33px"
                                  width="141px"
                                  placeholder="Select radius"
                                  childOnOptions
                                  options={[
                                    { label: '2,000 meters' },
                                    { label: '1,500 meters' },
                                    { label: '1,000 meters' },
                                  ]}
                                  noCross
                                  selectedOption={item?.radius}
                                  onChange={(value) => {
                                    const tempList = [...listingData.listings]
                                    const selectedListingsTemp = [
                                      ...selectedListings,
                                    ]
                                    selectedListingsTemp.map(
                                      (selectedItem: APIFeatureType) =>
                                        item.listingid ===
                                          selectedItem.listingid &&
                                        (selectedItem.radius = value),
                                    )
                                    const newA = tempList.find(
                                      (v: { listingid: number }) =>
                                        item.listingid === v.listingid,
                                    )

                                    newA.radius = value
                                    setListingData({
                                      ...listingData,
                                      listings: [...listingData.listings],
                                    })

                                    if (searchClicked) {
                                      setListings([...tempList])
                                    }
                                  }}
                                  maxHeight={300}
                                  isCenter
                                  radius="5px"
                                  size="14px"
                                />
                              </div>
                            )
                          })}
                          {selectedListings.length < 1 && (
                            <p style={{ textAlign: 'center' }}>
                              No Listings Selected
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

              {activeToggleFeature === 'buzz_filter' && (
                <LensFilterContainer>
                  <BuzzScoreFilters
                    filters={buzzFilters}
                    activeFilters={activeBuzzFilters}
                    onChange={setActiveBuzzFilters}
                  />
                  {/* <div className="buzzScoreImg__container">
                    <BuzzScore />
                  </div> */}
                </LensFilterContainer>
              )}
              {activeToggleFeature === 'density_filter' && <ProspectDensity />}
            </div>
          </>
        )}

        {/* buzz score toggle */}
        <div
          style={{
            position: 'absolute',
            right: '50px',
            zIndex: 999,
            bottom: '44px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ fontSize: '10px', marginRight: '5px' }}>Show Filters</p>
          <FontAwesomeIcon
            style={{ marginRight: '1.5rem', fontSize: '32px' }}
            size="1x"
            color={toggle ? '#FFB708' : '#FF0000'}
            icon={toggle ? faToggleOn : faToggleOff}
            onClick={() => setToggle(!toggle)}
          />
        </div>
        {/* buzz score toggle */}

        {/* <div className={`${toggle ? '' : 'MyListingsLens__hide'}`}>
          <div className="MyListingsLens__rightTop">
            <img
              src={mapMarkerImg}
              alt=""
              style={{ margin: '5px 14px -5px -15px', height: '26px' }}
            />
          </div>
        </div> */}

        <div
          // className="SellYourListingFirst__map"
          style={{ height: '100%' }}
        >
          {loading && <LinearLoader />}
          <Map
            features={listings}
            type="Digital Farming"
            locations={[]}
            setListingData={setListingData}
            listingData={listingData}
            setListings={setListings}
            searchClicked={searchClicked}
            setradiusListing={setradiusListing}
            marker={(f) => (
              <MapMarker
                searchClicked={searchClicked}
                color={getIconBuzzColor(f)}
                isSelected={useSelectedListingMarker}
                f={f}
              />
            )}
            tooltip={(f) => <MapToolTip feature={f} />}
          />
        </div>
      </div>
    </div>
  )
}
