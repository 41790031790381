/* eslint-disable */
/* @ts-ignore */
import {
  CardComponent,
  CardCVV,
  CardExpiry,
  CardNumber,
} from '@chargebee/chargebee-js-react-wrapper'
import { Button, FormItem, Text } from '@eltoro-ui/components'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PersonalInput } from 'Components/PersonalInfoForm/component/PersonalInput'
import { Spinner } from 'Components/Spinner/Spinner'
import * as React from 'react'
import { connect } from 'react-redux'
import { addCard, editCard, getPaymentCards } from 'Requests'
import toast from 'react-hot-toast'
import './AddCard.scss'
// import { ChargebeeSuccessModal } from './ChargebeeSuccessModal'
import { ErrorMsg, Tooltip } from 'Components'
import { chargebeeSubscription } from 'Requests'
import { LableInputField } from 'Components/LableInputField'
import Warning from '../../../../../../Images/warning.png'

//@ts-ignore
window?.Chargebee?.init({
  site: process.env.REACT_APP_CHARGEBEE_SITE,
  publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHKEY,
})

// export const successModal = () => {
//   return <ChargebeeSuccessModal />
// }

class AddCard extends React.Component<any, any> {
  // constructor(props) {
  //   super(props)
  //   this.state = { data: false }
  // }

  cardRef = React.createRef<any>()
  state = {
    successfullPayment: false,
    firstName:
      this.props.title === 'Edit Card' ? this?.props.cardData?.first_name : '',
    lastName:
      this.props.title === 'Edit Card' ? this?.props?.cardData?.last_name : '',

    isOnChange: false,
    // expiration: '',
    expiration: `${
      this?.props?.cardData?.expiry_month?.toString()?.length === 1
        ? `0${this?.props?.cardData?.expiry_month}`
        : this?.props?.cardData?.expiry_month
    }/${this?.props?.cardData?.expiry_year?.toString()?.slice(2, 5)}`,
    isSubmit: false,
    isLoading: false,
    isComplete: false,
    field: '',
    number: false,
    monthExpiry: '',
    expiry: false,
    detailsMessage: '',
    statusCode: '',
    statusCodeCard: '',
    statusCodeExpiry: '',
    statusCodeCvv: '',
    cvv: false,
    invalidCard: '',
    invalidExpiry: '',
    invalidCvv: '',
    ModalOpen: false,
    errors: {},
    allErr: false,
    errorMessage: '',
    // CSS class names for field's status
    classes: {
      focus: 'focus',
      invalid: 'invalid',
      empty: 'empty',
      complete: 'complete',
    },
    // Google Fonts and other whitelisted fonts
    fonts: ['https://fonts.googleapis.com/css?family=Open+Sans'],
    // Style customizations
    styles: {
      base: {
        color: '#6d6d6d',
        fontWeight: 400,
        paddingTop: '4px',

        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        lineHeight: '17px',

        // height: '1.4rem',
        ':focus': {
          // color: '#6d6d6d',
        },

        '::placeholder': {
          color: '#6D6D6D',
        },

        ':focus::placeholder': {
          color: '#6D6D6D',
          border: '1px solid #bd0000',
        },
      },
      invalid: {
        border: '1px solid #bd0000 !important',
        color: '#BD0000',
        ':focus': {
          color: '#BD0000',
        },
        '::placeholder': {
          color: '#BD0000',
        },
      },
    },
    placeholder: {
      number: '4111 1111 1111 1111',
      expiry: 'MM / YY',
      cvv: 'CVC',
    },
  }
  cardExpiryYear = new Date().getFullYear().toString().slice(-2)
  isValidYear = parseInt(this.state.expiration.toString()?.slice(0, 2)) > 22

  formatString = (event: any) => {
    var inputChar = String.fromCharCode(event.keyCode)
    var code = event.keyCode
    var allowedKeys = [8]
    if (allowedKeys.indexOf(code) !== -1) {
      return
    }
    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/', // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/', // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        '$1/$2', // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0', // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        '', // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/', // Prevent entering more than 1 `/`
      )
  }

  // expiry_data = this?.props?.cardData?.expiry_year.substring(2, 4)
  expiry_data = `${this?.props?.cardData?.expiry_month} ${this?.props?.cardData?.expiry_year}`

  // @ts-ignore
  onSubmit = (e: React.FormEvent) => {
    this.setState({ isSubmit: true, isLoading: true })

    if (e) e.preventDefault()

    // Call tokenize method on card element
    if (this.props.title === 'Edit Card') {
      if (
        this.state.firstName &&
        this.state.lastName &&
        this.state.expiration
      ) {
        this.props.setLoadings(true)
        // remvove slesh
        const output = this.state.expiration.replace(/\//g, '').split('')
        var ouput = output.reverse()
        const year = ouput.splice(0, 2).reverse().join('')
        const month = output.reverse().join('')
        // const month =
        editCard({
          card_id: this.props.editId,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          expiry_year: year,
          expiry_month: month,
        }).then((res: any) => {
          toast.success('Card updated successfully!!')
          this.props.setLoadings(false)
          this.props.setToggle((x: boolean) => !x)
          this.props.setIsAddCard(false)
        })

        // var data =[22,0,9];
      }
    } else {
      if (this.cardRef && this.state.firstName && this.state.lastName) {
        this.cardRef.current
          .tokenize({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
          })
          .then((data: any) => {
            // this.props.setLoadings(true)

            // @ts-ignore
            const Body: any = { token_id: data.token }

            addCard(Body).then((resp: any) => {
              this.props.getCardDetail()
              this.setState({ detailsMessage: resp?.detail?.error_msg })
              this.setState({ statusCode: resp?.detail?.http_status_code })
              if (this.state.detailsMessage?.includes('(3001)')) {
                toast.error('Insufficient funds!!')
                this.props.setIsAddCard(true)
                this.setState({ isSubmit: true, isLoading: false })
              } else if (this.state.statusCode === '400') {
                toast.error('Operation failed!!')
                this.props.setIsAddCard(true)
                this.setState({ isSubmit: true, isLoading: false })
              } else if (this.state.detailsMessage?.includes('(3002)')) {
                toast.error('Credit limit exceeded!!')
                this.props.setIsAddCard(true)
                this.setState({ isSubmit: true, isLoading: false })
              } else if (
                this.state.detailsMessage?.includes('Operation failed!!')
              ) {
                toast.error('Payment method verification failed!!')
                this.props.setIsAddCard(true)
                this.setState({ isSubmit: true, isLoading: false })
              } else {
                toast.success('Card added successfully!!')
                this.props.setIsAddCard(false)
                this.props.setLoadings(false)
                this.props.setIsSelectCard(true)
                this.props.setToggle((x: boolean) => !x)

                this.setState({ successfullPayment: true, isLoading: false })
              }
            })
            if (this.state.successfullPayment) {
              this.props.setIsSelectCard(true)
            }
            // .catch((err: any) => {
            //   // toast.success('invalid')
            //   this.props.setIsAddCard(true)
            //   this.setState({ isLoading: false, errors: err })
            // })
            // this.props.setIsSelectCard(true)
            // const body = {
            //   subscription_id:
            //     // @ts-ignore
            //     this?.props?.chargebeeSelectedPackage?.subscription_id,
            //   /* @ts-ignore */
            //   metadata: this.props.chargebeeSelectedPackage.metadata,
            //   token_id: data.token,
            //   // @ts-ignore
            //   user_id: JSON.stringify(this.props.user.id),
            // }

            // chargebeeSubscription(body).then((resp: any) => {
            //   // @ts-ignore
            //   this.props.close()

            //   this.setState({ successfullPayment: true, isLoading: false })
            // })
          })
          .catch((err: any) => {
            // this.setState({ isLoading: false, errors: err })
          })
      }
    }
  }

  onChange = (status: any) => {
    this.setState({
      isSubmit: false,
      statusCodeCard: typeof status.error === 'object' ? true : false,
      number: status.complete && status.field === 'number' ? true : false,
      invalidCard: status?.error?.message,
    })
  }
  onChangeExpiry = (status: any) => {
    this.setState({
      isSubmit: false,
      statusCodeExpiry: typeof status.error === 'object' ? true : false,
      invalidExpiry: status?.error?.message,

      expiry: status.complete && status.field === 'expiry' ? true : false,
    })
    this.state.invalidExpiry === 'Expired card'
      ? this.setState({ allErr: true })
      : false
  }
  onChangeCvv = (status: any) => {
    this.setState({
      isSubmit: false,
      statusCodeCvv: typeof status.error === 'object' ? true : false,
      invalidCvv: status?.error?.message,

      cvv: status.complete && status.field === 'cvv' ? true : false,
    })
  }

  onReady = (el: any) => {
    el.focus()
  }

  ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="Please fill the input"
          topAbsolute="-110px"
          rightAbsolute="-43px"
          padding="5px 5px"
        />
      </div>
    )
  }

  render() {
    const { fonts, styles, classes, placeholder } = this.state
    // @ts-ignore
    // Using individual fields mode
    return (
      <div style={{ height: 'auto', width: '80%' }}>
        <div className="cell example example3" id="example-3">
          <Text on="white" UNSAFE_className="addCardHeading">
            {/* @ts-ignore */}
            {this.props.title}
          </Text>

          <form>
            <div
              style={{
                zIndex: '1',
                position: 'relative',
                background: 'white',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div className="ex1-field" style={{ width: '48%' }}>
                  <LableInputField
                    style={{ paddingLeft: '22px', margin: '0rem  0' }}
                    lableStyle={{ left: '14px' }}
                    title="First Name"
                    placeholder="First Name"
                    requireTage="*"
                    type="text"
                    isError={this.state.isSubmit && !this.state.firstName}
                    onChange={(e) => {
                      this.setState({
                        firstName: e.target.value,
                        isSubmit: false,
                      })
                    }}
                    value={this.state.firstName}
                    iconRight={
                      <img
                        className="start_icon"
                        style={{ marginTop: '7px', width: '24px' }}
                        src={
                          this.state.isSubmit && !this.state.firstName
                            ? Warning
                            : ''
                        }
                        // alt="icon"
                      />
                    }
                  />
                  {this.state.isSubmit && !this.state.firstName ? (
                    <this.ErrorToolbar />
                  ) : (
                    ''
                  )}
                </div>
                <div className="ex1-field" style={{ width: '48%' }}>
                  <LableInputField
                    style={{ paddingLeft: '22px', margin: '0rem  0' }}
                    lableStyle={{ left: '14px' }}
                    title="Last Name"
                    placeholder="Last Name"
                    requireTage="*"
                    type="text"
                    isError={this.state.isSubmit && !this.state.lastName}
                    onChange={(e) => {
                      this.setState({
                        lastName: e.target.value,
                        isSubmit: false,
                      })
                    }}
                    value={this.state.lastName}
                    iconRight={
                      <img
                        className="start_icon"
                        style={{ marginTop: '7px', width: '24px' }}
                        src={
                          this.state.isSubmit && !this.state.lastName
                            ? Warning
                            : ''
                        }
                        // alt="icon"
                      />
                    }
                  />
                  {this.state.isSubmit && !this.state.lastName ? (
                    <this.ErrorToolbar />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {this.props.title === 'Edit Card' ? (
                <>
                  <LableInputField
                    style={{ paddingLeft: '22px', margin: '0rem  0' }}
                    lableStyle={{ left: '14px' }}
                    title="Card Number"
                    placeholder="Card Number"
                    disabled={true}
                    requireTage="*"
                    type="text"
                    value={this.props.cardData.masked_number}
                    // iconRight={
                    //   <img
                    //     className="start_icon"
                    //     style={{ marginTop: '7px', width: '24px' }}
                    //     src={
                    //       this.state.isSubmit && !this.state.firstName
                    //         ? Warning
                    //         : ''
                    //     }
                    //     // alt="icon"
                    //   />
                    // }
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginTop: '2rem',
                    }}
                  >
                    <div className="ex1-field" style={{ width: '48%' }}>
                      <LableInputField
                        maxlength={5}
                        id="exp"
                        onKeyUp={this.formatString}
                        // @ts-ignore
                        style={{ paddingLeft: '22px', margin: '0rem  0' }}
                        lableStyle={{ left: '14px' }}
                        title="Expiration"
                        placeholder="MM/YY"
                        requireTage="*"
                        type="text"
                        // @ts-ignore
                        isError={
                          // @ts-ignore
                          parseInt(
                            this.state.expiration.toString()?.slice(3, 5),
                          ) < this.cardExpiryYear &&
                          this.state.expiration.length > 3 &&
                          parseInt(
                            this.state.expiration.toString()?.slice(0, 2),
                          ) >= new Date().getMonth()
                        }
                        onChange={(e) => {
                          this.setState({
                            isOnChange: true,

                            expiration: e.target.value,

                            isSubmit: false,
                          })
                        }}
                        // defaultValue={this.ex}
                        value={this.state.expiration}
                        iconRight={
                          <img
                            className="start_icon"
                            style={{ marginTop: '7px', width: '24px' }}
                            src={
                              this.state.isSubmit && !this.state.expiration
                                ? Warning
                                : ''
                            }
                            // alt="icon"
                          />
                        }
                      />
                      {/* @ts-ignore */}
                      {parseInt(this.state.expiration.toString()?.slice(3, 5)) <
                        this.cardExpiryYear &&
                      parseInt(this.state.expiration.toString()?.slice(0, 2)) >=
                        new Date().getMonth() ? (
                        <ErrorMsg
                          title={'Expired card'}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      ) : (
                        ''
                      )}
                      {this.state.isSubmit && !this.state.expiration ? (
                        <this.ErrorToolbar />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="ex1-field" style={{ width: '48%' }}>
                      <LableInputField
                        style={{ paddingLeft: '22px', margin: '0rem  0' }}
                        lableStyle={{ left: '14px' }}
                        title="CVC"
                        requireTage="*"
                        placeholder="CVC"
                        disabled={true}
                        type="text"
                        value="***"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="fieldset">
                  <CardComponent
                    className="field"
                    fonts={fonts}
                    classes={classes}
                    styles={styles}
                    ref={this.cardRef}
                    onReady={this.onReady}
                    placeholder={placeholder}
                  >
                    <div className="ex1-field">
                      {/* Card number component */}
                      <CardNumber
                        onChange={this.onChange}
                        onReady={this.onReady}
                        className="ex1-input"
                      />
                      {!this.state.invalidCard ? (
                        ''
                      ) : (
                        <ErrorMsg
                          title={this.state.invalidCard}
                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        />
                      )}
                      <label className="ex1-label">
                        Card Number<span style={{ color: '#BD0000' }}>*</span>
                      </label>
                      {/* <i className="ex1-bar"></i> */}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div className="ex1-field" style={{ width: '48%' }}>
                        {/* Card expiry component */}
                        <CardExpiry
                          className="ex1-input"
                          onChange={this.onChangeExpiry}
                        />
                        {!this.state.invalidExpiry ? (
                          ''
                        ) : (
                          <ErrorMsg
                            title={this.state.invalidExpiry}
                            icon={
                              <FontAwesomeIcon icon={faExclamationCircle} />
                            }
                          />
                        )}
                        <label className="ex1-label">
                          Expiration<span style={{ color: '#BD0000' }}>*</span>
                        </label>
                      </div>

                      <div className="ex1-field" style={{ width: '48%' }}>
                        {/* Card cvv component */}
                        <CardCVV
                          onChange={this.onChangeCvv}
                          className="ex1-input"
                        />
                        {!this.state.invalidCvv ? (
                          ''
                        ) : (
                          <ErrorMsg
                            title={this.state.invalidCvv}
                            icon={
                              <FontAwesomeIcon icon={faExclamationCircle} />
                            }
                          />
                        )}
                        <label className="ex1-label">
                          CVC<span style={{ color: '#BD0000' }}>*</span>
                        </label>
                      </div>
                    </div>
                  </CardComponent>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <Button
                  type="button"
                  kind="primary"
                  UNSAFE_style={{
                    padding: '20px 20px',
                    marginTop: '1.5rem',
                    fontWeight: '700',
                    fontSize: '18px',
                    width: '200px',
                    height: '56px',
                  }}
                  loading={this.state.isLoading}
                  disabled={
                    !this.state.firstName ||
                    // @ts-ignore
                    parseInt(this.state.expiration.toString()?.slice(3, 5)) <
                      this.cardExpiryYear ||
                    !this.state.lastName ||
                    this.state.statusCodeCard ||
                    this.state.statusCodeCvv ||
                    this.state.statusCodeExpiry ||
                    (!this.state.number && this.props.title !== 'Edit Card') ||
                    (!this.state.expiry && this.props.title !== 'Edit Card') ||
                    (!this.state.cvv && this.props.title !== 'Edit Card') ||
                    (this.state.expiration.length < 4 &&
                      this.props.title === 'Edit Card') ||
                    this.state.isLoading
                  }
                  // @ts-ignore
                  onClick={this.onSubmit}
                >
                  {this.props.title === 'Edit Card' ? ' Update' : 'Add'}
                </Button>
              </div>
            </div>
          </form>
        </div>

        {this.state.successfullPayment && (
          // @ts-ignore
          <ChargebeeSuccessModal />
        )}
        {/* End Successfull payment */}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  user: state.userReducer.userData,
  chargebeeSelectedPackage: state?.chargebeePackageReducer?.chargebeePackage,
})
export default connect(mapStateToProps, null)(AddCard)
