import {
  APIFeatureType,
  AudienceType,
  CampaignType,
  CreativeType,
  FilterSetType,
  NotificationType,
  PaymentProfileType,
  UserType,
} from 'types'

import notifications from './Notifications.json'
import users from './Users.json'
// import mapFeatures from './MapFeatures.json'
import filters from './Filters.json'
// import listings from './Listings.json'
import paymentProfiles from './PaymentProfiles.json'
import mapListings from './MapListings.json'
import campaigns from './Campaigns.json'
import audiences from './Audiences.json'
import creatives from './Creatives.json'
// import APIFeatures from './APIFeatures.json'
import APIFeatures from './APIFeatures.json'

export const fixtureUsers = users as UserType[]
export const fixtureNotifications = notifications as NotificationType[]
export const fixtureCampaigns = campaigns as CampaignType[]
export const fixtureCreatives = creatives as CreativeType[]
export const fixturePaymentProfiles = paymentProfiles as PaymentProfileType[]
export const fixtureAudiences = audiences as AudienceType[]
export const fixtureAPIFeatures = APIFeatures as APIFeatureType[]
export const fixtureFilters = filters as FilterSetType

export default {
  notifications,
  users,
  // mapFeatures,
  filters,
  // listings,
  mapListings,
  paymentProfiles,
  audiences,
  campaigns,
  creatives,
}
