import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './ContactInformation.scss'

export const ContactInformation = ({
  icon,
  title,
  content,
  UNSAFE_CLASSNAME,
}: {
  icon?: any
  title: string
  content?: string | null
  UNSAFE_CLASSNAME?: string
}) => {
  return (
    <div className={UNSAFE_CLASSNAME}>
      {/* <FontAwesomeIcon icon={icon} className="ContactInformation__icon" /> */}
      {icon}
      <div className="ContactInformation__title">
        <p>{title}</p>
        <h4>{content !== '' && content ? content : '-'}</h4>
      </div>
    </div>
  )
}
