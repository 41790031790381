import React from 'react'
import { Rect } from 'react-konva'
import { useRecoilValue } from 'recoil'
import { addressSizePixels } from '../../../Helpers'
import { directMailSizeAtom } from '../../../State'

export const DirectMailAddress = () => {
  const directMailSize = useRecoilValue(directMailSizeAtom)
  if (!directMailSize) return null
  const { width, height, x, y } = addressSizePixels(directMailSize)
  return (
    <Rect
      width={width}
      height={height}
      x={x}
      y={y}
      fill="white"
      // temp stroke
      stroke="black"
    />
  )
}
