/* eslint-disable */
import { Button, FlexBox, Modal, Spacer, Text } from '@eltoro-ui/components'
import { faWalking } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import { CreditModal } from 'Components/CreditModal'
import { SpinnerLoader } from 'Components/SpinnerLoader'
import AddCard from 'Pages/MyProfile/components/BillingSection/components/AddCard/AddCard'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getPaymentCards, postCampaignBudget } from 'Requests'
import { TRootState } from 'types'

import plusIcon from '../../assets/Images/addcard.png'
import cross from '../../assets/Images/cross.png'
import banner from '../../assets/Images/Group870623.png'
import succesTick from '../../assets/Images/Group87260.png'
import Lefticon from '../../assets/Images/honeycombleft.png'
import Righticon from '../../assets/Images/honeycombright.png'
import leftGroup from '../../assets/Images/leftgroup.svg'
import rightGroup from '../../assets/Images/rightgroup.svg'
import video from '../../assets/Images/Subtract22.png'
import visacard from '../../assets/Images/visacard.png'

import addcardbill from '../../assets/Images/addcardbill.png'
import americanexpress from '../../assets/Images/americanexpress.png'
import dinearclub from '../../assets/Images/dinearclub.png'
import discover from '../../assets/Images/discover.png'
import Jcb from '../../assets/Images/Jcb.png'
import mastercard from '../../assets/Images/mastercard.png'

import { RadioButtonCustom } from '@eltoro-ui/components/RadioButtonCustom'
import { SummaryEditButton, SummaryTotal } from '.'
import './SummaryCart.scss'

export const SummaryCart: React.FC<{
  campaign: any
  checkout?: any
  daysRemaining?: any
}> = ({ campaign, checkout, daysRemaining }) => {
  const [creditModalOpen, setCreditModalOpen] = useState(false)
  const { userData } = useSelector((state: TRootState) => state.userReducer)
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const [isCreditBuy, setIsCreditBuy] = useState(false)
  const [checkoutLoaded, isCheckoutLoaded] = useState(false)
  const [isSelectCard, setIsSelectCard] = useState(false)
  const [isAddCard, setIsAddCard] = useState(false)
  const [loading, setLoading] = useState(true)
  const [seletedCardId, setSeletedCardId] = useState('')
  const [card, setCard] = useState<any>([])
  // const reduceProspects = (p: { name: string; prospects: number }[]) => {
  //   return p?.reduce((acc, cur) => acc + cur.prospects, 0)
  // }

  const getImpressions = (): {
    banner_imp: number
    video_imp: number
    impressions: number
  } => {
    const banner_imp = campaign?.banner_count
      ? 4 * checkout?.days * campaign?.prospects_counts
      : 0
    const video_imp = campaign?.video_count
      ? 3 * checkout?.days * campaign?.prospects_counts
      : 0
    const impressions =
      banner_imp && video_imp
        ? banner_imp + video_imp
        : banner_imp
        ? banner_imp
        : video_imp
    return { impressions, banner_imp, video_imp }

    // const impressions = {
    //   banner_imp: campaign?.banner_count
    //     ? 3 * checkout?.days * campaign?.prospects_counts
    //     : 0,
    //   video_imp: campaign?.video_count
    //     ? 4 * checkout?.days * campaign?.prospects_counts
    //     : 0,
    //   impressions:
    //     campaign?.video_count === 0
    //       ? 3 * checkout?.days * campaign?.prospects_counts
    //       : 4 * checkout?.days * campaign?.prospects_counts +
    //         3 * checkout?.days * campaign?.prospects_counts,
    // }
    // return impressions
  }
  // checkout?.frequency * checkout?.days * campaign?.prospects_counts
  // const [sum, setSum] = useState(0)
  const [needDays, setNeedDays] = useState<any>()
  const [discountedPrice, setDiscountedPrice] = useState<number | undefined>()
  const getFreq = () => {
    if (campaign?.video_count && campaign?.banner_count > 0) {
      return 4
    }
    if (campaign?.video_count > 0) {
      return 3
    }
    return 4
  }
  const brand = (item: string) => {
    if (item === 'visa') {
      return visacard
    }
    if (item === 'mastercard') {
      return mastercard
    }
    if (item === 'discover') {
      return discover
    }
    if (item === 'american_express') {
      return americanexpress
    }
    if (item === 'jcb') {
      return Jcb
    }
    if (item === 'diners_club') {
      return dinearclub
    }
  }
  const getCardDetail = () => {
    getPaymentCards().then((res) => {
      const data = res.data?.details
      /* @ts-ignore */
      setCard(data)
      /* @ts-ignore */
      setSeletedCardId(res?.data?.details[0].id || '')
      setLoading(false)
    })
  }
  useEffect(() => {
    getCardDetail()
  }, [])

  useEffect(() => {}, [])
  useEffect(() => {
    if (getImpressions().impressions < 10000) {
      const calc =
        10000 / (campaign?.prospects_counts * getFreq()) - checkout?.days
      if (calc > 0 && calc < 1) {
        if (daysRemaining) {
          daysRemaining(1)
        }
        setNeedDays(1)
      } else {
        setNeedDays(calc)
        if (daysRemaining) {
          daysRemaining(calc)
        }
      }
      //  ? daysRemaining(1) : daysRemaining(calc)

      // daysRemaining(calc)
    } else {
      setNeedDays(0)
      if (daysRemaining) {
        daysRemaining(0)
      }
    }
  }, [checkout?.days])
  function bannerCost() {
    if (campaign?.banner_count >= 1) {
      const data = (campaign?.prospects_counts * checkout?.days * 4) / 1000
      // return data * 18 * campaign?.banner_count
      return data * 18
    }
    return 0
  }
  function mailerCost() {
    if (campaign?.direct_mail_count >= 1) {
      return 2
    }
    return 0
  }
  function videoCost() {
    if (campaign.video_count >= 1) {
      const data = (campaign?.prospects_counts * checkout?.days * 3) / 1000
      // return data * 28 * campaign?.video_count
      return data * 28
    }
    return 0
  }
  function creditsPerMile(): any {
    if (!campaign?.banner_count && !campaign?.video_count) {
      return mailerCost()
    }
    return bannerCost() + videoCost()
  }
  function totalBudget() {
    if (campaign?.banner_count || campaign?.video_count) {
      // return (creditsPerMile() * campaign?.prospects_counts) / 1000
      // const value = (creditsPerMile() * campaign?.prospects_counts) / 1000
      const value = creditsPerMile()
      // if (userData.discount) {
      //   return value - value * (userData?.discount / 100)
      // }
      return value
    }
    return creditsPerMile()
  }

  const diff = userData?.addons_credits - totalBudget()
  // const hasCreatives = !!campaign?.creatives && !!campaign?.creatives?.length

  // const filterCreatives = (creatives: CreativeType[], type: string) => {
  //   return creatives?.filter(
  //     (creative) => creativeTypes[creative?.content_type] === type,
  //   )
  // }

  // const videoCreatives =
  //   campaign?.creatives && filterCreatives(campaign?.creatives, 'Video')
  // const bannerCreatives =
  //   campaign?.creatives && filterCreatives(campaign?.creatives, 'Banner')
  // const mailerCreatives =
  //   campaign?.creatives && filterCreatives(campaign?.creatives, 'Direct Mail')

  // // Temporary points balance
  // const pointsBalance = 2000

  // const getPointsNeeded = () => {
  //   if (campaign?.budget || totalBudget()) {
  //     return Math.abs(pointsBalance - campaign?.budget)
  //   }
  //   return undefined
  // }
  // const check = checkout?.start_date.toString().replace('00:00:00', '05:00:00')

  const isLastCard = card?.map((item: any, index: any) => index === 0)
  const History = useHistory()

  const postBudget = () => {
    isCheckoutLoaded(true)
    const { impressions, video_imp, banner_imp } = getImpressions() || {}
    const data = {
      start_date:
        `${checkout?.start_date.getFullYear()}-${
          checkout?.start_date.getMonth() + 1 < 10
            ? `0${checkout?.start_date.getMonth() + 1}`
            : checkout?.start_date.getMonth() + 1
        }-${
          checkout?.start_date.getDate() < 10
            ? `0${checkout?.start_date.getDate()}`
            : checkout?.start_date.getDate()
        }T00:00:00.000Z` || campaign?.start_date,
      end_date:
        `${checkout?.end_date.getFullYear()}-${
          checkout?.end_date.getMonth() + 1 < 10
            ? `0${checkout?.end_date.getMonth() + 1}`
            : checkout?.end_date.getMonth() + 1
        }-${
          checkout?.end_date.getDate() < 10
            ? `0${checkout?.end_date.getDate()}`
            : checkout?.end_date.getDate()
        }T00:00:00.000Z` || campaign?.end_date,
      days: checkout?.days || campaign?.date,
      budget: parseFloat(totalBudget().toFixed(2)),
      impressions,
      video_imp,
      banner_imp,
      impressions_perday: checkout?.frequency || campaign?.impressions_perday,
      payment_source_id: seletedCardId,
    }

    postCampaignBudget(campaign?.id, data)
      .then((res: any) => {
        if (res.detail) {
          toast.error(res.detail)
        }
        if (res?.data?.msg === 'campaign Created') {
          setOpenSubmitModal(true)
        }
        isCheckoutLoaded(false)
      })
      .catch((err: any) => {
        toast.error(err)
      })
  }

  const findCreatives = (creativeType: string) => {
    if (creativeType === 'banner') {
      return campaign?.creatives?.filter(
        (creat: { content_type: string }) =>
          creat.content_type === creativeType,
      )
    }
    return campaign?.creatives?.find(
      (creat: { content_type: string }) => creat.content_type === creativeType,
    )
  }

  useEffect(() => {
    if (isCreditBuy) {
      toast.success('Credits has been added to your account')
      setIsCreditBuy(false)
    }
  }, [isCreditBuy])

  return (
    <div className="SummaryCart">
      <FlexBox flexDirection="column" padding="1rem" backgroundColor="#FFFFFF">
        <Text
          size="xxl"
          on="white"
          weight="bold"
          UNSAFE_className="Summary_heading"
        >
          Summary
        </Text>
        {/* <Text on="white" size="s">
          Donec id elit non porta gravida et eget metus
        </Text> */}
      </FlexBox>
      {/* <div className="SummaryCart__header-item">
        <Text on="grey-100" weight="bold">
          CREDITS AVAILABLE
        </Text>
        <SummaryEditButton onClick={() => setCreditModalOpen(true)}>
          BUY CREDITS
        </SummaryEditButton>
      </div>
      <div className="SummaryCart__body-item">
        <Text size="xl" on="grey-050" weight="bold">
          <FontAwesomeIcon icon={faStar} color="#1BA1C5" />{' '}
          {userData?.addons_credits}
        </Text>
      </div> */}
      {campaign?.audiences ||
      campaign?.audience_count >= 1 ||
      campaign?.audiences?.length >= 1 ? (
        // {campaign?.audiences?.length >= 1 && (
        <>
          <div className="SummaryCart__header-item">
            <Text
              on="grey-100"
              weight="bold"
              UNSAFE_className="SummaryCart__save_audience"
            >
              SAVED AUDIENCE
            </Text>
            <SummaryEditButton
              onClick={() => {
                History.replace(`/create-campaign/${campaign.id}/setup`)
              }}
            >
              EDIT
            </SummaryEditButton>
          </div>
          <div className="SummaryCart__body-item">
            <Text on="grey-050">
              <FontAwesomeIcon
                icon={faWalking}
                color="var(--color-secondary-300)"
              />
              <span className="SummaryCart__prospects_heading">
                {' '}
                {campaign?.prospects || campaign?.prospects_counts} Prospects
              </span>{' '}
              -{' '}
              <span className="SummaryCart__prospects_subheading">
                {' '}
                {campaign?.audience_count || campaign?.audiences} audiences
              </span>
            </Text>
          </div>
        </>
      ) : (
        ''
      )}

      {openSubmitModal ? (
        <Modal>
          <div
            className="OnboardingModal-welcome SummaryCart__submitModal"
            style={{ position: 'relative' }}
          >
            <img
              className="SummaryCart__lefticon"
              src={Lefticon}
              alt="lefticon"
            />
            <div className="OnboardingModal-welcomeHeader SummaryCart__submitModalContent">
              <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
                {/* <FontAwesomeIcon icon={faCheck} color="gray" size="2x" /> */}
                <img src={succesTick} alt="succesTick" />
              </div>
              <div className="SummaryCart__title_container">
                <Text
                  UNSAFE_className="SummaryCart__title"
                  on="white"
                  textAlign="center"
                >
                  Your Campaign Is Submitted For Review
                </Text>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Button
                UNSAFE_style={{ padding: '30px 30px' }}
                kind="primary"
                size="xl"
                width="50%"
                weight="bold"
                onClick={() => {
                  setOpenSubmitModal(false)
                  History.push('/campaigns')
                }}
                UNSAFE_className="SummaryCart__Back_to_campaigns"
              >
                Back to campaigns
              </Button>
            </div>
            <img
              className="SummaryCart__rigticon"
              src={Righticon}
              alt="righticon"
            />
          </div>
        </Modal>
      ) : (
        ''
      )}
      {/* Creatives Type */}

      {campaign?.creatives?.length > 0 ||
      campaign?.banner_count ||
      campaign?.video_count ||
      campaign?.direct_mail_count ? (
        <>
          <div className="SummaryCart__header-item">
            <Text
              on="grey-100"
              weight="bold"
              UNSAFE_className="SummaryCart__save_audience"
            >
              CREATIVE TYPE
            </Text>
            <SummaryEditButton
              onClick={() =>
                History.replace(`/create-campaign/${campaign.id}/creatives`)
              }
            >
              EDIT
            </SummaryEditButton>
          </div>
          <div>
            {findCreatives('video') ? (
              // || campaign?.video_count > 0
              <div
                style={{ marginTop: '-10px', marginBottom: '-2rem' }}
                className="SummaryCart__body-item "
              >
                <Text
                  on="grey-050"
                  UNSAFE_className="SummaryCart__content_heading"
                >
                  <img src={video} alt="" className="SummaryCart__itemImage" />
                  Video
                </Text>
                <span className="SummaryCart__body-item-subheading">
                  This <b>video ad</b> will be served 3 times per day
                </span>
              </div>
            ) : (
              ''
            )}

            {findCreatives('banner')?.length !== 0 && (
              // &&
              // campaign?.banner_count > 0
              <div
                style={{ marginTop: '-7px', marginBottom: '-1.5rem' }}
                className="SummaryCart__body-item "
              >
                <Text
                  UNSAFE_className="SummaryCart__content_heading"
                  on="grey-050"
                >
                  <img src={banner} alt="" className="SummaryCart__itemImage" />
                  Digital Banner{' '}
                  {findCreatives('banner')?.length > 1 &&
                    `(${findCreatives('banner')?.length})`}
                </Text>
                <span className="SummaryCart__body-item-subheading">
                  This <b>banner ad</b> will be served 4 times per day
                </span>
              </div>
            )}
            {/* {findCreatives('directMail') || campaign?.direct_mail_count > 0 ? (
              <div className="SummaryCart__body-item SummaryCart__item">
                <Text on="grey-050">
                  <img src={mail} alt="" className="SummaryCart__itemImage" />
                  Direct Mail
                </Text>
              </div>
            ) : (
              ''
            )} */}
          </div>
        </>
      ) : (
        ''
      )}

      {totalBudget() || campaign.budget ? (
        <FlexBox flexDirection="column" padding="1rem" paddingBottom="0.5rem">
          <div className="SummaryCart__total_budget">
            <SummaryTotal
              total={totalBudget().toFixed(2) || campaign?.budget.toFixed(2)}
              // isNegative={diff < 1}
              // discount={userData?.discount}
            />
          </div>
          <Spacer />
          <Button
            size="xl"
            kind="primary"
            width="100%"
            UNSAFE_style={{
              height: '54px',
              fontSize: '16px',
              fontWeight: '600',
              color: '#2B1F0A',
            }}
            disabled={needDays > 0 || checkoutLoaded}
            // onClick={() => (getPointsNeeded() ? setCreditModalOpen(true) : {})}
            // onClick={postBudget}
            onClick={() => setIsSelectCard(true)}
          >
            {checkoutLoaded ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '1rem',
                }}
              >
                Checkout and Submit
                <SpinnerLoader style={{ position: 'unset', color: 'black' }} />
              </div>
            ) : (
              'Checkout and Submit'
            )}
          </Button>
          {isSelectCard && (
            <Modal
              style={{ position: 'relative' }}
              offClick={() => setIsSelectCard(false)}
            >
              <div className="SummaryCart__AddCardModal">
                <img
                  className="SummaryCart__leftcardicon"
                  src={leftGroup}
                  alt="leftcardicon"
                />
                <img
                  className="SummaryCart__righcardicon"
                  src={rightGroup}
                  alt="righcardicon"
                />
                <img
                  className="BillingSection__cross"
                  onClick={() => setIsSelectCard(false)}
                  src={cross}
                  alt="cross"
                />
                {/* @ts-ignore */}
                {/* <AddCard /> */}
                <div className="SummaryCart__allCardlist">
                  <Text
                    on="white"
                    UNSAFE_className="SummaryCart__addCardHeading"
                  >
                    {/* @ts-ignore */}
                    {!card.length ? 'Add Card' : 'Select Card'}
                  </Text>
                  {!card.length ? (
                    <div className="BillingSection__main">
                      <img
                        className="BillingSection__billingIcon"
                        src={addcardbill}
                        alt="addcardbill"
                      />
                      <Button
                        type="button"
                        kind="primary"
                        size="m"
                        weight="bold"
                        UNSAFE_className="BillingSection__billingButton"
                        onClick={() => {
                          setIsAddCard(true)
                        }}
                      >
                        Add Card
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="SummaryCart__allCardlist_heading">
                        <div className="SummaryCart__heading_section">
                          <Text
                            on="white"
                            UNSAFE_className="SummaryCart__heading_text"
                          >
                            Card Detail
                          </Text>
                        </div>

                        <Text
                          on="white"
                          UNSAFE_className="SummaryCart__heading_text"
                        >
                          Expiry Date
                        </Text>
                      </div>
                      <div className="SummaryCart__card_list Modal__scrollable-content">
                        {card?.map((item: any, index: number) => (
                          <div
                            className={
                              seletedCardId === item.id
                                ? 'SummaryCart__detailsCard_id'
                                : 'SummaryCart__detailsCard'
                            }
                          >
                            <div
                              key={item.id}
                              className="SummaryCart__billing_card"
                            >
                              <RadioButtonCustom
                                isCircular
                                // checkBorder={checkBorder}
                                // RadioButtonCustomActive={checkBoxActive}
                                checked={seletedCardId === item.id}
                                onChange={(checked) =>
                                  setSeletedCardId(item.id)
                                }
                              />
                              <img
                                src={brand(item?.card.brand)}
                                alt={brand(item?.card?.brand)}
                              />
                              <div className="SummaryCart__billing_card_left">
                                <Text
                                  on="white"
                                  UNSAFE_className="SummaryCart__card_detail_text"
                                >
                                  {item?.card?.masked_number}
                                </Text>
                                <Text
                                  on="white"
                                  UNSAFE_className="SummaryCart__card_detail_text"
                                >
                                  {`${item?.card?.first_name} ${item?.card?.last_name}`}
                                </Text>
                              </div>
                            </div>
                            <div className="">
                              <Text
                                on="white"
                                UNSAFE_className="SummaryCart__heading_text_detail"
                              >
                                {`${item?.card?.expiry_month}/${item?.card?.expiry_year}`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div
                        className="SummaryCart__addCard"
                        onClick={() => {
                          setIsAddCard(true)
                          setIsSelectCard(false)
                          // setCardTitle('Add Card')
                        }}
                      >
                        <button
                          className="SummaryCart__headingButton"
                          type="button"
                        >
                          <img
                            src={plusIcon}
                            alt="uploadicon"
                            style={{ marginRight: '10px' }}
                          />{' '}
                          Add Card
                        </button>
                      </div>
                      <Button
                        kind="primary"
                        width="30%"
                        UNSAFE_style={{
                          height: '54px',
                          fontSize: '16px',
                          fontWeight: '600',
                          color: '#2B1F0A',
                          margin: '1.5rem auto 0 auto',
                        }}
                        onClick={() => {
                          postBudget()
                          setIsSelectCard(false)
                        }}
                        // onClick={() => setIsSelectCard(true)}
                      >
                        Done
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Modal>
          )}
          {isAddCard && (
            <Modal
              style={{ position: 'relative' }}
              offClick={() => setIsAddCard(false)}
            >
              <div className="BillingSection__AddCardModal">
                <img
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    right: '0px',
                    width: '29%',
                  }}
                  src={rightGroup}
                  alt="confirmleftcomb"
                />

                <img
                  className="BillingSection__cross"
                  onClick={() => {
                    setIsAddCard(false)
                    setIsSelectCard(true)
                  }}
                  src={cross}
                  alt="cross"
                />
                {/* @ts-ignore */}
                <div className="SummaryCart__campaginCard">
                  <p
                    className="addCardHeading "
                    style={{ marginBottom: '10px' }}
                  >
                    Add Card
                  </p>

                  <AddCard
                    /* @ts-ignore */
                    getCardDetail={getCardDetail}
                    setIsAddCard={setIsAddCard}
                    setLoadings={setLoading}
                    setIsSelectCard={setIsSelectCard}
                  />
                </div>
                <img
                  style={{
                    position: 'absolute',
                    top: '-20px',
                    left: '8px',
                    width: '28%',
                  }}
                  src={leftGroup}
                  alt="lconfirmleftcomb"
                />
              </div>
            </Modal>
          )}

          {checkoutLoaded && (
            <span
              style={{
                color: '#DC9300',
                marginTop: '8px',
                fontSize: '17px',
                fontWeight: '400',
                textAlign: 'center',
              }}
            >
              The campaign is in progress to be created.
            </span>
          )}
          <Spacer />
        </FlexBox>
      ) : (
        ''
      )}

      {creditModalOpen && (
        <CreditModal
          offClick={() => setCreditModalOpen(false)}
          // pointsNeeded={getPointsNeeded()}
          pointsNeeded={diff < 1 ? diff : 0}
          setIsCreditBuy={setIsCreditBuy}
          // pointsNeeded={50}
        />
      )}
    </div>
  )
}
