import { LensFilterContainer } from 'Pages/ProspectActivity/lenses/components'
import React, { Children } from 'react'
import './Tooltip.scss'

type tooltipTypes = {
  text?: string
  topAbsolute?: string | number | undefined
  rightAbsolute?: string | number | undefined
  bottomAbsolute?: string | number | undefined
  leftAbsolute?: string | number | undefined
  padding?: string | number | undefined
}
export const Tooltip: React.FC<tooltipTypes> = ({
  text,
  topAbsolute,
  rightAbsolute,
  leftAbsolute,
  bottomAbsolute,
  padding,
}) => {
  return (
    <div
      className="box bottom"
      style={{
        padding,
        top: topAbsolute,
        right: rightAbsolute,
        left: leftAbsolute,
        bottom: bottomAbsolute,
      }}
    >
      {text}
    </div>
  )
}

Tooltip.defaultProps = {
  padding: '20px 5px',
}
