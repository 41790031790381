import { atomFamily, selectorFamily } from 'recoil'
import {
  BlockConfigType,
  BlocksConfigType,
  ValueWithErrorType,
} from '../Typings'
import {
  blockSelectorGetter,
  blockSelectorSetter,
  getDefaultShapeShadow,
} from '../Helpers'

// each atom in this file has a selector that corresponds to it
// The atom keeps track of the specific piece of the Block's state...
// ...the selector is able to update all atoms of selected blocks at the same time.

export const blockShadowVisibleAtom = atomFamily<boolean, BlockConfigType>({
  key: 'blockShadowVisible',
  default: (config) =>
    getDefaultShapeShadow(config.kind, config.key, 'enabled'),
})

export const blockShadowVisibleSelector = selectorFamily<
  ValueWithErrorType<boolean>,
  BlocksConfigType
>({
  key: 'blockShadowVisibleSelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockShadowVisibleAtom),
  set:
    (config) =>
    ({ set }, newVisible) => {
      blockSelectorSetter(set, config, blockShadowVisibleAtom, newVisible)
    },
})

export const blockShadowColorAtom = atomFamily<string, BlockConfigType>({
  key: 'blockShadowColor',
  default: (config) => getDefaultShapeShadow(config.kind, config.key, 'color'),
})

export const blockShadowColorSelector = selectorFamily<
  ValueWithErrorType<string>,
  BlocksConfigType
>({
  key: 'blockShadowColorSelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockShadowColorAtom),
  set:
    (config) =>
    ({ set }, newColor) => {
      blockSelectorSetter(set, config, blockShadowColorAtom, newColor)
    },
})

export const blockShadowBlurAtom = atomFamily<number, BlockConfigType>({
  key: 'blockShadowBlur',
  default: (config) => getDefaultShapeShadow(config.kind, config.key, 'blur'),
})

export const blockShadowBlurSelector = selectorFamily<
  ValueWithErrorType<number>,
  BlocksConfigType
>({
  key: 'blockShadowBlurSelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockShadowBlurAtom),
  set:
    (config) =>
    ({ set }, newBlur) => {
      blockSelectorSetter(set, config, blockShadowBlurAtom, newBlur)
    },
})

export const blockShadowOpacityAtom = atomFamily<number, BlockConfigType>({
  key: 'blockShadowOpacity',
  default: (config) =>
    getDefaultShapeShadow(config.kind, config.key, 'opacity'),
})

export const blockShadowOpacitySelector = selectorFamily<
  ValueWithErrorType<number>,
  BlocksConfigType
>({
  key: 'blockShadowOpacitySelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockShadowOpacityAtom),
  set:
    (config) =>
    ({ set }, newOpacity) => {
      blockSelectorSetter(set, config, blockShadowOpacityAtom, newOpacity)
    },
})

export const blockShadowOffsetXAtom = atomFamily<number, BlockConfigType>({
  key: 'blockShadowOffsetX',
  default: (config) =>
    getDefaultShapeShadow(config.kind, config.key, 'offsetX'),
})

export const blockShadowOffsetXSelector = selectorFamily<
  ValueWithErrorType<number>,
  BlocksConfigType
>({
  key: 'blockShadowOffsetXSelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockShadowOffsetXAtom),
  set:
    (config) =>
    ({ set }, newOffsetX) => {
      blockSelectorSetter(set, config, blockShadowOffsetXAtom, newOffsetX)
    },
})

export const blockShadowOffsetYAtom = atomFamily<number, BlockConfigType>({
  key: 'blockShadowOffsetY',
  default: (config) =>
    getDefaultShapeShadow(config.kind, config.key, 'offsetY'),
})

export const blockShadowOffsetYSelector = selectorFamily<
  ValueWithErrorType<number>,
  BlocksConfigType
>({
  key: 'blockShadowOffsetYSelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockShadowOffsetYAtom),
  set:
    (config) =>
    ({ set }, newOffsetY) => {
      blockSelectorSetter(set, config, blockShadowOffsetYAtom, newOffsetY)
    },
})
