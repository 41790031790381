import React from 'react'

import { Modal } from '@eltoro-ui/components'
import { Toaster } from 'react-hot-toast'
import { StepOne } from './StepOne'
import { StepTwo } from './StepTwo'
import { StepThree } from './StepThree'
import './UploadCSVModal.scss'

export const UploadCSVModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal offClick={onClose}>
      <div className="UploadBannerModal uploadcsvModal">
        <Toaster position="top-center" />
        <StepOne onClose={onClose} />

        <div className="UploadBannerModal__hl" />
        <StepTwo onClose={onClose} />

        <div className="UploadBannerModal__hl" />
        <StepThree onClose={onClose} />
      </div>
    </Modal>
  )
}
