import React from 'react'
import { Button, Text } from '@eltoro-ui/components'
import './ViewMarketStats.scss'
import { useHistory } from 'react-router-dom'
import up_icon from '../../../../assets/Images/up-icon.png'
import down_icon from '../../../../assets/Images/down-icon.png'

type ContactsSeenType = {
  textValue: number
  heading: string | undefined
  sub_heading: string | undefined
}
const ViewMarketStats = ({
  heading,
  sub_heading,
  textValue,
}: ContactsSeenType) => {
  const history = useHistory()
  return (
    <div>
      <div className="ViewMarketStats">
        <div className="ViewMarketStats__columns_per_line">
          <div className="ViewMarketStats__number_counter">
            <Text
              on="primary-darker"
              weight="bold"
              UNSAFE_className="ViewMarketStats__text-content__title"
            >
              {heading}
            </Text>
            <div className="ViewMarketStats__text-content">
              <Text
                weight="bold"
                on="primary-darker"
                UNSAFE_className="ViewMarketStats__text-content__sub_title"
              >
                {sub_heading}
              </Text>
            </div>
          </div>

          <div className="ViewMarketStats__percentage">
            <div className="ViewMarketStats__img_wrapper">
              {textValue > 0 ? (
                <img src={up_icon} alt="up icon" />
              ) : (
                <img src={down_icon} alt="down icon" />
              )}
            </div>
            <Text
              on="primary"
              UNSAFE_style={{ color: textValue > 0 ? '#3DC4F5' : '#D89613' }}
              UNSAFE_className="ViewMarketStats__text"
            >
              {textValue}%
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewMarketStats
