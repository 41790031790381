import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConfirmationModal } from 'Components'
import {
  // faPlusCircle,
  faSortDown,
  faSortUp,
  faPlus,
  faUserCircle,
  faSearch,
  faColumns,
  faChartArea,
  faImages,
  faImage,
  faUsers,
  faCog,
  faFile,
  faSignOutAlt,
  faMoneyBill,
  faList,
} from '@fortawesome/free-solid-svg-icons'
import { TRootState } from 'types'
import { useSelector, useDispatch } from 'react-redux'
import { setDropDown, setDropDown1 } from 'Redux/actions'
import { useHistory } from 'react-router-dom'

// import {
//   faSignOut,
//   faUserCircle,
//   faSearch,
//   faColumns,
//   faChartArea,
//   faImages,
//   faImage,
//   faUsers,
//   faCog,
//   faFile,
//   faPlus,
// } from '@fortawesome/pro-light-svg-icons'
import { useLocation } from 'react-router'
import Collapsible from 'react-collapsible'
import {
  HeaderItem,
  Avatar,
  HeaderLogo,
  Modal,
  Text,
  Button,
} from '@eltoro-ui/components'
import './SideNavigationBarForDesktop.scss'
// import logo from '../../Images/Icon_color@2x.png'
import { VotingModal } from 'Components/VotingModal'

import logo from '../../assets/Images/logo-update.svg'
import userIcon from '../../assets/Images/vector_user.png'
import listimg from '../../assets/Images/list.svg'
import activeList from '../../assets/Images/activeList.svg'
import report from '../../assets/Images/report.svg'
import reportActive from '../../assets/Images/report-active.svg'
import Charticons from '../../assets/Images/charticons.png'
import logoutleftcomb from '../../assets/Images/logoutleftcomb.png'
import logoutrightcomb from '../../assets/Images/logoutrightcomb.png'

export const SideNavigationBarForDesktop: FC<{
  value: boolean
  onClick: () => void
  onClickDropdown: () => void
  onLogOut: () => void
  isAdmin: boolean
  isNotificationBarOpen: boolean
  setNotificationsToggle: (arg: boolean) => any
}> = ({
  value,
  onClick,
  onClickDropdown,
  onLogOut,
  isAdmin,
  isNotificationBarOpen,
  setNotificationsToggle,
}) => {
  const [dropdownIsActive, setDropdownIsActive] = useState(false)
  const [logoutModal, setLogoutModal] = useState(false)
  const [collapseToggle, setCollapseToggle] = useState(false)
  const [chartModal, setChartModal] = useState(false)
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [Identifier, setIdentifier] = useState<number | null>(null)
  const { isUnReadNotification } = useSelector(
    (state: TRootState) => state.notificationsReducer,
  )
  const { userData } = useSelector((state: TRootState) => state.userReducer)
  const { dropDownValue } = useSelector((state: TRootState) => state.DropDown)
  const { dropDownValuee } = useSelector((state: TRootState) => state.DropDown)

  const dispatch = useDispatch()

  const history = useHistory()

  const Location = useLocation()
  const childItemRoutes = [
    '/campaign-dashboard',
    '/campaigns',
    '/saved-audiences',
    '/create-campaign',
    '/profile',
  ]
  const isActiveRoute = (route: string) => {
    let className = ''
    if (childItemRoutes.includes(route) && Location.pathname.includes(route)) {
      className = 'active_sidebar_icon_color'
    } else if (Location.pathname.includes(route)) {
      className = 'active_sidebar_icon'
    }
    return className
  }

  return (
    // <div className={`side_nav_bar_container_for_desktop ${0! ? '' : 'hide'}`}>
    <div className={`side_nav_bar_container_for_desktop ${''}`}>
      <div
        className={`shadow_container ${collapseToggle ? '' : 'hide'} ${
          isNotificationBarOpen ? 'heigh_zIndex' : ''
        }`}
        onClick={() => {
          setCollapseToggle(false)
          setNotificationsToggle(false)
        }}
        onKeyDown={() => {
          setCollapseToggle(false)
          setNotificationsToggle(false)
        }}
        role="none"
      />
      <div
        className={`side_nav_bar_nested_container ${
          collapseToggle ? 'set_width' : ''
        }`}
        // className="side_nav_bar_nested_container"
      >
        <div className="icon_container">
          <HeaderLogo
            onClick={() => {
              setCollapseToggle(!collapseToggle)
            }}
            logo={logo}
            // to="/dashboard"
            to="#"
          />
        </div>

        <div>
          <ul className="unorder_list">
            {isAdmin && (
              <>
                <li className="list">
                  <abbr title="Dashboard">
                    <HeaderItem
                      childrenClassName="bar_text"
                      iconClassname={
                        Location.pathname === '/'
                          ? 'active_sidebar_icon'
                          : isActiveRoute('/dashboard')
                      }
                      // iconClassname={isActiveRoute('/dashboard')}
                      icon={
                        <FontAwesomeIcon
                          className="sidebar_icon"
                          icon={faColumns}
                        />
                      }
                      to="/dashboard"
                      onClick={() => {
                        dispatch(setDropDown(false))
                        dispatch(setDropDown1(false))
                      }}
                    >
                      Dashboard
                    </HeaderItem>
                  </abbr>
                </li>

                <li className="list">
                  <abbr title="My Sphere">
                    <HeaderItem
                      childrenClassName="bar_text"
                      iconClassname={isActiveRoute('/my-sphere')}
                      icon={
                        <FontAwesomeIcon
                          className="sidebar_icon"
                          icon={faUserCircle}
                        />
                      }
                      to="/my-sphere"
                      onClick={() => {
                        dispatch(setDropDown(false))
                        dispatch(setDropDown1(false))
                      }}
                    >
                      My Sphere
                    </HeaderItem>
                  </abbr>
                </li>

                <li className="list">
                  <abbr title="Prospects">
                    <HeaderItem
                      childrenClassName="bar_text"
                      iconClassname={isActiveRoute('/prospects')}
                      icon={
                        <FontAwesomeIcon
                          className="sidebar_icon"
                          icon={faSearch}
                        />
                      }
                      to="/prospects"
                      onClick={() => {
                        dispatch(setDropDown(false))
                        dispatch(setDropDown1(false))
                      }}
                    >
                      Prospects
                    </HeaderItem>
                  </abbr>
                </li>

                <li className="list">
                  <abbr title="Advance Report">
                    <HeaderItem
                      childrenClassName="bar_text"
                      iconClassname={isActiveRoute('#')}
                      icon={
                        <FontAwesomeIcon
                          className="sidebar_icon"
                          color="lightgray"
                          icon={faChartArea}
                        />
                      }
                      to="#"
                      onClick={() => {
                        dispatch(setDropDown(false))
                        dispatch(setDropDown1(false))
                        setChartModal(true)
                      }}
                    >
                      Advance Report
                    </HeaderItem>
                  </abbr>
                </li>
                {chartModal && (
                  <Modal offClick={() => setChartModal(false)}>
                    <VotingModal
                      handleModel={() => setChartModal(false)}
                      icons={Charticons}
                      title="Advance Report"
                      subTitle="Engage with potential buyers seen at listings that are similiar to yours and advertise your listings."
                    />
                  </Modal>
                )}
                <li
                  style={
                    !collapseToggle
                      ? {
                          boxShadow: dropDownValue
                            ? '0px 3px 6px rgba(0, 0, 41, 0.25)'
                            : '',
                          // minWidth: '0px',
                          margin: '0px 9px',
                          borderRadius: '5px',
                          width: '72%',
                        }
                      : {}
                  }
                >
                  <Collapsible
                    open={dropDownValue}
                    // onOpen={() => {
                    // }}
                    // onClose={() => {
                    // }}
                    onOpening={() => {
                      setDropdownIsActive(!dropdownIsActive)
                      setIdentifier(1)
                      dispatch(setDropDown(true))
                      dispatch(setDropDown1(false))
                    }}
                    onClosing={() => {
                      setDropdownIsActive(!dropdownIsActive)
                      setIdentifier(null)
                      dispatch(setDropDown(false))
                    }}
                    trigger={
                      <li className="list">
                        <abbr title="My Campaigns">
                          <HeaderItem
                            childrenClassName="bar_text"
                            UNSAFE_STYLE={
                              !collapseToggle ? { marginLeft: '-11px' } : {}
                            }
                            iconClassname="icon_group"
                            icon={
                              <>
                                <FontAwesomeIcon
                                  className="sidebar_icon"
                                  icon={faImages}
                                />
                                <FontAwesomeIcon
                                  className="sidebar_icon"
                                  icon={dropDownValue ? faSortUp : faSortDown}
                                />
                              </>
                            }
                            to=""
                          >
                            My Campaigns
                          </HeaderItem>
                        </abbr>
                      </li>
                    }
                  >
                    <ul
                      className={
                        collapseToggle
                          ? 'unorder_list_withOutPadding'
                          : 'unorder_list'
                      }
                    >
                      <li className="list sub_item">
                        <abbr title="Campaign Dashboard">
                          <HeaderItem
                            childrenClassName="bar_text"
                            iconClassname={`${isActiveRoute(
                              '/campaign-dashboard',
                            )} active_sidebar_img box_shadow_none`}
                            UNSAFE_STYLE={
                              !collapseToggle ? { marginLeft: '-11px' } : {}
                            }
                            icon={
                              <img
                                width={16}
                                className="sidebar_icon"
                                src={
                                  Location.pathname.includes(
                                    '/campaign-dashboard',
                                  )
                                    ? reportActive
                                    : report
                                }
                                alt="..."
                              />
                            }
                            to="/campaign-dashboard"
                          >
                            Campaign Dashboard
                          </HeaderItem>
                        </abbr>
                      </li>
                      <li className="list sub_item">
                        <abbr title="Campaigns">
                          <HeaderItem
                            childrenClassName="bar_text"
                            iconClassname={`${isActiveRoute(
                              '/campaigns',
                            )} box_shadow_none`}
                            UNSAFE_STYLE={
                              !collapseToggle ? { marginLeft: '-11px' } : {}
                            }
                            icon={
                              <FontAwesomeIcon
                                className="sidebar_icon"
                                icon={faImage}
                                style={{
                                  transform: 'rotateY(180deg)',
                                }}
                              />
                            }
                            to="/campaigns"
                          >
                            Campaigns
                          </HeaderItem>
                        </abbr>
                      </li>
                      <li className="list sub_item">
                        <abbr title="Saved Audience">
                          <HeaderItem
                            childrenClassName="bar_text"
                            iconClassname={`${isActiveRoute(
                              '/saved-audiences',
                            )} box_shadow_none`}
                            UNSAFE_STYLE={
                              !collapseToggle ? { marginLeft: '-11px' } : {}
                            }
                            icon={
                              <FontAwesomeIcon
                                className="sidebar_icon"
                                icon={faUsers}
                              />
                            }
                            to="/saved-audiences"
                          >
                            Saved Audience
                          </HeaderItem>
                        </abbr>
                      </li>
                      <li className="list sub_item">
                        <abbr title="New Campaign">
                          <HeaderItem
                            childrenClassName="bar_text"
                            iconClassname={`${isActiveRoute(
                              '/create-campaign/setup',
                            )} box_shadow_none`}
                            UNSAFE_STYLE={
                              !collapseToggle ? { marginLeft: '-11px' } : {}
                            }
                            to="/create-campaign/setup"
                            onClick={onClickDropdown}
                            icon={<FontAwesomeIcon icon={faPlus} />}
                          >
                            New Campaign
                          </HeaderItem>
                        </abbr>
                      </li>
                    </ul>
                  </Collapsible>
                </li>

                <li className="list">
                  <abbr title="My Listings">
                    <HeaderItem
                      childrenClassName="bar_text"
                      iconClassname={`${isActiveRoute(
                        '/my-listings',
                      )} active_sidebar_img`}
                      icon={
                        <img
                          width={20}
                          className="sidebar_icon"
                          src={
                            Location.pathname.includes('/my-listings')
                              ? activeList
                              : listimg
                          }
                          alt="..."
                        />
                      }
                      to="/my-listings"
                      onClick={() => {
                        dispatch(setDropDown(false))
                        dispatch(setDropDown1(false))
                      }}
                    >
                      My Listings
                    </HeaderItem>
                  </abbr>
                </li>
                <li
                  style={
                    // !collapseToggle && dropDownValue
                    !collapseToggle
                      ? {
                          boxShadow: dropDownValuee
                            ? '0px 3px 6px rgba(0, 0, 41, 0.25)'
                            : '',
                          // minWidth: '0px',
                          margin: '0px 9px',
                          borderRadius: '5px',
                          width: '72%',
                        }
                      : {}
                  }
                >
                  <Collapsible
                    open={dropDownValuee}
                    onOpening={() => {
                      setDropdownIsActive(!dropdownIsActive)
                      setIdentifier(2)
                      dispatch(setDropDown(false))
                      dispatch(setDropDown1(true))
                    }}
                    onClosing={() => {
                      setDropdownIsActive(!dropdownIsActive)
                      dispatch(setDropDown1(false))
                      setIdentifier(null)
                    }}
                    trigger={
                      <li className="list">
                        <abbr title="Settings">
                          <HeaderItem
                            childrenClassName="bar_text"
                            UNSAFE_STYLE={
                              !collapseToggle ? { marginLeft: '-10px' } : {}
                            }
                            // UNSAFE_STYLE={
                            //   !dropdownIsActive ? { marginLeft: '-11px' } : {}
                            // }
                            iconClassname="icon_group"
                            icon={
                              <>
                                <FontAwesomeIcon
                                  className="sidebar_icon"
                                  icon={faCog}
                                />
                                <FontAwesomeIcon
                                  className="sidebar_icon"
                                  icon={dropDownValuee ? faSortUp : faSortDown}
                                />
                              </>
                            }
                            to=""
                          >
                            Settings
                          </HeaderItem>
                        </abbr>
                      </li>
                    }
                  >
                    <ul
                      className={
                        collapseToggle
                          ? 'unorder_list_withOutPadding'
                          : 'unorder_list'
                      }
                    >
                      <li className="list sub_item">
                        <abbr title="Profile">
                          <HeaderItem
                            childrenClassName="bar_text"
                            iconClassname={`${isActiveRoute(
                              '/profile',
                            )} box_shadow_none`}
                            UNSAFE_STYLE={
                              !collapseToggle ? { marginLeft: '-11px' } : {}
                            }
                            onClick={() => {
                              history.push('/profile')
                            }}
                            // UNSAFE_STYLE={
                            //   !dropdownIsActive ? { marginLeft: '-11px' } : {}
                            // }
                            icon={
                              userData?.profile_pic ? (
                                <Avatar
                                  name={`${userData?.first_name} ${userData?.last_name}`}
                                  rounded
                                  size="s"
                                  src={userData?.profile_pic}
                                  UNSAFE_className="avatar"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="sidebar_icon"
                                  icon={faUserCircle}
                                />
                              )
                            }
                            to="/profile"
                          >
                            Profile
                          </HeaderItem>
                        </abbr>
                      </li>

                      <li className="list sub_item">
                        <abbr title="Logout">
                          <HeaderItem
                            childrenClassName="bar_text"
                            UNSAFE_STYLE={
                              !collapseToggle ? { marginLeft: '-11px' } : {}
                            }
                            iconClassname="box_shadow_none"
                            UNSAFE_CLASSNAME="box_shadow_none"
                            // UNSAFE_STYLE={
                            //   !dropdownIsActive ? { marginLeft: '-11px' } : {}
                            // }
                            icon={
                              <FontAwesomeIcon
                                className="sidebar_icon"
                                icon={faSignOutAlt}
                              />
                            }
                            // onClick={onLogOut}
                            onClick={() => setLogoutModal(true)}
                          >
                            Logout
                          </HeaderItem>
                        </abbr>
                      </li>
                    </ul>
                  </Collapsible>
                </li>
                {logoutModal && (
                  <ConfirmationModal
                    heading={` Are you sure you want to logout?`}
                    onSubmit={() => {
                      onLogOut()
                      setIsLoading(true)
                    }}
                    onCancel={() => setLogoutModal(false)}
                    loading={Boolean(isLoading)}
                  />
                  // <Modal offClick={() => setLogoutModal(false)}>
                  //   <div className="logout_main">
                  //     <img
                  //       className="logoutrightcomb"
                  //       src={logoutrightcomb}
                  //       alt="logoutleftcomb"
                  //     />
                  //     <Text
                  //       UNSAFE_className="logout_main__heading"
                  //       on="tertiary-300"
                  //     >
                  //       Are you sure you want to logout?
                  //     </Text>
                  //     <div className="logout_main__button">
                  //       <Button
                  //         // UNSAFE_className="confirm_button_modal"
                  //         UNSAFE_className="logout_main__yes_button_modal"
                  //         kind="primary"
                  //         onClick={() => {
                  //           onLogOut()
                  //           setIsLoading(true)
                  //         }}
                  //         disabled={isLoading}
                  //         loading={Boolean(isLoading)}
                  //       >
                  //         Yes
                  //       </Button>
                  //       <Button
                  //         kind="text"
                  //         UNSAFE_className="logout_main__no_button_modal"
                  //         onClick={() => setLogoutModal(false)}
                  //         disabled={isLoading}
                  //       >
                  //         No
                  //       </Button>
                  //     </div>
                  //     <img
                  //       className="logoutleftcomb"
                  //       src={logoutleftcomb}
                  //       alt="logoutleftcomb"
                  //     />
                  //   </div>
                  // </Modal>
                )}
              </>
            )}

            {!isAdmin && (
              <ul className="unorder_list">
                <li className="list sub_item">
                  <abbr title="Logout">
                    <HeaderItem
                      childrenClassName="bar_text"
                      iconClassname="box_shadow_none"
                      UNSAFE_CLASSNAME="box_shadow_none"
                      icon={
                        <FontAwesomeIcon
                          className="sidebar_icon"
                          icon={faSignOutAlt}
                        />
                      }
                      onClick={onLogOut}
                    >
                      Logout
                    </HeaderItem>
                  </abbr>
                </li>
              </ul>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
