import { Button } from '@eltoro-ui/components'
import { CustomMapPopup } from 'Components'
import { CustomMapCanvasPopup } from 'Components/CustomMapCanvasPopup/CustomMapCanvasPopup'
import {
  getBounds,
  getLocationSearchResults,
  getLocationSearchResultsOSMId,
} from 'Helpers'
// import * as L from 'leaflet';

import L, { LatLngTuple } from 'leaflet'
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster'
import 'leaflet-providers'
import 'leaflet/dist/leaflet.css'
import React, { useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { setSpinner } from 'Redux/actions'
// import 'leaflet-fullscreen'
import { APIFeatureType, MapLocationType } from 'types'
import './Map.scss'

const center: L.LatLngExpression = [37.09024, -95.712891]

export const Map: React.FC<{
  tileProvider?: string
  // features: APIFeatureType[]
  features: any
  locations: MapLocationType[]
  updatedList?: boolean
  style?: any
  setupdatedList?: (val: boolean) => void
  refreshState?: boolean
  setrefreshState?: (val: boolean) => void
  locationsArea?: any[]
  buzzAvg?: any[]
  marker: (f: APIFeatureType) => JSX.Element
  targetedMarker?: (f: APIFeatureType) => JSX.Element
  popup?: (f: APIFeatureType) => JSX.Element
  tooltip: (f: any) => JSX.Element
  type?: string
  circle?: any
  callPolygonFilters?: boolean
  firstClicked?: boolean
  setcallPolygonFilters?: (val: boolean) => void
  searchClicked?: boolean
  listingData?: any
  setListingData?: (val: object) => void
  setListings?: (val: object) => void
  setradiusListing?: (val: number[]) => void
  zoomLevel?: number | undefined
}> = ({
  tileProvider,
  features,
  locations,
  marker,
  popup,
  tooltip,
  style,
  locationsArea,
  targetedMarker,
  setrefreshState,
  refreshState,
  buzzAvg,
  updatedList,
  setupdatedList,
  firstClicked,
  type,
  circle,
  callPolygonFilters,
  setcallPolygonFilters,
  searchClicked,
  listingData,
  setListingData,
  setradiusListing,
  setListings,
  zoomLevel,
}) => {
  const [bounds, setBounds] = useState(
    L.latLngBounds([42.2974279, -85.628292], [-8.852507, -45.351563]),
  )

  // init map

  const dispatch = useDispatch()
  const mapRef = useRef<any>()
  useEffect(() => {
    const provider = tileProvider || 'CartoDB.VoyagerLabelsUnder'
    mapRef.current = L.map('leaflet-map', {
      fullscreenControl: true,
      closePopupOnClick: false,
      // maxZoom: 19,
      // minZoom: 2,
    }).setView(center, zoomLevel || 4)
    // .setZoom(1)

    // L.tileLayer.provider(provider, { noWrap: true }).addTo(mapRef.current)
    L.tileLayer.provider(provider).addTo(mapRef.current)
    // mapRef.current.addControl(new L.Control.Fullscreen())

    // When Dragging maps, close all open popups
    if (type !== 'Digital Farming') {
      mapRef.current.on('dragstart', () => mapRef.current?.closePopup())
    }
  }, [])

  const [isClick, setIsClick] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState<any>({
    mls_id: '',
    data_source: '',
    ethash: '',
    street: '',
    city: '',
    zip: '',
    sub_divison: '',
    listing_price: 0,
    size: 34,
    beds: 2,
    baths: 3,
    year_built: 1997,
    created: '',
    updated: '',
    status: '',
    geojson: {
      coordinates: [],
      type: '',
    },
    latitude: 0,
    longitude: 0,
    activity_score: 0,
    unique_visitor_count: 0,
  })
  // init layer
  const layerRef = useRef<any>()
  useEffect(() => {
    if (mapRef.current) {
      layerRef.current = L.layerGroup().addTo(mapRef.current)
    }
  }, [])
  const [score, setScore] = useState()

  const popupContent = () => {
    return <h1>Bind popup works</h1>
  }
  // if there are locations, set bounds
  useEffect(() => {
    if (locations.length > 0) {
      const locationsBounds = {
        lat: locations.reduce((acc: number[], location) => {
          const north = parseFloat(location.bounds[0][0])
          const south = parseFloat(location.bounds[1][0])
          return [...acc, north, south]
        }, []),
        long: locations.reduce((acc: number[], location) => {
          const east = parseFloat(location.bounds[0][1])
          const west = parseFloat(location.bounds[1][1])
          return [...acc, east, west]
        }, []),
      }

      setBounds(getBounds(locationsBounds.lat, locationsBounds.long))
    }
  }, [locations])
  const ftData: any = []

  const metersCalculation = (radius: { label: string } | undefined) => {
    const meters = [1000, 750, 500]
    if (radius?.label === '2,000 meters') {
      return meters[0]
    }
    if (radius?.label === '1,500 meters') {
      return meters[1]
    }
    return meters[2]
  }
  // circle
  // useEffect(() => {
  //   const circle = L.circle([28.538336, -81.379234], {
  //     color: '#44C2E64D',
  //     fillColor: '#44C2E64D',
  //     fillOpacity: 0.5,
  //     radius: 3200.0,
  //   }).addTo(layerRef.current)

  //   const circle1 = L.circle([circle.latitude, circle.longitude], {
  //     color: '#44C2E64D',
  //     fillColor: '#44C2E64D',
  //     fillOpacity: 0.5,
  //     radius: 32000.0,
  //   }).addTo(layerRef.current)
  // })

  mapRef.current?.on('fullscreenchange', function () {
    if (mapRef.current.isFullscreen()) {
      // const provider = tileProvider || 'CartoDB.VoyagerLabelsUnder'
      // const updateCenter: L.LatLngExpression = [
      //   41.318043148599706, -87.02493358848308,
      // ]
      // mapRef.current = L.map('leaflet-map', {
      //   minZoom: 2,
      //   maxZoom: 6,
      // }).setView(updateCenter, zoomLevel || 4)
      // // mapRef.current._lastCenter(updateCenter)
      // L.tileLayer.provider(provider).addTo(mapRef.current)
      mapRef.current.panTo(bounds, zoomLevel || 4)

      fitMapToBounds()
    } else {
      const provider = tileProvider || 'CartoDB.VoyagerLabelsUnder'
      const updateCenter: L.LatLngExpression = [
        41.318043148599706, -87.02493358848308,
      ]
      mapRef.current = L.map('leaflet-map', {
        minZoom: 2,
        maxZoom: 6,
      }).setView(updateCenter, zoomLevel || 4)
      // mapRef.current._lastCenter(updateCenter)
      L.tileLayer.provider(provider).addTo(mapRef.current)
      mapRef.current.panTo(updateCenter, zoomLevel || 4)
      // fitMapToBounds()
    }
  })

  const [multicor, setmulticor] = useState([])
  useEffect(() => {
    if (updatedList) {
      // getListingHistogram().then((res) => {
      // setmulticor([])
      // layerRef.current?.clearLayers()
      // })
    }
  }, [updatedList])
  // const [onceLoop, setonceLoop] = useState(false)

  useEffect(() => {
    if (callPolygonFilters) {
      if (!firstClicked) {
        // mcg.clearLayers()

        layerRef.current?.clearLayers()
      }
      polygonFilters()
    }
  }, [callPolygonFilters])
  const polygonFilters = () => {
    if (
      (type === 'Sell Your Listing Faster' ||
        type === 'Expand Your Marketing') &&
      locationsArea &&
      locationsArea.length > 0
    ) {
      // if (refreshState) {
      // const base64Creatives = []
      const centerMap: any = []
      for (let i = 0; i < locationsArea.length; i += 1) {
        let buzzData: any
        if (buzzAvg) {
          buzzData = {
            street: locationsArea[i].display_value,
            activity_score: buzzAvg[i],
            mls_id: `${'locationsArea'[i]}`,
          }
        }

        dispatch(setSpinner(true))
        getLocationSearchResults(locationsArea[i].value)
          .then((resp) => {
            getLocationSearchResultsOSMId(resp.results[0].osm_id)
              .then((res) => {
                if (res.length > 0) {
                  if (res[0].geojson.type === 'Polygon') {
                    const singlePolLatLngs = L.GeoJSON.coordsToLatLngs(
                      res[0].geojson.coordinates,
                      1,
                    )
                    centerMap.push(singlePolLatLngs)
                    const singlePol = L.polygon(singlePolLatLngs, {
                      color: 'blue',
                      fillColor: 'skyblue',
                    })

                    singlePol
                      .bindPopup(
                        renderToString(
                          <CustomMapPopup
                            feature={buzzData}
                            prospect={false}
                          />,
                        ),
                      )
                      .openPopup()
                      .addTo(layerRef.current)
                  } else {
                    // base64Creatives.push(
                    //   ...L.GeoJSON.coordsToLatLngs(res[0].geojson.coordinates, 2),
                    // )
                    const multiPolLatLngs = L.GeoJSON.coordsToLatLngs(
                      res[0].geojson.coordinates,
                      2,
                    )
                    centerMap.push(multiPolLatLngs)

                    const multiPol = L.polygon(multiPolLatLngs, {
                      color: 'blue',
                      fillColor: 'skyblue',
                    })

                    multiPol
                      .bindPopup(
                        renderToString(
                          <CustomMapPopup
                            feature={buzzData}
                            prospect={false}
                          />,
                        ),
                      )
                      .openPopup()
                      .addTo(layerRef.current)
                    // if (type === 'my listing') {
                    // pol.on('mouseover', () => featureToolTip(pol).openTooltip())
                    // }
                  }
                  if (i === locationsArea.length - 1) {
                    setBounds(centerMap)
                    dispatch(setSpinner(false))
                    // layerRef.current.panTo()
                    // const group = L.featureGroup(centerMap)
                    // setBounds(L.latLngBounds([position, position2])
                  }
                } else {
                  if (i === locationsArea.length - 1) {
                    dispatch(setSpinner(false))
                    // layerRef.current.panTo()
                    // const group = L.featureGroup(centerMap)
                    // setBounds(L.latLngBounds([position, position2])
                  }
                  toast.error(
                    `No polygon found for ${locationsArea[i].display_value}`,
                  )
                }
              })
              .catch((err) => {
                toast.error(err)
              })
              .finally(() => {
                if (i === locationsArea.length - 1 && setcallPolygonFilters) {
                  setcallPolygonFilters(false)
                  // dispatch(setSpinner(false))
                }
              })
          })
          .catch((err) => {})
      }
      // }
    }
  }

  // add features to layer
  useEffect(() => {
    const mcg = L.markerClusterGroup({ chunkedLoading: true })

    // mcg.clearLayers()
    mcg.clearLayers()

    // mapRef.current.removeLayer(mcg)

    // L_Emergency_Access_Points.clearLayers();
    // mcg.removeLayer.clearLayers()
    // mcg
    layerRef.current?.clearLayers()
    // layerRef.current.removeLayer(mcg)

    if (layerRef.current) {
      let i = 0
      const newList: any = []
      const inRadiusObj: any = {}
      const countInObjList: any = []
      let ListingUpdate: any[]
      if (listingData) {
        ListingUpdate = [...listingData.listings]
      }

      if (features.length >= 1)
        features?.forEach((feature: any) => {
          i += 1
          const featureToolTip = (currentFeatureMarker: L.Marker) => {
            return currentFeatureMarker.bindTooltip(
              renderToString(tooltip(feature)),
              { direction: 'top', offset: [4, -27] },
            )
          }

          const featureMarker = L.marker(
            [feature.latitude, feature.longitude],
            {
              icon: L.divIcon({
                html: renderToString(marker(feature)),
              }),
              title: feature.street ? feature.street : undefined,
            },
          )

          if (type === 'my listing') {
            featureMarker
              .bindPopup(renderToString(<CustomMapPopup feature={feature} />))
              .openPopup()
              .addTo(layerRef.current)
          } else {
            // featureMarker.addTo(layerRef.current)
            // mcg.clearLayers()
            // mcg.clearLayers()

            mcg.addLayer(featureMarker)
            // mapRef.current.addTo(mcg)
          }

          if (type === 'my listing') {
            featureMarker.on('mouseover', () =>
              featureToolTip(featureMarker).openTooltip(),
            )
          }
          featureMarker.on('click', () => {
            setSelectedFeature(feature)
            setIsClick(true)
          })

          if (
            type === 'Digital Farming' &&
            searchClicked &&
            feature?.selected
          ) {
            const theRadius = metersCalculation(feature?.radius)

            const selPts: any = []

            const circleNew = L.circle(
              [feature.latitude, feature.longitude],
              theRadius,
              {
                color: '#44C2E64D',
                fillColor: '#44C2E64D',
                fillOpacity: 0.5,
              },
            ).addTo(layerRef.current)
            const xy: LatLngTuple = [feature.latitude, feature.longitude]

            let latlng_b

            // checking if property exists in the array and add into the object like {'listingid': 'unique_observations'}
            for (let k = 0; k < features.length; k += 1) {
              latlng_b = new L.LatLng(
                features[k]?.latitude,
                features[k]?.longitude,
              )
              if (
                latlng_b.distanceTo(xy) < theRadius &&
                feature.listingid !== features[k].listingid
              ) {
                if (!inRadiusObj[feature.listingid]) {
                  inRadiusObj[feature.listingid] =
                    features[k].unique_visitor_count
                } else {
                  inRadiusObj[feature.listingid] +=
                    features[k].unique_visitor_count
                }
                countInObjList.push(features[k].listingid)
                selPts.push({ i: features[k].unique_visitor_count })
              }
              if (k === features.length - 1 && setradiusListing) {
                setradiusListing(countInObjList)
              }
            }

            for (let l = 0; l < features.length; l += 1) {
              if (inRadiusObj[ListingUpdate[l].listingid]) {
                ListingUpdate[l].unique_observations_total =
                  inRadiusObj[ListingUpdate[l].listingid] +
                  ListingUpdate[l].unique_visitor_count
              } else {
                ListingUpdate[l].unique_observations_total = 0
              }
            }
            circleNew
              .bindPopup(
                renderToString(
                  <CustomMapCanvasPopup
                    feature={feature}
                    searchClicked={searchClicked}
                    setListingData={setListingData}
                    listingData={listingData}
                    setListings={setListings}
                  />,
                ),
                {
                  autoPan: false,
                  autoClose: false,
                  closeOnClick: false,
                  offset: [0, -10],
                },
              )
              .openPopup()

            newList.push([circleNew.getBounds()])
            if (i === features.length - 1) {
              setBounds(newList)
            }
          }
        })
      layerRef?.current?.addLayer(mcg)

      if (type === 'Digital Farming' && !searchClicked) {
        const featureBounds = {
          lats: features.map((feature: any) => feature.latitude),
          longs: features.map((feature: any) => feature.longitude),
        }
        setBounds(getBounds(featureBounds.lats, featureBounds.longs))
      }

      if (features.length > 0 && type !== 'Digital Farming') {
        const featureBounds = {
          lats: features.map((feature: any) => feature.latitude),
          longs: features.map((feature: any) => feature.longitude),
        }
        setBounds(getBounds(featureBounds.lats, featureBounds.longs))
      }
    }
  }, [features])

  // Map markers for selected listings

  // fit map to bounds when bounds are updated
  const fitMapToBounds = () => {
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds).setZoom(zoomLevel || 4)
      // mapRef.current.setZoom(4)
    }
  }

  // fit to bounds when bounds are updated via location/feature changes
  useEffect(() => {
    if (features.length > 0) {
      fitMapToBounds()
    }
  }, [bounds])

  return (
    <div style={style} className="Map" id="leaflet-map">
      {features?.length > 0 && (
        <div className="Map__fit-to-bounds-button">
          <Button onClick={fitMapToBounds} rounded>
            Zoom to results
          </Button>
        </div>
      )}
    </div>
  )
}
