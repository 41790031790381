import { request } from 'https'
import {
  MapLocationType,
  APIFeatureType,
  ResponseType,
  FilterType,
  APIFeatureResponseType,
  TUserAccess,
  TAdminUsers,
  NotificationType,
  NotificationSendType,
} from 'types'
import requests from '../http'

export const getRealStateUsersListings = async (
  page?: number | null,
  size?: number | null,
  search?: string | null,
  sort_key?: string | null,
  order?: string | null,
): Promise<ResponseType<TAdminUsers>> => {
  const req = search
    ? `/admin/users?page=${page}&size=${size}&search=${search}&${
        sort_key && `sort_key=${sort_key}&order=${order}`
      }`
    : `/admin/users?page=${page}&size=${size}&${
        sort_key && `sort_key=${sort_key}&order=${order}`
      }`
  // return requests.get(req)

  const res: any = await requests.get(req)
  return res
}

export const getUserToken = async (
  sub?: string | null,
): Promise<ResponseType<TUserAccess>> =>
  requests.get(`/admin/user_access?sub=${sub}`)

export const createNotification = async (
  body: NotificationSendType,
): Promise<ResponseType<NotificationSendType>> =>
  requests.post(`/admin/notification`, body)
