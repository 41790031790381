import React, { useState, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  Button,
  MaxHeightContainer,
  SelectOptionType,
  Spacer,
  Checkbox,
  FlexBox,
  Modal,
  Text,
  Table,
  FormItem,
  Select,
} from '@eltoro-ui/components'
import { useLocations } from 'Hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons'
import {
  getMapListings,
  getFilters,
  postFYP,
  getRealtorActivity,
} from 'Requests'
import {
  getIconBuzzColor,
  removeKeyFromObject,
  getDecryptedData,
} from 'Helpers'
import {
  APIFeatureType,
  FeatureFieldType,
  FilterDataType,
  FilterType,
  MinMaxType,
  TRealtorType,
  TRootState,
} from 'types'
import axios from 'axios'
import {
  BuzzScoreFilters,
  Map,
  MapPopup,
  MapSearch,
  MapToolTip,
  Realtor,
  BuzzScore,
} from 'Components'
import { browserLog } from 'Tools'
import toast from 'react-hot-toast'
import {
  AudienceResultsCard,
  AudienceResultsCount,
  BedroomNBathFilter,
  HomeTypeFilter,
  LocationLabels,
  MapMarker,
  PriceFilter,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components'
import { buzzFilters } from 'Pages/ProspectActivity/configs'
import { faWalking } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { SquareFootFilter } from 'Pages/ProspectActivity/components/FindProspectFilters/SquareFootFilter'
import { setFilteredBuzz, setSidebarLens } from 'Redux/actions'
// import { LinearLoader } from 'Components/LinearLoader'
import { postAreaSearch } from 'Requests/Request_Methods/prospectMethods'
import { ProspectsFilter } from '../../components/FindYourProspectsComponents'
import {
  LensFilterContainer,
  LensSectionHeading,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from '../components'
import './SellYourListingFirst.scss'
import buzzScoreImg from '../../../../assets/Images/buzz_score.png'

const defaults = {
  listing_price: {
    id: 'listing_price',
    label: 'Listing price',
    options: { min: 0, max: 5000000 },
  },
  beds: {
    id: 'beds',
    label: 'Minimum number of bedrooms',
    options: [1, 2, 3, 4, 5],
  },
  baths: {
    id: 'baths',
    label: 'Minimum number of bathrooms',
    options: [1, 2, 3, 4, 5],
  },
  status: {
    id: 'status',
    label: 'Availibility Status',
    options: ['Active', 'Contingent', 'Under Contract', 'Sold'],
  },
  year_built: {
    id: 'year_built',
    label: 'Year Built',
    options: { min: 0, max: 2021 },
  },
}

export const SellYourListingFirst = () => {
  const {
    locations,
    addLocation,
    removeLocation,
    hasLocations,
    clearLocation,
  } = useLocations()
  const [features, setFeatures] = useState<APIFeatureType[]>([])
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState<{ [key: string]: FilterType }>({})
  const dispatch = useDispatch()
  const [activeFilters, setActiveFilters] =
    useState<{ [key: string]: FilterType }>(defaults)
  const [activeBuzzFilters, setActiveBuzzFilters] = useState(buzzFilters)
  const [locationFilter, setLocationFilter] = useState({
    location: '',
  })
  const [activeToggleFeature, setActiveToggleFeature] = useState<string>('')
  // Filters dropdown positioning
  const [searchRect, setSearchRect] = useState<DOMRect>()
  const measuredFiltersRef = useCallback(
    (node: HTMLButtonElement) => {
      if (node !== null) {
        setSearchRect(node.getBoundingClientRect())
      }
    },
    [showFilters],
  )
  // prospectFilters={beds:}
  // used for onChange in active Filter component - this needs to be debounced for server side filtering
  const updateActiveFilters = (filter: { [key: string]: FilterType }) => {
    setActiveFilters((prev) => ({
      ...prev,
      ...filter,
    }))
  }

  const [realtorFilters, setRealtorFilters] = useState({
    page: 1,
    sort: 'active_listings',
    order: 'desc',
  })

  const [realtorData, setRealtorData] = useState([])

  useEffect(() => {
    if (locations.length) {
      const bodySchema = {
        key: '',
        // key: 'city',
        value: '',
        size: 5,
        ...realtorFilters,
      }
      const body: any = []

      locations.map(
        (location: { key: string; value: string; display_value: string }) => {
          return body.push({
            ...bodySchema,
            value: location.value,
            key: location.key,
          })
        },
      )

      getRealtorActivity({ search: body })
        .then((res: any) => {
          if (res?.detail) {
            toast.success(res?.detail?.msg)
          }
          setRealtorData(res?.data?.result)
        })
        .catch((err) => toast.error(err))
    }
  }, [locations, realtorFilters])

  const onPageChange = (page: number) => {
    setRealtorFilters({ ...realtorFilters, page })
  }
  const onSort = () => {
    const order = realtorFilters.order === 'desc' ? 'asc' : 'desc'
    setRealtorFilters({ ...realtorFilters, order, page: 1 })
  }

  // add or remove filter object
  const handleFilterActivation = (key: string, checked: boolean) => {
    if (checked) {
      setActiveFilters((prev) => ({
        ...prev,
        [key]: filters[key],
      }))
    } else {
      setActiveFilters((prev) => removeKeyFromObject(key, prev))
    }
  }

  const isActiveFilter = (k: string) =>
    Object.keys(activeFilters).some((filter: string) => filter === k)

  const filterSelections = (filterSet: { [key: string]: FilterType }) => {
    return Object.entries(filterSet).reduce((acc, filter) => {
      if (filter[1].data) {
        if ((filter[1].data as { label: string }).label) {
          return {
            ...acc,
            [filter[0]]: parseInt(
              (filter[1].data as { label: string }).label,
              10,
            ),
          }
        }
        return {
          ...acc,
          [filter[0]]: filter[1].data,
        }
      }
      return acc
    }, {})
  }

  // this filtering will most likely be done on the server, but some of the filtering might be more
  // perfomant with the client-side filtering of returned meta data of smaller API responses.
  // TLDR; this filtering should probably be done server-side

  const filterFeatures = (
    feats: APIFeatureType[],
    selectedFilters: { [key: string]: FilterDataType },
  ) => {
    return Object.entries(selectedFilters).reduce(
      (filteredFeatures: APIFeatureType[], flt) => {
        return filteredFeatures.reduce(
          (matchingFeatures: APIFeatureType[], feature) => {
            const matchingData = Object.entries(feature).reduce(
              (acc: any[], dataEntry) => {
                if (flt[0] === dataEntry[0]) {
                  if (dataEntry[1] && typeof flt[1] === 'object') {
                    if (
                      (flt[1] as MinMaxType).max >= 0 &&
                      (flt[1] as MinMaxType).min >= 0
                    ) {
                      const range = flt[1] as MinMaxType
                      if (
                        dataEntry[1] >= range.min &&
                        dataEntry[1] <= range.max
                      )
                        return [...acc, dataEntry]
                    }
                  }
                  if (dataEntry[1] && typeof flt[1] === 'number') {
                    if (dataEntry[1] >= flt[1]) return [...acc, dataEntry]
                  }
                  if (dataEntry[1] && Array.isArray(flt[1])) {
                    if (
                      flt[1].some(
                        (value: string | number) => dataEntry[1] === value,
                      )
                    )
                      return [...acc, dataEntry]
                  }
                  // data currently does not have a boolean meta data
                  /* if (dataEntry[1] && typeof flt[1] === 'boolean') {
                   *   if (dataEntry[1] === flt[1]) acc.concat(dataEntry)
                   * } */
                  return acc
                }
                return acc
              },
              [],
            )
            if (matchingData.length) return [...matchingFeatures, feature]
            return matchingFeatures
          },
          [],
        )
      },
      feats,
    )
  }
  const [buzzExcluded, setbuzzExcluded] = useState<string[]>()
  const [selectedListings, setSelectedListings] = useState<any>(null)
  const [listingArea, setlistingArea] = useState<any>([])
  const [buttonClicked, setbuttonClicked] = useState(false)
  const [buttonDisabled, setbuttonDisabled] = useState(false)
  const [isSubmitAreas, setisSubmitAreas] = useState(false)
  const [dataObj, setdataObj] = useState<any>()
  const [toggle, setToggle] = useState(true)
  const [loading, setLoading] = useState(false)

  const resetStates = () => {
    clearLocation()
    setlistingArea([])
    setSelectedListings([])
  }

  useEffect(() => {
    getFilters().then((res: any) => setFilters(res?.data?.filters))
  }, [])
  const AllBuzz: any = []
  const totalProspects = listingArea.reduce(
    (count: any, currFeature: any) => count + currFeature.unique_visitor_count,
    0,
  )
  const [range, setRange] = useState({ price: ['0', '1'], area: [1, 2] })
  const [filteres, setFilteres] = useState<any>([])

  const onChange = (key: string, value: string | number | undefined) => {
    setLocationFilter({ ...locationFilter, [key]: value })
  }

  const bedFilter = () =>
    postedFilter.bed &&
    filteres.push({
      key: 'beds',
      value: [postedFilter.bed],
      type: 'greater',
    })
  const bathFilter = () =>
    postedFilter.bath &&
    filteres.push({
      key: 'baths',
      value: [postedFilter.bath],
      type: 'greater',
    })
  const isPriceSelectedAny = (price: string) => price === 'Any'
  const priceFilter = (price: any) =>
    postedFilter.price &&
    filteres.push({
      key: 'listing_price',
      value: isPriceSelectedAny(price.price[0])
        ? ['0']
        : [price?.price[0], price?.price[1]],
      type: isPriceSelectedAny(price.price[0]) ? 'greater' : 'between',
    })
  const homeFilter = () =>
    currentFilters.homeType &&
    filteres.push({
      key: 'home_type',
      value: [currentFilters.homeType],
      type: 'exact',
    })

  const isAreaSelectedAny = (area: string) => area === 'Any'
  const areaFilter = (area: any) =>
    postedFilter.squareFoot &&
    filteres.push({
      key: 'size',
      value: isAreaSelectedAny(area?.area[0])
        ? ['0']
        : [area?.area[0], area?.area[1]],
      type: isAreaSelectedAny(area?.area[0]) ? 'greater' : 'between',
    })
  const searchProspect = (ourRequest?: any) => {
    setbuttonDisabled(true)
    const localPriceNArea = {
      price: postedFilter?.price?.replaceAll(' ', '').split('-') || range.price,
      area:
        postedFilter?.squareFoot?.replaceAll(' ', '').split('-') || range.area,
    }
    setRange({
      price: postedFilter?.price?.replaceAll(' ', '').split('-') || range.price,
      area:
        postedFilter?.squareFoot?.replaceAll(' ', '').split('-') || range.area,
    })
    // const realPrice = price.split('-')

    // filteres.push({
    //   key: 'beds',
    //   value: [postedFilter.bed],
    //   type: 'greater',
    // })
    bedFilter()
    bathFilter()
    areaFilter(localPriceNArea)
    priceFilter(localPriceNArea)
    homeFilter()

    setdataObj({
      exclude: buzzExcluded,
      // search: locations[0],
      bounding_box: locations?.[0]?.bounding_box,
      filters: filteres,
    })
    if (
      currentFilters.bath ||
      currentFilters.bed ||
      currentFilters.price ||
      currentFilters.homeType ||
      currentFilters.squareFoot
    ) {
      setLoading(true)
      setbuttonDisabled(true)

      postFYP({
        exclude: buzzExcluded,
        // search: locations[0],
        filters: filteres,
        bounding_box: locations[0].bounding_box,
        ourRequest,
      })
        .then(async (res: any) => {
          setbuttonDisabled(false)
          setFilteres([])
          const lisitingsData = []
          const listingCounts = { count: 0 }
          if (res?.data) {
            // lisitingsData.push(...res?.data?.listings)
            const decryptedData = await getDecryptedData(res?.data?.response)
            // const parseData = JSON.parse(decryptedData).listings

            lisitingsData.push(...decryptedData.listings)

            if (res.data) {
              setIsClicked({ ...isClicked, isLoaded: true })
              setlistingArea(lisitingsData)
              setSelectedListings(listingCounts.count)
            }
            // }
          } else {
            setFilteres([])
            setlistingArea([])
          }
        })
        .catch((err: any) => {
          setbuttonDisabled(false)
          setLoading(false)
        })
        .finally(() => {
          setbuttonClicked(false)
          setisSubmitAreas(false)
          setLoading(false)
          // dispatch(setSidebarLens(!openSideBar))
          // if (openSideBar) dispatch(setSidebarLens(false))
          if (!locations.length) dispatch(setSidebarLens(true))
        })
    }
  }

  useEffect(() => {
    if (locations.length < 1) {
      setCurrentFilters({
        bed: null,
        bath: null,
        price: null,
        squareFoot: null,
        homeType: null,
      })
      setPostedFilter({
        bed: null,
        bath: null,
        price: null,
        squareFoot: null,
        homeType: null,
      })
      setIsClicked({ ...isClicked, isCancel: false, isLoaded: false })
      setlistingArea([])
    }
  }, [locations])

  useEffect(() => {
    activeBuzzFilters.forEach((item) => AllBuzz.push(item.label))
    const Buzz = ['High buzz', 'Warm buzz', 'Medium buzz', 'Low buzz']
    const data = Buzz.filter(function (v: any) {
      return !AllBuzz.includes(v)
    })
    setbuzzExcluded(data)
    const revData = data.reverse()
  }, [activeBuzzFilters])
  useEffect(() => {
    if (hasLocations) {
      getMapListings(locations).then((res) => {
        if (res.data) {
          setFeatures(res.data.listings)
        }
      })
    } else {
      setFeatures([])
      // setCurrentFilters([])
    }
  }, [hasLocations, locations])
  const filteredFeatures = Object.keys(filterSelections(activeFilters)).length
    ? filterFeatures(features, filterSelections(activeFilters))
    : features

  useEffect(() => {
    const ourRequest = axios.CancelToken.source() // <-- 1st step
    searchProspect(ourRequest)
    return () => {
      ourRequest.cancel()
    }
  }, [buzzExcluded])

  const [modalOpen, setIsModalOpen] = useState({
    bedNBath: false,
    price: false,
    squareFoot: false,
    homeType: false,
  })
  const initialModal = {
    bedNBath: false,
    price: false,
    squareFoot: false,
    homeType: false,
  }
  const [home, setHome] = useState<any>(null)

  const [currentFilters, setCurrentFilters] = useState({
    bed: null,
    bath: null,
    price: null,
    squareFoot: null,
    homeType: null,
  })
  const [postedFilter, setPostedFilter] = useState<any>({
    bed: null,
    bath: null,
    price: null,
    squareFoot: null,
    homeType: null,
  })
  const [isClicked, setIsClicked] = useState({
    isCancel: false,
    isLoaded: false,
  })

  // const { openSideBar } = useSelector(
  //   (state: TRootState) => state.centerLensReducer,
  // )

  // const toggleSidebar = () => {
  //   dispatch(setSidebarLens(!openSideBar))
  // }

  const searchButtonIsDisabled = () => {
    const { bath, bed, price, homeType, squareFoot } = currentFilters
    return ((!bath || !bed) && !price && !homeType && !squareFoot) || loading
  }

  const showProspectsModal = () => {
    const { bedNBath, price: p, squareFoot: sf, homeType: ht } = modalOpen
    const { bed, bath, price, squareFoot, homeType } = currentFilters
    if (
      locations.length >= 1 &&
      isClicked.isLoaded &&
      !bedNBath &&
      !p &&
      !sf &&
      !ht &&
      (bed || bath || price || squareFoot || homeType)
    ) {
      return true
    }
    return false
  }
  const getFiletersBoxBody = () => {
    let component = null

    if (activeToggleFeature === 'realtor_filter') {
      component = (
        <div className="realtor_container">
          {realtorData.map((realtor: TRealtorType, index) => {
            return (
              <Realtor
                key={index}
                subTitle="Top brokerages"
                title={realtor.value}
                onPageChange={onPageChange}
                currentPage={realtor.current_page}
                totalPages={realtor.total_pages}
                isDataFound={!!realtor?.data?.length}
              >
                {!realtor?.data.length ? (
                  <div style={{ textAlign: 'center' }}>
                    <Text on="grey-050" kind="subdued">
                      No Data Found of{' '}
                      <span
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {realtor.value}
                      </span>
                    </Text>
                  </div>
                ) : (
                  <Table
                    rows={realtor?.data}
                    columns={[
                      {
                        path: 'rank',
                        label: 'RANK',
                        alignment: 'center',
                        RowCell: (listing) => (
                          <b className="table_cell_content">{listing.id + 1}</b>
                        ),
                      },
                      {
                        path: 'brokrage',
                        label: 'BROKERAGE',
                        alignment: 'left',
                        RowCell: (listing) => <b>{listing.brokrage}</b>,
                      },
                      {
                        path: 'active_listings',
                        label: 'ACTIVE LISTINGS',
                        alignment: 'center',
                        onSort,
                      },
                    ]}
                  />
                )}
              </Realtor>
            )
          })}
        </div>
      )
    } else if (activeToggleFeature === 'buzz_filter') {
      component = (
        <LensFilterContainer>
          <BuzzScoreFilters
            filters={buzzFilters}
            activeFilters={activeBuzzFilters}
            onChange={setActiveBuzzFilters}
          />
        </LensFilterContainer>
      )
    }

    return component
  }

  const activeFilterOnClick = (filterType: string) => {
    return activeToggleFeature === filterType
      ? setActiveToggleFeature('')
      : setActiveToggleFeature(filterType)
  }

  return (
    <div className="SellYourListingFirst">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Sell Your Listing Faster"
              // subHeading="Use filters to view prospects seen in listings that match your criteria."
              subHeading={
                <>
                  Engage with <b>potential buyers</b> seen at listings that are
                  similiar to yours and advertise your listing
                </>
              }
            />
          }
          footer={<></>}
        >
          <div className="SellYourListingFirst__search_location">
            Search a Location
          </div>
          <div className="search-location">
            <div className="SellYourListingFirst__city_zipcode">
              e.g. City, Zip Code
            </div>
            {/* <div className="filter-location">
              <Select
                Height="38px"
                placeholder="Select"
                noCross
                width="9rem"
                maxHeight={200}
                selectedOption={{ label: locationFilter.location }}
                onChange={(value: SelectOptionType | undefined) =>
                  onChange('location', value?.label)
                }
                options={[{ label: 'City' }, { label: 'Zip Code' }]}
              />
            </div> */}
          </div>
          {modalOpen.bedNBath ? (
            <div className="SellYourListingFirst__sideFilter bedrooms">
              <BedroomNBathFilter
                currentFilters={currentFilters}
                setBed={setCurrentFilters}
                setBath={setCurrentFilters}
                modalOpen={modalOpen}
                setIsModalOpen={setIsModalOpen}
                postedFilters={postedFilter}
                setPostedFilter={setPostedFilter}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            </div>
          ) : (
            ''
          )}
          {modalOpen.homeType ? (
            <div className="SellYourListingFirst__sideFilter home_type">
              <HomeTypeFilter
                currentFilters={currentFilters}
                setHome={setCurrentFilters}
                modalOpen={modalOpen}
                setIsModalOpen={setIsModalOpen}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            </div>
          ) : (
            ''
          )}

          {modalOpen.price ? (
            <div className="SellYourListingFirst__sideFilter price">
              <PriceFilter
                currentFilters={currentFilters}
                setPrice={setCurrentFilters}
                modalOpen={modalOpen}
                setIsModalOpen={setIsModalOpen}
                postedFilters={postedFilter}
                setPostedFilter={setPostedFilter}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            </div>
          ) : (
            ''
          )}
          {modalOpen.squareFoot ? (
            <div className="SellYourListingFirst__sideFilter sqft">
              <SquareFootFilter
                currentFilters={currentFilters}
                postedFilters={postedFilter}
                setPostedFilter={setPostedFilter}
                setSquareFeet={setCurrentFilters}
                modalOpen={modalOpen}
                setIsModalOpen={setIsModalOpen}
                setIsClicked={setIsClicked}
                isClicked={isClicked}
              />
            </div>
          ) : (
            ''
          )}
          <div className="SellYourListingFirst__sidebar-content">
            {locations.length < 1 ? <MapSearch onSelect={addLocation} /> : ''}
            {hasLocations && (
              <>
                {locations.length < 1 ? <Spacer height="2rem" /> : ''}
                <LocationLabels
                  loading={loading}
                  locations={locations}
                  onRemove={(rest: any) => {
                    removeLocation(rest)
                    setIsModalOpen({
                      bedNBath: false,
                      price: false,
                      squareFoot: false,
                      homeType: false,
                    })
                  }}
                />
                {locations.length < 1 ? <Spacer height="2rem" /> : ''}
                <Text on="white" size="m" weight="bold">
                  <span className="filters_prospects">Filters:</span>
                </Text>

                <div>
                  <Button
                    kind="default"
                    UNSAFE_className={`first_filter_button ${
                      currentFilters.bed && currentFilters.bath
                        ? 'SellYourListingFirst__sidebarFiltersButton'
                        : 'SellYourListingFirst__sidebarFiltersUnselectedButton'
                    }`}
                    size="l"
                    width="100%"
                    onClick={() => {
                      setIsModalOpen({ ...initialModal, bedNBath: true })
                    }}
                  >
                    {currentFilters.bed && currentFilters.bath
                      ? `${currentFilters.bed} Bed/${currentFilters.bath} Bath`
                      : 'Bed/Bath'}
                  </Button>
                  <Button
                    kind="default"
                    UNSAFE_className={
                      currentFilters.price
                        ? 'SellYourListingFirst__sidebarFiltersButton'
                        : 'SellYourListingFirst__sidebarFiltersUnselectedButton'
                    }
                    onClick={() => {
                      setIsModalOpen({ ...initialModal, price: true })
                    }}
                    size="l"
                    width="100%"
                  >
                    {currentFilters.price ? `${currentFilters.price}` : 'Price'}
                  </Button>
                  <Button
                    kind="default"
                    UNSAFE_className={
                      currentFilters.squareFoot
                        ? 'SellYourListingFirst__sidebarFiltersButton'
                        : 'SellYourListingFirst__sidebarFiltersUnselectedButton'
                    }
                    size="l"
                    width="100%"
                    onClick={() => {
                      setIsModalOpen({ ...initialModal, squareFoot: true })
                    }}
                  >
                    {currentFilters.squareFoot
                      ? `${currentFilters.squareFoot}`
                      : 'Squarefoot'}
                  </Button>
                  <Button
                    kind="default"
                    UNSAFE_className={
                      currentFilters.homeType
                        ? 'SellYourListingFirst__sidebarFiltersButton'
                        : 'SellYourListingFirst__sidebarFiltersUnselectedButton'
                    }
                    size="l"
                    width="100%"
                    onClick={() => {
                      setIsModalOpen({ ...initialModal, homeType: true })
                    }}
                  >
                    {currentFilters.homeType || 'Home Type'}
                  </Button>
                  <Button
                    UNSAFE_style={{
                      // backgroundColor: '#007b94',
                      color: '#2B1F0A',
                      border: 'none',
                      borderRadius: '10px',
                      fontSize: '16px',
                      fontWeight: '700',
                      lineHeight: '22px',
                      fontFamily: 'Open Sans',
                      background: '#FCD917',
                    }}
                    UNSAFE_className="MyListingsLens__FooterBtn"
                    kind="default"
                    size="l"
                    width="100%"
                    onClick={() => {
                      searchProspect()
                    }}
                    disabled={
                      searchButtonIsDisabled() ||
                      modalOpen.bedNBath ||
                      modalOpen.homeType ||
                      modalOpen.price ||
                      modalOpen.squareFoot
                    }
                    loading={loading}
                    // disabled={
                    //   !currentFilters.bath &&
                    //   !currentFilters.bed &&
                    //   !currentFilters.price &&
                    //   !currentFilters.homeType &&
                    //   !currentFilters.squareFoot
                    //   //    ||
                    //   // buttonDisabled
                    // }
                  >
                    Search
                  </Button>
                </div>
              </>
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>

      {/* <div className="MyListingsLens__filter">
          <LensFilterContainer>
            <BuzzScoreFilters
              filters={buzzFilters}
              activeFilters={activeBuzzFilters}
              onChange={setActiveBuzzFilters}
            />
          </LensFilterContainer>
        </div> */}
      {locations.length >= 1 && isClicked.isLoaded && (
        // <div className={`${!openSideBar ? '' : 'MyListingsLens__hide'}`}>
        <div>
          <div
            className="SellYourListingFirst__rightTop"
            style={{ display: 'flex' }}
          >
            <Button
              UNSAFE_className="ExpandYourMarketing__rightTopButton"
              UNSAFE_style={{
                background:
                  activeToggleFeature === 'buzz_filter' ? '#FFB708' : '',
                // color: activeToggleFeature === 'buzz_filter' ? 'white' : '',
              }}
              onClick={() => activeFilterOnClick('buzz_filter')}
            >
              Buzz Filter
            </Button>
            <Button
              UNSAFE_className="ExpandYourMarketing__rightTopButton"
              UNSAFE_style={{
                background:
                  activeToggleFeature === 'realtor_filter' ? '#FFB708' : '',
                // color: activeToggleFeature === 'realtor_filter' ? 'white' : '',
              }}
              onClick={() => activeFilterOnClick('realtor_filter')}
            >
              Realtor
            </Button>
          </div>
          <div className="MyListingsLens__filter">{getFiletersBoxBody()}</div>
        </div>
      )}

      {/* buzz score toggle */}
      {/* <div
        style={{
          position: 'absolute',
          right: '50px',
          zIndex: 999,
          bottom: '44px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p style={{ fontSize: '10px', marginRight: '5px' }}>Show Filters</p>
        <FontAwesomeIcon
          style={{ marginRight: '1.5rem', fontSize: '32px' }}
          size="1x"
          color={!openSideBar ? '#FFB708' : '#FF0000'}
          icon={!openSideBar ? faToggleOn : faToggleOff}
          onClick={toggleSidebar}
        />
      </div> */}
      {/* buzz score toggle */}

      {/* totalProspects && */}
      {showProspectsModal() ? (
        // <div className="prospects_wrapper">
        <LensSidebarFooter>
          <AudienceResultsCard>
            <div className="MyListingsLens__score">
              <div className="MyListingsLens__scoreLeft">
                <p className="prospects_count">
                  {totalProspects.toLocaleString()}
                </p>
              </div>
              <div className="MyListingsLens__scoreRight">
                <b
                  className="prospects_observed"
                  style={{
                    fontSize: '22px',
                    fontWeight: 'normal',
                  }}
                >
                  Prospects observed
                </b>
              </div>
            </div>
            {totalProspects === 0 ? (
              <div>
                <p className="no_footfall">
                  No footfall traffic has been seen in this area.
                </p>
              </div>
            ) : null}
            <SaveAudienceWizard
              type="Sell Your Listing Faster"
              dataObj={dataObj}
              onSaveAndClose={(audienceDetails) => {
                resetStates()
              }}
              totalProspects={totalProspects}
              listing={filteredFeatures}
            />
          </AudienceResultsCard>
        </LensSidebarFooter>
      ) : (
        // </div>
        ''
      )}

      <div
        className="SellYourListingFirst__map"
        aria-hidden="true"
        onClick={() => {
          setIsModalOpen(initialModal)
        }}
      >
        {/* {loading && <LinearLoader />} */}

        <Map
          features={listingArea}
          locations={[]}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          popup={(f) => <MapPopup feature={f} />}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className="SellYourListingFirst__filter-selection"
            style={{
              top: `${searchRect?.bottom}px`,
              left: `${searchRect?.left}px`,
            }}
          >
            {Object.keys(filters).map((key: string) => (
              <Checkbox
                key={filters[key].label}
                checked={isActiveFilter(key)}
                onChange={(checked) => handleFilterActivation(key, checked)}
                label={filters[key].label}
              />
            ))}
          </div>,
          document.body,
        )}
    </div>
  )
}
