import { Modal, Text } from '@eltoro-ui/components'
import { useAsync } from '@eltoro-ui/hooks'
import {
  faExclamationTriangle,
  faHome,
  faMapPin,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { Loading, OnboardingModal, PageHeader } from 'Components'
import {
  FinishedCampaigns,
  SavedAudiences,
} from 'Pages/CampaignDashboard/components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { setUnReadNotification } from 'Redux/actions'
import { getUnReadNotificationsCounts, subscribeTopic } from 'Requests'
import { getCompaignGraphData } from 'Requests/Request_Methods/campaignMethods'
import { NotificationType, TRootState, TUnReadTotalCounts } from 'types'
import { ActiveModelSerializer } from 'miragejs'
import toast from 'react-hot-toast'
import { VotingModal } from 'Components/VotingModal'

import campaignsLogo from '../../assets/Images/campaigns.png'
import Talktoyoursphere from '../../assets/Images/talktoyoursphere.png'
import Growyourspehere from '../../assets/Images/growyourspehere.png'
import Beatthemarket from '../../assets/Images/beatthemarket.png'
import Humbleberg from '../../assets/Images/humbleberg.png'
import Contacticons from '../../assets/Images/contacticons.png'

import listingLogo from '../../assets/Images/listing.png'
import prospects1 from '../../assets/Images/prospects_lg_1.png'
import prospects2 from '../../assets/Images/prospects_lg_2.png'
import prospects3 from '../../assets/Images/prospects_lg_3.png'
// import prospects4 from '../../assets/Images/prospects_lg_4.png'
import prospects5 from '../../assets/Images/prospects_lg_5.png'
import prospects6 from '../../assets/Images/prospects_lg_6.png'
import Marketacceleration from '../../assets/Images/Marketacceleration.png'
import Listingacceleration from '../../assets/Images/Listingacceleration.png'
import Activeagents from '../../assets/Images/Activeagents.png'
import Newbuyersarea from '../../assets/Images/Newbuyersarea.png'

import savedAudienceLogo from '../../assets/Images/saved-audience.png'
import ContactsSeen from './_Components/ContactsSeenInMarket/ContactsSeen'
import ViewMarketStats from './_Components/ViewMarketActivity/ViewMarketStats'
import './Dashboard.scss'
import CardBox from './_Components/Cardbox/CardBox'
import { CampaignProgress } from './_Components/CampaignProgress/CampaignProgress'
import { NotificationContainer } from './_Components/NotificationContainer/NotificationContainer'

export const DashboardPage = () => {
  // Modal currently only opens on button click...when we have a global user object can open if user is empty
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [TalkModal, setTalkModal] = useState<boolean>(false)
  const [growSphereModal, setGrowSphereModal] = useState<boolean>(false)
  const [beattheMorketModal, setBeattheMorketModal] = useState(false)
  const [humblebergeModal, setHumblebergeModal] = useState(false)
  const [contactSeenModal, setContactSeenModal] = useState(false)
  const [marketAccModal, setMarketAccModal] = useState(false)
  const [listingAccModal, setListingAccModal] = useState(false)
  const [activeAgentModal, setActiveAgentModal] = useState(false)
  const [newBuyers, setNewBuyers] = useState(false)

  const [infoStats, setInfoStats] = useState<any>({
    total_audience: 0,
    total_campaigns: 0,
    total_mylisting: 0,
    active_campaigns: {},
    finished_campaign: {},
  })
  const history = useHistory()
  const { userData } = useSelector((state: TRootState) => state.userReducer)
  const { userNotifications } = useSelector(
    (state: any) => state.notificationsReducer,
  )

  useEffect(() => {
    function completeProfile() {
      if (userData?.is_compelete === 0) {
        setModalOpen(true)
      } else if (
        userData?.user_role !== 'super_admin' &&
        userData?.is_compelete === 1
        // &&
        // userData?.chargebee_status !== 'subscribed'
      ) {
        history.push('/dashboard')
        // history.push('/profile')
      }
    }
    setTimeout(completeProfile, 0)
  }, [])

  const user = localStorage.getItem('firebase_notification')

  useEffect(() => {
    if (userNotifications && user) {
      subscribeTopic(user, userNotifications)
    }
  }, [userNotifications, user])

  const { value: dashboardInfo } = useAsync(() => getCompaignGraphData())
  const [counts, setCounts] = useState<TUnReadTotalCounts[]>([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (dashboardInfo?.data) {
      setInfoStats(dashboardInfo.data)
    }
  }, [dashboardInfo])

  useEffect(() => {
    if (counts.length) {
      dispatch(setUnReadNotification(true))
    } else {
      dispatch(setUnReadNotification(false))
    }
  }, [counts])

  useEffect(() => {
    getUnReadNotificationsCounts()
      .then((res) => {
        setCounts(res.data)

        // _getNotifications(src)
      })
      .catch((err) => {
        toast.error(err)
        // setIsLoading(false)
      })
  }, [])
  const getIcon = (type: 'System' | 'My Listing' | 'Location' | 'Friends') => {
    if (type === 'System') return faExclamationTriangle
    if (type === 'My Listing') return faHome
    if (type === 'Location') return faMapPin
    if (type === 'Friends') return faUser
    return faExclamationTriangle
  }

  return (
    <div className="app_dashboard_container">
      {/* <PageHeader
        UNSAFE_CLASSNAME="dashboard-header"
        title=""
        // subTitle
        subTitle=""
      /> */}
      <div>
        <div className="DashboardPage">
          {modalOpen && <OnboardingModal setModalOpen={setModalOpen} />}

          {/* <div className="DashboardPage__top_three_cards_container">
            <CardBox
              textLogo={campaignsLogo}
              text="Total Number of Campaign List"
              textValue={infoStats.total_campaigns}
            />

            <CardBox
              textLogo={savedAudienceLogo}
              text="Total Number of Saved Audience"
              textValue={infoStats.total_audience}
            />

            <CardBox
              textLogo={listingLogo}
              text="Total Number of My Listing"
              textValue={infoStats.total_mylisting}
            />
          </div> */}

          <div className="DashboardPage__lenses">
            <div className="DashboardPage__topLenses">
              <Link
                to="/prospects/sell-your-listing-faster"
                className="DashboardPage__topLensesLink"
              >
                <img
                  src={prospects2}
                  alt=""
                  // role="button"
                  // onClick={() => {
                  //   history.push('/')
                  // }}
                />
                <Text
                  on="white"
                  size="s"
                  UNSAFE_className="DashboardPage__topLensesText"
                >
                  Sell Your Listing Faster
                </Text>
              </Link>
            </div>
            <div className="DashboardPage__topLenses">
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="DashboardPage__topLensesLink"
                onClick={() => {
                  setTalkModal(true)
                  // toast.success('This feature is coming soon')
                }}
              >
                <img
                  src={prospects1}
                  alt=""
                  // role="button"
                  // onClick={() => {
                  //   setTalkModal(true)
                  // }}
                />
                <Text
                  on="primary-700"
                  size="s"
                  UNSAFE_className="DashboardPage__topLensesText lens_heading"
                >
                  Talk To Your Sphere
                </Text>
              </Link>
            </div>
            {TalkModal && (
              <Modal offClick={() => setTalkModal(false)}>
                <VotingModal
                  handleModel={() => setTalkModal(false)}
                  icons={Talktoyoursphere}
                  title="Talk To Your Sphere"
                  subTitle="Engage with potential buyers seen at listings that are similiar to
                  yours and advertise your listings."
                />
              </Modal>
            )}
            <div className="DashboardPage__topLenses">
              <Link
                // to="/prospects/grow-your-sphere"
                to="/dashboard"
                className="DashboardPage__topLensesLink"
                onClick={() => {
                  // toast.success('This feature is coming soon')
                  setGrowSphereModal(true)
                }}
              >
                {/* <span
                role="link"
                onClick={() => {
                  toast.success('This feature is coming soon')
                }}
                className="DashboardPage__topLensesLink"
                style={{ cursor: 'pointer' }}
              > */}
                <img
                  src={prospects3}
                  alt=""
                  // width={130}
                  // height={130}
                  // role="button"
                  // onClick={() => {
                  //   history.push('/')
                  // }}
                />
                <Text
                  on="white"
                  size="s"
                  UNSAFE_className="DashboardPage__topLensesText lens_heading"
                >
                  Grow Your Sphere
                </Text>
                {/* </span> */}
              </Link>
            </div>
            {growSphereModal && (
              <Modal offClick={() => setGrowSphereModal(false)}>
                <VotingModal
                  handleModel={() => setGrowSphereModal(false)}
                  icons={Growyourspehere}
                  title="Grow Your Sphere"
                  subTitle="Advertise to potential buyers seen at all available listings in a specific geographic area."
                />
              </Modal>
            )}

            <div className="DashboardPage__topLenses">
              <Link
                // to="/prospects/beat-the-market"
                to="/dashboard"
                onClick={() => {
                  // toast.success('This feature is coming soon')
                  setBeattheMorketModal(true)
                }}
                className="DashboardPage__topLensesLink"
              >
                <img
                  src={prospects5}
                  alt=""
                  // role="button"
                  // onClick={() => {
                  //   history.push('/')
                  // }}
                />
                <Text
                  on="white"
                  size="s"
                  UNSAFE_className="DashboardPage__topLensesText lens_heading"
                >
                  Beat The Market
                </Text>
              </Link>
            </div>
            {beattheMorketModal && (
              <Modal offClick={() => setBeattheMorketModal(false)}>
                <VotingModal
                  handleModel={() => setBeattheMorketModal(false)}
                  icons={Beatthemarket}
                  title="Beat The Market"
                  subTitle="Advertise to potential buyers that have been seen in market at active and inactive listings."
                />
              </Modal>
            )}
            <div className="DashboardPage__topLenses">
              <Link
                // to="/prospects/humble-brag"
                to="/dashboard"
                className="DashboardPage__topLensesLink"
                onClick={() => {
                  // toast.success('This feature is coming soon')
                  setHumblebergeModal(true)
                }}
              >
                <img
                  src={prospects6}
                  alt=""
                  // role="button"
                  // onClick={() => {
                  //   history.push('/')
                  // }}
                />
                <Text
                  on="white"
                  size="s"
                  UNSAFE_className="DashboardPage__topLensesText lens_heading"
                >
                  Humble Brag
                </Text>
              </Link>
            </div>
            {humblebergeModal && (
              <Modal offClick={() => setHumblebergeModal(false)}>
                <VotingModal
                  handleModel={() => setHumblebergeModal(false)}
                  icons={Humbleberg}
                  title="Humble Brag"
                  subTitle="Engage with potential sellers by advertising to the neighbors around your listings, active and inactive."
                />
              </Modal>
            )}
          </div>

          {/* {infoStats?.active_campaigns &&
        !Object.entries(infoStats?.active_campaigns).length ? (
          <Loading />
        ) : (
          <div className="DashboardPage__bottom_two_cards_container dahboard-charts">
            {/* <GridBox
              gridTemplateColumns="1fr 1fr "
              gap="1rem"
              paddingTop="1rem"
              UNSAFE_className="dahboard-charts"
              flexGrow={0}
            > */}
          {/* <SavedAudiences
            <SavedAudiences
              data={{
                total: infoStats?.active_campaigns?.total_campaigns,
                used: infoStats?.active_campaigns?.active_campaign,
              }}
              // textTotal="ACTIVE OUT"
              // textShow="# Of Active Campaigns"
              textShow="Campiagn Status"
            /> */}

          {/* <FinishedCampaigns data={infoStats?.finished_campaign} /> */}
        </div>
      </div>
      <div className="DashboardPage">
        <div className="DashboardPage__top_three_cards_container">
          {/* <div className="col-md-4"> */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridGap: '1rem',
              gap: '1rem',
            }}
          >
            <CampaignProgress
              data={{
                active: dashboardInfo?.data?.campaign_count?.active,
                complete: dashboardInfo?.data?.campaign_count?.completed,
                draft: dashboardInfo?.data?.campaign_count?.draft,
              }}
              textTotal=""
              textShow="Campaign Status"
            />
            <ContactsSeen
              textLogo={listingLogo}
              text="Contacts Seen in Market"
              textValue={70}
              onClick={() => {
                setContactSeenModal(true)
                // return textValue === 0 ? history.push('/my-sphere') : () => {}
              }}
            />
          </div>
          {contactSeenModal && (
            <Modal offClick={() => setContactSeenModal(false)}>
              <VotingModal
                handleModel={() => setContactSeenModal(false)}
                icons={Contacticons}
                title="Contacts seen in the Market"
                subTitle="The number of total contacts will be observed at listings within the mentioned time frame in your market that is collective SUM of contacts seen in the market over 7 day period."
              />
            </Modal>
          )}

          <div className="DashboardPage__activity_list">
            <div className="DashboardPage__activity_components">
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="Market_acceleration_status"
                onClick={() => {
                  setMarketAccModal(true)
                  // toast.success('This feature is coming soon')
                }}
              >
                <ViewMarketStats
                  textValue={5}
                  heading="MARKET ACCELERATION"
                  sub_heading="REGIONAL ACTIVITY"
                />
              </Link>
              {marketAccModal && (
                <Modal offClick={() => setMarketAccModal(false)}>
                  <VotingModal
                    handleModel={() => setMarketAccModal(false)}
                    icons={Marketacceleration}
                    title="Market Acceleration"
                    subTitle="[Percentage] Foot traffic momentum leading into the next reporting period based on all listings in the market."
                  />
                </Modal>
              )}
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="Market_acceleration_status"
                onClick={() => {
                  setListingAccModal(true)
                  // toast.success('This feature is coming soon')
                }}
              >
                <ViewMarketStats
                  textValue={20}
                  heading="LISTING ACCELERATION"
                  sub_heading="REGIONAL ACTIVITY"
                />
              </Link>
              {listingAccModal && (
                <Modal offClick={() => setListingAccModal(false)}>
                  <VotingModal
                    handleModel={() => setListingAccModal(false)}
                    icons={Listingacceleration}
                    title="Listing Acceleration"
                    subTitle="[Percentage] Foot traffic momentum leading into the next reporting period based on user’s active listings."
                  />
                </Modal>
              )}
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="Market_acceleration_status"
                onClick={() => {
                  setActiveAgentModal(true)
                  // toast.success('This feature is coming soon')
                }}
              >
                <ViewMarketStats
                  textValue={-1}
                  heading="ACTIVE AGENTS IN AREA"
                  sub_heading="COMPETITION INDEX"
                />
              </Link>
              {activeAgentModal && (
                <Modal offClick={() => setActiveAgentModal(false)}>
                  <VotingModal
                    handleModel={() => setActiveAgentModal(false)}
                    icons={Activeagents}
                    title="Active Agents in Your Area"
                    subTitle="A distinct count of list/buy agent MLS IDs in the agent’s area."
                  />
                </Modal>
              )}
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="Market_acceleration_status"
                onClick={() => {
                  setNewBuyers(true)
                  // toast.success('This feature is coming soon')
                }}
              >
                <ViewMarketStats
                  textValue={-9}
                  heading="NEW BUYERS IN AREA"
                  sub_heading="FOOTFALL TRAFFIC"
                />
              </Link>
              {newBuyers && (
                <Modal offClick={() => setNewBuyers(false)}>
                  <VotingModal
                    handleModel={() => setNewBuyers(false)}
                    icons={Newbuyersarea}
                    title="New Buyers in Area"
                    subTitle="Percent of new buyers the user targeted on an ad campaign in the reporting period."
                  />
                </Modal>
              )}
            </div>
          </div>

          <NotificationContainer />

          {/* <Contact /> */}

          {/* </GridBox> */}
          {/* </GridBox> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}
