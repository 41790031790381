import React from 'react'
import { Text } from '@eltoro-ui/components'
import logobefore from '../../assets/Images/honeycomb.svg'
import logoafter from '../../assets/Images/honeycomb_left.svg'

import './TermsOfUse.scss'

export const TermOfUse = () => {
  return (
    <div className="terms_of_use_container">
      <img
        src={logobefore}
        alt="beewo-logo-back"
        className="img_top"
        id="logoBefore"
      />

      <Text on="white" weight="bold" UNSAFE_className="heading">
        Beewo Terms of Use
      </Text>

      <Text on="white" weight="bold" UNSAFE_className="title">
        1. TERMS
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        By accessing this Mobile Application (App), you are agreeing to be bound
        by these App Terms and Conditions of Use, all applicable laws and
        regulations, and agree that you are responsible for compliance with any
        applicable local laws. If you do not agree with any of these terms, you
        are prohibited from using or accessing this App. The materials contained
        in this App are protected by applicable copyright and trademark law.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        2. USE LICENSE
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        Permission is granted to temporarily download one copy of the materials
        (information or software) on beewo.com&apos;s App for personal,
        non-commercial transitory viewing only. This is the grant of a license,
        not a transfer of title, and under this license you may not: modify or
        copy the materials; use the materials for any commercial purpose, or for
        any public display (commercial or non-commercial); attempt to decompile
        or reverse engineer any software contained on beewo.com&apos;s App;
        remove any copyright or other proprietary notations from the materials;
        or transfer the materials to another person or mirror the materials on
        any other server.
        <br />
        <br />
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by beewo.com at any time. Upon
        terminating your viewing of these materials or upon the termination of
        this license, you must destroy any downloaded materials in your
        possession whether in electronic or printed format.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        3. DISCLAIMER
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        The materials on beewo.com&apos;s App are provided &quot;as is&quot;.
        beewo.com makes no warranties, expressed or implied, and hereby
        disclaims and negates all other warranties, including without
        limitation, implied warranties or conditions of merchantability, fitness
        for a particular purpose, or non-infringement of intellectual property
        or other violation of rights. Further, beewo.com does not warrant or
        make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on its App or otherwise relating
        to such materials or on any sites linked to by this App.{' '}
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        4. LIMITATIONS
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        In no event shall beewo.com or its suppliers be liable for any damages
        (including, without limitation, damages for loss of data or profit, or
        due to business interruption) arising out of the use or inability to use
        the materials on beewo.com&apos;s App, even if beewo.com or a beewo.com
        authorized representative has been notified orally or in writing of the
        possibility of such damage. Because some jurisdictions do not allow
        limitations on implied warranties, or limitations of liability for
        consequential or incidental damages, these limitations may not apply to
        you.{' '}
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        5. REVISIONS AND ERRATUM
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        The materials appearing on beewo.com&apos;s App could include technical,
        typographical, or photographic errors. beewo.com does not warrant that
        any of the materials on its App are accurate, complete, or current.
        beewo.com may make changes to the materials contained on its App at any
        time without notice. beewo.com does not, however, make any commitment to
        update the materials.{' '}
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        6. LINKS
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        beewo.com has not reviewed all of the sites linked to in its App and is
        not responsible for the contents of any such linked site. The inclusion
        of any link does not imply endorsement by beewo.com of the website. Use
        of any such linked website is at the user&apos;s own risk.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        7. APP TERMS OF USE MODIFICATIONS
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        beewo.com may revise these terms of use for its App at any time without
        notice. By using this App you are agreeing to be bound by the then
        current version of these Terms and Conditions of Use.
      </Text>

      <Text on="white" weight="bold" size="xl" UNSAFE_className="title">
        8. GOVERNING LAW
      </Text>
      <Text on="grey-500" size="xl" UNSAFE_className="description">
        Any claim relating to beewo.com&apos;s App shall be governed by the laws
        of the State of Kentucky without regard to its conflict of law
        provisions.
      </Text>

      <img
        src={logoafter}
        alt="beewo-logo-back"
        className="img_bottom"
        id="logoAfter"
      />
    </div>
  )
}
