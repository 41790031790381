import React, { Component, ReactNode } from 'react'
import { Text } from '@eltoro-ui/components'
import './ErrorBoundary.scss'
import logo from '../../Images/Icon_color@2x.png'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // This can be used to log the error when we get logging capability
  public componentDidCatch(error: Error, errorInfo: any) {
    console.error('Through error:', error, errorInfo)
    // throw new Error(errorInfo)
  }

  public render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return (
        <div className="ErrorBoundary">
          <div className="ErrorBoundary__graphic">
            <img src={logo} alt="beewo-logo" />
          </div>
          <div>
            <Text on="white">Sorry... there was an error</Text>
          </div>
          <br />
          <div>
            <Text on="white">
              If this problem persists, please let us know at support@beewo.com
            </Text>
          </div>
        </div>
      )
    }
    return children
  }
}

export default ErrorBoundary
