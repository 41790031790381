import { BaseBlockType } from '../Typings'

// these are default styles for each block...
// ...a block's width and height is determined once it is applied to an artboard...
// ...minMaxWidth/Height give a range so the block can best fit the artboard it is applied to...
// ...as seen in `createBlockPosDims` in block-helpers.ts
const actionButton: BaseBlockType = {
  kind: 'actionButton',
  label: 'Button',
  textContent: {
    label: {
      value: 'Donate',
      color: '#ffffff',
      fontSize: 16,
      textAlign: 'center',
      fontStyle: 'bold',
      fontFamily: `'Monserrat', sans-serif`,
      textDecoration: '',
    },
  },
  shapeContent: {
    button: {
      color: '#ff0000',
      borderRadius: 10,
      mainColor: true,
    },
  },
  minMaxWidth: [80, 200],
  minMaxHeight: [40, 40],
}

const basicParagraph: BaseBlockType = {
  kind: 'basicParagraph',
  label: 'A basic paragraph',
  textContent: {
    paragraphText: {
      value: 'My basic paragraph text...',
      color: '#000000',
      fontSize: 12,
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: `'Monserrat', sans-serif`,
      textDecoration: '',
    },
  },
  minMaxWidth: [100, 300],
  minMaxHeight: [40, 300],
}

const politicalHeader: BaseBlockType = {
  kind: 'politicalHeader',
  label: 'Political Header',
  textContent: {
    topText: {
      value: 'Elect',
      color: '#000000',
      fontSize: 15,
      textAlign: 'left',
      fontStyle: 'normal',
      fontFamily: `'Monserrat', sans-serif`,
      textDecoration: '',
      mainColor: true,
    },
    middleText: {
      value: `Name here`,
      color: '#000000',
      fontSize: 20,
      textAlign: 'center',
      fontStyle: 'bold',
      fontFamily: `'Monserrat', sans-serif`,
      textDecoration: '',
      mainColor: true,
    },
    bottomText: {
      value: 'for Position',
      color: '#000000',
      fontSize: 15,
      textAlign: 'right',
      fontStyle: 'normal',
      fontFamily: `'Monserrat', sans-serif`,
      textDecoration: '',
      mainColor: true,
    },
  },
  minMaxWidth: [100, 300],
  minMaxHeight: [40, 200],
}

const squareLogo: BaseBlockType = {
  kind: 'squareLogo',
  label: 'Square logo',
  textContent: {
    centerText: {
      value: 'Candidate Name',
      color: '#000000',
      fontSize: 20,
      textAlign: 'center',
      fontStyle: 'bold',
      fontFamily: `'Monserrat', sans-serif`,
      textDecoration: '',
      mainColor: true,
    },
    year: {
      value: '2024',
      color: '#000000',
      fontSize: 10,
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: `'Monserrat', sans-serif`,
      textDecoration: '',
      mainColor: true,
    },
  },
  shapeContent: {
    outsideBorder: {
      borderRadius: 3,
      stroke: {
        enabled: true,
        color: '#000000',
        width: 2,
        mainColor: true,
      },
    },
    icon: {
      color: '#ff0000',
    },
  },
  minMaxWidth: [100, 300],
  minMaxHeight: [40, 100],
}

const imageFrame: BaseBlockType = {
  kind: 'imageFrame',
  label: 'Image Frame',
  textContent: {},
  shapeContent: {
    frame: {
      borderRadius: 2,
      shape: 'circle',
      color: '',
      stroke: {
        enabled: true,
        color: '#fff',
        width: 3,
        mainColor: true,
      },
    },
  },
  minMaxWidth: [40, 150],
  minMaxHeight: [40, 150],
}

// default block exists as a fallback
export const defaultBlock: Partial<BaseBlockType> = {
  label: 'Default block',
  textContent: {
    default: {
      value: '',
      color: '#000000',
      fontSize: 12,
      textAlign: 'left',
      fontStyle: 'normal',
      fontFamily: 'Sans serif',
      textDecoration: '',
    },
  },
  shapeContent: {
    default: {
      borderRadius: 0,
      shadow: {
        enabled: false,
        color: '#000000',
        blur: 3,
        opacity: 0.5,
        offsetX: 3,
        offsetY: 3,
      },
      stroke: {
        enabled: false,
        color: '#000000',
        width: 2,
      },
      dash: [1, 2],
    },
  },
  minMaxHeight: [50, 100],
  minMaxWidth: [50, 100],
}

export const blocksBase = {
  actionButton,
  basicParagraph,
  squareLogo,
  politicalHeader,
  imageFrame,
}
