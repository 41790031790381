import { request } from 'https'
import {
  MapLocationType,
  APIFeatureType,
  ResponseType,
  FilterType,
  APIFeatureResponseType,
  TGetListings,
  TGetListingHistogramBodyType,
  TPutNotesByListingIdBodyType,
  TBuzzData,
  TMyZip,
  TRealtorRequestBody,
  TRealtorResponse,
  TAreaSearchListining,
  TRealtorActivity,
  TPostAdvertiseToAll,
} from 'types'
import requests from '../http'

export const getListings = async (
  body: TGetListings,
): Promise<ResponseType<APIFeatureResponseType>> => {
  const req = '/listings/my_listining'
  return requests.post(req, body)
}

export const getMyListings = async (
  page?: number | null,
  size?: number | null,
  search?: string | null,
  sort_key?: string | null,
  order?: string | null,
): Promise<ResponseType<APIFeatureResponseType>> => {
  const req = search
    ? `/listings/my_listining/list?page=${page}&size=${size}&search=${search}&${
        sort_key && `sort_key=${sort_key}&order=${order}`
      }`
    : `/listings/my_listining/list?page=${page}&size=${size}&${
        sort_key && `sort_key=${sort_key}&order=${order}`
      }`
  return requests.get(req)
}

export const getListingById = async (
  mls_id: string,
): Promise<ResponseType<APIFeatureType>> => {
  const res = await requests.get(`/listings/my_listining/details/${mls_id}`)
  return res
}

export const putNotesByListingId = async (
  mls_id: string,
  body: TPutNotesByListingIdBodyType,
): Promise<ResponseType<APIFeatureType>> => {
  const res = await requests.put(
    `/listings/my_listining/add_note/${mls_id}`,
    body,
  )
  return res
}

export const getListingHistogram = async (
  body: TGetListingHistogramBodyType,
): Promise<ResponseType<TBuzzData>> =>
  requests.post(`/listings/buzz_data`, body)

export const getCompListings = async (
  locations: MapLocationType[],
  skip?: number | null,
  limit?: number | null,
): Promise<ResponseType<APIFeatureResponseType>> => {
  const constructRequest = (location: MapLocationType) => {
    const south = parseFloat(location.bounds[0][0])
    const north = parseFloat(location.bounds[1][0])
    const east = parseFloat(location.bounds[1][1])
    const west = parseFloat(location.bounds[0][1])
    const geoParams = `/listings/layer?south=${south}&north=${north}&east=${east}&west=${west}`
    const paginationParams = `&skip=${skip}&limit=${limit}`
    return skip && limit ? geoParams + paginationParams : geoParams
  }
  const resArray = await Promise.all(
    locations.map((location: MapLocationType) =>
      requests.get(constructRequest(location)),
    ),
  )
  const listingsRes = resArray.reduce(
    (acc: APIFeatureResponseType, val: { data: APIFeatureResponseType }) => {
      return { ...val.data, listings: acc.listings.concat(val.data.listings) }
    },
    { listings: [] },
  )
  return { data: listingsRes }
}

export const getMapListings = async (
  locations: MapLocationType[],
  filters?: { [key: string]: FilterType },
  skip?: number | null,
  limit?: number | null,
): Promise<ResponseType<APIFeatureResponseType>> => {
  const constructRequest = (location: MapLocationType) => {
    const south = parseFloat(location.bounds[0][0])
    const north = parseFloat(location.bounds[1][0])
    const east = parseFloat(location.bounds[1][1])
    const west = parseFloat(location.bounds[0][1])
    const geoParams = `/listings/layer?south=${south}&north=${north}&east=${east}&west=${west}`
    const paginationParams = `&skip=${skip}&limit=${limit}`
    return skip && limit ? geoParams + paginationParams : geoParams
  }
  const resArray = await Promise.all(
    locations.map((location: MapLocationType) =>
      requests.get(constructRequest(location), filters),
    ),
  )
  const listingsRes = resArray.reduce(
    (acc: APIFeatureResponseType, val: { data: APIFeatureResponseType }) => {
      return { ...val.data, listings: acc.listings.concat(val.data.listings) }
    },
    { listings: [] },
  )
  return { data: listingsRes }
}

export const getMyZipCode = async (): Promise<ResponseType<TMyZip>> => {
  const res = await requests.get(`/listings/my-zip`)
  return res
}

export const getRealtorActivity = async (body: {
  search: TRealtorActivity
}): Promise<ResponseType<TRealtorResponse>> => {
  const res = await requests.post(`/listings/realtor-activity`, body)
  return res
}

export const postAdvertiseToAll = async (
  body: TPostAdvertiseToAll,
): Promise<ResponseType<TAreaSearchListining>> => {
  const res = await requests.post('/listings/advertise-to-all/listining', body)
  return res
}
