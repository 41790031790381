import React, { useEffect, useState, FC } from 'react'
import { Avatar, Breadcrumbs, Text } from '@eltoro-ui/components'
import { useSelector } from 'react-redux'
import { TRootState } from 'types'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { NotificationsTooltip } from 'Pages'
import notification from '../../assets/Images/notification.png'
import './TopHeaderWithBreadCrumb.scss'

export const TopHeaderWithBreadCrumb: FC<{
  SetIsNotificationToggleChange: (arg: boolean) => void
  isNotificationToggleChange: boolean
}> = ({ SetIsNotificationToggleChange, isNotificationToggleChange }) => {
  const { userData } = useSelector((state: TRootState) => state.userReducer)
  const Location = useLocation()

  const [notificationsToggle, setNotificationsToggle] = useState(false)
  const [breadCrumbPath, setBreadCrumbPath] = useState<any>([])
  const { isUnReadNotification } = useSelector(
    (state: TRootState) => state.notificationsReducer,
  )

  useEffect(() => {
    SetIsNotificationToggleChange(notificationsToggle)
  }, [notificationsToggle])

  useEffect(() => {
    setNotificationsToggle(isNotificationToggleChange)
  }, [isNotificationToggleChange])

  const getBreadCrumbPathFormat = (url: string) => {
    let output: any = url.split('/').filter((member) => member) // .filter to get rid of the empty strings
    const regex = new RegExp(/[0-9]/)
    if (!output.length) {
      return [
        {
          path: '/',
          name: 'dashboard',
        },
      ]
    }

    output = output.map((member: any, index: any) => {
      const pathChanger = member === 'create-campaign'
      if (!regex.test(member)) {
        if (index) {
          return {
            path: Location.pathname,
            name: member,
          }
        }

        return {
          path: pathChanger ? `/create-campaign/setup` : `/${member}`,
          name: member,
        }
      }
      return ''
    })
    output = output.filter((path: string) => path !== '')

    if (output.length > 2) {
      output[1].path = Location.pathname.substring(
        0,
        Location.pathname.lastIndexOf('/'),
      )
    }

    return output
  }

  useEffect(() => {
    setBreadCrumbPath(getBreadCrumbPathFormat(Location.pathname))
  }, [Location.pathname])

  return (
    <div className="bread_crumb_container">
      <Breadcrumbs breadcrumbs={breadCrumbPath} />
      <div
        className="right_corner"
        style={{
          // justify-content: space-between;
          justifyContent:
            userData?.is_super === 0 ? 'space-between' : 'flex-end',
        }}
      >
        {userData?.is_super === 0 && (
          <button
            onClick={() => setNotificationsToggle(!notificationsToggle)}
            type="button"
            style={{
              position: 'relative',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none',
              width: 41,
            }}
          >
            {isUnReadNotification && <div className="dot" />}
            {/* <FontAwesomeIcon className="sidebar_icon" icon={faBell} /> */}
            <img
              src={notification}
              alt="notification"
              className="sidebar_icon"
            />
          </button>
        )}

        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Text on="grey-050" UNSAFE_className="bread_crumb_username">
            {userData?.first_name}
          </Text>

          <div>
            <Avatar
              name={`${userData?.first_name} ${userData?.last_name}`}
              rounded
              size="m"
              src={userData?.profile_pic}
              UNSAFE_className="avatar"
            />
          </div>
        </span>
      </div>
      {notificationsToggle && (
        <div
          className="HeaderDropdown__options"
          style={{ right: '1rem', zIndex: 9999 }}
        >
          <div className="notificaion_container">
            <div className="header">
              <Text on="grey-050">Notifications</Text>
              <Link to="/notifications">View All</Link>
            </div>
            <NotificationsTooltip />
          </div>
        </div>
      )}
    </div>
  )
}
