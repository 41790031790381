import React, { useState, useEffect } from 'react'
import { useAsync } from '@eltoro-ui/hooks'
import { Map, Loading } from 'Components'
import { APIFeatureType, MapLocationType } from 'types'
import { MapMarker } from 'Pages/ProspectActivity/components'
import { getLocationByLatLong } from 'Helpers'
import './ListingMap.scss'

export const ListingMap = ({ listing }: { listing?: APIFeatureType }) => {
  if (!listing) return null
  const [mapLocation, setMapLocation] = useState<MapLocationType>()

  const { longitude, latitude } = listing

  const { status, value } = useAsync(() =>
    getLocationByLatLong(longitude, latitude),
  )

  useEffect(() => {
    if (value) setMapLocation(value)
  }, [value])
  return (
    <div className="ListingMap">
      {status === 'pending' && (
        <div className="ListingMap__map-loading">
          <Loading />
        </div>
      )}
      {status === 'success' && mapLocation && listing && (
        <Map
          style={{ boxShadow: 'none' }}
          features={[listing]}
          locations={[mapLocation]}
          marker={() => <MapMarker color="var(--color-tertiary-500)" />}
          popup={() => <div />}
          tooltip={() => <div />}
          zoomLevel={18}
        />
      )}
    </div>
  )
}
