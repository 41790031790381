import { Button, FlexBox, Tabs, Spacer, Text } from '@eltoro-ui/components'
import {
  faCog,
  faPencilAlt,
  faStarHalfAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  allBlocksOfKindSelector,
  selectedBlockIdsAtom,
  selectedKindAtom,
  removeBlocksOfKindSelector,
} from '../../../State'
import { blocksBase } from '../../../Data'
import {
  EditMainFeatures,
  EditShapeContents,
  EditTextContents,
  EditTextValues,
  SelectionTable,
  BlockQuickSelect,
  BlockSelectPopover,
  PhotoEdit,
} from './components'

export const EditBlock: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [selectedBlockIds, setSelectedBlockIds] =
    useRecoilState(selectedBlockIdsAtom)
  const [selectedKind, setSelectedKind] = useRecoilState(selectedKindAtom)
  const allBlocksOfKind = useRecoilValue(allBlocksOfKindSelector)
  const handleRemoveBlocks = useSetRecoilState(removeBlocksOfKindSelector)

  const ids = allBlocksOfKind?.map((b) => b.id)

  // by default all of kind are selected
  useEffect(() => {
    if (ids) setSelectedBlockIds(ids)
  }, [selectedKind])

  const splitByCapital = (value: string) => {
    const split = value.split(/(?=[A-Z])/).join(' ')
    return split.charAt(0).toUpperCase() + split.slice(1)
  }

  const handleClose = () => {
    setSelectedKind(undefined)
    onClose()
  }

  const handleRemoveBlock = () => {
    handleRemoveBlocks([])
    handleClose()
  }

  if (!selectedKind) return null
  const { textContent, shapeContent, label } = blocksBase[selectedKind]
  const showPhotoEdit = selectedKind === 'imageFrame'
  return (
    <div className="EditBlock">
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexBox alignItems="center">
          <Text on="white" size="l" weight="bold">
            {label}
          </Text>
          <Spacer width="0.25rem" />
          <BlockQuickSelect label={`${label} Options`} />
        </FlexBox>
        <Button
          onClick={handleClose}
          kind="text"
          iconLeft={<FontAwesomeIcon icon={faTimes} />}
        />
      </FlexBox>
      <hr />
      <Spacer height="0.5rem" />
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexBox alignItems="center">
          <Text on="white" size="l" weight="bold">
            Settings
          </Text>
          <Spacer width="0.25rem" />
          <BlockSelectPopover />
        </FlexBox>
      </FlexBox>
      <Spacer />
      <Tabs
        on="white"
        tabs={[
          {
            id: 'content',
            label: (
              <FlexBox>
                <FontAwesomeIcon icon={faPencilAlt} />
                <Spacer width="0.5rem" />
                Content
              </FlexBox>
            ),
            component: (
              <>
                <Spacer />
                {Object.keys(textContent).map((textKey) => (
                  <EditTextValues
                    key={textKey}
                    label={splitByCapital(textKey)}
                    config={{
                      ids: selectedBlockIds,
                      kind: selectedKind,
                      key: textKey,
                    }}
                  />
                ))}
                <hr />
                {showPhotoEdit && ids && (
                  <>
                    <Spacer />
                    <PhotoEdit ids={ids} />
                  </>
                )}
                <Spacer />
                <EditMainFeatures />
              </>
            ),
          },
          {
            id: 'style',
            label: (
              <FlexBox>
                <FontAwesomeIcon icon={faStarHalfAlt} />
                <Spacer width="0.5rem" />
                Style
              </FlexBox>
            ),
            component: (
              <>
                <Spacer />
                {Object.keys(textContent).map((textKey) => (
                  <EditTextContents
                    key={textKey}
                    label={splitByCapital(textKey)}
                    config={{
                      ids: selectedBlockIds,
                      kind: selectedKind,
                      key: textKey,
                    }}
                  />
                ))}
                <Spacer />
                {shapeContent &&
                  Object.keys(shapeContent).map((shapeKey) => (
                    <EditShapeContents
                      key={shapeKey}
                      label={splitByCapital(shapeKey)}
                      config={{
                        ids: selectedBlockIds,
                        kind: selectedKind,
                        key: shapeKey,
                      }}
                    />
                  ))}
                <Spacer />
              </>
            ),
          },
          {
            id: 'advanced',
            label: (
              <FlexBox>
                <FontAwesomeIcon icon={faCog} />
                <Spacer width="0.5rem" />
                Advanced
              </FlexBox>
            ),
            component: (
              <>
                <Spacer />
                <SelectionTable />
                <Spacer />
                <Button onClick={handleRemoveBlock} kind="danger" width="100%">
                  {`Remove ${label}`}
                </Button>
              </>
            ),
          },
        ]}
      />
    </div>
  )
}
