import {
  faHome,
  faHouseUser,
  faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons'
import { MoverLifecycleType } from 'Components'

export const moverLifecycleFilters: MoverLifecycleType[] = [
  {
    label: 'Pre Movers',
    faIcon: faHome,
    test: (lifecycleKind) => lifecycleKind === 'pre_mover_prospects',
  },
  {
    label: 'Pre Movers in Escrow',
    faIcon: faPaperPlane,
    test: (lifecycleKind) => lifecycleKind === 'in_escrow_prospects',
  },
  {
    label: 'New Movers',
    faIcon: faHouseUser,
    test: (lifecycleKind) => lifecycleKind === 'new_mover_prospects',
  },
]
