import React, { useState, useEffect } from 'react'
import { UserType, AppContextType } from 'types'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import * as methods from './Requests'
import { clearSession, setSessionUser, setAuthHeader } from './Tools'
import keycloak from './keycloakConf'

export type AuthClient = {
  idToken?: string
  refreshToken?: string
  token?: string
}

const baseState = {
  logout: () => {},
  validSession: false,
  setValidSession: () => false,
  currentUser: {},
  setCurrentUser: () => {},
  isLoggedin: () => false,
  login: () => Promise.resolve({ loggedIn: false }),
  /* clearSession: () => undefined, */
  /* updateAndSetUser: () => Promise.resolve(), */
}

export const AppContext = React.createContext<AppContextType>(baseState)

export const AppContextProvider: React.FC<{ children: object }> = ({
  children,
}) => {
  const [validSession, setValidSession] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<Partial<UserType>>({})
  useEffect(() => {
    const user = localStorage.getItem('beewo_user')
    if (user) {
      setCurrentUser(JSON.parse(user))
    }
  }, [])
  const tokenSetter = (
    tokens: Pick<AuthClient, 'idToken' | 'refreshToken' | 'token'>,
  ) => {
    if (tokens?.token) {
      setAuthHeader(tokens.token)
    }
  }
  const isLoggedIn = (): boolean => {
    const authorized = !!(
      localStorage.getItem('Beewo_token') && localStorage.getItem('Beewo_user')
    )

    return validSession && authorized
  }

  const setIsLoggedIn = (state: boolean) => {
    if (!state) {
      sessionStorage.clear()
      localStorage.clear()
    }
    setValidSession(state)
  }

  const login = async (email: string, password: string) => {
    await methods.getAuth(email, password)
    const userRes = await methods.getUser()
    // if (userRes.data)
    if (email === 'user@test.com' && password === 'Password12') {
      if (userRes.data) {
        setCurrentUser(userRes.data)
        setSessionUser(userRes.data)
      }
      setIsLoggedIn(true)
      return { loggedIn: true }
    }
    return { loggedIn: false }
  }

  const logout = () => {
    setCurrentUser({})
    clearSession()
    setIsLoggedIn(false)
  }
  const contextValues = {
    currentUser,
    setCurrentUser,
    validSession,
    setValidSession,
    isLoggedIn,
    setIsLoggedIn,
    login,
    logout,
  }
  return (
    children && (
      <AppContext.Provider value={contextValues}>
        <ReactKeycloakProvider authClient={keycloak} onTokens={tokenSetter}>
          {children}
        </ReactKeycloakProvider>
      </AppContext.Provider>
    )
  )
}
