import React from 'react'
import { Checkbox, Spacer } from '@eltoro-ui/components'
import { useRecoilState } from 'recoil'
import {
  CollapseSection,
  ColorSelect,
  DropShadowSettings,
  HorizontalLabel,
  MoreInfoContainer,
  StrokeSettings,
} from '../../..'
import {
  getDefaultShapeShadow,
  getDefaultShapeStroke,
  getDefaultShapeValue,
} from '../../../../../Helpers'
import {
  blockBorderRadiusSelector,
  blockShadowBlurSelector,
  blockShadowColorSelector,
  blockShadowOffsetXSelector,
  blockShadowOffsetYSelector,
  blockShadowOpacitySelector,
  blockShadowVisibleSelector,
  blockShapeColorSelector,
  blockShapeTypeSelector,
  blockStrokeColorSelector,
  blockStrokeEnabledSelector,
  blockStrokeWidthSelector,
} from '../../../../../State'
import { BlocksConfigType } from '../../../../../Typings'
import { ResetSettings } from '../ResetSettings'

export const EditShapeContents: React.FC<{
  label: string
  config: BlocksConfigType
}> = ({ label, config }) => {
  const { kind, key } = config
  const [borderRadius, setBorderRadius] = useRecoilState(
    blockBorderRadiusSelector(config),
  )
  const defaultBorderRadius = getDefaultShapeValue(kind, key, 'borderRadius')
  const showBorderRadiusReset = defaultBorderRadius !== borderRadius.value
  const handleResetBorderRadius = () =>
    setBorderRadius({ value: defaultBorderRadius, error: false })
  const [color, setColor] = useRecoilState(blockShapeColorSelector(config))
  const defaultColor = getDefaultShapeValue(kind, key, 'color')
  const showColorReset = defaultColor !== color.value
  const handleResetColor = () => setColor({ value: defaultColor, error: false })

  const [enabled, setEnabled] = useRecoilState(
    blockShadowVisibleSelector(config),
  )
  const defaultEnabled = getDefaultShapeShadow(kind, key, 'enabled')

  const [shadowColor, setShadowColor] = useRecoilState(
    blockShadowColorSelector(config),
  )
  const defaultShadowColor = getDefaultShapeShadow(kind, key, 'color')

  const [blur, setBlur] = useRecoilState(blockShadowBlurSelector(config))
  const defaultBlur = getDefaultShapeShadow(kind, key, 'blur')

  const [shadowOpacity, setShadowOpacity] = useRecoilState(
    blockShadowOpacitySelector(config),
  )
  const defaultShadowOpacity = getDefaultShapeShadow(kind, key, 'opacity')

  const [offsetX, setOffsetX] = useRecoilState(
    blockShadowOffsetXSelector(config),
  )
  const defaultOffsetX = getDefaultShapeShadow(kind, key, 'offsetX')

  const [offsetY, setOffsetY] = useRecoilState(
    blockShadowOffsetYSelector(config),
  )
  const defaultOffsetY = getDefaultShapeShadow(kind, key, 'offsetY')

  const showShadowReset = !(
    enabled.value === defaultEnabled &&
    shadowColor.value === defaultShadowColor &&
    blur.value === defaultBlur &&
    shadowOpacity.value === defaultShadowOpacity &&
    offsetX.value === defaultOffsetX &&
    offsetY.value === defaultOffsetY
  )

  const handleShadowReset = () => {
    setEnabled({ value: defaultEnabled, error: false })
    setShadowColor({ value: defaultShadowColor, error: false })
    setBlur({ value: defaultBlur, error: false })
    setShadowOpacity({ value: defaultShadowOpacity, error: false })
    setOffsetX({ value: defaultOffsetX, error: false })
    setOffsetY({ value: defaultOffsetY, error: false })
  }

  const [shapeType, setShapeType] = useRecoilState(
    blockShapeTypeSelector(config),
  )

  const [strokeEnabled, setStrokeEnabled] = useRecoilState(
    blockStrokeEnabledSelector(config),
  )
  const defaultStrokeEnabled = getDefaultShapeStroke(kind, key, 'enabled')
  const [strokeColor, setStrokeColor] = useRecoilState(
    blockStrokeColorSelector(config),
  )
  const defaultStrokeColor = getDefaultShapeStroke(kind, key, 'color')
  const [strokeWidth, setStrokeWidth] = useRecoilState(
    blockStrokeWidthSelector(config),
  )
  const defaultStrokeWidth = getDefaultShapeStroke(kind, key, 'width')

  const showStrokeReset = !(
    strokeEnabled.value === defaultStrokeEnabled &&
    strokeColor.value === defaultStrokeColor &&
    strokeWidth.value === defaultStrokeWidth
  )

  const handleStrokeReset = () => {
    setStrokeEnabled({ value: defaultStrokeEnabled, error: false })
    setStrokeColor({ value: defaultStrokeColor, error: false })
    setStrokeWidth({ value: defaultStrokeWidth, error: false })
  }

  return (
    <CollapseSection label={label}>
      {shapeType.value && (
        <>
          <HorizontalLabel label="Shape">
            <ResetSettings showReset={false} onReset={() => {}}>
              <Checkbox
                checked={shapeType.value === 'circle'}
                onChange={() => setShapeType({ value: 'circle', error: false })}
                label="Circle"
              />
              <Spacer />
              <Checkbox
                checked={shapeType.value === 'square'}
                onChange={() => setShapeType({ value: 'square', error: false })}
                label="Square"
              />
            </ResetSettings>
          </HorizontalLabel>
          <Spacer height="0.5rem" />
        </>
      )}
      {color.value && (
        <>
          <HorizontalLabel label="Color">
            <ResetSettings
              showReset={showColorReset || color.error}
              onReset={handleResetColor}
            >
              <ColorSelect
                color={color.value}
                onChange={(c) => setColor({ value: c.hex, error: false })}
                error={color.error}
              />
            </ResetSettings>
          </HorizontalLabel>
          <Spacer height=".5rem" />
        </>
      )}
      {shapeType.value !== 'circle' && (
        <>
          <HorizontalLabel label="Border radius">
            <ResetSettings
              showReset={showBorderRadiusReset}
              onReset={handleResetBorderRadius}
            >
              <input
                className="EditBlock__number-input"
                type="number"
                value={borderRadius.value}
                onChange={(e) =>
                  setBorderRadius({
                    value: parseInt(e.target.value, 10),
                    error: false,
                  })
                }
                min="0"
                max="20"
              />
            </ResetSettings>
          </HorizontalLabel>
          <Spacer height=".5rem" />
        </>
      )}
      <MoreInfoContainer
        label="Drop shadow"
        showReset={showShadowReset}
        onReset={handleShadowReset}
      >
        <DropShadowSettings config={config} />
      </MoreInfoContainer>
      <Spacer height=".5rem" />
      <MoreInfoContainer
        label="Stroke"
        showReset={showStrokeReset}
        onReset={handleStrokeReset}
      >
        <StrokeSettings config={config} />
      </MoreInfoContainer>
      <Spacer />
    </CollapseSection>
  )
}
