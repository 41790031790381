import React from 'react'
import { useLocation } from 'react-router'
import { Text } from '@eltoro-ui/components'
import './LoginFooter.scss'

type InputTypes = {
  color?: Boolean
  borderTop?: string | number | undefined
  loginStyle?: Boolean
  classname?: string
}
export const LoginFooter: React.FC<InputTypes> = ({
  color,
  borderTop,
  loginStyle,
  classname,
}) => {
  const Location = useLocation()

  const isMarginRight = () => {
    return [
      '/login',
      '/signup',
      '/verify-email',
      '/email-verify',
      '/reset-password',
      '/profile/password-reset',
    ].includes(Location.pathname)
  }
  return (
    <div
      className={`loginFooter ${classname}`}
      style={{
        backgroundColor: color ? '#fff' : '#F8F8F8',
        borderTop,
        zIndex: loginStyle && 1,
        width: loginStyle && '100%',
        bottom: loginStyle && '0px',
        position: loginStyle && 'absolute',
        margin: isMarginRight() ? '' : '2rem 0 0 65px',
      }}
    >
      <div
      // className="loginFooterLeft"
      >
        <span>© 2021 Beewo. All rights reserved.</span>
      </div>
      <div
      //  className="loginFooterRight"
      >
        <span>Opt Out / Support / Terms & Conditions / Privacy Policy</span>
        {/* <ul>
          <li className="loginFooterRightList">
            Opt Out / Support / Terms & Conditions / Privacy Policy
          </li>
          <li className="loginFooterRightList">Opt Out / </li>
          <li className="loginFooterRightList"> Support / </li>
          <li className="loginFooterRightList">Terms & Conditions /</li>
          <li className="loginFooterRightList">Privacy Policy </li>
        </ul> */}
      </div>
    </div>
  )
}

LoginFooter.defaultProps = {
  color: false,
}
