import React from 'react'
import { Button, Modal, Text } from '@eltoro-ui/components'
import './index.scss'
import WarningIcon from '../../assets/Images/warning_lg.png'
import leftGroup from '../../assets/Images/leftgroup.svg'
import rightGroup from '../../assets/Images/rightgroup.svg'

export const ConfirmationModal = ({
  onSubmit,
  onCancel,
  loading,
  heading = 'Are You sure you want to delete?',
}: {
  onSubmit: (e: any) => void
  onCancel: () => void
  loading?: boolean
  heading?: string
}) => {
  return (
    <Modal offClick={onCancel}>
      {/* <div className="deleteConfirmationModal">
        <img src={WarningIcon} alt="waning" />
        <Text
          UNSAFE_className="confirmation_modal_heading"
          tag="div"
          on="white"
          size="xxl"
        >
          {heading}
        </Text>

        <div className="UploadVideoModal__footer">
          <Button
            UNSAFE_className="confirm_button_modal"
            kind="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            Confirm
          </Button>
          <Button
            kind="text"
            UNSAFE_className="cancel_button_modal"
            onClick={onCancel}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      </div> */}
      <div className="deleteConfirmationModal">
        <img className="rightImage" src={rightGroup} alt="confirmleftcomb" />
        <Text
          UNSAFE_className="deleteConfirmationModal__heading"
          on="tertiary-300"
        >
          {heading}
        </Text>
        <div className="deleteConfirmationModal__button">
          <Button
            // UNSAFE_className="confirm_button_modal"
            UNSAFE_className="deleteConfirmationModal__yes_button_modal"
            kind="primary"
            loading={loading}
            // disabled={disable}
            onClick={onSubmit}
            disabled={loading}
          >
            Yes
          </Button>
          <Button
            kind="text"
            UNSAFE_className="deleteConfirmationModal__no_button_modal"
            onClick={onCancel}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
        <img className="leftImage" src={leftGroup} alt="lconfirmleftcomb" />
      </div>
    </Modal>
  )
}
