import React, { useEffect, useState } from 'react'
import {
  Loading,
  PageHeader,
  // TableSelectionWrapper,
  SearchBar,
  // ConfirmationModal,
  // Pagination,
  EmptyStatus,
  ReactTable,
  ReactTableSearchBar,
} from 'Components'
import toast from 'react-hot-toast'

import { Button, Table, Text, TextInput } from '@eltoro-ui/components'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus, faSearch, faWalking } from '@fortawesome/pro-solid-svg-icons'
// import { AudienceType } from 'types'
import { getLocalDateTime } from 'Helpers'
import { TRootState } from 'types'
import { useSelector } from 'react-redux'
import './SavedAudiences.scss'
// import { useDispatch, useSelector } from 'react-redux'
// import { setSpinner } from 'Redux/actions'
// import { useAsync } from '@eltoro-ui/hooks'
import {
  getAudiences,
  deleteAudience,
} from 'Requests/Request_Methods/audienceMethods'
import person from '../../assets/Images/person.png'
import { Tags } from './components'

export const SavedAudiences = () => {
  // const [selections, setSelections] = useState<any>([])
  const [search, setSearch] = useState('')
  const [audiences, setAudiences] = useState<any>([])
  // const [pages, setPages] = useState<any>()
  // const [currentPage, setCurrentPage] = useState<any>(1)
  // const [unselect, setUnselectAll] = useState(false)
  // const [isLoading, setIsLoading] = useState({ apiCallLoaded: true })
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteIsLoading, setDeleteIsLoading] = useState(false)
  // const [sortedList, setSortedList] = useState({
  //   sort: null,
  //   path: null,
  // })
  // const [PreSelections, setPreSelections] = useState<any[]>([])

  // const dispatch = useDispatch()

  // useEffect(() => {
  //   if (!sortedList.path) {
  //     setIsLoading({ ...isLoading, apiCallLoaded: true })
  //   } else {
  //     dispatch(setSpinner(true))
  //   }
  //   setPreSelections([...selections])

  //   getAudiences(
  //     currentPage,
  //     20,
  //     search,
  //     sortedList.path && sortedList.path,
  //     sortedList.sort && sortedList.sort,
  //   ).then((res) => {
  //     setAudiences(res?.data?.audiences)
  //     if (!sortedList.path) {
  //       setIsLoading({ ...isLoading, apiCallLoaded: false })
  //     } else {
  //       dispatch(setSpinner(false))
  //     }
  //     setPages({
  //       current_page: res?.data?.current_page,
  //       total_count: res?.data?.total_audience,
  //       total_pages: res?.data?.total_pages,
  //     })
  //   })
  //   setUnselectAll(false)
  // }, [search, currentPage, sortedList])

  // const removeAudience = () => {
  //   const audienceId: any = []
  //   audienceId.push(
  //     selections.map((item: any) => {
  //       return item.id
  //     }),
  //   )
  //   setDeleteIsLoading(true)
  //   deleteAudience({ ids: audienceId[0] }).then((res) => {
  //     setDeleteIsLoading(false)
  //     setDeleteModalIsOpen(false)
  //     setSelections([])
  //     setPreSelections([])
  //     setUnselectAll(true)
  //     getAudiences(currentPage, 20, search).then((resp) => {
  //       setAudiences(resp?.data?.audiences)
  //       setPages({
  //         current_page: resp?.data?.current_page,
  //         total_count: resp?.data?.total_audience,
  //         total_pages: resp?.data?.total_pages,
  //       })
  //     })
  //     setUnselectAll(false)
  //   })
  // }

  // const onSort = (path: any, sort: any) => {
  //   setSortedList({ sort, path })
  //   setCurrentPage(1)
  // }

  // useEffect(() => {
  //   if (unselect) {
  //     setSelections([])
  //     setPreSelections([])
  //   }
  // }, [unselect])

  // ========== New Table Utilities
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false)

  const { setGlobalFilters } = useSelector(
    (state: TRootState) => state.setGlobalFilters,
  )
  const { globalFilters } = useSelector(
    (state: TRootState) => state.globalFilters,
  )
  const onDelete = async (_items: any) => {
    setDeleteIsLoading(true)
    const ids = _items.map((item: any) => item?.original?.id)
    try {
      await deleteAudience({ ids })
      const filterDeletedItems = audiences?.filter(
        (i: any) => !ids.includes(i.id),
      )
      setAudiences(filterDeletedItems)
      toast.success(
        `${ids.length} Audience${ids.length > 1 ? 's' : ''} Deleted`,
      )
      setDeleteModalIsOpen(false)
      setDeleteIsLoading(false)
    } catch (err: any) {
      toast.error(err)
      setDeleteIsLoading(false)
    }
  }

  const _fetchData = async () => {
    setFetchDataIsLoading(true)
    try {
      const res = await getAudiences(1, 1000, null, null)
      if (res.data?.audiences?.length) {
        setAudiences(res.data?.audiences)
      }
      setFetchDataIsLoading(false)
    } catch (err: any) {
      toast.error(err)
      setFetchDataIsLoading(false)
    }
  }

  useEffect(() => {
    if (!audiences?.length) {
      _fetchData()
    }
  }, [audiences])

  const columns = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Audience Name',
          accessor: 'name',
        },
        {
          Header: 'Prospects',
          accessor: (item: any) => (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                className="SavedAudiences__styleIcon"
                src={person}
                alt="person"
              />
              {item.prospects_counts}
            </span>
          ),
        },
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Date Uploaded',
          accessor: (item: any) => getLocalDateTime(item.created_at),
          width: 20,
        },
        {
          Header: 'Tags',
          accessor: (item: any) =>
            item.tags?.length ? (
              <span className="SavedAudiences__tagsContainer">
                <Tags data={item.tags} />
              </span>
            ) : (
              '-'
            ),
        },
      ],
    },
  ]
  // ========== New Table Utilities

  const getList = () => {
    let component = <></>
    if (fetchDataIsLoading) {
      component = <Loading />
    } else if (!audiences?.length) {
      component = (
        <EmptyStatus subHeading="You can try by creating new campaign to get better audience." />
      )
    } else {
      component = (
        <ReactTable
          title="audience"
          data={audiences}
          onDelete={onDelete}
          deleteModal={deleteModalIsOpen}
          setDeleteModal={setDeleteModalIsOpen}
          deleteIsLoading={deleteIsLoading}
          columns={columns}
        />
      )

      // component = (
      //   <TableSelectionWrapper
      //     selectionType="audience"
      //     selections={selections}
      //     actions={[
      //       <button
      //         type="button"
      //         className="SavedAudiences__select-action"
      //         onClick={() => setDeleteModalIsOpen(true)}
      //       >
      //         <Text
      //           on="tertiary-300"
      //           UNSAFE_className="SavedAudiences__delete_campaign_list"
      //         >
      //           Delete
      //         </Text>
      //       </button>,
      //     ]}
      //   >
      //     <Table
      //       rows={audiences}
      //       columns={[
      //         {
      //           path: 'name',
      //           label: 'Audience Name',
      //           width: '20%',
      //           alignment: 'left',
      //           onSort,
      //         },
      //         {
      //           path: 'prospects_counts',
      //           label: 'Prospects',
      //           alignment: 'left',
      //           width: '20%',
      //           onSort,
      //           RowCell: (audience) => (
      //             <div>
      //               <Text
      //                 on="white"
      //                 UNSAFE_className="SavedAudiences__textstyle"
      //               >
      //                 <img
      //                   className="SavedAudiences__styleIcon"
      //                   src={person}
      //                   alt="person"
      //                 />

      //                 {audience?.prospects_counts}
      //               </Text>
      //             </div>
      //           ),
      //         },
      //         {
      //           path: 'source',
      //           label: 'Source',
      //           width: '20%',
      //           alignment: 'left',
      //           onSort,
      //           RowCell: (audience) => (
      //             <Text
      //               on="white"
      //               UNSAFE_className="SavedAudiences__textstyle"
      //               textTransform="capitalize"
      //             >
      //               {audience.source}
      //             </Text>
      //           ),
      //         },

      //         {
      //           path: 'created_at',
      //           label: 'Date Captured',
      //           width: '20%',
      //           onSort,
      //           RowCell: (audience) => getLocalDateTime(audience.created_at),
      //           alignment: 'left',
      //         },
      //         {
      //           path: 'tags',
      //           label: 'Tags',
      //           alignment: 'left',
      //           // onSort: (path: any, sort?: any) => {
      //           //   setSortedList({ sort, path })
      //           // },
      //           RowCell: (audience) => {
      //             return <Tags data={audience.tags} />
      //           },
      //         },
      //       ]}
      //       // onSelect={setSelections}
      //       // unSelectAll={unselect}

      //       onSelect={(id) => {
      //         if (id.length > 0) {
      //           setUnselectAll(false)
      //         }
      //         setSelections([...id])
      //         setPreSelections([...id])
      //       }}
      //       preSelected={PreSelections}
      //       unSelectAll={unselect}
      //       setUnselectAll={setUnselectAll}
      //     />
      //     {!!audiences?.length && (
      //       <Pagination
      //         title="audience"
      //         loading={isLoading.apiCallLoaded}
      //         currentPage={pages?.current_page}
      //         totalItems={pages?.total_count}
      //         totalPages={pages?.total_pages}
      //         onClickPrev={() => setCurrentPage(currentPage - 1)}
      //         onClickNext={() => setCurrentPage(currentPage + 1)}
      //       />
      //     )}
      //     {deleteModalIsOpen && (
      //       <ConfirmationModal
      //         // title="Are you sure you want to delete contact"
      //         heading="Are you sure you want to delete audience"
      //         onSubmit={removeAudience}
      //         onCancel={() => setDeleteModalIsOpen(false)}
      //         loading={deleteIsLoading}
      //       />
      //     )}
      //   </TableSelectionWrapper>
      // )
    }
    return component
  }

  return (
    <div className="SavedAudiences">
      <PageHeader
        title=""
        style={{ height: '84px', background: 'var(--color-white)' }}
        LeftContent_ClassName="MyListings__header"
        subTitle={
          <span
            style={{ marginLeft: '5px' }}
            className="MyListings__totalCounts"
          >
            You have <b>{audiences.length} Saved Audience</b>
          </span>
        }
        actions={[
          <ReactTableSearchBar
            globalFilter={globalFilters}
            setGlobalFilter={setGlobalFilters}
          />,
          // <SearchBar
          //   value={search}
          //   onChange={(e) => setSearch(e.target.value)}
          // />,
          <Button
            kind="primary"
            to="/create-campaign/setup"
            weight="bolder"
            size="l"
            UNSAFE_style={{
              width: '202px',
              height: '48px',
              justifyContent: 'center',
            }}
          >
            New Campaign
          </Button>,
        ]}
      />
      {/* && status === 'success' */}
      {getList()}
    </div>
  )
}
