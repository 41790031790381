export const SpinnerReducer: undefined | any = (
  state = { spinner: null },
  action: any,
) => {
  switch (action.type) {
    case 'SET_SPINNER':
      return { spinner: action.payload }

    default:
      return { ...state }
  }
}
