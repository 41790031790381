import React from 'react'
import { Button, GridBox, Text } from '@eltoro-ui/components'
import './PageHeader.scss'

export const PageHeader: React.FC<{
  icon?: JSX.Element | boolean
  title: JSX.Element | string
  subTitle: JSX.Element | string
  actions?: React.ReactNode
  actionsStyle?: string
  statusbox?: JSX.Element | string
  UNSAFE_CLASSNAME?: string
  LeftContent_ClassName?: string
  UNSAFE_className_SUBTITLE?: string
  UNSAFE_CLASSNAME_TITLE?: string
  UNSAFE_CLASSNAME_GRID?: string
  style?: React.CSSProperties
}> = ({
  icon,
  title,
  subTitle,
  actions,
  statusbox,
  actionsStyle,
  UNSAFE_CLASSNAME,
  LeftContent_ClassName,
  UNSAFE_className_SUBTITLE,
  UNSAFE_CLASSNAME_TITLE,
  UNSAFE_CLASSNAME_GRID,
  style,
}) => {
  return (
    <div className={`PageHeader ${UNSAFE_CLASSNAME}`} style={style}>
      <div className={LeftContent_ClassName}>
        <div className="">
          <div className={UNSAFE_CLASSNAME_GRID}>
            <GridBox gridTemplateColumns="max-content max-content">
              {icon && <div>{icon}</div>}
              <Text
                size="xxxl"
                weight="light"
                on="white"
                UNSAFE_className={`${
                  icon && `creatives-icon`
                } ${UNSAFE_CLASSNAME_TITLE}`}
              >
                {title}
              </Text>
            </GridBox>
          </div>
          <Text
            on="white"
            size="s"
            UNSAFE_className={UNSAFE_className_SUBTITLE}
          >
            {subTitle}
          </Text>
        </div>
        <div className="">{statusbox}</div>
      </div>
      <div className={`PageHeader__actions ${actionsStyle}`}>{actions}</div>
    </div>
  )
}
