import React, { ReactNode } from 'react'
import { MaxHeightContainer, FlexBox, Text } from '@eltoro-ui/components'
import './Section.scss'

type SectionType = {
  title?: string
  actions?: ReactNode
  spacer?: boolean | undefined
  sub_title?: string
  UNSAFE_className_text?: string
  UNSAFE_className_container?: string
  isCenter?: Boolean
  titleRight?: string
  absoluteHeader?: Boolean
} & (
  | { dark?: boolean; noBackground?: never }
  | { dark?: never; noBackground?: boolean }
)

export const Section: React.FC<SectionType> = ({
  title,
  actions,
  dark = false,
  noBackground = false,
  children,
  UNSAFE_className_text,
  UNSAFE_className_container,
  spacer = true,
  sub_title,
  isCenter,
  absoluteHeader,
  titleRight,
}) => {
  let className = 'Section'

  if (dark) {
    className += ' Section--dark'
  }
  if (noBackground) {
    className += ' Section--no-background'
  }
  return (
    <div className={`${className} ${UNSAFE_className_container}`}>
      <MaxHeightContainer
        headerAbsolute={absoluteHeader}
        fullHeight
        header={
          <div className="Section__header">
            <FlexBox alignItems="center" justifyContent="space-between">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Text
                    UNSAFE_className={UNSAFE_className_text}
                    weight="bold"
                    on="grey-100"
                    size="xxl"
                  >
                    {title}
                  </Text>
                  <Text
                    UNSAFE_className={UNSAFE_className_text}
                    weight="bold"
                    on="grey-100"
                    size="xxl"
                  >
                    {titleRight}
                  </Text>
                </div>
                {sub_title && (
                  <Text
                    UNSAFE_className={UNSAFE_className_text}
                    // weight="bold"
                    on="grey-100"
                    UNSAFE_style={{
                      color: '#6D6D6D',
                      marginTop: '4px',
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                      lineHeight: '19px',
                    }}
                    size="l"
                  >
                    {sub_title}
                  </Text>
                )}
              </div>
              <div className="Section__actions">{actions}</div>
            </FlexBox>
          </div>
        }
      >
        <div
          className="Section__body"
          style={{ flexDirection: isCenter && 'row' }}
        >
          {/* {spacer && <div className="Section__spacer" />} */}
          {children}
        </div>
      </MaxHeightContainer>
    </div>
  )
}
