import { Button, GridBox, Text } from '@eltoro-ui/components'
import { getBrowserInfo } from '@eltoro-ui/hooks/useForm/helper'
import { Uploader } from 'Components/Uploader'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { uploadContactsCSV } from 'Requests'
import { FileUploadType } from 'types'
import {
  UploadAreaLayout,
  UploadTypes,
} from '../../../../CampaignCreation/components/CampaignCreatives/_components/UploadAreaLayout'

export const StepThree = ({ onClose }: { onClose: (e?: any) => void }) => {
  const [csvFile, setCsvFile] = useState(null)
  const [file, setfile] = useState('')
  const [loading, setLoading] = useState(false)
  const Form = new FormData()

  const onSubmit = (e: any) => {
    if (e.acceptedFiles) {
      setfile(e.acceptedFiles[0])
      setCsvFile(e.acceptedFiles[0].name)
    }
  }

  const postCSV = () => {
    setLoading(true)
    Form.append('files', file)
    Form.append('device', `Web-${getBrowserInfo()}`)
    // {
    //   files: Form,
    //   device: `Web-${getBrowserInfo()}`,
    // }
    uploadContactsCSV(Form)
      .then((res: any) => {
        if (res.data.detail) {
          toast.error('Invalid csv')
          setLoading(false)
        }
        setTimeout(() => {
          onClose(true)
          toast.success('Upload successfully')
          setLoading(false)
        }, 2000)
      })
      .catch(() => {
        toast.error('Invalid csv')
        setLoading(false)
      })
  }

  return (
    <>
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text tag="div" on="white" size="xl">
          STEP 3
        </Text>
        <Text on="secondary-lighter" size="s" tag="div">
          <b>Upload Your Contact</b>
          {/* {csvFile && <p>{csvFile}</p>} */}
        </Text>
      </GridBox>
      <div
      // style={{
      //   padding: '35px 54px',
      //   background: ' #EEEFF0',
      //   marginBottom: '10px',
      // }}
      >
        <Uploader
          type="csv"
          onDropFile={(files: FileUploadType) => onSubmit(files)}
        >
          <UploadAreaLayout uploadType={UploadTypes.csv} />
        </Uploader>
      </div>
      <div className="UploadBannerModal__footer">
        <Button
          kind="text"
          UNSAFE_className="UploadBannerModal__cancel-button"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button kind="primary" onClick={postCSV} disabled={!csvFile || loading}>
          Upload
        </Button>
      </div>
    </>
  )
}
