import { APIFeatureType } from 'types'
import { BuzzFilterType } from '../BuzzScoreFilters'

export const filterFeaturesByBuzz = (
  features: APIFeatureType[],
  filters: BuzzFilterType[],
) =>
  features.filter((feature) =>
    filters.some((filter) => filter.test(feature.buzz_score)),
  )
