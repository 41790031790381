import { Button, Table, Text } from '@eltoro-ui/components'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CampaignStatusBadge,
  // ConfirmationModal,
  EmptyStatus,
  Loading,
  PageHeader,
  // Pagination,
  ReactTable,
  ReactTableSearchBar,
  SearchBar,
  // TableSelectionWrapper,
} from 'Components'
import React, { useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
// import { setSpinner } from 'Redux/actions'
import { deleteCampaign, getCampaigns } from 'Requests'
import { useSelector } from 'react-redux'
import { TRootState } from 'types'

import './Campaigns.scss'
import toast from 'react-hot-toast'
import { removeTimeFromDate } from 'Helpers'
// import rightarrow from '../../assets/Images/rightarrow.png'
import draft from '../../assets/Images/draft.png'
import inreview from '../../assets/Images/inreview.png'
import activeimg from '../../assets/Images/activeimg.png'
// import completedicon from '../../assets/Images/completedicon.png'

export const statusBadgeIconClass = (status: string) => {
  if (status === 'processed' || status === 'Open')
    return 'CampaignBadge_inReview'
  if (status === 'pending' || status === 'processing')
    return 'CampaignBadge_draft'
  if (status === 'Serving') return 'CampaignBadge_active'
  return 'CampaignBadge_draft'
}
export const getStatusBadgeImage = (status: string) => {
  if (status === 'processed' || status === 'Open') return inreview
  if (status === 'pending' || status === 'processing') return draft
  if (status === 'Ready' || status === 'Serving') return activeimg
  return '#AAB2B5'
}

export const getStatusColor = (status: string) => {
  if (status === 'processed' || status === 'Open') return '#ffab03'
  if (status === 'pending' || status === 'processing') return '#6D797C'
  if (status === 'Ready' || status === 'Serving')
    return 'var(--color-success-400)'
  return '#AAB2B5'
}

export const getStatus = (status: string) => {
  if (status === 'pending' || status === 'processing') return 'Draft'
  if (status === 'processed' || status === 'Open') return 'In Review'
  if (status === 'Serving') return 'Active'
  return 'pending'
}

export const Campaigns = () => {
  // const [selections, setSelections] = useState<any[]>([])
  const [search, setSearch] = useState<string>('')
  const [campaigns, setCampaigns] = useState<any>([])
  const [pages, setPages] = useState<any>()
  // const [currentPage, setCurrentPage] = useState<any>(1)
  // const [isDiabaleDelete, setIsDisableDelete] = useState(false)
  // const [isLoading, setIsLoading] = useState({ apiCallLoaded: true })
  // const [unselect, setUnselectAll] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteIsLoading, setDeleteIsLoading] = useState(false)
  // const [sortedList, setSortedList] = useState({
  //   sort: null,
  //   path: null,
  // })
  // const [PreSelections, setPreSelections] = useState<any[]>([])

  // const dispatch = useDispatch()
  const history = useHistory()
  const { setGlobalFilters } = useSelector(
    (state: TRootState) => state.setGlobalFilters,
  )
  const { globalFilters } = useSelector(
    (state: TRootState) => state.globalFilters,
  )

  // const removeCampaign = () => {
  //   setDeleteIsLoading(true)
  //   const campaignId: any = []
  //   campaignId.push(
  //     selections.map((item) => {
  //       return item.id
  //     }),
  //   )

  //   deleteCampaign({ campaign_id: campaignId[0] }).then((resp) => {
  //     setSelections([])
  //     setPreSelections([])
  //     setDeleteIsLoading(false)
  //     setDeleteModalIsOpen(false)
  //     getCampaigns(currentPage, 20, search).then((res) => {
  //       setUnselectAll(true)
  //       setCampaigns(res.data?.campaigns)
  //       setIsLoading({ ...isLoading, apiCallLoaded: false })
  //       setPages({
  //         current_page: res?.data?.current_page,
  //         total_count: res?.data?.total_campaigns,
  //         total_pages: res?.data?.total_pages,
  //       })
  //     }) // setCampaigns(campaigns.filter((item)=>item.id !campaignId.foreach(item))
  //   })
  // }

  // useEffect(() => {
  //   if (unselect) {
  //     setSelections([])
  //     setPreSelections([])
  //   }
  // }, [unselect])

  // useEffect(() => {
  //   if (!sortedList.path) {
  //     setIsLoading({ ...isLoading, apiCallLoaded: true })
  //   } else {
  //     dispatch(setSpinner(true))
  //   }
  //   setPreSelections([...selections])
  //   getCampaigns(
  //     currentPage,
  //     20,
  //     search,
  //     sortedList.path && sortedList.path,
  //     sortedList.sort && sortedList.sort,
  //   ).then((res) => {
  //     setCampaigns(res.data?.campaigns)
  //     if (!sortedList.path) {
  //       setIsLoading({ ...isLoading, apiCallLoaded: false })
  //     } else {
  //       dispatch(setSpinner(false))
  //     }

  //     setPages({
  //       current_page: res?.data?.current_page,
  //       total_count: res?.data?.total_campaigns,
  //       total_pages: res?.data?.total_pages,
  //     })
  //   })
  //   setUnselectAll(false)
  // }, [search, currentPage, sortedList])

  // useEffect(() => {
  //   if (selections) {
  //     const cons = selections?.map((ele: any) =>
  //       ele.CreativeType.map((val: any) => val.content_type),
  //     )

  //     const flatten = (arr: any) => {
  //       return [].concat(...arr)
  //     }
  //     const fal: any = flatten(cons)
  //     setIsDisableDelete(fal?.includes('directMail'))
  //   }
  // }, [selections])

  // const onSort = (path: any, sort: any) => {
  //   setSortedList({ sort, path })
  //   setCurrentPage(1)
  // }

  const redirect = (campaign: any) => {
    if (campaign.status !== 'pending') {
      return `/campaigns/${campaign.id}`
    }
    if (!campaign.prospects_counts) {
      return `/create-campaign/${campaign?.id}/setup`
    }
    if (!campaign.CreativeType.length) {
      return `/create-campaign/${campaign?.id}/creatives`
    }
    return `/create-campaign/${campaign?.id}/calculate`
  }

  const _getStatus = (status: string) => (
    <CampaignStatusBadge
      status={getStatus(status)}
      // color={getStatusColor(status)}
      badgeIconClass={statusBadgeIconClass(status)}
      badgeicon={getStatusBadgeImage(status)}
    />
  )

  // ========== New Table Utilities
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false)

  const onDelete = async (_items: any[]) => {
    setDeleteIsLoading(true)
    const ids = _items.map((item: any) => item?.original?.id)
    try {
      await deleteCampaign({ campaign_id: ids })
      const filterDeletedItems = campaigns?.filter(
        (i: any) => !ids.includes(i.id),
      )
      setCampaigns(filterDeletedItems)
      toast.success(
        `${ids.length} Campaign${ids.length > 1 ? 's' : ''} Deleted`,
      )
      setDeleteModalIsOpen(false)
      setDeleteIsLoading(false)
    } catch (err: any) {
      toast.error(err)
      setDeleteIsLoading(false)
    }
  }

  const _fetchData = async () => {
    setFetchDataIsLoading(true)
    try {
      const res = await getCampaigns(1, 1000, null, null)
      if (res.data?.campaigns?.length) {
        setCampaigns(res.data?.campaigns)
      }
      setFetchDataIsLoading(false)
    } catch (err: any) {
      toast.error(err)
      setFetchDataIsLoading(false)
    }
  }

  useEffect(() => {
    if (!campaigns?.length) {
      _fetchData()
    }
  }, [campaigns])

  const onViewDetail = (item: any) => {
    return (
      <div className="">
        <FontAwesomeIcon
          onClick={() =>
            history.push({
              pathname: redirect(item),
            })
          }
          icon={faChevronRight}
          size="1x"
          color="gray"
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        />
      </div>
    )
  }
  const columns = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Campaign Name',
          accessor: 'name',
        },
        {
          Header: 'Days',
          accessor: (item: any) => item.days || '-',
        },
        {
          Header: 'Audience Size',
          accessor: (item: any) => item.prospects_counts || '-',
        },
        {
          Header: 'Date Uploaded',
          accessor: (item: any) => removeTimeFromDate(item.created_at),
        },
        {
          Header: 'Status',
          accessor: (item: any) => _getStatus(item.status),
        },
        {
          Header: ' ',
          accessor: onViewDetail,
        },
      ],
    },
  ]
  // ========== New Table Utilities

  const getList = () => {
    let component = <></>
    if (fetchDataIsLoading) {
      component = <Loading />
    } else if (!campaigns?.length) {
      component = (
        <EmptyStatus subHeading="You can try by creating new campaign to get better audience." />
      )
    } else {
      component = (
        <ReactTable
          title="campaign"
          data={campaigns}
          onDelete={onDelete}
          deleteModal={deleteModalIsOpen}
          setDeleteModal={setDeleteModalIsOpen}
          deleteIsLoading={deleteIsLoading}
          columns={columns}
        />
      )

      // component = (
      //   <TableSelectionWrapper
      //     selectionType="campaign"
      //     selections={selections}
      //     actions={[
      //       !isDiabaleDelete ? (
      //         <button
      //           type="button"
      //           className="Campaigns__select-action"
      //           onClick={() => setDeleteModalIsOpen(true)}
      //         >
      //           <Text
      //             on="tertiary-300"
      //             UNSAFE_className="Campaigns__delete_campaign_list"
      //           >
      //             Delete
      //           </Text>
      //         </button>
      //       ) : (
      //         <Text on="primary-700" weight="bold">
      //           Mailer Templates can&apos;t be deleted
      //         </Text>
      //       ),
      //     ]}
      //   >
      //     <Table
      //       rows={campaigns}
      //       columns={[
      //         {
      //           path: 'name',
      //           label: 'Campaign Name',
      //           width: '20%',
      //           alignment: 'left',
      //           onSort,
      //         },
      //         {
      //           path: 'days',
      //           label: 'Days',
      //           alignment: 'left',
      //           RowCell: (campaign) => campaign.days || '-',
      //           onSort,
      //         },
      //         {
      //           path: 'prospects_counts',
      //           label: 'Audience Size',
      //           alignment: 'left',
      //           RowCell: (campaign) => campaign.prospects_counts || '-',
      //           onSort,
      //         },
      //         {
      //           path: 'created_at',
      //           label: 'Date',
      //           alignment: 'left',
      //           RowCell: (campaign) => campaign.created_at.slice(0, 10) || '-',
      //           onSort,
      //         },
      //         {
      //           path: 'status',
      //           label: 'Status',
      //           alignment: 'left',
      //           onSort: (path: any, sort?: any) => {
      //             setSortedList({ sort, path })
      //           },

      //           RowCell: (campaign) => (
      //             <CampaignStatusBadge
      //               status={getStatus(campaign.status)}
      //               // color={getStatusColor(campaign.status)}
      //               badgeIconClass={statusBadgeIconClass(campaign.status)}
      //               badgeicon={getStatusBadgeImage(campaign.status)}
      //             />
      //           ),
      //         },
      //         {
      //           path: '',
      //           label: '',
      //           width: '80px',
      //           RowCell: (campaign: any) =>
      //             campaign.status !== 'pending' ? (
      //               <Link to={`/campaigns/${campaign.id}`}>
      //                 <img src={rightarrow} alt="rightarrow" />
      //               </Link>
      //             ) : (
      //               campaign.eltoro_status === 'pending' && (
      //                 <Link to={campaignRedirect(campaign)}>
      //                   <img src={rightarrow} alt="rightarrow" />
      //                 </Link>
      //               )
      //             ),
      //           alignment: 'left',
      //         },
      //       ]}
      //       onSelect={(id) => {
      //         if (id.length > 0) {
      //           setUnselectAll(false)
      //         }
      //         setSelections([...id])
      //         setPreSelections([...id])
      //       }}
      //       preSelected={PreSelections}
      //       unSelectAll={unselect}
      //       setUnselectAll={setUnselectAll}
      //       // fixedCellSize
      //     />
      //     {!!campaigns.length && (
      //       <Pagination
      //         title="campaigns"
      //         loading={isLoading.apiCallLoaded}
      //         currentPage={pages?.current_page}
      //         totalItems={pages?.total_count}
      //         totalPages={pages?.total_pages}
      //         onClickPrev={() => setCurrentPage(currentPage - 1)}
      //         onClickNext={() => setCurrentPage(currentPage + 1)}
      //       />
      //     )}
      //     {deleteModalIsOpen && (
      //       <ConfirmationModal
      //         // title="Are you sure you want to delete campaign(s)"
      //         heading="Are you sure you want to delete campaign"
      //         onSubmit={removeCampaign}
      //         onCancel={() => setDeleteModalIsOpen(false)}
      //         loading={deleteIsLoading}
      //       />
      //     )}
      //   </TableSelectionWrapper>
      // )
    }
    return component
  }

  return (
    <div className="Campaigns">
      {/* {isLoading.apiCallLoaded ? <Loading />  */}
      <PageHeader
        title=""
        style={{ height: '84px', background: 'var(--color-white)' }}
        LeftContent_ClassName="MyListings__header"
        subTitle={
          <span
            // style={{ marginLeft: '5px' }}
            className="MyListings__totalCounts"
          >
            You have <b>{campaigns.length} campaigns</b>
          </span>
        }
        actions={[
          <ReactTableSearchBar
            globalFilter={globalFilters}
            setGlobalFilter={setGlobalFilters}
          />,
          // <SearchBar
          //   value={search}
          //   onChange={(e) => setSearch(e.target.value)}
          // />,
          <Button
            kind="primary"
            to="/create-campaign/setup"
            UNSAFE_style={{
              width: '202px',
              height: '48px',
              justifyContent: 'center',
            }}
            size="l"
            // iconLeft={<FontAwesomeIcon icon={faPlus} />}
            weight="bold"
          >
            New Campaign
          </Button>,
        ]}
      />

      {/* <CloudSpongeWidget
            // cloudspongeKey="AILKrs3R1EuPAaz63Bdaqg"
            options={
              {
                // selectionLimit: availableNewUserSlots.bind(this),
                // afterSubmitContacts: '',
              }
            }
          >
            <button
              type="button"
              // href="/"
              className="cloudsponge-launch"
            >
              Add From Address Book
            </button>
          </CloudSpongeWidget> */}
      {getList()}
    </div>
  )
}
