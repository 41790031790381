import { Button, Modal, Text } from '@eltoro-ui/components'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloudSpongeWidget from 'Pages/Dashboard/_Components/contactsGetter/CloudSpongeWidget'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'

const ConfirmType = ({
  onCloseType,
  setCloseType,
  setUploadCSVModalIsOpen,
  setSyncContacts,
  setAddressBookContacts,
  addressBookContacts,
}: {
  onCloseType: (val?: string) => void
  setUploadCSVModalIsOpen: (state: boolean) => void
  setSyncContacts: (state: boolean) => void
  setAddressBookContacts: (state: []) => void
  addressBookContacts: any[]
  setCloseType: (state: boolean) => void
}) => {
  const history = useHistory()
  const [buttonActive, setButtonActive] = useState(true)
  const [btnCloudText, setbtnCloudText] = useState('second')
  const [loadFirst, setloadFirst] = useState(true)
  return (
    <Modal
      // header="Name and Tag your Target Audience"
      offClick={() => setCloseType(false)}
      className="SaveAudienceModal contacts_sync"
    >
      <div className="OnboardingModal__welcome ListingModalBg SaveAudiencePosition">
        <div className="OnboardingModal__welcomeHeader ListingAudienceModal">
          <div className="SaveAudienceModal__cancel">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => {
                setCloseType(false)
              }}
            />
          </div>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <Text
              on="white"
              weight="normal"
              // size="xxxl"
              UNSAFE_className="SaveAudienceModal__insideText"
              textAlign="center"
              UNSAFE_style={{ fontSize: '24px', lineHeight: '48px' }}
            >
              Select Upload Contact or Sync Email{' '}
            </Text>
          </div>
          <br />
          <div
            style={{
              margin: '0 30px',
              textAlign: 'center',
            }}
          >
            <div className="center-btns">
              <Text
                on="grey-500"
                size="m"
                UNSAFE_style={{ marginBottom: '0.5rem' }}
              />

              <br />

              <Button
                kind="primary"
                size="xl"
                weight="bolder"
                width="284px"
                UNSAFE_className="confirm_btn_upload"
                onClick={() => {
                  setUploadCSVModalIsOpen(true)
                  setCloseType(false)
                }}
              >
                Upload Contacts
              </Button>

              <CloudSpongeWidget
                setAddressBookContacts={setAddressBookContacts}
                // cloudspongeKey="AILKrs3R1EuPAaz63Bdaqg"
                setButtonActive={setButtonActive}
                loadFirst={loadFirst}
                onCloseTypea={(a: any) => {
                  onCloseType('sync')
                  setloadFirst(false)
                }}
                buttonActive={buttonActive}
                // setbtnCloudText={setbtnCloudText}
              >
                {/* <button
                  type="button"
                  className="cloudsponge-launch"
                  data-cloudsponge-source="gmail"
                >
                  Add your Gmail Contacts
                </button> */}
                <button
                  type="button"
                  className={`cloudsponge-launch MyListingsLens__FooterBtn confirm_btn_sync loading ${
                    buttonActive && 'Button--disabled '
                  }`}
                  disabled={buttonActive}
                  onClick={() => {
                    setCloseType(false)
                  }}
                >
                  {buttonActive ? 'Loading' : 'Sync Email Accounts'}

                  {buttonActive && (
                    <>
                      <span className="one">.</span>
                      <span className="two">.</span>
                      <span className="three">.</span>{' '}
                    </>
                  )}
                </button>
              </CloudSpongeWidget>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmType
