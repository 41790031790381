export const notificationsReducer: undefined | any = (
  state = { isUnReadNotification: false, userNotifications: null },
  action: any,
) => {
  switch (action.type) {
    case 'SET_UN_READ_NOTIFICATION':
      return { isUnReadNotification: action.payload }
    case 'SET_USER_NOTIFICATION':
      return { userNotifications: action.payload }
    default:
      return { ...state }
  }
}
