import React, { FC } from 'react'
import { Text } from '@eltoro-ui/components'
import './DeleteLabel.scss'

type deleteLable = {
  onDelete: () => void
  deleteItemCounts: number
  title: string
}

export const DeleteLable: FC<deleteLable> = ({
  onDelete,
  deleteItemCounts,
  title,
}) => (
  <div className="TableSelectionWrapper__selection-header">
    <Text on="white" UNSAFE_className="TableSelectionWrapper__selected-row">
      {`${deleteItemCounts} ${title}${
        deleteItemCounts > 1 ? 's' : ''
      } selected`}
    </Text>
    <div className="TableSelectionWraper__actions">
      <button
        type="button"
        className="Campaigns__select-action"
        onClick={onDelete}
      >
        <Text
          UNSAFE_className="TableSelectionWrapper__selected-row-delete"
          on="tertiary-300"
        >
          Delete
        </Text>
      </button>
    </div>
    <div className="bottomAges" />
  </div>
)
