import {
  ResponseType,
  TChargebeeCreateUser,
  TSubscription,
  TCreateSubscription,
  TAddBundle,
  TTransactions,
  TGetCard,
  AddCard,
  TAddCardRequestBody,
  TRemoveCard,
  EditCard,
  TEditCardRequestBody,
} from 'types'
import requests from '../http'

export const createChargebeeUser = async (
  body: Partial<any>,
): Promise<ResponseType<TChargebeeCreateUser>> =>
  requests.post('/chargebee/create_user', body)

export const getChargebeePackages = async (): Promise<
  ResponseType<TSubscription>
> => requests.get('/chargebee/subscriptions')

export const chargebeeSubscription = async (
  body: Partial<any>,
): Promise<ResponseType<TCreateSubscription>> =>
  requests.post('/chargebee/create_subscription', body)

export const chargebeeBundles = async (): Promise<ResponseType<any>> =>
  requests.get('/chargebee/bundles')

export const addChargebeeBundle = async (
  body: Object,
): Promise<ResponseType<TAddBundle>> =>
  requests.post('/chargebee/add_bundle', body)

export const getChargebeeTransactions = async (
  page: number,
  size: number,
): Promise<ResponseType<TTransactions>> =>
  requests.get(`/chargebee/transactions?page=${page}&size=${size}`)

export const getPaymentCards = async (): Promise<ResponseType<TGetCard>> =>
  requests.get('/chargebee/get_card', undefined)

export const addCard = async (
  body: TAddCardRequestBody,
): Promise<ResponseType<AddCard>> => requests.post('/chargebee/add_card', body)

export const editCard = async (
  body: TEditCardRequestBody,
): Promise<ResponseType<EditCard>> => requests.put('/chargebee/edit_card', body)

export const removeCard = async (
  body: Object,
): Promise<ResponseType<TRemoveCard>> =>
  requests.post('/chargebee/remove_card', body)
