import { Button, Table, Text } from '@eltoro-ui/components'
import { faWalking } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination, PageHeader } from 'Components'
import { UploadCSVModal } from 'Pages/MyContacts/components'
import ConfirmType from 'Pages/MyContacts/components/ConfirmModal.tsx/ConfirmType'
import CloudSpongeWidget from 'Pages/Dashboard/_Components/contactsGetter/CloudSpongeWidget'
import { faUpload } from '@fortawesome/pro-solid-svg-icons'

import React, { useEffect, useState } from 'react'
import { getContacts } from 'Requests'
import './TalkToSphereLens.scss'
import { TalkToSphereList } from './TalkToSphereList'
import syncIcon from '../../../../assets/Images/syncIcon.png'

export const TalkToSphereLens = () => {
  const [closeType, setCloseType] = useState(false)
  const [syncContacts, setSyncContacts] = useState(false)
  const [uploadCSVModalIsOpen, setUploadCSVModalIsOpen] = useState(false)
  const [addressBookContacts, setAddressBookContacts] = useState([])
  const [changeState, setchangeState] = useState(false)

  const _getContacts = async () => {
    // setPreSelections([...selections])
    getContacts(1, 20).then((res) => {
      // setContacts(res.data?.contact_files)
      // setPages({
      //   current_page: res?.data?.current_page,
      //   total_count: res?.data?.total_count,
      //   total_pages: res?.data?.total_pages,
      // })
      // setIsLoading({ ...isLoading, apiCallLoaded: false })
    })
  }

  useEffect(() => {
    // setIsLoading({ ...isLoading, apiCallLoaded: true })
    _getContacts()

    // setUnselectAll(false)
  }, [])
  const [buttonActive, setButtonActive] = useState(true)
  const [loadFirst, setloadFirst] = useState(true)

  return (
    <div className="talkToSphere">
      {/* <div className="talkToSphere__head">
        <button
          className="talkToSphere__headButton"
          type="button"
          onClick={() => setCloseType(true)}
        >
          Upload More Contacts
        </button>
      </div> */}

      <PageHeader
        title=""
        subTitle=""
        actionsStyle="MyListings__pageHeader"
        UNSAFE_CLASSNAME="MyListings__header"
        actions={[
          <Button
            kind="primary"
            UNSAFE_style={{ marginBottom: '0.5rem' }}
            size="l"
            iconLeft={<FontAwesomeIcon icon={faUpload} />}
            weight="bold"
            onClick={() => {
              setUploadCSVModalIsOpen(true)
              setCloseType(false)
            }}
          >
            Upload Contacts
          </Button>,
          <CloudSpongeWidget
            setAddressBookContacts={setAddressBookContacts}
            setButtonActive={setButtonActive}
            loadFirst={loadFirst}
            onCloseTypea={(a: any) => {
              setloadFirst(false)
            }}
            buttonActive={buttonActive}
          >
            <button
              type="button"
              className={`cloudsponge-launch MyListingsLens__FooterBtn confirm_btn_sync loading ${
                buttonActive && 'Button--disabled '
              }`}
              disabled={buttonActive}
              onClick={() => {
                setCloseType(false)
              }}
            >
              <img src={syncIcon} alt="" style={{ margin: '0 8px' }} />
              {buttonActive ? 'Loading' : 'Sync Email Accounts'}

              {buttonActive && (
                <>
                  <span className="one">.</span>
                  <span className="two">.</span>
                  <span className="three">.</span>{' '}
                </>
              )}
            </button>
          </CloudSpongeWidget>,
        ]}
      />

      <TalkToSphereList />

      {closeType && (
        <ConfirmType
          setSyncContacts={setSyncContacts}
          setAddressBookContacts={setAddressBookContacts}
          addressBookContacts={addressBookContacts}
          setUploadCSVModalIsOpen={setUploadCSVModalIsOpen}
          setCloseType={setCloseType}
          onCloseType={(type?: string) => {
            // setTimeout(() => {

            if (type) {
              setchangeState(true)
            }

            // }, 2000)
          }}
        />
      )}

      {uploadCSVModalIsOpen && (
        <UploadCSVModal
          onClose={(e?: boolean) => {
            if (e) {
              _getContacts()
            }
            setUploadCSVModalIsOpen(false)
          }}
        />
      )}
    </div>
  )
}
