import React from 'react'
import './CampaignCreation.scss'
import { useRouteMatch, Route, Switch } from 'react-router-dom'
import {
  CampaignSetup,
  CampaignCheckout,
  CampaignCreatives,
} from './components'

export const CampaignCreation = () => {
  const { url } = useRouteMatch()

  return (
    <div className="CampaignCreation">
      <Switch>
        <Route path={[`${url}/setup`, `${url}/:campaignId/setup`]}>
          <CampaignSetup />
        </Route>
        <Route path={`${url}/:campaignId/creatives`}>
          <CampaignCreatives />
        </Route>
        <Route path={`${url}/:campaignId/calculate`}>
          <CampaignCheckout />
        </Route>
      </Switch>
    </div>
  )
}
