import { Button, Form, FormItem, TextInput } from '@eltoro-ui/components'
import { PageHeader, Section } from 'Components'
import React, { FC, useState } from 'react'
// import { createnotification } from 'Requests'
import toast from 'react-hot-toast'
import { createNotification } from 'Requests/Request_Methods/superAdminMethods'
import { NotificationSendType } from 'types'
import './Notification.scss'

const initialState = {
  message: '',
  title: '',
}

export const Notification: FC = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [state, setState] = useState<NotificationSendType>(initialState)

  const isDisabled = () => {
    const { title, message } = state
    return !title && !message
  }

  const onSubmit = () => {
    setLoading(true)

    createNotification(state)
      .then(() => {
        toast.success('Notification has been sent')

        setState(initialState)
        setLoading(false)
      })
      .catch(() => {
        toast.error('Send error')
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string) => {
    if (key === 'message' && value.length <= 100) {
      setState({ ...state, [key]: value })
    }
    if (key === 'title' && value.length <= 50) {
      setState({ ...state, [key]: value })
    }
  }

  return (
    <>
      <PageHeader
        title="Send New Notification"
        UNSAFE_CLASSNAME_TITLE="header-user-title"
        LeftContent_ClassName="MyListings__header"
        subTitle=""
        actions={[]}
      />
      <div className="notification_section">
        <Form onSubmit={onSubmit}>
          <Section
            UNSAFE_className_container="notification_section__container"
            spacer={false}
            dark
          >
            <FormItem
              htmlFor="title"
              label="Subject"
              // data-testid="title"
              FontSize="18px"
              Height="1rem"
              required
            >
              <TextInput
                className="notification_section__input_field"
                data_testid="title"
                value={state.title}
                onChange={(e) => onChange('title', e.target.value)}
                placeholder="Write your subject"
              />
            </FormItem>
            <br />
            <FormItem
              htmlFor="Message"
              label="Body"
              FontSize="18px"
              Height="1rem"
              required
            >
              <textarea
                value={state.message}
                data-testid="message"
                onChange={(e) => onChange('message', e.target.value)}
                placeholder="Write your body"
                className="notification_section__input_text_area"
              />
            </FormItem>
            <span className="count_notification">
              {state.message.length ?? 0} / 100
            </span>
            <div className="notification_section__submit_container">
              <Button
                type="submit"
                kind="primary"
                UNSAFE_className="notification_section__submit"
                disabled={isDisabled() || loading}
              >
                Send
              </Button>
            </div>
          </Section>
        </Form>
      </div>
    </>
  )
}
