import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './InputBox.scss'

type InputTypes = {
  type?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  isError?: Boolean
  icon?: IconProp | JSX.Element
  validIcon?: JSX.Element
  placeHolder?: string | undefined
  value?: string | number | readonly string[] | undefined
  wdt?: string | number | undefined
  border?: string | number | undefined
  FontSize?: string | number | undefined
  Height?: string | number | undefined
  paddingLeft?: string | number | undefined
  paddingTop?: string | number | undefined
  EyePassIcon?: JSX.Element
  reference?: any
  UNSAFE_CLASS_text?: string | number | undefined
  UNSAFE_CLASS_icon?: string | number | undefined
  UNSAFE_CLASS_Boolean?: Boolean
  UNSAFE_CLASS_RIGHT_ICON?: Boolean
  UNSAFE_CLASS_INPUT_BOX?: string
  disabled?: boolean
  Input__ClassName?: string
  data_testid?: string
  prefix?: String
}
export const InputBox: React.FC<InputTypes> = ({
  type,
  onChange,
  isError,
  disabled = false,
  icon,
  validIcon,
  placeHolder,
  value,
  wdt,
  border,
  FontSize,
  Height,
  paddingLeft,
  paddingTop,
  EyePassIcon,
  UNSAFE_CLASS_icon,
  UNSAFE_CLASS_text,
  UNSAFE_CLASS_Boolean = true,
  UNSAFE_CLASS_RIGHT_ICON,
  UNSAFE_CLASS_INPUT_BOX,
  Input__ClassName,
  prefix,
  data_testid,
  reference,
}) => {
  return (
    <>
      <div
        style={{
          position:
            UNSAFE_CLASS_icon && UNSAFE_CLASS_Boolean ? 'absolute' : 'relative',
        }}
        className={UNSAFE_CLASS_INPUT_BOX}
      >
        <div
          className={
            isError
              ? `inputLeftIconError ${UNSAFE_CLASS_icon}`
              : `inputLeftIcon ${UNSAFE_CLASS_icon}`
          }
        >
          {icon}
          <span style={{ color: 'black' }} className="inputPrefix">
            {prefix}
          </span>
        </div>
        {/* {prefix && <p className="inputBoxPrefix">{prefix}</p>} */}
        <input
          ref={reference}
          style={{
            width: wdt,
            height: Height,
            border: isError ? '1px solid #bd0000' : border,
            fontSize: FontSize,
            paddingLeft,
            paddingTop,
          }}
          className={`
            ${
              isError
                ? `setPasswordInputError ${UNSAFE_CLASS_text}`
                : `setPasswordInput ${UNSAFE_CLASS_text}`
            }
          ${Input__ClassName}`}
          disabled={disabled}
          type={type}
          onChange={onChange}
          placeholder={placeHolder}
          value={value}
          data-testid={data_testid}
        />
        <div
          style={{
            top: UNSAFE_CLASS_icon && '3px',
            left: UNSAFE_CLASS_RIGHT_ICON && '351px',
          }}
          className={`${
            isError ? 'inputRightIcon campaignInputRightIcon' : 'hidden'
          } `}
        >
          {validIcon}
        </div>
      </div>
      <div className="inputRightIcon">{EyePassIcon}</div>
    </>
  )
}

InputBox.defaultProps = {
  type: 'text',
  Height: '40px',
  wdt: '400px',
  border: '3px solid #ccc',
  FontSize: '18px',
  paddingLeft: '45px',
}
