import React from 'react'
import { TextInput, FormItem } from '@eltoro-ui/components'

export const CardFormInput = ({
  htmlFor,
  label,
  errorMessage,
  valid,
  value,
  setter,
  ...props
}: {
  htmlFor: string
  label: string
  errorMessage?: string
  valid: boolean
  value: string
  setter: (val: string) => void
  required?: boolean
}) => {
  const { required } = props
  return (
    <FormItem
      htmlFor={htmlFor}
      label={label}
      errorMessage={errorMessage}
      valid={valid}
      required={required}
    >
      <TextInput value={value} onChange={(e) => setter(e.target.value)} />
    </FormItem>
  )
}
