import React, { ReactNode, ChangeEvent } from 'react'
import './RadioButtonCustom.scss'
import classNames from 'classnames'

export const RadioButtonCustom: React.FunctionComponent<{
  isCircular?: Boolean
  checked: boolean
  onChange: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void
  label?: ReactNode
  id?: string
  size?: 's' | 'm' | 'l' | 'xl'
  fontOverWrite?: boolean | undefined
  checkBorder?: string | undefined
  RadioButtonCustomActive?: string | undefined
  background?: string | undefined
}> = ({
  checked,
  onChange,
  label,
  id,
  size = 'm',
  isCircular,
  fontOverWrite,
  checkBorder,
  RadioButtonCustomActive,
  background,
}) => {
  const sizeClass = ` RadioButtonCustom--${size}-size`
  const modifierClasses = classNames(' ', sizeClass, {
    'RadioButtonCustom--checked': checked,
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.currentTarget.checked, event)
    }
  }

  return (
    <div
      className={`RadioButtonCustom${modifierClasses} ${
        checked ? RadioButtonCustomActive : ''
      }`}
    >
      <label htmlFor={id} className="RadioButtonCustom__wrapper-label">
        <div
          className="RadioButtonCustom__input-wrapper"
          style={{
            borderRadius: isCircular ? '100rem' : '',
            // background: '#fff',
            border: checkBorder,
            background: !checked ? background : '',
          }}
        >
          <input
            className="RadioButtonCustom__input"
            type="radio"
            onChange={handleChange}
            // id={id}
            checked={checked}
          />
          <div className="RadioButtonCustom__checkmark" />
        </div>
        {label && (
          <span
            className={`RadioButtonCustom__label ${
              fontOverWrite && 'fontOverwrite'
            }`}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  )
}
