import React, { ReactNode } from 'react'
import { StandardLonghandProperties } from 'csstype'

import './Header.scss'

/* eslint-disable react/self-closing-comp */
export const Header = ({
  justifyContent,
  children,
}: {
  justifyContent?: StandardLonghandProperties['justifyContent']
  children: ReactNode
}) => {
  return (
    <div className="HeaderContainer">
      <div className="Header" style={{ justifyContent }}>
        {children}
      </div>
      <div className="HeaderContainer__BottomBorder"></div>
    </div>
    // <hr />
    // </div>
  )
}
