import React from 'react'

export const SpinnerLoader: React.FC<{
  style: any
}> = ({ style }) => {
  return (
    <div>
      <div className="before-spinner" style={style} />
    </div>
  )
}
