export const TableReducer: undefined | any = (
  state = { tableSelectedList: [] },
  action: any,
) => {
  switch (action.type) {
    case 'SET_SELECTED_TABLELIST':
      return { tableSelectedList: action.payload }

    default:
      return { ...state }
  }
}
