import { Button, Modal, Table, Text } from '@eltoro-ui/components'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
  // faSpinner,
  // faSpinnerThird,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { useDispatch } from 'react-redux'
// import { setSpinner } from 'Redux/actions'
// import TableContent from 'Components/ReactTable/ReactTable'
import {
  // ConfirmationModal,
  EmptyStatus,
  Loading,
  PageHeader,
  ReactTable,
  // Pagination,
  // TableSelectionWrapper,
} from 'Components'
import {
  //  getFileName,
  removeTimeFromDate,
} from 'Helpers'
// import CloudSpongeWidget from 'Pages/Dashboard/_Components/contactsGetter/CloudSpongeWidget'
import React, { useEffect, useState } from 'react'
// import toast from 'react-hot-toast'
import {
  //  Link,
  useHistory,
} from 'react-router-dom'
import toast from 'react-hot-toast'
import { deleteContacts, getContacts } from 'Requests'
import { VotingModal } from 'Components/VotingModal'
import { UploadCSVModal } from './components'
import ConfirmType from './components/ConfirmModal.tsx/ConfirmType'
// import syncIcon from '../../assets/Images/syncIcon.png'
import uploadcontact from '../../assets/Images/uploadcontact.png'
import Uploadmodalcontacts from '../../assets/Images/uploadmodalcontacts.png'
import syciconbutton from '../../assets/Images/syciconbutton.png'
import Syncontacts from '../../assets/Images/syncontacts.png'
import './MyContacts.scss'

export const MyContactsPage = () => {
  // const [selections, setSelections] = useState<any[]>([])
  // const [pages, setPages] = useState<any>()
  // const [currentPage, setCurrentPage] = useState<any>(1)
  // const [unselect, setUnselectAll] = useState(false)
  // const [isLoading, setIsLoading] = useState({ apiCallLoaded: true })
  // const [PreSelections, setPreSelections] = useState<any[]>([])
  // const { status, value } = useAsync(() => getContacts())
  // const [sortedList, setSortedList] = useState({
  //   sort: null,
  //   path: null,
  // })

  const [contacts, setContacts] = useState<any[] | undefined>([])
  // const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [uploadCSVModalIsOpen, setUploadCSVModalIsOpen] = useState(false)
  // const [deleteIsLoading, setDeleteIsLoading] = useState(false)
  const [closeType, setCloseType] = useState(false)
  const [syncContacts, setSyncContacts] = useState(false)
  const history = useHistory()
  const [addressBookContacts, setAddressBookContacts] = useState([])

  // const onSort = (path: any, sort: any) => {
  //   setSortedList({ sort, path })
  //   setCurrentPage(1)
  // }

  // const _getContacts = async () => {
  //   setPreSelections([...selections])
  //   getContacts(
  //     currentPage,
  //     20,
  //     sortedList.path && sortedList.path,
  //     sortedList.sort && sortedList.sort,
  //   ).then((res) => {
  //     setContacts(res.data?.contacts)
  //     setPages({
  //       current_page: res?.data?.current_page,
  //       total_count: res?.data?.total_count,
  //       total_pages: res?.data?.total_pages,
  //     })

  //     if (!sortedList.path) {
  //       setIsLoading({ ...isLoading, apiCallLoaded: false })
  //     } else {
  //       dispatch(setSpinner(false))
  //     }
  //     // setIsLoading({ ...isLoading, apiCallLoaded: false })
  //   })
  // }
  const [changeState, setchangeState] = useState(false)
  const [uploadcontactModal, setUploadcontactModal] = useState(false)
  const [synUploadModal, setSynUploadModal] = useState(false)
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   // setIsLoading({ ...isLoading, apiCallLoaded: true })
  //   if (!sortedList.path) {
  //     setIsLoading({ ...isLoading, apiCallLoaded: true })
  //   } else {
  //     dispatch(setSpinner(true))
  //   }
  //   _getContacts()

  //   setUnselectAll(false)
  // }, [currentPage, sortedList])

  // useEffect(() => {
  //   if (unselect) {
  //     setSelections([])
  //     setPreSelections([])
  //   }
  // }, [unselect])

  useEffect(() => {
    if (changeState && addressBookContacts.length > 0) {
      history.push({
        pathname: '/sync-email',
        state: { addressBookContacts: JSON.stringify(addressBookContacts) },
      })
    }
  }, [changeState])

  // const onDeleteContact = () => {
  //   setDeleteIsLoading(true)
  //   const ids = selections?.map((i) => i.id)

  //   deleteContacts({ ids }).then((deleteRes) => {
  //     setSelections([])
  //     setPreSelections([])
  //     getContacts(currentPage, 20).then((res) => {
  //       setUnselectAll(true)
  //       setContacts(res.data?.contacts)
  //       setPages({
  //         current_page: res?.data?.current_page,
  //         total_count: res?.data?.total_count,
  //         total_pages: res?.data?.total_pages,
  //       })
  //       toast.success(
  //         `${ids.length} Contact${ids.length > 1 ? 's' : ''} Deleted`,
  //       )
  //       setDeleteModalIsOpen(false)
  //       setDeleteIsLoading(false)
  //     })
  //   })
  // }

  // const getContactName = (name: string) => {
  //   if (name?.includes('ios')) {
  //     return 'iOS'
  //   }
  //   return name || '-'
  // }

  // ========== New Table Utilities
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false)

  const getContactsUploadedSource = (source: string) => {
    return source.includes('ios') ? 'iOS' : source || '-'
  }

  const _fetchData = async () => {
    setFetchDataIsLoading(true)
    try {
      const res = await getContacts(1, 1000, null, null)
      if (res.data?.contacts?.length) {
        setContacts(res.data?.contacts)
      }
      setFetchDataIsLoading(false)
    } catch (err: any) {
      toast.error(err)
      setFetchDataIsLoading(false)
    }
  }

  useEffect(() => {
    if (!contacts?.length) {
      _fetchData()
    }
  }, [contacts])

  const onViewDetail = (item: any) => {
    return (
      <div className="">
        <FontAwesomeIcon
          onClick={() =>
            history.push({
              pathname: `/my-sphere/${item.id}/contact-details`,
            })
          }
          icon={faChevronRight}
          size="1x"
          color="gray"
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        />
      </div>
    )
  }

  const columns = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'First Name',
          accessor: 'first_name',
        },
        {
          Header: 'Last Name',
          accessor: 'last_name',
        },
        {
          Header: 'Upload Source',
          accessor: (item: any) => getContactsUploadedSource(item.device),
        },
        {
          Header: 'Date Uploaded',
          accessor: (item: any) => removeTimeFromDate(item.date_uploaded),
        },
        {
          Header: 'Date Updated',
          accessor: (item: any) => removeTimeFromDate(item.updated_at),
        },
        {
          Header: ' ',
          accessor: onViewDetail,
        },
      ],
    },
  ]
  // ========== New Table Utilities

  const getList = () => {
    let component = <></>
    if (fetchDataIsLoading) {
      component = <Loading />
    } else if (!contacts?.length) {
      component = (
        <EmptyStatus subHeading="You can try upload contacts to get better audience." />
      )
    } else {
      component = (
        <ReactTable
          title="contact"
          data={contacts}
          columns={columns}
          selectFeature={false}
        />
      )
      // component = (
      //   <TableSelectionWrapper
      //     selectionType="contact"
      //     selections={selections}
      //     actions={[
      //       <button
      //         type="button"
      //         className="Campaigns__select-action"
      //         onClick={() => setDeleteModalIsOpen(true)}
      //       >
      //         <Text on="tertiary-300" weight="bold">
      //           Delete
      //         </Text>
      //       </button>,
      //     ]}
      //   >
      //     <Table
      //       rows={contacts}
      //       columns={[
      //         {
      //           path: 'first_name',
      //           label: 'First Name',
      //           width: '20%',
      //           alignment: 'left',
      //           RowCell: (contact) => getFileName(contact.first_name) || '-',
      //           onSort,
      //         },
      //         {
      //           path: 'last_name',
      //           label: 'Last Name',
      //           width: '20%',
      //           alignment: 'left',
      //           RowCell: (contact) => getFileName(contact.last_name) || '-',
      //           onSort,
      //         },
      //         {
      //           path: 'device',
      //           label: 'Upload Source',
      //           alignment: 'left',
      //           width: '20%',
      //           RowCell: (contact) => getContactName(contact.device),
      //           onSort,
      //         },

      //         {
      //           path: 'date_uploaded',
      //           label: 'Date Uploaded',
      //           alignment: 'left',
      //           width: '20%',
      //           RowCell: (contact) =>
      //             removeTimeFromDate(contact.date_uploaded) || '-',
      //         },
      //         {
      //           path: 'updated_at',
      //           label: 'Date Updated',
      //           alignment: 'left',
      //           width: '20%',
      //           RowCell: (contact) =>
      //             removeTimeFromDate(contact.updated_at) || '-',
      //         },
      //         {
      //           path: '',
      //           label: '',
      //           width: '80px',
      //           RowCell: (contact: any) => (
      //             // <Link to={`/my-sphere/${contact.id}/contact-details`}>
      //             <FontAwesomeIcon
      //               onClick={() =>
      //                 history.push({
      //                   pathname: `/my-sphere/${contact.id}/contact-details`,
      //                 })
      //               }
      //               icon={faChevronRight}
      //               size="1x"
      //               color="gray"
      //               style={{
      //                 fontSize: '20px',
      //                 fontWeight: 'bold',
      //                 cursor: 'pointer',
      //               }}
      //             />
      //             // </Link>
      //           ),
      //           alignment: 'left',
      //         },
      //       ]}
      //       onSelect={(id) => {
      //         if (id.length > 0) {
      //           setUnselectAll(false)
      //         }
      //         setSelections([...id])
      //         setPreSelections([...id])
      //       }}
      //       preSelected={PreSelections}
      //       unSelectAll={unselect}
      //       setUnselectAll={setUnselectAll}
      //       // fixedCellSize
      //     />

      //     {/* Footer|Pagination section */}
      //     {!!contacts?.length && (
      //       <Pagination
      //         title="contacts"
      //         loading={isLoading.apiCallLoaded}
      //         currentPage={pages?.current_page}
      //         totalItems={pages?.total_count}
      //         totalPages={pages?.total_pages}
      //         onClickPrev={() => setCurrentPage(currentPage - 1)}
      //         onClickNext={() => setCurrentPage(currentPage + 1)}
      //       />
      //     )}
      //   </TableSelectionWrapper>
      // )
    }
    return component
  }
  const [buttonActive, setButtonActive] = useState(true)
  const [loadFirst, setloadFirst] = useState(true)

  return (
    <div className="MyContacts">
      {/* Header Section */}
      <PageHeader
        title=""
        style={{ height: '84px', background: 'var(--color-white)' }}
        actionsStyle="MyListings__pageHeader"
        UNSAFE_CLASSNAME="MyListings__header"
        subTitle={
          <span
            style={{ marginLeft: '5px' }}
            className="MyListings__totalCounts"
          >
            You have <b>{contacts?.length} contacts</b>
          </span>
        }
        actions={[
          <Button
            // kind="primary"
            UNSAFE_style={{
              marginBottom: '0.5rem',
              background: '#D1D1D1',
              border: 'none',
              width: '231px',
              height: '45px',
            }}
            size="l"
            iconLeft={
              <>
                <img
                  src={uploadcontact}
                  alt="uploadicon"
                  style={{ marginRight: '-0.8rem' }}
                />
              </>
            }
            weight="bold"
            // onClick={() => setCloseType(true)}
            onClick={() => {
              // setUploadCSVModalIsOpen(true)
              setCloseType(false)
              setUploadcontactModal(true)
            }}
          >
            Upload Contacts
          </Button>,
          <Button
            // kind="primary"
            UNSAFE_style={{
              marginBottom: '0.5rem',
              background: '#D1D1D1',
              border: 'none',
              width: '231px',
              height: '45px',
            }}
            size="l"
            iconLeft={
              <>
                <img
                  src={syciconbutton}
                  alt="uploadicon"
                  style={{ marginLeft: '10px' }}
                />
              </>
            }
            weight="bold"
            onClick={() => setSynUploadModal(true)}
          >
            Sync Email Accounts
          </Button>,
          // <CloudSpongeWidget
          //   setAddressBookContacts={setAddressBookContacts}
          //   // cloudspongeKey="AILKrs3R1EuPAaz63Bdaqg"
          //   setButtonActive={setButtonActive}
          //   loadFirst={loadFirst}
          //   onCloseTypea={(a: any) => {
          //     // onCloseType('sync')
          //     setchangeState(true)

          //     setloadFirst(false)
          //   }}
          //   buttonActive={buttonActive}
          //   // setbtnCloudText={setbtnCloudText}
          // >
          //   <button
          //     type="button"
          //     className={`cloudsponge-launch MyListingsLens__FooterBtn confirm_btn_sync loading ${
          //       buttonActive && 'Button--disabled '
          //     }`}
          //     disabled={buttonActive}
          //     onClick={() => {
          //       setCloseType(false)
          //     }}
          //   >
          //     <img src={syncIcon} alt="" style={{ margin: '0 8px' }} />
          //     {buttonActive ? 'Loading' : 'Sync Email Accounts'}

          //     {buttonActive && (
          //       <>
          //         <span className="one">.</span>
          //         <span className="two">.</span>
          //         <span className="three">.</span>{' '}
          //       </>
          //     )}
          //   </button>
          // </CloudSpongeWidget>,
        ]}
      />
      {uploadcontactModal && (
        <Modal offClick={() => setUploadcontactModal(false)}>
          <VotingModal
            handleModel={() => setUploadcontactModal(false)}
            icons={Uploadmodalcontacts}
            title="Upload Contacts"
            subTitle="The agent will have the ability to upload or write the contacts manually to gain more insights into the clients."
          />
        </Modal>
      )}
      {synUploadModal && (
        <Modal offClick={() => setSynUploadModal(false)}>
          <VotingModal
            handleModel={() => setSynUploadModal(false)}
            icons={Syncontacts}
            title="Sync Contacts"
            subTitle="The agent can sync the email contacts from the email provider (google, yahoo, AOL, etc. ) and gain more insights into the clients"
          />
        </Modal>
      )}
      {/* {status === 'pending' ? 'loading' : 'loaded'} */}
      {/* Body Section */}
      {getList()}
      {/* {status === 'pending' ? 'loading' : 'loaded'} */}
      {/* Delete Confirmation Modal */}
      {/* {deleteModalIsOpen && (
        <ConfirmationModal
          // title="Are you sure you want to delete contact"
          // onSubmit={onDeleteContact}
          onSubmit={() => setDeleteModalIsOpen(false)}
          onCancel={() => setDeleteModalIsOpen(false)}
          loading={deleteIsLoading}
        />
      )} */}
      {/* Upload Contacts Modal */}

      {closeType && (
        <ConfirmType
          setSyncContacts={setSyncContacts}
          setAddressBookContacts={setAddressBookContacts}
          addressBookContacts={addressBookContacts}
          setUploadCSVModalIsOpen={setUploadCSVModalIsOpen}
          setCloseType={setCloseType}
          onCloseType={(type?: string) => {
            if (type) {
              setchangeState(true)
            }
          }}
        />
      )}

      {uploadCSVModalIsOpen && (
        <UploadCSVModal
          onClose={(e?: boolean) => {
            if (e) {
              _fetchData()
            }
            setUploadCSVModalIsOpen(false)
          }}
        />
      )}
    </div>
  )
}
