import { MapRawType, MapLocationType } from 'types'

export const openStreepMapResolver = (
  results: MapRawType[],
): MapLocationType[] =>
  results.map((result) => ({
    x: result.lon,
    y: result.lat,
    label: result.display_name,
    bounds: [
      [result.boundingbox[0], result.boundingbox[2]],
      [result.boundingbox[1], result.boundingbox[3]],
    ],
    raw: result,
  }))
