import React from 'react'
import './ErrorMsg.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ErrorMsgType = {
  title?: string
  icon?: JSX.Element
  UNSAFE_CLASS_icon?: string | number | undefined
  data_testid?: string
}
export const ErrorMsg: React.FC<ErrorMsgType> = ({
  title,
  icon,
  UNSAFE_CLASS_icon,
  data_testid,
}) => {
  return (
    <div
      data-testid={data_testid}
      className={`setPasswordError ${UNSAFE_CLASS_icon}`}
    >
      <div>{icon}</div>
      <div>
        <p className="setPasswordErrorMsg">{title}</p>
      </div>
    </div>
  )
}

export default ErrorMsg
