import React from 'react'
import { GridBox, Spacer } from '@eltoro-ui/components'
import { Section, LabelValuePair, Notes } from 'Components'
import { APIFeatureType } from 'types'
import { browserLog } from 'Tools'
import { putNotesByListingId } from 'Requests/Request_Methods/listingMethods'
import toast from 'react-hot-toast'
import './ListingDetails.scss'

export const ListingDetails = ({
  listing,
  onNotesSubmit,
  loading,
}: {
  listing?: any
  onNotesSubmit: (notes: any) => void
  loading?: boolean
}) => {
  if (!listing) return null

  const {
    listingid,
    listing_price,
    size,
    last_sold,
    year_built,
    beds,
    baths,
    pool,
    hometype,
    color,
    note,
  } = listing

  return (
    <div className="ListingDetails">
      <Section
        UNSAFE_className_container="section_containers"
        title="Information"
        noBackground
        UNSAFE_className_text="heading-color"
      >
        <div className="Section__spacer" />
        <div className="ListingDetails__body">
          <GridBox gap="2rem" UNSAFE_className="container">
            <LabelValuePair
              UNSAFE_className_Value="text-campaign-details-value"
              UNSAFE_className="text-campaign-details"
              label="Price"
              value={`${
                listing_price ? `$${listing_price?.toLocaleString()}` : 'N/A'
              }`}
            />
            <LabelValuePair
              UNSAFE_className_Value="text-campaign-details-value"
              UNSAFE_className="text-campaign-details"
              label="Size"
              value={`${size ? `${size?.toLocaleString()} SQFT` : 'N/A'}`}
            />
            <LabelValuePair
              UNSAFE_className_Value="text-campaign-details-value"
              UNSAFE_className="text-campaign-details"
              label="Year Build"
              value={year_built}
            />

            <LabelValuePair
              UNSAFE_className_Value="text-campaign-details-value"
              UNSAFE_className="text-campaign-details"
              label="Bed"
              value={beds}
            />
            <LabelValuePair
              UNSAFE_className_Value="text-campaign-details-value"
              UNSAFE_className="text-campaign-details"
              label="Bath"
              value={baths}
            />
            <LabelValuePair
              UNSAFE_className_Value="text-campaign-details-value"
              UNSAFE_className="text-campaign-details"
              label="Pool"
              value={pool ? 'Yes' : 'None'}
            />
            <LabelValuePair
              UNSAFE_className_Value="text-campaign-details-value"
              UNSAFE_className="text-campaign-details"
              label="Property Style"
              value={hometype}
            />
          </GridBox>
          <Spacer height="1rem" />

          <div className="Section__spacer" />
          <Spacer height="1rem" />

          {/* TODO: Save notes to listing */}
          <Notes
            notes={note}
            onSaveNotes={(notes) => onNotesSubmit(notes)}
            loading={loading}
          />
        </div>
      </Section>
    </div>
  )
}
