import React, { ReactNode, useState, useEffect } from 'react'
import { TableFooter } from '../TableFooter'
import { TableRow } from '../TableRow'
import { TableHeader } from '../TableHeader'
import './Table.scss'

export type TableColumnType<T> = {
  RowCell?: (row: T) => ReactNode // Overrides the default row cell row[path] display
  alignment?: 'right' | 'center' | 'left' // The alignment for the cells in this column
  onSort?: (path: string, sort?: 'asc' | 'desc') => void // Called when the user clicks the label
  label?: string // Header label for column
  path: string | string[] // Object path for display value
  width?: string // Column width override
}

export type TableType<T> = {
  rows: T[]
  columns: TableColumnType<T>[]

  // For dropdown
  ExpandableRow?: (row: T) => ReactNode

  // For checkboxes
  onSelect?: (checked: T[]) => void // Adds item to array, receives checked items

  // Options
  stickyHeader?: boolean
  FooterContent?: ReactNode
  striped?: boolean
  fixedCellSize?: boolean
  UNSAFE_style?: React.CSSProperties
  UNSAFE_className?: string
  tableHeaderClass?: string
  tableRowClass?: string
  checkBorder?: string | undefined
  checkBoxActive?: string
  unSelectAll?: Boolean
  noCheckbox?: Boolean
  preSelected?: any
  radioButton?: Boolean
  tableHeaderNoCheckbox?: Boolean
  setUnselectAll?: (val: boolean) => void
}

export const Table = <T extends { [key: string]: any }>({
  rows,
  columns,
  stickyHeader,
  FooterContent,
  UNSAFE_style,
  UNSAFE_className,
  striped,
  fixedCellSize,
  ExpandableRow,
  onSelect,
  tableHeaderClass,
  tableRowClass,
  checkBorder,
  checkBoxActive,
  unSelectAll,
  noCheckbox,
  preSelected,
  tableHeaderNoCheckbox,
  radioButton = false,
  setUnselectAll,
}: TableType<T>) => {
  const [currentSelected, setCurrentSelected] = useState<number[]>([])
  const [firstLoad, setfirstLoad] = useState(false)
  const [unselect, setunselect] = useState<any>()
  useEffect(() => {
    if (preSelected?.length > 0 && !firstLoad) {
      setfirstLoad(true)
      setCurrentSelected(
        preSelected.map((item: any) => {
          return item.id
        }),
      )
    }
  }, [preSelected])

  const formatRows = () =>
    rows.map((row, index) => {
      return { id: index, ...row }
    })

  const isChecked = (id: number) => {
    if (currentSelected.length === 0) {
      if (preSelected) {
        if (preSelected.filter((item: any) => item.id === id).length > 0) {
          return true
        }
      }
    } else if (onSelect && currentSelected.includes(id)) {
      return true
    }
    return false
  }

  // Function for individual row selection
  const handleSelectRow = (row: T, checked: boolean) => {
    if (!checked) {
      setunselect(row.id)

      setCurrentSelected([
        ...currentSelected.filter((item: any) => item !== row.id),
      ])
    } else if (radioButton) {
      // preSelected.map((item: any) =>
      setCurrentSelected([row.id])
    } else {
      setCurrentSelected([...currentSelected, row.id])
    }
    // )
  }

  // Function for TableHeader to call when 'select all' checkbox is checked
  const handleSelectAll = (e: any) => {
    if (e) {
      if (currentSelected.length === rows.length) {
        setCurrentSelected([])
        // setSelectedListing([])
      } else {
        const allRowIds = []
        for (let i = 0; i <= rows.length - 1; i += 1) {
          allRowIds.push(rows[i].id)
        }
        setCurrentSelected(allRowIds)
        // setSelectedListing(allRowIds)
      }
    }
    if (setUnselectAll && !e) {
      setUnselectAll(true)
    }
  }

  // Determines colspan including all columns + checkboxes + expandable row icon
  const getColspan = () => {
    let num = columns.length
    if (ExpandableRow) num += 1
    if (onSelect) num += 1
    return num
  }
  // Calls onSelect function when currentSelected changes
  useEffect(() => {
    if (onSelect) {
      let selectedRows = formatRows().filter((row) =>
        currentSelected.includes(row.id),
      )

      if (preSelected?.length > 0) {
        if (unselect === undefined || unselect === null) {
          preSelected.filter((item: any) => selectedRows.push(item))
        } else {
          const newObj = preSelected.filter(
            (item: { id: number }) => item.id !== unselect,
          )
          selectedRows = [...newObj]
          setunselect(null)
        }
      }
      const ids = selectedRows.map((o: { id: any }) => o.id)
      const filtered = selectedRows.filter(
        ({ id }: any, index: number) => !ids.includes(id, index + 1),
      )

      onSelect(filtered)
    }
  }, [currentSelected])
  useEffect(() => {
    if (unSelectAll) {
      setCurrentSelected([])
    }
  }, [unSelectAll])
  return (
    <div
      className="TableWrapper"
      style={{ overflow: radioButton ? 'unset' : 'auto' }}
    >
      <table
        className={`Table${UNSAFE_className || ''}`}
        style={{
          ...UNSAFE_style,
          tableLayout: fixedCellSize ? 'fixed' : 'auto',
        }}
      >
        <TableHeader
          tableHeaderClass={tableHeaderClass}
          noCheckbox={noCheckbox}
          tableHeaderNoCheckbox={tableHeaderNoCheckbox}
          columns={columns}
          radioButton={radioButton}
          stickyHeader={stickyHeader}
          ExpandableRow={!!ExpandableRow}
          handleSelectAll={onSelect && handleSelectAll}
          selectedAll={
            onSelect &&
            rows.length > 0 &&
            currentSelected.length === rows.length
          }
        />
        <tbody className="Table__body">
          {stickyHeader && <tr className="TableRow__spacer" />}
          {formatRows().map((row, index) => {
            const altBg = striped && index % 2 === 1
            return (
              <TableRow
                key={index}
                row={row}
                columns={columns}
                altBg={altBg}
                radioButton={radioButton}
                striped={striped}
                ExpandableRow={ExpandableRow}
                selected={isChecked(row.id)}
                handleSelectRow={onSelect && handleSelectRow}
                getColspan={getColspan}
                tableRowClass={tableRowClass}
                checkBorder={checkBorder}
                checkBoxActive={checkBoxActive}
                noCheckbox={noCheckbox}
              />
            )
          })}
        </tbody>
        {FooterContent && (
          <TableFooter colSpan={getColspan()}>{FooterContent}</TableFooter>
        )}
      </table>
    </div>
  )
}
