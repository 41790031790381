import React, { useEffect, useState } from 'react'
import { Modal, Text } from '@eltoro-ui/components'
import { PaymentProfileType, CreditModalType, CreditBundleType } from 'types'
import { browserLog } from 'Tools'
// import { creditBundles } from 'Requests/Fixtures/CreditBundles'
import { chargebeeBundles } from 'Requests'
import { Loading } from 'Components'
import { SelectBundle } from './SelectBundle'
import { PurchaseBundle } from './PurchaseBundle'
import './CreditModal.scss'

export const CreditModal: React.FC<CreditModalType> = ({
  offClick,
  pointsNeeded,
  setIsCreditBuy,
}) => {
  const [selectedBundle, setSelectedBundle] =
    useState<CreditBundleType | null>()
  const [creditBundles, setCreditbundles] = useState()
  const handleSubmitPayment = (profile: PaymentProfileType) => {
    // Logging payment details for now
    browserLog.log({ paymentProfile: profile, bundle: selectedBundle })
    offClick()
  }
  const [isLoaded, setIsloaded] = useState(false)
  useEffect(() => {
    chargebeeBundles().then((res) => {
      // const data = []
      // res.data.subscription.map((item) => {
      //   const mock = {
      //     name: item.name,
      //     credits: item.metadata.coins,
      //     price: item.metadata.price,
      //     subText: item.metadata.name,
      //   }
      //   data.push(mock)
      //   return null
      // })
      const data = res.data.subscriptions.reverse()
      setCreditbundles(data)
      setIsloaded(true)
      // offClick()
      // setCreditbundles(false)
    })
  }, [])
  return isLoaded ? (
    <Modal
      header={
        <div className="CreditModal__header">
          Buy Credits
          <Text on="grey-500" weight="light" textAlign="center">
            Buy in bulk to save money
          </Text>
        </div>
      }
      offClick={offClick}
      className="CreditModal"
    >
      {creditBundles ? (
        <div className="CreditModal__content">
          <SelectBundle
            bundles={creditBundles}
            pointsNeeded={pointsNeeded}
            onChange={(bundle) => setSelectedBundle(bundle)}
            selectedBundle={selectedBundle}
            offClick={offClick}
            setIsCreditBuy={setIsCreditBuy}
          />
          {/* {selectedBundle ? (
            <PurchaseBundle
              bundle={selectedBundle}
              onPurchaseExit={() => setSelectedBundle(null)}
              onSubmitPayment={handleSubmitPayment}
            />
          ) : (
            <SelectBundle
              bundles={creditBundles}
              pointsNeeded={pointsNeeded}
              onChange={(bundle) => setSelectedBundle(bundle)}
              selectedBundle={selectedBundle}
            />
          )} */}
        </div>
      ) : (
        ''
      )}
    </Modal>
  ) : (
    <></>
  )
}
