import { Button, Modal, Text } from '@eltoro-ui/components'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LableInputField } from 'Components/LableInputField'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenTagModal } from 'Redux/actions'
import { TRootState } from 'types'
import { AudienceDetailsType, SaveAudienceModal } from '..'
import './SaveAudienceWizard.scss'

export const SaveAudienceWizard = ({
  onSaveAndClose,
  totalProspects,
  listing,
  type,
  dataObj,
  radiusListing,
}: {
  onSaveAndClose: (audienceDetails: AudienceDetailsType) => void
  totalProspects?: number
  listing?: any
  type?: string
  dataObj?: any
  radiusListing?: number[]
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleSaveAudience = (audienceDetails: AudienceDetailsType) => {
    onSaveAndClose(audienceDetails)
    // TODO: Call to save target
    setModalOpen(false)
  }
  const [val, setVal] = useState('')
  const dispatch = useDispatch()
  const { tagModal } = useSelector(
    (state: TRootState) => state.openTagModalReducer,
  )
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {}
  const [tags, setTags]: any = useState([])
  const addTag = () => {
    // tags.push(val)
    setTags([...tags, val])
    setVal('')
    dispatch(setOpenTagModal(false))
  }
  return (
    <div className="SaveAudienceWizard">
      <Button
        size="xl"
        kind="default"
        // iconLeft={<FontAwesomeIcon icon={faHeart} />}
        UNSAFE_style={{
          fontWeight: 'bold',
          fontSize: 'medium',
        }}
        UNSAFE_className="SaveAudienceWizard__btn"
        onClick={() => setModalOpen(true)}
        width="100%"
        disabled={!totalProspects}
      >
        Save Target Audience
      </Button>
      {!tagModal && modalOpen && (
        <SaveAudienceModal
          onClickOutside={() => {
            setModalOpen(false)
          }}
          onSave={handleSaveAudience}
          selectedtag={tags}
          listing={listing}
          totalProspects={totalProspects}
          setSelectedTags={setTags}
          dataObj={dataObj}
          radiusListing={radiusListing}
          type={type}
        />
      )}
      {tagModal ? (
        <Modal
          // header="Name and Tag your Target Audience"
          offClick={() => {
            dispatch(setOpenTagModal(false))
          }}
          className="SaveAudienceModal"
        >
          <div className="OnboardingModal__welcome ListingModalTagBg SaveAudiencePosition">
            <div className="icon">
              <FontAwesomeIcon
                icon={faLongArrowAltLeft}
                onClick={() => {
                  dispatch(setOpenTagModal(false))
                }}
              />
            </div>
            <div className="OnboardingModal__welcomeHeader ListingAudienceModal">
              <div style={{ textAlign: 'center' }}>
                <Text on="white" weight="normal" size="xxxl" textAlign="center">
                  Add Tag
                </Text>
              </div>
              <br />
              <div
                style={{
                  // width: '280px',
                  margin: '0 30px',
                  textAlign: 'center',
                }}
              >
                <div style={{ textAlign: 'left' }}>
                  {/* <Text
                    on="grey-500"
                    size="m"
                    UNSAFE_style={{ marginBottom: '0.5rem' }}
                  >
                    Tag Name
                  </Text> */}

                  {/* <InputBox
                    placeHolder="Enter Tag"
                    border="1px solid "
                    wdt="100%"
                    paddingLeft="10px"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setVal(e.target.value)
                    }}
                  /> */}
                  <LableInputField
                    placeholder="Tag"
                    title="Enter Tag"
                    type="text"
                    style={{ paddingLeft: '20px' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setVal(e.target.value)
                    }}
                  />
                  <Button
                    kind="primary"
                    size="xl"
                    width="100%"
                    weight="bolder"
                    UNSAFE_style={{ marginTop: '1rem' }}
                    onClick={() => addTag()}
                    disabled={!val}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}
