/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { LoginHeader, LoginFooter, Tooltip } from 'Components'
import { Button, Text, Modal } from '@eltoro-ui/components'
import { useHistory, useLocation } from 'react-router'
import { TRootState } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLockAlt } from '@fortawesome/pro-solid-svg-icons'
import {
  faArrowLeft,
  faExclamationCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'

import { resetPassword } from 'Requests'
import './ResetPassword.scss'
import toast from 'react-hot-toast'
import { restEmailVerify } from 'Requests/Request_Methods/authUserMethods'
import { useSelector } from 'react-redux'
import { LableInputField } from 'Components/LableInputField'
import logobefore from '../../assets/Images/honeycomb.svg'
import logoafter from '../../assets/Images/honeycomb_left.svg'
import logo from '../../Images/Icon_color@2x.png'
import PassWarning from '../../Images/lockw.png'
import Password_icon from '../../Images/lock.png'
import Warning from '../../Images/warning.png'
import { Input } from './components/Input'
import { ErrorMsg } from './components/ErrorMsg'
import leftGroup from '../../assets/Images/leftgroup.svg'
import rightGroup from '../../assets/Images/rightgroup.svg'
import cross from '../../assets/Images/cross.png'
import { SpinnerLoader } from 'Components/SpinnerLoader'

export const ResetPassword = () => {
  const Location = useLocation()
  const [modalOpen, setModalOpen] = useState(false)
  const [resetModal, setResetModal] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [pass, setPass] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [currentPasswordError, setCurrentPasswordError] = useState(Boolean)
  const [wrongPassword, setWrongPassword] = useState(Boolean)
  const [retypePassword, setRetypePassword] = useState(true)
  const [loading, setLoading] = useState(false)
  const [showPass1, setshowPass1] = useState(false)
  const [showPass2, setshowPass2] = useState(false)
  const [showPass3, setshowPass3] = useState(false)

  const history = useHistory()
  const focusInput = useRef(null)

  const query = new URLSearchParams(Location.search).get('token')?.toString()
  const [baseUrl, setBaseUrl] = useState<string>()
  const { userData } = useSelector((state: TRootState) => state.userReducer)
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      setBaseUrl(process.env.REACT_APP_URL)
    } else {
      setBaseUrl('http://localhost:3000')
    }
  }, [])
  // useEffect(() => {
  //   if (query && Location.pathname === '/verify-email') {
  //     tokenValidate(JSON.stringify(query))
  //       .then((response: any) => {
  //         if (response) {
  //           setIsValid(true)
  //           setEmail(response.data.email)
  //         }
  //       })
  //       .catch((err: any) => {
  //         alert('invalid token')
  //       })
  //   }
  // }, [])
  const [resetModalOpen, setResetModalOpen] = useState(false)
  const [sendingEmailLoading, setSendingEmailLoading] = useState(false)

  const sendMail = () => {
    setSendingEmailLoading(true)
    const body = {
      email: userData.work_email,
      redirect_url: baseUrl,
    }
    restEmailVerify(body)
      .then((res: any) => {
        if (res.detail) {
          toast.error('Email is not sending to this email address')
          setSendingEmailLoading(false)
        } else {
          setResetModalOpen(true)
          setSendingEmailLoading(false)
        }
      })
      .catch((err) => {
        // setError(true)
        toast.error('Email is not sending to this email address')
        setSendingEmailLoading(false)
      })
  }

  // const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   setLoading(true)
  //   e.preventDefault()
  //   if (retypePassword) {
  //     setWrongPassword(true)
  //     setRetypePassword(true)
  //   }

  //   else {

  //     if (Location.pathname === '/verify-email') {
  //       const body = { email, password, token: query }

  //       createNewUser(body).then((res: any) => {
  //         if (res.data.sub) {
  //           dispatch(setUserMail(res.data.email))
  //           localStorage.setItem('userMail', res.data.email)
  //           setModalOpen(true)
  //         } else {
  //           alert('error occured')
  //         }
  //       })
  //     } else {
  //       const param = { token: query, new_password: password }
  //       forgotPassword(param).then((res: any) => {
  //         if (res.detail) {
  //           alert('Inactive user')
  //         }
  //         // setResetModal(true)
  //         setModalOpen(true)
  //       })
  //     }
  //   }

  // }

  // const Login = () => {
  //   const obj = { username: email, password }
  //   userLogin(obj).then((res) => {
  //     if (res.data.token) {
  //       localStorage.setItem('beewo_token', res.data.token)
  //       setOpenOnboardingModal(true)
  //     } else {
  //       alert('not working')
  //     }
  //   })
  //   setModalOpen(false)
  // }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()

    const param = {
      current_password: currentPassword,
      new_password: password,
      confirm_password: pass,
    }

    resetPassword(param)
      .then((res: any) => {
        if (res.detail) {
          toast.error(res.detail)
          setLoading(false)
        } else {
          setModalOpen(true)
          setLoading(false)
        }
      })
      .catch((err: any) => {
        toast.error('Error')
        setLoading(false)
      })
  }

  const isDisabled = () => {
    if (
      currentPasswordError ||
      wrongPassword ||
      retypePassword ||
      !currentPassword ||
      !password ||
      !pass ||
      loading
    ) {
      return true
    }
    return false
  }

  const currentPasswordHandleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentPassword(e.target.value)
    setLoading(false)
    if (
      e.target.value.match(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
      ) &&
      e.target.value.length <= 16
    ) {
      setCurrentPasswordError(false)
    } else {
      setCurrentPasswordError(true)
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setLoading(false)
    if (
      e.target.value.match(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
      ) &&
      e.target.value.length <= 16
    ) {
      setWrongPassword(false)
    } else {
      setWrongPassword(true)
    }
  }

  const repeatPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass(e.target.value)
    setLoading(false)
    if (password === e.target.value) {
      setRetypePassword(false)
    } else {
      setRetypePassword(true)
    }
  }

  type ErrorToolbarTitle = {
    title?: string | undefined
    rightAbsolute?: string
  }
  const ErrorToolbar: React.FC<ErrorToolbarTitle> = ({
    title,
    rightAbsolute,
  }) => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text={title}
          topAbsolute="-80px"
          rightAbsolute={rightAbsolute ? rightAbsolute : '-70px'}
          padding="5px 5px"
        />
      </div>
    )
  }
  return (
    <div className="resetPassword">
      {/* <LoginHeader /> */}
      <div className="loginPrefix">
        <div className="mainLogin">
          <div className="Login_all_content">
            <img
              src={logobefore}
              alt="beewo-logo-back"
              className="before-login-bg"
            />
            <img
              src={logoafter}
              alt="beewo-logo-back"
              className="after-login-bg"
            />
            <div className="mainLogin__logo">
              <img src={logo} alt="beewo-logo" style={{ height: '111px' }} />
            </div>

            <Text weight="bold" size="xl" on="white">
              Reset Password
            </Text>
            <div style={{ textAlign: 'center', padding: '0.8rem 0 0 0' }}>
              <p className="Create_account_discription">
                Enter a new password, must be -criteria- and not be any previous
                passwords you’ve used
              </p>
            </div>
            <br />
            <div className="Login__form">
              <form onSubmit={onSubmit}>
                <div style={{ position: 'relative' }}>
                  {/* <Input
                  type="password"
                  placeHolder="Current Password"
                  wdt="100%"
                  icon={
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{
                        fontSize: '28px',
                        color: 'gray',
                        margin: '10px 0 0 10px',
                      }}
                    />
                  }
                  onChange={(e) => {
                    currentPasswordHandleChange(e)
                  }}
                /> */}
                  <LableInputField
                    title="Current Password"
                    id="currentPassword"
                    placeholder="Current Password"
                    isError={currentPasswordError && currentPassword.length > 0}
                    type={showPass1 === false ? 'password' : 'text'}
                    autoComplete="new-password"
                    iconLeft={
                      <img
                        className="start_icon"
                        style={{ marginTop: '7px', width: '24px' }}
                        src={
                          currentPasswordError && currentPassword.length > 0
                            ? PassWarning
                            : Password_icon
                        }
                        alt="icon"
                      />
                    }
                    // iconRight={
                    //   <img
                    //     className="start_icon"
                    //     style={{ marginTop: '7px', width: '24px' }}
                    //     src={
                    //       currentPasswordError && currentPassword.length > 0
                    //         ? Warning
                    //         : ''
                    //     }
                    //     // alt="icon"
                    //   />
                    // }
                    onChange={(e) => {
                      currentPasswordHandleChange(e)
                    }}
                    // lableStyle={{ top: '22px', left: '15px' }}
                    EyePassIcon={
                      showPass1 ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{
                            fontSize: '21px',
                            color: '#b3b0b0',
                            margin: '19px 0 0 12px',
                            cursor: 'pointer',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            if (focusInput.current) {
                              // @ts-ignore
                              focusInput.current.focus()
                            }
                            setshowPass1(false)
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{
                            fontSize: '21px',
                            color: '#b3b0b0',
                            margin: '19px 0 0 12px',
                            cursor: 'pointer',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            document.getElementById('currentPassword')?.focus()
                            if (focusInput.current) {
                              //  @ts-ignore
                              focusInput.current.focus()
                            }
                            setshowPass1(true)
                          }}
                        />
                      )
                    }
                  />
                  {currentPasswordError && currentPassword.length > 0 ? (
                    <ErrorMsg
                      title="You enter invalid password"
                      iconStyle={{ alignItems: 'flex-start' }}
                      icon={
                        <img
                          className="start_icon"
                          style={{ marginTop: '-3px', width: '24px' }}
                          src={Warning}
                          alt="icon"
                        />
                      }
                    />
                  ) : (
                    ''
                  )}
                  {/* {currentPasswordError && currentPassword.length > 0 ? (
                    <ErrorMsg
                      title="You enter invalid password"
                      icon={
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          style={{ color: 'red' }}
                        />
                      }
                    />
                  ) : (
                    ''
                  )} */}
                </div>
                <br />

                <div style={{ position: 'relative' }}>
                  {/* <Input
                  type="password"
                  placeHolder="Password"
                  wdt="100%"
                  icon={
                    <FontAwesomeIcon
                      icon={faLockAlt}
                      style={{
                        fontSize: '28px',
                        color: 'gray',
                        margin: '10px 0 0 10px',
                      }}
                    />
                  }
                  onChange={(e) => {
                    handleChange(e)
                  }}
                /> */}
                  <LableInputField
                    title="New Password"
                    placeholder="New Password"
                    id="newPassword"
                    isError={wrongPassword && password.length > 0}
                    type={showPass2 ? 'text' : 'password'}
                    iconLeft={
                      <img
                        className="start_icon"
                        style={{ marginTop: '7px', width: '24px' }}
                        src={
                          wrongPassword && password.length > 0
                            ? PassWarning
                            : Password_icon
                        }
                        alt="icon"
                      />
                    }
                    // iconRight={
                    //   <img
                    //     className="start_icon"
                    //     style={{ marginTop: '7px', width: '24px' }}
                    //     src={
                    //       wrongPassword && password.length > 0 ? Warning : ''
                    //     }
                    //     // alt="icon"
                    //   />
                    // }
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    // lableStyle={{ top: '22px', left: '15px' }}
                    EyePassIcon={
                      showPass2 ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{
                            fontSize: '21px',
                            color: '#b3b0b0',
                            margin: '19px 0 0 12px',
                            cursor: 'pointer',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            if (focusInput.current) {
                              // @ts-ignore
                              focusInput.current.focus()
                            }
                            setshowPass2(false)
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{
                            fontSize: '21px',
                            color: '#b3b0b0',
                            margin: '19px 0 0 12px',
                            cursor: 'pointer',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            document.getElementById('newPassword')?.focus()
                            if (focusInput.current) {
                              //  @ts-ignore
                              focusInput.current.focus()
                            }
                            setshowPass2(true)
                          }}
                        />
                      )
                    }
                  />
                  {wrongPassword && password.length > 0 ? (
                    // <ErrorToolbar title="You enter invalid password" />
                    <ErrorMsg
                      title="Password must contain min 8 characters including numbers, special characters, upper and lowercase letters."
                      iconStyle={{ alignItems: 'flex-start' }}
                      icon={
                        <img
                          className="start_icon"
                          style={{ marginTop: '-3px', width: '24px' }}
                          src={Warning}
                          alt="icon"
                        />
                      }
                    />
                  ) : (
                    ''
                  )}
                  {/* {wrongPassword && password.length > 0 ? (
                    <ErrorMsg
                      title="You enter invalid password"
                      icon={
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          style={{ color: 'red' }}
                        />
                      }
                    />
                  ) : (
                    ''
                  )} */}
                </div>
                <br />
                {/* <Input
                type="password"
                placeHolder="Re-enter Password"
                wdt="100%"
                icon={
                  <FontAwesomeIcon
                    icon={faLockAlt}
                    style={{
                      fontSize: '28px',
                      color: 'gray',
                      margin: '10px 0 0 10px',
                    }}
                  />
                }
                onChange={(e) => {
                  repeatPassword(e)
                }}
              /> */}
                <LableInputField
                  title="Re-enter New Password"
                  placeholder="Re-enter New Password"
                  isError={retypePassword && pass.length > 0}
                  id="reEnterPassword"
                  type={showPass3 ? 'text' : 'password'}
                  iconLeft={
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={
                        retypePassword && pass.length > 0
                          ? PassWarning
                          : Password_icon
                      }
                      alt="icon"
                    />
                  }
                  // iconRight={
                  //   <img
                  //     className="start_icon"
                  //     style={{ marginTop: '7px', width: '24px' }}
                  //     src={retypePassword && pass.length > 0 ? Warning : ''}
                  //     // alt="icon"
                  //   />
                  // }
                  onChange={(e) => {
                    repeatPassword(e)
                  }}
                  // lableStyle={{ top: '22px', left: '15px' }}

                  EyePassIcon={
                    showPass3 ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          fontSize: '21px',
                          color: '#b3b0b0',
                          margin: '19px 0 0 12px',
                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          if (focusInput.current) {
                            // @ts-ignore
                            focusInput.current.focus()
                          }
                          setshowPass3(false)
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        style={{
                          fontSize: '21px',
                          color: '#b3b0b0',
                          margin: '19px 0 0 12px',
                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          document.getElementById('reEnterPassword')?.focus()
                          if (focusInput.current) {
                            //  @ts-ignore
                            focusInput.current.focus()
                          }
                          setshowPass3(true)
                        }}
                      />
                    )
                  }
                />
                {retypePassword && pass.length > 0 ? (
                  // <ErrorToolbar
                  //   title="Password entries do not match"
                  //   rightAbsolute="-82px"
                  // />
                  <ErrorMsg
                    title="Password entries do not match"
                    iconStyle={{ alignItems: 'flex-start' }}
                    icon={
                      <img
                        className="start_icon"
                        style={{ marginTop: '-3px', width: '24px' }}
                        src={Warning}
                        alt="icon"
                      />
                    }
                  />
                ) : (
                  ''
                )}
                {/* {retypePassword && pass.length > 0 ? (
                  <ErrorMsg
                    title="Password entries do not match"
                    icon={
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        style={{ color: 'red' }}
                      />
                    }
                  />
                ) : (
                  ''
                )} */}
                <br />
                <Button
                  type="submit"
                  kind="primary"
                  size="xl"
                  width="100%"
                  fonts="16px"
                  disabled={isDisabled()}
                  weight="bold"
                  UNSAFE_style={{ justifyContent: 'center' }}
                >
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px',
                      }}
                    >
                      Update Password
                      <SpinnerLoader
                        style={{
                          position: 'unset',
                          color: 'black',
                          width: '25px',
                          height: '25px',
                          disabled: 'none !important',
                          marginTop: '0px',
                          opacity: 1,
                        }}
                      />
                    </div>
                  ) : (
                    'Update Password'
                  )}
                </Button>
              </form>
            </div>
            {/* Go Back */}

            {/* <div className="resetPassword__bottomNav">
              <div
                className="onboardingBack"
                onClick={() => {
                  history.replace('/profile')
                }}
                aria-hidden="true"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color="#FFAB03"
                  style={{ marginTop: '18px', marginRight: '4px' }}
                />
                <p style={{ fontWeight: 'bold' }}>Go Back</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* End login */}

      {resetModalOpen && (
        <Modal
          offClick={() => {
            setResetModalOpen(false)
          }}
        >
          <div className="OnboardingModal__welcome">
            <div className="OnboardingModal__welcomeHeader">
              <img
                src={cross}
                // onClick={() => {
                //   history.goBack()
                // }}
                alt="cross"
                className="PersonalInfoForm__cross"
                role="presentation"
              />
              <div style={{ textAlign: 'center' }}>
                <Text on="white" weight="bold" size="xl" textAlign="center">
                  Email Sent!
                </Text>
              </div>
              <br />
              <div
                style={{ width: '280px', margin: '0 30px', display: 'flex' }}
              >
                <Text on="white" weight="normal" size="m" textAlign="center">
                  An email has been sent to you providing instructions to reset
                  your password
                </Text>
              </div>
            </div>

            <div
              className={`loginResendMail ${
                sendingEmailLoading ? 'resetPassword__button_disabled' : ''
              }`}
              onClick={() => {
                // setPass()
                sendMail()
              }}
              aria-hidden="true"
            >
              <p style={{ fontWeight: 'bold' }}>
                Didn&apos;t receive the email?
              </p>
            </div>
          </div>
        </Modal>
      )}
      {modalOpen && (
        <Modal
          offClick={() => {
            setModalOpen(false)
          }}
        >
          <div
            style={{
              width: '30rem',
              padding: '3rem 2rem',
              position: 'relative',
            }}
            className="OnboardingModal__welcome"
          >
            <img
              src={leftGroup}
              alt="leftcardicon"
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '23%',
                zIndex: '-1',
              }}
            />
            <div
              style={{ padding: '0px 2rem' }}
              className="OnboardingModal__welcomeHeader"
            >
              <div style={{ textAlign: 'center' }}>
                <Text
                  UNSAFE_style={{ fontWeight: '800' }}
                  on="white"
                  weight="bold"
                  size="xxl"
                  textAlign="center"
                >
                  New Password Set
                </Text>
              </div>
              <br />
              <div
                style={{
                  // width: '280px',
                  margin: '10px 30px',
                  textAlign: 'center',
                }}
              >
                <p className="newpassword_model">
                  Your password has been updated
                </p>
              </div>
            </div>

            <Button
              UNSAFE_style={{
                borderRadius: '10px',
                padding: '26px',
                width: '70%',
              }}
              kind="primary"
              weight="bold"
              UNSAFE_className="passwordReset__login"
              onClick={() => {
                history.push('/profile')
              }}
            >
              Done
            </Button>

            <img
              style={{
                position: 'absolute',
                bottom: '0px',
                right: '0px',
                width: '24%',
                zIndex: '-1',
              }}
              src={rightGroup}
              alt="righcardicon"
            />
          </div>
        </Modal>
        // <Modal
        //   offClick={() => {
        //     setModalOpen(false)
        //   }}
        // >
        //   <div className="OnboardingModal__welcome">
        //     <div className="OnboardingModal__welcomeHeader">
        //       <div style={{ textAlign: 'center' }}>
        //         <Text on="white" weight="bold" size="xxl" textAlign="center">
        //           New Password Set
        //         </Text>
        //       </div>
        //       <br />
        //       <div
        //         style={{
        //           width: '280px',
        //           margin: '0 30px',
        //           textAlign: 'center',
        //         }}
        //       >
        //         <Text on="grey-500" weight="normal" size="m" textAlign="center">
        //           Your password has been updated
        //         </Text>
        //       </div>
        //     </div>

        //     <Button
        //       kind="primary"
        //       size="l"
        //       UNSAFE_style={{ padding: '0px 30px', marginTop: '10px' }}
        //       weight="bold"
        //       onClick={() => {
        //         history.push('/profile')
        //       }}
        //     >
        //       Done
        //     </Button>
        //   </div>
        // </Modal>
      )}
      {/* <LoginFooter /> */}
    </div>
  )
}
