import {
  Button,
  FlexBox,
  GridBox,
  MaxHeightContainer,
  Modal,
  Spacer,
  Text,
} from '@eltoro-ui/components'
import { CreativeGen } from '@eltoro-ui/creative_generator'
import { useAsync } from '@eltoro-ui/hooks'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faImages, faVideo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import {
  Loading,
  PageHeader,
  ProgressStepper,
  SummaryCart,
  Layout,
} from 'Components'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { useHistory, useParams } from 'react-router-dom'
import { setSpinner } from 'Redux/actions'
import {
  getCampaignById,
  saveCampaignCreative,
} from 'Requests/Request_Methods/campaignMethods'
import { browserLog } from 'Tools'
import { FileUploadType } from 'types'
import { CampaignCreationFooter, CampaignCreationWrapper } from '..'
import GroupIcon from '../../../../assets/Images/Group339.png'
import MailIcon from '../../../../assets/Images/mail-icon-shape.png'
import SubtractIcon from '../../../../assets/Images/Subtract.png'
import './CampaignCreatives.scss'
import {
  AttachedCreatives,
  CreativeCampaignModal,
  CreativeTypeBadge,
  UploadOrBuild,
} from './_components'

export const CampaignCreatives = () => {
  const history = useHistory()
  const [selectedCreativeType, setSelectedCreativeType] = useState<any>(null)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [campaign, setCampaign] = useState<any>({})

  const { campaignId } = useParams<{ campaignId: string }>()

  // const { status, value } = useAsync(() => getCampaignById(campaignId))
  const [showCreativeGen, setShowCreativeGen] = useState(false)
  const [videoFile, setvideoFile] = useState<any>()
  const [status, setstatus] = useState('pending')
  const [urls, setUrls] = useState<any>({ video_url: false, banner_url: false })

  // useEffect(()=>{
  //   if(
  //     res.data?.banner_count
  //     res.data?.video_count||
  //   )
  //   {
  //     setUrls()
  //   }
  // },[campaign])

  useEffect(() => {
    setstatus('pending')

    getCampaignById(campaignId)
      .then((res) => {
        setUrls({
          video_url: !!res.data?.video_count,
          banner_url: !!res.data?.banner_count,
        })

        setCampaign({ ...campaign, ...res.data })
        setstatus('success')
      })
      .catch((err) => {
        setstatus('success')
      })
  }, [])
  const [nextClicked, setnextClicked] = useState(true)

  // useEffect(() => {
  //   // if (
  //   //   (urls?.banner_url && campaign?.banner_count) ||
  //   //   urls?.video_url ||
  //   //   campaign?.video_count
  //   // )
  //   if (urls?.banner_url && urls?.video_url) {
  //     setnextClicked(false)
  //   } else {
  //     setnextClicked(true)
  //   }
  // }, [urls])

  useEffect(() => {
    // if (
    //   (campaign?.banner_count || campaign?.video_count) &&
    //   (urls?.banner_url || urls?.video_url)
    // )
    if (
      campaign?.creatives?.filter((e: any) => e.content_type === 'video')
        .length > 0 &&
      urls?.video_url
    ) {
      setnextClicked(false)
    }
    // else {
    //   setnextClicked(true)
    // }
    else if (
      campaign?.creatives?.filter((e: any) => e.content_type === 'banner')
        .length > 0 &&
      urls?.banner_url
    ) {
      setnextClicked(false)
    }

    if (
      (campaign?.creatives?.filter((e: any) => e.content_type === 'banner')
        .length > 0 &&
        !urls?.banner_url) ||
      (campaign?.creatives?.filter((e: any) => e.content_type === 'video')
        .length > 0 &&
        !urls?.video_url)
    ) {
      setnextClicked(true)
    }

    if (!campaign?.creatives?.length) {
      setnextClicked(true)
    }
  }, [campaign, urls])

  // useEffect(() => {
  //   if (campaign?.banner_count || campaign?.video_count) {
  //     setnextClicked(false)
  //   }
  // }, [campaign])

  const [isSubmit, setisSubmit] = useState(false)

  const returnHttpsPrefix = (val: string) => {
    let value = val
    // if (val.includes('https://') && val.includes('www')) {
    //   return val
    // }
    // if (!value.includes('www')) {
    //   value = `www${value}`
    //   if (!value.includes('https://')) {
    //     return (value = `https://${val}`)
    //   }
    // }

    if (!value.includes('https://')) {
      if (!value.includes('www')) {
        return (value = `https://www.${value}`)
      }
      return (value = `https://${value}`)
    }
    return value
  }

  const handleNextClick = async () => {
    const formData = new FormData()
    setisSubmit(true)
    await campaign.creatives.map(
      (item: any) => (item.click_url = returnHttpsPrefix(item.click_url)),
    )
    const dataNew = {
      content: campaign.creatives,
      campaign_id: campaignId,
    }
    formData.append('body', JSON.stringify(dataNew))
    if (campaign.creatives.length <= 0) {
      toast.error('Please add a creative')
      setnextClicked(false)
    } else if (
      campaign.creatives?.find(
        (creat: { mail_type: string; click_url: string }) => {
          return (
            creat.mail_type !== 'front' &&
            creat.mail_type !== 'back' &&
            !creat.click_url
          )
        },
      )
    ) {
      toast.error('Please fill all details')
      setnextClicked(false)
    } else {
      for (let i = 0; i < campaign.creatives.length; i += 1) {
        if (campaign?.creatives[i]?.file) {
          formData.append('files', campaign?.creatives[i]?.file)
        }
      }

      dispatch(setSpinner(true))

      setnextClicked(true)
      saveCampaignCreative(formData, campaignId)
        .then((res) => {
          if (res.data) {
            history.push({
              pathname: `/create-campaign/${campaignId}/calculate`,
            })
          }
          dispatch(setSpinner(false))
          setnextClicked(false)
        })
        .catch(() => {
          toast.error('something went wrong')
          dispatch(setSpinner(false))
        })
    }
  }

  const checkMailType = (base64s: string | any[], i: number) => {
    if (base64s.length - 1 === i) {
      return 'back'
    }
    return 'front'
  }
  const checkClickUrl = (typeModal: any) => {
    if (typeModal === 'banner') {
      return campaign?.creatives[0] ? campaign?.creatives[0].click_url : ''
    }
    return null
  }

  const checkDimension = (files: any, type: any) => {
    if (files[0]?.acceptedFiles[0] && type === 'height') {
      return files[0]?.height
    }
    if (files[0]?.acceptedFiles[0] && type === 'width') {
      return files[0]?.width
    }
    if (files?.height) {
      return files?.height
    }
    if (files?.width) {
      return files?.width
    }
    return null
  }

  const [CreativeDirectMail, setCreativeDirectMail] = useState(false)
  useEffect(() => {
    if (
      campaign?.creatives?.find(
        (creat: { mail_type: string }) => creat.mail_type === 'front',
      )
    ) {
      setCreativeDirectMail(true)
    } else {
      setCreativeDirectMail(false)
    }

    if (
      !campaign?.creatives?.find(
        (creat: { content_type: string }) => creat.content_type === 'video',
      )
    ) {
      setvideoFile(null)
    }
  }, [campaign])

  const dispatch = useDispatch()

  const objectCreatives = (files: any, typeModal: any) => {
    return {
      click_url: checkClickUrl(typeModal),
      text: '',
      thumb: URL.createObjectURL(
        files[0]?.acceptedFiles[0]
          ? files[0].acceptedFiles[0]
          : files.acceptedFiles[0],
      ),
      file: files[0]?.acceptedFiles[0]
        ? files[0].acceptedFiles[0]
        : files.acceptedFiles[0],
      content_name: files[0]?.acceptedFiles[0]
        ? files[0].acceptedFiles[0]?.name
        : files.acceptedFiles[0].name,
      backFile: files[1]?.acceptedFiles[0] ? files[1].acceptedFiles[0] : '',
      errorVal: true,
      size: files[0]?.acceptedFiles[0]
        ? files[0].acceptedFiles[0]?.size
        : files.acceptedFiles[0].size,
      content_type: typeModal,
      hight: checkDimension(files, 'height'),
      width: checkDimension(files, 'width'),
      content_data: null,
      mail_type: null,
    }
  }

  const [defaultCreative, setdefaultCreative] = useState<'banner'>('banner')
  return (
    <div className="CampaignCreatives">
      <MaxHeightContainer
        UNSAFE_CLASSNAME="bg-content-color"
        fullHeight
        header={
          <PageHeader
            UNSAFE_CLASSNAME_GRID="CampaignSetup__titleSpace"
            title={campaign.name}
            style={{ height: '84px', background: 'var(--color-white)' }}
            UNSAFE_CLASSNAME_TITLE="CampaignCheckout__title"
            actions={[
              <ProgressStepper
                pathset={() =>
                  history.push(`/create-campaign/${campaign.id}/setup`)
                }
                tagNames={['Audience', 'Creative', 'Checkout', 'Submit']}
                totalSteps={4}
                activeStep={2}
              />,
            ]}
            // icon={
            //   selectedCreativeType !== null &&
            //   selectedCreativeType !== 'video' && (
            //     <Button
            //       size="l"
            //       type="button"
            //       UNSAFE_className="icon-header"
            //       UNSAFE_TEXTSTYLE="icon-header-text"
            //       onClick={() => setSelectedCreativeType(null)}
            //     >
            //       <FontAwesomeIcon icon={faChevronLeft} />
            //     </Button>
            //   )
            // }
            subTitle=""
            actionsStyle="CampaignSetup__progress mt-17"
            UNSAFE_CLASSNAME="mb-8px"
          />
        }
        // loading
        footer={
          <CampaignCreationFooter>
            <Button
              size="l"
              UNSAFE_className="campaignBack"
              onClick={() =>
                selectedCreativeType === 'banner'
                  ? setSelectedCreativeType(!selectedCreativeType)
                  : history.push({
                      pathname: `/create-campaign/${campaignId}/setup`,
                    })
              }
            >
              Back
            </Button>
            <div className="CampaignSetup__next-button">
              <Button
                UNSAFE_style={{
                  padding: '20px 40px',
                  backgroundColor: '#FFF9ED',
                  color: '#FFAB03',
                  fontWeight: '600',
                  border: '1px solid #dc9300',
                  width: '212.76px',
                  height: '54.75px',
                }}
                size="l"
                // kind="primary"
                onClick={handleNextClick}
                disabled={nextClicked}
              >
                Next
              </Button>
            </div>
          </CampaignCreationFooter>
        }
      >
        <Layout>
          <CampaignCreationWrapper>
            {status === 'pending' ? (
              <Loading />
            ) : (
              <>
                <GridBox gap="1rem" UNSAFE_className="creatives_main_div">
                  {showCreativeGen && (
                    <Modal offClick={() => setShowCreativeGen(false)}>
                      <CreativeGen
                        onComplete={(base64s) => {
                          const base64Creatives = []
                          for (let i = 0; i < base64s.length; i += 1) {
                            base64Creatives.push({
                              click_url: checkClickUrl(defaultCreative),
                              text: '',
                              thumb: base64s[i]?.handleDataURL,
                              file: null,
                              content_name: null,
                              errorVal: true,
                              content_type: defaultCreative,
                              size: null,
                              hight: base64s[i]?.height,
                              width: base64s[i]?.width,
                              content_data: base64s[i]?.handleDataURL,
                            })
                            if (base64s.length - 1 === i) {
                              setCampaign({
                                ...campaign,
                                creatives: [
                                  ...campaign?.creatives,
                                  ...base64Creatives,
                                ],
                              })
                              setSelectedCreativeType(null)
                            }
                          }
                          setShowCreativeGen(false)
                          // setnextClicked(false) // issue here
                        }}
                        defaultCreativeType={defaultCreative}
                        onClose={() => setShowCreativeGen(false)}
                        width="95vw"
                        height="90vh"
                      />
                    </Modal>
                  )}
                  {/* Conditionals to open modals for specific creative types */}
                  {uploadModalOpen && (
                    <CreativeCampaignModal
                      onClickOutside={() => setUploadModalOpen(false)}
                      type={selectedCreativeType}
                      openCreativeGen={(typeModal) => {
                        if (typeModal) {
                          setdefaultCreative(typeModal)
                          setUploadModalOpen(false)
                          setShowCreativeGen(true)
                        }
                      }}
                      onSubmit={(
                        files: FileUploadType | any,
                        typeModal?: string,
                      ) => {
                        setnextClicked(true)
                        if (typeModal === 'video') {
                          const videoIndex = campaign.creatives?.findIndex(
                            (val: any) => val?.content_type === 'video',
                          )

                          setvideoFile(files)
                          if (videoIndex !== -1) {
                            const someArr = [...campaign?.creatives]
                            someArr[videoIndex] = {
                              ...objectCreatives(files, typeModal),
                            }
                            setCampaign({
                              ...campaign,
                              creatives: [...someArr],
                            })
                          } else {
                            setCampaign({
                              ...campaign,
                              creatives: [
                                ...campaign?.creatives,
                                {
                                  ...objectCreatives(files, typeModal),
                                },
                              ],
                            })
                          }
                        } else if (typeModal === 'directMail') {
                          const newCreatives = []

                          for (let i = 0; i < files.length; i += 1) {
                            newCreatives.push({
                              click_url: '',
                              text: '',
                              thumb: URL.createObjectURL(
                                files[i]?.acceptedFiles[0]
                                  ? files[i].acceptedFiles[0]
                                  : files.acceptedFiles[0],
                              ),
                              file: files[i]?.acceptedFiles[0]
                                ? files[i].acceptedFiles[0]
                                : files.acceptedFiles[0],
                              content_name: files[i]?.acceptedFiles[0]
                                ? files[i].acceptedFiles[0]?.name
                                : files.acceptedFiles[0].name,
                              errorVal: true,

                              size: files[i]?.acceptedFiles[0]
                                ? files[i].acceptedFiles[0]?.size
                                : files.acceptedFiles[0].size,
                              content_type: typeModal,
                              hight: null,
                              width: null,
                              content_data: null,
                              mail_type:
                                files.length - 1 === i ? 'back' : 'front',
                            })
                            if (files.length - 1 === i) {
                              setCampaign({
                                ...campaign,
                                creatives: [
                                  ...campaign?.creatives,
                                  ...newCreatives,
                                ],
                              })
                              setSelectedCreativeType(null)
                            }
                          }
                        } else {
                          setCampaign({
                            ...campaign,
                            creatives: [
                              ...campaign?.creatives,
                              {
                                ...objectCreatives(files, typeModal),
                              },
                            ],
                          })
                          setSelectedCreativeType(null)
                        }
                      }}
                    />
                  )}
                  <div className="CampaignCreatives__choose-creatives">
                    <FlexBox justifyContent="space-between">
                      <div>
                        <Text
                          UNSAFE_className="CampaignCreatives__choose_title"
                          tag="div"
                          on="white"
                          size="xxl"
                        >
                          Choose your Creative
                        </Text>

                        <Text
                          tag="div"
                          on="white"
                          size="s"
                          UNSAFE_className="CampaignCreatives__including_video"
                          UNSAFE_style={{ color: '#6d6d6d', marginTop: '8px' }}
                        >
                          Your campaign needs style. Create digital banners,
                          direct mail pieces, or both. You may also upload your
                          own files including audio and video
                        </Text>
                      </div>
                    </FlexBox>
                    <div
                      style={{
                        display:
                          selectedCreativeType === 'banner'
                            ? 'contents'
                            : 'grid',
                      }}
                      className={`CampaignCreatives__types ${classnames({
                        CampaignCreatives__if_banner:
                          selectedCreativeType === 'banner',
                      })}`}
                    >
                      {selectedCreativeType !== 'directMail' &&
                        selectedCreativeType !== 'banner' && (
                          <CreativeTypeBadge
                            onClick={() => setSelectedCreativeType('banner')}
                            selectedValue={selectedCreativeType === 'banner'}
                            icon={<FontAwesomeIcon icon={faImages} size="4x" />}
                            text="Digital Banner"
                            bannerIcon={GroupIcon}
                            UNSAFE_className="CampaignSetup__Choose_your_Creative"
                          />
                        )}
                      {selectedCreativeType !== 'banner' && (
                        <CreativeTypeBadge
                          fileTextClassName="video_file_name"
                          UNSAFE_className="flex CampaignSetup__Choose_your_Creative"
                          onClick={() => {
                            setUploadModalOpen(true)
                            setSelectedCreativeType('video')
                          }}
                          selectedValue={videoFile}
                          icon={<FontAwesomeIcon icon={faVideo} size="4x" />}
                          text={
                            videoFile
                              ? videoFile?.acceptedFiles[0].name
                              : 'Video'
                          }
                          typeBadge="video"
                          bannerIcon={SubtractIcon}
                        />
                      )}

                      {/* Renders the options to upload or use the ad builder when you click direct mail or banner */}
                      {selectedCreativeType === 'banner' && (
                        <div
                          className="CampaignCreatives__upload"
                          style={{ marginBlock: '10px' }}
                        >
                          <UploadOrBuild
                            onClickUpload={() => setUploadModalOpen(true)}
                            onClickBuild={() => {
                              setShowCreativeGen(true)
                              setdefaultCreative(selectedCreativeType)
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {/* Display disclaimer about direct mail when direct mail is selected or no selection has been made yet */}
                    {/* {(selectedCreativeType === 'directMail' ||
                    selectedCreativeType === null) && (
                    <>
                      <Spacer />
                      <Text on="white" size="s">
                        *The number of prospects may differ from the displayed
                        amount
                      </Text>
                    </>
                  )} */}
                  </div>
                  {campaign && campaign?.creatives?.length > 0 && (
                    <AttachedCreatives
                      isSubmit={isSubmit}
                      creatives={campaign?.creatives}
                      campaign={campaign}
                      setCampaign={setCampaign}
                      setnextClicked={setnextClicked}
                      urls={urls}
                      setUrls={setUrls}
                    />
                  )}
                </GridBox>
                {campaign && <SummaryCart campaign={campaign} />}
              </>
            )}
          </CampaignCreationWrapper>
        </Layout>
      </MaxHeightContainer>
    </div>
  )
}
// 758
