import React from 'react'
import { Modal } from '@eltoro-ui/components'
import { FileUploadType } from 'types'
import { UploadBannerModal, UploadMailerModal, UploadVideoModal } from '..'
import './CreativeCampaignModal.scss'
import { UploadCSVModal } from '../UploadCSVModal/UploadcsvModal'

export const CreativeCampaignModal = ({
  campaignId,
  type,
  onClickOutside,
  onSubmit,
  openCreativeGen,
}: {
  type: 'banner' | 'video' | 'csv' | null
  onClickOutside: () => void
  onSubmit: (files: FileUploadType | any, typeModal?: any) => void
  campaignId?: any
  openCreativeGen: (typeMod?: 'banner') => void
}) => {
  const displayModal = () => {
    if (type === 'banner') {
      return (
        <UploadBannerModal
          typeModal={type}
          openCreativeGen={openCreativeGen}
          onClickOutside={onClickOutside}
          onSubmit={onSubmit}
        />
      )
    }
    // if (type === 'directMail') {
    //   return (
    //     <UploadMailerModal
    //       typeModal={type}
    //       openCreativeGen={openCreativeGen}
    //       onClickOutside={onClickOutside}
    //       onSubmit={onSubmit}
    //     />
    //   )
    // }
    if (type === 'csv') {
      return (
        <UploadCSVModal
          onClickOutside={onClickOutside}
          onSubmit={onSubmit}
          campaignId={campaignId}
        />
      )
    }
    return (
      <UploadVideoModal
        typeModal={type}
        onClickOutside={onClickOutside}
        onSubmit={onSubmit}
      />
    )
  }
  return <Modal offClick={onClickOutside}>{displayModal()}</Modal>
}
