import { EmptyStatus, Section } from 'Components'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getUserNotifications } from 'Requests'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import membershipIcon from '../../../../assets/Images/membershipIcon.svg'
import accountIcon from '../../../../assets/Images/accounts.png'
import systemIcon from '../../../../assets/Images/notificationSystem.png'
import campaignIcon from '../../../../assets/Images/notificationCampaign.png'
import './NotificationContainer.scss'

export const NotificationContainer = (notification: Object) => {
  const [data, setData] = useState<any>([])
  useEffect(() => {
    getUserNotifications(1, 6).then((res) => {
      setData(res?.data?.notifications)
    })
  }, [])

  const getIcon = (src: string) => {
    switch (src) {
      case 'accounts':
        return accountIcon
      case 'membership':
        return membershipIcon
      case 'system':
        return systemIcon
      default:
        return campaignIcon
    }
  }
  return (
    <Section
      title="Notifications"
      UNSAFE_className_text="campaignProgress__title"
      UNSAFE_className_container="notificationContainer__container"
      spacer={false}
      isCenter
      absoluteHeader
    >
      <div className="campaignProgress__link">
        <Link to="/notifications" className="campaignProgress__linkAnchore">
          See all
        </Link>
      </div>
      <div className="notificationContainer__listBox">
        {!data.length ? (
          <EmptyStatus heading="No notifications are found" />
        ) : (
          data.map((item: any) => {
            return (
              <div
                className={`notificationContainer__list ${
                  !item?.read && 'notificationContainer__unread'
                }`}
              >
                <img src={getIcon(item.source)} alt="" />
                <div className="notificationContainer__listTitle">
                  <h4>{item.title}</h4>
                  <p>{item.title}</p>
                </div>
                {/* <FontAwesomeIcon icon={faAngleRight} /> */}
              </div>
            )
          })
        )}
      </div>
    </Section>
  )
}
