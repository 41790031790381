import { ResponseType, UserType, TUpdateUser, TUploadPicture } from 'types'
import requests from '../http'

export const getUser = async (): Promise<ResponseType<UserType>> =>
  requests.get(`/user`)

export const updateUser = async (
  body: Partial<UserType>,
): Promise<ResponseType<UserType>> => requests.patch(`/user`, body)

export const createUser = async (
  body: UserType,
): Promise<ResponseType<UserType>> => requests.post(`/user`, body)

export const updateTheUser = async (
  body: Object,
): Promise<ResponseType<TUpdateUser>> =>
  requests.put('/users/update_user', body)

export const uploadProfilePicture = async (
  body: Object,
): Promise<ResponseType<TUploadPicture>> =>
  requests.post('/users/upload_picture', body, true)

export const userdeviceToken = async (
  body: object,
): Promise<ResponseType<string>> => {
  const req = '/users/user_device'
  return requests.post(req, body)
}

export const logout = async (): Promise<ResponseType<string>> =>
  requests.post('/logout', { device: 'web' })
