/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setGlobalFilters, globalFilters } from 'Redux/actions'
import {
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faSort,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'

import {
  ConfirmationModal,
  Pagination,
  DeleteLable,
  ReactTableSearchBar,
} from 'Components'
import unsortedIcon from '../../assets/Images/unsorted.png'
import './ReactTable.scss'
// import { TextInput } from '@eltoro-ui/components'
import searchicon from '../../assets/Images/searchicon.png'

// Check-box
const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef()
    const resolvedRef: any = ref || defaultRef

    useEffect(() => {
      if (
        !!resolvedRef &&
        !!resolvedRef.current &&
        !!resolvedRef.current.indeterminate
      ) {
        resolvedRef.current.indeterminate = indeterminate
      }
    }, [resolvedRef, indeterminate])
    return (
      <div className="table_checkbox">
        <input
          ref={resolvedRef}
          {...rest}
          id="wp-comment-cookies-consent"
          name="wp-comment-cookies-consent"
          type="checkbox"
          value="yes"
        />
      </div>
    )
  },
)

type TableTypes = {
  columns: any[]
  data: any[]
  onDelete?: (params: any[]) => void
  deleteModal?: boolean
  setDeleteModal?: (params: boolean) => void
  deleteIsLoading?: boolean
  selectFeature?: boolean
  selectAllFeature?: boolean
  title?: string
}

// function GlobalFilter({
//   globalFilter,
//   setGlobalFilter,
// }: {
//   globalFilter: any
//   setGlobalFilter: any
// }) {
//   const [value, setValue] = React.useState(globalFilter)
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined)
//   }, 200)

//   return (
//     <TextInput
//       className="search_input_field"
//       value={value || ''}
//       placeholder="Search"
//       colorr="#2B1F0A"
//       iconStyle={{ position: 'absolute', right: '8px', top: '13px' }}
//       icon={<img src={searchicon} alt="searchicon" />}
//       onChange={(e) => {
//         setValue(e.target.value)
//         onChange(e.target.value)
//       }}
//     />
//   )
// }

function ReactTable({
  columns,
  data,
  onDelete = () => {},
  deleteModal,
  setDeleteModal = () => {},
  deleteIsLoading,
  selectFeature = true,
  selectAllFeature = true,
  title = 'item',
}: TableTypes) {
  const getCheckBox = (hooks: any) => {
    hooks?.visibleColumns?.push((_columns: any) =>
      selectFeature
        ? [
            {
              id: 'selection',
              Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
                <div>
                  {selectAllFeature ? (
                    <IndeterminateCheckbox
                      {...getToggleAllPageRowsSelectedProps()}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ),
              Cell: ({ row }: any) => (
                <div>
                  <IndeterminateCheckbox
                    {...row?.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            ..._columns,
          ]
        : [..._columns],
    )
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = useTable(
    { columns, data },
    getCheckBox,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setGlobalFilters(setGlobalFilter))
    dispatch(globalFilters(globalFilter))
  }, [])

  useEffect(() => {
    if (pageSize !== 20) {
      setPageSize(20)
    }
  }, [pageSize])

  const getSortStatus = (isSortedDesc: boolean) => {
    return isSortedDesc ? (
      <FontAwesomeIcon
        style={{ fontSize: '1.2rem', color: '#4496FC' }}
        color="#AAB2B5"
        icon={faAngleDown}
      />
    ) : (
      <FontAwesomeIcon
        style={{ fontSize: '1.2rem', color: '#4496FC' }}
        color="#AAB2B5"
        icon={faAngleUp}
      />
    )
  }

  return (
    <>
      {/* <ReactTableSearchBar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}

      {!!selectedFlatRows?.length && (
        <DeleteLable
          onDelete={() => setDeleteModal(true)}
          deleteItemCounts={selectedFlatRows?.length}
          title={title}
        />
      )}
      <div className="table_wrapper">
        <table
          cellPadding="18px"
          cellSpacing="0px"
          {...getTableProps()}
          className="mainTable"
        >
          <thead
            className={
              selectFeature ? `customTableRowSelected` : `customTableRow`
            }
          >
            {headerGroups.map((headerGroup: any, index: number) => {
              if (index === 0) return null
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th>
                      {column.render('Header')}
                      <span
                        {...column.getHeaderProps(
                          column?.getSortByToggleProps(),
                        )}
                        className={`sortedIcon ${
                          selectFeature ? 'sortedIconChecked' : ''
                        }`}
                      >
                        {column.isSorted ? (
                          getSortStatus(column.isSortedDesc)
                        ) : (
                          <img src={unsortedIcon} alt="unsortedIcon" />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              )
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  className={
                    row.isSelected ? 'mainTableTrSelected' : 'mainTableTr'
                  }
                  key={row?.id}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        className={
                          selectFeature ? `mainTableTdSelected` : `mainTableTd`
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* table pagination */}
      <Pagination
        loading={false}
        currentPage={pageIndex + 1}
        totalItems={data.length}
        totalPages={pageCount}
        onClickPrev={() => previousPage()}
        onClickNext={() => nextPage()}
      />
      {/* delete item/items confirmation modal  */}
      {deleteModal && (
        <ConfirmationModal
          heading={`Are you sure you want to delete ${title}?`}
          onSubmit={() => onDelete(selectedFlatRows)}
          onCancel={() => setDeleteModal(false)}
          loading={deleteIsLoading}
        />
      )}
    </>
  )
}

export { ReactTable }
