import classNames from 'classnames'
import React, { ReactNode, useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import './LensSidebar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen } from '@fortawesome/pro-regular-svg-icons'
import { setSidebarLens } from 'Redux/actions'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { TRootState } from 'types'

export const LensSidebar = ({ children }: { children: ReactNode }) => {
  const Location = useLocation()

  const newCheck = () => {
    if (Location?.pathname !== '/prospects') {
      return false
    }
    return true
  }

  const [prospect, setProspect] = useState(newCheck())

  const [centerState, setCenterState] = useState(false)
  const { isCenter, openSideBar } = useSelector(
    (state: TRootState) => state.centerLensReducer,
  )
  const dispatch = useDispatch()
  const toggleSidebar = () => {
    dispatch(setSidebarLens(!openSideBar))
  }

  // const {openSideBar} =useSelector<any,any>((state)=> state.centerLensReducer)
  useEffect(() => {
    setCenterState(isCenter)
  }, [isCenter])
  return (
    <div
      className={`${prospect ? 'LensSidebar' : 'ProspectLensGridBar'} ${
        isCenter ? 'ProspectLensGridBarCenter' : ''
      }`}
      style={{
        boxShadow: openSideBar ? '' : 'none',
        background: openSideBar ? '' : 'none',
      }}
    >
      <div style={{ display: !openSideBar ? 'none' : '' }}>{children}</div>
      {!openSideBar && (
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleSidebar}
          // className="LensSidebar__collapse"
        />
      )}
    </div>
  )
}
