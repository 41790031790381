/* eslint-disable */
/* @ts-ignore */
import {
  CardComponent,
  CardCVV,
  CardExpiry,
  CardNumber,
} from '@chargebee/chargebee-js-react-wrapper'
import { Button, FormItem } from '@eltoro-ui/components'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PersonalInput } from 'Components/PersonalInfoForm/component/PersonalInput'
import { Spinner } from 'Components/Spinner/Spinner'
import * as React from 'react'
import { connect } from 'react-redux'
import './ChargebeePay.scss'
import { ChargebeeSuccessModal } from './ChargebeeSuccessModal'

import { ErrorMsg, Tooltip } from 'Components'
import { chargebeeSubscription } from 'Requests'
import { LableInputField } from 'Components/LableInputField'
import Warning from '../../../../Images/warning.png'

//@ts-ignore
window?.Chargebee?.init({
  site: process.env.REACT_APP_CHARGEBEE_SITE,
  publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHKEY,
})

export const successModal = () => {
  return <ChargebeeSuccessModal />
}

class Pay extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = { data: false }
  // }
  componentDidMount() {
    //@ts-ignore
    // @ts-ignore
  }

  cardRef = React.createRef<any>()
  state = {
    successfullPayment: false,
    fullName: '',
    isSubmit: false,
    isLoading: false,
    ModalOpen: false,
    errors: {},
    errorMessage: '',
    // CSS class names for field's status
    classes: {
      focus: 'focus',
      invalid: 'invalid',
      empty: 'empty',
      complete: 'complete',
    },
    // Google Fonts and other whitelisted fonts
    fonts: ['https://fonts.googleapis.com/css?family=Open+Sans'],
    // Style customizations
    styles: {
      base: {
        color: '#6d6d6d',
        fontWeight: 400,
        paddingTop: '4px',

        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        lineHeight: '17px',

        // height: '1.4rem',
        ':focus': {
          // color: '#6d6d6d',
        },

        '::placeholder': {
          color: '#6D6D6D',
        },

        ':focus::placeholder': {
          color: '#6D6D6D',
          border: '1px solid #bd0000',
        },
      },
      invalid: {
        border: '1px solid #bd0000 !important',
        color: '#BD0000',
        ':focus': {
          color: '#BD0000',
        },
        '::placeholder': {
          color: '#BD0000',
        },
      },
    },
    placeholder: {
      number: '4111 1111 1111 1111',
      expiry: 'MM / YY',
      cvv: 'CVC',
    },
  }

  onSubmit = (e: React.FormEvent) => {
    this.setState({ isSubmit: true, isLoading: true })

    if (e) e.preventDefault()

    if (this.cardRef && this.state.fullName) {
      // Call tokenize method on card element
      this.cardRef.current
        .tokenize()
        .then((data: any) => {
          // @ts-ignore

          const body = {
            subscription_id:
              // @ts-ignore
              this?.props?.chargebeeSelectedPackage?.subscription_id,
            /* @ts-ignore */
            metadata: this.props.chargebeeSelectedPackage.metadata,
            // metadata: {
            //   /* @ts-ignore */
            //   coins: this.props.chargebeeSelectedPackage.metadata.coins,
            //   /* @ts-ignore */
            //   price: this.props.chargebeeSelectedPackage.metadata.price,
            //   /* @ts-ignore */
            //   currency: this.props.chargebeeSelectedPackage.metadata.currency,
            // },
            token_id: data.token,
            // @ts-ignore
            user_id: JSON.stringify(this.props.user.id),
          }

          chargebeeSubscription(body).then((resp: any) => {
            // if (resp.data.detail === 'subcription created') {
            // @ts-ignore
            this.props.close()

            this.setState({ successfullPayment: true, isLoading: false })
          })
          // this.props.getModalData({ name: 'data' })
        })
        .catch((err: any) => {
          // alert(err.error.detail)
          this.setState({ isLoading: false, errors: err })
        })
    }
  }

  onChange = (status: any) => {
    this.setState({ isSubmit: false })
  }

  onReady = (el: any) => {
    el.focus()
  }

  ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="Please fill the input"
          topAbsolute="-110px"
          rightAbsolute="-43px"
          padding="5px 5px"
        />
      </div>
    )
  }

  render() {
    const { fonts, styles, classes, placeholder } = this.state
    // @ts-ignore
    // Using individual fields mode
    return (
      <div style={{ height: 'auto', width: '300px' }}>
        {this.state.isLoading && <Spinner />}
        <div className="cell example example3" id="example-3">
          <form>
            <LableInputField
              style={{ paddingLeft: '22px', margin: '2rem  0' }}
              lableStyle={{ left: '14px' }}
              title="Name On Card"
              placeholder="Name On Card"
              type="text"
              isError={this.state.isSubmit && !this.state.fullName}
              onChange={(e) => {
                this.setState({ fullName: e.target.value, isSubmit: false })
              }}
              value={this.state.fullName}
              iconRight={
                <img
                  className="start_icon"
                  style={{ marginTop: '7px', width: '24px' }}
                  src={
                    this.state.isSubmit && !this.state.fullName ? Warning : ''
                  }
                  // alt="icon"
                />
              }
            />
            {this.state.isSubmit && !this.state.fullName ? (
              <this.ErrorToolbar />
            ) : (
              ''
            )}
            {/* <FormItem
            UNSAFE_ClassName="Chargebee_label"
            htmlFor="Name on Card"
            label="Name on Card"
            errorMessage="Please enter valid name"
            required
          >
            <PersonalInput
              UNSAFE_ClassName="Fullname_chargebee"
              isError={this.state.isSubmit && !this.state.fullName}
              onChange={(e) => {
                this.setState({ fullName: e.target.value, isSubmit: false })
              }}
              value={this.state.fullName}
            />
            {this.state.isSubmit && !this.state.fullName ? (
              <ErrorMsg
                title="Fullname required"
                icon={<FontAwesomeIcon icon={faExclamationCircle} />}
              />
            ) : (
              ''
            )}
          </FormItem> */}

            <div className="fieldset">
              <CardComponent
                className="field"
                fonts={fonts}
                classes={classes}
                styles={styles}
                ref={this.cardRef}
                onReady={this.onReady}
                placeholder={placeholder}
              >
                <div className="ex1-field">
                  {/* Card number component */}
                  <CardNumber
                    onChange={this.onChange}
                    onReady={this.onReady}
                    className="ex1-input"
                  />
                  <label className="ex1-label">Card Number</label>
                  {/* <i className="ex1-bar"></i> */}
                </div>

                {/* <span>{this.state.errors?<this.ErrorToolbar />:""}</span> */}

                {/* <FormItem
                  UNSAFE_ClassName="Chargebee_label"
                  htmlFor="Card Number"
                  label="Card Number*"
                  errorMessage="Please enter valid name"
                  required
                  Height="1rem"
                >
                  <CardNumber
                    placeholder="4111 1111 1111 1111"
                    className="field-ex1 empty"
                    onChange={this.onChange}
                    onReady={this.onReady}
                  />
                </FormItem> */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div className="ex1-field" style={{ width: '45%' }}>
                    {/* Card expiry component */}
                    <CardExpiry
                      className="ex1-input"
                      onChange={this.onChange}
                    />
                    <label className="ex1-label">
                      Expiration<span style={{ color: '#BD0000' }}>*</span>
                    </label>
                  </div>

                  <div className="ex1-field" style={{ width: '45%' }}>
                    {/* Card cvv component */}
                    <CardCVV onChange={this.onChange} className="ex1-input" />
                    <label className="ex1-label">
                      CVC<span style={{ color: '#BD0000' }}>*</span>
                    </label>
                  </div>

                  {/* <FormItem
                    Width=""
                    isStar
                    htmlFor="Expiration"
                    UNSAFE_ClassName="Chargebee_label"
                    label="Expiration"
                    errorMessage="Please enter valid name"
                    required
                    Height="1rem"
                  >
                    <CardExpiry
                      placeholder="MM / YY"
                      className="field-ex1 empty cvvSpace"
                      onChange={this.onChange}
                    />
                  </FormItem>

                  <FormItem
                    Width=""
                    htmlFor="CVC"
                    UNSAFE_ClassName="Chargebee_label"
                    label="CVC"
                    isStar
                    errorMessage="Please enter valid name"
                    required
                    Height="1rem"
                  >
                    <div style={{ background: '#fff' }}>
                      <CardCVV
                        placeholder="CVV"
                        className="field-ex1 empty cvvSpace"
                        onChange={this.onChange}
                      />
                    </div>
                  </FormItem> */}
                </div>
              </CardComponent>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <Button
                  type="button"
                  kind="primary"
                  UNSAFE_style={{
                    padding: '20px 20px',
                    marginTop: '10px',
                    fontWeight: '600',
                    width: '177px',
                    height: '65px',
                  }}
                  disabled={
                    // @ts-ignore
                    !this.props?.chargebeeSelectedPackage ||
                    this.state.isLoading
                  }
                  // @ts-ignore
                  onClick={this.onSubmit}
                >
                  Pay Now
                </Button>
              </div>
              <div style={{ position: 'relative' }}>
                <div
                  className="onboardingBack"
                  onClick={() => {
                    //@ts-ignore
                    this.props.back()
                  }}
                  aria-hidden="true"
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    color="#FFAB03"
                    style={{ marginTop: '18px', marginRight: '1rem' }}
                  />
                  <p style={{ fontWeight: 'bold' }}>Go Back</p>
                </div>
              </div>
            </div>
          </form>
        </div>

        {this.state.successfullPayment && (
          // @ts-ignore
          <ChargebeeSuccessModal />
        )}
        {/* End Successfull payment */}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  user: state.userReducer.userData,
  chargebeeSelectedPackage: state?.chargebeePackageReducer?.chargebeePackage,
})
export default connect(mapStateToProps, null)(Pay)
