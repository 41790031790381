import React, { useState } from 'react'
import { Button, Modal, Text } from '@eltoro-ui/components'
import './index.scss'

export default function ConfirmModal({
  onClickOutside,
  onSubmit,
  deleteId,
  textToDisplay,
}: {
  onClickOutside: () => void
  onSubmit: (id: string | number) => void
  deleteId: string | number
  textToDisplay: string
}) {
  const [frontSubmittedFile, setfrontSubmittedFile] =
    useState<{ acceptedFiles: [{ name: string }] }>()
  return (
    <Modal offClick={onClickOutside}>
      <div className="UploadVideoModal">
        <Text tag="div" on="white" size="xxl">
          Confirmation
        </Text>
        <Text tag="div" on="white">
          {textToDisplay}
        </Text>
        <div>{frontSubmittedFile?.acceptedFiles[0]?.name}</div>
        <div className="UploadVideoModal__footer">
          <Button
            kind="text"
            UNSAFE_className="UploadVideoModal__cancel-button"
            onClick={onClickOutside}
          >
            No
          </Button>
          <Button
            kind="primary"
            onClick={() => {
              onSubmit(deleteId)
              onClickOutside()
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  )
}
