import React, { Fragment } from 'react'
import './ProgressStepper.scss'
import { useHistory } from 'react-router-dom'

export const ProgressStepper = ({
  totalSteps,
  activeStep,
  margin,
  color,
  tagNames,
  pathset,
  pathset1,
}: {
  totalSteps: number
  activeStep: number
  margin?: string
  color?: string
  pathset?: any
  pathset1?: any

  tagNames?: string[]
}) => {
  const history = useHistory()
  const pathsetp = (path: number) => {
    if (path === 0) {
      return pathset
    }
    if (path === 1) {
      return pathset1
    }
    return null
  }
  let i = 0
  const steps = []
  while (i < totalSteps) {
    steps.push(
      <Fragment key={i}>
        {i > 0 && (
          <div
            className={`ProgressStepperBar__line ${
              activeStep > i ? 'ProgressStepperBar__line--is-filled' : ''
            }`}
          />
        )}
        <div>
          <button
            onClick={pathsetp(i)}
            style={{ cursor: pathsetp(i) ? 'pointer' : '' }}
            className="ProgressStepperBar__stepbutton"
            type="button"
          >
            <div
              className={`ProgressStepperBar__step ${
                activeStep > i ? 'ProgressStepperBar__step--is-filled' : ''
              }`}
              style={{
                color: activeStep > i ? 'var(--color-secondary-300)' : '',
                textAlign: 'center',
                paddingTop: activeStep > i ? '2px' : '3px',
                border:
                  activeStep > i ? '3px solid var(--color-secondary-300)' : '',
              }}
            >
              {i + 1}
            </div>
          </button>
          <button
            onClick={pathsetp(i)}
            style={{ cursor: pathsetp(i) ? 'pointer' : '' }}
            className="ProgressStepperBar__stepbutton_text"
            type="button"
          >
            <div
              style={{
                color: activeStep > i ? 'var(--color-secondary-300)' : '',
                textAlign: 'center',
                letterSpacing: '1px',
                paddingTop: '4px',
                fontWeight: activeStep > i ? 600 : 500,
              }}
              className="progressTag"
            >
              {tagNames && tagNames[i]}
            </div>
          </button>
        </div>
      </Fragment>,
    )
    i += 1
  }

  return (
    <div className="ProgressStepperBar">
      {' '}
      <div className="ProgressStepperBar__steps">{steps}</div>
      {/* <div>
        <div className="progressStepper">1</div>
        <div
          className={`ProgressStepperBar__line ${
            activeStep > i ? 'ProgressStepperBar__line--is-filled' : ''
          }`}
          style={{ backgroundColor: activeStep > i ? color : '' }}
        />
        <span>Audiendce</span>
      </div>
      <div>
        <div className="progressStepper">1</div>
        <span>Audiendce</span>
      </div>
      <div>
        <div className="progressStepper">1</div>
        <span>Audiendce</span>
      </div>
      <div>
        <div className="progressStepper">1</div>
        <span>Audiendce</span>
      </div> */}
    </div>
  )
}
