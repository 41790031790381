import React from 'react'
import { Spacer, Text } from '@eltoro-ui/components'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './MoverLifecycleFilters.scss'

export type MoverLifecycleType = {
  label: string
  faIcon: IconDefinition
  test: (lifecycleKind: string) => boolean
}

export const MoverLifecycleFilters: React.FC<{
  filters: MoverLifecycleType[]
  activeFilters: MoverLifecycleType[]
  onChange: (newActiveFilters: MoverLifecycleType[]) => void
}> = ({ filters, activeFilters, onChange }) => {
  const isActive = (filter: MoverLifecycleType) =>
    activeFilters.some((activeFilter) => activeFilter.label === filter.label)

  const handleClick = (clickedFilter: MoverLifecycleType) => {
    if (isActive(clickedFilter)) {
      onChange(activeFilters.filter((f) => f.label !== clickedFilter.label))
    } else {
      onChange([...activeFilters, clickedFilter])
    }
  }
  return (
    <>
      {filters.map((filter) => (
        <button
          key={filter.label}
          className={`MoverLifecycleFilters ${
            isActive(filter) ? 'MoverLifecycleFilters--is-active' : ''
          }`}
          onClick={() => handleClick(filter)}
          type="button"
        >
          <div className="MoverLifecycleFilters__icon">
            <FontAwesomeIcon icon={filter.faIcon} />
          </div>
          <Spacer />
          <div className="MoverLifecycleFilters__label">
            <Text
              on="white"
              weight="bold"
              kind={isActive(filter) ? 'secondary' : 'subdued'}
            >
              {filter.label}
            </Text>
            <Text on="white">
              {/* update <b> below when API returns targets per type */}
              <b>an amount of</b> targets
            </Text>
          </div>
        </button>
      ))}
    </>
  )
}
