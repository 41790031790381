import React, { Fragment } from 'react'
import { Section, ProgressIndicator } from 'Components'
import { FlexBox, Text, Button, GridBox } from '@eltoro-ui/components'
import { TCampaignDetails } from 'types'
import './CampaignReport.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImagePolaroid, faPlus } from '@fortawesome/pro-solid-svg-icons'
import axios from 'axios'
import { fakeCreatives, FakeCreativeType } from './fakeCreatives'

const CampaignDetailsText = ({
  title,
  body,
}: {
  title: string
  body: string
}) => {
  return (
    <div>
      <Text
        on="white"
        size="m"
        UNSAFE_className="creatives-report-text"
        weight="bold"
        tag="div"
      >
        {title}
      </Text>
      <Text on="white" size="xl" tag="div">
        {body}
      </Text>
    </div>
  )
}

const DevicesServedContainer = () => {
  return (
    <FlexBox
      flexGrow={1}
      flexDirection="column"
      justifyContent="space-around"
      alignContent="center"
    >
      <Text on="white" size="m" weight="bold" tag="div">
        Devices Served
      </Text>
      <div className="CampaignReport__devices-served">
        <Text on="white" size="m" weight="bold" tag="div">
          Mobile - 25%
        </Text>
        <Text on="white" size="m" weight="bold" tag="div">
          Desktop - 35%
        </Text>
        <Text on="white" size="m" weight="bold" tag="div">
          Tablet - 35%
        </Text>
        <Text on="white" size="m" weight="bold" tag="div">
          Other - 5%
        </Text>
      </div>
    </FlexBox>
  )
}

const HighestPreformingContainer = ({
  creative,
}: {
  creative: FakeCreativeType
}) => {
  return (
    <FlexBox flexDirection="column" flexGrow={3}>
      <Text on="white" size="m" weight="bold" tag="div">
        Highest Performing Creative
      </Text>
      <div className="CampaignReport__highest-preforming-container">
        <FlexBox flexDirection="column">
          <FontAwesomeIcon
            icon={faImagePolaroid}
            className="CampaignReport__big-image"
          />
          <Text on="white" size="m" tag="div">
            {creative.name}
          </Text>
          <Text on="white" size="m" tag="div">
            Banner - 300x250
          </Text>
        </FlexBox>
        <FlexBox flexDirection="column" justifyContent="center">
          <GridBox gap="1rem">
            <CampaignDetailsText
              title="CLICKS"
              body={creative.clicks ? creative.clicks.toString() : ''}
            />
            <CampaignDetailsText title="CTR" body="7.90%" />
            <CampaignDetailsText
              title="IMPRESSIONS"
              body={creative.impressions ? creative.impressions.toString() : ''}
            />
          </GridBox>
        </FlexBox>
      </div>
    </FlexBox>
  )
}
export function getBase64(url: string) {
  axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  }
  return axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
}

export const CampaignReport = ({
  campaign,
}: {
  campaign: TCampaignDetails
}) => {
  const fakeCreative: FakeCreativeType = {
    name: 'Best Creative Ever',
    cost: 1000,
    impressions: 371,
    clicks: 789,
  }
  const fileToBlob = (url: string, name: string) => {
    getBase64(url).then((res) => {
      // setloader(false);
      // setimgState("data:image/jpeg;base64," + res);
      // showMarkerArea();

      const a = document.createElement('a')
      a.href = `data:image/jpeg;base64,${res}`
      a.download = name

      a.target = '_blank'

      a.click()
    })
  }

  return (
    <div className="CampaignReport">
      {campaign?.orderlines.length > 0 && (
        <Section
          title="Creatives"
          UNSAFE_className_text="section-campaign-details"
        >
          <GridBox gap="1rem" UNSAFE_className="creatives-banners">
            {campaign?.orderlines?.map((creative, indexParent: number) => {
              return (
                <Fragment key={indexParent}>
                  {creative?.creatives?.length &&
                    creative?.creatives?.map((view, index: number) => (
                      <button
                        key={`${index}${view?.url}`}
                        type="button"
                        // download={creative.thumb}
                        style={{
                          textDecoration: 'none',
                          background: '#FFFFFF',
                          cursor: 'pointer',
                          border: '0.965838px solid #C6CBCD',
                        }}
                        // download={view.name}

                        onClick={(e) => {
                          fileToBlob(view?.url, view.name)
                        }}
                      >
                        <div
                          className="CampaignReport__creative-grid-box"
                          key={view._id}
                        >
                          {creative.creative_type === 'Video' ? (
                            <video
                              // src={this.state.get_video}
                              src={view.url}
                              style={{
                                objectFit: 'cover',
                                border: '1px solid #eeeff0',
                                background: '#f3f3f3',
                              }}
                              width="140px"
                              height="95px"
                              // className="get_preview_video_class"
                            >
                              <track kind="captions" />
                            </video>
                          ) : (
                            <img
                              src={view.url}
                              alt=""
                              style={{
                                objectFit: 'cover',
                                background: '#f3f3f3',

                                border: '1px solid #eeeff0',
                              }}
                              width="140px"
                              height="95px"
                            />
                          )}
                          <div>
                            <Text
                              UNSAFE_className="CampaignReport__creative-grid-box__imageText"
                              on="white"
                              size="s"
                              tag="div"
                              weight="bold"
                            >
                              {view.name.split('__')[1]}
                            </Text>
                            <Text
                              on="white"
                              UNSAFE_style={{ color: '#6d797c' }}
                              size="s"
                              tag="div"
                            >
                              {creative.creative_type} - {view.width}x
                              {view.height}
                            </Text>
                          </div>
                        </div>
                      </button>
                    ))}
                </Fragment>
              )
            })}
          </GridBox>
        </Section>
      )}

      <Section title="Report" UNSAFE_className_text="section-campaign-details">
        <div className="CampaignReport__body ">
          <FlexBox
            UNSAFE_className="display-grid-aligned"
            justifyContent="space-between"
          >
            <CampaignDetailsText title="CLICKS" body={`${campaign.clicks}`} />
            <CampaignDetailsText title="CTR" body={`${campaign.ctr}%`} />
            <div>
              <Text
                UNSAFE_className="text-campaign-details"
                on="white"
                size="m"
                weight="bold"
                tag="div"
              >
                IMPRESSIONS
              </Text>
              <ProgressIndicator
                styleXL="xl"
                current={campaign?.served_impressions}
                end={campaign?.total_impressions}
              />
            </div>
            <div>
              <Text
                UNSAFE_className="creatives-report-text "
                on="white"
                size="m"
                weight="bold"
                tag="div"
              >
                SPENT
              </Text>
              <ProgressIndicator
                current={campaign.served_rate || 0}
                end={campaign.total_rate || 0}
                prefix="$"
                styleXL="xl"
              />
            </div>
          </FlexBox>
        </div>
      </Section>
    </div>
  )
}
