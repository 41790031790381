import React from 'react'
import './Button.scss'
import classNames from 'classnames'
import * as CSS from 'csstype'
import { Link, LinkProps } from 'react-router-dom'
import { SpinnerLoader } from 'Components/SpinnerLoader'

type ButtonProps = {
  children?: string | JSX.Element | JSX.Element[]
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  content?: JSX.Element
  UNSAFE_className?: string
  rounded?: boolean
  type?: 'button' | 'submit' | 'file'
  kind?: 'default' | 'primary' | 'danger' | 'text'
  size?: 's' | 'm' | 'l' | 'xl'
  UNSAFE_TEXTSTYLE?: string
  width?: string
  UNSAFE_style?: CSS.Properties
  fonts?: undefined | string
  weight?: CSS.Property.FontWeight | undefined
  disabled?: any
  onMouseOver?: (event?: React.MouseEvent) => void
  onMouseOut?: (event?: React.MouseEvent) => void
  name?: undefined | string
  data_testid?: string
  loading?: boolean
  Text?: string
} & (
  | { onClick?: (event?: React.MouseEvent) => void; to?: undefined }
  | { onClick?: undefined; to?: LinkProps['to'] }
)
type RefProps =
  | {
      buttonRef?:
        | React.Ref<HTMLButtonElement>
        | React.MutableRefObject<HTMLButtonElement>
      linkRef?: undefined
    }
  | {
      linkRef?:
        | React.Ref<HTMLAnchorElement>
        | React.MutableRefObject<HTMLAnchorElement>
      buttonRef?: undefined
    }

export const Button = ({
  children,
  iconLeft,
  iconRight,
  content,
  onClick,
  weight,
  UNSAFE_className,
  rounded = false,
  type,
  kind = 'default',
  size = 'm',
  width,
  UNSAFE_style,
  fonts,
  disabled = false,
  onMouseOver,
  onMouseOut,
  to,
  buttonRef,
  linkRef,
  name,
  UNSAFE_TEXTSTYLE,
  data_testid,
  loading,
}: ButtonProps & RefProps) => {
  const sizeClass = ` Button--${size}-size`

  const wrapperClasses = classNames(
    'Button',
    sizeClass,
    {
      'Button--primary': kind === 'primary',
      'Button--danger': kind === 'danger',
      'Button--text': kind === 'text',
      'Button--disabled': disabled,
      'Button--rounded': rounded,
      'Button--has-right-icon': !!iconRight,
      'Button--has-left-icon': !!iconLeft,
    },
    UNSAFE_className,
  )

  const handleMouseOver = (event: React.MouseEvent) => {
    if (onMouseOver) {
      onMouseOver(event)
    }
  }
  const handleMouseOut = (event: React.MouseEvent) => {
    if (onMouseOut) {
      onMouseOut(event)
    }
  }

  const handleClick = (event: React.MouseEvent) => {
    if (onClick && !disabled) {
      onClick(event)
    }
  }

  const customStyle: CSS.Properties = { width, ...UNSAFE_style }

  const RenderIcon = ({ icon }: { icon: JSX.Element }) => icon

  const buttonContent = (
    <>
      {iconLeft ? (
        <div className="Button__icon-wrapper">
          <RenderIcon icon={iconLeft} />
        </div>
      ) : null}
      {children ? (
        <div>
          <span
            className={`Button__text ${UNSAFE_TEXTSTYLE}`}
            style={{ font: fonts, fontWeight: weight }}
          >
            {children}
            {content}
          </span>
        </div>
      ) : null}
      {iconRight ? (
        <div className="Button__icon-wrapper">
          <RenderIcon icon={iconRight} />
        </div>
      ) : null}
    </>
  )

  const commonProps = {
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    style: customStyle,
    onFocus: () => false,
    onBlur: () => false,
  }

  const linkProps = {
    ...commonProps,
    ref: linkRef,
    className: `${wrapperClasses} Button__link`,
  }

  const buttonProps = {
    ...commonProps,
    onClick: handleClick,
    disabled,
    type: type || 'button',
    className: wrapperClasses,
    ref: buttonRef,
    width,
  }

  const renderButton = (
    passedType: 'button' | 'submit' | 'file' | undefined,
  ) => {
    if (passedType === 'submit') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button {...buttonProps} style={{ ...customStyle }} type="submit">
            {loading ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {buttonContent}
                <SpinnerLoader
                  style={{
                    position: 'unset',
                    color: 'black',
                    width: '25px',
                    height: '25px',
                    disabled: 'none !important',
                    marginTop: '0px',
                    opacity: 1,
                  }}
                />
              </div>
            ) : (
              buttonContent
            )}
          </button>
        </div>
        // <button
        //   {...buttonProps}
        //   style={{ display: 'flex', justifyContent: 'center' }}
        //   type="submit"
        // >
        //   {buttonContent}
        //   {loading ? (
        //     <SpinnerLoader
        //       style={{
        //         position: 'unset',
        //         color: 'green',
        //         width: '25px',
        //         height: '25px',
        //       }}
        //     />
        //   ) : (
        //     ''
        //   )}
        // </button>
      )
    }

    return (
      <>
        <button
          data-testid={data_testid}
          {...buttonProps}
          type="button"
          style={{
            ...customStyle,
            display: 'flex',
            justifyContent: 'center',
          }}
          name={name}
        >
          {loading ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {buttonContent}
              <SpinnerLoader
                style={{
                  position: 'unset',
                  color: 'black',
                  width: '25px',
                  height: '25px',
                  disabled: 'none !important',
                  marginTop: '0px',
                  opacity: 1,
                }}
              />
            </div>
          ) : (
            buttonContent
          )}
        </button>
      </>
    )
  }

  return to && !disabled ? (
    <Link {...linkProps} to={to}>
      {buttonContent}
    </Link>
  ) : (
    renderButton(type)
  )
}
