import { Button, Modal, Table, Text } from '@eltoro-ui/components'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loading, Section, Spinner } from 'Components'
import { getLocalDate, getLocalDateTime } from 'Helpers'
import React, { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import toast, { Toaster } from 'react-hot-toast'
import { getCampaigns } from 'Requests'
import { useDispatch } from 'react-redux'
import { currentCampaignName } from 'Redux/actions'
import { getCompaignPerformance } from 'Requests/Request_Methods/campaignMethods'
import { multiOverlappingOptions } from '../../barOptions'
import './CampaignPerformance.scss'
import cross from '../../../../assets/Images/cross.png'
import nodata from '../../../../assets/Images/nodata.png'

export const CampaignPerformance = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [loader, setloader] = useState(true)
  const [campaigns, setCampaigns] = useState<any[]>([])
  const [unselect, setunselect] = useState()
  const [campaignName, setcampaignName] = useState('')
  const [selectedCampaign, setSelectedCampaign] = useState<any[]>([])
  const [pages, setPages] = useState<any>()
  const [LoadingList, setLoadingList] = useState(false)
  const [barData, setbarData] = useState({})
  const [submitList, setsubmitList] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    setLoadingList(true)
    if (currentPage === 1) {
      setloader(true)
    }
    getCampaignsList()
  }, [currentPage])
  useEffect(() => {
    dispatch(currentCampaignName(campaignName))
  }, [campaignName])
  const getCampaignsList = () => {
    getCampaigns(currentPage, 20, null, null, null, false).then((res: any) => {
      setCampaigns([...campaigns, ...res.data.campaigns])

      if (res.data.campaigns[0]) {
        if (currentPage === 1) {
          campaignPerformance(res.data.campaigns[0])
          setSelectedCampaign([res.data.campaigns[0]])
          setcampaignName(res.data?.campaigns[0]?.name)
        } else {
          setloader(false)
        }
        setLoadingList(false)
        setPages({
          current_page: res?.data?.current_page,
          total_count: res?.data?.total_campaigns,
          total_pages: res?.data?.total_pages,
          last_page: res.data.campaigns.length < 1 && true,
        })
      } else {
        // toast.error('Campaign not found')
        setloader(false)
        setLoadingList(false)
      }
    })
  }
  const QuaterCheck = (date: string) => {
    const month = date.split('-')[1]
    const checkQuater = parseFloat(month) / 3
    return Math.round(checkQuater)
  }
  // QuaterCheck()

  const campaignPerformance = (selectedCamp: undefined | any) => {
    let campaignId: number
    // if (selectedCamp) {
    //   campaignId = selectedCamp.id
    // } else {
    //   campaignId = selectedCampaign[0].id
    // }
    setsubmitList(true)
    getCompaignPerformance(selectedCamp.id).then((reds) => {
      if (currentPage === 1) {
        setloader(false)
      }
      setsubmitList(false)
      setcampaignName(selectedCamp.name)
      if (reds.data) {
        const multiDataddd = {
          labels: [
            `Q${QuaterCheck(reds.data.Q0.end_date)}  - ${reds.data.Q0.end_date
              .split('-')[0]
              .slice(-2)}’`,
            `Q${QuaterCheck(reds.data.Q1.end_date)} - ${reds.data.Q1.end_date
              .split('-')[0]
              .slice(-2)}’`,
            `Q${QuaterCheck(reds.data.Q2.end_date)} - ${reds.data.Q2.end_date
              .split('-')[0]
              .slice(-2)}’`,
            `Q${QuaterCheck(reds.data.Q3.end_date)} - ${reds.data.Q3.end_date
              .split('-')[0]
              .slice(-2)}’`,
          ],
          datasets: [
            {
              label: 'Clicks',
              data: [
                `${reds.data.Q0.clicks}`,
                `${reds.data.Q1.clicks}`,
                `${reds.data.Q2.clicks}`,
                `${reds.data.Q3.clicks}`,
              ],
              backgroundColor: '#44C2E6',
              hoverBackgroundColor: '#44C2E6',
            },
            {
              label: 'Impressions',
              data: [
                `${reds.data.Q0.impressions}`,
                `${reds.data.Q1.impressions}`,
                `${reds.data.Q2.impressions}`,
                `${reds.data.Q3.impressions}`,
              ],
              backgroundColor: '#007B94',
              hoverBackgroundColor: '#007B94',
            },
          ],
        }
        setbarData(multiDataddd)
        setIsModalOpen(false)
      } else {
        toast.error('Campaign not found')
      }
    })
  }

  const listInnerRef = useRef<HTMLInputElement | null>(null)

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight === scrollHeight) {
        if (!pages.last_page) {
          setTimeout(() => {
            setCurrentPage(currentPage + 1)
          }, 300)
        }
      }
    }
  }
  return (
    <>
      {loader && (
        <div className="CampaignPerformance__loader_campain">
          <Loading />
        </div>
      )}
      {!loader && campaigns.length > 0 && (
        <Section
          title="Campaign Performance"
          UNSAFE_className_text="CampaignPerformance__title"
          sub_title={campaignName}
          actions={
            <Button
              onClick={() => setIsModalOpen(true)}
              UNSAFE_className="CampaignPerformance__select_campaign"
              disabled={campaignName === '' && true}
            >
              Select Campaign
            </Button>
          }
        >
          {isModalOpen && (
            <Modal offClick={() => setIsModalOpen(false)}>
              <Toaster position="top-center" />

              <div className="OnboardingModal-welcome CampaignPerformance_ListingModalBg CampaignPerformance__submitModal">
                <div className="SaveAudienceModal__cancel">
                  {/* <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => setIsModalOpen(false)}
                  /> */}
                  <img
                    src={cross}
                    onClick={() => {
                      setIsModalOpen(false)
                    }}
                    alt="cross"
                    className="PersonalInfoForm__cross"
                    role="presentation"
                  />
                </div>
                <div
                  // style={{ width: '620px', height: '90%' }}
                  className="CampaignPerformance__content"
                >
                  <div className="">
                    <div style={{ padding: '8px 5rem', textAlign: 'center' }}>
                      <Text
                        on="white"
                        size="xxxl"
                        UNSAFE_className="CampaignPerformance__contentModal"
                        textAlign="center"
                      >
                        Select Campaign{' '}
                      </Text>
                    </div>
                  </div>

                  <div
                    className="Modal__scrollable-content"
                    style={{
                      maxHeight: '445px',
                      overflowY: 'auto',
                      marginBottom: '1rem',
                    }}
                    onScroll={() => onScroll()}
                    ref={listInnerRef}
                  >
                    <Table
                      noCheckbox
                      rows={campaigns}
                      onSelect={(camp) => {
                        setSelectedCampaign(camp)
                        // setSelections([...id])
                        // setPreSelections([...id])
                      }}
                      radioButton
                      // preSelected={PreSelections}
                      unSelectAll={unselect}
                      // noCheckbox={false}
                      // fixedCellSize
                      columns={[
                        {
                          path: 'name',
                          label: 'Campaign Name',
                          width: '50%',
                          alignment: 'left',

                          // onSort,
                        },

                        {
                          path: 'created_at',
                          label: 'Date Captured',
                          alignment: 'left',
                          width: '20%',
                          RowCell: (campaign) => (
                            <div
                              style={{
                                // paddingInline: '50px',
                                textAlign: 'start',
                              }}
                            >
                              {' '}
                              {
                                getLocalDateTime(
                                  campaign.created_at,
                                  true,
                                ).split(' ')[0]
                              }
                              <br />{' '}
                              {getLocalDateTime(
                                campaign.created_at,
                                true,
                              ).split(' ')[1]
                                ? getLocalDateTime(
                                    campaign.created_at,
                                    true,
                                  ).split(' ')[1]
                                : getLocalDateTime(
                                    campaign.created_at,
                                    true,
                                  ).split(' ')[2]}
                            </div>

                            // || '-'
                          ),
                          // onSort,
                        },
                      ]}
                    />
                    {LoadingList && <Spinner />}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '8px',
                      position: 'absolute',
                      bottom: '3rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      left: 0,
                      right: 0,
                    }}
                  >
                    <Button
                      kind="primary"
                      size="xl"
                      width="204px"
                      disabled={submitList}
                      weight="bold"
                      onClick={() => {
                        campaignPerformance(selectedCampaign[0])
                        // setOpenSubmitModal(false)
                        // History.push('/campaigns')
                      }}
                      loading={submitList}
                    >
                      {submitList ? '' : 'Done'}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          <div className="CampaignDashboard__campaign-performance">
            <Bar
              data={barData}
              options={multiOverlappingOptions}
              height={100}
            />
          </div>
        </Section>
      )}

      {!loader && campaigns.length < 1 && (
        <Section
          title="Campaign Performance"
          UNSAFE_className_text="CampaignPerformance__title"
          spacer={false}
        >
          <div className="noData">
            <img src={nodata} alt="nodata" />
            <Text on="tertiary-300" UNSAFE_className="noDataText">
              No Data Found
            </Text>
          </div>
        </Section>
      )}
    </>
  )
}
