import React from 'react'
import { Text } from '@eltoro-ui/components'
import hexagon from '../../assets/Images/hexagon.png'

import './ProspectDensity.scss'

export const ProspectDensity = () => {
  return (
    <div className="ProspectDensity_container">
      <div className="title_container">
        <img src={hexagon} alt="..." />
        <Text on="grey-050">Prospect Density</Text>
      </div>
      <div className="description">
        <Text on="grey-050" UNSAFE_className="sub_title">
          Targets that reside around a specific location
        </Text>
      </div>
      <div>
        <div>
          <div className="score_status_bar">
            <span className="span_1" />
            <span className="span_2" />
            <span className="span_3" />
            <span className="span_4" />
          </div>

          <div className="score_status">
            <Text on="grey-050">High</Text>
            <Text on="grey-050" />
            <Text on="grey-050" />
            <Text on="grey-050">Low</Text>
          </div>
        </div>
      </div>
    </div>
  )
}
