import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { InputHTMLAttributes } from 'react'
import './PersonalInput.scss'

interface InputTypes extends InputHTMLAttributes<HTMLInputElement> {
  type?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  isError?: Boolean
  icon?: IconProp | JSX.Element
  validIcon?: JSX.Element
  placeHolder?: string | undefined
  value?: string | number | readonly string[] | undefined
  wdt?: string | number | undefined
  border?: string | number | undefined
  fontSize?: string | number | undefined
  color?: string | undefined
  padding?: string | number | undefined
  UNSAFE_ClassName?: string
}
export const PersonalInput: React.FC<InputTypes> = (props: InputTypes) => {
  const {
    type,
    onChange,
    isError,
    icon,
    validIcon,
    placeHolder,
    value,
    wdt,
    fontSize,
    border,
    color,
    padding,
    UNSAFE_ClassName,
  } = { ...props }
  return (
    <div style={{ position: 'relative' }}>
      <div className={isError ? 'inputLeftIconError' : 'inputLeftIcon'}>
        {icon}
      </div>
      <input
        {...props}
        className={`${
          isError ? 'setPasswordInputError' : 'setPasswordInput'
        } ${UNSAFE_ClassName}`}
        style={{
          width: wdt,
          border: isError ? '1px solid #bd0000' : border,
          padding,
          fontSize,
          height: '38px',
          color,
        }}
        type={type}
        onChange={onChange}
        placeholder={placeHolder}
        value={value}
      />
      <div className={isError ? 'inputRightIcon' : 'hidden'}>{validIcon}</div>
    </div>
  )
}

PersonalInput.defaultProps = {
  type: 'text',
  wdt: '400px',
  border: '3px solid #ccc',
  fontSize: '18px',
  padding: ' 0 0 0 25px',
  color: '#2B1F0A',
}
