import React, { FC } from 'react'
import { Section } from 'Components'
import './SavedAudiences.scss'
import { Text } from '@eltoro-ui/components'
import { PieChart } from 'react-minimal-pie-chart'
import saveaudience from '../../../../assets/Images/saveaudience.png'
import nodata from '../../../../assets/Images/nodata.png'

type SavedAudiencesDataType = {
  total: number
  used: number
}

export const SavedAudiences: FC<{
  data: SavedAudiencesDataType
  textTotal?: String
  textShow: string
}> = ({ data, textTotal = 'SAVED OUT', textShow }) => {
  // const savedAudiencesDataSchema = [
  //   { value: data.total - data.used, color: '#CFD8DC' },
  //   { value: data?.used + 20, color: '#007B94' },
  //   // { value: 345, color: 'red' },
  // ]
  const savedAudiencesDataSchema = [
    { saveaudienceTotal: data?.total },
    { saveaudienceUsed: data?.used },
    // { value: 345, color: 'red' },
  ]
  const isSavedAudiencesDataSchema = savedAudiencesDataSchema.reduce(
    (item) => item,
  )

  // const getChart = () => {
  //   return (
  //     <PieChart
  //       data={savedAudiencesDataSchema}
  //       lineWidth={30}
  //       labelPosition={0}
  //       className="save_audiences_container__pie_className"
  //       label={({ dataIndex, dataEntry }) =>
  //         dataIndex === 1 ? (
  //           <>
  //             <text
  //               dominantBaseline="central"
  //               x="50"
  //               y="50"
  //               dx="0"
  //               dy="-5"
  //               textAnchor="middle"
  //             >
  //               {dataEntry.value}
  //             </text>
  //             <text
  //               dominantBaseline="central"
  //               x="50"
  //               y="50"
  //               dx="0"
  //               dy="13"
  //               textAnchor="middle"
  //               style={{ fontSize: '8px', fontWeight: 700 }}
  //             >
  //               {textTotal}
  //             </text>
  //             <text
  //               dominantBaseline="central"
  //               x="50"
  //               y="50"
  //               dx="0"
  //               dy="22"
  //               textAnchor="middle"
  //               style={{ fontSize: '8px', fontWeight: 700 }}
  //             >
  //               OF {data?.total}
  //             </text>
  //           </>
  //         ) : null
  //       }
  //     />
  //   )
  // }

  return (
    <Section
      title={textShow}
      UNSAFE_className_text="SavedAudiences__title"
      UNSAFE_className_container="SavedAudiences__container"
      // spacer={false}
    >
      {isSavedAudiencesDataSchema?.saveaudienceTotal === 0 ? (
        <div className="noData">
          <img
            style={{ width: '100px', marginTop: '1rem' }}
            src={nodata}
            alt="nodata"
          />
          <Text on="tertiary-300" UNSAFE_className="noDataText">
            No Data Found
          </Text>
        </div>
      ) : (
        <div className="SavedAudiences__childContainer">
          <div className="save_audiences_container">
            <img
              className="SavedAudiences__leftimage"
              src={saveaudience}
              alt="saveaudience"
            />
          </div>
          <div className="save_audiences_container">
            {savedAudiencesDataSchema.map((value) => (
              <p className="saved_audience_heading">
                {value.saveaudienceTotal}
              </p>
            ))}

            <p className="saved_audience_subheading">Saved Audiences</p>
          </div>
          {/* <div className="">
          <div>Draft</div>
          <div>Active</div>
          <div>Completed</div>
        </div> */}
        </div>
      )}
    </Section>
  )
}
