import { Button, FlexBox, Spacer } from '@eltoro-ui/components'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const ResetSettings: React.FC<{
  showReset: boolean
  onReset: () => void
}> = ({ showReset, onReset, children }) => {
  return (
    <FlexBox alignItems="center">
      {showReset && (
        <Button
          kind="text"
          iconLeft={<FontAwesomeIcon icon={faUndo} />}
          onClick={onReset}
        />
      )}
      <Spacer width="0.5rem" />
      {children}
    </FlexBox>
  )
}
