import React, { useState } from 'react'
import { Button, GridBox, Text } from '@eltoro-ui/components'
import './UploadBannerModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-solid-svg-icons'
import { Uploader } from 'Components/Uploader'
import { createAndAttachCreatives } from 'Requests/Request_Methods/campaignMethods'
import { CreativeType, FileUploadType } from 'types'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import creativePlaceholders from './CreativePlaceholders.json'
import { UploadAreaLayout, UploadTypes } from '../UploadAreaLayout'
import orIcon from '../../../../../../assets/Images/Group 298.png'
import cross from '../../../../../../assets/Images/cross.png'

export const UploadBannerModal = ({
  onClickOutside,
  onSubmit,
  typeModal,
  openCreativeGen,
}: {
  onClickOutside: () => void
  onSubmit: (files: FileUploadType, type?: string) => void
  typeModal?: 'banner'
  openCreativeGen: (type?: 'banner') => void
}) => {
  const { campaignId } = useParams<{ campaignId: string }>()
  const [fileSubmited, setfileSubmited] = useState<any>()
  return (
    <div className="UploadBannerModal">
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <img
          src={cross}
          onClick={onClickOutside}
          alt="cross"
          className="PersonalInfoForm__cross"
          role="presentation"
        />
      </div>
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text
          tag="div"
          on="white"
          size="xxl"
          UNSAFE_style={{ marginTop: '5px' }}
          UNSAFE_className="UploadMailerModal__step-text"
        >
          STEP 1
        </Text>
        <Text
          tag="div"
          on="white"
          size="xxl"
          // UNSAFE_style={{ marginBottom: '20px' }}
          UNSAFE_className="UploadMailerModal__step-subheading"
        >
          Upload Your Banner{' '}
        </Text>
      </GridBox>
      <div className="UploadBannerModal__hl" />
      <GridBox gap="0.5rem">
        <Text
          tag="div"
          on="white"
          size="xxl"
          UNSAFE_style={{ marginTop: '5px' }}
          UNSAFE_className="UploadMailerModal__step-text"
        >
          STEP 2
        </Text>
        <Text
          tag="div"
          on="white"
          size="xxl"
          UNSAFE_style={{ marginBottom: '20px' }}
          UNSAFE_className="UploadMailerModal__step-subheading"
        >
          Consider Using Multiple Banner Sizes{' '}
        </Text>
        <GridBox
          UNSAFE_className="UploadMailerModal__grid_box"
          // gap="0rem"
          // gridTemplateColumns="1fr 1fr"
        >
          {creativePlaceholders.map((creative) => (
            <GridBox
              key={creative.id}
              gridTemplateColumns="min-content auto"
              alignItems="center"
              gap="1rem"
              UNSAFE_className="gridSizing"
            >
              <div
                className="UploadBannerModal__creative-placeholder"
                style={{ height: creative.height, width: creative.width }}
              />
              <Text on="white">
                <b>{creative.actualDimensions}</b>
                {creative.text}
                {!!creative.textSecondary && <br />}
                {!!creative.textSecondary && creative.textSecondary}
              </Text>
            </GridBox>
          ))}
        </GridBox>
      </GridBox>
      <div className="UploadBannerModal__hl" />
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text
          tag="div"
          on="white"
          size="xxl"
          UNSAFE_style={{ marginTop: '5px' }}
          UNSAFE_className="UploadMailerModal__step-text"
        >
          STEP 3
        </Text>
        <Text
          tag="div"
          on="white"
          size="xxl"
          UNSAFE_style={{ marginBottom: '20px' }}
          UNSAFE_className="UploadMailerModal__step-subheading"
        >
          Upload Your Banner Creatives{' '}
        </Text>
      </GridBox>
      {/* <div>{fileSubmited?.acceptedFiles[0]?.name}</div> */}
      <div className="UploadBannerModal__upload_area_layout">
        <Uploader
          type="banner"
          onDropFile={(files: FileUploadType) => setfileSubmited(files)}
        >
          <UploadAreaLayout
            // openCreativeGen={openCreativeGen}
            uploadType={UploadTypes.banner}
          />
        </Uploader>

        <div className="upload-creative-btn">
          <img alt="or" src={orIcon} style={{ marginTop: '-13px' }} />
          <Button
            onClick={(e) => openCreativeGen(typeModal)}
            // kind="text"
            // size="s"
            // UNSAFE_className="btn-access-creative"
            UNSAFE_TEXTSTYLE="btn-span"
            kind="default"
            size="l"
            UNSAFE_style={{
              backgroundColor: '#FFF9ED',
              color: '#ffab03',
              fontSize: '17px',
              border: '1px dashed #FFAB03',
              fontWeight: '400',
            }}
            UNSAFE_className="SellYourListingFirst__modalSave"
            weight="bold"
          >
            Access Creative Library
          </Button>
        </div>
      </div>
      <div className="UploadBannerModal__footer">
        <button
          type="button"
          className="UploadBannerModal__cancel-button"
          onClick={onClickOutside}
        >
          Cancel
        </button>
        <Button
          kind="primary"
          UNSAFE_className="upload_video_confirm"
          onClick={() => {
            if (fileSubmited?.acceptedFiles?.length > 0) {
              onSubmit(fileSubmited, typeModal)
              onClickOutside()
            } else {
              toast.error('Please add a banner creatives')
            }
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
