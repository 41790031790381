import Axios from 'axios'
import { getLocalUser } from 'Helpers'
import toast from 'react-hot-toast'
import { authRoles } from 'routes'
// import { useHistory } from 'react-router-dom'

const axios = Axios.create()
axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}`
axios.defaults.withCredentials = false

// const history = useHistory()

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error?.response?.status === 500) {
      //   showToast("Temporarily Down for Maintenance");
    } else if (error?.response?.status === 402) {
      // localStorage.removeItem('user')
      //   window.location.href = '/subscription/failed'
    } else if (error?.response?.status === 403) {
      //   const userType = atob(localStorage.getItem("user_type"));
      // localStorage.removeItem('user', btoa(JSON.stringify(tempData)))
      // localStorage.getItem("user_type")
      // const getData = getLocalUser()
      // let getData = getLocalUser()
      const user = localStorage.getItem('user')
      let getData = { user_role: '' }
      // const user = localStorage.getItem('user')
      // return user ? JSON.parse(atob(user)) : null
      if (typeof user === 'string') {
        getData = JSON.parse(atob(user))
      }
      const superAdmin = localStorage.getItem('user_view')
      if (
        typeof superAdmin === 'string' &&
        getData?.user_role === 'ultra_admin'
      ) {
        localStorage.removeItem('user_view')
      } else {
        localStorage.removeItem('beewo_token')
        localStorage.removeItem('user')
      }
      // localStorage.removeItem('beewo_token')
      // localStorage.clear()
      // history.replace('/login')
      // clearSession()
      // dispatch(setUser({}))
      //   historypush()
      window.location.reload()
      //   history.push('/signup')
    }
    if (error?.response?.status === 401 && !originalRequest._retry) {
      // const refresh_token = localStorage.getItem('refresh_token')
      // originalRequest = { ...originalRequest, _retry: true }
      //   if (refresh_token) {
      //     return refreshTokenRequest({
      //       refresh_token,
      //       client_secret: process.env.REACT_APP_CLIENT_SECRET,
      //       client_id: process.env.REACT_APP_CLIENT_ID,
      //       grant_type: "refresh_token",
      //     })
      //       .then((resp) => {
      //         localStorage.setItem("access_token", resp.data.access_token);
      //         localStorage.setItem("refresh_token", resp.data.refresh_token);
      //         originalRequest.headers[
      //           "Authorization"
      //         ] = `Bearer ${resp.data.access_token}`;
      //         axios.defaults.headers.common[
      //           "Authorization"
      //         ] = `Bearer ${resp.data.access_token}`;
      //         return axios(originalRequest);
      //       })
      //       .catch((err) => {
      //         localStorage.clear();
      //         window.location.href = "/login";
      //       });
      //   }
    } else if (!error?.response) {
      toast.error('no internet')
    }
    //   if (originalRequest._retryIteration === 2) {
    //     const newError = await checkConnectivity()
    //       .then(() => {
    //         showToast(
    //           "Temporarily down for maintenance.\nPlease try again later."
    //         );
    //         return {
    //           noInternet:
    //             "Temporarily down for maintenance.\nPlease try again later.",
    //         };
    //       })
    //       .catch(() => {
    //         showToast(
    //           "Please check your internet connection or try again later."
    //         );
    //         return {
    //           noInternet:
    //             "Please check your internet connection or try again later.",
    //         };
    //       });
    //     return Promise.reject(newError);
    //   } else {
    //     if (!originalRequest?._retryIteration) {
    //       originalRequest._retryIteration = 0;
    //     }
    //     originalRequest._retryIteration = originalRequest._retryIteration + 1;
    //     return axios(originalRequest);
    //   }
    // }
    //  else {
    // }
    return Promise.reject(error)
  },
)

export default axios
