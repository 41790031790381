import React, { FC, useEffect, useState } from 'react'
import { PageNav, PageRoutes, Modal, Text, Button } from '@eltoro-ui/components'
import { BrowserRouter, useHistory } from 'react-router-dom'
import { UserType, TRootState } from 'types'
import { createChargebeeUser, getChargebeePackages, getMlS } from 'Requests'
import { useSelector, useDispatch } from 'react-redux'
import { setChargebeePackage, setUser } from 'Redux/actions'
import { Toaster, toast } from 'react-hot-toast'
import { Loading, Layout } from 'Components'
import { userRole } from 'Helpers'

import { userDetail } from 'Requests/Request_Methods/authUserMethods'
import { subscriptions as subscriptionsDummyData } from 'Requests/Fixtures/dummyData.json'
import PaymentSuccess from '../../assets/Images/paymentSuccess.png'
import Leftgroup from '../../assets/Images/leftgroup.svg'
import Rightgroup from '../../assets/Images/rightgroup.svg'
import {
  TabWrapper,
  BillingSection,
  SubscriptionSection,
  PersonalInfoSection,
  RealEstateInfoSection,
  Transaction,
  SubscriptionCard,
  Feedback,
  TopHeading,
} from './components'
import './MyProfilePage.scss'

import Pay from './components/ChargebeeComponent/Chargebee'
import NotificationSetting from './components/NotificationSetting/NotificationSetting'

export const MyProfilePage: FC = () => {
  const [userInfo, setUserInfo] = useState<UserType>()
  const [modalOpen, setModalOpen] = useState(false)
  const [paymentModelOpen, setPaymentModelOpen] = useState(false)
  const [confirmPaymentModal, setConfirmPaymentModal] = useState(false)
  const [options, setOptions] = useState('')
  const [profilePages, setprofilePages] = useState<any>([])
  // const [chargebeeSuccessModal, setChargebeeSuccessModal] = useState(true)
  const [overWrite, setOverWrite] = useState(true)
  const { userData } = useSelector((state: TRootState) => state.userReducer)

  const dispatch = useDispatch()
  const getModalData = (e: any) => {}
  useEffect(() => {
    if (options === '') {
      getMlS()
        .then((res) => {
          res.data.listings.map((i: { name: any }) => setOptions(i.name))
        })
        .catch((err) => toast.error(err.data))
    }
  }, [])
  function setupUserProfile() {
    if (userData?.is_compelete !== 1) {
      const schema = {
        id: userData?.id,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        email: userData?.email,
      }
      createChargebeeUser(schema).catch((err) => {
        toast.error(err)
      })
    }
    if (userData?.chargebee_status === 'subscribed') {
      setModalOpen(false)
    }
    if (
      userData?.user_role !== 'super_admin' &&
      userData?.chargebee_status !== 'subscribed'
    ) {
      // setModalOpen(true)
    }
  }
  const classes = {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete',
  }
  const backModal = () => {
    setConfirmPaymentModal(false)
    setModalOpen(true)
    setOverWrite(true)
  }
  const meApicall = (tkn: string | undefined) => {
    if (tkn) {
      userDetail(tkn)
        .then((res) => {
          if (res?.data?.email) {
            const user_data = {
              user_role: userRole(res.data.is_super),
              ...res.data,
            }

            dispatch(setUser(user_data))
            // setModalOpen(false)
            // setModalStep(1)
            // history.push('/dashboard')
          }
        })
        .catch((err: any) => {
          // setIsLoading(false)
        })
    }
  }
  const nextModal = () => {
    setConfirmPaymentModal(false)
    meApicall(localStorage.getItem('beewo_token') || undefined)
    // setChargebeeSuccessModal(true)
    toast.success('Payment Successful')
    setPaymentModelOpen(true)
  }
  const [packages, setPackages] = useState<any>([])
  const { chargebeePackage } = useSelector(
    (state: TRootState) => state.chargebeePackageReducer,
  )

  useEffect(() => {
    if (userData) {
      setupUserProfile()
    }
  }, [userData])

  useEffect(() => {
    // getChargebeePackages().then((res) => {
    //   setPackages(res.data?.subscriptions.reverse() || [])
    // })

    // setPackages([
    //   {
    //     name: 'beewo-subscription-basic',
    //     id: 'beewo-subscription-basic',
    //     metadata: {
    //       price: 49.99,
    //       currency: 'USD',
    //       first_campaign_discount: 20,
    //       bullet_points: [
    //         {
    //           name: 'Prospect Lenses',
    //           description: [
    //             'Talk To Your Sphere',
    //             'Digital Farming',
    //             'Sell Your Listing',
    //             'Beat The Market',
    //           ],
    //         },
    //         {
    //           name: 'Advertising & Performance Reporting',
    //         },
    //         {
    //           name: 'Upload or sync contacts',
    //         },
    //         {
    //           name: 'Notifications about changes in the market and MLS inventory',
    //         },
    //         {
    //           name: 'Notifications about changes in the market and MLS inventory',
    //         },
    //         {
    //           name: 'First Degree Contact Notifications - know when your contact is in market',
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     name: 'beewo-subscription-premium',
    //     id: 'beewo-subscription-premium',
    //     metadata: {
    //       price: 99.99,
    //       currency: 'USD',
    //       first_campaign_discount: 20,
    //       bullet_points: [
    //         {
    //           name: 'Prospect Lenses',
    //           description: [
    //             'Talk To Your Sphere',
    //             'Digital Farming',
    //             'Sell Your Listing',
    //             'Beat The Market',
    //             'Grow Your Sphere',
    //           ],
    //         },
    //         {
    //           name: 'Advertising & Performance Reporting',
    //         },
    //         {
    //           name: 'Upload or sync contacts',
    //         },
    //         {
    //           name: 'Notifications about changes in the market and MLS inventory',
    //         },
    //         {
    //           name: 'Notifications about changes in the market and MLS inventory',
    //         },
    //         {
    //           name: 'First Degree Contact Notifications - know when your contact is in market',
    //         },
    //         {
    //           name: 'Advanced Reporting',
    //         },
    //         {
    //           name: 'contact enrichment',
    //         },
    //       ],
    //     },
    //   },
    // ])
    setUserInfo({
      _id: '',
      first_name: '',
      last_name: '',
      email: '',
      working_area: '',
      avatar: '',
      experience: '',
      work_status: '',
      work_type: '',
    })
  }, [])

  const history = useHistory()
  const callReset = () => {
    history.push('/profile/password-reset')
  }

  const getSelectedCard = () =>
    chargebeePackage?.subscription_id === 'beewo-subscription-basic' ? (
      <SubscriptionCard data={[packages[0]]} button_visibility_hidden />
    ) : (
      <SubscriptionCard data={[packages[1]]} button_visibility_hidden />
    )
  useEffect(() => {
    setprofilePages([
      {
        path: '/profile',
        title: 'General Info',
        exactPath: true,
        component: () => (
          <TabWrapper>
            {userInfo && (
              <>
                {!userData ? (
                  <Loading />
                ) : (
                  <>
                    <PersonalInfoSection
                      mslRegion={options}
                      user={userData}
                      callReset={callReset}
                    />
                    <RealEstateInfoSection user={userData} />
                  </>
                )}

                {modalOpen && overWrite && (
                  <Modal>
                    <div className="OnboardingModal__welcome ChargebeeBg onBoardBgIcon">
                      <div className="chargebeePackageModalHeader">
                        <p style={{ color: '#2B1F0A' }}>Coming Soon</p>
                      </div>

                      <div className="chargebeePackageModalText">
                        <p
                          style={{
                            color: '#6D6D6D',
                            fontWeight: '400',
                            fontSize: '20px',
                          }}
                        >
                          To access the other modules in the platform, please
                          activate a Beewo subscription.
                        </p>
                      </div>
                      {!packages.length ? (
                        <Loading />
                      ) : (
                        <SubscriptionCard
                          data={packages}
                          onClick={(pkg: any) => {
                            dispatch(setChargebeePackage(pkg))
                            setModalOpen(false)
                            setOverWrite(false)
                            setConfirmPaymentModal(true)
                          }}
                          userData={userData}
                        />
                      )}
                    </div>
                  </Modal>
                )}
                {/* Confirm Payment */}
                {confirmPaymentModal && (
                  <Modal>
                    <div
                      style={{ width: '918px' }}
                      className="OnboardingModal__welcome ChargebeeBgPayment onBoardBgIconPayment"
                    >
                      <div className="">
                        <div className="chargebeePackageModalHeader">
                          <p style={{ color: '#2B1F0A' }}>Confirm Payment</p>
                        </div>
                      </div>
                      <div className="chargebeePackageModalText">
                        <p className="chargebeePackageModalText">
                          Please enter your card information below
                        </p>
                      </div>

                      <div className="chargebeePackageModalBoxes">
                        {getSelectedCard()}
                        <div className="chargebeePay">
                          {/* Chargebee components */}
                          <Pay
                            /* eslint-disable-line */
                            // @ts-ignore

                            back={backModal}
                            close={nextModal}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
                {paymentModelOpen && (
                  <Modal offClick={() => setPaymentModelOpen(false)}>
                    <div className="OnboardingModal-welcome">
                      <img
                        className="Leftgroup"
                        src={Leftgroup}
                        alt="Leftgroup"
                      />
                      <div className="OnboardingModal-welcomeHeader">
                        <div className="beewo-header-congrats">
                          <img src={PaymentSuccess} alt="congrats" />
                        </div>
                        <div className="beewo-header-welcome">
                          <p>Payment Successful</p>
                        </div>
                        <div className="description">
                          <p>You’re payment was successful</p>
                        </div>
                      </div>
                      <div className="btn-container">
                        <Button
                          UNSAFE_style={{
                            borderRadius: '10px',
                            padding: '26px 20px',
                            fontFamily: 'Open Sans',
                            fontSize: '20px',
                            marginTop: '-20px',
                            fontWeight: '700',
                            lineHeight: '27px',
                            color: '#2B1F0A',
                          }}
                          kind="primary"
                          size="s"
                          width="30%"
                          onClick={() => {
                            setPaymentModelOpen(false)
                            history.push('/dashboard')
                          }}
                        >
                          Dashboard
                        </Button>
                      </div>
                      <img
                        className="Rightgroup"
                        src={Rightgroup}
                        alt="Rightgroup"
                      />
                    </div>
                  </Modal>
                )}
                {/* {chargebeeSuccessModal && (
                      <Modal
                        offClick={() => {
                          // @ts-ignore
                          //   this.props.close()
                        }}
                      >
                        <div className="OnboardingModal__welcome onBoardBgIcon">
                          <div className="OnboardingModal__welcomeHeader">
                            <div style={{ textAlign: 'center', marginBottom: '5px' }}>
                              <Text
                                on="white"
                                weight="bold"
                                size="xxxl"
                                textAlign="center"
                              >
                                Payment Successful
                              </Text>
                            </div>
                            <div
                              style={{
                                maxWidth: '280px',
                                margin: '0 30px',
                                textAlign: 'center',
                              }}
                            >
                              <Text
                                on="grey-500"
                                weight="normal"
                                size="m"
                                textAlign="center"
                              >
                                You’re payment was successful
                              </Text>
                            </div>
                          </div>
                          <div style={{ margin: '0 auto' }}>
                            <Button
                              kind="primary"
                              size="l"
                              weight="bold"
                              // @ts-ignore
                              onClick={() => {
                                toast.success(
                                  'Payment Successful, You’re payment was successful',
                                )
      
                                // setChargebeeSuccessModal(false)
                                // setTimeout(() => {
                                //   history.push('/profile/billing')
                                // }, 500)
                                // history.push('/dashboard')
                              }}
                            >
                              Back to Billing
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )} */}
              </>
            )}
          </TabWrapper>
        ),
      },
      {
        path: '/profile/billing',
        title: 'Billing',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <SubscriptionSection />
            <Transaction />
            <BillingSection />
          </TabWrapper>
        ),
      },

      {
        path: '/profile/notifications',
        title: 'Feedback',
        exactPath: false,
        component: () => (
          <TabWrapper>
            {/* <TopHeading title="New Feedback" /> */}
            <Feedback />
          </TabWrapper>
        ),
      },
      {
        path: '/profile/notificationSettings',
        title: 'Notification Settings',
        exactPath: false,
        component: () => (
          <TabWrapper>
            {/* <TopHeading title="New Feedback" /> */}
            <NotificationSetting />
          </TabWrapper>
        ),
      },
    ])
  }, [userData])

  return (
    <Layout>
      <div className="MyProfilePage">
        <BrowserRouter>
          <Toaster position="top-center" />

          <nav className="MyProfilePage__nav">
            <h1 className="MyProfilePage__header">Account Settings</h1>
            <PageNav pages={profilePages} />
          </nav>

          <main className="MyProfilePage__main">
            <PageRoutes pages={profilePages} />
          </main>
        </BrowserRouter>
      </div>
    </Layout>
  )
}
