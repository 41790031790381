import { Button, GridBox, Spacer, Text } from '@eltoro-ui/components'
import {
  faEye,
  faEyeSlash,
  faLock,
  faArrowsAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  allBlocksOfKindSelector,
  artboardLabelSelector,
  lockedBlockAtom,
  selectedKindAtom,
  visibleBlockAtom,
} from '../../../../../State'

export const SelectionTable: React.FC<{}> = () => {
  const selectedKind = useRecoilValue(selectedKindAtom)
  const allBlocksOfKind = useRecoilValue(allBlocksOfKindSelector)
  return (
    <div className="SelectionTable">
      <Text on="white" size="l" weight="bold">
        Block attributes
      </Text>
      <Spacer />
      <GridBox
        gridTemplateColumns="min-content min-content 1fr"
        alignItems="center"
        gap="0.25rem"
      >
        {selectedKind &&
          allBlocksOfKind?.map((matchingBlock) => (
            <SelectionRow
              key={matchingBlock.id}
              id={matchingBlock.id}
              artboardName={matchingBlock.artboardName}
            />
          ))}
      </GridBox>
    </div>
  )
}

const SelectionRow: React.FC<{
  id: string
  artboardName: string
}> = ({ id, artboardName }) => {
  const [visible, setVisible] = useRecoilState(visibleBlockAtom(id))
  const [locked, setLocked] = useRecoilState(lockedBlockAtom(id))
  const artboardLabel = useRecoilValue(artboardLabelSelector(artboardName))
  return (
    <>
      <Button
        kind="text"
        iconLeft={<FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />}
        onClick={() => setVisible(!visible)}
      />
      <Button
        kind="text"
        iconLeft={<FontAwesomeIcon icon={locked ? faLock : faArrowsAlt} />}
        onClick={() => setLocked(!locked)}
        disabled={!visible}
      />
      <Text on="white">{artboardLabel}</Text>
    </>
  )
}
