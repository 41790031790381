import { FlexBox, Text } from '@eltoro-ui/components'
import React from 'react'

export const HorizontalLabel: React.FC<{ label: string }> = ({
  label,
  children,
}) => {
  return (
    <FlexBox alignItems="center" justifyContent="space-between">
      <Text on="white" weight="bold" kind="subdued">
        {label}
      </Text>
      {children}
    </FlexBox>
  )
}
