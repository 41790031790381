/* eslint-disable */
import { faTable } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef } from 'react'
import './LableInputField.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type TextInputType = {
  title?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  type?: string
  isError?: Boolean
  icon?: IconProp | JSX.Element
  placeHolder?: string | undefined
  value?: string | number | readonly string[] | undefined
  wdt?: string | number | undefined
  border?: string | number | undefined
  fontSize?: string | number | undefined
  color?: string | undefined
  padding?: string | number | undefined
  EyePassIcon?: JSX.Element
  requireTage?: string
  UNSAFE_ContainerStyle?: any
  iconLeft?: any
  iconRight?: any
  disabled?: boolean
  style?: any
  placeholder: string
  lableStyle?: any
  imgIconStyle?: any
  UNSAFE_ClassName?: string
  onKeyUp?: any
  defaultValue?: string
  onkeypress?: any
  id?: string
  autoComplete?: string | undefined
  maxlength?: number
}

export const LableInputField: React.FC<TextInputType> = ({
  title,
  requireTage,
  onChange,
  placeholder,
  EyePassIcon,
  defaultValue,
  disabled,
  value,
  onKeyUp,
  maxlength,
  isError,
  UNSAFE_ContainerStyle,
  iconLeft,
  iconRight,
  style,
  onkeypress,
  lableStyle,
  type = 'text',
  imgIconStyle,
  icon,
  wdt,
  border,
  fontSize,
  color,
  padding,
  UNSAFE_ClassName,
  autoComplete,
  id,
}) => {
  const [errir, seterrir] = useState('')
  return (
    <>
      <div className={`container`} style={UNSAFE_ContainerStyle}>
        <div
          className={`
          ${
            isError
              ? `did-floating-label-content did-error-input`
              : `did-floating-label-content `
          }
          ${disabled ? 'input_disabled' : ''}
        `}
          style={{ position: 'relative' }}
        >
          <div className="input_icon_start">{iconLeft}</div>
          {/* <div className="input_icon_start">{icon}</div> */}
          <div className="input_icon_end">{iconRight}</div>
          <input
            disabled={disabled}
            maxLength={maxlength}
            onKeyUp={onKeyUp}
            onKeyPress={onkeypress}
            id={id}
            value={value}
            style={style}
            className={`did-floating-input ${
              !iconLeft && 'did-floating-input__padding'
            } ${UNSAFE_ClassName ? UNSAFE_ClassName : ''}`}
            // className='did-floating-input'
            type={type}
            placeholder=" "
            autoComplete={autoComplete}
            // placeholder={placeholder}
            onFocus={(e) => (e.target.placeholder = placeholder)}
            onBlur={(e) => (e.target.placeholder = ' ')}
            onChange={onChange}
            defaultValue={defaultValue}
          />
          <label
            style={lableStyle}
            htmlFor="sale"
            className={`did-floating-label ${
              iconLeft ? '' : 'did-floating-label__padding'
            }`}
          >
            {title}
            <span style={{ color: '#BD0000' }}>{requireTage}</span>
          </label>
          <div className="inputRightIcon">{EyePassIcon}</div>
        </div>
      </div>
    </>
  )
}
