import { Button } from '@eltoro-ui/components'
import {
  faExpand,
  faSearchMinus,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stage } from 'konva/types/Stage'
import React from 'react'
import { basicZoom } from '../../../Helpers'
import { DimensionsType, PositionType } from '../../../Typings'
import './ZoomSettings.scss'

export const ZoomSettings: React.FC<{
  windowDims: DimensionsType
  stage: Stage
  zoom: PositionType
  onChange: (newZoom: PositionType) => void
  onPositionReset: () => void
}> = ({ windowDims, stage, zoom, onChange, onPositionReset }) => {
  const minZoom = zoom.y <= 1

  const handleZoom = (zoomIn: boolean) => {
    const scaleBy = 1.1
    // point to center of window
    const pointTo = {
      x: windowDims.width / 2,
      y: windowDims.height / 2,
    }
    onChange(basicZoom(stage, scaleBy, pointTo, zoomIn))
  }

  const handleZoomToFit = () => {
    const scaleBy = stage.getAbsoluteScale().x
    // point to center of window
    const pointTo = {
      x: windowDims.width / 2,
      y: windowDims.height / 2,
    }
    basicZoom(stage, scaleBy, pointTo, false)
    stage.position({ x: 0, y: 0 })
    onPositionReset()
  }

  return (
    <>
      <Button
        kind="text"
        iconLeft={<FontAwesomeIcon icon={faSearchMinus} />}
        disabled={minZoom}
        onClick={() => handleZoom(false)}
      />
      <Button
        kind="text"
        iconLeft={<FontAwesomeIcon icon={faSearchPlus} />}
        onClick={() => handleZoom(true)}
      />
      <Button
        kind="text"
        iconLeft={<FontAwesomeIcon icon={faExpand} />}
        onClick={handleZoomToFit}
      />
    </>
  )
}
