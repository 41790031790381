import React from 'react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridBox } from '@eltoro-ui/components'
import { APIFeatureType } from 'types'
import { getIconBuzzColor } from 'Helpers'
import './ListingLabel.scss'

export const ListingLabel = ({
  listing,
  isSelected,
  onClick,
}: {
  listing: APIFeatureType
  isSelected: boolean
  onClick?: (clickedListing: APIFeatureType) => void
}) => {
  return (
    <button
      type="button"
      className={`ListingLabel${isSelected ? ' ListingLabel--selected' : ''}`}
      onClick={() => listing && onClick && onClick(listing)}
    >
      <GridBox
        gridTemplateColumns="min-content auto min-content"
        gap="0.75rem"
        alignItems="center"
      >
        <div className="ListingLabel__icon-circle">
          {isSelected && <FontAwesomeIcon icon={faCheck} />}
        </div>
        <div className="ListingLabel__labels">
          <span className="ListingLabel__buzz-marker">
            {' '}
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              color={getIconBuzzColor(listing)}
            />
          </span>
          <div className="ListingLabel__label" title={listing.street}>
            {listing.street}
          </div>
          <div className="ListingLabel__sub-label">{`${listing.city}, ${listing.zip}`}</div>
        </div>
        {/* <div className="ListingLabel__buzz-marker">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            color={getIconBuzzColor(listing)}
          />
        </div> */}
      </GridBox>
    </button>
  )
}
