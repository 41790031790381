import {
  AudienceType,
  CampaignType,
  CreativeType,
  ResponseType,
  TCampaigns,
  TCampaignStats,
  TCampaignsUserStats,
  TCampaignTargets,
  TCreativeTypesUpload,
  TDeleteCampaigns,
  TProspectsUpload,
  TSaveCampaign,
  TTargetAudience,
} from 'types'
import requests from '../http'
import { createCreative } from './creativeMethods'

export const createCampaign = async (body: {
  name: string
}): Promise<ResponseType<TSaveCampaign>> =>
  requests.post(`/campaigns/save`, body)

export const getCampaigns = async (
  page?: number | null,
  size?: number | null,
  search?: string | null,
  sort_key?: string | null,
  order?: string | null,
  draft = true,
): Promise<ResponseType<TCampaigns>> => {
  const req = search
    ? `/campaigns?page=${page}&size=${size}&search=${search}&${
        sort_key && `sort_key=${sort_key}&order=${order}`
      }&draft=${draft}`
    : `/campaigns?page=${page}&size=${size}&${
        sort_key && `sort_key=${sort_key}&order=${order}`
      }&draft=${draft}`
  return requests.get(req)
}
export const getCampaignAudience = async (
  id?: number | undefined,
  page?: number | null,
  size?: number | null,
  showContact?: Boolean,
): Promise<ResponseType<TTargetAudience>> => {
  return requests.get(
    `/campaigns/all/target_audience?campaign_id=${id}&page=${page}&size=${size}&show_contact=${showContact}`,
  )
}
export const uploadCSV = async (
  body: Object | any,
): Promise<ResponseType<TProspectsUpload>> => {
  return requests.post(`/campaigns/prospects/upload`, body)
}

export const saveCampaignTarget = async (
  body: Object | any,
  campaign_id?: string | number | undefined,
): Promise<ResponseType<TCampaignTargets>> => {
  return requests.post(`/campaigns/targets?campaign_id=${campaign_id}`, body)
}

export const deleteCampaign = async (
  body: Object,
): Promise<ResponseType<TDeleteCampaigns>> => {
  return requests.post(`/campaigns/delete-campaigns`, body)
}
export const saveCampaignCreative = async (
  body: Object | any,
  campaign_id?: string | number | undefined,
): Promise<ResponseType<TCreativeTypesUpload>> => {
  //  const jsonData = Object.fromEntries(formData.entries())
  // const jsonData = Object.fromEntries(body.entries())

  // formData.forEach((valued, key) => {
  // })
  return requests.post(`/campaigns/creative_types/upload`, body)
}

export const getCampaignById = async (
  campaign_id: string,
): Promise<ResponseType<CampaignType>> => {
  return requests.get(`/campaigns/${campaign_id}`)
}

export const getCampaignDetailsById = async (
  campaign_id: string,
): Promise<ResponseType<CampaignType>> => {
  return requests.get(`/campaigns/details/${campaign_id}`)
}

export const getSavedCampaignAudienceById = async (
  campaign_id: string,
): Promise<ResponseType<CampaignType>> => {
  return requests.get(`/campaigns/get_saved_targets?campaign_id=${campaign_id}`)
}

export const updateCampaign = async (
  campaign_id: string,
  body: Partial<CampaignType>,
): Promise<ResponseType<CampaignType>> => {
  return requests.patch(`/campaign/${campaign_id}`, body)
}
export const postCampaignBudget = async (
  campaign_id: string,
  body: Partial<CampaignType>,
): Promise<ResponseType<CampaignType>> => {
  return requests.post(
    `/campaigns/update_campaign_budget?campaign_id=${campaign_id}`,
    body,
  )
}
export const attachAudience = async (
  campaign_id: string,
  body: AudienceType[],
): Promise<ResponseType<CampaignType>> => {
  return requests.patch(`/campaign/attach-audience/${campaign_id}`, body)
}

export const detachAudience = async (
  campaign_id: string,
  body: AudienceType[],
): Promise<ResponseType<CampaignType>> => {
  return requests.patch(`/campaign/detach-audience/${campaign_id}`, body)
}

export const attachCreative = async (
  campaign_id: string,
  body: CreativeType[],
): Promise<ResponseType<CampaignType>> => {
  return requests.patch(`/campaign/attach-creative/${campaign_id}`, body)
}

export const detachCreative = async (
  campaign_id: string,
  body: CreativeType[],
): Promise<ResponseType<CampaignType>> => {
  return requests.patch(`/campaign/detach-creative/${campaign_id}`, body)
}

export const createAndAttachCreatives = async (
  body: Partial<CreativeType>[],
  campaign_id: string,
) => {
  body.map((creative) =>
    createCreative(creative).then((res) => {
      if (res.data) attachCreative(campaign_id, [res.data])
    }),
  )
}

export const getCompaignGraphData = async (): Promise<
  ResponseType<TCampaignsUserStats>
> => requests.get('/campaigns/user-stats')

export const getCompaignPerformance = async (
  id: number,
): Promise<ResponseType<TCampaignStats>> =>
  requests.get(`/campaigns/campaign-stats/${id}`)
