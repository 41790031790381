import { userRole } from 'Helpers'

export const userReducer: undefined | any = (
  state = { userData: null, notificationSetting: false },
  action: any,
) => {
  let temp
  switch (action.type) {
    case 'SET_USER':
      temp = userRole(action.payload.is_super)
      localStorage.setItem('user_type', btoa(temp))
      localStorage.setItem('user', btoa(JSON.stringify(action.payload)))

      return { ...state, userData: action.payload }
    case 'SET_NOTIFICATION_CALLED':
      return { notificationSetting: true }
    default:
      return { ...state }
  }
}

export const userProfileComplete: undefined | any = (
  state = { userComplete: null },
  action: any,
) => {
  switch (action.type) {
    case 'SET_USER_COMPLETE':
      return { userComplete: action.payload }

    default:
      return { ...state }
  }
}
