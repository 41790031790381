import React from 'react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridBox, Text } from '@eltoro-ui/components'
import './CheckBoxLable.scss'

export const CheckBoxLable = ({
  location,
  onClick,
}: {
  location: any
  onClick: () => void
}) => {
  return (
    <button
      type="button"
      className="ad_to_all_ListingLabel ListingLabel--selected"
      onClick={onClick}
    >
      <GridBox
        gridTemplateColumns="min-content auto min-content"
        gap="0.75rem"
        alignItems="center"
      >
        <div className="ListingLabel__icon-circle">
          <FontAwesomeIcon icon={faCheck} />
        </div>

        <div className="ListingLabel__labels">
          <Text
            on="grey-050"
            kind="subdued"
            UNSAFE_className="AreaSearchLens__search_title"
          >
            {location.display_value.split(',')[1]}
          </Text>
        </div>
      </GridBox>
    </button>
  )
}
