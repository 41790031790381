import React, { useState, useEffect } from 'react'
import './AdvertiseToAllInMarket.scss'
import {
  MapMarker,
  SaveAudienceWizard,
  AudienceResultsCard,
  LocationLabels,
} from 'Pages/ProspectActivity/components'

import {
  Button,
  MaxHeightContainer,
  Spacer,
  Text,
  Table,
} from '@eltoro-ui/components'
import {
  MapSearch,
  MapToolTip,
  MapSearchModal,
  Realtor,
  MultiColorPolygonMap,
  BuzzScoreFilters,
  BuzzScore,
} from 'Components'
import {
  getMyZipCode,
  getRealtorActivity,
  getLocationOnSearch,
  postAdvertiseToAll,
} from 'Requests'
import toast from 'react-hot-toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons'

import { useLocations } from 'Hooks'

import { TRealtorType, TRealtorResult, TLocation } from 'types'

import { getIconBuzzColor } from 'Helpers'
import { setSpinner } from 'Redux/actions'
import { useDispatch } from 'react-redux'
import { buzzFilters } from '../../configs'
import {
  LensSidebar,
  LensSidebarHeader,
  LensSidebarFooter,
  LensFilterContainer,
} from '../components'
// import { ListingLabel } from '../../components/ListingLabel'
import { CheckBoxLable } from './components'
import buzzScoreImg from '../../../../assets/Images/buzz_score.png'

export const AdvertiseToAllInMarket = () => {
  const {
    locations,
    addLocation,
    removeLocation,
    hasLocations,
    clearLocation,
  } = useLocations()
  const dispatch = useDispatch()

  const [realtorData, setRealtorData] = useState<TRealtorResult | any>([])
  const [realtorFilters, setRealtorFilters] = useState({
    page: 1,
    sort: 'active_listings',
    order: 'desc',
  })

  const [activeToggleFeature, setActiveToggleFeature] =
    useState<string>('buzz_filter')

  const onPageChange = (page: number) => {
    setRealtorFilters({ ...realtorFilters, page })
  }

  const onSort = () => {
    const order = realtorFilters.order === 'desc' ? 'asc' : 'desc'
    setRealtorFilters({ ...realtorFilters, order, page: 1 })
  }

  useEffect(() => {
    if (locations.length) {
      const bodySchema = {
        key: 'zip',
        value: '',
        size: 5,
        ...realtorFilters,
      }
      const body: any = []

      locations.map((location: TLocation) => {
        return body.push({
          ...bodySchema,
          value: location.display_value.split(',')[1],
        })
      })

      getRealtorActivity({ search: body })
        .then((res) => {
          if (res?.detail) {
            toast.success(res?.detail[0]?.msg)
          }
          setRealtorData(res.data?.result)
        })
        .catch((err) => toast.error(err))
    }
  }, [locations, realtorFilters])

  useEffect(() => {
    getMyZipCode()
      .then((res: any) => {
        if (res?.detail) {
          toast.success(res?.detail[0]?.msg)
        }
        getLocationOnSearch(res?.data?.city, '')
          .then((loc) => {
            addLocation(loc?.result?.[0], setcallPolygonFilters)
          })
          .catch((err) => toast.error(err))
      })
      .catch((err) => toast.error(err))
  }, [])

  const [removeLocationBool, setremoveLocationBool] = useState(false)
  const [activeBuzzFilters, setActiveBuzzFilters] = useState(buzzFilters)
  const [isActiveAnother, setisActiveAnother] = useState(false)

  const [buzzExcluded, setbuzzExcluded] = useState<string[]>([])

  const [listingArea, setlistingArea] = useState<any[]>([])
  const [buttonClicked, setbuttonClicked] = useState(false)

  const [buttonDisabled, setbuttonDisabled] = useState(false)
  const [selectedListings, setSelectedListings] = useState<number[] | number>(
    [],
  )
  const [callPolygonFilters, setcallPolygonFilters] = useState<boolean>(false)
  const [dataObj, setdataObj] = useState<any>()

  // useEffect(() => {
  // }, [dataObj, listingArea])

  const [firstClicked, setfirstClicked] = useState(false)
  const [loader, setloader] = useState(false)
  const [refreshState, setrefreshState] = useState(false)
  const [buzzAvg, setbuzzAvg] = useState<number[]>([])

  useEffect(() => {
    apiCall()
  }, [buzzExcluded.length])

  useEffect(() => {
    if (removeLocationBool) {
      setcallPolygonFilters(true)
      apiCall()
    }
  }, [locations])

  useEffect(() => {
    if (firstClicked) {
      apiCall()
    }
  }, [firstClicked])

  const [first, setfirst] = useState(false)

  const resetStates = () => {
    clearLocation()
    setbuzzAvg([])
    setlistingArea([])
    setSelectedListings([])
  }

  const apiCall = () => {
    if (first || firstClicked) {
      setfirst(true)
      setbuttonDisabled(true)
      setloader(true)
      setdataObj({
        exclude: buzzExcluded,
        search: locations,
      })

      const search = locations.map((location: TLocation) => {
        return {
          key: 'city',
          value: location.display_value.split(',')[0],
        }
      })

      postAdvertiseToAll({ search })
        .then((res) => {
          const lisitingsData = []
          const buzzData = []
          const listingCounts = { count: 0 }
          if (res.data?.result.length) {
            for (let i = 0; i < res?.data?.result?.length; i += 1) {
              // dispatch(setSpinner(true))
              listingCounts.count += res?.data?.result[i].listings.length
              lisitingsData.push(...res?.data?.result[i].listings)
              buzzData.push(res?.data?.result[i].avg)
              if (res.data.result.length - 1 === i) {
                setbuzzAvg(buzzData)
                setlistingArea(lisitingsData)
                setrefreshState(true)
                setSelectedListings(listingCounts.count)
                setcallPolygonFilters(true)
              }
            }
          } else {
            setcallPolygonFilters(false)
            dispatch(setSpinner(false))

            setbuzzAvg([])
            setrefreshState(true)
            setlistingArea([])
          }

          setfirstClicked(false)
        })
        .catch((err) => {
          setbuttonDisabled(false)
        })
        .finally(() => {
          setbuttonClicked(false)
          setloader(false)
          setremoveLocationBool(false)
        })
    }
  }
  const totalProspects = listingArea?.reduce(
    (count, currFeature) => count + currFeature.unique_visitor_count,
    0,
  )

  const AllBuzz: string[] = []

  useEffect(() => {
    activeBuzzFilters.forEach((item) => AllBuzz.push(item.label))
    const Buzz = ['High buzz', 'Warm buzz', 'Medium buzz', 'Low buzz']
    const data = Buzz.filter(function (v: string) {
      return !AllBuzz.includes(v)
    })
    setbuzzExcluded(data)
  }, [activeBuzzFilters])

  const [toggle, setToggle] = useState(true)

  return (
    <div className="AreaSearchLens AdvertiesToAllInMarket separate_class">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              toggle
              heading="Advertise to all in market"
              subHeading="Advertise to potential buyers that have been seen in market"
            />
          }
        >
          {isActiveAnother && (
            <MapSearchModal
              onSelect={(suggestion) => {
                const location = {
                  key: 'city',
                  value: suggestion.display_value.split(',')[0],
                  display_value: suggestion.display_value,
                }
                addLocation(location, setcallPolygonFilters)
              }}
              onClose={() => setisActiveAnother(false)}
            />
          )}
          <div className="AreaSearchLens__sidebar-content">
            {locations.map((location: TLocation) => (
              <CheckBoxLable
                key={location.value}
                location={location}
                onClick={() =>
                  removeLocation(location.value, setremoveLocationBool)
                }
              />
            ))}
            <Text
              on="grey-050"
              kind="subdued"
              UNSAFE_className="AreaSearchLens__search_title"
            >
              Optional: Add more ZIP codes
            </Text>

            {locations.length < 1 && (
              <>
                <MapSearch
                  onSelect={(loc) => {
                    setbuttonDisabled(false)
                    addLocation(loc, setcallPolygonFilters)
                    setrefreshState(true)
                  }}
                />
                <Spacer height="1rem" />
              </>
            )}

            {locations.length > 0 && locations.length <= 5 && (
              <>
                <Button
                  UNSAFE_style={{
                    color: '#007B94',
                    border: '2px solid #007B94',
                    fontWeight: 'bold',
                    fontFamily: 'Helvetica',
                    fontSize: 'medium',
                  }}
                  UNSAFE_className="MyListingsLens__FooterBtn"
                  kind="default"
                  size="l"
                  width="100%"
                  onClick={() => {
                    setisActiveAnother(true)
                    setbuttonDisabled(false)
                  }}
                >
                  Add Another Listing
                </Button>
                <Spacer height="1rem" />
              </>
            )}
            <div className="LensSidebarHeader__divider" />

            <Button
              UNSAFE_style={{
                backgroundColor: '#007b94',
                color: '#F8F8F8',
                border: '2px solid #007B94',
                fontWeight: 'bold',
                fontFamily: 'Helvetica',
                fontSize: 'medium',
              }}
              UNSAFE_className="MyListingsLens__FooterBtn"
              kind="default"
              size="l"
              width="100%"
              onClick={() => {
                setbuttonClicked(true)
                setfirstClicked(true)
              }}
              disabled={
                !locations.length || locations.length === 5 || buttonDisabled
              }
            >
              Submit
            </Button>
          </div>
        </MaxHeightContainer>
      </LensSidebar>

      {locations.length === 0 ||
        (!loader && buttonDisabled && totalProspects && (
          <div className="MyListingsLens__audienceCenter results_prospects">
            <div className="MyListingsLens__border_radius">
              <LensSidebarFooter>
                <AudienceResultsCard>
                  <div className="MyListingsLens__score">
                    <div className="MyListingsLens__scoreLeft">
                      <p>{totalProspects.toLocaleString()}</p>
                    </div>
                    <div className="MyListingsLens__scoreRight">
                      <b>Prospects</b>
                      <br />
                      have visited <b>{locations.length}</b>
                      {/* <b> listings</b> */}
                      <b>{locations.length > 1 ? ' locations' : ' location'}</b>
                    </div>
                  </div>
                  <Spacer />
                  <SaveAudienceWizard
                    type="area search"
                    dataObj={dataObj}
                    onSaveAndClose={(audienceDetails) => {
                      // browserLog.info(audienceDetails)
                      resetStates()
                    }}
                    totalProspects={totalProspects}
                    listing={selectedListings}
                  />
                </AudienceResultsCard>
              </LensSidebarFooter>
            </div>
          </div>
        ))}

      {!!locations.length && !!realtorData?.length && !!totalProspects && (
        <div className={`${toggle ? '' : 'MyListingsLens__hide'}`}>
          <>
            <div
              className="ExpandYourMarketing__rightTop"
              style={{ display: 'flex' }}
            >
              <Button
                UNSAFE_className="ExpandYourMarketing__rightTopButton"
                UNSAFE_style={{
                  background:
                    activeToggleFeature === 'buzz_filter' ? '#FFB708' : '',
                  color: activeToggleFeature === 'buzz_filter' ? 'white' : '',
                }}
                onClick={() => {
                  setActiveToggleFeature('buzz_filter')
                }}
              >
                Buzz Filter
              </Button>
              <Button
                UNSAFE_className="ExpandYourMarketing__rightTopButton"
                UNSAFE_style={{
                  background:
                    activeToggleFeature === 'realtor_filter' ? '#FFB708' : '',
                  color:
                    activeToggleFeature === 'realtor_filter' ? 'white' : '',
                }}
                onClick={() => {
                  setActiveToggleFeature('realtor_filter')
                }}
              >
                Realtor
              </Button>
            </div>
            <div className="MyListingsLens__filter">
              {activeToggleFeature === 'realtor_filter' ? (
                <div className="realtor_container">
                  {realtorData.map((realtor: TRealtorType, index: number) => (
                    <Realtor
                      key={index}
                      subTitle="Top brokerages"
                      title={realtor.value}
                      onPageChange={onPageChange}
                      currentPage={realtor.current_page}
                      totalPages={realtor.total_pages}
                      isDataFound={!!realtor?.data?.length}
                    >
                      {!realtor?.data.length ? (
                        <div style={{ textAlign: 'center' }}>
                          <Text on="grey-050" kind="subdued">
                            No Data Found of {realtor.value}
                          </Text>
                        </div>
                      ) : (
                        <Table
                          rows={realtor?.data}
                          columns={[
                            {
                              path: 'rank',
                              label: 'RANK',
                              alignment: 'center',
                              RowCell: (listing) => (
                                <b className="table_cell_content">
                                  {index + 1}
                                </b>
                              ),
                            },
                            {
                              path: 'mls_id',
                              label: 'BROKERAGE',
                              alignment: 'center',
                              RowCell: (listing) => <b>{listing.mls_id}</b>,
                            },
                            {
                              path: 'active_listings',
                              label: 'ACTIVE LISTINGS',
                              alignment: 'center',
                              onSort,
                              RowCell: (listing) => (
                                <b>{listing.active_listings}</b>
                              ),
                            },
                          ]}
                        />
                      )}
                    </Realtor>
                  ))}
                </div>
              ) : (
                <LensFilterContainer>
                  <BuzzScoreFilters
                    filters={buzzFilters}
                    activeFilters={activeBuzzFilters}
                    onChange={setActiveBuzzFilters}
                  />
                  <div className="buzzScoreImg__container">
                    {/* <BuzzScore /> */}

                    {/* <img
                      src={buzzScoreImg}
                      className="buzzScoreImg__containerInside"
                      alt="..."
                    /> */}
                  </div>
                </LensFilterContainer>
              )}
            </div>
          </>
        </div>
      )}

      {/* buzz score toggle */}
      {!!locations.length && !!realtorData?.length && !!totalProspects && (
        <div
          style={{
            position: 'absolute',
            right: '50px',
            zIndex: 999,
            bottom: '44px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ fontSize: '10px', marginRight: '5px' }}>Show Filters</p>
          <FontAwesomeIcon
            style={{ marginRight: '1.5rem', fontSize: '32px' }}
            size="1x"
            color={toggle ? '#FFB708' : '#FF0000'}
            fill="#FF0000"
            icon={toggle ? faToggleOn : faToggleOff}
            onClick={() => setToggle(!toggle)}
          />
        </div>
      )}
      {/* buzz score toggle */}

      <div className="AreaSearchLens__map">
        <MultiColorPolygonMap
          features={listingArea}
          buzzAvg={buzzAvg}
          firstClicked={first}
          locationsArea={locations}
          callPolygonFilters={callPolygonFilters}
          setcallPolygonFilters={setcallPolygonFilters}
          locations={[]}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  )
}
