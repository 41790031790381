/* eslint-disable */

import React, { useState, useEffect, useMemo } from 'react'
import { Button, Modal, Spacer, Text } from '@eltoro-ui/components'
import { Section, Loading } from 'Components'
import { getPaymentCards, removeCard } from 'Requests'
import { EditCard } from './components'
import toast from 'react-hot-toast'
import './BillingSection.scss'
import addcardbill from '../../../../assets/Images/addcardbill.png'
import plusIcon from '../../../../assets/Images/addcard.png'
import visacard from '../../../../assets/Images/visacard.png'
import americanexpress from '../../../../assets/Images/americanexpress.png'
import discover from '../../../../assets/Images/discover.png'
import mastercard from '../../../../assets/Images/mastercard.png'
import dinearclub from '../../../../assets/Images/dinearclub.png'
import Jcb from '../../../../assets/Images/Jcb.png'
import deletecard from '../../../../assets/Images/deletecard.png'
import editcard from '../../../../assets/Images/editcard.png'
import cross from '../../../../assets/Images/cross.png'
import logoutleftcomb from '../../../../assets/Images/logoutleftcomb.png'
import logoutrightcomb from '../../assets/Images/logoutrightcomb.png'
import AddCard from './components/AddCard/AddCard'
import { useDispatch } from 'react-redux'
import { ConfrimModal } from 'Components/ConfrimModal'

import leftGroup from '../../../../assets/Images/leftgroup.svg'
import rightGroup from '../../../../assets/Images/rightgroup.svg'

export const BillingSection = () => {
  const [card, setCard] = useState<any>([])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isAddCard, setIsAddCard] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [cardTitle, setCardTitle] = useState('Add Card')
  const [isDeleted, setIsDeleted] = useState(false)
  const [removeCardId, setRemoveCardId] = useState('')
  const [editId, setEditId] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [carddata, setCardData] = useState('')
  const [toggle, setToggle] = useState(false)
  const [loader, setloader] = useState(false)

  const getCardDetail = () => {
    getPaymentCards().then((res) => {
      const data = res.data?.details
      setCard(res.data?.details)
      setLoading(false)
    })
  }
  // const cardId = card.map((item: any) => item.id)
  // const cardGet = cardId.toString()
  const removeCards = () => {
    setloader(true)
    removeCard({ card_id: removeCardId }).then((res: any) => {
      toast.success('Card deleted successfully!!')
      setLoading(true)
      setloader(false)

      getPaymentCards().then((res) => {
        const data = res.data?.details
        setCard(res.data?.details)
        setLoading(false)
        setIsDeleted(false)
      })
    })
  }

  useEffect(() => {
    getCardDetail()
  }, [toggle])
  const getUnMutatedCard = () => (
    <div className="BillingSection__credit_card">
      <table>
        <thead>
          <tr>
            <td className="card_title">{card.card_type}</td>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td style={{ width: '100%' }}>Name on card</td>
            <td>Static</td>
          </tr> */}

          <tr>
            <td>Card number</td>
            {/* <td>....{card.last4}</td> */}
            <td>{card.masked_number}</td>
          </tr>
          <tr>
            <td>Expiry date</td>
            <td>
              {card.expiry_month < 10
                ? `0${card.expiry_month}`
                : card.expiry_month}
              /{card.expiry_year}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  const brand = (item: string) => {
    if (item === 'visa') {
      return visacard
    }
    if (item === 'mastercard') {
      return mastercard
    }
    if (item === 'discover') {
      return discover
    }
    if (item === 'american_express') {
      return americanexpress
    }
    if (item === 'jcb') {
      return Jcb
    }
    if (item === 'diners_club') {
      return dinearclub
    }
  }

  const onClose = (fetch: boolean) => {
    if (fetch) {
      getCardDetail()
    }
    setIsEditMode(false)
  }

  const getMutatedCard = () => <EditCard onClose={onClose} />

  const getCard = () => {
    return isEditMode ? getMutatedCard() : getUnMutatedCard()
  }
  const getLoading = () => {
    let component
    if (loading) {
      component = <Loading />
    } else if (!card.length) {
      component = (
        <div className="BillingSection__main">
          <img
            className="BillingSection__billingIcon"
            src={addcardbill}
            alt="addcardbill"
          />
          <Button
            type="button"
            kind="primary"
            size="m"
            weight="bold"
            UNSAFE_className="BillingSection__billingButton"
            onClick={() => {
              setIsAddCard(true)
              setCardTitle('Add Card')
            }}
          >
            Add Card
          </Button>
        </div>
      )
    } else {
      component = (
        <div className="BillingSection__allCardlist">
          <div className="BillingSection__allCardlist_heading">
            <div className="BillingSection__heading_section">
              <Text on="white" UNSAFE_className="BillingSection__heading_text">
                Card Detail
              </Text>
            </div>
            <div className="BillingSection__heading_section_expiry">
              <Text on="white" UNSAFE_className="BillingSection__heading_text">
                Expiry Date
              </Text>
            </div>
          </div>
          {card?.map((item: any) => (
            <div className="BillingSection__detailsCard">
              <div className="BillingSection__billing_card">
                <img src={brand(item?.card.brand)} alt="brand" />
                <div>
                  <Text
                    on="white"
                    UNSAFE_className="BillingSection__card_detail_text"
                  >
                    {item?.card?.masked_number}
                  </Text>
                  <Text
                    on="white"
                    UNSAFE_className="BillingSection__card_detail_text"
                  >
                    {`${item?.card?.first_name} ${item?.card?.last_name}`}
                  </Text>
                </div>
              </div>
              <div className="">
                <Text
                  on="white"
                  UNSAFE_className="BillingSection__heading_text_detail"
                >
                  {`${item?.card?.expiry_month}/${item?.card?.expiry_year}`}
                </Text>
              </div>
              <div className="BillingSection__edit_delete">
                <img
                  className="BillingSection__ctn"
                  onClick={() => {
                    setIsAddCard(true)
                    setCardTitle('Edit Card')
                    setCardNumber(item?.card?.masked_number)
                    setEditId(item?.id)
                    setCardData(item?.card)
                  }}
                  src={editcard}
                  alt="editcard"
                />
                <img
                  className="BillingSection__ctn"
                  onClick={() => {
                    setIsDeleted(true)
                    setRemoveCardId(item?.id)
                  }}
                  src={deletecard}
                  alt="editcard"
                />
              </div>
            </div>
          ))}
          {isDeleted && (
            <Modal offClick={() => setIsDeleted(false)}>
              <ConfrimModal
                loading={loader}
                disable={loader}
                noOnclick={() => setIsDeleted(false)}
                yesOnclick={removeCards}
                title="Are you sure you want to delete this card?"
              />
            </Modal>
          )}
        </div>
      )
    }
    return component
  }
  return (
    <div className="BillingSection">
      <Section
        // title="Card on File"
        UNSAFE_className_text="BillingSection__title"
        UNSAFE_className_container="BillingSection__card"
      >
        <div className="BillingSection__billing_topbar">
          <Text on="white" UNSAFE_className="BillingSection__heading">
            Billing
          </Text>
          {card.length >= 1 && (
            <button
              className="BillingSection__headingButton"
              type="button"
              onClick={() => {
                setIsAddCard(true)
                setCardTitle('Add Card')
              }}
            >
              <img
                src={plusIcon}
                alt="uploadicon"
                style={{ marginRight: '10px' }}
              />{' '}
              Add Card
            </button>
          )}
        </div>
        <div className="BillingSection__borderline" />

        {/* *****************Card List******** */}
        {getLoading()}
        {/* *****************No Card List******** */}

        {isAddCard && (
          <Modal
            style={{ position: 'relative' }}
            offClick={() => setIsAddCard(false)}
          >
            <div className="BillingSection__AddCardModal">
              <img
                className="BillingSection__cross"
                onClick={() => setIsAddCard(false)}
                src={cross}
                alt="cross"
              />
              <img
                src={leftGroup}
                alt="leftcardicon"
                style={{
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  width: '30%',
                  zIndex: '-1',
                }}
              />
              <AddCard
                // @ts-ignore
                editId={editId}
                setToggle={setToggle}
                setLoadings={setLoading}
                setIsAddCard={setIsAddCard}
                setCardNumber={cardNumber}
                title={cardTitle}
                cardData={carddata}
                getCardDetail={getCardDetail}
              />
              <img
                style={{
                  position: 'absolute',
                  bottom: '0px',
                  right: '0px',
                  width: '32%',
                  zIndex: '-1',
                }}
                src={rightGroup}
                alt="righcardicon"
              />
            </div>
          </Modal>
        )}
        {/* end */}
        {/* {!card ? <Loading /> : getCard()}
        {!isEditMode && (
          <button
            onClick={() => setIsEditMode(true)}
            className="forgotPassword edit_button"
            type="button"
          >
            Edit
          </button>
        )} */}
      </Section>
    </div>
  )
}
