import { FlexBox, Select, Spacer, GridBox, Button } from '@eltoro-ui/components'
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useRecoilState } from 'recoil'
import {
  blockFontFamilySelector,
  blockFontSizeSelector,
  blockFontStyleSelector,
  blockTextAlignSelector,
  blockTextDecorationSelector,
} from '../../../State'
import { BlocksConfigType } from '../../../Typings'
import './TypographySettings.scss'

const defaultFontFamilies = [
  {
    label: 'Arial',
    value: 'arial',
  },
]

export const TypographySettings: React.FC<{
  config: BlocksConfigType
}> = ({ config }) => {
  const [fontSize, setFontSize] = useRecoilState(blockFontSizeSelector(config))
  const [fontFamily, setFontFamily] = useRecoilState(
    blockFontFamilySelector(config),
  )
  const [textAlign, setTextAlign] = useRecoilState(
    blockTextAlignSelector(config),
  )
  const [fontStyle, setFontStyle] = useRecoilState(
    blockFontStyleSelector(config),
  )
  const [textDecoration, setTextDecoration] = useRecoilState(
    blockTextDecorationSelector(config),
  )

  const textAlignSelected = (align: string) => {
    return textAlign.value === align && !textAlign.error
  }
  const fontStyleSelected = (style: string) => {
    return fontStyle.value === style && !fontStyle.error
  }
  const textDecorationSelected = (decoration: string) => {
    return textDecoration.value === decoration && !textDecoration.error
  }

  return (
    <div className="TypographySettings">
      <GridBox gap="1rem" gridTemplateColumns="min-content 1fr">
        <input
          className="TypographySettings__number-input"
          type="number"
          value={fontSize.value}
          min={8}
          max={100}
          onChange={(e) =>
            setFontSize({ value: parseInt(e.target.value, 10), error: false })
          }
        />
        <Select
          placeholder="Select a font family"
          options={defaultFontFamilies}
          selectedOption={{ label: fontFamily.value, value: fontFamily.value }}
          onChange={(option) =>
            setFontFamily({ value: option?.value || '', error: false })
          }
          maxHeight={200}
        />
      </GridBox>
      <Spacer />
      <FlexBox justifyContent="space-between">
        <GridBox gap=".5rem" gridTemplateColumns="repeat(4, min-content)">
          <Button
            kind={textAlignSelected('left') ? 'default' : 'text'}
            iconLeft={<FontAwesomeIcon icon={faAlignLeft} />}
            onClick={() => setTextAlign({ value: 'left', error: false })}
          />
          <Button
            kind={textAlignSelected('center') ? 'default' : 'text'}
            iconLeft={<FontAwesomeIcon icon={faAlignCenter} />}
            onClick={() => setTextAlign({ value: 'center', error: false })}
          />
          <Button
            kind={textAlignSelected('right') ? 'default' : 'text'}
            iconLeft={<FontAwesomeIcon icon={faAlignRight} />}
            onClick={() => setTextAlign({ value: 'right', error: false })}
          />
          <Button
            kind={textAlignSelected('justify') ? 'default' : 'text'}
            iconLeft={<FontAwesomeIcon icon={faAlignJustify} />}
            onClick={() => setTextAlign({ value: 'justify', error: false })}
          />
        </GridBox>
        <Spacer />
        <GridBox gap=".5rem" gridTemplateColumns="repeat(4, min-content)">
          <Button
            kind={fontStyleSelected('bold') ? 'default' : 'text'}
            iconLeft={<FontAwesomeIcon icon={faBold} />}
            onClick={() =>
              setFontStyle({
                value: fontStyle.value === 'bold' ? 'normal' : 'bold',
                error: false,
              })
            }
          />
          <Button
            kind={fontStyleSelected('italic') ? 'default' : 'text'}
            iconLeft={<FontAwesomeIcon icon={faItalic} />}
            onClick={() =>
              setFontStyle({
                value: fontStyle.value === 'italic' ? 'normal' : 'italic',
                error: false,
              })
            }
          />
          <Button
            kind={textDecorationSelected('underline') ? 'default' : 'text'}
            iconLeft={<FontAwesomeIcon icon={faUnderline} />}
            onClick={() =>
              setTextDecoration({
                value: textDecoration.value === 'underline' ? '' : 'underline',
                error: false,
              })
            }
          />
          <Button
            kind={textDecorationSelected('linethrough') ? 'default' : 'text'}
            iconLeft={<FontAwesomeIcon icon={faStrikethrough} />}
            onClick={() =>
              setTextDecoration({
                value:
                  textDecoration.value === 'linethrough' ? '' : 'linethrough',
                error: false,
              })
            }
          />
        </GridBox>
      </FlexBox>
    </div>
  )
}
