import { Button, Modal, Text } from '@eltoro-ui/components'
import { useForm } from '@eltoro-ui/hooks'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputBox } from 'Components'
import { LableInputField } from 'Components/LableInputField'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  setIsAudienceSaved,
  setLaunchProspect,
  setOpenTagModal,
  setTargetAudience,
} from 'Redux/actions'
import { saveAudience } from 'Requests'
import { TRootState } from 'types'
import './SaveAudienceModal.scss'

export type AudienceDetailsType = { name: string; tags?: string[] }

type SaveAudienceModalType = {
  onClickOutside: () => void
  onSave: (audienceDetails: AudienceDetailsType) => void
  selectedtag: any
  listing?: any
  totalProspects?: any
  setSelectedTags?: any
  dataObj?: any
  type?: any
  radiusListing?: number[]
}

export const SaveAudienceModal: React.FC<SaveAudienceModalType> = ({
  onClickOutside,
  onSave,
  selectedtag,
  listing,
  totalProspects,
  setSelectedTags,
  dataObj,
  type,
  radiusListing,
}) => {
  const [name, setName] = useState('')
  // const [tags, setTags] = useState<any[]>(selectedtag)
  const isLaunchCampaign = useSelector(
    (state: TRootState) => state.isCampaignLaunchReducer,
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const { isValidForm, touched } = useForm({ name })
  const required = ['name']
  const { validForm, missingFields } = isValidForm([...required])
  const targetAudiennce = useSelector(
    (state: TRootState) => state.targetAudienceReducer,
  )

  const listingsId: any = []
  if (type === 'my listings' || type === 'Digital Farming') {
    listing?.forEach((element: any) => {
      listingsId.push(element.listingid)
    })
  }
  const [btnDisabled, setbtnDisabled] = useState(false)
  const [loader1, setLoader1] = useState<boolean>(false)
  const [loader2, setLoader2] = useState<boolean>(false)

  const postInfo = (isSelected?: boolean) => {
    setbtnDisabled(true)

    if (isSelected) {
      setLoader1(true)
    } else {
      setLoader2(true)
    }

    if (type !== 'my listings' && type !== 'Digital Farming') {
      const bodyWithFilters = {
        name: targetAudiennce.targetAudience,
        source: type,
        consider_body: true,
        body: dataObj,
        tags: selectedtag,
        prospects_counts: totalProspects?.toString(),
        listings: [],
      }

      selectedtag = []
      // dispatch(setTargetAudience(''))
      saveAudienceAPI(bodyWithFilters, isSelected)
    } else {
      const sourceType =
        type === 'Digital Farming' ? 'Digital Farming' : 'My Listings'
      const bodyWithArray = {
        name: targetAudiennce.targetAudience,
        source: sourceType,
        prospects_counts: totalProspects.toString(),
        listings:
          radiusListing && type === 'Digital Farming'
            ? Array.from(new Set([...listingsId, ...radiusListing]))
            : listingsId,
        tags: selectedtag,
      }
      selectedtag = []
      // dispatch(setTargetAudience(''))
      saveAudienceAPI(bodyWithArray, isSelected)
    }
  }

  const saveAudienceAPI = (body: Partial<any>, isSelected?: boolean) => {
    saveAudience(body)
      .then((res) => {
        onClickOutside()
        dispatch(setTargetAudience(''))
        setSelectedTags([])
        onSave({ name })
        toast.success('Audience Saved Successfully')
        dispatch(setIsAudienceSaved(true))

        if (isSelected) {
          history.push({
            pathname: '/create-campaign/setup',
            state: { isSelected },
          })
        }
        if (isLaunchCampaign?.LaunchProspectOpen)
          if (isLaunchCampaign?.campaignId?.id) {
            history.push(
              `/create-campaign/${isLaunchCampaign?.campaignId.id}/setup`,
            )
            dispatch(setLaunchProspect(false))
          } else {
            history.push('/create-campaign/setup')
            dispatch(setLaunchProspect(false))
          }
      })
      .finally(() => {
        setbtnDisabled(false)
        setLoader1(false)
        setLoader2(false)
      })
  }

  const openTagModel = () => {
    dispatch(setOpenTagModal(true))
  }
  // const [isHover, setIsHover] = useState(false)
  const offClickOutside = () => {
    onClickOutside()
    dispatch(setTargetAudience(''))
    setSelectedTags([])
  }
  return (
    <Modal
      // header="Name and Tag your Target Audience"
      offClick={offClickOutside}
      className="SaveAudienceModal"
    >
      <div className="OnboardingModal__welcome ListingModalBg SaveAudiencePosition">
        <div className="OnboardingModal__welcomeHeader ListingAudienceModal">
          <div className="SaveAudienceModal__cancel">
            <FontAwesomeIcon icon={faTimes} onClick={offClickOutside} />
          </div>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <Text
              on="white"
              weight="normal"
              // size="xxxl"
              textAlign="center"
              UNSAFE_style={{ fontSize: '40px', lineHeight: '48px' }}
            >
              Name and Tag your Target Audience
            </Text>
          </div>
          <br />
          <div
            style={{
              // width: '280px',
              margin: '0 30px',
              textAlign: 'center',
            }}
          >
            <div style={{ textAlign: 'left', position: 'relative' }}>
              <Text
                on="grey-500"
                size="m"
                UNSAFE_style={{ marginBottom: '0.5rem' }}
              />

              <LableInputField
                placeholder="Target Audience Name"
                title="Target Audience"
                type="text"
                style={{ paddingLeft: '20px' }}
                padding={10}
                value={targetAudiennce.targetAudience}
                onChange={(e) => {
                  // setName(e.target.value)
                  dispatch(setTargetAudience(e.target.value))
                }}
              />
              <br />
              {/* <Text on="grey-500" size="m">
                
              </Text> */}
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Button
                  kind="default"
                  UNSAFE_className="targetAudience__btn"
                  onClick={openTagModel}
                  disabled={selectedtag?.length > 5}
                >
                  + Tag
                </Button>
                {selectedtag?.length >= 1
                  ? selectedtag.map((item: any, index: number) => {
                      // const [hover, isHOver] = useState(false)
                      let hover = true
                      return (
                        <div style={{ position: 'relative' }} key={index}>
                          <Button
                            UNSAFE_className="targetAudience__tags"
                            kind="default"
                            onMouseOver={() => {
                              hover = true
                            }}
                            onMouseOut={() => {
                              hover = false
                            }}
                          >
                            {item}
                          </Button>
                          <FontAwesomeIcon
                            style={{ display: hover ? 'grid' : 'none' }}
                            // onMouseOver={() => isHOver(true)}
                            className="targetAudience__tagsCross"
                            icon={faTimes}
                            onClick={() => {
                              setSelectedTags([
                                ...selectedtag.filter(
                                  (val: any) => val !== item,
                                ),
                              ])
                            }}
                          />
                        </div>
                      )
                    })
                  : // </div>
                    ''}
              </div>
              <Button
                kind="primary"
                size="xl"
                weight="bolder"
                width="100%"
                onClick={() => postInfo(true)}
                disabled={!targetAudiennce.targetAudience || btnDisabled}
                UNSAFE_style={{
                  borderRadius: '12px',
                  fontSize: '17px',
                }}
                loading={loader1}
              >
                Save &#38; launch new ad campaign
              </Button>
              <Button
                loading={loader2}
                kind="primary"
                size="xl"
                weight="bolder"
                width="100%"
                onClick={() => postInfo(false)}
                disabled={!targetAudiennce.targetAudience || btnDisabled}
                UNSAFE_style={{
                  border: 'none',
                  marginTop: '5px',
                  borderRadius: '12px',
                  fontSize: '17px',
                }}
              >
                Save audience
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
