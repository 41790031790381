import React, { useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import 'leaflet/dist/leaflet.css'
import './MultiColorPolygonMap.scss'
import L from 'leaflet'
import 'leaflet-providers'

import { APIFeatureType, MapLocationType, TLocation } from 'types'
import {
  getBounds,
  getLocationSearchResults,
  getLocationSearchResultsOSMId,
  getPolygonBorderColor,
} from 'Helpers'
import { Button } from '@eltoro-ui/components'
import { useDispatch } from 'react-redux'
import { setSpinner } from 'Redux/actions'
import { CustomMapPopup } from 'Components'
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

import toast from 'react-hot-toast'
import { CustomMapCanvasPopup } from 'Components/CustomMapCanvasPopup/CustomMapCanvasPopup'

const center: L.LatLngExpression = [28.538336, -81.379234]

export const MultiColorPolygonMap: React.FC<{
  tileProvider?: string
  features: APIFeatureType[]
  locations: MapLocationType[]
  locationsArea?: TLocation[]
  buzzAvg?: any[]
  marker: (f: APIFeatureType) => JSX.Element
  tooltip: (f: any) => JSX.Element
  // type?: string
  callPolygonFilters?: boolean
  firstClicked?: boolean
  setcallPolygonFilters?: (val: boolean) => void
  searchClicked?: boolean
}> = ({
  tileProvider,
  features,
  locations,
  marker,
  tooltip,
  locationsArea,
  buzzAvg,
  firstClicked,
  // type,
  callPolygonFilters,
  setcallPolygonFilters,
  searchClicked,
}) => {
  const [bounds, setBounds] = useState(
    L.latLngBounds([42.2974279, -85.628292], [-8.852507, -45.351563]),
  )

  const dispatch = useDispatch()
  const mapRef = useRef<any>()
  useEffect(() => {
    const provider = tileProvider || 'CartoDB.VoyagerLabelsUnder'
    mapRef.current = L.map('leaflet-map', {
      fullscreenControl: true,
      closePopupOnClick: false,
    }).setView(center, 8)
    L.tileLayer.provider(provider).addTo(mapRef.current)

    // When Dragging maps, close all open popups
    // if (type !== 'Digital Farming') {
    //   mapRef.current.on('dragstart', () => mapRef.current?.closePopup())
    // }
  }, [])

  // init layer
  const layerRef = useRef<any>()
  useEffect(() => {
    if (mapRef.current) {
      layerRef.current = L.layerGroup().addTo(mapRef.current)
    }
  }, [])
  const [score, setScore] = useState()

  // if there are locations, set bounds
  useEffect(() => {
    if (locations.length) {
      const locationsBounds = {
        lat: locations.reduce((acc: number[], location) => {
          const north = parseFloat(location.bounds[0][0])
          const south = parseFloat(location.bounds[1][0])
          return [...acc, north, south]
        }, []),
        long: locations.reduce((acc: number[], location) => {
          const east = parseFloat(location.bounds[0][1])
          const west = parseFloat(location.bounds[1][1])
          return [...acc, east, west]
        }, []),
      }

      setBounds(getBounds(locationsBounds.lat, locationsBounds.long))
    }
  }, [locations])
  const ftData = []

  useEffect(() => {
    if (callPolygonFilters) {
      if (!firstClicked) {
        layerRef.current?.clearLayers()
      }
      polygonFilters()
    }
  }, [callPolygonFilters])

  const polygonFilters = () => {
    if (locationsArea?.length) {
      const centerMap: any = []

      for (let i = 0; i < locationsArea.length; i += 1) {
        let buzzData: {
          street: string
          activity_score: number
          mls_id: string
        }
        if (buzzAvg) {
          buzzData = {
            street: locationsArea[i].display_value,
            activity_score: buzzAvg[i],
            mls_id: `${'locationsArea'[i]}`,
          }
        }
        dispatch(setSpinner(true))
        getLocationSearchResults(locationsArea[i].value)
          .then((resp) => {
            getLocationSearchResultsOSMId(resp.results[0].osm_id)
              .then((res) => {
                if (res?.length) {
                  if (res[0].geojson.type === 'Polygon') {
                    const singlePolLatLngs = L.GeoJSON.coordsToLatLngs(
                      res[0].geojson.coordinates,
                      1,
                    )
                    centerMap.push(singlePolLatLngs)
                    const singlePol = L.polygon(singlePolLatLngs, {
                      color: 'blue',
                      fillColor: 'skyblue',
                    })

                    singlePol
                      .bindPopup(
                        renderToString(
                          <CustomMapPopup
                            feature={buzzData}
                            prospect={false}
                          />,
                        ),
                      )
                      .openPopup()
                      .addTo(layerRef.current)
                  } else {
                    const multiPolLatLngs = L.GeoJSON.coordsToLatLngs(
                      res[0].geojson.coordinates,
                      2,
                    )
                    centerMap.push(multiPolLatLngs)

                    const multiPol = L.polygon(multiPolLatLngs, {
                      color: getPolygonBorderColor(buzzAvg?.[i]),
                      fillColor: 'skyblue',
                    })

                    multiPol
                      .bindPopup(
                        renderToString(
                          <CustomMapPopup
                            feature={buzzData}
                            prospect={false}
                            type="Advertise To All In Market"
                          />,
                        ),
                      )
                      .openPopup()
                      .addTo(layerRef.current)
                  }

                  if (i === locationsArea.length - 1) {
                    setBounds(centerMap)
                    dispatch(setSpinner(false))
                  }
                } else {
                  if (i === locationsArea.length - 1) {
                    dispatch(setSpinner(false))
                  }
                  toast.error(
                    `No polygon found for ${locationsArea[i].display_value}`,
                  )
                }
              })
              .catch((err) => {
                toast.error(err)
              })
              .finally(() => {
                if (i === locationsArea.length - 1 && setcallPolygonFilters) {
                  setcallPolygonFilters(false)
                  // dispatch(setSpinner(false))
                }
              })
          })
          .catch((err) => {
            toast.error(err)
          })
      }
      // }
    }
  }

  // add features to layer
  useEffect(() => {
    layerRef.current?.clearLayers()

    if (layerRef.current) {
      let i = 0
      // const newList = []
      if (features.length >= 1)
        features?.forEach((feature) => {
          i += 1
          const featureToolTip = (currentFeatureMarker: L.Marker) => {
            return currentFeatureMarker.bindTooltip(
              renderToString(tooltip(feature)),
              { direction: 'top', offset: [4, -27] },
            )
          }

          ftData.push([feature.latitude, feature.longitude])
          const featureMarker = L.marker(
            [feature.latitude, feature.longitude],
            {
              icon: L.divIcon({
                html: renderToString(marker(feature)),
              }),
              title: feature.street ? feature.street : undefined,
            },
          )

          featureMarker.addTo(layerRef.current)
        })
    }
  }, [features])

  // Map markers for selected listings

  // fit map to bounds when bounds are updated
  const fitMapToBounds = () => {
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds)
    }
  }

  // fit to bounds when bounds are updated via location/feature changes
  useEffect(() => {
    if (features.length > 0) {
      fitMapToBounds()
    }
  }, [bounds])

  return (
    <div className="Map" id="leaflet-map">
      {features?.length > 0 && (
        <div className="Map__fit-to-bounds-button">
          <Button onClick={fitMapToBounds} rounded>
            Zoom to results
          </Button>
        </div>
      )}
    </div>
  )
}
