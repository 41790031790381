import React, { FC, ReactElement } from 'react'
import { Text } from '@eltoro-ui/components'
import folderImg from '../../assets/Images/folder.png'
import './EmptyStatus.scss'

export const EmptyStatus: FC<{
  heading?: string
  subHeading?: string
  icon?: ReactElement
}> = ({ heading = 'No Records Are Found', subHeading, icon }) => {
  return (
    <div className="empty_status_container">
      <div className="empty_status_container__nested_container">
        {icon || <img src={folderImg} alt="folder" />}
        <div className="empty_status_container__headings_container">
          <Text
            UNSAFE_style={{ textTransform: 'none' }}
            on="white"
            size="xxxl"
            weight="bold"
          >
            {heading}
          </Text>
          <br />
          <Text
            UNSAFE_className="empty_status_container__subHeading"
            on="white"
          >
            {subHeading}
          </Text>
        </div>
      </div>
    </div>
  )
}
