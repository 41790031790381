import React from 'react'
import {
  useTable,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
} from 'react-table'

import { TextInput } from '@eltoro-ui/components'
import searchicon from '../../assets/Images/searchicon.png'

import './ReactTableSearchBar.scss'

type ReactTableSearchBarType = {
  globalFilter: string
  setGlobalFilter: (p?: string) => void
  // columns: any[]
  // data: any[]
  // value: string
  // setValue: (p: string) => void
}

// export const ReactTableSearchBar: React.FC<ReactTableSearchBarType> = ({
export const ReactTableSearchBar: React.FC<ReactTableSearchBarType> = ({
  globalFilter,
  setGlobalFilter,
  // columns,
  // data = [],
  // columns,
  // data,
  // value,
  // setValue,
}) => {
  // const {
  //   setGlobalFilter,
  //   state: { globalFilter },
  // }: any = useTable({ columns, data })
  // const {
  //   setGlobalFilter,
  //   state: { globalFilter },
  // }: any = useTable({ columns, data }, useFilters, useGlobalFilter)

  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((_value) => {
    setGlobalFilter(_value || undefined)
  }, 200)

  return (
    <TextInput
      className="react_table_search_bar"
      value={value || ''}
      placeholder="Search"
      colorr="#2B1F0A"
      iconStyle={{ position: 'absolute', right: '8px', top: '13px' }}
      icon={<img src={searchicon} alt="searchicon" />}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}
