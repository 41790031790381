import {
  AudienceType,
  ResponseType,
  TAudiences,
  TDeleteAudiences,
  TSaveAudience,
} from 'types'
import requests from '../http'

export const getAudiences = async (
  page?: number | null,
  size?: number | null,
  search?: string | null,
  sort_key?: string | null,
  order?: string | null,
): Promise<ResponseType<TAudiences>> => {
  const req = search
    ? `/audience?page=${page}&size=${size}&search=${search}&${
        sort_key && `sort_key=${sort_key}&order=${order}&order=${order}`
      }`
    : `/audience?page=${page}&size=${size}&${
        sort_key && `sort_key=${sort_key}&order=${order}&order=${order}`
      }`
  return requests.get(req)
}

export const getAudienceById = async (
  audience_id: string,
): Promise<ResponseType<AudienceType>> => {
  return requests.get(`/audience/${audience_id}`)
}

export const deleteAudience = async (
  body: Object,
): Promise<ResponseType<TDeleteAudiences>> => {
  return requests.post(`/audience/delete-audience`, body)
}
export const saveAudience = async (
  body: Partial<any>,
): Promise<ResponseType<TSaveAudience>> => {
  return requests.post(`/audience/save`, body)
}
export const updateAudience = async (
  audience_id: string,
  body: Partial<AudienceType>,
): Promise<ResponseType<AudienceType>> => {
  return requests.patch(`/audience/${audience_id}`, body)
}

export const createAudience = async (
  body: Partial<AudienceType>,
): Promise<ResponseType<AudienceType>> => requests.post(`/audience`, body)
