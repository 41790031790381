import { request } from 'http'
import {
  ResponseType,
  TResetPassword,
  TPasswordReset,
  TEmailValidate,
  TCreateUser,
  TAccessToken,
  TMe,
  TUpdateUser,
} from 'types'
import qs from 'qs'
import requests, { keycloakLogin, keycloakRest, Req } from '../http'

type Body = {
  email: string
  redirect_link: string
}
export const emailVerify = async (
  body: Partial<any>,
): Promise<ResponseType<Body>> => requests.post('/email_verify', body)

export const restEmailVerify = async (
  body: Partial<any>,
): Promise<ResponseType<Body>> => requests.post('/password_recovery', body)

export const forgotPassword = async (
  data: Partial<any>,
): Promise<ResponseType<TResetPassword>> => Req.post('/reset-password', data)

export const resetPassword = async (
  data: Partial<any>,
): Promise<ResponseType<TResetPassword>> =>
  requests.post('/user/password-reset', data)

export const tokenValidate = async (
  body: string | undefined,
): Promise<ResponseType<TEmailValidate>> =>
  requests.post('/email_validate', body)

const adminLogin = qs.stringify({
  username: 'admin',
  password: 'pa55wOrd',
  grant_type: 'password',
  client_id: 'admin-cli',
})
export const keycloakAdminLogin = async (): Promise<any> =>
  keycloakLogin.post(
    '/auth/realms/master/protocol/openid-connect/token',
    adminLogin,
  )

export const createKeycloakUser = async (
  body: { email: string; enabled: Boolean },
  token: string | undefined,
): Promise<ResponseType<any>> =>
  keycloakRest.post('/auth/admin/realms/dev_beewo/users', body, token)

export const createNewUser = async (
  body: string | undefined | Object,
): Promise<ResponseType<TCreateUser>> =>
  requests.post('/users/create_user', body)

export const userLogin = async (
  body: Object,
): Promise<ResponseType<TAccessToken>> =>
  Req.post('/keycloak/access_token', body)

export const whomi = async (body: Object): Promise<ResponseType<any>> =>
  Req.post('/users/me', body)

export const userDetail = async (
  token: string | undefined,
): Promise<ResponseType<TMe>> => Req.get('/users/me', token)

export const userUpdate = async (
  body: Object,
): Promise<ResponseType<TUpdateUser>> =>
  requests.put('/users/update_user', body)
// keycloakRest.post('/users/update_user', body, token)
// export const updateUser = async (
//   body: Object,
//   token: string | undefined,
// ): Promise<ResponseType<any | undefined>> =>
//   keycloakRest.post('/users/update_user', body, token)
