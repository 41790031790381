import { BuzzFilterType } from 'Components'

export const buzzFilters: BuzzFilterType[] = [
  {
    label: 'High buzz',
    color: 'var(--color-data-warm-800)',
    test: (buzzScore) => buzzScore === 'high buzz',
  },
  {
    label: 'Warm buzz',
    color: 'var(--color-data-warm-600)',
    test: (buzzScore) => buzzScore === 'warm buzz',
  },
  {
    label: 'Medium buzz',
    color: 'var(--color-data-warm-300)',
    test: (buzzScore) => buzzScore === 'medium buzz',
  },
  {
    label: 'Low buzz',
    color: 'var(--color-data-warm-200)',
    test: (buzzScore) => buzzScore === 'low buzz',
  },
]
