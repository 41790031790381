import React, { useState, useEffect, FC } from 'react'
import {
  PageHeader,
  TableSelectionWrapper,
  Loading,
  SearchBar,
  Spinner,
  Pagination,
} from 'Components'
import { Button, Table, TextInput, Text } from '@eltoro-ui/components'
import { useAsync } from '@eltoro-ui/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { setSpinner, setUser } from 'Redux/actions'
import {
  faHouse,
  faHeart,
  faPlus,
  faSearch,
  faHome,
} from '@fortawesome/pro-solid-svg-icons'
import {
  APIFeatureType,
  APIFeatureResponseType,
  ResponseType,
  TSort,
  TEstate_users,
} from 'types'
import { getListings, getMyListings } from 'Requests'
import './MyListings.scss'
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory } from 'react-router-dom'
import {
  getRealStateUsersListings,
  getUserToken,
} from 'Requests/Request_Methods/superAdminMethods'
import { getLocalUser, userRole } from 'Helpers'
import { authRoles } from 'routes'
import { userDetail } from 'Requests/Request_Methods/authUserMethods'
import toast from 'react-hot-toast'

type MyUserListings = {
  callResetUrl: () => void
  setSortedList?: (data: { sort: TSort; path: string }) => void
  sortedList?: { sort: TSort; path: string }
  setCurrentPage?: (page: number) => void
  currentPage?: number
}
export const MyListings: FC<MyUserListings> = ({
  callResetUrl,
  // currentPage,
  // setCurrentPage,
  // setSortedList,
  // sortedList,
}) => {
  const [selections, setSelections] = useState<TEstate_users[]>([])
  const [users, setUsers] = useState<TEstate_users[]>()
  const [search, setSearch] = useState('')
  // const [listingData, setListingData] = useState()
  const [pages, setPages] =
    useState<{
      current_page?: number
      total_count?: number
      total_pages?: number
    }>()
  const [unselect, setUnselectAll] = useState(false)
  const [isLoading, setIsLoading] = useState({ apiCallLoaded: false })
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortedList, setSortedList] = useState<{
    path: string
    sort: TSort
  }>({
    sort: 'asc',
    path: '',
  })
  const dispatch = useDispatch()
  const history = useHistory()

  const [spinner, setspinner] = useState(false)

  const [refreshPage, setrefreshPage] = useState(false)
  const ViewAsAUser = (sub: string) => {
    setrefreshPage(true)
    getUserToken(sub)
      .then((res) => {
        const userToken: string =
          'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSaHVMa1hPYnVYcGdEUGE4NkUyZnU0aWZsRFA4dkRPWEkwN0ZZSVpDMXNVIn0.eyJleHAiOjE2NDQzNTc5ODMsImlhdCI6MTY0NDMyMTk4MywianRpIjoiMTg4MGU1NjAtZjRlMi00NWFhLThkMzItY2M1MjRhNjA4NmI2IiwiaXNzIjoiaHR0cHM6Ly9oaXZlLnN0YWdpbmcuYmVld28uY29tL2F1dGgvcmVhbG1zL2Rldl9iZWV3byIsInN1YiI6IjUwZmJkNzI1LWE5MTYtNDU2MC1iZGJjLWRkNGU1NDdmNDc1ZSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImJhY2tlbmQiLCJzZXNzaW9uX3N0YXRlIjoiNTlmMmUzZDAtYTkxNC00NWNhLThjYmMtNDYzZTNhYmE1MzdmIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2hpdmUuc3RhZ2luZy5iZWV3by5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6InNhZndhbi1zdGFnZUBtYWlsaW5hdG9yLmNvbSIsImVtYWlsIjoic2Fmd2FuLXN0YWdlQG1haWxpbmF0b3IuY29tIn0.gUhPOJngGeMcZUtpFMxUvZ2ikfpRCM-VqYtBIHEEjurIKsYSIT9euAMuUX7dqd53TSj_FVsis8CfvIfIayx6rWmNecpmMX9E_uwhG3ev_DpAwDWEVR6vhnT6uygIHwQLJVDAAy3_NfEM3Mk6L_8oI4xs-MpUBWtu4E5b2GA3EeS3Lo9LttJ_7y-LVm72rDHshWbgVMGxs9LlLVUzQpPMY3-2iQNE9J1LlEau7js8RN_wASkLuiPTMIB19UT1XirdBrH51a0ZHC1MC2yM3QF3zrCX74ARLBiR4fdVt4vhVc6V7-Y0hWZhk0JMiUcu58MNdROSU_WRCIvdyU--uVUR8A'
        // 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSaHVMa1hPYnVYcGdEUGE4NkUyZnU0aWZsRFA4dkRPWEkwN0ZZSVpDMXNVIn0.eyJleHAiOjE2NDI3ODcxNjAsImlhdCI6MTY0Mjc1MTE2MCwianRpIjoiZDUxMjhjYTgtZTkxYS00N2E4LTkyNWYtN2RjNTkxNTA4MmNjIiwiaXNzIjoiaHR0cHM6Ly9oaXZlLnN0YWdpbmcuYmVld28uY29tL2F1dGgvcmVhbG1zL2Rldl9iZWV3byIsInN1YiI6IjUwZmJkNzI1LWE5MTYtNDU2MC1iZGJjLWRkNGU1NDdmNDc1ZSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImJhY2tlbmQiLCJzZXNzaW9uX3N0YXRlIjoiNGMxNmU5OGYtMzIxZi00NTFkLTk2ZjItOWU3ZDIxN2NlYWVmIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2hpdmUuc3RhZ2luZy5iZWV3by5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6InNhZndhbi1zdGFnZUBtYWlsaW5hdG9yLmNvbSIsImVtYWlsIjoic2Fmd2FuLXN0YWdlQG1haWxpbmF0b3IuY29tIn0.RJeZ4b3-ApdiFVGe-HhsRWHfF5B1frC4XFEkZau6AGePQw8voLNYZReDqQ-jTTzj6XZzMAfzM-T-_6fjk8RcmxIrZ-0rzNK6nIlmgASVhYXkDhug460hmfPmEpiOhGN4xQ0-cER8sSUEIZawxlxLufuAFg1j0Ct-bVHzZ2lKp4OL9exFG8BPQ57xUv3k6QSAZf0y4n2OsD2H3yM8EpcSfgOzqHxNxdMcekWZafUgHZW9FI6Gioa6fnXb2--E-21JSZFMRkBAiS0gt4igqOtUXpIImNs30b3d2hCYy5JZO6RkMmxbrEmU5JvzUz_e8m2pmdmkJukMvUI7El8eAxMBqA'
        // 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSaHVMa1hPYnVYcGdEUGE4NkUyZnU0aWZsRFA4dkRPWEkwN0ZZSVpDMXNVIn0.eyJleHAiOjE2NDI2OTk3NDUsImlhdCI6MTY0MjY2Mzc0NSwianRpIjoiZTlhNGJhYjItNjRmNC00NjAwLTk1YmQtNzUwYWVjODc0N2U0IiwiaXNzIjoiaHR0cHM6Ly9oaXZlLnN0YWdpbmcuYmVld28uY29tL2F1dGgvcmVhbG1zL2Rldl9iZWV3byIsInN1YiI6IjUwZmJkNzI1LWE5MTYtNDU2MC1iZGJjLWRkNGU1NDdmNDc1ZSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImJhY2tlbmQiLCJzZXNzaW9uX3N0YXRlIjoiMDdhOTI1ODYtZWQ3My00MWM2LTgyY2EtNWViNGYxYTg4ODU4IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2hpdmUuc3RhZ2luZy5iZWV3by5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6InNhZndhbi1zdGFnZUBtYWlsaW5hdG9yLmNvbSIsImVtYWlsIjoic2Fmd2FuLXN0YWdlQG1haWxpbmF0b3IuY29tIn0.cUyK6zoZkTmy5CP2gtk7yTtIAO2ajUYyRRsqX4o3gQElpE4FfttoE3YXTIJJVSZv4gNVacr8X2am06jv7kCC8AXb4bIrPApvbiK6fgVlYf0P-WK2KgfrwxK7YZpELHIQMk8aRlVpuDSUylX5tYT_XvJHTyvmdcRksGAmM9z8FLiWtLBG1sPZkztP2qwQ6udLkJlmeypMsPHjnG28Exv1si-Cgk0Ijf53Lm9N-C211leIKE03O12hdk05unY0SOVAbDBXv4HGrsZf-vX-hDIHFock3KMlPt9Hg1pCLDL3faj54VRpqdDYnyYo98WaK_h7JPkjqCXuiBmzg7XZBPHviQ'
        // 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSaHVMa1hPYnVYcGdEUGE4NkUyZnU0aWZsRFA4dkRPWEkwN0ZZSVpDMXNVIn0.eyJleHAiOjE2NDI2MjIyMDEsImlhdCI6MTY0MjU4NjIwMSwianRpIjoiZDYyMjQ5ZTgtNTdmNi00YTBmLTgxYzYtMTNiN2ZkODMyZjUwIiwiaXNzIjoiaHR0cHM6Ly9oaXZlLnN0YWdpbmcuYmVld28uY29tL2F1dGgvcmVhbG1zL2Rldl9iZWV3byIsInN1YiI6IjUwZmJkNzI1LWE5MTYtNDU2MC1iZGJjLWRkNGU1NDdmNDc1ZSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImJhY2tlbmQiLCJzZXNzaW9uX3N0YXRlIjoiOWJmYjdkZmYtNTZmMC00NWZiLTk3NzYtZmY2MTBkNThlNGFmIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2hpdmUuc3RhZ2luZy5iZWV3by5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6InNhZndhbi1zdGFnZUBtYWlsaW5hdG9yLmNvbSIsImVtYWlsIjoic2Fmd2FuLXN0YWdlQG1haWxpbmF0b3IuY29tIn0.BMZeS46ZZ-ekWaUh0i9io2_Ezk9yCWy8uT9zPT9ilwLpUUKjyRykJ9Ci5FhR72LmChu0H0agDzNi5MJwfwC9H0iMIrbs60eZhbRINpxxUgVIEi80jYfbBgnuJPPrm5M9U0CFKjN_UBV_47sUQwK1tOsyZGRQrSds2BEqTvrQFb_vvwW1-Zzl_ZEVdXlnOs4rCpTHpg5OQ9jSXu5Bz1Knw9zKpNC_9R8UOCMOlMF66KRUA4xPqaOkvP8Qyg2W5-r0dVAuky9EWD2-rpbQjQzAhazD3KTIhOP1M1XrxquQxqwEhOj3NdDdqlq-KTKvkJG8oBIo67s_yiLDRmZfGLqNVw'
        // 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSaHVMa1hPYnVYcGdEUGE4NkUyZnU0aWZsRFA4dkRPWEkwN0ZZSVpDMXNVIn0.eyJleHAiOjE2NDI2OTk3NDUsImlhdCI6MTY0MjY2Mzc0NSwianRpIjoiZTlhNGJhYjItNjRmNC00NjAwLTk1YmQtNzUwYWVjODc0N2U0IiwiaXNzIjoiaHR0cHM6Ly9oaXZlLnN0YWdpbmcuYmVld28uY29tL2F1dGgvcmVhbG1zL2Rldl9iZWV3byIsInN1YiI6IjUwZmJkNzI1LWE5MTYtNDU2MC1iZGJjLWRkNGU1NDdmNDc1ZSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImJhY2tlbmQiLCJzZXNzaW9uX3N0YXRlIjoiMDdhOTI1ODYtZWQ3My00MWM2LTgyY2EtNWViNGYxYTg4ODU4IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2hpdmUuc3RhZ2luZy5iZWV3by5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6InNhZndhbi1zdGFnZUBtYWlsaW5hdG9yLmNvbSIsImVtYWlsIjoic2Fmd2FuLXN0YWdlQG1haWxpbmF0b3IuY29tIn0.cUyK6zoZkTmy5CP2gtk7yTtIAO2ajUYyRRsqX4o3gQElpE4FfttoE3YXTIJJVSZv4gNVacr8X2am06jv7kCC8AXb4bIrPApvbiK6fgVlYf0P-WK2KgfrwxK7YZpELHIQMk8aRlVpuDSUylX5tYT_XvJHTyvmdcRksGAmM9z8FLiWtLBG1sPZkztP2qwQ6udLkJlmeypMsPHjnG28Exv1si-Cgk0Ijf53Lm9N-C211leIKE03O12hdk05unY0SOVAbDBXv4HGrsZf-vX-hDIHFock3KMlPt9Hg1pCLDL3faj54VRpqdDYnyYo98WaK_h7JPkjqCXuiBmzg7XZBPHviQ'
        userDetail(userToken)
          .then((resData) => {
            if (resData.detail?.[0].msg !== 'User is not authorized!') {
              const tempData = {
                ...resData.data,
                user_role: authRoles.ultraAdmin,
              }
              dispatch(setUser(tempData))
              localStorage.setItem('user_type', btoa(authRoles.ultraAdmin))

              localStorage.setItem('user', btoa(JSON.stringify(tempData)))

              const superAdmin = localStorage.getItem('beewo_token')
              if (typeof superAdmin === 'string') {
                localStorage.setItem('user_view', btoa(superAdmin))
                // localStorage.setItem('user_type', btoa(temp))
                localStorage.setItem('beewo_token', userToken)
              }
              // window.location.href=
              callResetUrl()
            }
          })
          .catch((err) => {
            toast.error(err)
          })
          .finally(() => {})
        // window.location.reload()
      })
      .catch((err) => {
        toast.error(err)
      })
  }
  const usersListing = () => {
    if (!sortedList.path) {
      setIsLoading({ ...isLoading, apiCallLoaded: true })
    } else {
      setspinner(true)
    }

    getRealStateUsersListings(
      currentPage,
      20,
      search,
      sortedList.path && sortedList.path,
      sortedList.sort && sortedList.sort,
    ).then((res) => {
      setUsers(res?.data?.estate_users)
      // setListingData(res.data)
      setPages({
        current_page: res?.data?.current_page,
        total_count: res?.data?.total_count,
        total_pages: res?.data?.total_pages,
      })
      if (!sortedList.path) {
        setIsLoading({ ...isLoading, apiCallLoaded: false })
      } else {
        setspinner(false)
      }
    })
    setUnselectAll(false)
  }

  useEffect(() => {
    usersListing()
  }, [currentPage, sortedList])
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      usersListing()
    }, 600)

    return () => clearTimeout(delayDebounceFn)
  }, [search])
  const onSort = (path: string, sort: TSort) => {
    setSortedList({ sort, path })
    setCurrentPage(1)
  }
  const dummyData = [
    {
      sub: '1a257b16-d455-4514-9bd6-2309f25e3ba9',
      first_name: 'diloo',
      last_name: 'khan',
      email: 'dilroo@mailinator.com',
      work_email: 'dilroo@mailinator.com',
      mls_region: 'Orlando',
      mls_id: 'T3280433',
      is_active: 1,
      is_super: 0,
    },
  ]

  return (
    <div className="MyListings">
      {spinner && <Spinner />}
      <PageHeader
        // icon={<FontAwesomeIcon icon={faHouse} />}
        title="Real-Estate Users"
        UNSAFE_CLASSNAME_TITLE="header-user-title"
        LeftContent_ClassName="MyListings__header"
        subTitle=""
        actions={[
          // <TextInput
          //   value={search}
          //   onChange={(e) => setSearch(e.target.value)}
          //   icon={<FontAwesomeIcon icon={faSearch} />}
          // />,
          <span className="header-users">
            <SearchBar
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              UNSAFE_CLASSNAME="header-users-search"
            />
          </span>,
        ]}
      />

      {users && !isLoading.apiCallLoaded ? (
        <TableSelectionWrapper
          selectionType="user"
          selections={selections}
          UNSAFE_CLASSNAME_WRAPPER="table-padding"
          actions={
            [
              // <button type="button" className="Campaigns__select-action">
              //   <Text on="tertiary-300" weight="bold">
              //     Delete
              //   </Text>
              // </button>,
            ]
          }
        >
          {users.length > 0 ? (
            <Table
              noCheckbox
              rows={users || []}
              columns={[
                {
                  path: 'first_name',
                  // muliPath: 'last_name',

                  label: 'Name',
                  width: '18%',
                  alignment: 'left',
                  onSort,
                  RowCell: (user) => (
                    <span>
                      {user.first_name} {user.last_name}
                    </span>
                  ),
                },
                {
                  path: 'mls_region',
                  label: 'MLS Region',
                  alignment: 'left',
                  onSort,
                },
                {
                  path: 'mls_id',
                  label: 'MLS ID',
                  alignment: 'left',
                  onSort,
                },
                // { path: 'sub_divison', label: 'Subdivision Name', width: '20%' },
                {
                  path: 'work_email',
                  label: 'Work Email',
                  alignment: 'left',
                  onSort,
                  // RowCell: (user) => (
                  //   <b>{`$${user.listing_price.toLocaleString()}`}</b>
                  // ),
                },
                {
                  path: 'email',
                  label: 'Usersname',
                  alignment: 'left',
                  onSort,
                  // RowCell: (user) => (user.email ? `${user.email}` : ''),
                },

                // {
                //   path: 'status',
                //   label: 'Status',
                //   alignment: 'left',
                //   onSort,
                //   RowCell: (user) => (
                //     <div className="MyListings__status-badge">
                //       {user.status === 'Active' && (
                //         <FontAwesomeIcon
                //           icon={faCheck}
                //           color="white"
                //           style={{ marginRight: '8px' }}
                //         />
                //       )}
                //       {user.status}
                //     </div>
                //   ),
                // },
                {
                  path: '',
                  alignment: 'left',

                  RowCell: (user) => (
                    <Button
                      UNSAFE_style={{
                        color: '#007B94',
                        border: '2px solid #007B94',
                      }}
                      UNSAFE_className="MyListings__FooterBtn"
                      kind="default"
                      size="l"
                      width="100%"
                      onClick={() => {
                        // ViewAsAUser(user.sub)
                        // getIconMap()
                        // dispatch(setFilteredBuzz([]))
                        // setActiveBuzzFilters(buzzFilters)
                        // getListingMethod()
                        // dispatch(setTypeSearch(''))
                        // dispatch(setSelectedPage(1))
                        // dispatch(
                        //   setSelectedSort({ label: 'Zip Code', value: 'zip' }),
                        // )
                        // setSelectedListings([])
                      }}
                    >
                      View
                    </Button>
                    // <Button to={`/users/${user.listingid}`}>View</Button>
                  ),
                },
              ]}
              onSelect={setSelections}
              unSelectAll={unselect}
            />
          ) : (
            // <Table
            //   noCheckbox
            //   rows={dummyData || []}
            //   columns={[
            //     {
            //       path: '',
            //       // muliPath: 'last_name',

            //       label: 'Name',
            //       width: '18%',
            //       alignment: 'left',
            //       onSort,
            //       RowCell: (user) => <span>No data found </span>,
            //     },
            //     {
            //       path: '',
            //       label: 'MLS Region',
            //       alignment: 'left',
            //       onSort,
            //     },
            //     {
            //       path: '',
            //       label: 'MLS ID',
            //       alignment: 'left',
            //       onSort,
            //     },
            //     // { path: 'sub_divison', label: 'Subdivision Name', width: '20%' },
            //     {
            //       path: '',
            //       label: 'Work Email',
            //       alignment: 'left',
            //       onSort,
            //       // RowCell: (user) => (
            //       //   <b>{`$${user.listing_price.toLocaleString()}`}</b>
            //       // ),
            //     },
            //     {
            //       path: '',
            //       label: 'Usersname',
            //       alignment: 'left',
            //       onSort,
            //       // RowCell: (user) => (user.email ? `${user.email}` : ''),
            //     },

            //     {
            //       path: '',
            //       alignment: 'left',
            //     },
            //   ]}
            //   onSelect={setSelections}
            //   unSelectAll={unselect}
            // />
            <></>
          )}

          {!!users.length && (
            <Pagination
              title="users"
              loading={isLoading.apiCallLoaded}
              currentPage={pages?.current_page || 0}
              totalItems={pages?.total_count || 0}
              totalPages={pages?.total_pages || 0}
              onClickPrev={() => setCurrentPage(currentPage - 1)}
              onClickNext={() => setCurrentPage(currentPage + 1)}
            />
          )}
        </TableSelectionWrapper>
      ) : (
        <Loading />
      )}
    </div>
  )
}
