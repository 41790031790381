import { getBrowserInfo } from '@eltoro-ui/hooks/useForm/helper'
import {
  ResponseType,
  TContacts,
  TDeleteContacts,
  TUploadContacts,
  TSaveCampaign,
  TSaveContacts,
} from 'types'
import requests from '../http'

export const getContacts = async (
  page?: number | null,
  size?: number | null,
  sort_key?: string | null,
  order?: string | null,
): Promise<ResponseType<TContacts>> => {
  const req = `/contacts?page=${page}&size=${size}&${
    sort_key && `sort_key=${sort_key}&order=${order}`
  }`
  return requests.get(req)
}

export const getContactDetailById = async (
  id: number | string,
): Promise<ResponseType<TContacts>> => {
  const req = `/contacts/${id}`
  return requests.get(req)
}

export const deleteContacts = async (
  body: Object,
): Promise<ResponseType<TDeleteContacts>> => {
  return requests.post(`/contacts/delete-contacts`, body)
}

export const uploadContactsCSV = async (
  body: Object | any,
): Promise<ResponseType<TUploadContacts>> => {
  return requests.post(`/contacts/upload`, body)
}
export const uploadContactsSync = async (
  body: Object | any,
): Promise<ResponseType<TUploadContacts>> => {
  return requests.post(`/contacts/sync`, {
    contacts: body,
    device: `Web-${getBrowserInfo()}`,
  })
}

export const saveContacts = async (
  body: Object | any,
): Promise<ResponseType<TUploadContacts>> => {
  return requests.post(`/contacts/save`, body)
}

export const editContact = async (
  body: Object,
  id: number,
): Promise<ResponseType<any>> => requests.put(`/contacts/${id}`, body)
