/*eslint-disable*/

import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/free-solid-svg-icons'
import { HeaderItem, HeaderDropdown } from '@eltoro-ui/components'
/* import { Link } from 'react-router-dom' */
import { AppContext } from '../AppContext'
import * as methods from '../Requests'
import { useKeycloak } from '@react-keycloak/web'
import { clearSession } from './sessionManager'

import axios, { AxiosResponse } from 'axios'
import { logger, browserLog, terminalLog } from 'Tools'
import {
  getListings,
  getMapListings,
} from 'Requests/Request_Methods/listingMethods'
import {
  attachAudience,
  attachCreative,
  createCampaign,
  detachAudience,
  detachCreative,
  getCampaignById,
  getCampaigns,
  updateCampaign,
} from 'Requests/Request_Methods/campaignMethods'
import {
  createAudience,
  getAudienceById,
  getAudiences,
  updateAudience,
} from 'Requests/Request_Methods/audienceMethods'
import {
  createCreative,
  getCreativeById,
  getCreatives,
  updateCreative,
} from 'Requests/Request_Methods/creativeMethods'
import { getNotifications } from 'Requests/Request_Methods/miscMethods'
import { createUser } from 'Requests/Request_Methods/userMethods'
import { mockLocation } from 'Pages/MyListings/mockLocation'

// const { getUser, getAuth, updateUser, getListingById } = methods
// const root =
//   process.env.NODE_ENV === 'development'
//     ? process.env.REACT_APP_PROXY
//     : process.env.REACT_APP_API
const root =
  process.env.NODE_ENV === 'development' ? 'http://35.80.134.236' : ''
const baseUrl = `${root}/api/v1`

const endpoint = `${baseUrl}/users`
export const DebugButtons = () => {
  const { currentUser, login, logout } = useContext(AppContext)
  const { keycloak } = useKeycloak()

  return <div>works</div>
  //   <HeaderDropdown
  //     hasDropdownIcon={false}
  //     position="left"
  //     icon={<FontAwesomeIcon icon={faBug} />}
  //   >
  //     <HeaderItem
  //       onClick={() => {
  //         throw new Error('This is a test Error thrown by error debug button')
  //       }}
  //     >
  //       Throw Test Error
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         logger.log('local storge: ', localStorage.getItem('beewo_user'))
  //         logger.log('Current User: ', currentUser)
  //       }}
  //     >
  //       Log user
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         getAuth('user@test.com', 'password').then(() => {
  //           getUser().then((res) =>
  //             browserLog.log('debug button res:', res),
  //           )
  //         })
  //       }}
  //     >
  //       Test 1 - mock api
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         getAuth('user@test.com', 'password').then(() => {
  //           updateUser({ first_name: 'sal' }).then((res) =>
  //             browserLog.log('debug button res:', res),
  //           )
  //         })
  //       }}
  //     >
  //       Test 2 - mock api
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         getListingById('T3280433').then((res) => browserLog.log(res))
  //       }}
  //     >
  //       Test getListingById
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         login('user@test.com', '12345')
  //         logger.log(
  //           'please click the "Log user" debug button to see if login worked',
  //         )
  //       }}
  //     >
  //       Test Login
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         logout()
  //         logger.log(
  //           'please click the "Log user" debug button to see if logout worked',
  //         )
  //       }}
  //     >
  //       Test logout
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         keycloak.logout()
  //         clearSession()
  //         logger.log('You will see the undefined tokens above for kc')
  //       }}
  //     >
  //       Test logoutKC
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         logger.log(keycloak)
  //       }}
  //     >
  //       log Keycloak Object
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         logger.log('this is a test of the basic logger')
  //       }}
  //     >
  //       test basic logger
  //     </HeaderItem>

  //     <HeaderItem
  //       onClick={() => {
  //         browserLog.log(`what browserLog.${name} looks like`)
  //         browserLog.info(`what browserLog.${name} looks like`)
  //         browserLog.error(`what browserLog.${name} looks like`)
  //       }}
  //     >
  //       test browserLog
  //     </HeaderItem>
  //     <HeaderItem
  //       onClick={() => {
  //         terminalLog.log(`what TerminalLog.${name} looks like`)
  //         terminalLog.info(`what TerminalLog.${name} looks like`)
  //         terminalLog.error(`what TerminalLog.${name} looks like`)
  //       }}
  //     >
  //       test terminallog
  //     </HeaderItem>

  //     <HeaderItem
  //       onClick={() => {
  //         browserLog.fatal('Congrats, you just deleted the internet.')
  //       }}
  //     >
  //       {' '}
  //       test your resolve...{' '}
  //     </HeaderItem>
  //   </HeaderDropdown>
  // )
}

export default DebugButtons
