import { ContactsSync } from 'Pages/MyContacts/ContactsSync'
import OAuthGoogle from 'Pages/OAuthGoogle/OAuthGoogle'
import React from 'react'
import { SuperAdminDashboard } from 'SuperAdminPages/SuperAdminDashboard'
import {
  MyListings,
  DashboardPage,
  ProspectActivityPage,
  MyProfilePage,
  Login,
  MyContacts,
  CampaignDashboard,
  Campaigns,
  SavedAudiences,
  AudienceView,
  CampaignCreation,
  CampaignView,
  ListingView,
  CreateAcc,
  PasswordSetup,
  ResetPassword,
  Notifications,
  TermOfUse,
  PrivacyPolicy,
  GeneralPrivacyPolicy,
} from './Pages'

type RouteType = {
  path: string
  component: React.FC
  public: boolean | string
  user_role: string[]
}
type AuthRoles = {
  superAdmin: string
  realEstate: string
  ultraAdmin: string
}
export const authRoles: AuthRoles = {
  superAdmin: 'super_admin',
  realEstate: 'real_estate',
  ultraAdmin: 'ultra_admin',
}

const routes: RouteType[] = [
  { path: '/login', component: Login, public: true, user_role: [] },
  { path: '/login/super-admin', component: Login, public: true, user_role: [] },
  { path: '/login/sa', component: Login, public: true, user_role: [] },
  // { path: '/logout', component: Login, public: true, user_role: [] },
  { path: '/signup', component: CreateAcc, public: true, user_role: [] },
  {
    path: '/email-verify',
    component: CreateAcc,
    public: true,
    user_role: [],
  },
  {
    path: `/reset-password`,
    component: PasswordSetup,
    public: true,
    user_role: [],
  },
  {
    path: `/verify-email`,
    component: PasswordSetup,
    public: true,
    user_role: [],
  },
  {
    path: `/profile/password-reset`,
    component: ResetPassword,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/',
    component: SuperAdminDashboard,
    public: false,
    user_role: ['super_admin'],
  },
  {
    path: '/dashboard',
    component: SuperAdminDashboard,
    public: false,
    user_role: ['super_admin'],
  },

  {
    path: '/dashboard',
    component: DashboardPage,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/notifications',
    component: Notifications,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },

  {
    path: '/',
    component: DashboardPage,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/my-sphere',
    component: MyContacts,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/sync-email',
    component: ContactsSync,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/my-listings',
    component: MyListings,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/my-listings/:listingId',
    component: ListingView,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/prospects',
    component: ProspectActivityPage,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/create-campaign',
    component: CampaignCreation,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/campaign-dashboard',
    component: CampaignDashboard,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/campaigns',
    component: Campaigns,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/campaigns/:campaignId',
    component: CampaignView,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/saved-audiences',
    component: SavedAudiences,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/saved-audiences/:audienceId',
    component: AudienceView,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/profile',
    component: MyProfilePage,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/solo-auth-proxy.html',
    component: OAuthGoogle,
    public: false,
    user_role: ['real_estate', authRoles.ultraAdmin],
  },
  {
    path: '/solo-auth-proxy.html',
    component: OAuthGoogle,
    public: true,
    user_role: [],
  },
  {
    path: '/terms-of-use',
    component: TermOfUse,
    public: true,
    user_role: [],
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    public: true,
    user_role: [],
  },
  {
    path: '/general-privacy-policy',
    component: GeneralPrivacyPolicy,
    public: true,
    user_role: [],
  },
]

export default routes
