/* eslint-disable */

import { Button, Form, Text } from '@eltoro-ui/components'
import { FormObjectType, useForm } from '@eltoro-ui/hooks'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
// import { createChargebeeUser, userUpdate } from 'Requests'

import { ErrorMsg, Tooltip, Spinner } from 'Components'
import { LableInputField } from 'Components/LableInputField'
import './PersonalInfoForm.scss'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { createChargebeeUser, getMlS, userUpdate } from 'Requests'
import { TRootState, UserInfoType } from 'types'
import Maskgroup from '../../assets/Images/Maskgroup.svg'
import Warning from '../../Images/warning.png'
import cross from '../../assets/Images/cross.png'

import { LableInputSelect } from 'Components/LableInputSelect'
import { setSpinner } from 'Redux/actions'
import { useHistory } from 'react-router-dom'

export const PersonalInfoForm = ({
  onSubmit,
  user,
}: {
  onSubmit: (value: FormObjectType) => void
  user: UserInfoType
}) => {
  const [firstName, setFistName] = useState(
    user.personalInfo.firstName ? user.personalInfo.firstName : '',
  )
  const [lastName, setLastName] = useState(
    user.personalInfo.lastName ? user.personalInfo.lastName : '',
  )

  const [mls, setMLS]: any = useState('')
  const [mlsID, setMLSID] = useState(
    user.personalInfo.mlsID ? user.personalInfo.mlsID : '',
  )
  const [territory, setTerritory] = useState(
    user.personalInfo.territory ? user.personalInfo.territory : '',
  )
  const [email, setEmail] = useState(
    user.personalInfo.email ? user.personalInfo.email : '',
  )
  const [agency, setAgency] = useState<React.ReactText>(
    user.personalInfo.agency ? user.personalInfo.agency : '',
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const { formData, isValidForm, touched } = useForm({
    firstName,
    lastName,
    email,
    mls,
    mlsID,
    agency,
    territory,
  })
  const [options, setOptions] = useState([])
  const [store, setstore] = useState('')
  const { spinner } = useSelector((state: TRootState) => state.SpinnerReducer)

  useEffect(() => {
    setLocalStoredMail(localStorage.getItem('userMail'))
    // @ts-ignore
    setEmail(localStorage.getItem('userMail'))
    getMlS()
      .then((Res) => {
        const opt: any = []
        Res.data.listings.map((item: { name: string }) => {
          return opt.push({ label: item.name })
        })
        setOptions(opt)
        setMLS(opt[0].label)
      })
      .catch((err) => {
        toast.error(err)
      })
    user.personalInfo.email = userEmail || ''
  }, [])

  const [isValidFirstName, setIsValidFirstname] = useState(false)
  const [isValidLastName, setIsValidLastname] = useState(false)
  const required = ['firstName', 'lastName', 'email', 'MLS', 'MLSID', 'agency']
  const [isSubmit, setIsSubmit] = useState(false)
  const [havedata, isHaveData] = useState(false)
  const [isValidateMLSID, setIsValidateMLSID] = useState(false)
  const [localStoredMail, setLocalStoredMail] = useState<string | null>('')
  const { userEmail } = useSelector(
    (state: TRootState) => state.userMailReducer,
  )

  const verifySubmit = (data: FormObjectType) => {
    if (
      (firstName.length >= 2 || user.personalInfo.firstName.length >= 2) &&
      (lastName.length >= 2 || user.personalInfo.lastName.length >= 2) &&
      // isValidEmail &&
      territory.length >= 1 &&
      mls.length >= 1 &&
      mlsID.length >= 1
      // agency >= 1
    ) {
      // isHaveData(true)
      const body = {
        first_name: data.firstName,
        last_name: data.lastName,
        work_email: data.email,
        mls_region: data.mls,
        mls_id: data.mlsID,
        territory: data.territory,
      }
      dispatch(setSpinner(true))
      userUpdate(body)
        .then((res) => {
          const schema = {
            id: res.data?.id,
            first_name: res.data?.first_name,
            last_name: res.data?.last_name,
            email: res.data?.email,
          }

          createChargebeeUser(schema).catch((err) => {
            toast.error(err)
          })

          // const userss = {
          //   user_role: userRole(res.data.is_super),
          //   ...res.data,
          // }
          // dispatch(setUser(userss))
          // dispatch(setUser(res.data))
          onSubmit(data)
          dispatch(setSpinner(false))
        })
        .catch((err) => {
          dispatch(setSpinner(false))
          toast.error(err)
        })

      // onSubmit(data)
    } else {
      setIsSubmit(true)
    }
  }
  const closedSection = () => {
    window.localStorage.clear()
    location.reload()
  }
  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="Please fill the input"
          topAbsolute="-80px"
          rightAbsolute="-43px"
          padding="5px 5px"
        />
      </div>
    )
  }
  const MlsRegion = options.map((item: any) => {
    return item.lable
  })

  return (
    <div
      className="PersonalInfoForm"
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      <img
        src={cross}
        onClick={closedSection}
        alt="cross"
        className="PersonalInfoForm__cross"
      />
      <>
        {spinner && <Spinner />}
        <div className="PersonalInfoForm__description">
          {/* <div className="PersonalInfoForm__progress">
          <ProgressBar totalSteps={4} activeStep={1} />
        </div> */}
          <div className="PersonalInfoForm__header">
            <Text on="white" UNSAFE_className="heading">
              Personal Info
            </Text>
            <Text on="grey-500" UNSAFE_className="sub_heading">
              We’re here to get you the most tailored experience. All we need
              from you is to answer a couple of simple questions.
            </Text>
          </div>
        </div>
        <div className="PersonalInfoForm__inputs">
          <Form
            // onSubmit(formData)
            onSubmit={() => {
              verifySubmit(formData)
              // location.reload()
            }}
            required={required}
            // valid={validForm}
            // missingFields={missingFields}
            touched={touched}
          >
            {/* <FormItem
            htmlFor="first name"
            FontSize="14px"
            label="First Name"
            errorMessage="Please enter valid name"
            required
            isStar
            valid={isValidFirstName}
            Height="1rem"
          > */}
            <LableInputField
              style={{ paddingLeft: '22px' }}
              lableStyle={{ left: '14px' }}
              requireTage="*"
              value={firstName}
              title="First Name"
              placeholder="First Name"
              isError={isSubmit && firstName.length <= 1}
              type="text"
              iconRight={
                <img
                  className="start_icon"
                  style={{ marginTop: '7px', width: '24px' }}
                  src={isSubmit && firstName.length <= 1 ? Warning : ''}
                  // alt="icon"
                />
              }
              onChange={(e) => {
                setFistName(e.target.value)
                if (firstName.length <= 1) {
                  setIsValidFirstname(false)
                } else {
                  setIsValidFirstname(true)
                }
              }}
              // lableStyle={{ top: '22px', left: '15px' }}
            />
            {/* <PersonalInput
              fontSize="16px"
              type="text"
              value={firstName}
              wdt="100%"
              maxLength={40}
              border="1px solid #c6cbcd"
              isError={isSubmit && firstName.length <= 1}
              placeHolder="First Name"
              onChange={(e) => {
                setFistName(e.target.value)
                if (firstName.length <= 1) {
                  setIsValidFirstname(false)
                } else {
                  setIsValidFirstname(true)
                }
              }}
            />
            {isSubmit && firstName.length <= 1 ? <ErrorToolbar /> : ''}
            {firstName.length >= 2 || firstName === '' ? (
              ''
            ) : (
              <ErrorMsg
                title="Please enter right name"
                icon={<FontAwesomeIcon icon={faExclamationCircle} />}
              />
            )}*/}
            {isSubmit && firstName.length <= 1 ? <ErrorToolbar /> : ''}
            {firstName.length >= 2 || firstName === '' ? (
              ''
            ) : (
              <ErrorMsg
                title="Please enter right name"
                icon={<FontAwesomeIcon icon={faExclamationCircle} />}
              />
            )}
            {/* </FormItem> */}
            <LableInputField
              // style={{ paddingLeft: '22px', margin: '2rem 0' }}
              style={{ paddingLeft: '22px', marginTop: '2rem' }}
              lableStyle={{ left: '14px' }}
              requireTage="*"
              value={lastName}
              title="Last Name"
              placeholder="Last Name"
              isError={isSubmit && lastName.length <= 1}
              type="text"
              iconRight={
                <img
                  className="start_icon"
                  style={{ marginTop: '7px', width: '24px' }}
                  src={isSubmit && lastName.length <= 1 ? Warning : ''}
                  // alt="icon"
                />
              }
              onChange={(e) => {
                setLastName(e.target.value)
                if (lastName.length <= 1) {
                  setIsValidLastname(false)
                } else {
                  setIsValidLastname(true)
                }
              }}
              // lableStyle={{ top: '22px', left: '15px' }}
            />
            {isSubmit && lastName.length <= 1 ? <ErrorToolbar /> : ''}

            {lastName.length >= 2 || lastName.length === 0 ? (
              ''
            ) : (
              <ErrorMsg
                title="Please enter right name"
                icon={<FontAwesomeIcon icon={faExclamationCircle} />}
              />
            )}
            {/* <FormItem
            FontSize="14px"
            htmlFor="last name"
            label="Last Name"
            isStar
            errorMessage="Please enter valid name"
            required
            valid={isValidLastName}
            Height="1rem"
          >
            <PersonalInput
              type="text"
              fontSize="16px"
              value={lastName}
              maxLength={40}
              wdt="100%"
              border="1px solid #c6cbcd"
              isError={isSubmit && lastName.length <= 1}
              placeHolder="Last Name"
              onChange={(e) => {
                setLastName(e.target.value)
                if (lastName.length <= 1) {
                  setIsValidLastname(false)
                } else {
                  setIsValidLastname(true)
                }
              }}
            />
            {isSubmit && lastName.length <= 1 ? <ErrorToolbar /> : ''}

            {lastName.length >= 2 || lastName.length === 0 ? (
              ''
            ) : (
              <ErrorMsg
                title="Please enter right name"
                icon={<FontAwesomeIcon icon={faExclamationCircle} />}
              />
            )}
          </FormItem> */}
            <LableInputField
              requireTage="*"
              style={{
                paddingLeft: '22px',
                // marginRight: '2rem',
                marginTop: '2rem',
              }}
              lableStyle={{ left: '14px' }}
              value={user.personalInfo.email ? user.personalInfo.email : email}
              title="Work Email"
              placeholder="Work Email"
              isError={isSubmit && email.length < 1}
              disabled={true}
              type="text"
              // iconRight={
              //   <img
              //     className="start_icon"
              //     style={{ marginTop: '7px', width: '24px' }}
              //     src={isSubmit && email.length <= 1 ? Warning : ''}
              //     // alt="icon"
              //   />
              // }

              // lableStyle={{ top: '22px', left: '15px' }}
            />
            {isSubmit && email.length < 1 ? <ErrorToolbar /> : ''}
            {/* <FormItem
            htmlFor="email"
            FontSize="14px"
            label="Work Email"
            isStar
            errorMessage="Please enter a valid email"
            // valid={isValidEmail}
            Height="1rem"
          >
            <PersonalInput
              type="text"
              fontSize="16px"
              placeHolder="Work Email"
              value={user.personalInfo.email ? user.personalInfo.email : email}
              wdt="100%"
              border="1px solid #c6cbcd"
              // onChange={(e) => setEmail(e.target.value)}
              isError={isSubmit && email.length < 1}
              disabled
            />
            {isSubmit && email.length < 1 ? <ErrorToolbar /> : ''}
          </FormItem> */}
            <LableInputSelect
              requireTage="*"
              value={mls}
              title="Select Your MLS Region"
              // isError={isSubmit && mls.length < 1}
              style={{ marginTop: '2rem' }}
              onChange={(value: any) => setMLS(value.target.value)}
              Options={
                <>
                  {options.map((item: any) => {
                    return (
                      <>
                        {/* <option value="MLS Region">MLS Region</option> */}
                        <option selected value={item.label}>
                          {item.label}
                        </option>
                      </>
                    )
                  })}
                </>
              }
            />
            {/* {isSubmit && mls.length < 1 ? <ErrorToolbar /> : ''} */}
            {/* <FormItem
            htmlFor="MLS"
            label="Select Your MLS Region"
            isStar
            FontSize="14px"
            errorMessage="Please enter a valid MLS id"
            Height="1rem"
          >
            <Select
              Height="39px"
              // placeholder="Select Your MLS Region* "
              placeholder={
                user.personalInfo.mls
                  ? 'MLS-Region'
                  : 'Select Your MLS Region* '
              }
              options={options}
              isError={isSubmit && mls.label.length < 1}
              // placeholder={user.personalInfo.mls?user.personalInfo.mls.value:'__Select__'}
              selectedOption={mls}
              onChange={(value) => !!value && setMLS(value)}
              maxHeight={300}
              radius="5px"
              size="14px"
            />
            {isSubmit && mls.label.length < 1 ? <ErrorToolbar /> : ''}
          </FormItem> */}

            {/* MLS ID  */}
            {/* {mls.label.length > 1 ? ( */}
            <LableInputField
              requireTage="*"
              style={{ paddingLeft: '22px', marginTop: '2rem' }}
              lableStyle={{ left: '14px' }}
              value={mlsID}
              title="What is your MLS ID?"
              placeholder="Enter MLS ID"
              isError={isSubmit && !mlsID}
              type="text"
              iconRight={
                <img
                  className="start_icon"
                  style={{ marginTop: '7px', width: '24px' }}
                  src={isSubmit && !mlsID ? Warning : ''}
                  // alt="icon"
                />
              }
              onChange={(e) => setMLSID(e.target.value)}
              // lableStyle={{ top: '22px', left: '15px' }}
            />
            {isSubmit && mlsID.length < 1 ? <ErrorToolbar /> : ''}
            {/* <FormItem
            htmlFor="MLS ID"
            label="What is your MLS ID?"
            isStar
            errorMessage="Please enter a MLS ID"
            Height="1rem"
            required
            // valid={isValidEmail}
          >
            <PersonalInput
              value={mlsID}
              fontSize="16px"
              placeHolder="000-000-000"
              wdt="100%"
              border="1px solid #c6cbcd"
              onChange={(e) => setMLSID(e.target.value)}
              isError={isSubmit && !mlsID}
            />
            {isSubmit && mlsID.length < 1 ? <ErrorToolbar /> : ''}
          </FormItem> */}
            {/* // ) : (
          //   ''
          // )} */}
            <LableInputField
              requireTage="*"
              style={{ paddingLeft: '22px', marginTop: '2rem' }}
              lableStyle={{ left: '14px' }}
              value={territory}
              title="Real estate territory?"
              placeholder="Real estate territory?"
              isError={!territory && isSubmit}
              type="text"
              iconRight={
                <img
                  className="start_icon"
                  style={{ marginTop: '7px', width: '24px' }}
                  src={isSubmit && !mlsID ? Warning : ''}
                  // alt="icon"
                />
              }
              onChange={(e) => {
                setTerritory(e.target.value)
                setIsSubmit(false)
              }}
              // lableStyle={{ top: '22px', left: '15px' }}
            />
            {!territory && isSubmit ? <ErrorToolbar /> : ''}
            {/* <FormItem
            FontSize="14px"
            htmlFor="territory"
            label="Where is your primary real estate territory?"
            isStar
          >
            <PersonalInput
              type="text"
              placeHolder="e.g, city, state, zipcode"
              value={territory}
              wdt="100%"
              border="2px solid #2B1F0A"
              onChange={(e) => {
                setTerritory(e.target.value)
                setIsSubmit(false)
              }}
              fontSize="16px"
              isError={!territory && isSubmit}
            />
            {!territory && isSubmit ? <ErrorToolbar /> : ''}
          </FormItem> */}

            {/* MLS ID */}
            {/* <FormItem
            htmlFor="agency"
            label="Do you work for a Real Estate agency, or are you self represented?"
            isStar
            FontSize="14px"
            Height="1rem"
            required
            // valid={!!agency}
          >
            <RadioButtonGroup
              name="agency"
              valueSelected={!agency ? 'agency' : agency}
              onChange={(value) => {
                setAgency(value)
                // setIsSubmit(false)
              }}
            >
              <RadioButton
                size="m"
                key={1}
                value="agency"
                label="Real Estate Agency"
              />
              <RadioButton key={2} value="self" label="Self Represented" />
            </RadioButtonGroup>
          </FormItem> */}

            <div
              style={{ position: 'relative', right: 0, paddingBottom: '2rem' }}
            >
              <div className="PersonalInfoForm__submit">
                <Text
                  UNSAFE_style={{
                    color: '#2B1F0A',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                  on="white"
                >
                  <span style={{ color: '#BD0000' }}>*</span>required
                </Text>
                <Button
                  UNSAFE_style={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    background: '#FCD917',
                    color: '#2B1F0A',
                    fontFamily: 'Open Sans',
                    fontWeight: '700',
                    justifyContent: 'center',
                  }}
                  type="submit"
                  kind="primary"
                  size="l"
                  width="115px"
                  fonts="16px"
                  weight="bolder"
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <img
          id=""
          style={{
            position: 'absolute',
            left: '-56px',
            width: '50%',
            bottom: '-82px',
          }}
          src={Maskgroup}
          alt="beewo-logo-back"
          className=""
        />
      </>
    </div>
  )
}
