import { Button, MaxHeightContainer, Spacer } from '@eltoro-ui/components'
import { useAsync } from '@eltoro-ui/hooks'
import {
  ImpressionValidator,
  Layout,
  Loading,
  PageHeader,
  ProgressStepper,
  SummaryCart,
} from 'Components'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useHistory, useParams } from 'react-router-dom'
import { getCampaignById } from 'Requests/Request_Methods/campaignMethods'
import {
  CampaignCreationFooter,
  CampaignCreationWrapper,
  DateRangeSelector,
} from '..'
import './CampaignCheckout.scss'

export const CampaignCheckout = () => {
  // This should be the full campaign but no good standard for it yet
  // const [campaignFrequency, setCampaignFrequency] = useState<
  //   number | undefined
  // >(campaign.frequency)
  const [freq, setFreq] = useState(3)
  const [campaign, setCampaign] = useState<any>()
  const [remainingdays, setRemainingDays] = useState<number>()

  useEffect(() => {
    getCampaignById(campaignId)
      .then((res) => {
        setCampaign(res.data)
      })
      .catch((err) => toast.error(err))
  }, [])
  const { campaignId } = useParams<{ campaignId: string }>()

  const { status, value } = useAsync(() => getCampaignById(campaignId))

  useEffect(() => {
    if (value?.data) setCampaign(value.data)
  }, [value])

  const now = new Date()
  const [startDate, setStartDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate()),
  )
  const [endDate, setEndDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() + 14),
  )
  // const incCampaignFrequency = () => {
  //   setFreq(freq + 1)
  // }
  // const decCampaignFrequency = () => {
  //   if (freq > 1) setFreq(freq - 1)
  // }
  const days =
    Math.ceil(
      (endDate.valueOf() - startDate.valueOf()) / (60 * 60 * 24) / 1000,
    ) + 1

  const checkout = {
    days: days || campaign?.days,
    frequency: freq || campaign?.impressions_perday,
    start_date: startDate,
    end_date: endDate,
  }
  const history = useHistory()
  const [openSubmitCampaign, setOpenSubmitCampaign] = useState(false)
  return (
    <div className="CampaignCheckout">
      {/* {openSubmitCampaign?<campaign />:''} */}
      <MaxHeightContainer
        fullHeight
        header={
          <div>
            <PageHeader
              title={campaign?.name}
              style={{ height: '84px', background: 'var(--color-white)' }}
              UNSAFE_CLASSNAME_GRID="CampaignSetup__titleSpace"
              UNSAFE_CLASSNAME_TITLE="CampaignCheckout__title"
              subTitle=" "
              actions={[
                <ProgressStepper
                  pathset1={() =>
                    history.push(`/create-campaign/${campaign.id}/creatives`)
                  }
                  pathset={() =>
                    history.push(`/create-campaign/${campaign.id}/setup`)
                  }
                  tagNames={['Audience', 'Creative', 'Checkout', 'Submit']}
                  totalSteps={4}
                  activeStep={3}
                  color="#1BA1C5"
                />,
              ]}
              actionsStyle="CampaignSetup__progress mt-17"
              UNSAFE_CLASSNAME="mb-8px"
            />
          </div>
        }
        footer={
          <CampaignCreationFooter>
            <Button
              size="l"
              UNSAFE_className="campaignBack"
              // onClick={() => browserLog.info('back')}
              // disabled
              onClick={() => {
                history.replace(`/create-campaign/${campaign.id}/creatives`)
              }}
            >
              Back
            </Button>
            <Spacer />
          </CampaignCreationFooter>
        }
      >
        <Layout>
          <CampaignCreationWrapper>
            <div className="CampaignCheckout__content">
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                // message={(duration) =>
                //   `Your ads will display for these ${duration} days`
                // }
                message={(duration: any) => {
                  return remainingdays ? (
                    !!remainingdays && remainingdays > 0 && (
                      <>
                        <p className="CampaignCheckout__add_display">
                          {' '}
                          Your ads will display for these {duration}{' '}
                          {duration <= 1 ? 'day' : 'days'}
                        </p>
                        <br />
                        <ImpressionValidator days={Math.round(remainingdays)} />
                      </>
                    )
                  ) : (
                    <p className="CampaignCheckout__add_display">
                      {' '}
                      Your ads will display for these {duration}{' '}
                      {duration <= 1 ? 'day' : 'days'}
                    </p>
                  )
                }}
              />
            </div>
            {campaign ? (
              <SummaryCart
                campaign={campaign}
                checkout={checkout}
                daysRemaining={setRemainingDays}
              />
            ) : (
              <Loading />
            )}
          </CampaignCreationWrapper>
          {/* {remainingdays
            ? remainingdays > 0 && (
                <ImpressionValidator days={Math.round(remainingdays)} />
              )
            : ''} */}
        </Layout>
      </MaxHeightContainer>
    </div>
  )
}
