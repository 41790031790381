import { Button, Modal, Text } from '@eltoro-ui/components'
import {
  faExclamationCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputBox } from 'Components'
import ErrorMsg from 'Pages/PasswordSetup/components/ErrorMsg'
import React from 'react'

const AddAddressModal = ({
  setpostalCode,
  addressName,
  setaddressName,
  setfullAddress,
  fullAddress,
  postalCode,
  setaddAddressModal,
  AddressId,
  onSubmit,
  updateAddress,
  setupdateAddress,
}: {
  addressName: string
  setpostalCode: (state: string) => void
  postalCode: string
  setaddressName: (state: string) => void
  setfullAddress: (state: string) => void
  fullAddress: string
  setaddAddressModal: (state: boolean) => void
  AddressId: number | undefined
  onSubmit: () => void
  updateAddress?: boolean
  setupdateAddress: (state: boolean) => void
}) => {
  return (
    <Modal
      // header="Name and Tag your Target Audience"
      offClick={() => {
        setaddAddressModal(false)
        setupdateAddress(false)
      }}
      className="SaveAudienceModal"
    >
      <div className="OnboardingModal__welcome ListingModalTagBg SaveAudiencePosition">
        <div className="OnboardingModal__welcomeHeader CampaignSetup__modalSpace">
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            className="CampaignSetup__modalIcon"
            onClick={() => {
              setaddAddressModal(false)
              setupdateAddress(false)
            }}
          />
          <div style={{ textAlign: 'center', marginBottom: '9px' }}>
            <Text on="white" weight="normal" size="xxxl" textAlign="center">
              Update Address
            </Text>
          </div>
          <br />
          <div
            style={{
              // width: '280px',
              margin: '0 30px',
              textAlign: 'center',
            }}
          >
            <div style={{ textAlign: 'left' }}>
              <Text
                on="grey-500"
                size="m"
                UNSAFE_style={{ marginBottom: '0.5rem' }}
              >
                {' '}
              </Text>
              <div className="addModalFields">
                <InputBox
                  placeHolder="Enter Name"
                  border="1px solid "
                  value={addressName}
                  wdt="100%"
                  Height="50px"
                  paddingLeft="10px"
                  disabled
                />
              </div>
              <div className="addModalFields">
                <InputBox
                  placeHolder="Enter Address"
                  border="1px solid "
                  value={fullAddress}
                  isError={!fullAddress && updateAddress}
                  wdt="100%"
                  Height="50px"
                  paddingLeft="10px"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setfullAddress(e.target.value)
                  }}
                />
                {!fullAddress && updateAddress && (
                  <ErrorMsg
                    title="Please enter right name"
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )}
              </div>

              <div className="addModalFields">
                <InputBox
                  placeHolder="Enter Zip code"
                  border="1px solid "
                  value={postalCode}
                  wdt="100%"
                  // isError={!postalCode && updateAddress}
                  Height="50px"
                  paddingLeft="10px"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setpostalCode(e.target.value)
                  }}
                />
                {/* {!postalCode && updateAddress && (
                  <ErrorMsg
                    title="Please enter Zip code"
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                  />
                )} */}
              </div>
              <Button
                kind="primary"
                size="xl"
                width="70%"
                weight="bolder"
                UNSAFE_style={{
                  margin: '2rem auto 0 auto',
                  marginTop: '25px',
                }}
                onClick={() => {
                  onSubmit()
                }}
              >
                Update Address
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddAddressModal
