import React, { FC } from 'react'
import { Section } from 'Components'
import './CampaignType.scss'
import { PieChart } from 'react-minimal-pie-chart'
import { Text } from '@eltoro-ui/components'
import nodata from '../../../../assets/Images/nodata.png'

type CompaignDataType = {
  banner: number
  video: number
}

export const CompaignType: FC<{ data: CompaignDataType }> = ({ data }) => {
  const compaignTypeDataSchema = {
    pieData: [
      { title: 'Banner Ad', value: data?.banner || 0, color: '#85E2FF' },
      { title: 'Video', value: data?.video || 0, color: '#FF6D03' },
    ],
    context: [
      { value: data?.video || 0, label: 'Video', backgroundColor: '#FF6D03' },
      {
        value: data?.banner || 0,
        label: 'Banner Ad',
        backgroundColor: '#85E2FF',
      },
    ],
  }
  const CampaignData = Object.values(data).length
  //  const CampaignType = data.
  return (
    <Section
      title="Campaign Type"
      UNSAFE_className_text="campaignType__title"
      actions="Last 30 days"
      UNSAFE_className_container="campaignType__section"
    >
      <div className="campaignType__campaign-type">
        <div className="campaignType__campaign-type-text">
          {compaignTypeDataSchema.context.map((value) => {
            return (
              <div
                key={value.value}
                className="campaignType__context_containers"
              >
                <div
                  className="campaignType__in-progress-campaign-circle"
                  style={{
                    backgroundColor: value.backgroundColor,
                  }}
                />
                <Text on="white" UNSAFE_className="campaignType__label">
                  <span className="campaignType__value">
                    {Math.round(value.value)}%
                  </span>{' '}
                  - {value.label}
                </Text>
              </div>
            )
          })}
        </div>
        {CampaignData === 0 ? (
          <div className="noData-campaign-type">
            <img style={{ width: '100px' }} src={nodata} alt="nodata" />
            <Text on="tertiary-300" UNSAFE_className="noDataText">
              No Data Found
            </Text>
          </div>
        ) : (
          <PieChart
            className="campaignType__pieChart"
            lineWidth={25}
            labelPosition={0}
            rounded
            data={compaignTypeDataSchema.pieData}
          />
        )}
      </div>
    </Section>
  )
}
