import React, { useState } from 'react'
import './DateRangePicker.scss'
import { Spacer } from '../Spacer'
import { CalendarInput, DateTargetToggle } from './components'

export const DateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  min,
  max,
  spacerBoolean = true,
}: {
  startDate?: Date
  endDate?: Date
  onStartDateChange?: (newStartDate: Date) => any
  onEndDateChange?: (newEndDate: Date) => any
  min?: Date
  max?: Date
  spacerBoolean?: boolean
}) => {
  const [target, setTarget] = useState<'start' | 'end'>()
  const handleStartDateChange = (newStartDate: Date) => {
    if (onStartDateChange) {
      onStartDateChange(newStartDate)
    }
  }
  const handleEndDateChange = (newEndDate: Date) => {
    if (onEndDateChange) {
      onEndDateChange(newEndDate)
    }
  }

  return (
    <div className="DateRangePicker">
      {/* <DateTargetToggle
        startDate={startDate}
        endDate={endDate}
        inputTarget={target || 'start'}
        onInputTargetChange={setTarget}
      /> */}
      {spacerBoolean && <Spacer />}
      <CalendarInput
        startDate={startDate}
        endDate={endDate}
        editableDates={spacerBoolean}
        inputTarget={target || 'start'}
        onStartDateChange={handleStartDateChange}
        onEndDateChange={handleEndDateChange}
        onInputTargetChange={setTarget}
        min={(!!min && new Date(min.toDateString())) || undefined}
        max={(!!max && new Date(max.toDateString())) || undefined}
      />
    </div>
  )
}
