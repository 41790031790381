import { ParsedResType } from 'types'
import { getAuthHeader } from 'Tools'
import * as Sentry from '@sentry/react'

import toast from 'react-hot-toast'
import { AxiosResponse } from 'axios'
import axios from './axios-default'
import { mockApi } from './Mock/mockApi'
// const root =
//   process.env.NODE_ENV === 'development'
//     ? process.env.REACT_APP_PROXY
//     : process.env.REACT_APP_API
const root = process.env.REACT_APP_API

const baseUrl = `${root}/api/v1/beew`

const resParser = (res: AxiosResponse<string | Object>): ParsedResType => {
  const parsedData =
    typeof res.data === 'string' ? JSON.parse(res.data) : res.data
  return { data: parsedData }
}
if (process.env.NODE_ENV !== 'production' && !process.env.REACT_APP_IS_LIVE) {
  mockApi()
}

function SentryError(msg: any) {
  Sentry.captureException(msg)
}
const errorHandler = (err: any) => {
  SentryError(err)
  if (err.response.status === 422) {
    toast.error('Validation error')
  }
  if (err.response.status === 403) {
    return err.response
  }
  if (err.response) {
    if (err.response.data) {
      return err.response.data
    }
    return err.response
  }
  return err
}
function headers(token: string | undefined) {
  const myHeaders = new Headers()
  myHeaders.append('Authorization', `Bearer ${token}`)
  myHeaders.append('Content-Type', 'application/json')
}

export const keycloakLogin = {
  post: async (path: string, body: any) => {
    // const body = { name: 'admin' }
    const url = `http://35.80.134.236:8080${path}`
    return axios
      .post(url, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Cookie: 'KEYCLOAK_LOCALE=en',
        },
      })
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => errorHandler(err))
  },
}
export const keycloakRest = {
  get: async (path: string, params?: object) => {
    return axios
      .get(`${baseUrl}${path}`, {
        params,
        headers: getAuthHeader(),
      })
      .then((res) => resParser(res))
      .catch((err) => errorHandler(err))
  },
  post: async (path: string, body: Object, token?: string | undefined) => {
    const url = `${baseUrl}${path}`

    return axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => errorHandler(err))
  },
  patch: async (path: string, body: object, token?: string) => {
    const url = `http://35.80.134.236:8080${path}`
    return axios
      .patch(url, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => errorHandler(err))
  },
}

export const Req = {
  get: async (path: string, token?: string, params?: object) => {
    return axios
      .get(`${baseUrl}${path}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => resParser(res))
      .catch((err) => errorHandler(err))
  },
  post: async (path: string, body: any) => {
    const url = `${baseUrl}${path}`

    return axios
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => errorHandler(err))
  },
  patch: async (path: string, body: object, token?: string) => {
    const url = `${baseUrl}${path}`
    return axios
      .patch(url, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => errorHandler(err))
  },
}

const requests = {
  get: async (path: string, params?: object, ourRequest?: any) => {
    let config
    if (ourRequest === undefined) {
      config = {
        params,
        headers: getAuthHeader(),
      }
    } else {
      config = {
        params,
        headers: getAuthHeader(),
        cancelToken: ourRequest.token,
      }
    }

    return axios
      .get(`${baseUrl}${path}`, {
        ...config,
      })
      .then((res) => resParser(res))
      .catch((err) => errorHandler(err))
  },
  delete: async (path: string, body?: any) => {
    const url = `${baseUrl}${path}`

    return axios
      .delete(url, { headers: getAuthHeader() })
      .then((res) => resParser(res))
      .catch((err) => errorHandler(err))
  },
  post: async (
    path: string,
    body: object | string | undefined,
    upload?: Boolean,
    ourRequest?: any,
  ) => {
    const url = `${baseUrl}${path}`
    let config
    if (ourRequest === undefined) {
      config = {
        body,
        headers: getAuthHeader(upload),
      }
    } else {
      config = {
        body,
        headers: getAuthHeader(upload),
        cancelToken: ourRequest.token,
      }
    }

    return axios
      .post(url, body, {
        ...config,
      })
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => errorHandler(err))
  },
  patch: async (path: string, body: object) => {
    const url = `${baseUrl}${path}`
    return axios
      .patch(url, body, { headers: getAuthHeader() })
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => errorHandler(err))
  },
  put: async (path: string, body: object) => {
    const url = `${baseUrl}${path}`
    return axios
      .put(url, body, { headers: getAuthHeader() })
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => errorHandler(err))
  },
}

const http = requests

export default http
