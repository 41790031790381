/* eslint-disable */
import React, { useState } from 'react'
import { Button, GridBox, Text } from '@eltoro-ui/components'
import './uploadcsvModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faUpload } from '@fortawesome/pro-solid-svg-icons'
import { Uploader } from 'Components/Uploader'
import {
  createAndAttachCreatives,
  uploadCSV,
} from 'Requests/Request_Methods/campaignMethods'
import { CreativeType, FileUploadType } from 'types'
import toast, { Toaster } from 'react-hot-toast'
import ShowExample from '../../../../../../assets/Images/ShowExample.png'
import cross from '../../../../../../assets/Images/cross.png'
import creativePlaceholders from '../UploadBannerModal/CreativePlaceholders.json'
import { UploadAreaLayout, UploadTypes } from '../UploadAreaLayout'

export const UploadCSVModal = ({
  onClickOutside,
  onSubmit,
  campaignId,
}: {
  onClickOutside: () => void
  onSubmit: (files: FileUploadType) => void
  campaignId: any
}) => {
  //   const { campaignId } = useParams<{ campaignId: string }>()
  const [csvFile, setCsvFile] = useState(null)
  const [file, setfile] = useState('')
  const [id, setId] = useState('')
  const [openImage, setOpenImage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const Form = new FormData()

  const onSubmitFile = (e: any) => {
    if (e?.acceptedFiles.length > 0) {
      setfile(e?.acceptedFiles[0])
      setId(campaignId.id)
      // const file = e.acceptedFiles[0]
      // Form.append('files', file)
      // Form.append('campaign_id', campaignId.id)
      setCsvFile(e.acceptedFiles[0].name)
      // postCSV(Form)
    } else {
      setCsvFile(null)
    }
  }
  const postCSV = () => {
    setIsLoading(true)
    Form.append('files', file)
    Form.append('campaign_id', id)
    uploadCSV(Form)
      .then((res) => {
        if (res.detail) {
          toast.error('Invalid csv')
          // setTimeout(() => onClickOutside(), 0)
        }
        setIsLoading(false)
        setTimeout(() => onClickOutside(), 200)
      })
      .catch(() => {
        toast.error('Invalid csv')
        setIsLoading(false)

        // setTimeout(() => onClickOutside(), 2000)
      })
    // setTimeout(() => {
    //   onClickOutside()
    // }, 3000)
    // onClickOutside()
  }
  return (
    <div className="UploadBannerModal uploadcsvModal">
      {/* <GridBox justifyItems="flex-start" gap="0.5rem"> */}
      <Toaster position="top-center" />
      <img
        className="uploadcsvModal__cross"
        onClick={onClickOutside}
        src={cross}
        alt="cross"
      />

      <p className="uploadcsvModal__StepOne_heading">STEP 1</p>

      <b className="uploadcsvModal__StepOne_sub_heading">
        {' '}
        Make sure your CSV is formatted correctly
      </b>

      <i className="uploadcsvModal__StepOne_description">
        Notice: Our system will only process addresses and zip codes. All other
        columns are ignored by our system.
      </i>

      {/* <Button kind="text" iconLeft={<FontAwesomeIcon icon={faDownload} />}>
          Download Banner Template
        </Button> */}
      {/* </GridBox> */}
      <div className="uploadcsvModal__points">
        <ol type="a">
          <li>Address Column with address as column header </li>
          <li>
            Zip Code Column with zip as column header (Zip needs to be broken
            down into a 5-digit zip and must preserve 0 in zip codes beginning
            with the number 0)
          </li>
          <li>
            Must be a text or CSV file (save as a CSV UTF-8(comma
            delimited)(*.csv))
          </li>
          <li>No commas or special characters</li>
          <li>Be sure to provide the proper target list for your campaign</li>
        </ol>
      </div>
      <a
        // className="example_img_container"
        style={{ textDecoration: 'none' }}
        download="prospects"
        href="data:text/csv;base64,YWRkcmVzcyx6aXAK"
        // href="data:text/csv;base64,Zmlyc3QgbmFtZSxsYXN0IG5hbWUsYWRkcmVzcyx6aXAscGhvbmUgbnVtYmVyLGVtYWlsLGNpdHkK"
      >
        <Button
          type="button"
          kind="default"
          size="s"
          UNSAFE_className="uploadcsvModal__example"
          onClick={() => {
            setOpenImage(!openImage)
          }}
        >
          Download Template
        </Button>
      </a>
      {/* {openImage ? ( */}
      {/* <a
        className="example_img_container"
        download="prospects"
        href="data:text/csv;base64,YWRkcmVzcyx6aXAK"
        // href="data:text/csv;base64,Zmlyc3QgbmFtZSxsYXN0IG5hbWUsYWRkcmVzcyx6aXAscGhvbmUgbnVtYmVyLGVtYWlsLGNpdHkK"
      >
        <img src={ShowExample} alt="" style={{ height: '90px' }} />
      </a> */}
      {/* ) : (
        ''
      )} */}
      {/* <div className="UploadBannerModal__hl" />
      <GridBox gap="0.5rem">
        <Text tag="div" on="white" size="xxl">
          STEP 2: <b>Consider Using Multiple Banner Sizes</b>
        </Text>
        <GridBox gap="2rem" gridTemplateColumns="1fr 1fr">
          {creativePlaceholders.map((creative) => (
            <GridBox
              key={creative.id}
              gridTemplateColumns="min-content auto"
              alignItems="center"
              gap="1rem"
            >
              <div
                className="UploadBannerModal__creative-placeholder"
                style={{ height: creative.height, width: creative.width }}
              />
              <Text on="white">
                <b>{creative.actualDimensions}</b>
                {creative.text}
                {!!creative.textSecondary && <br />}
                {!!creative.textSecondary && creative.textSecondary}
              </Text>
            </GridBox>
          ))}
        </GridBox>
      </GridBox> */}
      <div className="UploadBannerModal__hl" />
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <p className="uploadcsvModal__StepOne_heading">STEP 2</p>

        <b className="uploadcsvModal__StepOne_sub_heading">Upload Your List</b>
        {/* {csvFile && <p>{csvFile}</p>} */}
      </GridBox>
      <div
        style={
          {
            // padding: '35px 54px',
            // background: ' #EEEFF0',
            // marginBottom: '1rem',
          }
        }
      >
        <Uploader
          type="csv"
          onDropFile={(files: FileUploadType) => onSubmitFile(files)}
        >
          <UploadAreaLayout uploadType={UploadTypes.csv} />
          {/* <div className="uploadcsvModal__upload">
            <FontAwesomeIcon icon={faUpload} />
            <Text on="success-200" size="s">
              Upload your file here
            </Text>
          </div> */}
        </Uploader>
      </div>
      <div className="UploadBannerModal__hl" style={{ marginBottom: '3rem' }} />
      <div className="UploadBannerModal__footer">
        <button
          type="button"
          className="UploadBannerModal__cancel-buttons"
          onClick={onClickOutside}
        >
          Cancel
        </button>
        <Button
          UNSAFE_style={{ marginLeft: '2rem' }}
          UNSAFE_className=" upload_video_confirm"
          kind="primary"
          onClick={postCSV}
          disabled={!csvFile || isLoading}
          loading={isLoading}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
