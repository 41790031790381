import { Button } from '@eltoro-ui/components'
import { faToggleOff } from '@fortawesome/pro-duotone-svg-icons'
import { faToggleOn } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loading } from 'Components'
import { NONAME } from 'dns'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import {
  getNotificationSettings,
  pushNotificationSetting,
  subscribeTopic,
} from 'Requests'
import './NotificationSetting.scss'

const NotificationSetting = () => {
  const [notificationSetting, setNotificationSetting] = useState<any>([])
  const [isClicked, setIsClicked] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [toggleNotification, setToggleNotification] = useState<any>({
    email: 0,
    product: 0,
    system: 0,
  })
  const dummyNotification = [
    {
      status: 0,
      device: 'web',
      type: 'email',
    },
    {
      status: 0,
      device: 'web',
      type: 'product',
    },
    {
      status: 0,
      device: 'web',
      type: 'system',
    },
  ]
  useEffect(() => {
    getNotificationSettings().then((res: any) => {
      if (res.data.notifications.length > 0) {
        setNotificationSetting(res.data.notifications)
        setToggleNotification({
          email: res.data.notifications[0].status,
          product: res.data.notifications[1].status,
          system: res.data.notifications[2].status,
        })
      } else {
        setNotificationSetting(dummyNotification)
        setToggleNotification({
          email: dummyNotification[0].status,
          product: dummyNotification[1].status,
          system: dummyNotification[2].status,
        })
      }
    })
  }, [])

  // function setNotificationToggle(items: string, value: number) {
  //   setToggleNotification({ ...toggleNotification, items: !value })
  // }

  function turnOffNotification() {
    setToggleNotification({
      email: 0,
      product: 0,
      system: 0,
    })
    const user = localStorage.getItem('firebase_notification')
    if (user) {
      subscribeTopic(user, 0)
    }
    setIsClicked(true)
  }

  function callNotificationToggle() {
    setLoading(true)
    pushNotificationSetting({
      settings: [
        { type: 'email', status: toggleNotification.email },
        { type: 'product', status: toggleNotification.product },
        { type: 'system', status: toggleNotification.system },
      ],
      device: 'web',
    }).then(() => {
      toast.success('Saved Successfully!')
      const user = localStorage.getItem('firebase_notification')
      setIsClicked(false)
      if (user) {
        subscribeTopic(user, toggleNotification.system)
      }
    })
    setLoading(false)
  }

  function getNotificationTitle(notif: string) {
    // switch (notif) {
    //   case 'system':
    //     return 'System notifications'
    //     break
    //   case 'email':
    //     return 'All email notifications'
    //     break
    //   case 'product':
    //     return 'Product and Campaign performance notifications'
    //     break

    //   default:
    //     break
    // }
    let val = ''
    if (notif === 'system') {
      val = 'System notifications'
    } else if (notif === 'email') {
      val = 'All email notifications'
    } else if (notif === 'product') {
      val = 'Product and Campaign performance notifications'
    }

    return val
  }

  return (
    <div className="notificationSetting">
      {notificationSetting.length ? (
        <>
          <h3>Notification Settings</h3>

          <div className="notificationSetting__container">
            <div className="notificationSetting__containerItem">
              <p
                className="notificationSetting__containerItemOff"
                style={{
                  opacity: toggleNotification.email === 0 ? '1' : '0.6',
                }}
              >
                Off
              </p>
              <FontAwesomeIcon
                className="notificationSetting__containerItemIcon"
                size="1x"
                color={toggleNotification.email === 1 ? '#FFB708' : 'lightgray'}
                icon={toggleNotification.email === 1 ? faToggleOn : faToggleOff}
                // icon={faToggleOff}
                onClick={() => {
                  setIsClicked(true)
                  setToggleNotification({
                    ...toggleNotification,
                    email: toggleNotification.email === 0 ? 1 : 0,
                  })
                  // setNotificationToggle(item.type, item.status)
                }}
              />
              <p
                className="notificationSetting__containerItemOn"
                style={{
                  color: toggleNotification.email === 1 ? '#ffab03' : 'gray',
                  opacity: toggleNotification.email === 1 ? '1' : '0.7',
                }}
              >
                On
              </p>
              <p className="notificationSetting__containerItemContent">
                {getNotificationTitle(notificationSetting[0].type)}
              </p>
            </div>

            <div className="notificationSetting__containerItem">
              <p
                className="notificationSetting__containerItemOff"
                style={{
                  opacity: toggleNotification.product === 0 ? '1' : '0.6',
                }}
              >
                Off
              </p>
              <FontAwesomeIcon
                className="notificationSetting__containerItemIcon"
                size="1x"
                // color={toggleNotification.product?"#FFB708":''}
                color={
                  toggleNotification.product === 1 ? '#FFB708' : 'lightgray'
                }
                icon={
                  toggleNotification.product === 1 ? faToggleOn : faToggleOff
                }
                // icon={faToggleOff}
                onClick={() => {
                  setIsClicked(true)
                  setToggleNotification({
                    ...toggleNotification,
                    product: toggleNotification.product === 0 ? 1 : 0,
                  })
                }}
              />
              <p
                className="notificationSetting__containerItemOn"
                style={{
                  color: toggleNotification.product === 1 ? '#ffab03' : 'gray',
                  opacity: toggleNotification.product === 1 ? '1' : '0.7',
                }}
              >
                On
              </p>
              <p className="notificationSetting__containerItemContent">
                {getNotificationTitle(notificationSetting[1].type)}
              </p>
            </div>

            <div className="notificationSetting__containerItem">
              <p
                className="notificationSetting__containerItemOff"
                style={{
                  opacity: toggleNotification.system === 0 ? '1' : '0.6',
                }}
              >
                Off
              </p>
              <FontAwesomeIcon
                className="notificationSetting__containerItemIcon"
                size="1x"
                color={
                  toggleNotification.system === 1 ? '#FFB708' : 'lightgray'
                }
                icon={
                  toggleNotification.system === 1 ? faToggleOn : faToggleOff
                }
                // icon={faToggleOff}
                onClick={() => {
                  setIsClicked(true)
                  setToggleNotification({
                    ...toggleNotification,
                    system: toggleNotification.system === 0 ? 1 : 0,
                  })
                  // setNotificationToggle(item.type, item.status)
                }}
              />
              <p
                className="notificationSetting__containerItemOn"
                style={{
                  color: toggleNotification.system === 1 ? '#ffab03' : 'gray',
                  opacity: toggleNotification.system === 1 ? '1' : '0.7',
                }}
              >
                On
              </p>
              <p className="notificationSetting__containerItemContent">
                {getNotificationTitle(notificationSetting[2].type)}
              </p>
            </div>

            <div className="notificationSetting__containerBottom">
              <button
                className="notificationSetting__button"
                type="button"
                onClick={() => turnOffNotification()}
                // disabled={!isClicked}
              >
                Turn off all notifications
              </button>
              <Button
                type="submit"
                kind="primary"
                size="l"
                UNSAFE_style={{ margin: '0 1rem 1rem 0', fontWeight: 'bold' }}
                onClick={() => callNotificationToggle()}
                disabled={!isClicked}
                loading={loading}
              >
                {loading ? '' : 'Save'}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default NotificationSetting
