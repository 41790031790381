import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { setSidebarLens } from 'Redux/actions'
import { ProspectFinderSelection } from './components'
import {
  AdvertiseToAllInMarket,
  DigitalFarming,
  ExpandYourMarketing,
  MyListingsLens,
  SellYourListingFirst,
  BeatTheMarket,
  TalkToSphereLens,
} from './lenses'
import { LensSidebar } from './lenses/components'
import './ProspectActivityPage.scss'

export const ProspectActivityPage = () => {
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(setSidebarLens(true))
  }, [location.pathname])

  return (
    <div className="ProspectActivityPage">
      <Switch>
        <Route exact path={path}>
          <LensSidebar>
            <ProspectFinderSelection />
          </LensSidebar>
          {/* <div className="theMap">
            <div className="theMap__splash-screen">
              <ProspectActivitySplash />
            </div>
            <Map
              features={[]}
              locations={[]}
              marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
              popup={(f) => <MapPopup feature={f} />}
              tooltip={(f) => <MapToolTip feature={f} />}
            />
          </div> */}
        </Route>
        <Route path={`${path}/my-listings`}>
          <MyListingsLens />
        </Route>
        <Route path={`${path}/grow-your-sphere`}>
          <ExpandYourMarketing />
        </Route>
        {/* <Route path={`${path}/comparable-listings`}>
          <ComparableListingsLens />
        </Route> */}
        <Route path={`${path}/sell-your-listing-faster`}>
          <SellYourListingFirst />
        </Route>
        <Route path={`${path}/beat-the-market`}>
          <BeatTheMarket />
        </Route>

        <Route path={`${path}/humble-brag`}>
          <DigitalFarming />
        </Route>
        <Route path={`${path}/advertise-to-all-in-market`}>
          <AdvertiseToAllInMarket />
        </Route>

        <Route path={`${path}/talk-to-your-sphere`}>
          <TalkToSphereLens />
        </Route>

        {/* <Route path={`${path}/mover-lifecycle`}>
          <MoverLifecycleLens />
        </Route> */}
        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  )
}
