import React from 'react'
import './UploadAreaLayout.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-solid-svg-icons'
import { Button, GridBox, Text } from '@eltoro-ui/components'
import uploadIcon from '../../../../../../assets/Images/icon-shape.png'
// import orIcon from '../../../../../../assets/Images/Group 298.png'

interface UploadRestrictions {
  fileSize: string
  fileTypes: string
}

interface UploadDetails {
  header: string
  creativeLibrary: boolean
  uploadRestrictions?: UploadRestrictions
}

/* eslint-disable-next-line no-shadow */
export enum UploadTypes {
  mailerFront,
  mailerBack,
  banner,
  video,
  csv,
}

function uploadDetails(type: UploadTypes): UploadDetails {
  switch (type) {
    case UploadTypes.mailerFront: {
      return {
        header: 'Upload Front of Mailer',
        creativeLibrary: true,
      }
    }
    case UploadTypes.mailerBack: {
      return {
        header: 'Upload Back of Mailer',
        creativeLibrary: true,
      }
    }
    case UploadTypes.banner: {
      return {
        header: 'Upload your Banner Creative file(s) here',
        creativeLibrary: true,
        // uploadRestrictions: {
        //   fileSize: '100KB',
        //   fileTypes: 'jpg, gif, png',
        // },
      }
    }
    case UploadTypes.video: {
      return {
        header: 'Upload your Video Creative file(s) here',
        creativeLibrary: false,
        // uploadRestrictions: {
        //   fileSize: '100MB',
        //   fileTypes: 'avi, mp4, quicktime',
        // },
      }
    }
    case UploadTypes.csv: {
      return {
        header: 'Upload your file here',
        creativeLibrary: false,
        // uploadRestrictions: {
        //   fileSize: '100MB',
        //   fileTypes: 'avi, mp4, quicktime',
        // },
      }
    }

    default: {
      return {
        header: 'Upload Front of Mailer',
        creativeLibrary: true,
      }
    }
  }
}

export const UploadAreaLayout = ({
  uploadType,
}: // openCreativeGen,
{
  uploadType: UploadTypes
  // openCreativeGen?: () => void
}) => {
  const details = uploadDetails(uploadType)
  return (
    <div
      className="UploadAreaLayout"
      style={{
        marginBottom: uploadType !== 4 && uploadType !== 3 ? '21px' : '',
      }}
    >
      {/* <FontAwesomeIcon icon={faUpload} /> */}
      <img style={{ marginTop: '-46px' }} src={uploadIcon} alt="upload-icon" />

      <Text
        on="white"
        weight="bold"
        UNSAFE_className="UploadAreaLayout__title"
        UNSAFE_style={{
          color: '#277B94',
          fontFamily: 'Open Sans',
          // marginBottom: '13px',
        }}
      >
        {details.header}
      </Text>
      {details.uploadRestrictions && (
        <GridBox justifyItems="center">
          <Text on="white" size="s">
            Max file size - <b>{details.uploadRestrictions.fileSize}</b>
          </Text>
          <Text on="white" size="s">
            Accepted file types - <b>{details.uploadRestrictions.fileTypes}</b>
          </Text>
        </GridBox>
      )}
      {/* {details.creativeLibrary && (
       
      )} */}
    </div>
  )
}
