import React from 'react'
import { Button, FlexBox, GridBox, Text } from '@eltoro-ui/components'
import { faEdit, faUpload } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './UploadOrBuild.scss'

export const UploadOrBuild = ({
  onClickUpload,
  onClickBuild,
}: {
  onClickUpload?: () => void
  onClickBuild?: () => void
}) => {
  return (
    <div className="UploadOrBuild">
      <GridBox
        gridTemplateColumns="1fr 1fr"
        gap="1rem"
        alignItems="center"
        // justifyItems="center"
        justifyContent="space-between"
        UNSAFE_style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
        UNSAFE_className="Upload_your_own"
      >
        <GridBox gap="0.25rem" UNSAFE_style={{ padding: '0rem 2rem' }}>
          <Text
            on="white"
            weight="bold"
            textAlign="center"
            UNSAFE_className="UploadOrBuild__Upload_creative_Mheading"
            // UNSAFE_style={{ height: '22px' }}
          >
            Upload Your Own
          </Text>
          <Text
            on="white"
            size="s"
            UNSAFE_className="UploadOrBuild__Upload_creative_heading"
          >
            Upload your creative
          </Text>
        </GridBox>
        <div className="UploadOrBuild__action-button">
          <Button
            kind="primary"
            UNSAFE_className="UploadOrBuild__action-button"
            size="l"
            onClick={onClickUpload}
            // iconLeft={<FontAwesomeIcon icon={faUpload} />}
          >
            Upload Creative
          </Button>
        </div>
      </GridBox>
      <FlexBox justifyContent="center" alignItems="center">
        <Text
          on="white"
          weight="bold"
          UNSAFE_className="UploadOrBuild__creative_or"
        >
          Or
        </Text>
      </FlexBox>
      <GridBox
        UNSAFE_className="Upload_your_own"
        gridTemplateColumns="1fr 1fr"
        gap="1rem"
        alignItems="center"
        justifyContent="space-between"
        UNSAFE_style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          // justifyContant: 'space-between',
        }}
      >
        <GridBox gap="0.25rem" UNSAFE_style={{ padding: '0rem 2rem' }}>
          <Text
            on="white"
            weight="bold"
            textAlign="center"
            UNSAFE_className="UploadOrBuild__Upload_creative_Mheading"
            // UNSAFE_style={{ height: '22px' }}
          >
            Use Our Ad Builder
          </Text>
          <Text
            on="white"
            size="s"
            UNSAFE_className="UploadOrBuild__Upload_creative_heading"
          >
            Use our builder to build your creative
          </Text>
        </GridBox>
        <div className="UploadOrBuild__action-button">
          <Button
            UNSAFE_className="UploadOrBuild__action-button"
            kind="primary"
            size="l"
            onClick={onClickBuild}
            // iconLeft={<FontAwesomeIcon icon={faEdit} />}
          >
            Use Ad Builder
          </Button>
        </div>
      </GridBox>
    </div>
  )
}
