import React, { FC } from 'react'
import { Section } from 'Components'
import './ProgressCampaign.scss'
import { Text } from '@eltoro-ui/components'
import { Bar } from 'react-chartjs-2'
import { PieChart } from 'react-minimal-pie-chart'
import { verticalStackingOptions } from '../../barOptions'
import nodata from '../../../../assets/Images/nodata.png'

type ProgressCampaignDataType = {
  open: number
  processed: number
}

export const ProgressCampaign: FC<{ data: ProgressCampaignDataType }> = ({
  data,
}) => {
  const compaignTypeDataSchema = {
    pieData: [
      { title: 'Pending', value: data?.open || 0, color: '#E3E0E0' },
      { title: 'Processed', value: data?.processed || 0, color: '#20C412' },
    ],
    context: [
      {
        value: data?.open || 0,
        label: 'Pending',
        backgroundColor: '#E3E0E0',
      },
      {
        value: data?.processed || 0,
        label: 'Processed',
        backgroundColor: '#20C412',
      },
    ],
  }
  const isCompaignTypeDataSchema = data

  // const verticalData = {
  //   datasets: [
  //     {
  //       label: 'Pending',
  //       data: [data?.pending || 0],
  //       backgroundColor: '#44C2E6',
  //       hoverBackgroundColor: '#44C2E6',
  //     },
  //     {
  //       label: 'Processed',
  //       data: [data?.processed || 0],
  //       backgroundColor: '#007B94',
  //       hoverBackgroundColor: '#007B94 ',
  //     },
  //   ],
  // }

  return (
    <Section
      title="In-Progress Campaign"
      UNSAFE_className_container="CampaignDashboard"
      UNSAFE_className_text="CampaignDashboard__title"
    >
      <div className="CampaignDashboard__in-progress-campaign-body">
        <div className="CampaignDashboard__in-progress-campaign-bar">
          {/* <Bar
            data={verticalData}
            options={verticalStackingOptions}
            height={200}
            width={200}
          /> */}
          {isCompaignTypeDataSchema?.processed === 0 &&
          isCompaignTypeDataSchema?.open === 0 ? (
            <div
              style={{ flexDirection: 'column' }}
              className="nodata-inprogress"
            >
              <img style={{ width: '100px' }} src={nodata} alt="nodata" />
              <Text on="tertiary-300" UNSAFE_className="noDataText">
                No Data Found
              </Text>
            </div>
          ) : (
            <PieChart
              // style={{ paddingRight: '2rem', height: '250px', width: '250px' }}
              className="CampaignDashboard__inProgressPieChart"
              lineWidth={45}
              labelPosition={0}
              data={compaignTypeDataSchema.pieData}
            />
          )}
        </div>
        <div className="CampaignDashboard__in-progress-campaign-titles">
          {compaignTypeDataSchema.context.map((value) => {
            return (
              <div className="progress_btn">
                <div
                  key={value.label}
                  className="campaignType__context_container"
                >
                  <div
                    className="CampaignDashboard__in-progress-campaign-circle"
                    style={{
                      backgroundColor: value.backgroundColor,
                    }}
                  />
                  <Text
                    UNSAFE_className="campaignType__label"
                    size="m"
                    on="white"
                  >
                    <span className="campaignType__value">
                      {' '}
                      {Math.round(value.value)}
                    </span>{' '}
                    - {value.label}
                  </Text>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Section>
  )
}
