import { Button, Modal, Text } from '@eltoro-ui/components'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'

const GoBackModal = ({
  setgoBackModal,
  text,
  sub_text,
}: {
  setgoBackModal: (val: boolean) => void
  text: string
  sub_text: string
}) => {
  const history = useHistory()
  return (
    <Modal>
      <div className="OnboardingModal-welcome goBackModal">
        <div className="OnboardingModal-welcomeHeader SummaryCart__submitModalContent">
          <div style={{ textAlign: 'center' }}>
            <Text
              on="white"
              size="xxxl"
              UNSAFE_className="goBackModal__text"
              UNSAFE_style={{ lineHeight: '32px' }}
              textAlign="center"
            >
              {text}
            </Text>
          </div>
          <Text
            on="white"
            size="xxxl"
            UNSAFE_style={{ lineHeight: '32px' }}
            textAlign="center"
            UNSAFE_className="goBackModal__sub_text"
          >
            {sub_text}
          </Text>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            kind="primary"
            size="xl"
            width="50%"
            weight="bold"
            onClick={() => {
              setgoBackModal(false)
              history.push('/my-sphere')
            }}
          >
            Back to contacts
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default GoBackModal
