import React, { ReactNode, CSSProperties } from 'react'
import './HeaderItem.scss'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'

interface HeaderItemCommonPropsType {
  children?: ReactNode
  icon?: JSX.Element
  UNSAFE_CLASSNAME?: string
  UNSAFE_STYLE?: CSSProperties
  UNSAFE_CLASSNAME_NAVLINK_ITEM?: string
  iconClassname?: string
  childrenClassName?: string
}

interface HeaderItemWithLinkPropsType {
  to: string
  onClick?: any
}

interface HeaderItemWithOnClickPropsType {
  to?: undefined
  onClick: () => void
}

type HeaderItemPropsType = HeaderItemCommonPropsType &
  (HeaderItemWithLinkPropsType | HeaderItemWithOnClickPropsType)

export const HeaderItem = ({
  to,
  onClick,
  children,
  icon,
  UNSAFE_CLASSNAME,
  UNSAFE_CLASSNAME_NAVLINK_ITEM,
  UNSAFE_STYLE,
  iconClassname,
  childrenClassName,
}: HeaderItemPropsType) => {
  const Location = useLocation()
  if (to) {
    return (
      <NavLink
        className={`HeaderItem ${UNSAFE_CLASSNAME_NAVLINK_ITEM}`}
        to={to}
        onClick={onClick}
        // activeClassName=""
      >
        {!!icon && (
          <span
            className={`${children ? 'HeaderItem__icon' : ''} ${iconClassname}`}
            style={UNSAFE_STYLE}
          >
            {icon}
          </span>
        )}
        <span
          className={`${childrenClassName} ${
            Location.pathname.includes(to) && 'HeaderItem--is-active'
          }`}
        >
          {children}
        </span>
      </NavLink>
    )
  }
  return (
    <button
      className={`HeaderItem ${UNSAFE_CLASSNAME}`}
      style={UNSAFE_STYLE}
      type="button"
      // onClick={onClick ? onClick : undefined}
      onClick={onClick}
    >
      {!!icon && (
        <span
          className={`${children ? 'HeaderItem__icon' : ''} ${iconClassname}`}
          // className={`${children ? 'HeaderItem__icon 2' : ''}`}
        >
          {icon}
        </span>
      )}
      {children}
    </button>
  )
}
