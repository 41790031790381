import React, { useState, useEffect } from 'react'
import { Button, MaxHeightContainer, Spacer, Text } from '@eltoro-ui/components'
import {
  faFileImport,
  faMarker,
  faTrash,
  faWalking,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AudienceResultsCard,
  AudienceResultsCount,
  ListingsList,
  MapMarker,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components'
/* import { mockLocation } from 'Requests/Fixtures/listings'
 * import { getMapListings } from 'Requests/Live/listings' */
import { browserLog } from 'Tools'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilteredBuzz,
  setRefreshList,
  setSelectedListing,
  setSelectedPage,
  setSelectedSort,
  setTypeSearch,
  setSidebarLens,
} from 'Redux/actions'
import {
  BuzzScoreFilters,
  CustomMapPopup,
  Map,
  MapPopup,
  MapToolTip,
  BuzzScore,
} from 'Components'
import { APIFeatureType, TRootState } from 'types'
import { buzzFilters } from 'Pages/ProspectActivity/configs'
import './MyListingsLens.scss'
import toast from 'react-hot-toast'
import { getIconBuzzColor } from 'Helpers'
import { OutlinedMapMarker } from 'Pages/ProspectActivity/components/MapMarker/OutlinedMapMarker'
import { faHouseUser } from '@fortawesome/pro-solid-svg-icons'
import { getListings } from 'Requests'
import mapMarkerImg from '../../../../Images/Map-Marker.png'
import {
  LensFilterContainer,
  LensSectionHeading,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from '../components'
import { useSelectedListingMarker } from '../components/LensSelectedMarker/LensSelectedMarker'
import buzzScoreImg from '../../../../assets/Images/buzz_score.png'

export const MyListingsLens = () => {
  const [activeBuzzFilters, setActiveBuzzFilters] = useState(buzzFilters)
  const [inactiveListing, setInactivelisting] = useState<Boolean>(true)
  const dispatch = useDispatch()

  const { selectedList } = useSelector(
    (state: TRootState) => state.selectedListingsReducer,
  )
  const AllBuzz: any = []

  const { selectedMarker } = useSelector(
    (state: TRootState) => state.selectedMarkerReducer,
  )
  const { filteredBuzz } = useSelector(
    (state: TRootState) => state.FilteredBuzzReducer,
  )
  const { selectedPage } = useSelector(
    (state: TRootState) => state.selectedPageReducer,
  )
  const { typedSearch } = useSelector(
    (state: TRootState) => state.typedSearchReducer,
  )
  const { selectedSort } = useSelector(
    (state: TRootState) => state.selectedSortReducer,
  )
  const [listingData, setListingData] = useState({
    listings: [],
    total_listings: 20,
    total_pages: 1,
  })
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getListings({
      page: selectedPage,
      size: 20,
      exclude: filteredBuzz,
      search: typedSearch,
      sort: selectedSort.value,
      show_inactive: inactiveListing,
    })
      .then((res: any) => {
        // setList(res.data.listings)
        setListingData(res.data)
        setIsLoading(true)
        dispatch(setRefreshList(res.data.listings))
      })
      .catch((err: any) => {
        toast.error(err)
      })
  }, [
    filteredBuzz,
    selectedPage,
    selectedSort,
    inactiveListing,
    typedSearch.length === 0,
  ])

  useEffect(() => {
    if (typedSearch.length >= 1) {
      dispatch(setSelectedPage(1))
      getListings({
        page: selectedPage,
        size: 20,
        exclude: filteredBuzz,
        search: typedSearch,
        sort: selectedSort.value,
        show_inactive: inactiveListing,
      })
        .then((res: any) => {
          // setList(res.data.listings)
          setListingData(res.data)
          setIsLoading(true)
          dispatch(setRefreshList(res.data.listings))
        })
        .catch((err: any) => {
          toast.error(err)
        })
    }
  }, [typedSearch])
  const getListingMethod = () => {
    getListings({ size: 20 })
      .then((res: any) => {
        // setList(res.data.listings)
        setListingData(res.data)
        dispatch(setRefreshList(res.data.listings))
      })
      .catch((err: any) => {
        toast.error(err)
      })
  }

  useEffect(() => {
    activeBuzzFilters.forEach((item) => AllBuzz.push(item.label))
    const Buzz = ['High buzz', 'Warm buzz', 'Medium buzz', 'Low buzz']
    const data = Buzz.filter(function (v: any) {
      return !AllBuzz.includes(v)
    })
    dispatch(setFilteredBuzz(data))
    const revData = data.reverse()
  }, [activeBuzzFilters])

  const { refreshList } = useSelector(
    (state: TRootState) => state.RefreshListingReducer,
  )
  useEffect(() => {
    setListings(refreshList)
  }, [refreshList])
  const [listings, setListings] = useState(refreshList)
  const initialListing = refreshList

  const [selectedListings, setSelectedListings] = useState<APIFeatureType[]>([])
  const [result, setResult] = useState(false)

  useEffect(() => {
    openAudience()
    dispatch(setSelectedListing(selectedListings))
  }, [selectedListings])

  // setActiveBuzzFilters()
  const totalProspects = selectedListings.reduce(
    (total, listing) => total + listing?.unique_visitor_count,
    0,
  )
  const openAudience = () => {
    if (selectedListings?.length >= 1) {
      setResult(true)
    } else {
      setResult(false)
    }
  }
  const checkSwitchToggle = (value: Boolean) => {
    setInactivelisting(value)
  }
  const { isCenter } = useSelector(
    (state: TRootState) => state.centerLensReducer,
  )

  const { openSideBar } = useSelector(
    (state: TRootState) => state.centerLensReducer,
  )

  const toggleSidebar = () => {
    dispatch(setSidebarLens(!openSideBar))
  }

  return (
    <div className="MyListingsLens">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="My Listings"
              subHeading="View prospects that have been seen in one or all of your listings."
              toggle={!0}
              isToggle={checkSwitchToggle}
            />
          }
          footer={
            <div style={{ padding: '0 3rem', margin: '0 0 1rem 0' }}>
              <Button
                UNSAFE_style={{ color: '#007B94', border: '2px solid #007B94' }}
                UNSAFE_className="MyListingsLens__FooterBtn"
                kind="default"
                size="l"
                width="100%"
                onClick={() => {
                  dispatch(setFilteredBuzz([]))
                  setActiveBuzzFilters(buzzFilters)
                  getListingMethod()
                  dispatch(setTypeSearch(''))
                  dispatch(setSelectedPage(1))
                  dispatch(setSelectedSort({ label: 'Zip Code', value: 'zip' }))
                  setSelectedListings([])
                }}
              >
                Refresh
              </Button>
            </div>
          }
        >
          {listings?.length >= 1 ? (
            <>
              <div
                className={
                  isCenter
                    ? 'MyListingsLens__sidebar-content '
                    : 'MyListingsLens__sidebar-content MyListingsLens__Height'
                }
              >
                <ListingsList
                  listings={listings}
                  selectedListings={selectedListings}
                  onSelectedListingsChange={setSelectedListings}
                  listingData={listingData}
                />
              </div>
            </>
          ) : (
            ''
          )}
          {listings?.length < 1 && isLoading ? (
            <div className="MyListingsLens__sidebar-content MyListingsLens__NoList">
              <p style={{ color: '#BD0000' }}>
                You don&apos;t have any listings. Your listings are pulled from
                the MLS every morning. Please check again or contact support if
                the issue persists.
              </p>
            </div>
          ) : (
            ''
          )}
        </MaxHeightContainer>
      </LensSidebar>

      <div className="MyListingsLens__map">
        {result ? (
          <div
            className={
              isCenter
                ? 'MyListingsLens__audience'
                : 'MyListingsLens__audienceCenter'
            }
          >
            <div className="MyListingsLens__border_radius">
              <LensSidebarFooter>
                <AudienceResultsCard>
                  <div className="MyListingsLens__score">
                    <div className="MyListingsLens__scoreLeft">
                      <p>{totalProspects.toLocaleString()}</p>
                    </div>
                    <div className="MyListingsLens__scoreRight">
                      <b>Prospects</b>
                      {/* <b>Results Prospects</b> */}
                      <br />
                      have visited <b>{selectedListings.length} </b>
                      {/* of my */}{' '}
                      {selectedListings.length > 1 ? 'locations' : 'location'}
                      {/* <b> listings</b> */}
                    </div>
                  </div>
                  <Spacer />
                  <SaveAudienceWizard
                    type="my listings"
                    onSaveAndClose={(audienceDetails) =>
                      browserLog.info(audienceDetails)
                    }
                    totalProspects={totalProspects}
                    listing={selectedListings}
                  />
                </AudienceResultsCard>
              </LensSidebarFooter>
            </div>
          </div>
        ) : (
          ''
        )}
        {/* buzz score toggle */}
        {/* <div
          style={{
            position: 'absolute',
            right: '35px',
            zIndex: 999,
            bottom: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ fontSize: '10px', marginRight: '5px' }}>Show Filters</p>
          <FontAwesomeIcon
            style={{ marginRight: '1.5rem', fontSize: '32px' }}
            size="1x"
            color={toggle ? '#FFB708' : '#FF0000'}
            icon={toggle ? faToggleOn : faToggleOff}
            onClick={() => setToggle(!toggle)}
          />
        </div> */}
        {/* buzz score toggle */}

        {/* <div className={`${toggle ? '' : 'MyListingsLens__hide'}`}> */}
        <div>
          <div className="MyListingsLens__rightTop">
            <img
              src={mapMarkerImg}
              // className='MyListingsLens__pin_point_img'
              alt=""
              style={{ height: '26px' }}
            />

            <Text on="white" size="m" textAlign="center" weight="bold">
              My Listing
            </Text>

            <div style={{ display: 'flex' }}>
              <p style={{ fontSize: '10px', marginRight: '5px' }}>
                Show Filters
              </p>
              <FontAwesomeIcon
                style={{ fontSize: '32px' }}
                size="1x"
                color={!openSideBar ? '#FFB708' : '#FF0000'}
                icon={!openSideBar ? faToggleOn : faToggleOff}
                onClick={toggleSidebar}
              />
            </div>
          </div>
          <div
            className={`MyListingsLens__filter ${
              !openSideBar ? '' : 'MyListingsLens__hide'
            }`}
          >
            <LensFilterContainer>
              <BuzzScoreFilters
                filters={buzzFilters}
                activeFilters={activeBuzzFilters}
                onChange={setActiveBuzzFilters}
              />
              <div className="buzzScoreImg__container">
                {/* <BuzzScore /> */}
                {/* <img
                  src={buzzScoreImg}
                  className="buzzScoreImg__containerInside"
                  alt="..."
                /> */}
              </div>
            </LensFilterContainer>
          </div>
        </div>

        {/* <div className="MyListingsLens__filter"> */}
        {/* <div className="buzzScoreImg_container">
          <img src={buzzScoreImg} alt="..." />
        </div> */}
        {/* </div> */}
        <Map
          features={listings}
          type="my listing"
          locations={[]}
          marker={(f) => (
            <MapMarker
              color={getIconBuzzColor(f)}
              isSelected={useSelectedListingMarker}
              f={f}
            />
          )}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  )
}
