import React from 'react'
import './BuzzScoreFilters.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Checkbox, Text } from '@eltoro-ui/components'

export type BuzzFilterType = {
  label: string
  color: string
  test: (buzzScore: string) => boolean
}

export const BuzzScoreFilters = ({
  filters,
  activeFilters,
  onChange,
}: {
  filters: BuzzFilterType[]
  activeFilters: BuzzFilterType[]
  onChange: (newActiveFilters: BuzzFilterType[]) => void
}) => {
  const isActive = (filter: BuzzFilterType) => {
    return activeFilters.some(
      (activeFilter) => activeFilter.label === filter.label,
    )
  }

  const handleFilterClick = (clickedFilter: BuzzFilterType) => {
    if (isActive(clickedFilter)) {
      onChange(
        activeFilters.filter((filter) => filter.label !== clickedFilter.label),
      )
    } else {
      onChange([...activeFilters, clickedFilter])
    }
  }

  return (
    <div className="BuzzScoreFilters">
      <div className="BuzzScoreFilters__Header">
        <Text
          on="white"
          size="xl"
          weight="bold"
          textAlign="center"
          UNSAFE_className=""
        >
          Buzz Score
        </Text>
      </div>
      <div
        className="BuzzScoreFilters__filters"
        data-testid="filters_container"
      >
        {filters.map((filter) => (
          <Checkbox
            checkBorder={`1px solid ${filter.color}`}
            backgroundColor={isActive(filter) ? filter.color : 'inherit'}
            checked={isActive(filter)}
            onChange={() => handleFilterClick(filter)}
            label={filter.label.split(' ')[0]}
          />
        ))}
      </div>
      {/* <div className="BuzzScoreFilters__labels">
        <p>High</p>
        <p>Warm</p>
        <p>Medium</p>
        <p style={{ marginBottom: '0px' }}>Low</p>
      </div> */}
    </div>
  )
}
