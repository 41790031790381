export const isCampaignLaunchReducer: undefined | any = (
  state = {
    isLaunchCampaign: false,
    campaignId: null,
    LaunchProspectOpen: false,
    currentCampaignName: '',
  },
  action: any,
) => {
  switch (action.type) {
    case 'CURRENT_CAMPAIGN_NAME':
      return { ...state, currentCampaignName: action.payload }
    case 'SET_ISLAUNCHCAMPAIGN':
      return { ...state, isLaunchCampaign: action.payload }
    case 'SET_CAMPAIGN_ID':
      return { ...state, campaignId: action.payload }
    case 'SET_LAUNCH_CAMPAIGN':
      return { ...state, LaunchProspectOpen: action.payload }
    default:
      return { ...state }
  }
}

export const campaignIdReducer: undefined | any = (
  state = { campaignId: null },
  action: any,
) => {
  switch (action.type) {
    case 'SET_CAMPAIGN_ID':
      return { campaignId: action.payload }
    default:
      return { ...state }
  }
}
