import { Loading, LoginFooter } from 'Components'
import React, { ReactNode } from 'react'
import { inherits } from 'util'
import './MaxHeightContainer.scss'

type MaxHeightContainerType = {
  header?: ReactNode
  footer?: ReactNode
  fullHeight?: boolean
  overFlow?: string
  bottom?: Boolean
  headerAbsolute?: Boolean
  UNSAFE_CLASSNAME?: string
  // loading?: boolean
}

export const MaxHeightContainer: React.FC<MaxHeightContainerType> = ({
  header,
  footer,
  fullHeight = false,
  children,
  overFlow,
  bottom,
  UNSAFE_CLASSNAME,
  headerAbsolute,
  // loading,
}) => {
  return (
    <>
      <div
        className={`MaxHeightContainer ${
          fullHeight ? 'MaxHeightContainer--full-height' : ''
        }`}
      >
        {header && (
          <div
            className="MaxHeightContainer__header"
            style={{ zIndex: headerAbsolute ? 0 : 'inherit' }}
          >
            {header}
          </div>
        )}
        <div className={`MaxHeightContainer__content ${UNSAFE_CLASSNAME}`}>
          {children}
        </div>
        {footer && <div className="MaxHeightContainer__footer">{footer}</div>}
      </div>
    </>
  )
}
