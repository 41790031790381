import React, { useState } from 'react'
import { Button, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ShowExample from '../../../../../assets/Images/ShowExample.png'

export const StepOne = ({ onClose }: { onClose: () => void }) => {
  const [openImage, setOpenImage] = useState(false)

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Text tag="div" on="secondary-200" size="xxl">
            STEP 1
          </Text>
        </div>
        <div>
          <FontAwesomeIcon
            className="cross_icon"
            icon={faTimes}
            onClick={onClose}
            cursor="pointer"
          />
        </div>
      </div>
      <Text on="white" size="xl" tag="div">
        <b>Make sure your CSV is formatted correctly</b>
      </Text>
      <Text on="grey-500" tag="div" size="s">
        <i>
          Notice: Our system will only process addresses and zip codes. All
          other columns are ignored by our system.
        </i>
      </Text>

      <div className="uploadcsvModal__points">
        <ol type="a">
          <li>
            Column format : first name, last name, address, city, zip, phone
            number, email <br />
            <i>
              Example: <br />
              John, Smith, 926 River Cove Ave FL, Orlando, 32825, +1 234 698
              5874, john@gmail.com John, Smith, 926 River Cove Ave FL, Orlando,
              32825, +1 234 698 5874, john@gmail.com
            </i>
          </li>
          <li>
            Zip Code Column (Zip needs to be broken down into a 5-digit zip and
            must be preserve 0 in zip codes beginning with the number 0).
          </li>
          <li>ENTER key, the insertion point moves to the next field/row</li>
          <li>
            Comma-separated string will convert to column next to the column
          </li>
          <li>
            Must be an Excel or CSV file ( save as CSV UTT-8 ( Comma
            delimited)(*.csv))
          </li>
        </ol>
      </div>

      <Button
        type="button"
        kind="default"
        size="s"
        UNSAFE_className="uploadcsvModal__example"
        onClick={() => {
          setOpenImage(!openImage)
        }}
      >
        Download Template
      </Button>
      {openImage ? (
        <a
          className="example_img_container"
          download="contacts"
          href="data:text/csv;base64,Zmlyc3QgbmFtZSxsYXN0IG5hbWUsYWRkcmVzcyx6aXAscGhvbmUgbnVtYmVyLGVtYWlsLGNpdHkK"
        >
          <img src={ShowExample} alt="" style={{ height: '90px' }} />
        </a>
      ) : (
        ''
      )}
      {/* </div> */}
      {/* <div>
          <FontAwesomeIcon
            className="cross_icon"
            icon={faTimes}
            // onClick={onClick}
          />
        </div>
      </div> */}
    </>
  )
}
