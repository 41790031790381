import { atomFamily, selectorFamily } from 'recoil'
import {
  BlockConfigType,
  BlocksConfigType,
  ValueWithErrorType,
} from '../Typings'
import {
  blockSelectorGetter,
  blockSelectorSetter,
  getDefaultShapeStroke,
} from '../Helpers'

// each atom in this file has a selector that corresponds to it
// The atom keeps track of the specific piece of the Block's state...
// ...the selector is able to update all atoms of selected blocks at the same time.

export const blockStrokeEnabledAtom = atomFamily<boolean, BlockConfigType>({
  key: 'blockStrokeEnabled',
  default: (config) =>
    getDefaultShapeStroke(config.kind, config.key, 'enabled'),
})

export const blockStrokeEnabledSelector = selectorFamily<
  ValueWithErrorType<boolean>,
  BlocksConfigType
>({
  key: 'blockStrokeEnabledSelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockStrokeEnabledAtom),
  set:
    (config) =>
    ({ set }, newEnabled) => {
      blockSelectorSetter(set, config, blockStrokeEnabledAtom, newEnabled)
    },
})

export const blockStrokeColorAtom = atomFamily<string, BlockConfigType>({
  key: 'blockStrokeColor',
  default: (config) => getDefaultShapeStroke(config.kind, config.key, 'color'),
})

export const blockStrokeColorSelector = selectorFamily<
  ValueWithErrorType<string>,
  BlocksConfigType
>({
  key: 'blockStrokeColorSelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockStrokeColorAtom),
  set:
    (config) =>
    ({ set }, newColor) => {
      blockSelectorSetter(set, config, blockStrokeColorAtom, newColor)
    },
})

export const blockStrokeWidthAtom = atomFamily<number, BlockConfigType>({
  key: 'blockStrokeWidth',
  default: (config) => getDefaultShapeStroke(config.kind, config.key, 'width'),
})

export const blockStrokeWidthSelector = selectorFamily<
  ValueWithErrorType<number>,
  BlocksConfigType
>({
  key: 'blockStrokeWidthSelector',
  get:
    (config) =>
    ({ get }) =>
      blockSelectorGetter(get, config, blockStrokeWidthAtom),
  set:
    (config) =>
    ({ set }, newWidth) => {
      blockSelectorSetter(set, config, blockStrokeWidthAtom, newWidth)
    },
})
