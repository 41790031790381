import React, { ReactNode } from 'react'
import { DateRangePicker, Text } from '@eltoro-ui/components'
import { LabelValuePairType } from 'types'

export const LabelValuePair = ({
  label,
  value,
  UNSAFE_className,
  startDate,
  endDate,
  UNSAFE_className_Value,
  onStartDateChange,
  onEndDateChange,
}: LabelValuePairType) => {
  return (
    <div className="date-campaign-view">
      <Text
        UNSAFE_className={UNSAFE_className}
        on="white"
        kind="subdued"
        size="m"
        weight="bold"
      >
        {label}
      </Text>
      {startDate ? (
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          spacerBoolean={false}
          min={new Date()}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
        />
      ) : (
        <Text
          on="white"
          UNSAFE_className={UNSAFE_className_Value}
          size="l"
          tag="div"
        >
          {value}
        </Text>
      )}
    </div>
  )
}
