import React, { useState, useEffect } from 'react'
import { Table, Text } from '@eltoro-ui/components'
import { getChargebeeTransactions } from 'Requests'
import { TableSelectionWrapper, Loading } from 'Components'
import { removeTimeFromDate } from 'Helpers'
import './Transaction.scss'

export const Transaction = () => {
  const [transactions, setTransactions] = useState<any>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getChargebeeTransactions(1, 5)
      .then((res) => {
        setTransactions(res?.data?.transactions)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const getLoading = () => {
    let component
    if (loading) {
      component = <Loading />
    } else if (!transactions.length) {
      component = (
        <div
          style={{
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h4>No Transactions</h4>
        </div>
      )
    } else {
      component = (
        <Table
          noCheckbox
          striped
          rows={transactions || []}
          columns={[
            {
              path: '',
              width: '18%',
              alignment: 'left',
              RowCell: (i: any) => (
                <span>
                  <Text on="white" weight="bold" size="l">
                    {i.name}
                  </Text>
                  <br />
                  <Text
                    on="white"
                    weight="light"
                    UNSAFE_style={{ fontSize: '17px' }}
                  >
                    {removeTimeFromDate(i.created_at)} - Campaign
                  </Text>
                </span>
              ),
            },

            {
              path: '',
              alignment: 'right',
              RowCell: (i: any) => (
                <span>
                  <Text on="white" weight="bold" size="l">
                    ${i.budget}
                  </Text>
                </span>
              ),
            },
          ]}
        />
      )
    }
    return component
  }

  return (
    <div className="Transactions__container">
      <TableSelectionWrapper
        selectionType="listing"
        selections={[]}
        actions={[]}
        UNSAFE_CLASSNAME_WRAPPER="Transactions__table-wrapper"
      >
        <Text on="white" weight="bold" size="l" UNSAFE_className="heading">
          Transactions
        </Text>

        {/* {!transactions.length && loading ? ( */}
        {getLoading()}
      </TableSelectionWrapper>
    </div>
  )
}
