import { Button, Modal, Text } from '@eltoro-ui/components'
import React from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export class ChargebeeSuccessModal extends React.Component {
  render() {
    return (
      <Modal
        offClick={() => {
          // @ts-ignore
          //   this.props.close()
        }}
      >
        <div className="OnboardingModal__welcome">
          <div className="OnboardingModal__welcomeHeader">
            <div style={{ textAlign: 'center' }}>
              <Text on="white" weight="bold" size="xxxl" textAlign="center">
                Payment Successful
              </Text>
            </div>
            <br />
            <div
              style={{
                width: '280px',
                margin: '0 30px',
                textAlign: 'center',
              }}
            >
              <Text on="grey-500" weight="normal" size="m" textAlign="center">
                You’re payment was successful
              </Text>
            </div>
          </div>
          <div style={{ margin: '0 auto' }}>
            <Button
              kind="primary"
              size="l"
              weight="bold"
              // @ts-ignore
              onClick={() => this.props.close()}
            >
              Back to Billing
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}
